<template>
  <el-dialog
    class="showAll_dialog"
    :title="getDialogTitle()"
    width="800px"
    :height="'60%'"
    :max-height="'450px'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="165px"
      v-loading="formLoading"
    >
      <!-- 基础信息 -->
      <el-form-item label="城市：" prop="city_code">
        <el-select
          v-model="dataForm.city_code"
          placeholder="请选择城市"
          @change="cityChange"
          collapse-tags
          clearable
          style="width: 400px"
          :disabled="isDisabled"
        >
          <el-option
            v-for="item in cityList"
            :key="item.city_code"
            :label="item.city_name"
            :value="item.city_code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="editState != 'add'" label="创建时间">
        <el-date-picker
          v-model="dataForm.create_time"
          type="datetime"
          :disabled="true"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="展示时间" prop="start_time" :disabled="true">
        <el-date-picker
          v-model="showTime"
          type="datetimerange"
          style="width: 400px"
          value-format="yyyy-MM-dd HH:mm:ss"
          :clearable="true"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="timeChange"
          :disabled="isDisabled"
        ></el-date-picker>
      </el-form-item>
      <div v-for="(item, index) in dataForm.page_config.item_list" :key="index">
        <el-form-item
          :label="`首页活动图${index + 1}`"
          :prop="`page_config.item_list.${index}.pic_url`"
          :rules="[
            {
              required: true,
              message: '请上传首页活动图',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <div
            v-if="editState != 'details'"
            class="flex"
            style="justify-content: space-between"
          >
            <el-upload
              v-if="!item.fileList1.length"
              v-model="item.pic_url"
              :action="uploadUrl"
              :on-remove="createOnRemove(index, 1)"
              :before-remove="beforeRemove"
              :multiple="false"
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="item.fileList1"
              :http-request="createUploadRequest(index, 1)"
              :before-upload="beforeUp"
              list-type="picture-card"
              :disabled="editState != 'details' ? false : true"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <div class="image-preview" v-else>
              <div class="image-preview-wrapper">
                <img :src="file_view_rul + item.pic_url" />
                <div class="image-preview-action">
                  <i
                    @click="handleRemove('file', index, 1)"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>
            <!-- 加减按钮 -->
            <div
              class="flex"
              style="margin-left: 0.625rem; margin-bottom: 1.125rem"
            >
              <img
                class="icon"
                @click="formArrHandleChange(0, index)"
                src="@/assets/img/icon-add.png"
                alt
              />
              <img
                v-if="dataForm.page_config.item_list.length > 1"
                @click="formArrHandleChange(1, index)"
                class="icon"
                src="@/assets/img/icon-delete.png"
                alt
              />
            </div>
          </div>
          <div v-else class="image-container">
            <el-image
              style="
                width: 150px;
                height: 150px;
                border-radius: 6px;
                margin-right: 10px;
              "
              :src="file_view_rul + item.pic_url"
              :preview-src-list="[file_view_rul + item.pic_url]"
              :disabled="true"
            >
              <img
                slot="error"
                :src="file_view_rul + item.pic_url"
                style="
                  width: 150px;
                  height: 150px;
                  border-radius: 6px;
                  margin-right: 10px;
                "
              />
            </el-image>
            <div
              class="image-container-btn"
              @click="
                downloadByBlob(
                  `${file_view_rul + item.pic_url}`,
                  `${'首页活动图' + index + 1}`
                )
              "
            >
              下载
            </div>
          </div>
          <span style="display: block"
            >仅支持上传jpg,jpeg,png格式图片，推荐分辨率为560*720</span
          >
        </el-form-item>
        <el-form-item
          label="是否跳转详情页"
          :prop="`page_config.item_list.${index}.to_detail`"
          :rules="[
            {
              required: true,
              message: '请选择是否跳转详情页',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-select
            v-model="item.to_detail"
            placeholder="请选择是否跳转详情页"
            @change="detailsChange($event, index)"
            collapse-tags
            clearable
            :disabled="editState != 'details' ? false : true"
          >
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="item.to_detail"
          :label="`活动详情${index + 1}`"
          :prop="`page_config.item_list.${index}.detail_url`"
          :rules="[
            {
              required: true,
              message: '请上传活动详情图',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <div v-if="editState != 'details'">
            <el-upload
              v-if="!item.fileList2.length"
              v-model="item.detail_url"
              :action="uploadUrl"
              :on-remove="createOnRemove(index, 2)"
              :before-remove="beforeRemove"
              :multiple="false"
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="item.fileList2"
              :http-request="createUploadRequest(index, 2)"
              :before-upload="beforeUp"
              list-type="picture-card"
              :disabled="editState != 'details' ? false : true"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <div v-else class="image-preview">
              <div class="image-preview-wrapper">
                <img :src="file_view_rul + item.detail_url" />
                <div class="image-preview-action">
                  <i
                    @click="handleRemove('file', index, 2)"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="image-container">
            <el-image
              style="
                width: 150px;
                height: 150px;
                border-radius: 6px;
                margin-right: 10px;
              "
              :src="file_view_rul + item.detail_url"
              :preview-src-list="[file_view_rul + item.detail_url]"
              :disabled="true"
              @error="handleError"
            />
            <div
              class="image-container-btn"
              @click="
                downloadByBlob(
                  `${file_view_rul + item.detail_url}`,
                  `${'活动详情' + index + 1}`
                )
              "
            >
              下载
            </div>
          </div>
        </el-form-item>
        <el-form-item
          v-if="item.to_detail"
          label="详情页是否有跳转按钮"
          :prop="`page_config.item_list.${index}.has_btn`"
          :rules="[
            {
              required: true,
              message: '请选择详情页是否有跳转按钮',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-select
            v-model="item.has_btn"
            placeholder="请选择详情页是否有跳转按钮"
            @change="hasBtnChange($event, index)"
            collapse-tags
            clearable
            :disabled="editState != 'details' ? false : true"
          >
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="item.has_btn"
          label="跳转位置"
          :prop="`page_config.item_list.${index}.path`"
          :rules="[
            {
              required: true,
              message: '请选择详情页是否有跳转按钮',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-select
            v-model="item.path"
            placeholder="请选择跳转位置"
            collapse-tags
            clearable
            :disabled="editState != 'details' ? false : true"
          >
            <el-option label="余额充值页" :value="1"></el-option>
            <el-option label="度数充值页" :value="2"></el-option>
            <el-option label="度数页" :value="3"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-foote r">
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      <el-button v-if="editState != 'details'" @click="visible = false"
        >取消</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import axios from "../../../utils/axiosHelper";

export default {
  // name: "AddOrUpdate",
  props: {
    editState: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      visible: false,
      formLoading: false,
      cityList: [],
      cityOptions: [],
      showTime: [],
      uploadUrl: "",
      file_view_rul: this.FILE_VIEW_ROOT_RUL,
      dataForm: {
        page_config: {
          item_list: [
            {
              has_btn: "",
              path: "",
              pic_url: "",
              to_detail: "",
              detail_url: "",
              fileList1: [],
              fileList2: [],
            },
          ],
        },
        start_time: "",
        end_time: "",
        city_code: "",
      },
      dataRule: {
        start_time: [
          {
            required: true,
            message: "请选择时间",
            trigger: ["blur", "change"],
          },
        ],
        city_code: [
          {
            required: true,
            message: "请选择城市",
            trigger: ["blur", "change"],
          },
        ],
      },
      status: "",
    };
  },
  computed: {
    isDisabled() {
      if (this.editState == "details") {
        return true;
      }
      if (this.status != 4 && this.status != "") {
        return true;
      }
      return false;
    },
  },
  created() {},
  async mounted() {
    await this.getCityList();
    this.hideLoading();
  },
  methods: {
    handleError(e) {
      console.log(e);
    },
    init(obj) {
      this.visible = true;
      this.fileList = [];
      this.showTime = [];
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (obj) {
          this.$nextTick(() => {
            let row = JSON.parse(JSON.stringify(obj));
            // this.formItem = row;
            this.dataForm.id = row.id;
            this.dataForm.create_time = row.create_time;
            this.showTime = [row.start_time, row.end_time];
            this.dataForm.start_time = row.start_time;
            this.dataForm.end_time = row.end_time;
            this.dataForm.city_name = row.city_name;
            this.dataForm.city_code = row.city_code;
            let page_config = row.page_config ? row.page_config : {};
            this.status = row.status;
            if (page_config?.item_list) {
              page_config.item_list.forEach((item, index) => {
                if (item.pic_url) {
                  item.fileList1 = [
                    {
                      url: this.file_view_rul + item.pic_url,
                      name: item.pic_url,
                    },
                  ];
                } else {
                  item.fileList1 = [];
                }
                if (item.detail_url) {
                  item.fileList2 = [
                    {
                      url: this.file_view_rul + item.detail_url,
                      name: item.detail_url,
                    },
                  ];
                } else {
                  item.fileList2 = [];
                }
                // if (!item.has_btn) {
                //   item.has_btn = 0;
                // }
              });
            }
            this.dataForm.page_config = page_config;
          });
        } else {
          this.dataForm.create_time = new Date();
          this.dataForm.showTime = [];
          this.dataForm.start_time = "";
          this.dataForm.end_time = "";
          this.dataForm.city_name = "";
          this.dataForm.city_code = "";
          this.dataForm.page_config = {
            item_list: [
              {
                has_btn: "",
                path: "",
                pic_url: "",
                to_detail: "",
                detail_url: "",
                fileList1: [],
                fileList2: [],
              },
            ],
          };
          this.status = "";
        }
      });
    },
    getCityList() {
      this.axiosHelper
        .get("/api/partner/agentlist/get/list", {
          params: { status: 1 },
        })
        .then((res) => {
          this.hideLoading();
          this.cityList = res.data.data;
          res.data.data.map((item) => {
            this.cityOptions[item.city_code] = { ...item };
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    // 选择是否跳转详情页
    detailsChange(val, index) {
      if (!val) {
        this.dataForm.page_config.item_list[index].fileList2 = [];
        this.dataForm.page_config.item_list[index].detail_url = "";
        this.dataForm.page_config.item_list[index].has_btn = "";
        this.dataForm.page_config.item_list[index].path = "";
      }
    },
    // 选择详情页是否有跳转按钮
    hasBtnChange(val, index) {
      console.log(val, !val, "aa");
      if (!val) return (this.dataForm.page_config.item_list[index].path = "");
    },
    // 城市选择Change
    cityChange(val) {},
    timeChange(val) {
      this.showTime = val;
      if (val.length) {
        this.dataForm.start_time = val[0];
        this.dataForm.end_time = val[1];
      }
    },
    getDialogTitle() {
      switch (this.editState) {
        case "edit":
          return "活动页编辑";
        case "add":
          return "新增城市活动页";
        case "details":
          return "活动页详情";
        default:
          return "";
      }
    },
    //限制上传文件的个数提示
    handleExceed(files, fileList) {
      this.$message.warning("只允许上传1张图片");
    },
    //删除之前执行的方法
    beforeRemove(file) {
      //return this.$confirm(`确定移除 ${file.name}？`);
    },
    createOnRemove(index, type) {
      return (file) => {
        this.handleRemove(file, index, type);
      };
    },
    //文件删除的方法
    handleRemove(file, index, type) {
      // 点击删除图片，获取图片文件file,
      // 筛选出图片文件file.url和图片数组的url一致的数据
      // 删除掉这个数据
      switch (type) {
        case 1:
          this.dataForm.page_config.item_list[index].fileList1 = [];
          this.dataForm.page_config.item_list[index].pic_url = "";
          break;
        case 2:
          this.dataForm.page_config.item_list[index].fileList2 = [];
          this.dataForm.page_config.item_list[index].detail_url = "";
          break;
      }
    },

    /* 上传前判断 */
    beforeUp(file) {
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        this.$message.error("仅支持上传jpg,jpeg,png格式图片");
        return false;
      } else if (file.size / 1024 / 1024 > 5) {
        this.$message.error("图片大小不能超过5M");
        return false;
      }
      return true;
    },
    createUploadRequest(index, type) {
      return (file) => {
        this.upload(file, index, type);
      };
    },
    /* 上传 */
    upload(file, index, type) {
      console.log(file, "file");
      const formData = new FormData();
      formData.append("path", "activityPage");
      formData.append("file", file.file);
      this.axiosHelper
        .post(`/api/file/upload/path`, formData)
        .then((res) => {
          // 获取上传成功返回的图片地址
          const imageUrl = res.data.url;

          let imgObj = {
            url: imageUrl,
            name: res.data.name,
          };
          console.log(index, type);
          switch (type) {
            case 1:
              this.dataForm.page_config.item_list[index].fileList1.push(imgObj);
              this.dataForm.page_config.item_list[index].pic_url =
                res.data.name;
              console.log(res.data.name, "res.data.name");
              break;
            case 2:
              this.dataForm.page_config.item_list[index].fileList2.push(imgObj);
              this.dataForm.page_config.item_list[index].detail_url =
                res.data.name;
              break;
          }
        })
        .catch(() => {
          this.$message.error("上传失败");
        });
    },
    // 首页活动图的增加与删除
    formArrHandleChange(type, index) {
      // 0、1首页活动图的增加与删除
      switch (type) {
        case 0:
          this.dataForm.page_config.item_list.push({
            has_btn: "",
            path: "",
            pic_url: "",
            to_detail: "",
            detail_url: "",
            fileList1: [],
            fileList2: [],
          });
          console.log(this.dataForm, "asdad");
          break;
        case 1:
          this.dataForm.page_config.item_list.splice(index, 1);
          break;
      }
    },
    // 表单提交
    dataFormSubmit() {
      if (this.editState == "details") {
        this.hideLoading();
        this.showTime = [];
        this.visible = false;
        //this.$emit("refreshDataList");
        return;
      }

      this.$refs["dataForm"].validate(async (valid) => {
        if (!valid) return;
        this.getCityList();
        this.showLoading("加载中");
        //   新增
        if (this.editState == "add") {
          axios
            .post("/api/activity/page/add", this.dataForm)
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg);
                return;
              }
              this.showTime = [];
              this.visible = false;
              this.$emit("refreshDataList");
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            });
        } else if (this.editState == "edit") {
          // 编辑
          axios
            .put(`/api/activity/page/update/${this.dataForm.id}`, this.dataForm)
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg);
                return;
              }
              this.showTime = [];
              this.visible = false;
              this.$emit("refreshDataList");
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            });
        }
      });
    },
    // 下载图片
    downloadByBlob(url, name) {
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob);
          this.download(url, name);
          // 用完释放URL对象
          URL.revokeObjectURL(url);
        });
      };
    },

    download(href, name) {
      let eleLink = document.createElement("a");
      eleLink.download = name;
      eleLink.href = href;
      eleLink.click();
      eleLink.remove();
    },
  },
};
</script>
<style lang="scss" scoped>
.form_list {
  &-title {
    &::after {
      content: " ";
      display: inline-block;
      width: 100%;
      height: 1px;
      background-color: rgb(222, 222, 222);
      margin: 10px 0;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 2px;
      height: 15px;
      background-color: #f6a83a !important;
      margin-right: 6px;
    }
  }
}

.showAll_dialog {
  overflow: hidden;

  ::v-deep .el-dialog {
    height: 70%;
    overflow: hidden;

    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
      line-height: 30px;
      padding: 0 15px 60px;
    }

    .el-dialog__footer {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: right;
      background-color: #ffffff;
      padding: 0 20px 0;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}

.marleft10 {
  margin-left: 10px;
}

.icon {
  width: 24px;
  height: 24px;
  &:first-child {
    margin-right: 4px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

::v-deep .el-upload-list__item {
  transition: none !important;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  &-btn {
    cursor: pointer;
    color: #315efb;
  }
}

.block {
  margin-bottom: 10px;
  /* 控制行与行之间的间距 */
}

.image-preview {
  position: relative;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .image-preview-wrapper {
    position: relative;
    width: 148px;
    height: 148px;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 1px dashed #d9d9d9;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    .image-preview-action {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s;
      cursor: pointer;
      text-align: center;
      line-height: 148px;
      border-radius: 10px;

      .el-icon-delete {
        font-size: 32px;
      }
    }
  }
}

.image-preview-wrapper:hover .image-preview-action {
  opacity: 1;
}
</style>