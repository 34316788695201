
<el-dialog title="二维码" :close-on-click-modal="false" :visible.sync="visible" width="20%" style="height: 100vh;"
    center>
    <div style="text-align: center;">
        <el-image style="align-content: center" :src="imgUrl"></el-image>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadQrcode()">下载</el-button>
        <el-button @click="visible = false">关闭</el-button>
    </span>
</el-dialog>
