<template>
  <div style="height: 97%">
    <el-card class="box-card" style="height: 100%" v-if="pageShow">
      <el-form :model="form" :rules="relus" ref="form" label-width="80px">
        <el-form-item label="当前城市">
          <el-select
            style="width: 200px"
            v-model="cityCode"
            placeholder="全国"
            filterable
            @change="getCityDegrees"
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最低度数" prop="min_degree">
          <el-input-number
            v-model.number="form.min_degree"
            @change="minChange"
            :step="5"
            :step-strictly="true"
            :min="15"
            :max="150"
            controls-position="right"
            @keyup.native="UpNumber"
            @keydown.native="UpNumber"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="最高度数" prop="max_degree">
          <el-input-number
            v-model.number="form.max_degree"
            @change="maxChange"
            :step="5"
            :step-strictly="true"
            :min="15"
            :max="150"
            controls-position="right"
            @keyup.native="UpNumber"
            @keydown.native="UpNumber"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div class="footer-btn">
        <el-button type="primary" style="width: 100px" @click="dataFormSubmit()"
          >编辑</el-button
        >
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      form: {},
      cityCode: "",
      pageShow: false,
      relus: {
        max_degree: [
          {
            required: true,
            message: "请输入最高度数",
            trigger: ["blur", "change"],
          },
        ],
        min_degree: [
          {
            required: true,
            message: "请输入最低度数",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  created() {
    if (this.restrictedCityList.length > 0) {
      this.cityCode = this.restrictedCityList[0].cityCode;
      this.getCityDegrees();
    }
  },
  methods: {
    getCityDegrees() {
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/api/config/area/charging/range/get?cityCode=${this.cityCode}`)
        .then((response) => {
          this.hideLoading();
          if (response.data.code != 0) {
            this.pageShow = false;
            this.$message.error(response.data.msg);
            return;
          }
          this.pageShow = true;
          this.form = response.data.data;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        this.showLoading("加载中");
        this.axiosHelper
          .put(
            `/api/config/area/charging/range/update/${this.form.id}`,
            this.form
          )
          .then((res) => {
            this.hideLoading();
            if (res.data.code != 0) return this.$message.error(res.data.msg);
            this.visible = false;
            this.$emit("refreshDataList");
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
            });
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error("加载失败");
          });
      });
    },
    maxChange(val) {
      if (val <= this.form.min_degree) {
        this.$message.warning("最高度数不能低于最低度数");
        this.$nextTick(() => {
          this.form.max_degree = this.form.min_degree + 5;
        });
      }
    },
    minChange(val) {
      if (val >= this.form.max_degree) {
        this.$message.warning("最低度数不能高于最高度数");
        this.$nextTick(() => {
          this.form.min_degree = this.form.max_degree - 5;
        });
      }
    },
    UpNumber(e) {
      e.target.value = e.target.value.replace(/[^\d]/g, "").slice(0, 3);
    },
  },
};
</script>
<style scoped lang="scss">
.box-card {
  margin-top: 15px;
  height: 100%;
}

.footer-btn {
  margin: 50px 0 0 12px;
}
</style>