
<el-dialog
  :title="title + '-配送范围设置'"
  :visible.sync="visible"
  width="80%"
  :before-close="handleClose"
  :close-on-click-modal="false"
  @opened="handleDialogOpened"
>
  <div v-if="titleShow" class="map_title">双击即可结束绘制！</div>
  <div id="map-container">
    <div ref="amap" id="amap" style="height: 100%; width: 100%"></div>
  </div>
  <div class="footer">
    <button class="footer_btn" @click="createPolygon" :disabled="titleShow">
      新建多边形
    </button>
    <el-button v-if="hasPolygon" class="footer_btn" @click="startEdit"
      >开始编辑</el-button
    >
    <el-button v-if="hasPolygon" class="footer_btn" @click="endEdit"
      >结束编辑</el-button
    >
    <el-button v-if="hasPolygon" class="footer_btn" @click="clearPolygon"
      >清空</el-button
    >
    <el-button
      v-if="selectedPolygon"
      class="footer_btn"
      @click="deletePolygon"
      >删除多边形</el-button
    >
    <el-button class="footer_btn" @click="confirm">确认</el-button>
  </div>
</el-dialog>
