<template>
    <el-dialog title="高级搜索" v-dialogDrag :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
        <el-form :model="dataForm" ref="dataForm" label-width="125px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="订单编号：">
                        <el-input v-model.trim="dataForm.order_no" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="微信号：">
                        <el-input v-model.trim="dataForm.wechat_no" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="微信ID：">
                        <el-input v-model.trim="dataForm.open_id" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系方式：">
                        <el-input v-model.trim="dataForm.cellphone" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="支付流水号：">
                        <el-input v-model.trim="dataForm.pay_serial_number" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="支付平台流水号：">
                        <el-input v-model.trim="dataForm.pay_platform_serial_number" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="支付状态：">
                        <el-select v-model="dataForm.pay_status" clearable style="width: 100%">
                            <el-option v-for="item in paymentStatusDim" :key="item.data_value" :value="item.data_value"
                                :label="item.data_name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="支付方式：">
                        <el-select v-model="dataForm.pay_method" clearable style="width: 100%">
                            <el-option v-for="item in paymentModeDim" :key="item.data_value" :value="item.data_value"
                                :label="item.data_name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="clear">清除条件</el-button>
            <el-button type="default" @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import DIM_API from "@/api/dim_api";
    export default {
        name: "pay-search-model",
        props: {
            dialog: {
                type: Boolean,
                default () {
                    return false;
                }
            },
        },
        data() {
            return {
                dialogVisible: false,
                dataForm: {},
                paymentStatusDim: [],
                paymentModeDim: [],
            }
        },
        mounted() {
            this.initDim();
        },
        watch: {
            dialog: {
                immediate: true,
                handler(value) {
                    this.dialogVisible = value;
                }
            },
            dialogVisible: {
                handler(value) {
                    this.$emit("update:dialog", value);
                }
            }
        },
        methods: {
            clear() {
                this.dataForm = {};
            },
            cancel() {
                this.dialogVisible = false;
            },
            confirm() {
                this.dialogVisible = false;
                this.$emit("search", this.dataForm);
            },
            async initDim() {
                let res = await DIM_API.getByType("payment_state")
                this.paymentStatusDim = res.data;
                res = await DIM_API.getByType("payment_mode")
                this.paymentModeDim = res.data;
            },
        }
    }
</script>