
<div class="mod-config">
  <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
    <el-form-item>
      <el-input v-model="dataForm.user_name" placeholder="账户姓名" clearable></el-input>
    </el-form-item>
    <el-form-item>
      <el-input v-model="dataForm.mobile" placeholder="手机号" clearable></el-input>
    </el-form-item>
    <el-form-item>
      <el-button  type="primary" @click="getDataList()">查询</el-button>
      <el-button  type="primary" @click="addOrUpdateHandle()">新增</el-button>
      <!-- <el-button  type="danger" @click="deleteHandle()"
        :disabled="dataListSelections.length <= 0">批量删除</el-button> -->
    </el-form-item>
  </el-form>
  <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
    style="width: 100%;">
    <!-- <el-table-column type="selection" header-align="center" align="center" width="50">
    </el-table-column> -->
    <!-- <el-table-column prop="id" header-align="center" align="center" label="">
    </el-table-column> -->
    <el-table-column prop="user_name" header-align="center" align="center" min-width="120" label="账户名">
    </el-table-column>
    <el-table-column prop="login_name" header-align="center" align="center" min-width="100" label="用户名">
    </el-table-column>
    <!-- <el-table-column prop="login_password" header-align="center" align="center" label="MD5加密后的密文">
    </el-table-column> -->
    <el-table-column prop="mobile" header-align="center"  min-width="100" align="center" label="手机号">
    </el-table-column>
    <el-table-column prop="category" header-align="center" align="center"  label="账号类别">
    </el-table-column>
    <!-- 状态（0无效；1有效） -->
    <!-- <el-table-column prop="status" header-align="center" align="center" label="状态">
    </el-table-column> -->
    <el-table-column prop="remark" header-align="center" align="center" min-width="150" label="备注">
    </el-table-column>
    <!-- <el-table-column prop="createId" header-align="center" align="center" label="">
    </el-table-column>
    <el-table-column prop="createTime" header-align="center" align="center" label="">
    </el-table-column> -->
    <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row)">修改</el-button>
        <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
    :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
    layout="total, sizes, prev, pager, next, jumper">
  </el-pagination>
  <!-- 弹窗, 新增 / 修改 -->
  <sysaccount_add_or_update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></sysaccount_add_or_update>
</div>
