<template>
  <!-- 发放情况表格 -->
  <el-dialog
    width="70%"
    :title="'账户明细'"
    :visible.sync="visible"
    append-to-body
  >
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="收入/支出：">
          <el-select
            v-model="form.type"
            placeholder="全部"
            collapse-tags
            clearable
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="收入" :value="1"></el-option>
            <el-option label="支出" :value="-1"></el-option>
          </el-select>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-form>
    </el-card>
    <el-table ref="tableData" :height="tableHeight" :data="dataTable">
      <el-table-column prop="type" label="名称" min-width="150" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 7">账户充值</span>
          <span v-else-if="scope.row.type === 8">订单支出</span>
          <span v-else-if="scope.row.type === 9">度数赠送</span>
          <span v-else-if="scope.row.type === 10">度数回退</span>
          <span v-else-if="scope.row.type === 11">度数转赠</span>
          <span v-else>其他</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="time"
        label="时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="购买金额"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="degree"
        label="收入/支出(度)"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.type === 7 ||
              scope.row.type === 9 ||
              scope.row.type === 11
            "
            >+{{ scope.row.degree }}</span
          >
          <span v-else>-{{ scope.row.degree }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="activity_name"
        label="活动名称"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="order_no"
        label="关联订单编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column label="前" min-width="150" align="center">
        <el-table-column
          prop="early_total_degree_buy_money"
          label="总充值金额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="early_total_degree"
          label="总充值度数"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="early_available_degree"
          label="度数余额"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table-column>
      <el-table-column label="后" min-width="150" align="center">
        <el-table-column
          prop="cur_total_degree_buy_money"
          label="总充值金额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cur_total_degree"
          label="总充值度数"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cur_available_degree"
          label="度数余额"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-dialog>
</template>
<script>
import { operateAdd } from "@/api/system/user-api";

export default {
  props: {
    userId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      form: {
        type: "",
      },
      visible: false,
      tableHeight: "50vh",
      dataTable: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      user_id: "",
    };
  },
  methods: {
    async search() {
      this.page_index = 1;
      this.$emit("getList");
    },
    clear() {
      this.form = {
        type: "",
      };
      this.createTime = [];
      this.page_index = 1;
      this.$emit("getList");
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.$emit("getList");
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.$emit("getList");
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 3,
        operate_parameter: JSON.stringify(this.form),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      this.$confirm("是否要导出全部数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        if (this.page_total > 20000) {
          await this.$confirm(
            `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          );
        }
        this.showLoading("数据加载中...");
        this.axiosHelper
          .get(`/v0.1/api/operate/degree/detail/${this.userId}/export`, {
            params: this.form,
          })
          .then((response) => {
            this.hideLoading();
            this.exportData(response.data.data);
          })
          .catch(() => {
            this.hideLoading();
          });
      });
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "名称",
          "时间",
          "收入/支出（度）",
          "活动名称",
          "关联订单编号",
          "总充值金额（前）",
          "总充值度数（前）",
          "度数余额（前）",
          "总充值度数（后）",
          "总充值度数（后）",
          "度数余额（后）",
        ];
        const filterVal = [
          "type",
          "time",
          "degree",
          "activity_name",
          "order_no",
          "early_total_degree_buy_money",
          "early_total_degree",
          "early_available_degree",
          "cur_total_degree_buy_money",
          "cur_total_degree",
          "cur_available_degree",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "度数钱包明细导出",
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j == "type") {
            if (v[j] == 7) {
              return "账户充值";
            } else if (v[j] == 8) {
              return "订单支出";
            } else if (v[j] == 9) {
              return "度数赠送";
            } else if (v[j] == 10) {
              return "度数回退";
            } else {
              return "其他";
            }
          } else if (j == "degree") {
            if (v["type"] == 7 || v["type"] == 9) {
              return `+${v[j]}`;
            } else {
              return `-${v[j]}`;
            }
          } else {
            return v[j];
          }
        })
      );
    },
  },
};
</script>
<style lang="scss">
</style>