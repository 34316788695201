
<div class="height:100%;">
  <el-card class="box-card" style="height: 10%">
    <el-form :inline="true">
      <el-form-item label="城市">
        <el-select
          v-model="form.cityCode"
          placeholder="全部"
          collapse-tags
          clearable
          filterable
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理商">
        <el-input
          v-model="form.agentName"
          placeholder="请输入代理商"
          clearable
          maxlength="35"
        />
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-table ref="tableData" :height="tableHeight" :data="dataTable">
      <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="agent_name"
        label="代理商"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="update_time"
        label="修改时间"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column label="上门充电" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            @click="updateHandle(scope.row, '1')"
            type="text"
            size="small"
            >设置
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="应急补电" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            @click="updateHandle(scope.row, '2')"
            type="text"
            size="small"
            >设置
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <activityPageAddModal
    ref="addOrUpdate"
    :editState="editState"
    @refreshDataList="search"
  >
  </activityPageAddModal>
  <activityPageView
    v-if="activityPageViewVisible"
    ref="activityPageView"
  ></activityPageView>
  <delivertySettingModal
    ref="delivertySettingModal"
    @mounted="onChildMounted"
    @getList="getList"
  ></delivertySettingModal>
</div>
