<template>
    <el-dialog :title="!dataForm.id ? '新增补贴' : '修改'" :close-on-click-modal="false" :visible.sync="visible" width="40%">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" >
        <el-form-item label="城市" prop="cityCode">
          <el-select v-model="dataForm.cityCode" placeholder="请选择城市" @change="cityOpsChange">
            <el-option
              v-for="item in cityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代理商名称" disabled>
          <el-input v-model="agent.agentName" disabled></el-input>
        </el-form-item>
        <el-form-item label="代理商手机号" >
          <el-input v-model="agent.agentPhone"  disabled></el-input>
        </el-form-item>
        <el-form-item label="申请人手机号" prop="applyPhone">
          <el-input v-model="dataForm.applyPhone" placeholder="申请人手机号"></el-input>
        </el-form-item>
        <el-form-item label="补贴金额" prop="amount">
          <el-input v-model="dataForm.amount" placeholder="补贴金额"></el-input>
        </el-form-item>
        <el-form-item label="审批编号" prop="approvalNum">
          <el-input v-model="dataForm.approvalNum" placeholder="审批编号"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="dataForm.remark" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" >
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
import OPERATE_API from "../../../api/operate_api.js";
import agentApi from "../../../api/agent_api.js";
import promotionApi from "../../../api/promotion_api.js";
  export default {

    data() {
      return {
        visible: false,
        cityList:[],
        dataForm: {
          id: undefined,
          cityCode: '',
          applyPhone:'',
          agentId:'',
          amount:"",
          approvalNum:"",
          remark:'',
        },
        agent:{
          agentName:'',
          agentPhone:''
        },
        dataRule: {

          cityCode: [
            { required: true, message: '城市不能为空', trigger: 'blur' }
          ],
          applyPhone: [
            { required: true, message: '申请人手机号不能为空', trigger: 'blur' },
            { validator: this.validateCellphone, trigger: 'blur' } // 添加手机号格式校验

          ],
          amount:[
             { required: true, message: '金额不能为空' },

          ],
          // { type: 'number', message: '金额必须为数字值'}
          approvalNum: [
            { required: true, message: '审批编号不能为空', trigger: 'blur' }
          ],
        }
      }
    },
    async created(){
     await this.getCityList();
    },
    methods: {
      validateCellphone(rule, value, callback) {
      const pattern = /^[1-9]\d{10}$/; // 以1-9开头的11位数字
          if (!pattern.test(value)) {
            callback(new Error('手机号格式不正确'));
          } else {
            callback(); // 格式正确，通过校验
          }
        },
      init(row) {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (row) {
            // this.dataForm= {
            //   id: undefined,
            //   cityCode: '',
            //   cellphone:'',
            //   promotionId:'',
            //   thirtyCoupon:'',
            //   emergencyCoupon:'',
            //   tenCoupon:'',
            //   remark:'',
            //   dateRange:''
            // }
            // this.dataForm.id = row.id
          } else {
            this.dataForm=  {
              id: undefined,
              cityCode: '',
              applyPhone:'',
              agentId:'',
              amount:"",
              approvalNum:"",
              remark:'',
            }
            this.agent={
              agentName:'',
              agentPhone:''
            }
          }
        })
      },
          // 获取城市选择列表
    async getCityList() {

      let res = await OPERATE_API.getCityList();
      if (res.data.code != 0) {
        this.$message.error({
          message: "加载城市列表错误"
        });
        return
      }
      this.cityList = res.data.cityList
    },

     async cityOpsChange(){
        //回填代理商信息
       if(!this.dataForm.cityCode){
        this.agent={
              agentName:'',
              agentPhone:''
            }
        this.dataForm.agentId=""
        return 
       }
       let res = await agentApi.queryByCityCode({cityCode:this.dataForm.cityCode})
       if (res.data.code != 0) {
        this.agent={
              agentName:'',
              agentPhone:''
            }
        this.dataForm.agentId=""
        this.$message.error({
          message: "加载城市列表错误"
        });
        return
      }
      let agent=res.data.agent
      this.agent.agentName=agent.agent_name
      this.agent.agentPhone=agent.call_phone
      this.dataForm.agentId=agent.id
     },

      // 表单提交
      dataFormSubmit() {
        this.$refs['dataForm'].validate(async valid => {
          if (!valid) return
          if(this.dataForm.amount<=0){
            this.$message.error({
              message: "金额必须大于0"
            });
            return
          }
          if(!this.dataForm.agentId){
            this.$message.error({
              message: "代理商不能为空"
            });
            return
          }
          if(!this.dataForm.id){
            let res = await promotionApi.saveSubsidy(this.dataForm)
            if (res.data.code != 0) {
                this.$message.error(res.data.msg)
                return
            }else{
              this.visible = false
              this.$emit('refreshDataList')
              this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                })
            }
          }
        })
      }
    }
  }
  </script>
<style scoped lang="scss">
::v-deep .el-dialog__body{
  // padding-right: 30px !important;
  padding-bottom: 0px;
  padding-left: 0px;
}

::v-deep .el-dialog__footer {
    padding: 0px 20px 20px;
}
</style>
  