<template>
  <el-dialog
    :title="this.editState == 'edit' ? '编辑' : '新增'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="140px"
    >
      <el-form-item label="商户名称：" prop="merchant_name">
        <el-input
          v-model.trim="dataForm.merchant_name"
          placeholder="必填"
          clearable
          maxlength="35"
        />
      </el-form-item>
      <el-form-item label="城市：" prop="city_code">
        <el-select
          v-model="dataForm.city_code"
          placeholder="请选择"
          collapse-tags
          clearable
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商户负责人：" prop="merchant_manager">
        <el-input
          v-model.trim="dataForm.merchant_manager"
          clearable
          maxlength="20"
        />
      </el-form-item>
      <el-form-item label="商户电话：" prop="merchant_tel">
        <el-input
          v-model.trim="dataForm.merchant_tel"
          clearable
          clearable
          maxlength="11"
          oninput="value=value.replace(/[^0-9]/g,'')"
        />
      </el-form-item>
      <el-form-item label="有效期：" required prop="validTime">
        <el-date-picker
          v-model="dataForm.validTime"
          type="daterange"
          range-separator="至"
          start-placeholder="起始时间"
          end-placeholder="截止时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import agentApi from "../../../api/agent_api";
import axios from "../../../utils/axiosHelper";
import OPERATE_API from "../../../api/operate_api";
import { mapState } from "vuex";
export default {
  // name: "AddOrUpdate",
  props: {
    editState: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      visible: false,
      cityList: [],
      formItem: {},
      dataForm: {
        id: "",
        merchant_name: "",
        city_code: "",
        merchant_manager: "",
        merchant_tel: "",
        valid_time_start: "",
        valid_time_end: "",
        validTime: [],
      },
      validTime: [],
      dataRule: {
        merchant_name: [
          { required: true, message: "请输入商户名称", trigger: "blur" },
        ],
        city_code: [
          { required: true, message: "请选择城市", trigger: "change" },
        ],
        validTime: [
          {
            type: "array",
            required: true,
            message: "请输入有效时间",
            fields: {
              //tpye类型试情况而定,所以如果返回的是date就改成date
              0: { type: "string", required: true, message: "请选择起始时间" },
              1: { type: "string", required: true, message: "请选择截止时间" },
            },
          },
        ],
      },
    };
  },
  async created() {},
  async mounted() {},
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  methods: {
    init(row) {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (row) {
          console.log(row);
          this.formItem = row;

          this.dataForm.id = row.id;
          this.dataForm.merchant_name = row.merchant_name;
          this.dataForm.city_code = row.city_code;
          this.dataForm.merchant_manager = row.merchant_manager;
          this.dataForm.merchant_tel = row.merchant_tel;
          this.dataForm.validTime[0] = row.valid_time_start;
          this.dataForm.validTime[1] = row.valid_time_end;
        } else {
          this.dataForm.id = "";
          this.dataForm.merchant_name = "";
          this.dataForm.city_code = "";
          this.dataForm.merchant_manager = "";
          this.dataForm.merchant_tel = "";
          this.dataForm.validTime = [];
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (!valid) return;
        let dataFormStr = JSON.stringify(this.dataForm);
        this.dataForm.valid_time_start = this.dataForm.validTime[0];
        this.dataForm.valid_time_end = this.dataForm.validTime[1];

        this.showLoading("加载中");
        if (this.editState == "add") {
          axios
            .post("/api/recom/merchant", this.dataForm)
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg);
                return;
              }
              this.visible = false;
              this.$emit("refreshDataList");
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            });
        } else if (this.editState == "edit") {
          axios
            .put("/api/recom/merchant", this.dataForm)
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg);
                return;
              }
              this.visible = false;
              this.$emit("refreshDataList");
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            });
        }
      });
    },
  },
};
</script>
