import axios from "../utils/axiosHelper";
import Vue from 'vue'
import {Message} from 'element-ui'
const FILE_API = {};
FILE_API.upload = (params, upload_obj) => {
    return new Promise((resolve, reject) => {
        axios.post(
            '/api/file/upload',
            params,
            {
                timeout: 1000 * 60 * 10,
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: progressEvent => {
                    let percent = (progressEvent.loaded / progressEvent.total * 100) | 0;
                    if (upload_obj) {
                        upload_obj.onProgress({percent: percent})
                    }
                }
            }
        ).then(response => {
            resolve(response);
        }).catch(error => {
            switch (error.response.data.code) {
                case 'FILE_SPACE/DISK_SPACE_FULL':
                    Message.warning("文件空间已满，请扩大文件空间！");
                    break;
                case 'PMS/UPLOAD_RENAME':
                    Message.warning(error.response.data.message);
                    break;
                case 'PMS/FILE_NAME_NONE':
                    Message.warning(error.response.data.message);
                    break;
                case 'PMS/UPLOAD_FAIL':
                    Message.warning(error.response.data.message);
                    break;
                default:
                    break;
            }
            reject(error);
        })
    });
}
FILE_API.copy = (id) => {
    return axios.post(
        `api/file/copy/${id}`
    ).then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    }).catch(_ => {
        Message.error("文件复制失败");
    });
};
FILE_API.downloadPost= (data, url) => {
    return new Promise ((resolve, reject) => {
        axios.post(
            url, data,
            {responseType: 'blob'}
        ).then(response => {
            let strArr = response.headers['content-disposition'].split("=");
            if (!response) {
                return
            }
            let url = window.URL.createObjectURL(response.data);
            let link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.setAttribute('download', decodeURI(strArr[1]));
            document.body.appendChild(link);
            link.click();
            resolve();
        }).catch(async e => {
            let error_code = null;
            // 在blob返回值中data中正常是没有code，错误异常隐藏在data中的promise，所以需要用await
            await e.response.data.text().then(x => {error_code = JSON.parse(x).code;});
            switch (error_code) {
                case 'FILE_DOWNLOAD/NONE':
                    Message.warning("文件不存在！");
                    break;
                case 'FILE_SPACE/DISK_SPACE_FULL':
                    Message.warning("文件空间已满，请扩大文件空间！");
                    break;
                default:
                    e.response.data.code = error_code;
                    reject(e);
                    break;
            }
        })
    });
};
export default FILE_API;