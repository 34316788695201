
<div>
  <el-card class="box-card" style="margin-top: 15px; padding-top: 20px">
    <el-form
      :label-position="'right'"
      label-width="200px"
      :model="configForm"
    >
      <el-form-item label="城市">
        <el-select
          style="width: 200px"
          v-model="cityCode"
          placeholder="全国"
          filterable
          @change="getConfig"
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-checkbox-group v-model="checkList">
        <div class="timeList">
          <div
            class="timeList-item"
            v-for="item in orderCountScope"
            :key="item.time"
          >
            <el-checkbox :label="item.time"> </el-checkbox>
            <el-input
              class="timeList-item-input"
              v-model="item.num"
              oninput="value=value.replace(/[^0-9]/g,'')"
              :disabled="edit_disable"
              maxlength="3"
            ></el-input>
          </div>
        </div>
      </el-checkbox-group>
      <el-form-item label="批量设置运力" label-width="auto">
        <el-input
          style="width: 300px; margin-right: 20px"
          v-model="order_count"
          oninput="value=value.replace(/[^0-9]/g,'')"
          :disabled="edit_disable"
          maxlength="3"
        ></el-input>
        <el-button type="primary" plain @click="allSettled">全选</el-button>
        <el-button type="primary" plain @click="setting">设置</el-button>
      </el-form-item>
    </el-form>

    <div class="btn_css">
      <el-button
        type="primary"
        style="margin-right: 30px"
        @click="edit_disable = false"
        >编辑</el-button
      >
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-card>
</div>
