<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="用户编号：">
          <el-input
            v-model.trim="form.userNumber"
            placeholder="请输入用户编号"
            clearable
            maxlength="10"
            oninput="value=value.replace(/[^0-9]/g,'')"
          />
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input
            v-model.trim="form.cellphone"
            placeholder="请输入手机号"
            clearable
          />
        </el-form-item>
        <el-form-item label="城市：">
          <el-select
            v-model="form.cityCode"
            placeholder="全部"
            multiple
            collapse-tags
            clearable
            filterable
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>度数钱包</span>
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        :height="tableHeight"
        :data="dataTable"
        show-summary
        :summary-method="getSummaries"
        @sort-change="handleSortChange"
      >
        <el-table-column label="序号" min-width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="first_city_name"
          label="城市"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="available_degree"
          label="度数余额（度）"
          sortable="custom"
          min-width="160"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="total_degree_buy_money"
          label="购买度数总金额（元）"
          sortable="custom"
          min-width="170"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="total_degree"
          label="购买度数合计（度）"
          sortable="custom"
          min-width="180"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="busin_transfer_total_degree"
          label="B端转赠度数合计"
          sortable="custom"
          min-width="160"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="degree_unit_price"
          label="实时度数单价（元/度）"
          sortable="custom"
          min-width="170"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="use_degree"
          label="已使用度数（度）"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="total_use_degree_order_num"
          label="累计使用度数下单数"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              @click="handelDetails(scope.row)"
              type="text"
              size="small"
              >明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <userDegreesWalletModal
      ref="detailsModal"
      @getList="getUserDetails"
      :userId="user_id"
    >
    </userDegreesWalletModal>
  </div>
</template>
<script>
import userDegreesWalletModal from "./model/user-degrees-wallet-modal.vue";
import agentApi from "@/api/agent_api";
import * as XLSX from "xlsx";
import { preciseMultiplication } from "@/utils/count";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";

export default {
  components: {
    userDegreesWalletModal,
  },
  data() {
    return {
      form: {
        cellphone: "",
        cityCode: [],
        field: "",
        type: "",
        userNumber: "",
      },
      statusOptions: [
        { label: "休息", value: 0 },
        { label: "营业", value: 1 },
      ],
      dataTable: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      addOrUpdateVisible: false,
      user_id: "",
    };
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.search();
  },
  mounted() {},
  methods: {
    handleSortChange(column) {
      this.form.field = column.prop;
      const sortingType = column.order;
      sortingType == "ascending"
        ? (this.form.type = "ASC")
        : (this.form.type = "DESC");
      this.loadDataTable();
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {
        cellphone: "",
        cityCode: [],
        field: "",
        type: "",
        userNumber: "",
      };
      this.createTime = [];
      this.page_index = 1;
      this.loadDataTable();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        ...this.form,
        cityCode: this.form.cityCode.join(","),
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/v0.1/api/operate/degree/${offset}/${limit}`, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          let array = response.data.data.items;
          array.forEach((item) => {
            item.total_degree = preciseMultiplication(
              item.total_degree - item.busin_transfer_total_degree
            );
          });
          this.dataTable = response.data.data.items;
          this.page_total = response.data.data.total;
          //   this.$nextTick(() => {
          //     this.$refs["dataTable"].doLayout();
          //   });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    handelDetails(row) {
      this.user_id = row.user_id;
      this.getUserDetails();
      this.$refs.detailsModal.visible = true;
    },
    getUserDetails() {
      let params = {
        ...this.$refs.detailsModal.form,
      };
      let limit = this.$refs.detailsModal.page_size;
      let offset =
        this.$refs.detailsModal.page_size *
        (this.$refs.detailsModal.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(
          `/v0.1/api/operate/degree/detail/${this.user_id}/${offset}/${limit}`,
          {
            params: params,
          }
        )
        .then((response) => {
          this.hideLoading();
          this.$refs.detailsModal.dataTable = response.data.data.items;
          this.$refs.detailsModal.page_total = response.data.data.total;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (
          column.property === "available_degree" ||
          column.property === "total_degree_buy_money" ||
          column.property === "total_degree" ||
          column.property === "use_degree" ||
          column.property === "total_use_degree_order_num"
        ) {
          let total = data.reduce((prev, cur) => {
            return prev + Number(cur[column.property]);
          }, 0);
          sums[index] = Number(total.toFixed(2));
        } else {
          sums[index] = "";
          if (index === 0) {
            sums[index] = "合计";
          }
        }
      });

      return sums;
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 8,
        operate_parameter: JSON.stringify({
          ...this.form,
          cityCode: this.form.cityCode.join(","),
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      this.$confirm("是否要导出全部数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        if (this.page_total > 20000) {
          await this.$confirm(
            `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          );
        }
        this.showLoading("数据加载中...");
        this.axiosHelper
          .get("/v0.1/api/operate/degree/export", {
            params: {
              ...this.form,
              cityCode: this.form.cityCode.join(","),
            },
          })
          .then((response) => {
            this.hideLoading();
            let array = response.data.data;
            array.forEach((item) => {
              item.total_degree = preciseMultiplication(
                item.total_degree - item.busin_transfer_total_degree
              );
            });
            this.exportData(array);
          })
          .catch(() => {
            this.hideLoading();
          });
      });
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "手机号码",
          "城市",
          "度数余额（度）",
          "购买度数总金额（元）",
          "购买度数合计（度）",
          "B端转赠度数合计",
          "实时度数单价（元/度）",
          "已使用度数（度）",
          "累计使用度数下单数",
          "用户编号",
        ];
        const filterVal = [
          "cellphone",
          "first_city_name",
          "available_degree",
          "total_degree_buy_money",
          "total_degree",
          "busin_transfer_total_degree",
          "degree_unit_price",
          "use_degree",
          "total_use_degree_order_num",
          "user_number",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "度数钱包导出",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}
</style>