
<div>
  <el-card class="iconShow">
    <el-row :gutter="24">
      <el-col :span="8">
        <div
          class="view-block left-line-blue"
          style="border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 0"
        >
          <div class="outImag1">
            <img class="imgCss" src="../../assets/img/subsidy-total.png" />
          </div>
          <div class="textCss">
            <p class="view-count" style="text-align: center">
              {{ statistics.totalAmount }}
            </p>
            <p class="view-add" style="text-align: center">全国补贴池总额</p>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div
          class="view-block left-line-blue"
          style="border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 0"
        >
          <div class="outImag1">
            <img
              class="imgCss"
              src="../../assets/img/subsidy-remaining.png"
            />
          </div>
          <div class="textCss">
            <!-- <span class="view-title" style="margin-left: 40px">80033</span> -->
            <p class="view-count" style="text-align: center">
              {{ statistics.remainingAmount }}
            </p>
            <p class="view-add" style="text-align: center">全国补贴池余额</p>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div
          class="view-block left-line-blue"
          style="border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 0"
        >
          <div class="outImag1">
            <img class="imgCss" src="../../assets/img/subsidy-used.png" />
          </div>
          <div class="textCss">
            <!-- <span class="view-title" style="margin-left: 40px">80033</span> -->
            <p class="view-count" style="text-align: center">
              {{ statistics.usedAmount }}
            </p>
            <p class="view-add" style="text-align: center">
              全国本月已用金额
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-card>
  <el-card class="search">
    <el-form :model="dataForm">
      <el-row :gutter="24">
        <el-col :span="4">
          <el-form-item label="城市" label-width="70px">
            <el-select
              v-model="dataForm.areaCode"
              placeholder="请选择城市"
              clearable
              filterable
            >
              <el-option
                v-for="item in restrictedCityList"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="代理商名称" label-width="150px">
            <el-input v-model="dataForm.agentName" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="代理商联系方式" label-width="160px">
            <el-input v-model="dataForm.callPhone" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="开城日期" label-width="120px">
            <el-date-picker
              v-model="dataForm.openingDate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item>
            <el-button type="primary" @click="searchBtn()">查询</el-button>
            <el-button @click="clear()">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
  <el-card>
    <div class="tableHeaderCss">
      <div>城市补贴列表</div>
      <div>
        <el-button type="primary" @click="addOrUpdateHandle" v-if="isShowBtn"
          >新增</el-button
        >
        <el-button type="primary" @click="exportExcel">导出</el-button>
      </div>
    </div>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
      max-height="370"
    >
      <el-table-column
        prop="fullname"
        header-align="center"
        align="center"
        min-width="100"
        label="城市"
      >
      </el-table-column>
      <el-table-column
        prop="agent_name"
        header-align="center"
        align="center"
        min-width="150"
        label="代理商名称"
      >
      </el-table-column>
      <el-table-column
        prop="call_phone"
        header-align="center"
        align="center"
        min-width="120"
        label="代理商联系方式"
      >
      </el-table-column>
      <el-table-column
        prop="opening_date"
        header-align="center"
        align="center"
        min-width="120"
        label="开城日期"
        :formatter="formatOpeningDate"
      >
      </el-table-column>
      <el-table-column
        prop="total_amount"
        header-align="center"
        align="center"
        min-width="150"
        label="合计补贴总额(元)"
      >
      </el-table-column>
      <el-table-column
        prop="used_amount"
        header-align="center"
        align="center"
        min-width="150"
        label="已使用额度(元)"
      >
      </el-table-column>
      <el-table-column
        prop="remaining_amount"
        header-align="center"
        align="center"
        min-width="150"
        label="剩余补贴额度(元)"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="100"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="toCitySubsidy(scope.row)"
            >补贴详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </el-card>
  <!-- 弹窗, 新增 / 修改 -->
  <subsidy_add_or_update
    v-if="addOrUpdateVisible"
    ref="addOrUpdate"
    @refreshDataList="getDataList"
  ></subsidy_add_or_update>
</div>
