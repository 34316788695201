
<div
  class="role-body"
  v-loading="loading"
  element-loading-text="正在加载请稍后..."
>
  <div class="role-header">
    <div class="role-header-left">
      <el-button
        class="rewrite-button-style"
        size="small"
        @click="addRole"
      >新增</el-button>
      <el-button
        class="rewrite-button-style"
        size="small"
        @click="deleteRole(multiple_selection)"
      >删除</el-button>
    </div>
    <div class="role-header-right">
      <el-input
        style="width: 100%"
        v-model="search_value.keyword"
        placeholder="请输入编号或名称进行搜索"
        clearable
      >
        <el-button
          slot="append"
          @click="search"
        >搜索</el-button>
      </el-input>
    </div>
  </div>
  <div class="role-center">
     
      <el-table
        :data="tableData"
        max-height="80vh"
        height="70vh"
        border
        header-align="center"
        element-loading-customClass="personal-loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(204, 204, 204, 0.6)"
        class="refTable"
        ref="refTable"
        default-expand-all
        :header-row-style="header_row_style"
        :header-cell-style="header_cell_style"
        :cell-style="cell_style"
        :style="table_style"
        @selection-change="handleSelectionChange"
        @select-all="handleSelectionChange"
        row-key="id"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
          :selectable="handleSelectable"
          :reserve-selection="true"
          fixed="left"
        >
        </el-table-column>
        <el-table-column
          type="index"
          width="50"
          label="序号"
        ></el-table-column>
        <el-table-column
          width="200"
          label="编号"
          prop="code"
        ></el-table-column>
        <el-table-column
          width="200"
          label="名称"
          prop="role_name"
        ></el-table-column>
        <el-table-column
          width="200"
          label="类型"
          prop="type"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.type ==3">
              系统角色
            </div>
            <div v-if="scope.row.type ==4">
              组织角色
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="描述"
          prop="description"
        ></el-table-column>
        <el-table-column
          width="100"
          label="状态"
          prop="status"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status ==1">
              启用
            </div>
            <div v-else>
              禁用
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          label="是否可以删除"
          prop="allow_deleted"
        >
        <template slot-scope="scope">
            <div v-if="scope.row.allow_deleted ==true">
              是
            </div>
            <div v-else>
              否
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="320"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="editRole(scope.row)"
            >编辑</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="setRoleUsers(scope.row)"
            >设置角色用户</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="checkRoleUsers(scope.row)"
            >查看角色用户
            </el-button>
          </template>
        </el-table-column>
      </el-table>
     
  </div>
  <div class="role-footer">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="current_page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next"
      :total="total_count"
    >
    </el-pagination>
  </div>
  <ex-dialog
    :title="title"
    :visible.sync="dialog_visible"
    v-if="dialog_visible"
    :width="900"
    :height="450"
    :show-fullscreen-icon="true"
    :append-to-body="true"
  >
    <el-scrollbar
      style="height: 100%; margin-right: -20px"
      wrapStyle="overflow-x:hidden;padding-right:20px;"
      viewStyle=""
    >
      <role-edit
        :action="action"
        :role_="select_role"
        @close="closeDialog"
        @refresh="refresh"
      ></role-edit>
    </el-scrollbar>
  </ex-dialog>
  <ex-dialog
    title="设置角色用户"
    :visible.sync="role_user_dialog_visible"
    v-if="role_user_dialog_visible"
    :width="1100"
    :height="670"
    :show-fullscreen-icon="true"
    :append-to-body="true"
  >
    <role-user
      :role_id="select_role_id"
      @close="closeRoleUserDialog"
      @submit="submit"
    ></role-user>
  </ex-dialog>
  <ex-dialog
    title="查看角色用户"
    :visible.sync="role_users_dialog_visible"
    v-if="role_users_dialog_visible"
    :width="1100"
    :height="670"
    :show-fullscreen-icon="true"
    :append-to-body="true"
  >
    <role-table
      :role_id="select_role_id"
      @close="closeRoleUsersDialog"
    ></role-table>
  </ex-dialog>

</div>
