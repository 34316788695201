<template>
  <el-dialog
    :title="'新增注册用户数-' + this.dataItem.week_name"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <div class="height:100%;">
      <el-card class="box-card" style="height: 20%">
        <div class="text item">
          <el-form
            ref="form"
            :model="form"
            label-width="110px"
            class="demo-form-inline"
          >
            <el-row class="search">
              <el-form-item label="城市" style="width: 100%">
                <el-select
                  v-model="form.firstCityCode"
                  placeholder="全国"
                  collapse-tags
                  clearable
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.cityCode"
                    :label="item.cityName"
                    :value="item.cityCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-col class="elCol">
                <el-form-item label="手机号码：">
                  <el-input
                    v-model.trim="form.cellphone"
                    placeholder="请输入"
                    clearable
                  />
                </el-form-item>
              </el-col>
              <el-col class="elColBtn">
                <el-button
                  style="margin-right: 15px"
                  type="primary"
                  @click="search"
                  >查询</el-button
                >
                <el-button type="default" @click="clear">重置</el-button>
                <!-- </el-form-item> -->
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card>
      <el-card class="box-card">
        <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
          <el-table-column
            prop="fullname"
            label="城市"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="cellphone"
            label="客户手机号"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="注册时间"
            min-width="150"
            align="center"
          ></el-table-column>
        </el-table>
        <el-footer height="32px" style="margin-top: 10px">
          <el-pagination
            background
            layout="total,sizes, prev, pager, next,jumper"
            :total="page_total"
            :page-size="page_size"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :current-page.sync="page_index"
          ></el-pagination>
        </el-footer>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import OPERATE_API from "../../../api/operate_api";

export default {
  props: {
    dataItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      visible: false,
      form: {
        firstCityCode: "",
        cellphone: "",
      },
      cityList: [],
      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
    };
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.getCityList();
  },
  mounted() {},
  methods: {
    init() {
      this.visible = true;
      this.dataTable = [];
      this.clear();
      if (this.$parent.form.firstCityCode) {
        this.form.firstCityCode = this.$parent.form.firstCityCode;
      }
      this.search();
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    // 获取城市选择列表
    async getCityList() {
      let res = await OPERATE_API.getCityList();
      if (res.data.code != 0) {
        this.$message.error({
          message: "加载城市列表错误",
        });
        return;
      }
      this.cityList = res.data.cityList;
    },
    search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {
        firstCityCode: "",
        cellphone: "",
      };
      this.page_index = 1;
      this.loadDataTable();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    loadDataTable() {
      this.form.starTime = this.dataItem.star_time;
      this.form.endTime = this.dataItem.end_time;

      let params = {
        ...this.form,
        source: "pc",
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/manage/newAdd/user/list/" + offset + "/" + limit, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.items;
          this.page_total = response.data.total;
          this.$nextTick(() => {
            this.$refs["dataTable"].doLayout();
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
</style>