
<el-dialog title="实时配送位置" :close-on-click-modal="false" :visible.sync="visible" width="60%" append-to-body>

    <div id="amap-container" style="width: 100%;height: 600px;"></div>

    <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">关 闭</el-button>
        <el-button type="primary" @click="refresh()">刷 新</el-button>
    </span>
</el-dialog>
