
<el-dialog
  :title="'特殊配置-' + form.city_name"
  width="800px"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <el-form :model="form" :rules="relus" ref="form" label-width="200px">
    <el-form-item label="电费单价" prop="electric_unit_price">
      <el-input v-model="form.electric_unit_price" style="width: 150px">
        <template slot="suffix">元/度</template>
      </el-input>
    </el-form-item>
    <el-form-item label="运维工单" prop="maintenance_display_status">
      <el-radio-group v-model="form.maintenance_display_status">
        <el-radio :label="0">关闭</el-radio>
        <el-radio :label="1">开启</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      label="订单进入调度时间设置"
      prop="dispatch_order_delay_time"
    >
      <el-input v-model="form.dispatch_order_delay_time" style="width: 150px">
        <template slot="suffix">分钟</template>
      </el-input>
    </el-form-item>
    <el-form-item label="每天每人最大下单数" prop="dispatch_order_delay_time">
      <el-input
        v-model="form.max_place_order"
        style="width: 150px"
        maxlength="2"
      ></el-input>
    </el-form-item>
    <el-form-item label="接单模式" prop="name">
      <el-select v-model="form.operate_mode" style="width: 150px">
        <el-option :value="1" label="抢单模式"></el-option>
        <el-option :value="2" label="抢单+调度模式"></el-option>
        <el-option :value="3" label="调度模式"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="是否开启认证配送员" prop="name">
      <el-radio-group v-model="form.delivery_open_authentication">
        <el-radio :label="0">关闭</el-radio>
        <el-radio :label="1">开启</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      label="确认订单是否开启人脸认证"
      prop="name"
      v-if="form.delivery_open_authentication == 1"
    >
      <el-radio-group v-model="form.confirm_order_open_authentication">
        <el-radio :label="0">关闭</el-radio>
        <el-radio :label="1">开启</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="自助充电单价" prop="self_charge_unit_price">
      <el-input
        v-model="form.self_charge_unit_price"
        style="width: 150px"
      ></el-input>
    </el-form-item>
    <el-form-item label="设备预留比例" prop="surplus_electric_tip_ratio">
      <el-input
        v-model="form.surplus_electric_tip_ratio"
        style="width: 150px"
      >
        <span slot="suffix">%</span>
      </el-input>
    </el-form-item>
    <el-form-item label="自助充电最低充电度数" prop="self_charge_min_degree">
      <el-input v-model="form.self_charge_min_degree" style="width: 150px">
        <span slot="suffix">度</span>
      </el-input>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    <el-button @click="visible = false">取消</el-button>
  </span>
</el-dialog>
