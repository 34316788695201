<!--
 * @Author: ljf
 * @Date: 2022-06-14 09:09:07
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-20 17:43:47
 * @FilePath: \lxm-admin\src\views\system\user\UserEdit.vue
 * @Description: 
 * 
-->
<template>
  <ex-dialog
    :title="title"
    :visible.sync="dialog_visible"
    :width="900"
    :height="500"
    :show-fullscreen-icon="true"
    :append-to-body="true"
    :before-close="cancel"
  >
    <el-scrollbar
      style="height: 100%; margin-right: -20px"
      wrapStyle="overflow-x:hidden;padding-right:20px;"
      viewStyle=""
    >
      <div class="user-edit">
        <el-form
          ref="userForm"
          v-loading="loading"
          :model="user"
          label-width="130px"
          :rules="rules"
          inline
        >
          <el-form-item label="手机号码" prop="cellphone">
            <el-input
              v-model="user.cellphone"
              placeholder="请输入手机号码"
              maxlength="11"
              show-word-limit
              disabled
              style="width: 100%"
              :readonly="this.action == 'edit'"
            ></el-input>
          </el-form-item>
          <el-form-item label="微信昵称" prop="real_name">
            <el-input
              v-model="user.real_name"
              placeholder="请输入微信昵称"
              style="width: 100%"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="注册推荐人类型" prop="recommend_type">
            <el-select
              v-model="user.recommend_type"
              placeholder=""
              collapse-tags
              clearable
              :disabled="this.action == 'edit'"
            >
              <el-option
                v-for="item in recommendTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="推荐方：" prop="reference_name">
            <el-input
              v-model="user.reference_name"
              placeholder="请输入微信昵称"
              maxlength="20"
              style="width: 100%"
              show-word-limit
              :disabled="this.action == 'edit'"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户编号" prop="user_number">
            <el-input
              v-model="user.user_number"
              placeholder="请输入用户编号"
              maxlength="10"
              oninput="value=value.replace(/[^0-9]/g,'')"
              disabled
              :readonly="this.action == 'edit'"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input
              v-model="user.email"
              placeholder="请输入邮箱"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-select
              v-model="user.sex"
              value-key="value"
              placeholder="请选择性别"
              clearable
              filterable
            >
              <el-option
                v-for="item in sex_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="黑名单">
            <el-select
              v-model="user.blacklist"
              value-key="value"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in if_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="城市">
            <el-select
              v-model="user.first_city_code"
              value-key="value"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in cityList"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否脱敏" prop="desensi" v-if="isShowBtn">
            <el-select
              v-model="user.desensi"
              value-key="value"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in if_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="每日可下单数">
            <el-input
              v-model="user.daily_person_max_order_num"
              oninput="value=value.replace(/[^\d]/g,'')"
              placeholder="请输入每日可下单数"
              maxlength="6"
            >
              <template slot="suffix">单</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="是否地推人员"
            prop="ground_push_person"
            v-if="isShowBtn"
          >
            <el-select
              v-model="user.ground_push_person"
              value-key="value"
              placeholder="请选择"
            >
              <el-option
                v-for="item in if_options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户类型">
            <el-select
              v-model="user.user_category"
              value-key="value"
              placeholder="请选择"
              filterable
              :disabled="!showTypeBtn"
            >
              <el-option
                v-for="item in user_categorys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="B端类型"
            label-width="100px"
            v-if="user.user_category === 2"
          >
            <el-select
              v-model="user.un_pay_num_limit"
              value-key="value"
              placeholder="请选择"
              :disabled="!showTypeBtn"
            >
              <el-option
                v-for="item in b_end_type_list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="系统角色">
            <!-- <el-input v-model="user.role_ids"></el-input> -->
            <el-select
              v-model="user.role_ids_"
              clearable
              filterable
              multiple
              :disabled="!isShowBtn"
            >
              <el-option
                v-for="item in role_options"
                :key="item.id"
                :label="item.role_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="width: 100%" label="备注">
            <el-input
              type="textarea"
              v-model="user.remark"
              :rows="3"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-scrollbar>
    <div slot="footer">
      <el-button type="primary" @click="submit">确定</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
  </ex-dialog>
</template>

<script>
import { getAllRole } from "api/system/role-api";
import {
  getUserByCondition,
  createUser,
  updateUser,
  getRecomMerchantList,
} from "api/system/user-api";
import Cookies from "js-cookie";
import OPERATE_API from "../../../api/operate_api";
import { recommend_type_list } from "@/enum/userEnum.js";
export default {
  name: "UserEdit",
  props: {
    action: {
      type: String,
      validator: (s) => ["add", "edit"].includes(s),
    },
    title: {
      type: String,
    },
    user_: {
      type: Object,
      default: () => {
        return {
          user_name: "",
          real_name: "",
          wechat_no: "",
          open_id: "",
          sex: "",
          user_no: "",
          cellphone: "",
          email: "",
          org_ids: "",
          role_ids: "",
          uid: "",
          recom_merchant_id: "",
          blacklist: 0,
          first_city_code: "",
          desensi: 1,
          org: {
            id: "",
            name: "",
          },
        };
      },
    },
    cityList_: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog_visible: true,
      user: {
        user_name: "",
        real_name: "",
        wechat_no: "",
        open_id: "",
        sex: "",
        user_no: "",
        cellphone: "",
        email: "",
        user_category: 1,
        un_pay_num_limit: 0,
        orgs: {
          id: "",
          name: "",
        },
        role_ids: "",
        uid: "4380a7da-c651-4afd-ab92-4a55d7a30776",
        role_ids_: "",
        recom_merchant_id: "",
        blacklist: 0,
        first_city_code: "",
        first_city_name: "",
        desensi: 1,
        isShowBtn: false,
        showTypeBtn: false,
        showLimitBtn: false,
        recommend_type: "",
        ground_push_person: 0,
      },
      rules: {
        /*user_name: [
            { required: true, message: "请输入用户账号", trigger: "change" },
            { validator: this.unique, trigger: "blur", text: "用户账号" },
          ],*/
        real_name: [
          { required: true, message: "请输入微信昵称", trigger: "change" },
          //{ validator: this.unique, trigger: "blur", text: "微信昵称" },
        ],
        // wechat_no: [
        //   { required: true, message: "请输入微信号", trigger: "change" },
        //   { validator: this.unique, trigger: "blur", text: "微信号" },
        // ],
        // open_id: [{ required: true, message: '请输入open_id', trigger: 'change' },
        // { validator: this.unique, trigger: 'blur', text: 'open_id' }],
        // cellphone: [
        //   { required: true, message: "请输入用户手机号", trigger: "change" },
        //   {
        //     validator: this.checkCellphone,
        //     trigger: "blur",
        //     text: "用户手机号",
        //   },
        // ],
        first_city_code: [
          { required: true, message: "请选择城市", trigger: "change" },
        ],
      },
      sex_options: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
        {
          value: 0,
          label: "保密",
        },
      ],
      if_options: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      if_options1: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      un_pay_num_limits: [
        {
          value: true,
          label: "否",
        },
        {
          value: false,
          label: "是",
        },
      ],
      b_end_type_list: [
        {
          value: 0,
          label: "连续下单",
        },
        {
          value: 1,
          label: "b端优享",
        },
        {
          value: 2,
          label: "b端优享+连续下单",
        },
      ],
      user_categorys: [
        {
          value: 1,
          label: "C端用户",
        },
        {
          value: 2,
          label: "B端用户",
        },
      ],
      // isMaintenance_opts:[
      // {
      //     value: true,
      //     label: "是",
      //   },
      //   {
      //     value: false,
      //     label: "否",
      //   },
      // ],
      role_options: [],
      loading: true,
      recomRerchantList: [],
      cityList: [],
    };
  },
  computed: {
    read_only() {
      if (this.action !== "add") {
        return true;
      }
      return false;
    },
    recommendTypeList() {
      return recommend_type_list.filter((it) => it.value);
    },
  },
  methods: {
    async init() {
      this.loading = true;
      this.role_options = await getAllRole(Cookies.get("appId"));
      this.user = { ...this.user_ };
      await this.getCityList();
      if (this.user.role_ids) {
        this.$set(this.user, "role_ids_", this.user.role_ids.split(","));
      }
      if (this.user.org_ids) {
        //this.$set(this.user, 'orgs', { id: this.user.org_ids, name: '' })
        this.user.orgs.id = this.user.org_ids;
        this.user.orgs.name = this.user.org_names;
      }
      if (this.action == "add") {
        this.getRecomRerchantList();
      } else if (this.action == "edit") {
        if (this.user.recom_merchant_id && this.user.merchant_name) {
          let obj = {
            id: this.user.recom_merchant_id,
            merchant_name: this.user.merchant_name,
          };
          this.recomRerchantList.push(obj);
        }
      }

      this.loading = false;
    },
    async getRecomRerchantList() {
      let params = {
        validState: "1",
      };
      var data = await getRecomMerchantList(params);
      if (data && data.code === 0) {
        this.$nextTick(() => {
          this.recomRerchantList = data.items;
        });
      }
      this.$forceUpdate(); //可使用此方法强制刷新渲染
    },
    async getCityList() {
      let res = await OPERATE_API.getCityList();
      if (res.data.code != 0) {
        this.$message.error({
          message: "加载城市列表错误",
        });
        return;
      }
      this.cityList = res.data.cityList;

      console.log("11111:" + this.user.first_city_code);
      // 你希望默认添加的城市信息
      const defaultCity = {
        cityCode: this.user.first_city_code, // 替换为实际的默认城市代码
        cityName: this.user.first_city_name, // 替换为实际的默认城市名称
      };

      // 检查是否存在你需要的 cityCode
      const cityExists = this.cityList.some(
        (city) => city.cityCode === defaultCity.cityCode
      );

      // 如果不存在，则添加到 cityList 中
      if (!cityExists) {
        this.cityList.push(defaultCity);
      }
    },
    async submit() {
      try {
        await this.$refs["userForm"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            var send_data = this.buildSendData();
            if (this.action == "add") {
              var result = await createUser(Cookies.get("appId"), send_data);
              if (result == 1) {
                this.$message.success("添加成功!");
                this.$emit("close");
                this.$emit("refresh");
                this.loading = false;
              } else {
                this.loading = false;
                this.$message.error(result);
              }
            } else if (this.action == "edit") {
              var result = await updateUser(Cookies.get("appId"), send_data);
              if (result == 1) {
                this.$message.success("修改成功!");
                this.$emit("close");
                this.$emit("refresh");
                this.loading = false;
              } else {
                this.loading = false;
                this.$message.error(result);
              }
            }
          }
        });
      } catch (error) {
        this.loading = false;
      }
    },
    buildSendData() {
      var user = { ...this.user };
      if (user.role_ids_?.length > 0) {
        user.role_ids = user.role_ids_.join(",");
      } else {
        user.role_ids = "";
      }
      if (user.orgs.id.length > 0) {
        user.org_ids = user.orgs.id;
      } else {
        user.org_ids = "";
      }
      delete user.role_ids_;
      delete user.orgs;
      delete user.cellphone;
      // user.user_name = user.cellphone;
      return user;
    },

    cancel() {
      this.$emit("close");
    },
    async unique(rule, value, callback) {
      var user = await getUserByCondition(this.user.uid, rule.field, value);
      if (user) {
        callback(new Error(`${rule.text}被占用`));
      }
      callback();
    },
    async checkCellphone(rule, value, callback) {
      var pattern =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      var flag = pattern.test(value);
      if (!flag) {
        callback(new Error(`手机号不正确`));
      }
      var user = await getUserByCondition(this.user.uid, rule.field, value);
      if (user) {
        callback(new Error(`${rule.text}被占用`));
      }
      callback();
    },
  },
  created() {
    if (this.$store.state.user.isMaintenanceOrder == 1) {
      this.isShowBtn = true;
      this.showTypeBtn = true;
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.user-edit {
  ::v-deep .el-form-item {
    display: inline-flex;
    width: 48%;
    .el-form-item__content {
      flex: 1;
    }
  }
}
</style>
