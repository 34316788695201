
<div class="page">
  <div class="city">
    <div style="display: flex">
      <div class="city_item">
        <div class="city_item-label">城市：</div>
        <el-select
          style="width: 150px"
          v-model="cityCode"
          placeholder="全国"
          filterable
          @change="getCityArea"
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </div>
      <div class="city_item">
        <div class="city_item-label">代理商：</div>
        <div class="city_item-text">{{ cityAgent }}</div>
      </div>
    </div>
    <div class="city-tab">
      <div
        class="city-tab-item"
        :class="{ active: type === '1' }"
        @click="switchType('1')"
      >
        上门充电
      </div>
      <div
        class="city-tab-item"
        :class="{ active: type === '2' }"
        @click="switchType('2')"
      >
        应急补电
      </div>
    </div>
    <div
      class="city_title"
      :style="{ color: titleShow ? '#fe5e00' : '#fff' }"
    >
      双击即可结束绘制！
    </div>
  </div>
  <div class="map_box" id="map-container">
    <div class="map_box-main">
      <div class="map_box-map" ref="amap" id="amap"></div>
      <div class="map_box-btnList">
        <el-button
          class="map_box-btnList--btn"
          @click="createPolygon"
          :disabled="titleShow"
          >新建多边形</el-button
        >
        <el-button
          v-if="hasPolygon"
          class="map_box-btnList--btn"
          @click="startEdit"
          >开始编辑</el-button
        >
        <el-button
          v-if="hasPolygon"
          class="map_box-btnList--btn"
          @click="endEdit"
          >结束编辑</el-button
        >
        <el-button
          v-if="selectedPolygon"
          class="map_box-btnList--btn"
          @click="deletePolygon"
          >删除多边形</el-button
        >
        <el-button
          v-if="hasPolygon"
          class="map_box-btnList--btn"
          @click="clearPolygon"
          >清空</el-button
        >
        <el-button class="map_box-btnList--btn" @click="confirm"
          >确认</el-button
        >
      </div>
    </div>

    <!-- <div class="map_box-type">
      <div>
        <el-button
          :type="type === '1' ? 'primary' : ''"
          @click="switchType('1')"
          style="width: 150px"
          >上门充电</el-button
        >
      </div>
      <div style="margin-top: 20px">
        <el-button
          :type="type === '2' ? 'primary' : ''"
          @click="switchType('2')"
          style="width: 150px"
          >应急补电</el-button
        >
      </div>
    </div> -->
  </div>
</div>
