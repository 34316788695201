
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <div class="text item">
      <el-form ref="form" :model="form" label-width="110px">
        <el-row class="search">
          <el-form-item label="城市" style="width: 100%">
            <el-select
              v-model="form.firstCityCode"
              placeholder="全国"
              collapse-tags
              clearable
            >
              <el-option
                v-for="item in cityList"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-col class="elCol">
            <el-form-item label="年份：">
              <el-select v-model="form.year" placeholder="请选择">
                <el-option :value="2024" label="2024" />
                <el-option :value="2023" label="2023" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col class="elCol">
            <el-form-item label="月份：">
              <el-select v-model="form.month" placeholder="请选择">
                <el-option :value="1" label="01" />
                <el-option :value="2" label="02" />
                <el-option :value="3" label="03" />
                <el-option :value="4" label="04" />
                <el-option :value="5" label="05" />
                <el-option :value="6" label="06" />
                <el-option :value="7" label="07" />
                <el-option :value="8" label="08" />
                <el-option :value="9" label="09" />
                <el-option :value="10" label="10" />
                <el-option :value="11" label="11" />
                <el-option :value="12" label="12" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col class="elColBtn">
            <el-button
              style="margin-right: 15px"
              type="primary"
              @click="search"
              >查询</el-button
            >
            <el-button
              style="margin-right: 15px"
              type="default"
              @click="clear"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <el-button style="float: right" @click="exportData">导出</el-button>
    </el-row>
    <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
      <el-table-column
        prop="week_name"
        label="时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="new_add_user_num"
        label="新增注册用户数（个）"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.week_name != '合计'">
            <el-button
              @click="viewAddUserList(scope.row)"
              type="text"
              size="small"
              >{{ scope.row.new_add_user_num }}</el-button
            >
          </div>
          <div v-else>{{ scope.row.new_add_user_num }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="order_user_num"
        label="新用户下单客户数（个）"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.week_name != '合计'">
            <el-button
              @click="orderUserListList(scope.row, 0)"
              type="text"
              size="small"
              >{{ scope.row.order_user_num }}</el-button
            >
          </div>
          <div v-else>{{ scope.row.order_user_num }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="order_user_ratio"
        label="下单客户占比"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.order_user_ratio">
            {{ scope.row.order_user_ratio }}%
          </div>
          <!--<div v-else>0%</div>-->
        </template>
      </el-table-column>
      <el-table-column
        prop="un_order_user_num"
        label="未下单客户（个）"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="over_one_order_user_num"
        label="复购（2次及以上）客户数（个）"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.week_name != '合计'">
            <el-button
              @click="orderUserListList(scope.row, 1)"
              type="text"
              size="small"
              >{{ scope.row.over_one_order_user_num }}</el-button
            >
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="over_two_order_user_num"
        label="复购（3次及以上）客户数（个）"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.week_name != '合计'">
            <el-button
              @click="orderUserListList(scope.row, 2)"
              type="text"
              size="small"
              >{{ scope.row.over_two_order_user_num }}</el-button
            >
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="loss_user_num"
        label="流失客户"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="loss_user_ratio"
        :formatter="
          (row) => {
            if (row.loss_user_ratio) return row.loss_user_ratio + '%';
          }
        "
        label="客户流失率"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="loss_user_subsidy_money"
        label="流失客户消耗补贴金额"
        min-width="150"
        align="center"
      ></el-table-column>
    </el-table>
  </el-card>
  <NewAddUserList
    v-if="addUserListVisible"
    ref="newAddUserList"
    :dataItem="dataItem"
  ></NewAddUserList>
  <OrderUserList
    v-if="orderUserListVisible"
    ref="orderUserList"
    :dataItem="dataItem"
  ></OrderUserList>
</div>
