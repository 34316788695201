
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="手机号：">
        <el-input
          v-model.trim="form.cellphone"
          placeholder="请输入手机号"
          clearable
        />
      </el-form-item>
      <el-form-item label="来源活动：">
        <el-input
          v-model.trim="form.activityName"
          placeholder="请输入来源活动"
          clearable
        />
      </el-form-item>
      <!--                <el-form-item label="城市：">-->
      <!--                    <el-select v-model="form.cityCode" placeholder="全部" collapse-tags clearable>-->
      <!--                        <el-option v-for="item in cityOptions" :key="item.cityCode" :label="item.cityName"-->
      <!--                            :value="item.cityCode">-->
      <!--                        </el-option>-->
      <!--                    </el-select>-->
      <!--                </el-form-item>-->
      <el-form-item label="状态：">
        <el-select
          v-model="form.state"
          placeholder="全部"
          collapse-tags
          clearable
        >
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="购买时间：">
        <el-date-picker
          v-model="createTime"
          type="datetimerange"
          style="width: 335px"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          :clearable="true"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="timeChange"
        ></el-date-picker>
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card" style="height: 20%">
    <div class="total">
      <div class="total_money">
        <div>购买总额（元）：{{ statistics.total_amount }}</div>
      </div>
      <div class="total_degrees">
        <div>购买总度数（度）：{{ statistics.total_degree }}</div>
      </div>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>度数管理</span>
      <el-button style="float: right" @click="exportOrder">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      :height="tableHeight"
      :data="dataTable"
      @selection-change="rowSelected"
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cell_phone"
        label="手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="activity_name"
        label="来源活动"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="time"
        label="领取时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="type"
        label="类型"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.type === 7">充值</div>
          <div v-else-if="scope.row.type === 9">赠送</div>
          <div v-else-if="scope.row.type === 11">转赠</div>
          <span v-else>其他</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="amount"
        label="购买金额"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="degree"
        label="获得度数"
        min-width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="state	"
        label="状态"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.state === 1
                ? "正常"
                : scope.row.state === 0
                ? "失败"
                : "作废"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handelDetails(scope.row)"
            type="text"
            size="small"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <agentDegreesManageDetailsModal ref="detailsModal">
  </agentDegreesManageDetailsModal>
</div>
