
<el-dialog :title="formItem.input_status==true ? '修改' : '待录入'" :close-on-click-modal="false" :visible.sync="visible">
  <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="140px">
    <el-form-item label="城市：" prop="fullname">
      {{ this.formItem.fullname}}
    </el-form-item>
    <el-form-item label="手机号码：" prop="cellphone">
      {{ this.formItem.cellphone}}
    </el-form-item>
    <el-form-item label="车价：" prop="car_price">
      <el-select v-model="dataForm.car_price" placeholder="请选择" collapse-tags clearable style="width: 100%">
        <el-option v-for="item in carPriceDim" :key="item.data_value" :value="item.data_value" :label="item.data_name"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="性别：" prop="sex">
      <el-select v-model="dataForm.sex" placeholder="请选择" collapse-tags clearable style="width: 100%">
        <el-option :value="1" label="男"/>
        <el-option :value="2" label="女"/>
      </el-select>
    </el-form-item>
    <el-form-item label="年龄段：" prop="age_group">
      <el-select v-model="dataForm.age_group" placeholder="请选择" collapse-tags clearable style="width: 100%">
        <el-option v-for="item in ageGroupDim" :key="item.data_value" :value="item.data_value" :label="item.data_name"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="车辆属性：" prop="car_attribute">
      <el-select v-model="dataForm.car_attribute" placeholder="请选择" collapse-tags clearable style="width: 100%">
        <el-option :value="1" label="客运车"/>
        <el-option :value="2" label="私家车"/>
        <el-option :value="2" label="货运车"/>
      </el-select>
    </el-form-item>
    <el-form-item label="未下单原因：" prop="un_order_reason">
      <el-select v-model="dataForm.un_order_reason" placeholder="请选择" collapse-tags clearable style="width: 100%">
        <el-option v-for="item in unOrderReasonDim" :key="item.data_value" :value="item.data_value" :label="item.data_name"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="回访结论：" prop="follow_result">
      <el-select v-model="dataForm.follow_result" placeholder="请选择" collapse-tags clearable style="width: 100%">
        <el-option v-for="item in followResultDim" :key="item.data_value" :value="item.data_value" :label="item.data_name"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="备注：" prop="remarks">
      <el-input type="textarea" v-model="dataForm.remarks" placeholder="备注"></el-input>
    </el-form-item>

  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="visible = false">取消</el-button>
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
  </span>
</el-dialog>
