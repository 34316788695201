
<!-- 发放情况表格 -->
<el-dialog
  width="70%"
  :title="'账户明细'"
  :visible.sync="visible"
  append-to-body
>
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="收入/支出：">
        <el-select
          v-model="form.type"
          placeholder="全部"
          collapse-tags
          clearable
        >
          <el-option label="全部" :value="null"></el-option>
          <el-option label="收入" :value="1"></el-option>
          <el-option label="支出" :value="-1"></el-option>
        </el-select>
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
      <el-button style="float: right" @click="exportOrder">导出</el-button>
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-table ref="tableData" :height="tableHeight" :data="dataTable">
      <el-table-column
        prop="type"
        label="名称"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type === 7">账户充值</span>
          <span v-else-if="scope.row.type === 8">订单支出</span>
          <span v-else-if="scope.row.type === 9">度数赠送</span>
          <span v-else-if="scope.row.type === 10">度数回退</span>
          <span v-else-if="scope.row.type === 11">度数转赠</span>
          <span v-else>其他</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="time"
        label="时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="购买金额"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="degree"
        label="收入/支出(度)"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.type === 7 ||
              scope.row.type === 9 ||
              scope.row.type === 11
            "
            >+{{ scope.row.degree }}</span
          >
          <span v-else>-{{ scope.row.degree }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="activity_name"
        label="活动名称"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="order_no"
        label="关联订单编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column label="前" min-width="150" align="center">
        <el-table-column
          prop="early_total_degree_buy_money"
          label="总充值金额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="early_total_degree"
          label="总充值度数"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="early_available_degree"
          label="度数余额"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table-column>
      <el-table-column label="后" min-width="150" align="center">
        <el-table-column
          prop="cur_total_degree_buy_money"
          label="总充值金额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cur_total_degree"
          label="总充值度数"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cur_available_degree"
          label="度数余额"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
</el-dialog>
