
<div>
  <ex-dialog
    :title="title"
    :visible.sync="dialog_visible"
    :width="700"
    :height="400"
    :show-fullscreen-icon="true"
    :append-to-body="true"
    @close="close"
  >
    <el-form
      :model="FormData"
      ref="FormData"
      :rules="rules"
      label-width="110px"
    >
      <el-form-item label="手机号码" prop="cellphone">
        <el-select
          v-model="FormData.cellphone"
          filterable
          remote
          reserve-keyword
          placeholder="请输入手机号码"
          style="width: 500px"
          :remote-method="remoteMethod"
          :loading="loading"
          @change="selectUser"
          :disabled="!!select_user.user_id"
        >
          <el-option
            v-for="item in userList"
            :key="item.uid"
            :label="`${item.cellphone}`"
            :value="item.cellphone"
          >
            <p>{{ `${item.cellphone} ${item.first_city_name || ""}` }}</p>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="城市" prop="city_code">
        <el-select
          v-model="FormData.city_code"
          placeholder="请选择城市"
          style="width: 500px"
          :disabled="agentCityCode != ''"
        >
          <el-option
            v-for="item in cityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册时间">
        <el-date-picker
          v-model="FormData.create_time"
          :disabled="FormData.create_time != ''"
          style="width: 500px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="姓名" prop="real_name">
        <el-input
          v-model="FormData.real_name"
          style="width: 500px"
          placeholder="请输入姓名"
        />
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select
          v-model="FormData.sex"
          placeholder="请选择性别"
          style="width: 500px"
        >
          <el-option label="保密" :value="0"></el-option>
          <el-option label="男" :value="1"></el-option>
          <el-option label="女" :value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submitForm('FormData')"
        >确定</el-button
      >
    </div>
  </ex-dialog>
</div>
