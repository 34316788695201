<!--
 * @Author: ljf
 * @Date: 2022-05-26 16:17:06
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-15 10:54:47
 * @FilePath: \lxm-admin\src\views\system\menu\menu.vue
 * @Description: 
 * 
-->
<template>
  <div class="menu-container">
    <div class="menu-container-left">
      <div class="menu-container-left-bar">
        <el-input
          v-model="filterText_"
          clearable
          placeholder="搜索"
          size="small"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
            size="small"
          ></el-button>
        </el-input>
      </div>
      <div class="menu-container-left-container">
        <el-scrollbar
          style="height: 100%"
          wrapStyle="overflow-x:hidden;"
          viewStyle=""
        >
          <!-- 选择树 -->
          <el-tree
            class="filter-tree"
            default-expand-all
            ref="tree"
            :props="defaultProps"
            :data="treeData"
            :show-checkbox="multiple"
            node-key="id"
            @node-click="handleNodeClick"
            @node-contextmenu="showContextmenu"
          >
          </el-tree>
        </el-scrollbar>
      </div>
    </div>

    <div class="menu-container-right">
      <el-scrollbar
        style="height: 100%"
        wrapStyle="overflow-x:hidden;"
        viewStyle=""
      >
        <el-form
          class="menuForm"
          ref="menuForm"
          :model="menu"
          label-width="80px"
          :rules="rules"
        >
          <el-form-item :label="p_title" size="normal">
            {{ p_name }}
          </el-form-item>
          <el-form-item label="菜单名称" size="normal" prop="menu_name">
            <el-input
              v-model="menu.menu_name"
              placeholder="请输入菜单名称"
              size="normal"
              clearable
              maxlength="30"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="页面地址" size="normal">
            <el-input
              v-model="menu.url"
              placeholder="请输入页面地址"
              size="normal"
              clearable
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="图标icon" size="normal">
            <el-input
              v-model="menu.icon"
              placeholder="请输入图标icon"
              size="normal"
              clearable
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="页面组件" size="normal">
            <el-input
              v-model="menu.component"
              placeholder="请输入页面组件"
              size="normal"
              clearable
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="是否展开" size="normal" v-show="false">
            <el-switch
              v-model="menu.opened"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="是否显示" size="normal">
            <el-switch
              v-model="menu.status"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="排        序" size="normal">
            <el-input
              v-model.number="menu.sort"
              placeholder="请输入排序号"
              size="normal"
              type="number"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="描        述" size="normal">
            <el-input
              v-model="menu.remark"
              type="textarea"
              placeholder="请输入排序号"
              size="normal"
              clearable
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">确定</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </div>
    <div
      :style="{
        'z-index': 9999,
        position: 'fixed',
        left: optionCardX + 'px',
        top: optionCardY + 'px',
        width: '100px',
        background: 'white',
        'box-shadow':
          '0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)',
      }"
      v-show="optionCardShow"
      id="option-button-group"
    >
      <!-- <el-dropdown-item @click.stop="addMenu">新增菜单</el-dropdown-item>
      <el-dropdown-item @click.stop="removeMenu">删除菜单</el-dropdown-item> -->
      <li
        v-for="item in setting"
        :key="item.command"
        @click="handleCommand(item.command)"
      >
        <i :class="item.icon"></i>
        <span style="margin-left: 5px">{{ item.context }}</span>
      </li>
    </div>
  </div>
</template>

<script>
import {
  getMenuTree,
  addMenu,
  updateMenu,
  deleteMenu,
  checkMenu,
} from "api/system/menu-api";
import { nest } from "utils/utils";
import Cookies from "js-cookie";
export default {
  name: "Menu",

  data() {
    return {
      defaultProps: {
        value: "id",
        label: "menu_name",
        parentId: "pid",
        children: "children",
      },
      treeData: [],
      multiple: false,
      filterText_: "",
      appId: Cookies.get("app_id") ?? "2",
      p_name: "根节点",
      pid: "",
      menu: {
        id: "",
        pid: "",
        menu_name: "",
        component: "",
        url: "",
        icon: "",
        opened: true,
        status: true,
        sort: 0,
        remark: "",
        type: "pms",
      },
      rules: {
        menu_name: [
          {
            required: true,
            message: "请输入菜单名称",
            trigger: "change",
          },
          { validator: this.unique, trigger: "blur", text: "菜单名称" },
        ],
      },
      setting: [
        {
          command: "addMenu",
          icon: "el-icon-plus",
          context: "新增菜单",
        },
        {
          command: "removeMenu",
          icon: "el-icon-minus",
          context: "删除菜单",
        },
      ],
      optionCardShow: false,
      optionCardY: 0,
      optionCardX: 0,
      node: null,
      selectMenu: null,
      p_title: "父菜单为",
    };
  },
  watch: {
    optionCardShow: {
      handler(newValue, oldValue) {
        if (newValue) {
          document.body.addEventListener("click", this.closeMenu);
        } else {
          document.body.removeEventListener("click", this.closeMenu);
        }
      },
    },
  },
  methods: {
    async init() {
      this.treeData = nest(await getMenuTree("pms"), 0, this.defaultProps);
    },
    handleNodeClick(data) {
      this.p_name = data.title;
      this.p_title = "当前菜单";
      this.menu = { ...data };
      this.menu.status = data.status == 0 ? true : false;
    },
    filterNode(value, data) {
      const { label } = this.defaultProps;
      if (!value) return true;
      return data[label].indexOf(value) !== -1;
    },
    search() {},
    submit() {
      this.$refs["menuForm"].validate(async (valid) => {
        if (valid) {
          this.menu.status ? (this.menu.status = 0) : (this.menu.status = 1);
          var data =
            this.menu.id == ""
              ? await addMenu(this.menu)
              : updateMenu(this.menu);
          if (data) {
            this.$message.success("保存菜单成功");
            this.init();
            return;
          } else {
            this.$message.success("保存菜单失败");
          }
        }
      });
    },
    showContextmenu(e, data, n, t) {
      this.optionCardShow = false;
      this.optionCardX = e.x + 5; // 让右键菜单出现在鼠标右键的位置
      var height = document.body.clientHeight - 150;
      this.optionCardY = e.y + 10 > height ? height : e.y + 10;
      this.selectMenu = data;
      this.node = n; // 将当前节点保存
      this.optionCardShow = true; // 展示右键菜单
    },
    closeMenu() {
      this.optionCardShow = false;
    },
    cancel() {},
    handleCommand(command) {
      this[command]();
    },
    addMenu() {
      this.p_title = "父菜单为";
      this.menu = {
        id: "",
        pid: "",
        menu_name: "",
        component: "",
        url: "",
        icon: "",
        opened: true,
        status: true,
        sort: 0,
        remark: "",
        type: "pms",
      };
      this.optionCardShow = false;
      this.menu.pid = this.selectMenu.id;
      this.p_name = this.selectMenu.menu_name;
    },
    removeMenu() {
      this.optionCardShow = false;
      this.$confirm("此操作将永久删除该菜单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var data = await deleteMenu(this.selectMenu.id);
          if (data) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.init();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async unique(rule, value, callback) {
      var menuDto = {
        menuName: value,
        id: this.menu.id,
        pid: this.menu.pid,
        menuType: "pms",
      };
      var flag = await checkMenu(menuDto);
      if (flag) {
        callback(new Error(`${rule.text}被占用`));
      }
      callback();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.menu-container {
  width: 100%;
  height: calc(100% - 10px);
  display: flex;
  margin-top: 10px;
  overflow: hidden;
  .menu-container-left {
    width: 260px;
    height: calc(100% - 10px);
    flex-direction: column;
    border: 0.5px solid #c1cfdf;
    .menu-container-left-bar {
      height: 30px;
      width: 100%;
      display: flex;
    }
    .menu-container-left-container {
      margin-top: 10px;
      flex: 1;
      height: calc(100% - 40px);
    }
  }
  .menu-container-right {
    flex: 1;
    margin-left: 10px;
    height: calc(100% - 10px);
    border: 0.5px solid #c1cfdf;
  }
  .menuForm {
    margin-top: 10px;
    width: 98%;
  }
  li {
    margin: 0;
    padding: 7px 10px;
    cursor: pointer;
    &:hover {
      background: #ecf5ff;
      color: #66b1ff;
    }
  }

  li::marker {
    content: "";
  }
}
</style>