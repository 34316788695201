<template>
    <el-dialog title="关闭订单" v-dialogDrag :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
        <el-form :model="dataForm" ref="dataForm" label-width="110px">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注信息：">
                        <el-input v-model.trim="dataForm.remark" type="textarea" placeholder="输入备注信息" :rows="5"
                            maxlength="300" show-word-limit clearable resize="none" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    export default {
        props: {
            dialog: {
                type: Boolean,
                default () {
                    return false;
                }
            },
            id: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                dialogVisible: false,
                dataForm: {},
            }
        },
        watch: {
            dialog: {
                immediate: true,
                handler(value) {
                    this.dialogVisible = value;
                    if(value){
                        this.clear();
                    }
                }
            },
            dialogVisible: {
                handler(value) {
                    this.$emit("update:dialog", value);
                }
            }
        },
        methods: {
            clear(){
                this.dataForm = {}
            },
            cancel() {
                this.dialogVisible = false;
            },
            confirm() {
                let param = {
                    id: this.id,
                    remark: this.dataForm.remark
                }
                this.showLoading("提交中");
                this.axiosHelper.post('/api/order/close', param)
                    .then((response) => {
                        this.hideLoading();
                        this.dialogVisible = false;
                        this.$emit("search", this.dataForm);
                    })
                    .catch((err) => {
                        this.hideLoading();
                        if(err.response.data.message){
                            this.$message.error(err.response.data.message);
                        } else {
                            this.$message.error("提交失败");
                        }
                    })

            },
        }
    }
</script>