<template>
    <el-dialog title="高级搜索" v-dialogDrag :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
        <el-form :model="dataForm" ref="dataForm" label-width="125px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="微信号：">
                        <el-input v-model.trim="dataForm.wechat_no" placeholder="请输入微信号" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="微信ID：">
                        <el-input v-model.trim="dataForm.open_id" placeholder="请输入微信ID" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="手机号：">
                        <el-input v-model.trim="dataForm.cellphone" placeholder="请输入手机号" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="优惠券类型：">
                        <el-select clearable v-model="dataForm.discount_type" placeholder="请选择优惠券类型">
							<el-option v-for="item in discount_type_emu" :key="item.data_code"
									   :label="item.data_name" :value="item.data_value"></el-option>
						</el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="优惠券编号：">
                        <el-input v-model.trim="dataForm.id" placeholder="请输入优惠券编号" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="优惠券名称：">
                        <el-input v-model.trim="dataForm.name" placeholder="请输入优惠券名称" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="有效期：">
                        <el-date-picker
                            style="width: 335px"
                            value-format="yyyy-MM-dd"
                            v-model="validity_Time"
                            type="daterange"
                            :clearable="true"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="状态：">
                        <el-select clearable v-model="dataForm.cur_state" placeholder="请选择状态">
							<el-option v-for="item in cur_state_emu" :key="item.data_code"
									   :label="item.data_name" :value="item.data_value"></el-option>
						</el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="领取时间：">
                        <el-date-picker v-model="create_Time" type="datetimerange"
										style="width: 335px"
										value-format="yyyy-MM-dd HH:mm:ss" :clearable="true" range-separator="至"
										start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="活动名称：">
                    <el-input v-model.trim="dataForm.promotion_name" placeholder="请输入活动名称" clearable />
                  </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="clear">清除条件</el-button>
            <el-button type="default" @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    export default {
        name: "coupon-manage-search-model",
        props: {
            dialog: {
                type: Boolean,
                default () {
                    return false;
                }
            },
        },
        data() {
            return {
                validity_Time: null,
                create_Time: null,
                dialogVisible: false,
                dataForm: {
                  promotion_name:'',
                },
                //优惠券类型（0：金额 1：折扣）
                discount_type_emu: [
                    {
                        data_value: 0,
                        data_name: '现金',
                        data_code: 'JE'
                    },
                    {
                        data_value: 2,
                        data_name: '度数',
                        data_code: 'DS'
                    },
                    {
                        data_value: 3,
                        data_name: '次数',
                        data_code: 'CS'
                    }
                ],
                //状态（0-待使用；1-已使用；2-已过期；3-已作废）
                state_emu: [
                    {
                        data_value: 0,
                        data_name: '待使用',
                        data_code: 'DSY'
                    },
                    {
                        data_value: 1,
                        data_name: '已使用',
                        data_code: 'YSY'
                    },
                    // {
                    //     data_value: 2,
                    //     data_name: '已过期',
                    //     data_code: 'YGQ'
                    // },
                    {
                        data_value: 3,
                        data_name: '已作废',
                        data_code: 'YZF'
                    },
                ],
              // 当前状态
              cur_state_emu: [
                {
                  data_value: 0,
                  data_name: '待使用',
                  data_code: 'DSY'
                },
                {
                  data_value: 1,
                  data_name: '已使用',
                  data_code: 'YSY'
                },
                {
                    data_value: 2,
                    data_name: '已过期',
                    data_code: 'YGQ'
                },
                {
                  data_value: 3,
                  data_name: '已作废',
                  data_code: 'YZF'
                },
                {
                  data_value: 4,
                  data_name: '代扣续用',
                  data_code: 'DKXY'
                }
              ],

            }
        },
        mounted() {
            
        },
        watch: {
            dialog: {
                immediate: true,
                handler(value) {
                    this.dialogVisible = value;
                }
            },
            dialogVisible: {
                handler(value) {
                    this.$emit("update:dialog", value);
                }
            }
        },
        methods: {
            clear() {
                this.dataForm = {};
                this.validity_Time = null;
                this.create_Time = null;
            },
            cancel() {
                this.dialogVisible = false;
            },
            confirm() {
                if (this.validity_Time) {
                    this.dataForm.start_time = this.validity_Time[0];
                    this.dataForm.end_time = this.validity_Time[1] + ' 23:59:59';
                } else {
                    this.dataForm.start_time = null;
                    this.dataForm.end_time = null;
                }
                if (this.create_Time) {
                    this.dataForm.create_time_start = this.create_Time[0];
                    this.dataForm.create_time_end = this.create_Time[1];
                } else {
                    this.dataForm.create_time_start = null;
                    this.dataForm.create_time_end = null;
                }
                this.dialogVisible = false;
                this.$emit("search", this.dataForm);
            },
        }
    }
</script>