
<el-row class="block-body">
  <el-form
    ref="orgForm"
    :model="org"
    label-width="80px"
    :rules="rules"
    v-loading="loading"
  >
    <el-form-item
      label="父组织"
      v-show="action=='add'"
    >
      <el-input
        v-model="org.parent_name"
        placeholder=""
      ></el-input>
    </el-form-item>
    <el-form-item label="组织名称">
      <el-input
        v-model="org.org_name"
        placeholder=""
        clearable
        maxlength="50"
        show-word-limit
      ></el-input>

    </el-form-item>
    <el-form-item label="组织别名">
      <el-input
        v-model="org.alias"
        placeholder=""
        clearable
        maxlength="100"
        show-word-limit
      ></el-input>

    </el-form-item>

    <el-row :gutter="20">
      <el-col
        :span="12"
        :offset="0"
      >
        <el-form-item label="组织编号">
          <el-input
            v-model="org.org_code"
            placeholder=""
            clearable
            maxlength="32"
            show-word-limit
          ></el-input>

        </el-form-item>
      </el-col>
      <el-col
        :span="12"
        :offset="0"
      >
        <el-form-item label="组织领导">

          <user-selector
            v-model="org.leader_id"
            defaultPanel="byDepartmentFilter"
            :panels="panels"
          ></user-selector>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :span="12"
        :offset="0"
      >
        <el-form-item label="组织类型">
          <el-select v-model="org.category">
            <el-option
              v-for="item in categoryOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

        </el-form-item>
      </el-col>
      <el-col
        :span="12"
        :offset="0"
      >
        <el-form-item label="组织排序">

          <el-input-number
            v-model="org.sort_index"
            :min="0"
            :max="20"
            label="组织排序"
          ></el-input-number>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :span="12"
        :offset="0"
      >
        <el-form-item label="联系电话">
          <el-input
            v-model="org.telephone"
            placeholder=""
            clearable
            maxlength="20"
            show-word-limit
          ></el-input>

        </el-form-item>
      </el-col>
      <el-col
        :span="12"
        :offset="0"
      >
        <el-form-item label="是否显示">
          <el-switch
            v-model="org.show"
            active-text="是"
            inactive-text="否"
          >
          </el-switch>

        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="联系地址">
      <el-input
        v-model="org.address"
        placeholder=""
        clearable
        type="textarea"
        maxlength="100"
        show-word-limit
      ></el-input>

    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="submit"
      >确定</el-button>
      <el-button @click="cancel">取消</el-button>
    </el-form-item>
  </el-form>
</el-row>
