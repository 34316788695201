
<el-dialog
  class="showAll_dialog"
  :title="'详情'"
  width="800px"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="140px"
    v-loading="formLoading"
  >
    <div class="form_label">基础信息</div>
    <div class="flex">
      <el-form-item label="活动名称：" prop="name">
        <el-input
          v-model.trim="form.name"
          placeholder="请输入活动名称"
          :disabled="true"
          clearable
          maxlength="35"
        />
      </el-form-item>
      <el-form-item label="城市：" prop="city_code">
        <el-select
          v-model="form.city_code"
          placeholder="请选择城市"
          :disabled="true"
        >
          <el-option :label="form.city_name" :value="form.city_code">
          </el-option>
        </el-select>
      </el-form-item>
    </div>
    <el-form-item label="活动展示名称：" prop="name">
      <el-input
        v-model.trim="form.promotion_show_name"
        placeholder="请输入活动展示名称"
        :disabled="true"
        clearable
        maxlength="35"
      />
    </el-form-item>
    <el-form-item label="活动时间：" prop="create_time">
      <el-date-picker
        v-model="form.create_time"
        :disabled="true"
        type="datetimerange"
        style="width: 400px"
        value-format="yyyy-MM-dd HH:mm:ss"
        :clearable="true"
        :default-time="['00:00:00', '23:59:59']"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
      ></el-date-picker>
    </el-form-item>
    <div class="form_label">场景</div>
    <div class="flex">
      <el-form-item label="场景" prop="billing_type">
        <el-input
          v-model.trim="form.billing_name"
          placeholder="补贴金额上限"
          :disabled="true"
          clearable
        />
      </el-form-item>
      <el-form-item label="补贴金额上限：" prop="total_amount">
        <div class="flex">
          <el-input
            v-model.trim="form.total_amount"
            placeholder="补贴金额上限"
            :disabled="true"
            clearable
            maxlength="35"
          />
          <span style="margin: 0 10px">元</span>
        </div>
      </el-form-item>
    </div>
    <div class="flex">
      <el-form-item
        :label="
          form.billing_type == '13' ? '参与活动人数限制：' : '人数限制：'
        "
        prop="personCode"
      >
        <el-select
          v-model="form.personCode"
          placeholder="请选择人数限制"
          :disabled="true"
        >
          <el-option label="否" :value="-1"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.personCode !== -1"
        label="前："
        prop="person_limit"
      >
        <div class="flex">
          <el-input
            v-model.trim="form.person_limit"
            placeholder="人数"
            :disabled="true"
            clearable
            maxlength="35"
          />
          <span style="margin: 0 10px">人</span>
        </div>
      </el-form-item>
    </div>
    <div class="flex">
      <el-form-item label="场景互斥限制：" prop="billing_mutex">
        <el-select
          v-model="form.billing_mutex"
          placeholder="请选择场景互斥限制"
          :disabled="true"
        >
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.billing_mutex"
        label="互斥场景："
        prop="mutex_billing_type"
      >
        <el-select
          v-model="form.mutex_billing_type"
          placeholder="请选择场景互斥限制"
          :disabled="true"
        >
          <el-option
            v-for="item in sceneList"
            :key="item.id"
            :label="item.billing_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="flex" v-if="form.billing_type === 0">
      <el-form-item label="落点设置：" prop="jump_page">
        <el-select v-model="form.jump_page" placeholder="请选择" disabled>
          <el-option label="无" value="0"></el-option>
          <el-option label="首页" value="1"></el-option>
          <el-option label="常规充电下单页" value="2"></el-option>
          <el-option label="应急补电下单页" value="3"></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="flex" v-if="form.billing_type === 13">
      <el-form-item label="参与活动次数限制" prop="frequencyCode">
        <el-select
          v-model="form.frequencyCode"
          placeholder="请选择人数限制"
          :disabled="true"
        >
          <el-option label="否" :value="-1"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.frequencyCode !== -1"
        label=""
        prop="person_limit"
      >
        <div class="flex">
          <el-input
            v-model.trim="form.every_one_times_limit"
            placeholder="次数"
            :disabled="true"
            clearable
            maxlength="35"
          />
          <span style="margin: 0 10px">次</span>
        </div>
      </el-form-item>
    </div>
    <div class="flex" v-if="form.billing_type === 13">
      <el-form-item
        label="连续邀请人数："
        prop="reach_standard_meet_person_num"
      >
        <div class="flex">
          <el-input
            v-model.trim="form.reach_standard_meet_person_num"
            placeholder="人数"
            :disabled="true"
            clearable
            maxlength="35"
          />
          <span style="margin: 0 10px">人</span>
        </div>
      </el-form-item>
    </div>
    <div
      class="flex"
      v-if="billing_rule_type === 2 || billing_rule_type === 3"
    >
      <el-form-item
        label="单人充值上限："
        prop="every_one_recharge_limit_code"
      >
        <el-select
          v-model="form.every_one_recharge_limit_code"
          placeholder="请选择场景互斥限制"
          :disabled="true"
        >
          <el-option label="否" :value="-1"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.every_one_recharge_limit_code !== -1"
        label="金额："
        prop="every_one_recharge_limit"
      >
        <div class="flex">
          <el-input
            v-model.trim="form.every_one_recharge_limit"
            placeholder="金额"
            :disabled="true"
            clearable
            maxlength="35"
          />
          <span style="margin: 0 10px">元</span>
        </div>
      </el-form-item>
    </div>
    <!-- 非充值、度数套餐活动时 -->
    <div v-if="billing_rule_type === 1">
      <div
        v-for="(item, index) in form.activity_subsidy_type_list"
        :key="index"
      >
        <el-form-item label="优惠类型：">
          <el-select
            v-model="item.subsidy_type"
            placeholder="请选择优惠类型"
            :disabled="true"
          >
            <el-option label="优惠券" :value="1"></el-option>
            <el-option label="充电度数" :value="2"></el-option>
            <el-option label="车辆性能报告" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="item.subsidy_type === 1"
          label="支付互斥限制："
          prop="pay_mutex_limit"
        >
          <el-select
            v-model="item.pay_mutex_limit"
            placeholder="请选择"
            :disabled="true"
          >
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="item.subsidy_type === 2" label="度数：">
          <el-input
            v-model.trim="item.degree"
            placeholder="度数"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
        <el-form-item v-if="item.subsidy_type === 3" label="次数：">
          <div class="flex">
            <el-input
              v-model.trim="item.num"
              placeholder="请输入次数"
              :disabled="true"
              clearable
              maxlength="35"
            />
            <el-button
              plain
              style="
                margin-left: 10px;
                color: #409eff;
                border: 1px #409eff solid;
              "
              @click="viewCondition(4, item.promotion_id)"
              >发放情况</el-button
            >
          </div>
        </el-form-item>
        <div
          class="flex"
          v-for="(val, valIndex) in item.coupon_quote_list"
          :key="valIndex"
        >
          <el-form-item label="券：">
            <el-input
              v-model.trim="val.coupon_name"
              style="width: 180px"
              placeholder="券"
              :disabled="true"
              clearable
              maxlength="35"
            />
          </el-form-item>
          <el-form-item label="有效期：" label-width="80px">
            <div class="flex">
              <el-input
                v-model.trim="val.valid_day"
                style="width: 94px"
                placeholder="有效期"
                :disabled="true"
                clearable
                maxlength="35"
              />
              <span style="margin: 0 10px">天</span>
            </div>
          </el-form-item>
          <el-form-item label="张数：" label-width="70px">
            <div class="flex">
              <el-input
                v-model.number="val.num"
                :disabled="true"
                label="描述文字"
              ></el-input>
              <el-button
                plain
                style="
                  margin-left: 10px;
                  color: #409eff;
                  border: 1px #409eff solid;
                "
                @click="viewCondition(2, val.id)"
                >发放情况</el-button
              >
            </div>
          </el-form-item>
        </div>
      </div>
    </div>
    <!-- 充值活动 -->
    <div v-if="billing_rule_type === 2">
      <div v-for="(item, index) in form.recharge_rule_list" :key="index">
        <el-form-item
          v-if="form.recharge_rule_list.length"
          :label="`第${index + 1}档：`"
          prop="recharge"
        >
          <div class="flex">
            <span
              style="
                margin: 0 10px 0 20px;
                white-space: nowrap;
                color: #8590a6;
              "
              >充值</span
            >
            <el-input
              v-model.trim="item.recharge"
              placeholder="有效期"
              :disabled="true"
              clearable
              maxlength="35"
            />
          </div>
        </el-form-item>
        <div
          v-for="(val, valIndex) in item.activity_subsidy_type_list"
          :key="valIndex"
        >
          <el-form-item label="优惠类型：">
            <el-select
              v-model="val.subsidy_type"
              placeholder="请选择优惠类型"
              :disabled="true"
            >
              <el-option label="优惠券" :value="1"> </el-option>
            </el-select>
          </el-form-item>
          <div
            class="flex"
            v-for="(coupon, couponIndex) in val.coupon_quote_list"
            :key="couponIndex"
          >
            <el-form-item label="券：">
              <el-input
                v-model.trim="coupon.coupon_name"
                style="width: 180px"
                placeholder="券"
                :disabled="true"
                clearable
                maxlength="35"
              />
            </el-form-item>
            <el-form-item label="有效期：" label-width="80px">
              <div class="flex">
                <el-input
                  v-model.trim="coupon.valid_day"
                  style="width: 94px"
                  placeholder="有效期"
                  :disabled="true"
                  clearable
                  maxlength="35"
                />
                <span style="margin: 0 10px">天</span>
              </div>
            </el-form-item>

            <el-form-item label="张数：" label-width="70px">
              <div class="flex">
                <el-input
                  v-model.number="coupon.num"
                  :disabled="true"
                  label="描述文字"
                ></el-input>
                <el-button
                  plain
                  style="
                    margin-left: 10px;
                    color: #409eff;
                    border: 1px #409eff solid;
                  "
                  @click="viewCondition(2, coupon.id)"
                  >发放情况</el-button
                >
              </div>
            </el-form-item>
          </div>
        </div>
      </div>
    </div>
    <!-- 度数套餐活动 -->
    <div v-if="billing_rule_type === 3">
      <div v-for="(item, index) in form.recharge_rule_list" :key="item.id">
        <el-form-item
          v-if="form.recharge_rule_list.length"
          :label="`第${index + 1}档：`"
          prop="recharge"
        >
          <div class="flex">
            <span
              style="
                margin: 0 10px 0 20px;
                white-space: nowrap;
                color: #8590a6;
              "
              >充值</span
            >
            <el-input
              v-model.trim="item.recharge"
              placeholder="有效期"
              :disabled="true"
              clearable
              maxlength="35"
            />
          </div>
        </el-form-item>
        <div
          v-for="(val, valIndex) in item.activity_subsidy_type_list"
          :key="val.id"
        >
          <template v-if="valIndex === 0 && form.billing_type != 15">
            <el-form-item label="优惠类型：">
              <el-select
                v-model="val.subsidy_type"
                placeholder="请选择优惠类型"
                :disabled="true"
              >
                <el-option label="充电度数" :value="2"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="度数：">
              <div class="flex">
                <el-input
                  v-model.trim="val.degree"
                  placeholder="请输入充电度数"
                  :disabled="true"
                  clearable
                  maxlength="35"
                />
                <el-button
                  plain
                  style="
                    margin-left: 10px;
                    color: #409eff;
                    border: 1px #409eff solid;
                  "
                  @click="viewCondition(3, val.id)"
                  >发放情况</el-button
                >
              </div>
            </el-form-item>
          </template>
          <div v-else-if="form.billing_type == 11 || form.billing_type == 15">
            <el-form-item
              :label="form.billing_type == 15 ? '优惠类型：' : '搭赠类型：'"
              style="width: 100%"
            >
              <el-select
                v-model="val.subsidy_type"
                placeholder="请选择是否搭赠"
                disabled
              >
                <el-option label="无" :value="0"> </el-option>
                <el-option label="车辆性能检测" :value="3"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="form.billing_type == 15 ? '数量' : '搭赠数量：'"
              v-if="val.subsidy_type == 3"
              style="width: 100%"
            >
              <div class="flex">
                <el-input
                  v-model.trim="val.num"
                  placeholder="请输入次数"
                  clearable
                  disabled
                  maxlength="9"
                  ><span slot="suffix">次</span></el-input
                >
                <el-button
                  plain
                  style="
                    margin-left: 10px;
                    color: #409eff;
                    border: 1px #409eff solid;
                  "
                  @click="viewCondition(4, val.promotion_id, item.id)"
                  >发放情况</el-button
                >
              </div>
            </el-form-item>
          </div>
        </div>
      </div>
    </div>
  </el-form>
  <!-- 发放情况表格 -->
  <el-dialog
    width="70%"
    title="发放情况"
    :visible.sync="innerVisible"
    append-to-body
  >
    <div class="flex">
      <el-button style="margin-left: auto" type="primary" @click="exportExcel"
        >导出</el-button
      >
    </div>
    <el-table
      style="margin-top: 20px"
      ref="tableData"
      :height="tableHeight"
      :data="dataTable"
    >
      <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="用户手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        v-if="type === 4"
        prop="num"
        label="次数"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        :label="type === 2 ? '领取时间' : '获取时间'"
        min-width="150"
        align="center"
      ></el-table-column>
      <div v-if="type === 2">
        <el-table-column
          prop="start_time"
          label="有效期"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="state_name"
          label="状态"
          min-width="100"
          align="center"
        ></el-table-column>
      </div>
    </el-table>

    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-dialog>
  <span slot="footer" class="dialog-foote r">
    <el-button type="primary" @click="confirmForm">确定</el-button>
  </span>
</el-dialog>
