<template>
  <el-dialog
    class="showAll_dialog"
    :title="this.editState == 'edit' ? '编辑' : '新增'"
    width="50%"
    :height="'80%'"
    :max-height="'37.5rem'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @open="open"
  >
    <div style="overflow: auto">
      <div style="min-width: 30rem">
        <el-form
          :model="dataForm"
          style="margin-top: 1.25rem"
          :rules="dataRule"
          ref="dataForm"
          label-width="10.625rem"
          v-loading="formLoading"
        >
          <el-form-item label="用户手机号：" prop="cellphone">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入用户手机号"
              v-model="dataForm.cellphone"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="城市：" prop="city_code">
            <el-select
              v-model="dataForm.city_code"
              placeholder="请选择城市"
              @change="cityChange"
              collapse-tags
              clearable
            >
              <el-option
                v-for="item in cityList"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动名称：" prop="promotion_id">
            <el-select
              v-model="dataForm.promotion_id"
              placeholder="请选择活动"
              @change="activityChange"
              collapse-tags
              clearable
            >
              <el-option
                v-for="item in promotionOpsList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否挂靠活动优惠：" prop="isCoupon">
            <el-select
              v-model="dataForm.isCoupon"
              placeholder="请选择是否挂靠活动优惠"
              :disabled="!dataForm.promotion_id"
              @change="couponChange"
              collapse-tags
              clearable
            >
              <el-option label="否" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <!-- 2 -->
          <div v-if="billing_rule_type === 2 && this.dataForm.isCoupon === 1">
            <el-form-item label="充值档位" prop="recharge">
              <el-select
                v-model="dataForm.recharge"
                placeholder="请选择充值档位"
                @change="rechargeChange"
                collapse-tags
                clearable
              >
                <el-option
                  v-for="(item, index) in dataForm.recharge_rule_list"
                  :key="index"
                  :label="item.recharge"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <div v-for="(item, index) in dataForm.activityList" :key="index">
              <div class="flex">
                <el-form-item label="补贴类型：" style="width: 100%">
                  <el-select
                    v-model="item.subsidy_type"
                    :disabled="statusDisabled"
                    placeholder="请选择补贴类型"
                    @change="subsidyChange($event, index)"
                    collapse-tags
                  >
                    <el-option label="优惠券" :value="1"> </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div v-if="dataForm.activityList.length">
                <div
                  class="flex"
                  v-for="(val, valIndex) in item.coupon_quote_list"
                  :key="valIndex"
                >
                  <el-form-item label="券：">
                    <el-select
                      v-model="val.coupon_id"
                      :disabled="statusDisabled"
                      placeholder="请选择券"
                      collapse-tags
                      clearable
                    >
                      <el-option
                        v-for="couponVal in couponList"
                        :key="couponVal.id"
                        :label="couponVal.name"
                        :value="couponVal.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="张数：" label-width="70px">
                    <el-input-number
                      v-model.number="val.num"
                      :disabled="statusDisabled"
                      style="width: 5.625rem"
                      :min="1"
                      :max="99"
                      controls-position="right"
                      @keyup.native="UpNumber"
                      @keydown.native="UpNumber"
                      label="描述文字"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="有效期：" label-width="90px">
                    <div class="flex">
                      <el-input
                        v-model.trim="val.valid_day"
                        :readonly="statusDisabled"
                        style="width: 6.25rem"
                        placeholder="有效期"
                        clearable
                        maxlength="6"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                      />
                      <span class="marleft10">天</span>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <!-- 1 -->
          <div v-else>
            <div
              v-for="(item, index) in dataForm.activity_subsidy_type_list"
              :key="index"
            >
              <div class="flex">
                <el-form-item
                  label="补贴类型："
                  style="width: 100%"
                  :prop="`activity_subsidy_type_list.${index}.subsidy_type`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择补贴类型',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <el-select
                    v-model="item.subsidy_type"
                    :disabled="statusDisabled"
                    placeholder="请选择补贴类型"
                    @change="subsidyChange($event, index)"
                    collapse-tags
                  >
                    <el-option label="优惠券" :value="1"> </el-option>
                    <el-option label="车辆性能报告" :value="3"> </el-option>
                  </el-select>
                </el-form-item>
                <!-- 加减按钮 -->
                <div
                  v-if="!statusDisabled"
                  class="flex"
                  style="margin-left: 0.625rem; margin-bottom: 1.125rem"
                >
                  <img
                    class="icon"
                    @click="formArrHandleChange(0)"
                    src="@/assets/img/icon-add.png"
                    alt=""
                  />
                  <img
                    v-if="dataForm.activity_subsidy_type_list.length > 1"
                    @click="formArrHandleChange(1, index)"
                    class="icon"
                    src="@/assets/img/icon-delete.png"
                    alt=""
                  />
                </div>
              </div>
              <div
                v-if="
                  item.coupon_quote_list &&
                  item.coupon_quote_list.length &&
                  item.subsidy_type === 1
                "
              >
                <el-form-item
                  label="支付互斥限制："
                  :prop="`activity_subsidy_type_list.${index}.pay_mutex_limit`"
                  v-if="dataForm.isCoupon === 0"
                  :rules="[
                    {
                      required: true,
                      message: '请选择支付互斥限制',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <el-select
                    v-model="item.pay_mutex_limit"
                    placeholder="请选择"
                    collapse-tags
                    clearable
                  >
                    <el-option label="否" :value="0"></el-option>
                    <el-option label="是" :value="1"></el-option>
                  </el-select>
                </el-form-item>
                <div
                  class="flex"
                  v-for="(val, valIndex) in item.coupon_quote_list"
                  :key="valIndex"
                >
                  <el-form-item
                    label="券："
                    :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.coupon_id`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择券',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <el-select
                      v-model="val.coupon_id"
                      :disabled="statusDisabled"
                      placeholder="请选择券"
                      collapse-tags
                      clearable
                    >
                      <el-option
                        v-for="couponVal in couponList"
                        :key="couponVal.id"
                        :label="couponVal.name"
                        :value="couponVal.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="张数："
                    :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.num`"
                    label-width="4.375rem"
                    :rules="[
                      {
                        required: true,
                        message: '请输入张数',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <el-input-number
                      v-model.number="val.num"
                      :disabled="statusDisabled"
                      style="width: 5.625rem"
                      :min="1"
                      :max="99"
                      controls-position="right"
                      @keyup.native="UpNumber"
                      @keydown.native="UpNumber"
                      label="描述文字"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    label="有效期："
                    :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.valid_day`"
                    label-width="5.625rem"
                    :rules="[
                      {
                        required: true,
                        message: '请输入有效期',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <div class="flex">
                      <el-input
                        v-model.trim="val.valid_day"
                        :readonly="statusDisabled"
                        style="width: 6.25rem"
                        placeholder="有效期"
                        clearable
                        maxlength="6"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                      />
                      <span class="marleft10">天</span>
                    </div>
                  </el-form-item>
                  <!-- 加减按钮 -->
                  <div
                    v-if="!statusDisabled"
                    class="flex"
                    style="margin-left: 0.625rem; margin-bottom: 1.125rem"
                  >
                    <img
                      class="icon"
                      @click="formArrHandleChange(2, index)"
                      src="@/assets/img/icon-add.png"
                      alt=""
                    />
                    <img
                      v-if="item.coupon_quote_list.length > 1"
                      @click="formArrHandleChange(3, index, valIndex)"
                      class="icon"
                      src="@/assets/img/icon-delete.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <el-form-item
                v-if="item.subsidy_type === 3"
                label="次数："
                :prop="`activity_subsidy_type_list.${index}.num`"
                :rules="[
                  {
                    required: true,
                    message: '请输入次数',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <el-input
                  v-model.trim="item.num"
                  placeholder="请输入次数"
                  clearable
                  :disabled="statusDisabled"
                  maxlength="8"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                />
              </el-form-item>
            </div>
          </div>
          <el-form-item label="备注：">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="备注信息填写位置"
              v-model="dataForm.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <span slot="footer" class="dialog-foote r">
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from "../../../utils/axiosHelper";
import OPERATE_API from "../../../api/operate_api.js";
export default {
  // name: "AddOrUpdate",
  props: {
    editState: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      billing_rule_type: "",
      visible: false,
      formLoading: false,
      statusDisabled: false,
      cityList: [],
      couponList: [],
      formItem: {},
      promotionOpsList: [],
      dataForm: {
        cellphone: "",
        isCoupon: "",
        city_code: "",
        promotion_id: "",
        remark: "",
        recharge: "",
        activity_subsidy_type_list: [],
        recharge_rule_list: [],
        activityList: [],
      },
      dataRule: {
        cellphone: [
          {
            required: true,
            message:
              '手机号必须是11位，多个手机号时需用","逗号隔开(小写逗号)。多个手机号格式如下(xxx,xxx,xxx)',
            trigger: ["blur", "change"],
            pattern: /^\d{11}(,\d{11}){0,}$/,
          },
        ],
        city_code: [
          {
            required: true,
            message: "请选择城市",
            trigger: ["blur", "change"],
          },
        ],
        promotion_id: [
          {
            required: true,
            message: "清选择活动",
            trigger: ["blur", "change"],
          },
        ],
        isCoupon: [
          {
            required: true,
            message: "请选择是否挂靠活动优惠",
            trigger: ["blur", "change"],
          },
        ],
        recharge: [
          {
            required: true,
            message: "请选择充值档位",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {},
  async mounted() {
    await this.getCityList();
  },
  methods: {
    async open() {
      await this.getCouponList();
      this.hideLoading();
    },
    init(row) {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (row) {
          this.$nextTick(() => {
            this.formItem = row;
            this.dataForm.cellphone = row.cellphone;
            this.dataForm.city_code = row.city_code;
            this.dataForm.promotion_id = row.promotion_id;
            this.dataForm.remark = row.remark;
            this.dataForm.activity_subsidy_type_list =
              row.activity_subsidy_type_list;
            this.dataForm.recharge_rule_list = row.recharge_rule_list;
          });
        } else {
          this.dataForm.cellphone = "";
          this.dataForm.city_code = [];
          this.dataForm.promotion_id = "";
          this.dataForm.remark = "";
          this.dataForm.recharge = "";
          this.dataForm.activity_subsidy_type_list = [];
          this.dataForm.recharge_rule_list = [];
          this.dataForm.activityList = [];
        }
      });
    },
    getCityList() {
      this.axiosHelper
        .post(`/api/partner/areacorp/allList`, {})
        .then((res) => {
          this.cityList = res.data.cityList;
        })
        .catch(() => {
          this.$message.error("加载失败");
        });
    },
    getCouponList() {
      this.axiosHelper
        .get("/api/coupon/all/list", { params: { status: 0 } })
        .then((res) => {
          this.couponList = res.data.data;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    // 获取活动选择列表
    async getPromotionOpsList() {
      this.showLoading("加载中");
      if (!this.dataForm.city_code) {
        this.$message.error({
          message: "请先选择城市",
        });
        return;
      }
      let _this = this;
      let params = {
        cityCode: _this.dataForm.city_code,
        status: 1,
        billingRuleTypes: "1, 2",
      };
      this.axiosHelper
        .get(`/api/activity/list/`, { params })
        .then((res) => {
          if (res.data.code != 0) {
            this.$message.error({
              message: "加载活动列表错误",
            });
            this.hideLoading();
            return;
          }
          this.hideLoading();
          this.promotionOpsList = res.data.data;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    // 城市选择Change
    cityChange(val) {
      this.dataForm.isCoupon = "";
      this.dataForm.recharge = "";
      this.dataForm.promotion_id = "";
      this.dataForm.activity_subsidy_type_list = [];
      this.dataForm.recharge_rule_list = [];
      this.dataForm.activityList = [];
      this.getPromotionOpsList();
    },
    // 选择活动Change
    activityChange(val) {
      if (!val) {
        this.dataForm.isCoupon = "";
        this.dataForm.recharge = "";
        this.dataForm.activity_subsidy_type_list = [];
        this.dataForm.recharge_rule_list = [];
        this.dataForm.activityList = [];
        return;
      }
      this.promotionOpsList.find((item) => {
        if (val === item.id) {
          this.billing_rule_type = item.billing_rule_type;
          switch (item.billing_rule_type) {
            case 1:
              this.dataForm.isCoupon = "";
              this.dataForm.recharge = "";
              this.dataForm.activity_subsidy_type_list = [];
              this.dataForm.recharge_rule_list = [];
              this.dataForm.activityList = [];
              break;
            case 2:
              this.getActivityForm(item.id);
              this.dataForm.isCoupon = 1;
              this.dataForm.recharge = "";
              this.dataForm.activity_subsidy_type_list = [];
              this.dataForm.recharge_rule_list = [];
              this.dataForm.activityList = [];
              break;
          }
        }
      });
    },
    // 充值档位change
    rechargeChange(val) {
      if (!val) {
        this.dataForm.activityList = [];
        return;
      }
      this.dataForm.recharge_rule_list.find((item) => {
        if (item.id === val)
          this.dataForm.activityList = item.activity_subsidy_type_list;
      });
    },
    // 是否挂靠活动优惠券
    couponChange(val) {
      switch (val) {
        case 0:
          this.statusDisabled = false;
          this.dataForm.activity_subsidy_type_list = [];
          this.dataForm.activity_subsidy_type_list.push({
            subsidy_type: 1,
            pay_mutex_limit: 1,
            coupon_quote_list: [
              {
                coupon_id: "",
                valid_day: "",
                num: 1,
              },
            ],
          });
          break;
        case 1:
          this.getActivityForm(this.dataForm.promotion_id);
          break;
        default:
          this.dataForm.activity_subsidy_type_list = [];
          break;
      }
    },
    // 表单提交
    dataFormSubmit() {
      console.log(this.dataForm, "assformformform");
      this.$refs["dataForm"].validate(async (valid) => {
        if (!valid) return;
        this.showLoading("加载中");
        //  新增
        if (this.billing_rule_type === 2 && this.dataForm.isCoupon === 1)
          this.dataForm.activity_subsidy_type_list = this.dataForm.activityList;
        axios
          .post("/api/activity/manual/distribute/activity", this.dataForm)
          .then((res) => {
            this.hideLoading();
            if (res.data.code != 0) {
              this.$message.error(res.data.msg);
              return;
            }
            this.visible = false;
            this.$emit("refreshDataList");
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
            });
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error("加载失败");
          });
      });
    },
    getActivityForm(id) {
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/api/activity/get/detail/${id}`)
        .then((res) => {
          this.$nextTick(() => {
            this.hideLoading();
            this.statusDisabled = true;
            let form = JSON.parse(
              JSON.stringify(
                this.billing_rule_type === 1
                  ? res.data.data.activity_subsidy_type_list
                  : res.data.data.recharge_rule_list
              )
            );
            console.log(form);
            switch (this.billing_rule_type) {
              case 1:
                this.dataForm.activity_subsidy_type_list = form;
                break;
              case 2:
                this.dataForm.recharge_rule_list = form;
                break;
            }
            console.log(this.dataForm);
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    // 补贴类型及券的增加与删除
    formArrHandleChange(type, index, valIndex) {
      // 0、1补贴类型增加与删除
      // 2、3券增加与删除
      switch (type) {
        case 0:
          this.dataForm.activity_subsidy_type_list.push({
            subsidy_type: 1,
            coupon_quote_list: [
              {
                coupon_id: "",
                valid_day: "",
                num: 1,
              },
            ],
          });
          break;
        case 1:
          this.dataForm.activity_subsidy_type_list.splice(index, 1);
          break;
        case 2:
          this.dataForm.activity_subsidy_type_list[
            index
          ].coupon_quote_list.push({
            coupon_id: "",
            valid_day: "",
            num: 1,
          });
          break;
        case 3:
          this.dataForm.activity_subsidy_type_list[
            index
          ].coupon_quote_list.splice(valIndex, 1);
          break;
      }
    },
    // 只能输入汉字、英文、数字
    UpNumber(e, num) {
      e.target.value = e.target.value.replace(/[^\d]/g, "").slice(0, num);
    },
  },
};
</script>
<style lang="scss" scoped>
.form_list {
  &-title {
    &::after {
      content: " ";
      display: inline-block;
      width: 100%;
      height: 0.0625rem;
      background-color: rgb(222, 222, 222);
      margin: 0.625rem 0;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 0.125rem;
      height: 0.9375rem;
      background-color: #f6a83a !important;
      margin-right: 0.375rem;
    }
  }
}

.showAll_dialog {
  overflow: hidden;

  ::v-deep .el-dialog {
    height: 70%;
    overflow: hidden;
    max-width: 50rem;
    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 3.375rem;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
      line-height: 1.875rem;
      padding: 0 0.9375rem 3.75rem;
    }

    .el-dialog__footer {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 100%;
      height: 3.75rem;
      display: flex;
      align-items: center;
      justify-content: right;
      background-color: #ffffff;
      padding: 0 1.25rem 0;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}

.marleft10 {
  margin-left: 0.625rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  &:first-child {
    margin-right: 0.25rem;
  }
}
</style>