
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="投资预算：" label-width="54px" v-if="!isAgent">
        <el-select v-model="form.cityCode" placeholder="全国" clearable>
          <el-option
            v-for="item in investList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号码：">
        <el-input
          v-model.trim="form.cellphone"
          placeholder="请输入手机号码"
          clearable
        />
      </el-form-item>
      <el-form-item label="状态：" label-width="54px" v-if="!isAgent">
        <el-select v-model="form.cityCode" placeholder="全国" clearable>
          <el-option label="全部" :value="0"></el-option>
          <el-option label="已联系" :value="1"></el-option>
          <el-option label="未联系" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>B端优享管理</span>
      <el-button style="float: right" @click="exportData">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      :height="tableHeight"
      :data="dataTable"
      @selection-change="rowSelected"
    >
      <!-- <el-table-column
                  type="selection"
                  width="50"
              align="center"></el-table-column>-->
      <el-table-column
        type="selection"
        width="50"
        align="center"
        :selectable="selectable"
      ></el-table-column>
      <el-table-column label="序号" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="first_city_name"
        label="姓名"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="用户手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="意向城市"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="投资预算"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="状态"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope"> </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handelDetails(scope.row)"
            type="text"
            size="small"
            >明细</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <bEndEnjoyDetailsModal
    ref="detailsModal"
    @getList="getUserDetails"
    :userId="user_id"
  />
</div>
