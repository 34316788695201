<template>
  <el-dialog
    :title="`地推详情-${selectObj.user_number}`"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="1000px"
    @close="close"
  >
    <div class="height:100%;">
      <div style="display: flex; justify-content: space-between">
        <el-form :inline="true">
          <el-form-item label="购买时间" style="margin-bottom: 0">
            <el-date-picker
              v-model="createTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-button style="margin-right: 15px" type="primary" @click="search"
            >查询</el-button
          >
          <el-button style="margin-right: 15px" type="primary" @click="clear"
            >重置</el-button
          >
        </el-form>
        <el-button @click="exportData">导出</el-button>
      </div>
      <div class="total">
        <div class="total-item">交易面额（元）：{{ total.total_amount }}</div>
        <div class="total-item">单数：{{ total.total_number }}</div>
      </div>
      <el-card class="box-card">
        <el-table ref="dataTable" height="50vh" :data="dataTable">
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="city_name"
            label="城市"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="pay_platform_serial_number"
            label="支付平台流水单号"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="time"
            label="购买时间"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="交易面额"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="discount_content"
            label="套餐内容"
            min-width="150"
            align="center"
          ></el-table-column>
        </el-table>
        <el-footer height="32px" style="margin-top: 10px">
          <el-pagination
            background
            layout="total,sizes, prev, pager, next,jumper"
            :total="page_total"
            :page-size="page_size"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :current-page.sync="page_index"
          ></el-pagination>
        </el-footer>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import * as XLSX from "xlsx";
import moment from "moment/moment";
import OPERATE_API from "@/api/operate_api";
import { operateAdd } from "@/api/system/user-api";
export default {
  data() {
    return {
      visible: true,
      form: {
        startTime: "",
        endTime: "",
      },
      page_total: 0,
      page_size: 10,
      page_index: 1,
      dataTable: [],
      total: {
        total_amount: 0,
        total_number: 0,
      },
    };
  },
  props: {
    selectObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    createTime: {
      get() {
        return [this.form.startTime, this.form.endTime];
      },
      set(val) {
        this.form.startTime = val
          ? moment(val[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
        this.form.endTime = val
          ? moment(val[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
    },
  },
  created() {
    this.loadDataTable();
    this.loadDataTotal();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async loadDataTable() {
      const res = await this.axiosHelper.get(
        `/api/user/manage/ground/push/detail/${
          (this.page_index - 1) * this.page_size
        }/${this.page_size}`,
        {
          params: {
            ...this.form,
            userId: this.selectObj.user_id,
          },
        }
      );
      if (res.data.code == 0) {
        this.hideLoading();
        this.dataTable = res.data.data.items;
        this.page_total = res.data.data.total;
      }
    },
    async loadDataTotal() {
      const res = await this.axiosHelper.get(
        `/api/user/manage/ground/push/detail/total`,
        {
          params: {
            ...this.form,
            userId: this.selectObj.user_id,
          },
        }
      );
      if (res.data.code == 0) {
        this.total = res.data.data;
      }
    },
    clear() {
      this.form.startTime = "";
      this.form.endTime = "";
      this.page_index = 1;
      this.loadDataTable();
      this.loadDataTotal();
    },
    search() {
      this.page_index = 1;
      this.loadDataTable();
      this.loadDataTotal();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 36,
        operate_parameter: JSON.stringify({
          ...this.form,
          userId: this.selectObj.user_id,
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/manage/ground/push/detail/export", {
          params: {
            ...this.form,
            userId: this.selectObj.user_id,
          },
        })
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.data);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = `地推详情-${this.selectObj.user_number}.xlsx`;
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["城市"] = item.city_name;
        newObj["支付平台流水单号"] = item.pay_platform_serial_number;
        newObj["购买时间"] = item.time;
        newObj["交易面额"] = item.amount;
        newObj["套餐内容"] = item.discount_content;
        newArray.push(newObj);
      });
      return newArray;
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
.total {
  display: flex;
  margin-top: 30px;
  .total-item {
    width: 50%;
    height: 70px;
    border: 1px solid #eee;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #606266;
    &:first-child {
      margin-right: 20px;
    }
  }
}
</style>