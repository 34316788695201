<template>
    <el-dialog title="二维码" :close-on-click-modal="false" :visible.sync="visible" width="20%" style="height: 100vh;"
        center>
        <div style="text-align: center;">
            <el-image style="align-content: center" :src="imgUrl"></el-image>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="downloadQrcode()">下载</el-button>
            <el-button @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>

    export default {
        props: {

        },
        data() {
            return {
                visible: false,
                imgUrl: '',
                dataItem: {},
            }
        },
        created() {

        },
        mounted() {

        },
        methods: {
            init(row, fileRoot) {
                this.visible = true
                this.dataItem = row
                if (this.dataItem) {
                    this.imgUrl = fileRoot + this.dataItem.qrcode_url
                }
            },
            downloadQrcode() {
                if (this.imgUrl) {
                    this.downloadIamge(this.imgUrl, this.dataItem.merchant_name + "-小程序码.png")
                } else {
                    this.$message.warning("下载失败");
                }
            },
            downloadIamge(imgsrc, name) {//下载图片地址和图片名
                let image = new Image();
                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous");
                image.onload = function () {
                    let canvas = document.createElement("canvas");
                    canvas.width = image.width;
                    canvas.height = image.height;
                    let context = canvas.getContext("2d");
                    context.drawImage(image, 0, 0, image.width, image.height);
                    let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
                    let a = document.createElement("a"); // 生成一个a元素
                    let event = new MouseEvent("click"); // 创建一个单击事件
                    a.download = name || "photo"; // 设置图片名称
                    a.href = url; // 将生成的URL设置为a.href属性
                    a.dispatchEvent(event); // 触发a的单击事件
                };
                image.src = imgsrc;
            },
        }
    }
</script>