<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="商户名称：">
          <el-input
            v-model.trim="form.merchantName"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="城市">
          <el-select
            v-model="form.cityCode"
            placeholder="全国"
            collapse-tags
            filterable
            clearable
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <el-button style="margin-left: 15px" @click="addOrUpdateHandle('add')"
          >新增</el-button
        >
        <el-button style="margin-left: 15px" @click="exportData(FILE_VIEW_URL)"
          >导出</el-button
        >
      </el-row>
      <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
        <el-table-column
          prop="merchant_name"
          label="商户名称"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="merchant_manager"
          label="商户负责人"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="merchant_tel"
          label="商户联系电话"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column label="有效期" min-width="150" align="center">
          <template slot-scope="scope">
            {{ scope.row.valid_time_start }}~{{ scope.row.valid_time_end }}
          </template>
        </el-table-column>
        <el-table-column
          prop="qrcode_url"
          label="二维码"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.qrcode_url"
              style="width: 50px; height: 50px"
              :src="getQrcodeUrl(scope.row.qrcode_url)"
              @click="showWxappQrcodeView(scope.row)"
            >
            </el-image>
            <!--            <span v-if="scope.row.qrcode_url">{{FILE_VIEW_URL+scope.row.qrcode_url}}</span>-->
            <el-button
              v-if="!scope.row.qrcode_url"
              @click="generateWxAppQrcode(scope.row.id)"
              type="text"
              size="small"
              >生成</el-button
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="250" align="center">
          <template slot-scope="scope">
            <el-button
              @click="addOrUpdateHandle('edit', scope.row)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              @click="deleteHandle(scope.row.id)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <RecomMerchantSave
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      :editState="editState"
      @refreshDataList="search"
    >
    </RecomMerchantSave>
    <WxappQrcodeView
      v-if="wxappQrcodeViewVisible"
      ref="wxappQrcodeView"
    ></WxappQrcodeView>
  </div>
</template>
<script>
import OPERATE_API from "../../api/operate_api";
import RecomMerchantSave from "./model/recom-merchant-save.vue";
import WxappQrcodeView from "./model/wxapp-qrcode-view.vue";
import * as XLSX from "xlsx";
import agentApi from "../../api/agent_api";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";

export default {
  components: {
    RecomMerchantSave,
    WxappQrcodeView,
  },
  data() {
    return {
      cityList: [],
      form: {
        cityCode: "",
        merchantName: "",
      },
      createTime: [],
      multiQuery: {},

      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      addOrUpdateVisible: false,
      wxappQrcodeViewVisible: false,
      file_view_rul: this.FILE_VIEW_ROOT_RUL,
    };
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.search();
  },
  async mounted() {
    // if(this.isAgent){
    //       await this.initAgentCity()
    //     }
    // this.getCityList();
    // this.search();
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  methods: {
    filter() {
      this.filterVisible = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form.cityCode = "";
      this.form.merchantName = "";
      this.search();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    // 新增 / 修改
    addOrUpdateHandle(editState, row) {
      this.addOrUpdateVisible = true;
      this.editState = editState;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(row);
      });
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        ...this.form,
        source: "pc",
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/recom/merchant/select/" + offset + "/" + limit, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.items;
          this.page_total = response.data.total;
          this.$nextTick(() => {
            this.$refs["dataTable"].doLayout();
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    async exportData(fileRoot) {
      await operateAdd({
        operate_type: "1",
        operate_number: 22,
        operate_parameter: JSON.stringify({
          ...this.form,
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        ...this.form,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/recom/merchant/export", {
          params: params,
        })
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.items, fileRoot);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "B端二维码商户.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list, fileRoot) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["商户名称"] = item.merchant_name;
        newObj["城市"] = item.city_name;
        newObj["商户负责人"] = item.merchant_manager;
        newObj["商户联系电话"] = item.merchant_tel;
        newObj["创建时间"] = item.create_time;
        newObj["有效期"] = item.valid_time_start + "~" + item.valid_time_end;
        newObj["二维码"] = fileRoot + item.qrcode_url;
        newArray.push(newObj);
      });
      return newArray;
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    filterSearch(data) {
      this.multiQuery = data;
      this.page_index = 1;
      this.loadDataTable();
    },
    // 删除
    async deleteHandle(id) {
      const confirmResult = await this.$confirm("确定删除该记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .delete("/api/recom/merchant/" + id, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    //生成商户推荐微信小程序二维码
    generateWxAppQrcode(id) {
      let params = {
        page: "pages/Main/index",
        width: 450,
        checkPath: true,
      };
      this.showLoading("加载中");
      this.axiosHelper
        .put("/api/recom/merchant/generate/wxapp/qrcode/" + id, params)
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("生成失败");
        });
    },
    //显示小程序二维码
    showWxappQrcodeView(row) {
      this.wxappQrcodeViewVisible = true;
      this.$nextTick(() => {
        this.$refs.wxappQrcodeView.init(row, this.file_view_rul);
      });
    },
    getQrcodeUrl(qrcode_url) {
      return this.file_view_rul + qrcode_url;
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}
</style>