<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="用户编号：">
          <el-input
            v-model.trim="form.userNumber"
            placeholder="请输入"
            clearable
            maxlength="10"
            oninput="value=value.replace(/[^0-9]/g,'')"
          />
        </el-form-item>
        <el-form-item label="用户手机号：">
          <el-input
            v-model.trim="form.cellphone"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="城市">
          <el-select
            v-model="form.firstCityCode"
            placeholder="全国"
            collapse-tags
            filterable
            clearable
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <el-button style="margin-left: 15px" @click="exportData(FILE_VIEW_URL)"
          >导出</el-button
        >
      </el-row>
      <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="total_amount"
          label="交易总面额"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="viewRow(scope.row)">{{
              scope.row.total_amount
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="total_number"
          label="交易总单"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="viewRow(scope.row)">{{
              scope.row.total_number
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="qrcode_url"
          label="二维码"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.qrcode_url"
              style="width: 50px; height: 50px"
              :src="getQrcodeUrl(scope.row.qrcode_url)"
              @click="showWxappQrcodeView(scope.row)"
            >
            </el-image>
            <!--            <span v-if="scope.row.qrcode_url">{{FILE_VIEW_URL+scope.row.qrcode_url}}</span>-->
            <el-button
              v-if="!scope.row.qrcode_url"
              @click="generateWxAppQrcode(scope.row.user_id)"
              type="text"
              size="small"
              >生成</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <groundPushingModal
      :selectObj="selectObj"
      v-if="isGroundPushingModal"
      @close="isGroundPushingModal = false"
    />
    <el-dialog
      title="二维码"
      :close-on-click-modal="false"
      :visible.sync="wxappQrcodeViewVisible"
      width="500px"
      style="height: 100vh"
      center
    >
      <div style="text-align: center">
        <el-image style="align-content: center" :src="imgUrl"></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadQrcode()">下载</el-button>
        <el-button @click="wxappQrcodeViewVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import OPERATE_API from "../../api/operate_api";
import * as XLSX from "xlsx";
import agentApi from "../../api/agent_api";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";
import groundPushingModal from "./model/ground-pushing-modal.vue";
export default {
  components: {
    groundPushingModal,
  },
  data() {
    return {
      cityList: [],
      form: {
        firstCityCode: "",
        cellphone: "",
        userNumber: "",
      },
      createTime: [],
      multiQuery: {},

      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      addOrUpdateVisible: false,
      wxappQrcodeViewVisible: false,
      file_view_rul: this.FILE_VIEW_ROOT_RUL,
      isGroundPushingModal: false,
      imgUrl: "",
    };
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.search();
  },
  methods: {
    downloadQrcode() {
      if (this.imgUrl) {
        this.downloadIamge(
          this.imgUrl,
          this.selectObj.cellphone + "-小程序码.png"
        );
      } else {
        this.$message.warning("下载失败");
      }
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    viewRow(item) {
      this.selectObj = item;
      this.isGroundPushingModal = true;
    },
    filter() {
      this.filterVisible = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form.firstCityCode = "";
      this.form.cellphone = "";
      this.form.userNumber = "";
      this.search();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    // 新增 / 修改
    addOrUpdateHandle(editState, row) {
      this.addOrUpdateVisible = true;
      this.editState = editState;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(row);
      });
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    async loadDataTable() {
      let params = {
        ...this.form,
        source: "pc",
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      const res = await this.axiosHelper.get(
        `/api/user/manage/ground/push/${offset}/${limit}`,
        {
          params: params,
        }
      );
      if (res.data.code == 0) {
        this.hideLoading();
        this.dataTable = res.data.data.items;
        this.page_total = res.data.data.total;
        this.$nextTick(() => {
          this.$refs["dataTable"].doLayout();
        });
      }
    },
    async exportData(fileRoot) {
      await operateAdd({
        operate_type: "1",
        operate_number: 35,
        operate_parameter: JSON.stringify({
          ...this.form,
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        ...this.form,
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/manage/ground/push/export", {
          params: params,
        })
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.data, fileRoot);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "地推概况列表.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list, fileRoot) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["用户编号"] = item.user_number;
        newObj["手机号"] = item.cellphone;
        newObj["城市"] = item.city_name;
        newObj["交易总面额"] = item.total_amount;
        newObj["交易总单"] = item.total_number;
        newObj["二维码"] = fileRoot + item.qrcode_url;
        newArray.push(newObj);
      });
      return newArray;
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    filterSearch(data) {
      this.multiQuery = data;
      this.page_index = 1;
      this.loadDataTable();
    },
    // 删除
    async deleteHandle(id) {
      const confirmResult = await this.$confirm("确定删除该记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .delete("/api/recom/merchant/" + id, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    //生成商户推荐微信小程序二维码
    generateWxAppQrcode(id) {
      this.showLoading("加载中");
      this.axiosHelper
        .post("/api/qrcode/user/generate", {
          business_type: 4,
          page: "pages/scan/index",
          user_id: id,
        })
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("生成失败");
        });
    },
    //显示小程序二维码
    showWxappQrcodeView(row) {
      this.wxappQrcodeViewVisible = true;
      this.selectObj = row;
      this.imgUrl = this.file_view_rul + row.qrcode_url;
    },
    getQrcodeUrl(qrcode_url) {
      return this.file_view_rul + qrcode_url;
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}
</style>