/*
 * @Author: ljf
 * @Date: 2022-06-15 09:06:36
 * @LastEditors: ljf
 * @LastEditTime: 2022-06-29 10:14:05
 * @FilePath: \lxm-admin\src\api\system\role-api.js
 * @Description:
 *
 *
 */
import axios from "utils/axiosHelper";
import { Message } from "element-ui";
export const getAllRole = (appId) => {
  return axios
    .get(`api/role/list?appId=${appId}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      Message.error("获取系统角色失败");
    });
};
export const getRolePage = (params) => {
  return axios
    .get(`api/role/`, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      params,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch(() => {
      Message.error("获取角色失败");
    });
};
export const checkRole = (roleDto) => {
  return axios
    .post(
      `api/role/check`,
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      },
      {
        params: roleDto,
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    });
};

export const createRole = (roleDto) => {
  return axios
    .post(`api/role`, roleDto)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      Message.error("创建角色失败");
    });
};
export const updateRole = (roleDto) => {
  return axios
    .put(`api/role`, roleDto)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      Message.error("更新角色失败");
    });
};

export const deleteRole = (ids) => {
  return axios
    .delete(`api/role/${ids}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      Message.error("删除角色失败");
    });
};

export const roleUserAssign = (params) => {
  return axios.put(`/api/role/user/assign`,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    },
    {
      params
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
}

export const getRoleUsers = (roleId) => {
  return axios.get(`/api/role/user?roleId=${roleId}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
}

export const getRoleUserPage = (page, offset, limit, roleId) => {
  return axios.get(`/api/role/page/user?page=${page}&$offset=${offset}&$limit=${limit}&roleId=${roleId}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
}

export const getRoleUserByType = (type) => {
  return axios.get(`/api/rest/role/${type}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
}