<template>
  <el-dialog
    title="设备列表"
    v-dialogDrag
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      ref="form"
      style="padding-right: 10px"
      :model="form"
      label-width="110px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="设备编号：">
            <el-input
              v-model.trim="form.equipment_no"
              clearable
              placeholder="请输入设备编号"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="设备状态：">
            <el-select
              v-model="form.equipment_state"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in equipmentStateDim"
                :key="item.data_value"
                :value="item.data_value"
                :label="item.data_name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="创建时间：">
            <el-date-picker
              class="css-forbid-flash"
              style="width: 100%"
              v-model="form.create_time"
              type="date"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button
            style="margin-left: 10px"
            type="primary"
            @click="handleCurrentChange(1)"
            >查询</el-button
          >
          <el-button type="default" @click="clear">重置</el-button>
        </el-col>
      </el-row>
      <el-table
        :data="table_data"
        height="300"
        border
        @selection-change="rowSelected"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="equipment_no"
          label="设备编号"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_name"
          label="设备名称"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_type_name"
          label="设备类型"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_power"
          label="设备功率"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_capacity"
          label="容量（kWh）"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="rate"
          label="电量百分比"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_state_name"
          label="设备状态"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_org"
          label="归属网点"
          min-width="150"
          align="center"
        >
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="default" @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import DIM_API from "@/api/dim_api";
import EQUIPMENT_API from "@/api/equipment_api";
export default {
  props: {
    dialog: {
      type: Boolean,
      default() {
        return false;
      },
    },
    //1：单选 2：多选
    mode: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {},
      equipmentStateDim: [],
      table_data: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      select_row: [],
    };
  },
  mounted() {
    this.initDim();
  },
  watch: {
    dialog: {
      immediate: true,
      handler(value) {
        this.dialogVisible = value;
        if (value) {
          this.search();
        }
      },
    },
    dialogVisible: {
      handler(value) {
        this.$emit("update:dialog", value);
      },
    },
  },
  methods: {
    async initDim() {
      let res = await DIM_API.getByType("equipment_state");
      this.equipmentStateDim = res.data;
    },
    cancel() {
      this.dialogVisible = false;
    },
    confirm() {
      if (this.select_row.length === 0) {
        this.$message.warning("请先选择设备");
        return;
      }
      if (this.select_row.length > 1 && this.mode === 1) {
        this.$message.warning("只能选择一个设备");
        return;
      }
      this.dialogVisible = false;
      this.$emit(
        "confirm",
        this.mode === 1 ? this.select_row[0] : this.select_row
      );
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.search();
    },
    rowSelected(rows) {
      this.select_row = rows;
    },
    async search() {
      this.form.$limit = this.page_size;
      this.form.$offset = this.page_size * (this.page_index - 1);
      this.form.equipmentState = 0;
      this.form.availability = 1;
      this.showLoading("加载中");
      let res = await EQUIPMENT_API.getByCondition(this.form).finally((_) => {
        this.hideLoading();
      });
      this.page_total = res.data.total_count;
      this.table_data = res.data.items;
    },
    clear() {
      this.form = {};
      this.handleCurrentChange(1);
    },
  },
};
</script>
<style scoped>
</style>