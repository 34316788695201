
<div class="sidebar">
  <el-scrollbar
    style="height: 100%"
    wrapStyle="overflow-x:hidden;"
    viewStyle=""
  >
    <el-menu
      ref="menu"
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#ffffff"
      text-color="#333333"
      active-text-color="#ffffff"
      unique-opened
      router
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :fid="item.index" :index="item.index" :key="item.id">
            <template slot="title">
              <!-- <i :class="item.icon"></i><span slot="title">{{ item.title }}</span> -->
              <i
                v-if="item.icon.indexOf('-icon-') > -1"
                :class="item.icon"
              ></i>
              <img
                v-else-if="item.icon !== ''"
                style="display: inline-block; padding: 1px 10px 0 8px"
                height="17px"
                :src="
                  getImg(
                    item.icon,
                    item.index === current_route_path && !collapse
                  )
                "
                alt=""
              />
              <span
                :style="{ marginLeft: item.icon == '' ? '8px' : '0' }"
                slot="title"
                >{{ item.title }}</span
              >
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu
                v-if="subItem.subs"
                :fid="subItem.index"
                :index="subItem.index"
                :key="subItem.id"
              >
                <template slot="title"
                  >&#x3000;&#x3000;{{ subItem.title }}</template
                >
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  :fid="threeItem.index"
                  :class="{ active: threeItem.index === current_route_path }"
                >
                  <span class="el-menu-item-box">
                    {{ threeItem.title }}
                  </span>
                </el-menu-item>
              </el-submenu>
              <el-menu-item
                v-else
                :fid="subItem.index"
                :index="subItem.index"
                :key="subItem.index"
                :class="{ active: subItem.index === current_route_path }"
              >
                <span class="el-menu-item-box">
                  {{ subItem.title }}
                </span>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item
            :fid="item.index"
            :index="item.index"
            :key="item.index"
            :class="{ active: item.index === current_route_path }"
          >
            <!-- <i :class="item.icon"></i><span slot="title">{{ item.title }}</span> -->
            <i v-if="item.icon.indexOf('-icon-') > -1" :class="item.icon"></i>
            <img
              v-else-if="item.icon !== ''"
              style="display: inline-block; padding: 1px 10px 0 8px"
              height="17px"
              :src="
                getImg(
                  item.icon,
                  item.index === current_route_path && !collapse
                )
              "
              alt=""
            />
            <img
              v-else
              style="display: inline-block; padding: 1px 0 0 8px"
              height="17px"
              src=""
              alt=""
            />
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </el-scrollbar>
</div>
