
<el-dialog
  :title="!dataForm.id ? '新增' : '修改'"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <el-form
    :model="dataForm"
    :rules="dataRule"
    ref="dataForm"
    label-width="140px"
  >
    <!-- <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px"> -->
    <!-- <el-form-item label="代理商编号" prop="sn">
      <el-input v-model="dataForm.sn" placeholder="代理商编号，格式：AG-202309-1001，10表示城市id，01表示代理商数量，依次递增"></el-input>
    </el-form-item> -->
    <el-form-item label="代理商名称" prop="agentName">
      <el-input
        v-model="dataForm.agentName"
        placeholder="代理商名称"
      ></el-input>
    </el-form-item>
    <!-- <el-form-item label="代理商名称首字母" prop="agentNameChar" >
      <el-input v-model="dataForm.agentNameChar" placeholder="代理商名称首字母"></el-input>
    </el-form-item> -->
    <el-form-item label="信用代码" prop="creditCode">
      <el-input
        v-model="dataForm.creditCode"
        placeholder="信用代码"
      ></el-input>
    </el-form-item>
    <el-form-item label="城市行政区划编码" prop="cityCode">
      <el-input
        v-model="dataForm.cityCode"
        placeholder="城市行政区划编码"
      ></el-input>
    </el-form-item>
    <el-form-item label="代理商联系人" prop="callName">
      <el-input
        v-model="dataForm.callName"
        placeholder="代理商联系人"
      ></el-input>
    </el-form-item>
    <el-form-item label="代理商联系电话" prop="callPhone">
      <el-input
        v-model="dataForm.callPhone"
        placeholder="代理商联系电话"
      ></el-input>
    </el-form-item>
    <!-- <el-form-item label="数据有效状态" prop="status" >
      <el-input v-model="dataForm.status" placeholder="数据有效状态（0无效；1有效）"></el-input>
    </el-form-item> -->
    <el-form-item label="备注" prop="remark">
      <el-input
        type="textarea"
        v-model="dataForm.remark"
        placeholder="备注"
      ></el-input>
    </el-form-item>
    <!-- <el-form-item label="创建人id" prop="createId">
      <el-input v-model="dataForm.createId" placeholder="创建人id"></el-input>
    </el-form-item>
    <el-form-item label="写入时间 YYYY-MM-DD HH24:MM:SS" prop="createTime">
      <el-input v-model="dataForm.createTime" placeholder="写入时间 YYYY-MM-DD HH24:MM:SS"></el-input>
    </el-form-item> -->
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="visible = false">取消</el-button>
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
  </span>
</el-dialog>
