
<div class="height:100%;">
  <el-card class="box-card" style="height: 10%">
    <el-form :inline="true">
      <el-form-item label="活动名称：">
        <el-input v-model.trim="form.name" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="城市">
        <el-select
          v-model="form.cityCode"
          placeholder="全部"
          collapse-tags
          clearable
          filterable
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="form.status"
          placeholder="全部"
          collapse-tags
          clearable
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="场景">
        <el-select
          v-model="form.billingType"
          placeholder="全部"
          collapse-tags
          clearable
        >
          <el-option
            v-for="item in sceneList"
            :key="item.id"
            :label="item.billing_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <el-button
        style="margin-left: 15px"
        v-if="user.isMaintenanceOrder == 1"
        @click="addOrUpdateHandle('add')"
        >新增</el-button
      >
      <el-button style="margin-left: 15px" @click="exportData"
        >导出</el-button
      >
    </el-row>
    <el-table ref="tableData" :height="tableHeight" :data="dataTable">
      <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
      <el-table-column
        prop="name"
        label="活动名称"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="promotion_show_name"
        label="活动展示名称"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="billing_name"
        label="场景"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="start_time"
        label="活动时间"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="remaining_amount"
        label="剩余补贴金"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="person_limit_name"
        label="人数限制"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="grant_person_num"
        label="已发人数"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="cur_status"
        label="状态"
        min-width="100"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.cur_status === 1
                ? "进行中"
                : scope.row.cur_status === 2
                ? "停用"
                : scope.row.cur_status === 3
                ? "已结束"
                : "待开始"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button
            @click="addOrUpdateHandle('copy', scope.row)"
            type="text"
            size="small"
          >
            复制
          </el-button>
          <el-button
            v-if="scope.row.cur_status === 1 && user.isMaintenanceOrder == 1"
            @click="statusSwitch(scope.row)"
            type="text"
            size="small"
          >
            停用
          </el-button>
          <el-button
            v-if="scope.row.cur_status === 2 && user.isMaintenanceOrder == 1"
            @click="statusSwitch(scope.row)"
            type="text"
            size="small"
          >
            启用
          </el-button>
          <el-button
            v-if="scope.row.cur_status === 4 && user.isMaintenanceOrder == 1"
            @click="deleteHandle(scope.row)"
            type="text"
            size="small"
            >删除</el-button
          >
          <el-button
            v-if="scope.row.cur_status === 4 && user.isMaintenanceOrder == 1"
            @click="addOrUpdateHandle('edit', scope.row)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            @click="addOrUpdateHandle('details', scope.row)"
            type="text"
            size="small"
            >详情</el-button
          >
          <el-button
            v-if="scope.row.cur_status === 1 && user.isMaintenanceOrder == 1"
            @click="overTime(scope.row)"
            type="text"
            size="small"
            >延长时间</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <!-- 延长时间 -->
  <el-dialog
    :title="'延长有效期'"
    width="600px"
    :height="'30%'"
    :close-on-click-modal="false"
    :visible.sync="pickerShow"
  >
    <el-form
      :model="pickerForm"
      :rules="rules"
      ref="pickerForm"
      label-width="120px"
    >
      <el-form-item label="活动时间：" prop="end_time">
        <div class="flex">
          <el-date-picker
            v-model="pickerForm.start_time"
            style="width: 300px"
            type="datetime"
            placeholder="请选择开始时间"
            :disabled="true"
          >
          </el-date-picker>
          <span style="margin: 0 10px">至</span>
          <el-date-picker
            v-model="pickerForm.end_time"
            style="width: 300px"
            type="datetime"
            placeholder="请选择结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <!-- <el-date-picker v-model="dataForm.create_time" type="datetimerange" style="width: 400px"
                  value-format="yyyy-MM-dd HH:mm:ss" :clearable="true" :default-time="['00:00:00', '23:59:59']"
                  range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
                  @change="timeChange"></el-date-picker> -->
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-foote r">
      <el-button type="primary" @click="pickerFormSubmit()">确定</el-button>
      <el-button @click="pickerShow = false">取消</el-button>
    </span>
  </el-dialog>
  <activityAddModal
    ref="addOrUpdate"
    :editState="editState"
    @refreshDataList="search"
  >
  </activityAddModal>
  <activityDetailsModal ref="detailsForm"> </activityDetailsModal>
</div>
