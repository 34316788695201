
<el-dialog
  class="showAll_dialog"
  :title="getDialogTitle()"
  width="1000px"
  :height="'80%'"
  :max-height="'450px'"
  :close-on-click-modal="false"
  :visible.sync="visible"
  append-to-body
>
  <el-form
    :model="form"
    :rules="dataRule"
    ref="form"
    label-width="110px"
    v-loading="formLoading"
  >
    <div class="flex">
      <el-form-item label="资讯标题" prop="news_title">
        <el-input
          v-model.trim="form.news_title"
          placeholder="请输入资讯标题："
          clearable
          maxlength="35"
        />
      </el-form-item>
      <el-form-item label="副标题" prop="subtitle">
        <el-input
          v-model.trim="form.subtitle"
          placeholder="请输入副标题"
          clearable
          maxlength="35"
        />
      </el-form-item>
      <el-form-item label="类型" prop="newsType">
        <el-select v-model="form.news_type">
          <el-option value="1" label="电池保养常识"></el-option>
          <el-option value="2" label="查看教程"></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="flex" style="margin-top: 10px">
      <el-form-item label="封面" prop="cover_pic_url">
        <el-upload
          v-if="!form.cover_pic_url"
          v-model="form.cover_pic_url"
          :action="uploadUrl"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :multiple="false"
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :http-request="upload"
          :before-upload="beforeUp"
          list-type="picture-card"
          :disabled="editState != 'details' ? false : true"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <div v-else class="image-preview">
          <div class="image-preview-wrapper">
            <img :src="file_view_rul + form.cover_pic_url" />
            <div class="image-preview-action">
              <i @click="handleRemove()" class="el-icon-delete"></i>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="发布日期" prop="release_time" label-width="166px">
        <el-date-picker
          v-model="form.release_time"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="请选择发布日期"
          :picker-options="pickerOptions"
          @change="handleDateChange"
        ></el-date-picker>
      </el-form-item>
    </div>
    <el-form-item label="详情内容" prop="details">
      <VueUeditorWrap v-model="form.details" :config="myConfig" />
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="formSubmit()">确定</el-button>
    <el-button v-if="editState != 'details'" @click="visible = false"
      >取消</el-button
    >
  </span>
</el-dialog>
