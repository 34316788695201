
<el-dialog
  :visible.sync="model_dialog"
  v-if="model_dialog"
  :title="`设备详情(${type_name})`"
  append-to-body
  :close-on-click-modal="false"
  @close="model_dialog = false"
>
  <el-row>
    <el-row class="block-header">
      <el-col :span="8">
        <span class="block-title">基本信息</span>
      </el-col>
    </el-row>
    <el-form
      ref="form"
      style="padding-right: 10px"
      :model="form"
      :rules="rules"
      label-width="110px"
      v-loading="loading"
      element-loading-background="rgba(204, 204, 204, 0.6)"
      element-loading-text="基础数据正在加载中..."
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="代理商：">
            <el-autocomplete
              :disabled="is_see"
              v-model="state"
              :fetch-suggestions="querySearchAsync"
              @select="handleSelect"
              placeholder="输入代理商名称首字母"
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.agent_name }}</div>
              </template>
            </el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="城市：">
            <el-select
              disabled
              v-model="form.city_code"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in cityList"
                :key="item.cityCode"
                :value="item.cityCode"
                :label="item.cityName"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
                      <el-form-item label="设备物理状态：">
                          <el-select :disabled="is_see" v-model="form.physical_state" clearable style="width: 100%">
                              <el-option  v-for="item in dim_physical_state"
                                          :key="item.code"
                                          :value="item.code"
                                          :label="item.desc"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col> -->
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="设备编号：" prop="equipment_no">
            <el-input
              :disabled="is_see"
              v-model.trim="form.equipment_no"
              clearable
              placeholder="请输入设备编号"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="设备名称：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.equipment_name"
              clearable
              placeholder="请输入设备名称"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="设备类型：" prop="equipment_type">
            <el-select
              :disabled="is_see"
              v-model="form.equipment_type"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in dim_equipment_type"
                :key="item.data_value"
                :value="item.data_value"
                :label="item.data_name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="版本号：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.version"
              clearable
              placeholder="请输入版本号"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务器地址：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.service_ip"
              clearable
              placeholder="请输入服务器地址"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="GPS设备标识：" label-width="130px">
            <el-input
              :disabled="is_see"
              v-model.trim="form.gps_imei"
              clearable
              placeholder="请输入GPS设备标识"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-container>
        <el-aside width="607px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="设备功率：">
                <el-input
                  :disabled="is_see"
                  v-model.trim="form.equipment_power"
                  clearable
                  placeholder="请输入设备功率"
                  maxlenth="7"
                  oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                >
                  <template slot="append" style="width: 10px">kW</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备容量：">
                <el-input
                  :disabled="is_see"
                  v-model.trim="form.equipment_capacity"
                  clearable
                  placeholder="请输入设备容量"
                  maxlenth="7"
                  oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                >
                  <template slot="append">kWh</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="长宽高：">
                <el-input
                  :disabled="is_see"
                  v-model.trim="form.equipment_describe"
                  clearable
                  placeholder="长宽高"
                  maxlenth="30"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重量：">
                <el-input
                  :disabled="is_see"
                  v-model.trim="form.equipment_weight"
                  clearable
                  placeholder="请输入重量"
                  maxlenth="7"
                  oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                >
                  <template slot="append">KG</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="归属网点：">
                <el-input
                  :disabled="is_see"
                  v-model.trim="form.equipment_org"
                  clearable
                  placeholder="归属网点"
                  autocomplete="off"
                  maxlenth="36"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="可用状态：" prop="availability">
                <el-select
                  :disabled="is_see"
                  v-model="form.availability"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in dim_availability"
                    :key="item.data_value"
                    :value="item.data_value"
                    :label="item.data_name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!--                        <el-row>-->
          <!--                            <el-col :span="12">-->
          <!--                                <el-form-item label="设备枪号：" prop="gun_no">-->
          <!--                                    <el-input :disabled="is_see" v-model.trim="form.gun_no" clearable placeholder="请输入设备枪号" maxlenth="100"/>-->
          <!--                                </el-form-item>-->
          <!--                            </el-col>-->
          <!--                        </el-row>-->
        </el-aside>
        <el-main
          style="margin-top: -24px; margin-left: -19px; margin-bottom: -17px"
        >
          <el-form-item label="设备图片：">
            <upload_pic
              :file.sync="form.image_file"
              :disabled="is_see"
            ></upload_pic>
          </el-form-item>
        </el-main>
      </el-container>
      <el-row>
        <el-form-item label="备注：">
          <el-input
            :disabled="is_see"
            :rows="4"
            type="textarea"
            v-model="form.remark"
            show-word-limit
            maxlength="500"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-row>
    </el-form>
  </el-row>
  <el-row v-if="is_see">
    <el-row class="block-header">
      <span class="block-title">工况参数</span>
      <el-button style="margin-left: 29px; margin-top: -9px" @click="test"
        >实时更新</el-button
      >
    </el-row>
    <el-form
      ref="form"
      style="padding-right: 10px"
      :model="form"
      label-width="110px"
      v-loading="loading"
      element-loading-background="rgba(204, 204, 204, 0.6)"
      element-loading-text="基础数据正在加载中..."
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="设备状态：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.equipment_state_name"
              clearable
              placeholder="请输入工况评估"
              maxlenth="100"
            />
            <!--                            <el-select :disabled="is_see" v-model="form.equipment_state" clearable style="width: 100%">-->
            <!--                                <el-option  v-for="item in dim_equipment_state"-->
            <!--                                            :key="item.data_value"-->
            <!--                                            :value="item.data_value"-->
            <!--                                            :label="item.data_name"-->
            <!--                                ></el-option>-->
            <!--                            </el-select>-->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工况评估：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.working_condition_assessment"
              clearable
              placeholder="请输入工况评估"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="电池温度：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.battery_temperature"
              clearable
              placeholder="请输入电池温度"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="充电电压：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.charging_voltage"
              clearable
              placeholder="请输入充电电压"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="充电电流：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.recharging_current"
              clearable
              placeholder="请输入充电电流"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="充电功率：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.charging_power"
              clearable
              placeholder="请输入充电功率"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="电量百分比：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.battery_percentage"
              clearable
              placeholder="请输入电量百分比"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="剩余电量：">
            <el-input
              :disabled="is_see"
              v-model.trim="form.remaining_battery"
              clearable
              placeholder="请输入剩余电量"
              maxlenth="100"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-row>
  <div slot="footer" class="dialog-footer">
    <el-button @click="model_dialog = false">返回</el-button>
    <el-button type="primary" @click="click" v-if="!is_see">保存</el-button>
  </div>
</el-dialog>
