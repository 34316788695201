
<el-dialog
  title="修改密码"
  v-dialogDrag
  :visible.sync="changePassword"
  width="20%"
  :close-on-click-modal="false"
  v-if="changePassword"
>
  <el-form
    ref="form"
    :model="password"
    label-width="100px"
    :rules="rules"
    label-position="left"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item
          label="旧密码:"
          prop="oldPassword"
        >
          <el-input
            v-model.trim="password.oldPassword"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item
          label="新密码:"
          prop="password1"
        >
          <el-input
            v-model.trim="password.password1"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item
          label="确认新密码:"
          prop="password2"
        >
          <el-input
            v-model.trim="password.password2"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-button
          type="primary"
          @click="onSubmit"
          style="float: right"
          :disabled="updateDisabled"
        >确定修改</el-button>
      </el-col>
      <el-col
        :span="12"
        style="text-align:center"
      >
        <el-button
          type="primary"
          @click="changePassword = false"
        >取消</el-button>
      </el-col>
    </el-row>
  </el-form>
</el-dialog>
