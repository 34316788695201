
<el-row class="block-body">
  <el-form
    ref="roleForm"
    :model="role"
    label-width="80px"
    :rules="rules"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item
          label="角色名称"
          prop="role_name"
        >
          <el-input
            v-model="role.role_name"
            placeholder="请输入角色名称"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          label="角色编号"
          prop="code"
        >
          <el-input
            v-model="role.code"
            placeholder="请输入角色名称"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item
          label="角色类型"
          prop="role_name"
        >
          <el-select
            v-model="role.type"
            value-key="value"
            placeholder="请选择角色类型"
            clearable
            filterable
            :disabled="read_only"
          >
            <el-option
              v-for="item in role_type_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          label="角色状态"
          prop="role_name"
        >
          <el-select
            v-model="role.status"
            value-key="value"
            placeholder="请选择角色类型"
            clearable
            filterable
          >
            <el-option
              v-for="item in role_status_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col
        :span="24"
        :offset="0"
      >
        <el-form-item label="描述">
          <el-input
            type="textarea"
            v-model="role.description"
            :rows="3"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col
        :span="24"
        :offset="0"
      >
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="role.remark"
            :rows="3"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item>
      <el-button
        type="primary"
        @click="submit"
      >确定</el-button>
      <el-button @click="cancel">取消</el-button>
    </el-form-item>
  </el-form>
</el-row>
