<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="优惠券名称：">
          <el-input v-model.trim="form.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="可用范围">
          <el-select
            v-model="form.orderType"
            placeholder="全部"
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in scopeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="form.status"
            placeholder="全部"
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间：">
          <el-date-picker
            v-model="createTime"
            type="datetimerange"
            style="width: 400px"
            value-format="yyyy-MM-dd HH:mm:ss"
            :clearable="true"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          ></el-date-picker>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <el-button style="margin-left: 15px" @click="addOrUpdateHandle('add')"
          >新增</el-button
        >
        <el-button style="margin-left: 15px" @click="exportData"
          >导出</el-button
        >
      </el-row>
      <el-table ref="tableData" :height="tableHeight" :data="dataTable">
        <!-- <el-table-column
                    type="selection"
                    width="50"
                    align="center"></el-table-column> -->
        <el-table-column
          prop="name"
          label="优惠券名称"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_type"
          label="可用范围"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{
                !scope.row.order_type
                  ? "通用"
                  : scope.row.order_type === 1
                  ? "上门充电"
                  : "应急补电"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="discount_type"
          label="类型"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{
                !scope.row.discount_type
                  ? "现金券"
                  : scope.row.discount_type === 1
                  ? "折扣券"
                  : scope.row.discount_type === 3
                  ? "次数券"
                  : "度数券"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="discount_electric"
          label="度数"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="discount"
          label="抵扣金额（元）"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="expect_discount_amount"
          label="预估折算金额（元）"
          min-width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="stauts"
          label="状态"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{
                !scope.row.status
                  ? "正常"
                  : scope.row.status === 1
                  ? "过期"
                  : "停用"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              @click="statusSwitch(scope.row)"
              type="text"
              size="small"
              >{{ !scope.row.status ? "停用" : "启用" }}</el-button
            >
            <el-button
              @click="addOrUpdateHandle('edit', scope.row)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button @click="deleteHandle(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <couponSettingAdd
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      :editState="editState"
      @refreshDataList="search"
    >
    </couponSettingAdd>
  </div>
</template>
<script>
import couponSettingAdd from "./model/coupon-setting-add.vue";
import * as XLSX from "xlsx";
import agentApi from "../../api/agent_api";
import { operateAdd } from "@/api/system/user-api";

export default {
  components: {
    couponSettingAdd,
  },
  data() {
    return {
      cityList: [],
      form: {
        name: "",
        orderType: "",
        status: "",
      },
      scopeList: [
        {
          label: "通用",
          value: 0,
        },
        {
          label: "上门充电",
          value: 1,
        },
        {
          label: "应急补电",
          value: 2,
        },
      ],
      statusList: [
        {
          label: "正常",
          value: 0,
        },
        {
          label: "停用",
          value: 2,
        },
      ],
      createTime: [],
      multiQuery: {},
      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      addOrUpdateVisible: false,
    };
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.search();
  },
  mounted() {},
  methods: {
    filter() {
      this.filterVisible = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async search() {
      if (this.createTime) {
        this.form.createTimeStart = this.createTime[0];
        this.form.createTimeEnd = this.createTime[1];
      } else {
        this.form.createTimeStart = null;
        this.form.createTimeEnd = null;
      }
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {
        name: "",
        orderType: "",
        status: "",
      };
      this.createTime = [];
      this.page_index = 1;
      this.loadDataTable();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    // 新增 / 修改
    addOrUpdateHandle(editState, row) {
      this.addOrUpdateVisible = true;
      this.editState = editState;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(row);
      });
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        ...this.form,
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/api/coupon/query/${offset}/${limit}`, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.data.items;
          this.page_total = response.data.data.total;
          //   this.$nextTick(() => {
          //     this.$refs["dataTable"].doLayout();
          //   });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 17,
        operate_parameter: JSON.stringify({
          ...this.form,
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        ...this.form,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/coupon/all/list", {
          params: params,
        })
        .then((res) => {
          console.log(res.data.data, "asdasdasd");
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.data);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "优惠券设置.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["优惠券名称"] = item.name;
        newObj["可用范围"] = item.orderType === 1 ? "上门充电" : "应急补电";
        newObj["类型"] = !item.discount_type
          ? "现金券"
          : item.discount_type === 1
          ? "折扣券"
          : "度数券";
        newObj["度数"] = item.discount_electric;
        newObj["折扣金额（元）"] = item.discount;
        newObj["预估折算金额（元）"] = item.expect_discount_amount;
        newObj["创建时间"] = item.create_time;
        newObj["状态"] = !item.stauts
          ? "正常"
          : item.status === 1
          ? "过期"
          : "停用";
        newArray.push(newObj);
      });
      return newArray;
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    // 删除
    async deleteHandle(row) {
      const confirmResult = await this.$confirm(
        `确定删除${row.name}该优惠券么?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .delete("/api/coupon/del/" + row.id, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    async statusSwitch(row) {
      const confirmResult = await this.$confirm(
        `确定${!row.discountType ? "停用" : "启用"}${row.name}优惠券么？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .put(`/api/coupon/set/status/${row.id}/${!row.status ? 2 : 0}`, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}
</style>
