const OBJECT_UTIL = {};
/**
 * 下划线转驼峰
 * @param value
 * @returns {*}
 */
OBJECT_UTIL.formatToHump = (value) => {
    return value.replace(/\_(\w)/g, (_, letter) => letter.toUpperCase())
}
/**
 * 对象key批量转换（下划线转驼峰）
 * @param obj
 * @returns {{}}
 * @constructor
 */
OBJECT_UTIL.ObjectKeyConvert = (obj) => {
    let newObject = {};
    Object.entries(obj).forEach(([key, value]) => {
        newObject[OBJECT_UTIL.formatToHump(key)] = value
    });
    return newObject;
}


// 字符串的驼峰格式转下划线格式，eg：helloWorld => hello_world
OBJECT_UTIL.hump2Underline=(value)=>{
    return value.replace(/([A-Z])/g, '_$1').toLowerCase()
}

// JSON对象的key值转换为下划线格式
// function jsonToUnderline(obj) {
//     if (obj instanceof Array) {
//       obj.forEach(function(v, i) {
//         jsonToUnderline(v)
//       })
//     } else if (obj instanceof Object) {
//       Object.keys(obj).forEach(function(key) {
//         var newKey = hump2Underline(key)
//         if (newKey !== key) {
//           obj[newKey] = obj[key]
//           delete obj[key]
//         }
//         jsonToUnderline(obj[newKey])
//       })
//     }
//   }
  // JSON对象的key值转换为下划线格式
  OBJECT_UTIL.jsonToUnderline=(obj)=>{
    let newObject = {};
    Object.entries(obj).forEach(([key, value]) => {
        newObject[OBJECT_UTIL.hump2Underline(key)] = value
    });
    return newObject;
  }

export default OBJECT_UTIL;