
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <div class="box-card-main">
      <p class="title">数据筛选</p>
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
        >
          <div class="flex">
            <el-form-item
              label="城市"
              label-width="60px"
              style="margin-bottom: 0"
            >
              <el-select
                v-model="searchValue.cityCode"
                placeholder="请选择城市"
                clearable
                filterable
              >
                <el-option
                  v-for="item in restrictedCityList"
                  :key="item.cityCode"
                  :label="item.cityName"
                  :value="item.cityCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="手机号"
              label-width="80px"
              style="margin-bottom: 0"
            >
              <el-input
                v-model.trim="searchValue.cellphone"
                oninput="value=value.replace(/[^0-9]/g,'')"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="用户编号"
              label-width="80px"
              style="margin-bottom: 0"
            >
              <el-input
                v-model.trim="searchValue.userNumber"
                maxlength="10"
                oninput="value=value.replace(/[^0-9]/g,'')"
                clearable
              />
            </el-form-item>
            <el-button
              type="primary"
              @click="search"
              style="margin-left: 10px"
              >查询</el-button
            >
            <el-button type="default" @click="clear">重置</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>车辆检测钱包</span>
      <el-button style="float: right" @click="exportData">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
    >
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="手机号"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="available_car_assess_report_num"
        label="剩余检测次数"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" min-width="150" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="detail(scope.row)">明细</el-button>
          <el-button
            type="text"
            v-if="user.isMaintenanceOrder == 1"
            @click="deduction(scope.row)"
            >扣减次数</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <el-dialog
    title="扣减次数"
    :visible.sync="deductionDialogVisible"
    width="500px"
  >
    <el-form :rules="dataRule" ref="dataForm" :model="dataForm">
      <el-form-item label="用户编号：">
        <span>{{ selectObj.user_number }}</span>
      </el-form-item>
      <el-form-item label="用户手机号：">
        <span>{{ selectObj.cellphone }}</span>
      </el-form-item>
      <el-form-item label="剩余检测次数：">
        <span>{{ selectObj.available_car_assess_report_num }}次</span>
      </el-form-item>
      <el-form-item label="扣减次数：" prop="reduce_number">
        <el-input
          style="width: 200px"
          v-model="dataForm.reduce_number"
          @input="deductionNum"
          placeholder="请输入扣减次数"
        >
          <span slot="suffix">次</span>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="deductionDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="deductionDialogSubmit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
  <detectionWalletDetailModal
    v-if="isDetectionWalletDetailModal"
    :selectObj="selectObj"
    @close="isDetectionWalletDetailModal = false"
  />
</div>
