<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <div class="box-card-main">
        <p class="title">数据筛选</p>
        <div class="text item">
          <el-form
            ref="searchValue"
            style="padding-right: 10px"
            :model="searchValue"
          >
            <el-row type="flex">
              <el-col :span="3">
                <el-form-item
                  label="城市"
                  label-width="60px"
                  style="margin-bottom: 0"
                >
                  <el-select
                    v-model="searchValue.city_code"
                    placeholder="请选择城市"
                    multiple
                    collapse-tags
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in restrictedCityList"
                      :key="item.cityCode"
                      :label="item.cityName"
                      :value="item.cityCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item
                  label="手机号"
                  label-width="80px"
                  style="margin-bottom: 0"
                >
                  <el-input v-model.trim="searchValue.cellphone" clearable />
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item
                  label="用户编号"
                  label-width="80px"
                  style="margin-bottom: 0"
                >
                  <el-input
                    v-model.trim="searchValue.userNumber"
                    maxlength="10"
                    oninput="value=value.replace(/[^0-9]/g,'')"
                    clearable
                  />
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item
                  label="充值状态"
                  label-width="90px"
                  style="margin-bottom: 0"
                >
                  <el-select
                    v-model="searchValue.recharge_status"
                    clearable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in paymentStatusDim"
                      :key="item.data_value"
                      :value="item.data_value"
                      :label="item.data_name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="充值时间"
                  label-width="100px"
                  style="margin-bottom: 0"
                >
                  <el-date-picker
                    v-model="time"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    style="width: auto"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="4" style="float: left; text-align: left">
                <el-form-item
                  label="充值类型："
                  label-width="90px"
                  style="margin-bottom: 0"
                >
                  <el-select
                    v-model="searchValue.type"
                    placeholder="全部"
                    collapse-tags
                    clearable
                  >
                    <el-option label="全部" :value="null"></el-option>
                    <el-option label="账户余额" :value="0"></el-option>
                    <el-option label="度数" :value="7"></el-option>
                    <el-option label="B端度数" :value="21"></el-option>
                    <el-option label="车辆性能评估" :value="33"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3" style="float: left; text-align: left">
                <el-button
                  type="primary"
                  @click="clickSearch"
                  style="margin-left: 10px"
                  >查询</el-button
                >
                <el-button type="default" @click="clear">重置</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="filter"
          >高级查询</el-button
        >
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>充值列表</span>
        <el-button style="float: right" @click="exportOrder">导出</el-button>
        <el-button
          v-if="isShowBtn"
          style="float: right; margin-right: 10px"
          @click="addRechargeSubsidy()"
          >手动充值返现</el-button
        >
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
        @selection-change="rowSelected"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="first_city_name"
          label="城市"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_platform_serial_number"
          label="支付平台流水号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="time"
          label="充值时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="实付金额（元）"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="degree"
          label="充值度数（度）"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="充值类型"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type === 7">度数</span>
            <span v-else-if="scope.row.type === 0">账户余额</span>
            <span v-else-if="scope.row.type === 21">B端度数</span>
            <span v-else-if="scope.row.type === 33">车辆性能评估</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="pay_status_name"
          label="充值状态"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="手机号码"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="real_name"
          label="姓名"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_method_name"
          label="支付方式"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="open_id"
          label="微信ID"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="recommend_type_name"
          label="推荐类型"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="reference_name"
          label="推荐人"
          min-width="120"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="serial_number" label="充值流水号" min-width="150" align="center"></el-table-column> -->
        <!-- <el-table-column prop="wechat_no" label="微信号" min-width="150" align="center"></el-table-column> -->
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="early_amount"
          label="充值前"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type == 33">
              {{ scope.row.early_num }}
            </span>
            <span v-else>
              <span v-if="scope.row.early_amount == null">{{
                scope.row.early_available_degree
              }}</span>
              <span v-else>{{ scope.row.early_amount }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="current_amount"
          label="充值后"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type == 33">
              {{ scope.row.early_num + scope.row.num }}
            </span>
            <span v-else>
              <span v-if="scope.row.current_amount == null">{{
                scope.row.cur_available_degree
              }}</span>
              <span v-else>{{ scope.row.current_amount }}</span>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <el-dialog
      title="手动充值返现"
      :close-on-click-modal="false"
      :visible.sync="visible"
      width="40%"
    >
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        label-width="110px"
      >
        <el-form-item label="城市" prop="cityCode">
          <el-select
            v-model="dataForm.cityCode"
            placeholder="请选择城市"
            @change="cityOpsChange"
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动" prop="promotionId">
          <el-select v-model="dataForm.promotionId" placeholder="请选择城市">
            <el-option
              v-for="item in promotionOpsList"
              :key="item.promotionId"
              :label="item.name"
              :value="item.promotionId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="cellphone">
          <el-input
            disabled
            v-model="dataForm.cellphone"
            placeholder="用户手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="流水id" prop="id">
          <el-input
            disabled
            v-model="dataForm.id"
            placeholder="流水id"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付平台流水号" prop="pay_platform_serial_number">
          <el-input
            disabled
            v-model="dataForm.pay_platform_serial_number"
            placeholder="支付平台流水号"
          ></el-input>
        </el-form-item>
        <el-form-item label="充值金额" prop="rechargeMount">
          <el-input
            disabled
            v-model="dataForm.rechargeMount"
            placeholder="充值金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="返现金额" prop="amount">
          <el-input
            v-model="dataForm.amount"
            placeholder="请填写返现金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="dataForm.remark"
            placeholder="备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      </span>
    </el-dialog>
    <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
  </div>
</template>
<script>
import DIM_API from "../../api/dim_api";
import FilterModel from "./model/recharge-search-model.vue";
import OPERATE_API from "../../api/operate_api.js";
import promotionApi from "../../api/promotion_api.js";
import agentApi from "../../api/agent_api";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api.js";
import { recommend_type_list } from "@/enum/userEnum.js";
export default {
  name: "recharge-account",
  components: {
    FilterModel,
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: "",
        pay_platform_serial_number: "",
        cellphone: "",
        user_id: "",
        amount: "",
        remark: "",
        cityCode: "",
        promotionId: "",
        rechargeMount: "", //充值金额
        userNumber: "",
        recommendType: "",
        referenceName: "",
      },
      dataRule: {
        cityCode: [
          { required: true, message: "城市不能为空", trigger: "blur" },
        ],
        promotionId: [
          { required: true, message: "活动不能为空", trigger: "blur" },
        ],
        amount: [
          { required: true, message: "返现金额不能为空", trigger: "blur" },
        ],
      },
      promotionOpsList: [],
      isShowBtn: false,
      total: 0,
      currentPage: 1,
      vloading: false,
      time: [],
      searchValue: {
        $limit: 50,
        $offset: 0,
        serial_number: "",
        recharge_status: "",
        pay_method: "",
        city_code: [],
        timeStart: "",
        timeEnd: "",
        cellphone: "",
        type: null,
      },
      paymentStatusDim: [],
      paymentModeDim: [],
      dataTable: [],
      selection: [],
      pageSize: 50,
      offset: 0,
      limit: 0,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      filterVisible: false,
    };
  },
  async created() {
    if (this.$store.state.user.isMaintenanceOrder == 1) {
      this.isShowBtn = true;
    }
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.initStatusDim();
    this.initModeDim();
    this.search();
  },
  mounted() {
    // this.initStatusDim();
    // this.initModeDim();
    // this.search();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  methods: {
    cityOpsChange() {
      this.dataForm.promotionId = "";
      this.getPromotionOpsList();
    },
    // 获取活动选择列表
    async getPromotionOpsList() {
      if (!this.dataForm.cityCode) {
        this.$message.error({
          message: "请先选择城市",
        });
        return;
      }
      let _this = this;
      let reqData = {
        cityCode: _this.dataForm.cityCode,
        billingType: "5",
      };
      let res = await OPERATE_API.getPromotionSelectOpsList(reqData);
      if (res.data.code != 0) {
        this.$message.error({
          message: "加载活动列表错误",
        });
        return;
      }
      this.promotionOpsList = res.data.opsList;
    },
    addRechargeSubsidy() {
      if (this.selection.length != 1) {
        this.$message.error("请选择一条充值成功的记录");
        return;
      }
      if (this.selection[0].recharge_status != 0) {
        this.$message.error("充值失败，请选择充值成功的一条记录");
        return;
      }
      this.dataForm = {
        id: "",
        pay_platform_serial_number: "",
        cellphone: "",
        user_id: "",
        amount: "",
        remark: "",
        cityCode: "",
        promotionId: "",
        rechargeMount: "",
        recommendType: "",
        referenceName: "",
      };
      this.promotionOpsList = [];
      let record = this.selection[0];
      this.dataForm.id = record.id;
      this.dataForm.pay_platform_serial_number =
        record.pay_platform_serial_number;
      this.dataForm.cellphone = record.cellphone;
      this.dataForm.rechargeMount = record.amount;
      this.visible = true;
    },

    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (!valid) return;
        if (this.dataForm.id) {
          let res = await promotionApi.addRechargeSubsidy(this.dataForm);
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          } else {
            this.visible = false;
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
            });
          }
        }
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (column.property === "amount") {
          let total = data.reduce((prev, cur) => {
            return prev + parseFloat(cur[column.property]);
          }, 0);
          sums[index] = parseFloat(total).toFixed(2);
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    filter() {
      this.filterVisible = true;
    },
    search() {
      let that = this;
      this.vloading = true;
      this.axiosHelper
        .get("/v0.1/api/operate/recharge", {
          params: {
            ...this.searchValue,
            city_code: this.searchValue.city_code.join(","),
          },
        })
        .then((response) => {
          let arr = response.data.items;
          arr.forEach((item) => {
            item.recommend_type_name =
              recommend_type_list.find((it) => it.value === item.recommend_type)
                ?.label || "";
          });
          this.dataTable = arr;
          this.total = response.data.total_count;
          this.vloading = false;
        })
        .catch((error) => {
          this.vloading = false;
          this.$message.error(
            {
              message: "获取充值对账列表失败",
            },
            error
          );
        });
    },
    clear() {
      this.searchValue = {
        $limit: 50,
        $offset: 0,
        serial_number: "",
        recharge_status: "",
        pay_method: "",
        userNumber: "",
        city_code: [],
        timeStart: "",
        timeEnd: "",
        type: null,
        recommendType: "",
        referenceName: "",
      };
      this.time = [];
      this.search();
      // this.searchValue.serial_number = '';
      // this.searchValue.recharge_status = '';
      // this.searchValue.pay_method = '';
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 21,
        operate_parameter: JSON.stringify({
          ...this.searchValue,
          city_code: this.searchValue.city_code.join(","),
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.selection.length == 0) {
        this.$confirm("是否要导出全部数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (this.total > 20000) {
            await this.$confirm(
              `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );
          }
          this.showLoading("数据加载中...");
          this.axiosHelper
            .get("/v0.1/api/operate/recharge/export", {
              params: {
                ...this.searchValue,
                city_code: this.searchValue.city_code.join(","),
              },
            })
            .then((response) => {
              this.hideLoading();
              let arr = response.data;
              arr.forEach((item) => {
                item.recommend_type_name =
                  recommend_type_list.find(
                    (it) => it.value === item.recommend_type
                  )?.label || "";
              });
              this.exportData(arr);
            })
            .catch(() => {
              this.hideLoading();
            });
        });
      } else {
        this.exportData(this.selection);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "城市",
          "用户编号",
          "支付平台流水号",
          "充值时间",
          "实付金额（元）",
          "充值度数（度）",
          "支付方式",
          "微信ID",
          "推荐人类型",
          "推荐人",
          "充值状态",
          "手机号码",
          "姓名",
          "充值类型",
          "创建时间",
          "充值前",
          "充值后",
        ];
        const filterVal = [
          "first_city_name",
          "user_number",
          "pay_platform_serial_number",
          "time",
          "amount",
          "degree",
          "pay_method_name",
          "open_id",
          "recommend_type_name",
          "reference_name",
          "pay_status_name",
          "cellphone",
          "real_name",
          "type",
          "create_time",
          "early_amount",
          "current_amount",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "充值对账导出",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j == "amount") {
            return Number(v[j]);
          } else if (j == "type") {
            switch (Number(v[j])) {
              case 0:
                return "账号余额";
              case 7:
                return "度数";
              case 21:
                return "B端度数";
              case 33:
                return "车辆性能评估";
            }
            return v[j];
          } else {
            return v[j];
          }
        })
      );
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.limit = this.pageSize;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.offset = (this.currentPage - 1) * this.pageSize;
      this.limit = this.pageSize;
      this.searchValue.$limit = this.limit;
      this.searchValue.$offset = this.offset;
      this.search();
    },
    async initStatusDim() {
      let res = await DIM_API.getByType("payment_state");
      this.paymentStatusDim = res.data;
    },
    async initModeDim() {
      let res = await DIM_API.getByType("fill_payment_mode");
      this.paymentModeDim = res.data;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    clickSearch() {
      if (this.time) {
        this.searchValue.timeStart = this.time[0];
        this.searchValue.timeEnd = this.time[1];
      } else {
        this.searchValue.timeStart = null;
        this.searchValue.timeEnd = null;
      }
      this.handleCurrentChange(1);
    },
    filterSearch(form) {
      this.searchValue.height_serial_number = form.serial_number;
      this.searchValue.height_recharge_status = form.recharge_status;
      this.searchValue.height_pay_method = form.pay_method;
      this.searchValue.wechat_no = form.wechat_no;
      this.searchValue.open_id = form.open_id;
      this.searchValue.cellphone = form.cellphone;
      this.searchValue.pay_platform_serial_number =
        form.pay_platform_serial_number;
      this.searchValue.recommendType = form.recommendType;
      this.searchValue.referenceName = form.referenceName;
      this.handleCurrentChange(1);
    },
  },
};
</script>
<style  lang="scss" scoped >
.box-card {
  margin-top: 15px;
}
.box-card-main {
  display: flex;

  align-items: center;
  .title {
    flex-shrink: 0;
    line-height: 32px;
  }
}
::v-deep .el-dialog__body {
  padding: 20px 40px 20px 20px;
}
</style>