<template>
  <div class="role-authority-container">
    <div class="role-authority-container-left">
      <el-row
        :gutter="20"
        style="margin-top:12px;width:100%"
      >
        <base-table
          ref="choose-table"
          :data="roleData"
          showIndex
          :height="height"
          :columns="tableColumns"
          :totalCount="totalCount"
          @page-change="pageChange"
          @on-row-click="onRowClick"
        ></base-table>
      </el-row>
    </div>
    <div class="role-authority-container-right">
      <div class="role-authority-container-right-bar">
        <span class="role-authority-container-right-bar-text">菜单权限管理:</span>
        <el-button
          class="rewrite-button-style"
          size="small"
          @click="submit"
        >保存</el-button>
      </div>
      <div class="role-authority-container-right-body">
        <el-scrollbar
          style="height: 100%"
          wrapStyle="overflow-x:hidden;"
          viewStyle=""
        >
          <!-- 选择树 -->
          <el-tree
            class="filter-tree"
            default-expand-all
            ref="tree"
            :props="defaultProps"
            :data="treeData"
            show-checkbox
            check-strictly
            node-key="id"
            :default-checked-keys="defaultCheckedKeys"
          >
          </el-tree>
        </el-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
  import { getRolePage } from "api/system/role-api";
  import { nest } from 'utils/utils'
  import { getRoleAuthority, saveRoleAuthority } from 'api/system/role-authority-api'

  export default {
    name: 'roleAuthority',
    data () {
      return {
        roleData: [],
        totalCount: 0,
        treeData: [],
        height: '77vh',
        search_value: {
          $limit: 50,
          $offset: 0,
          field: "",
          type: "",
          keyword: "",
        },
        tableColumns: [
          {
            label: '编号',
            prop: 'code',
            style: 'center',
            minWidth: '80'
          },
          {
            label: '名称',
            prop: 'role_name',
            style: 'center',
            minWidth: '80'
          },
          {
            label: '描述',
            prop: 'description',
            style: 'center',
            minWidth: '80'
          },
          {
            label: '状态',
            prop: 'status',
            style: 'center',
            minWidth: '80'
          },
          {
            label: '是否可以删除',
            prop: 'allow_deleted',
            style: 'center',
            minWidth: '40'
          },
          {
            label: '备注',
            prop: 'remark',
            style: 'center',
          },

        ],
        defaultProps: {
          value: "id",
          label: "name",
          parentId: "pid",
          children: 'children'
        },
        defaultCheckedKeys: [],
        selectRow: null
      }
    },
    methods: {
      async init () {
        var data = await getRolePage(this.search_value);
        this.roleData = data.items;
        this.total_count = data.total_count;
      },
      pageChange (page) {
        this.limit = page.limit;
        this.offset = page.offset;
        this.search()

      },
      async onRowClick (row) {
        this.selectRow = row.row.id;
        var node = await getRoleAuthority(1, this.selectRow, "pms")
        this.defaultCheckedKeys = node.filter(t => t.checked == true).map(t => t.id)
        this.treeData = nest(node, 0, this.defaultProps);
      },
      async submit () {
        if (this.selectRow === null) {
          this.$message.warning("请先选择角色")
          return
        }
        var resourceIds = (this.$refs.tree.getCheckedKeys()).join(',')
        var data = await saveRoleAuthority(this.selectRow, {
          "resourceIds": resourceIds,
          "resourceType": 1,
          "menuType": 'pms'
        })
        if (data) {
          this.$message.success("分配权限成功")
        }
        else {
          this.$message.error("分配权限异常")
        }
      }
    },
    mounted () {
      this.init();
    }
  }
</script>

<style lang="scss" scoped>
.role-authority-container {
  margin-top: 10px;
  width: 100%;
  height: calc(100% - 30px);
  display: flex;

  .role-authority-container-left {
    flex: 1;
    height: calc(100% - 50px);
    margin-top: -2px;
    widows: 100%;
    margin-left: 10px;
  }
  .role-authority-container-right {
    height: calc(100% - 30px);
    width: 300px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    margin-left: 10px;
    margin-top: 10px;
    border: 1px solid #c1cfdf;
    .role-authority-container-right-bar {
      height: 35px;
      display: flex;
      .role-authority-container-right-bar-text {
        flex: 1;
        justify-content: center;
        align-items: center;
        align-content: center;
        display: flex;
      }
    }
    .role-authority-container-right-body {
      flex: 1;
    }
  }
}
</style>