<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <div slot="header" class="clearfix">
        <span>数据筛选</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="filter"
          >高级查询</el-button
        >
      </div>
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          label-width="110px"
        >
          <el-row class="search">
            <el-col>
              <el-form-item label="开票状态：">
                <el-select
                  v-model="searchValue.state"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in stateDim"
                    :key="item.value"
                    :value="item.value"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="申请人编号：">
                <el-input
                  v-model.trim="searchValue.userNumber"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  clearable
                />
              </el-form-item>
              <el-form-item label="申请手机号：">
                <el-input
                  v-model.trim="searchValue.user_mobile"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  maxlength="11"
                  clearable
                />
              </el-form-item>
              <el-form-item label="城市">
                <el-select
                  v-model="searchValue.city_code"
                  placeholder="全国"
                  collapse-tags
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in restrictedCityList"
                    :key="item.cityCode"
                    :label="item.cityName"
                    :value="item.cityCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="elColBtn">
              <el-button type="primary" @click="clickSearch">查询</el-button>
              <el-button type="default" @click="clear">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>开票管理</span>
        <el-button style="margin-left: 15px" @click="finishBill" v-if="isAgent"
          >完成开票</el-button
        >
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
        @selection-change="rowSelected"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_number"
          label="申请人编号"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="bill_no"
          label="开票流水号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_mobile"
          label="申请手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="申请时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="bill_type"
          label="抬头类型"
          min-width="150"
          align="center"
          :formatter="typeFormat"
        ></el-table-column>
        <el-table-column
          prop="company_name"
          label="发票抬头"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="company_tax_no"
          label="公司税号"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="email_address"
          label="邮箱地址"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="more_content"
          label="更多内容"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="amount"
          label="开票金额（元）"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="state"
          label="开票情况"
          min-width="150"
          align="center"
          :formatter="stateFormat"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              @click="showBillDeatil(scope.row)"
              type="text"
              size="small"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
    <BillDeatil v-if="billDeatilVisible" ref="billDeatil"></BillDeatil>
  </div>
</template>
<script>
import FilterModel from "./model/bill-search-model.vue";
import BillDeatil from "./model/bill-deatil.vue";
import OPERATE_API from "../../api/operate_api";
import agentApi from "../../api/agent_api";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";
export default {
  name: "bill-manage",
  components: {
    FilterModel,
    BillDeatil,
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      vloading: false,
      searchValue: {
        $limit: 50,
        $offset: 0,
        state: "",
        user_mobile: "",
        city_code: "",
        userNumber: "",
      },
      stateDim: [
        {
          value: 0,
          name: "未开票",
        },
        {
          value: 1,
          name: "已开票",
        },
      ],
      dataTable: [],
      selection: [],
      pageSize: 50,
      offset: 0,
      limit: 0,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      filterVisible: false,
      cityList: [],
      billDeatilVisible: false,
    };
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.search();
  },
  async mounted() {},
  computed: {
    ...mapState(["restrictedCityList", "user"]),
    isAgent() {
      if (this.user && this.user.isAgent) {
        return true;
      }
      return false;
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  methods: {
    finishBill() {
      if (!this.selection || this.selection.length === 0) {
        this.$message.warning("请先选择要完成开票的数据");
        return;
      }
      for (var i = 0; i < this.selection.length; i++) {
        if (this.selection[i].state == 1) {
          this.$message.warning("已开票的不能重复开票，请重新选择！");
          return;
        }
      }
      let ids = [];
      for (var i = 0; i < this.selection.length; i++) {
        ids.push(this.selection[i].id);
      }
      this.axiosHelper
        .put("/v0.1/api/operate/bill/" + ids)
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$message.success({
              message: "开票成功",
            });
            this.search();
          }
        })
        .catch((error) => {
          this.$message.error(
            {
              message: "开票失败",
            },
            error
          );
        });
    },
    stateFormat: function (data) {
      if (data.state == 0) {
        return "未开票";
      } else if (data.state == 1) {
        return "已开票";
      } else {
        return "";
      }
    },
    typeFormat: function (data) {
      if (data.bill_type == 0) {
        return "企业单位";
      } else if (data.bill_type == 1) {
        return "个人";
      } else {
        return "";
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (column.property === "amount") {
          let total = data.reduce((prev, cur) => {
            return prev + parseFloat(cur[column.property]);
          }, 0);
          sums[index] = parseFloat(total).toFixed(2);
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    filter() {
      this.filterVisible = true;
    },
    async search() {
      let that = this;
      this.vloading = true;
      this.axiosHelper
        .get("/v0.1/api/operate/bill", { params: that.searchValue })
        .then((response) => {
          this.dataTable = response.data.items;
          this.total = response.data.total_count;
          this.vloading = false;
        })
        .catch((error) => {
          this.vloading = false;
          this.$message.error(
            {
              message: "获取开票管理列表失败",
            },
            error
          );
        });
    },
    clear() {
      this.searchValue.$limit = 50;
      this.searchValue.$offset = 0;
      this.searchValue.state = "";
      this.searchValue.user_mobile = "";
      this.searchValue.userNumber = "";
      this.searchValue.city_code = "";
      this.search();
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 15,
        operate_parameter: JSON.stringify(this.searchValue),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.selection.length == 0) {
        this.$confirm("是否要导出全部数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (this.total > 20000) {
            await this.$confirm(
              `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );
          }
          this.showLoading("数据加载中...");
          this.axiosHelper
            .get("/v0.1/api/operate/bill/export", {
              params: this.searchValue,
            })
            .then((response) => {
              this.hideLoading();
              this.exportData(response.data);
            })
            .catch(() => {
              this.hideLoading();
            });
        });
      } else {
        this.exportData(this.selection);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "城市",
          "开票流水号",
          "用户编号",
          "申请手机号",
          "申请时间",
          "抬头类型",
          "发票抬头",
          "公司税号",
          "邮箱地址",
          "更多内容",
          "开票金额（元）",
          "开票情况",
        ];
        const filterVal = [
          "city_name",
          "bill_no",
          "user_number",
          "user_mobile",
          "create_time",
          "bill_type",
          "company_name",
          "company_tax_no",
          "email_address",
          "more_content",
          "amount",
          "state",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "开票管理导出",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j == "bill_type") {
            if (v[j] == 0) {
              return "企业单位";
            } else if (v[j] == 1) {
              return "个人";
            } else {
              return "";
            }
          } else if (j == "state") {
            if (v[j] == 0) {
              return "未开票";
            } else if (v[j] == 1) {
              return "已开票";
            } else {
              return "";
            }
          } else {
            return v[j];
          }
        })
      );
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.limit = this.pageSize;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.offset = (this.currentPage - 1) * this.pageSize;
      this.limit = this.pageSize;
      this.searchValue.$limit = this.limit;
      this.searchValue.$offset = this.offset;
      this.search();
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    clickSearch() {
      this.handleCurrentChange(1);
    },
    filterSearch(form) {
      this.searchValue.height_state = form.state;
      this.searchValue.bill_type = form.bill_type;
      this.searchValue.company_name = form.company_name;
      this.searchValue.company_tax_no = form.company_tax_no;
      this.searchValue.more_content = form.more_content;
      this.searchValue.bill_no = form.bill_no;
      this.handleCurrentChange(1);
    },
    showBillDeatil(row) {
      this.billDeatilVisible = true;
      this.$nextTick(() => {
        this.$refs.billDeatil.init(row);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
</style>
