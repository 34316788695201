<template>
    <el-dialog title="编辑有效期" v-dialogDrag :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
        <el-form :model="dataForm" ref="dataForm" label-width="125px" >
            <el-row>
                <el-col :span="24">
                    <el-form-item label="有效期：">
                        <el-date-picker v-model="validity_Time" type="datetimerange" :default-time="['00:00:00', '23:59:59']"
										value-format="yyyy-MM-dd HH:mm:ss" :clearable="true" range-separator="至"
										start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    export default {
        name: "validity-time-model",
        props: {
            dialog: {
                type: Boolean,
                default () {
                    return false;
                }
            },
            selection: {
                type: Array,
                default: () => new Array()
            }
        },
        data() {
            return {
                validity_Time: null,
                dialogVisible: false,
                dataForm: {},
            }
        },
        mounted() {
            
        },
        watch: {
            dialog: {
                immediate: true,
                handler(value) {
                    this.validity_Time = null
                    this.dialogVisible = value;
                }
            },
            dialogVisible: {
                handler(value) {
                    this.$emit("update:dialog", value);
                }
            }
        },
        methods: {
            cancel() {
                this.dialogVisible = false;
            },
            async confirm() {
                if (this.validity_Time) {
                    this.dataForm.start_time = this.validity_Time[0];
                    this.dataForm.end_time = this.validity_Time[1];
                } else {
                   this.$message.warning("请选择有效期");
                   return
                }
                const confirmResult = await this.$confirm(
                    '确认修改优惠券有效期么？',
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).catch((err) => {
                    return err;
                });
                if (confirmResult != "confirm") {
                    return;
                }
                let ids = [];
                for(var i=0;i<this.selection.length;i++){
                    ids.push(this.selection[i].id)
                }
                let user = this.$store.state.user
                this.dataForm.uid =user.uid
                this.axiosHelper.put('/v0.1/api/operate/coupon_manage/validity/'+ids, {params: this.dataForm})
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.$message.success({
                            message: '修改成功'
                        });
                        this.dialogVisible = false;
                        this.$emit("search");
                    }
                }).catch(() => {
                    this.dialogVisible = false;
                    this.$message.error({
                        message: '修改失败'
                    }, error);
                })
                
            },
        }
    }
</script>
<style scoped>
  ::v-deep .el-dialog {
    width: 600px;
  }
</style>