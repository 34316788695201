
  <div class="orgTree">
    <!-- 搜索框 -->
    <div class="orgTree-bar">
      <el-input
        v-model="filterText_"
        clearable
        placeholder="搜索"
        size="small"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search"
          size="small"
        ></el-button>
      </el-input>
    </div>
    <div
      class="orgTree-container"
      @contextmenu.prevent="show"
    >
      <el-scrollbar
        style="height: 100%"
        wrapStyle="overflow-x:hidden;"
        viewStyle=""
      >
        <!-- 选择树 -->
        <el-tree
          class="filter-tree"
          default-expand-all
          ref="tree"
          :props="defaultProps"
          :data="treeData"
          :show-checkbox="multiple"
          :node-key="props.value"
          @node-click="handleNodeClick"
          @node-contextmenu="showContextmenu"
          :filter-node-method="filterNode"
        >
        </el-tree>
      </el-scrollbar>
    </div>
    <div
      :style="{'z-index': 9999, position: 'fixed',left: optionCardX + 'px', 
				top: optionCardY + 'px', width: '140px', background: 'white',
				 'box-shadow': '0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)'}"
      v-show="optionCardShow"
      id="option-button-group"
    >
      <!-- <el-dropdown-item @click.stop="addMenu">新增菜单</el-dropdown-item>
      <el-dropdown-item @click.stop="removeMenu">删除菜单</el-dropdown-item> -->
      <li
        v-for="item in settings"
        :key="item.command"
        @click="handleCommand(item.command)"
      >
        <i :class="item.icon"></i>
        <span style="margin-left:5px">{{ item.context }}</span>
      </li>
    </div>
  </div>
