
<div class="role-authority-container">
  <div class="role-authority-container-left">
    <el-row
      :gutter="20"
      style="margin-top:12px;width:100%"
    >
      <base-table
        ref="choose-table"
        :data="roleData"
        showIndex
        :height="height"
        :columns="tableColumns"
        :totalCount="totalCount"
        @page-change="pageChange"
        @on-row-click="onRowClick"
      ></base-table>
    </el-row>
  </div>
  <div class="role-authority-container-right">
    <div class="role-authority-container-right-bar">
      <span class="role-authority-container-right-bar-text">菜单权限管理:</span>
      <el-button
        class="rewrite-button-style"
        size="small"
        @click="submit"
      >保存</el-button>
    </div>
    <div class="role-authority-container-right-body">
      <el-scrollbar
        style="height: 100%"
        wrapStyle="overflow-x:hidden;"
        viewStyle=""
      >
        <!-- 选择树 -->
        <el-tree
          class="filter-tree"
          default-expand-all
          ref="tree"
          :props="defaultProps"
          :data="treeData"
          show-checkbox
          check-strictly
          node-key="id"
          :default-checked-keys="defaultCheckedKeys"
        >
        </el-tree>
      </el-scrollbar>
    </div>

  </div>
</div>
