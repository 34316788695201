

<div class="base-table-container">    
  <div class="base-table-main">
    <el-table
      :class="{fixCellHeight:fixCellHeight?true:false}"
      ref="refTable"
      :height=setTableHeight
      border
      default-expand-all
      :data="newData"
      :tree-props="{children: 'children'}"
      :stripe="stripe"
      :size="size"
      :show-header="showHeader"
      :row-key="rowKey"
      :highlight-current-row="rowLight"
      :header-row-style="{'line-height':'36px'}"
      :row-style="tableRowClassName"
      :header-cell-style="headerCellSyleFun"
      :cell-style="{fontSize:'14px', color:'#000', padding:fixCellHeight?'0':'8px 0'}"
      @header-contextmenu="rightClick"
      @sort-change="sortChange"
      @select="OnSelect"
      @select-all="selectAll"
      @selection-change="selectChange"
      @cell-mouse-enter="OnCellMouseEnter"
      @row-click="rowClick"
      @header-dragend="handleHeaderDragend"
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-customClass="my-table-loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(204, 204, 204, 0.6)"
    >
      <el-table-column
        v-if="showCheck"
        type="selection"
        :fixed="selectFixed"
        width="40px"
        align="center"
        :reserve-selection="reserveSelection"
        :selectable="selectable"
      ></el-table-column>
      <el-table-column
        v-if="showIndex"
        type="index"
        label="序号"
        width="60px"
        align="center"
      ></el-table-column>
      <el-table-column
        v-for="(col, index) in columns"
        :key="index"
        :prop="col.prop"
        :label="col.label"
        :header-align="col.headerAlign"
        :align="col.style"
        :fixed="col.fixed"
        :width="col.width"
        :min-width="col.minWidth"
        :type="col.type"
        :sortable="col.sortable"
        :sort-method="col.sortMethod"
        :class-name="col.className"
        :show-overflow-tooltip="overflow || fixCellHeight"
      >
        <template slot-scope="scope">
          <div
            v-if="col.useFormItem"
            :style="fixCellHeight?{display:'flex',alignItems:'center',height:'36px',lineHeight:'36px',}:{}"
          >
            <el-form-item
              style="flex:1;margin-bottom:3px;"
              :prop="`data.${scope.$index}.${col.prop}`"
              :rules="[{required: col.validate?true:false, message: '不能为空', trigger: 'change'}]"
            >
              <form-slot
                :render="col.render"
                :row="scope.row"
                :index="scope.$index"
              ></form-slot>
            </el-form-item>
          </div>
          <ex-slot
            v-else
            :render="col.render"
            :row="scope.row"
            :index="scope.$index"
            :column="col"
            :propId="col.propId"
            :showCard="col.showCard"
            :fixHeight="fixCellHeight"
          ></ex-slot>
        </template>
      </el-table-column>
      <span
        slot="empty"
        id="empty_box"
        class="empty-box"
      >
        <empty-slot
          :click="empty.click"
          :text="empty.text"
          :btnText="empty.btnText"
          :html="emptyHtml"
        ></empty-slot>
      </span>
    </el-table>
  </div>
  <div
    class="base-table-footer"
    v-if="noPage === false"
  >
    <el-pagination
      background
      :small="smallPagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="page_sizes"
      :layout="layout"
      :page-size="pageSize"
      :total="total"
      :disabled="pageForbidden"
    >
    </el-pagination>
  </div>
</div>
