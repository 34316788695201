<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    @close="close"
  >
    <div class="height:100%;">
      <div style="display: flex; justify-content: space-between">
        <el-form :inline="true">
          <el-form-item
            label="注册时间"
            v-if="type != 'today'"
            style="margin-bottom: 0"
          >
            <el-date-picker
              v-model="createTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="城市" style="margin-bottom: 0">
            <el-select
              v-model="form.city_code"
              placeholder="全国"
              collapse-tags
              clearable
            >
              <el-option
                v-for="item in cityList"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button style="margin-right: 15px" type="primary" @click="search"
            >查询</el-button
          >
        </el-form>
        <el-button @click="exportData">导出</el-button>
      </div>

      <el-card class="box-card">
        <el-table ref="dataTable" height="50vh" :data="dataTable">
          <el-table-column
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_number"
            label="用户编号"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="cellphone"
            label="用户手机号"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="city_name"
            label="城市"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="order_total_number"
            label="下单数"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="注册时间"
            min-width="150"
            align="center"
          ></el-table-column>
        </el-table>
        <el-footer height="32px" style="margin-top: 10px">
          <el-pagination
            background
            layout="total,sizes, prev, pager, next,jumper"
            :total="page_total"
            :page-size="page_size"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :current-page.sync="page_index"
          ></el-pagination>
        </el-footer>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import * as XLSX from "xlsx";
import moment from "moment/moment";
import OPERATE_API from "@/api/operate_api";
import { operateAdd } from "@/api/system/user-api";
export default {
  props: {
    type: {
      type: String,
      default: "today",
    },
    dataItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      visible: true,
      form: {
        start_time: "",
        end_time: "",
        city_code: "",
      },
      page_total: 0,
      page_size: 10,
      page_index: 1,
      dataTable: [],
      cityList: [],
    };
  },
  computed: {
    title() {
      if (this.type == "today") {
        return "今日注册用户数";
      } else {
        return "引流客户总数量";
      }
    },
    createTime: {
      get() {
        return [this.form.start_time, this.form.end_time];
      },
      set(val) {
        this.form.start_time = val
          ? moment(val[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
        this.form.end_time = val
          ? moment(val[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
    },
  },
  created() {
    if (this.type == "today") {
      this.form.start_time = moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.form.end_time = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    }
    this.getCityList();
    this.loadDataTable();
  },
  mounted() {},
  methods: {
    async getCityList() {
      let res = await OPERATE_API.getCityList();
      if (res.data.code != 0) {
        this.$message.error({
          message: "加载城市列表错误",
        });
        return;
      }
      this.cityList = res.data.cityList;
    },
    close() {
      this.$emit("close");
    },
    async loadDataTable() {
      const res = await this.axiosHelper
        .post(
          `/api/channel/merchant/userQuery/${
            (this.page_index - 1) * this.page_size
          }/${this.page_size}`,
          {
            ...this.form,
            channel_merchant_id: this.dataItem.id,
          }
        )
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.data.items;
          this.page_total = response.data.data.total;
        });
    },
    search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 5,
        operate_parameter: JSON.stringify({
          ...this.form,
          channel_merchant_id: this.dataItem.id,
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      this.showLoading("加载中");
      this.axiosHelper
        .post("/api/channel/merchant/userExport", {
          ...this.form,
          channel_merchant_id: this.dataItem.id,
        })
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.data);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = `${this.dataItem.channel_merchant_name}.xlsx`;
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["用户编号"] = item.user_number;
        newObj["用户手机号"] = item.cellphone;
        newObj["城市"] = item.city_name;
        newObj["下单数"] = item.order_total_number;
        newObj["注册时间"] = item.create_time;
        newArray.push(newObj);
      });
      return newArray;
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
</style>