
<div>
    <el-card class="box-card" style="height: 10%">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item label="代理商名称首字母">
                <el-input v-model="dataForm.agent_name_char" placeholder="代理商名称首字母" clearable></el-input>
            </el-form-item>
            <el-button style="margin-right: 15px" type="primary" @click="getDataList()">查询</el-button>
            <el-button style="margin-right: 15px" type="default" @click="clear()">重置</el-button>
            <el-button style="margin-right: 15px" type="primary" @click="addOrUpdateHandle()">新增</el-button>
        </el-form>
    </el-card>
    <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
        style="width: 100%;">
        <!-- <el-table-column type="selection" header-align="center" align="center" width="50">
  </el-table-column> -->
        <!-- <el-table-column prop="id" header-align="center" align="center" label="">
  </el-table-column> -->
        <!-- <el-table-column prop="sn" header-align="center" align="center" label="代理商编号">
  </el-table-column>  -->
        <!-- label="代理商编号，格式：AG-202309-1001，10表示城市id，01表示代理商数量，依次递增"> -->
        <el-table-column prop="agent_name" header-align="center" align="center" min-width="150" label="代理商名称">
        </el-table-column>
        <el-table-column prop="agent_name_char" header-align="center" align="center" label="代理商名称首字母">
        </el-table-column>
        <el-table-column prop="credit_code" header-align="center" align="center" label="信用代码">
        </el-table-column>
        <el-table-column prop="city_code" header-align="center" align="center" label="城市行政区划编码">
        </el-table-column>
        <!-- <el-table-column prop="city_id" header-align="center" align="center" label="代理商所在城市">
  </el-table-column> -->
        <el-table-column prop="call_name" header-align="center" align="center" label="代理商联系人">
        </el-table-column>
        <el-table-column prop="call_phone" header-align="center" align="center" label="联系电话">
        </el-table-column>
        <!-- <el-table-column prop="total_amount" header-align="center" align="center" label="补贴总额"></el-table-column>
  <el-table-column prop="remaining_amount" header-align="center" align="center" label="补贴剩余总额"></el-table-column> -->
        <!-- <el-table-column prop="status" header-align="center" align="center" label="数据有效状态">
  </el-table-column> -->
        <!-- （0无效；1有效） -->
        <el-table-column prop="remark" header-align="center" align="center" min-width="150" label="备注">
        </el-table-column>
        <!-- <el-table-column prop="create_id" header-align="center" align="center" label="创建人id">
  </el-table-column>
  <el-table-column prop="create_time" header-align="center" align="center" label="写入时间">
  </el-table-column>       -->
        <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
            <template slot-scope="scope">
                <!-- <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button> -->
                <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row)">修改</el-button>
                <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <agentlist_add_or_update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList">
    </agentlist_add_or_update>
</div>
