
<div style="display:inline-block;width:100%">
    <el-upload
        :disabled="disabled"
        action="string"
        :class="{disabled: add_disabled}"
        :limit="1"
        class="avatar-uploader"
        v-loading.fullscreen.lock="loading"
        element-loading-text="拼命上传中"
        element-loading-customClass="personal-loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(204, 204, 204, 0.6)"
        :http-request="upload"
        :file-list="image_list"
        accept=""
        :before-upload="beforeUpload"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove">
        <i slot="default" class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :title="`放大`" :visible.sync="dialog">
        <img width="100%" :src="url" alt="">
    </el-dialog>
</div>
