<template>
  <el-dialog :title="formItem.input_status==true ? '修改' : '待录入'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="140px">
      <el-form-item label="城市：" prop="fullname">
        {{ this.formItem.fullname}}
      </el-form-item>
      <el-form-item label="手机号码：" prop="cellphone">
        {{ this.formItem.cellphone}}
      </el-form-item>
      <el-form-item label="车价：" prop="car_price">
        <el-select v-model="dataForm.car_price" placeholder="请选择" collapse-tags clearable style="width: 100%">
          <el-option v-for="item in carPriceDim" :key="item.data_value" :value="item.data_value" :label="item.data_name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="性别：" prop="sex">
        <el-select v-model="dataForm.sex" placeholder="请选择" collapse-tags clearable style="width: 100%">
          <el-option :value="1" label="男"/>
          <el-option :value="2" label="女"/>
        </el-select>
      </el-form-item>
      <el-form-item label="年龄段：" prop="age_group">
        <el-select v-model="dataForm.age_group" placeholder="请选择" collapse-tags clearable style="width: 100%">
          <el-option v-for="item in ageGroupDim" :key="item.data_value" :value="item.data_value" :label="item.data_name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车辆属性：" prop="car_attribute">
        <el-select v-model="dataForm.car_attribute" placeholder="请选择" collapse-tags clearable style="width: 100%">
          <el-option :value="1" label="客运车"/>
          <el-option :value="2" label="私家车"/>
          <el-option :value="2" label="货运车"/>
        </el-select>
      </el-form-item>
      <el-form-item label="未下单原因：" prop="un_order_reason">
        <el-select v-model="dataForm.un_order_reason" placeholder="请选择" collapse-tags clearable style="width: 100%">
          <el-option v-for="item in unOrderReasonDim" :key="item.data_value" :value="item.data_value" :label="item.data_name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="回访结论：" prop="follow_result">
        <el-select v-model="dataForm.follow_result" placeholder="请选择" collapse-tags clearable style="width: 100%">
          <el-option v-for="item in followResultDim" :key="item.data_value" :value="item.data_value" :label="item.data_name"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="备注：" prop="remarks">
        <el-input type="textarea" v-model="dataForm.remarks" placeholder="备注"></el-input>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import agentApi from "../../../api/agent_api";
import axios from "../../../utils/axiosHelper";
export default {
  // name: "AddOrUpdate",
  props: {
    //车价
    carPriceDim: {
      type: Object,
      default: () => {
        return {};
      }
    },
    //年龄段
    ageGroupDim: {
      type: Object,
      default: () => {
        return {};
      }
    },
    //未下单原因
    unOrderReasonDim: {
      type: Object,
      default: () => {
        return {};
      }
    },
    //回访结论
    followResultDim: {
      type: Object,
      default: () => {
        return {};
      }
    },

  },
  data() {
    return {
      visible: false,
      formItem:{},
      dataForm: {
        user_id: '',
        car_price: '',
        sex: '',
        age_group: '',
        car_attribute: '',
        un_order_reason: '',
        follow_result: '',
        remarks: '',
      },
      dataRule: {

      }
    }
  },
  methods: {
    init(row) {
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (row) {
          console.log(row)
          this.formItem = row
          this.dataForm.user_id = row.user_id
          this.dataForm.car_price = row.car_price
          this.dataForm.sex = row.sex
          this.dataForm.age_group = row.age_group
          this.dataForm.car_attribute = row.car_attribute
          this.dataForm.un_order_reason = row.un_order_reason
          this.dataForm.follow_result = row.follow_result
          this.dataForm.remarks = row.remarks

        } else {
          this.dataForm.user_id = ""
          this.dataForm.car_price = ""
          this.dataForm.sex = ""
          this.dataForm.age_group = ""
          this.dataForm.car_attribute = ""
          this.dataForm.un_order_reason = ""
          this.dataForm.follow_result = ""
          this.dataForm.remarks =""
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(async valid => {
        if (!valid) return
        this.showLoading("加载中");
        let dataFormStr=JSON.stringify(this.dataForm);
        axios.put(`/api/user/un/order/extend/save`,this.dataForm)
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg)
                return
              }
              this.visible = false
              this.$emit('refreshDataList')
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              })
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            })

      })
    }
  }
}
</script>
