<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height:20%;">
      <div class="text item">
        <el-form ref="form"  :model="form" label-width="110px">
          <el-row class="search">
            <el-form-item label="城市" style="width: 100%">
              <el-select v-model="form.firstCityCode" placeholder="全国" collapse-tags clearable>
                <el-option
                    v-for="item in cityList" :key="item.cityCode" :label="item.cityName" :value="item.cityCode" >
                </el-option>
              </el-select>
            </el-form-item>
            <el-col class="elCol">
              <el-form-item label="年份：">
                <el-select v-model="form.year" placeholder="请选择" >
                  <el-option :value="2024" label="2024"/>
                  <el-option :value="2023" label="2023"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="elCol">
              <el-form-item label="月份：">
                <el-select v-model="form.month" placeholder="请选择" >
                  <el-option :value="1" label="01"/>
                  <el-option :value="2" label="02"/>
                  <el-option :value="3" label="03"/>
                  <el-option :value="4" label="04"/>
                  <el-option :value="5" label="05"/>
                  <el-option :value="6" label="06"/>
                  <el-option :value="7" label="07"/>
                  <el-option :value="8" label="08"/>
                  <el-option :value="9" label="09"/>
                  <el-option :value="10" label="10"/>
                  <el-option :value="11" label="11"/>
                  <el-option :value="12" label="12"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="elColBtn">
              <el-button style="margin-right: 15px;" type="primary" @click="search">查询</el-button>
              <el-button style="margin-right: 15px;" type="default" @click="clear">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom:15px;">
        <el-button style="float:right"  @click="exportData">导出</el-button>
      </el-row>
      <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
        <el-table-column prop="week_name" label="时间" min-width="150" align="center"></el-table-column>
        <el-table-column prop="new_add_recharge_user_num" label="新增储值客户数（个）" min-width="150" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.week_name!='合计'">
              <el-button @click="viewRow(scope.row)" type="text" size="small">{{ scope.row.new_add_recharge_user_num }}</el-button>
            </div>
            <div v-else>{{ scope.row.new_add_recharge_user_num }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="total_amount" label="储值金额（元）" min-width="150" align="center"></el-table-column>
        <el-table-column prop="give_amount" label="其中赠送金额（元）" min-width="150" align="center"></el-table-column>
        <!-- <el-table-column prop="recharge_unit_price" label="储值单价（元/kwh）" min-width="150" align="center"></el-table-column> -->
        <el-table-column prop="cur_total_recharge_balance" label="当前总储值余额（元）" min-width="150" align="center"></el-table-column>
      </el-table>
    </el-card>
    <NewAddRechargeUser v-if="newAddRechargeUserVisible" ref="newAddRechargeUser" :dataItem="dataItem"></NewAddRechargeUser>
  </div>
</template>
<script>
import DIM_API from "../../../api/dim_api";
import OPERATE_API from "../../../api/operate_api";
import { operateAdd } from "@/api/system/user-api";
import NewAddRechargeUser from "./new-add-recharge-user.vue";
import * as XLSX from "xlsx";

export default {
  components: {
    NewAddRechargeUser
  },
  data() {
    return {
      cityList:[],
      form: {
        year : new Date().toISOString().slice(0, 4),
        month : new Date().toISOString().slice(5, 7),
      },
      createTime: [],

      dataTable: [],
      selection: [],
      tableHeight: '70vh',
      dataItem:[],
      newAddRechargeUserVisible:false
    }
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  mounted() {
    this.getCityList();
    this.search();
  },
  methods: {
    filter() {
      this.filterVisible = true;
    },
    getTableHeight() {
      this.tableHeight = String(document.documentElement.clientHeight - 95 - 150 - 200);
    },
    // 获取城市选择列表
    async getCityList() {
      let res = await OPERATE_API.getCityList();
      if (res.data.code != 0) {
        this.$message.error({
          message: "加载城市列表错误"
        });
        return
      }
      this.cityList = res.data.cityList
    },
    async search() {
      this.loadDataTable();
    },
    clear() {
      this.form = {
        year : new Date().toISOString().slice(0, 4),
        month : new Date().toISOString().slice(5, 7),
      }
      this.loadDataTable();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
          filterVal.map(j => {
            return v[j];
          })
      );
    },
    loadDataTable() {
      let params = {
        ...this.form,
        source: 'pc'
      }
      this.showLoading("加载中");
      this.axiosHelper
          .get('/api/userAccountRecord/recharge/statistics/list', {
            params: params
          })
          .then((response) => {
            this.hideLoading();
            this.dataTable = response.data.list
            this.$nextTick(() => {
              this.$refs['dataTable'].doLayout();
            })
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error("加载失败");
          })
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 10,
        operate_parameter: JSON.stringify({
          ...this.form,
          source: "pc",
        }),
      })
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      let params = {
        ...this.form,
        source: 'pc'
      }
      this.showLoading("加载中");
      this.axiosHelper
          .get('/api/userAccountRecord/recharge/statistics/export', {
            params: params
          })
          .then((res) => {
            this.hideLoading();
            let newData=this.getHeadersAndbody(res.data.items)
            const ws = XLSX.utils.json_to_sheet(newData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const fileName = '客户储值情况导出.xlsx';
            XLSX.writeFile(wb, fileName);
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error("导出失败");
          })
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach(item => {
        let newObj={}
        newObj['时间']=item.week_name
        newObj['新增储值客户数（个）']=item.new_add_recharge_user_num
        newObj['储值金额（元）']=item.total_amount
        newObj['其中赠送金额（元）']=item.give_amount
        newObj['储值单价（元/kwh）']=item.recharge_unit_price
        newObj['当前总储值余额（元）']=item.cur_total_recharge_balance

        newArray.push(newObj);
      });
      return newArray;
    },
    viewRow(row) {
      this.newAddRechargeUserVisible = true;
      this.dataItem = row;
      this.$nextTick(() => {
        this.$refs.newAddRechargeUser.init()
      })
    },
  }
}
</script>
<style scoped lang="scss">
.search{
  display: flex;
  flex-direction: row;
}
.el-col{
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn{
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
</style>
