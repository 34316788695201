<!--
 * @Author: ljf
 * @Date: 2022-06-20 10:07:36
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-14 15:12:39
 * @FilePath: \lxm-admin\src\views\system\role\RoleUser.vue
 * @Description: 
 * 
-->
<template>
  <div class="role-user">
    <div class="role-user-center">
      <div
        class="role-user-left"
        v-if="type=='role'"
      >
        <org-tree
          @nodeClick="handleNodeClick"
          filter
        ></org-tree>
      </div>
      <div class="role-user-table">
        <div class="role-user-table-left">
          <div class="role-user-table-left-toolbar">
            <div class="role-user-table-searchBar">
              <el-input
                v-model="search_value.keyword"
                placeholder="请输入用户姓名或账号"
                size="normal"
                clearable
                style="width: 300px"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="search()"
                ></el-button>
              </el-input>
            </div>

          </div>

          <!-- <el-scrollbar style="height: 100%; overflow-x: hidden"> -->
          <!-- 左边用户列表 -->
          <el-table
            :data="table_data"
            max-height="750"
            height="700"
            border
            header-align="center"
            element-loading-customClass="personal-loading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(204, 204, 204, 0.6)"
            class="refTable"
            ref="leftTable"
            default-expand-all
            :header-row-style="header_row_style"
            :header-cell-style="header_cell_style"
            :cell-style="cell_style"
            :style="table_style"
            @selection-change="handleSelectionChange"
            @select-all="handleSelectionChange"
            row-key="id"
          >
            >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="user_name"
              label="用户账号"
              width="150"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="real_name"
              label="用户姓名"
              show-overflow-tooltip
            >
            </el-table-column>

          </el-table>
          <!-- </el-scrollbar> -->
          <div class="file-table-footer">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="current_page"
              small
              :page-sizes="[10, 20, 50, 100, 500]"
              :page-size="page_size"
              layout="total, sizes, prev, pager, next"
              :total="total_count"
            ></el-pagination>
          </div>
        </div>
        <!-- 中间穿梭框按钮 -->
        <div class="role-user-table-center">
          <el-button
            icon="el-icon-arrow-right"
            circle
            :disabled="addToRightDisabled"
            type="primary"
            @click="addToRight"
          ></el-button>
          <el-button
            icon="el-icon-arrow-left"
            circle
            @click="removeFromRight"
            type="primary"
            :disabled="removeFromRightDisabled"
          ></el-button>
        </div>
        <div class="role-user-table-right">
          <div class="role-user-table-searchBar">
            <el-input
              v-model="searchCode"
              placeholder="请输入用户姓名或编号"
              size="normal"
              clearable
              style="width: 300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="setSearchCode"
              ></el-button>
            </el-input>
          </div>
          <!-- <el-scrollbar style="height: 100%; overflow-x: hidden"> -->
          <!-- 右边选中的人员列表 -->
          <el-table
            :data="transferData"
            max-height="750"
            height="700"
            border
            header-align="center"
            element-loading-customClass="personal-loading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(204, 204, 204, 0.6)"
            class="refTable"
            ref="refTable"
            default-expand-all
            :header-row-style="header_row_style"
            :header-cell-style="header_cell_style"
            :cell-style="cell_style"
            :style="table_style"
            show-overflow-tooltip
            @selection-change="handleTransferSelectionChange"
            row-key="id"
          >
            >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="user_name"
              label="用户账号"
              width="150"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="real_name"
              label="用户姓名"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
          <!-- </el-scrollbar> -->
          <div class="file-table-footer">
            <el-pagination
              @size-change="handleTransferSizeChange"
              @current-change="handleTransferCurrentChange"
              :current-page.sync="transfer_current_page"
              small
              :page-sizes="[10, 20, 50, 100, 500]"
              :page-size="transfer_page_size"
              layout="total, sizes, prev, pager, next"
              :total="transferFilterData.length"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="role-user-foot">

      <el-button
        type="primary"
        @click="submit"
      >确定</el-button>
      <el-button @click="cancel">取消</el-button>

    </div>
  </div>
</template>

<script>
  import { roleUserAssign, getRoleUsers } from 'api/system/role-api'
  import { multiUser } from "api/system/user-api";

  import { flatten, ArrSet } from "utils/utils";
  export default {
    name: 'RoleUser',
    props: {
      defaultProps: {
        type: Object,
        default: () => {
          return {
            value: "id",
            label: "org_name",
            children: "child_orgs",
            parentId: "parent_id",
            treeDeep: "tree_deep",
          };
        },
      },
      role_id: {
        type: String,
        default: ""
      },
      type: {
        type: String,
        default: "role"
      },
      org_id: {
        type: String,
        default: ""
      }
    },
    data () {
      return {
        table_style: {
          "margin-top": "10px",
          width: "99%",
        },
        header_cell_style: {
          background: "#EDF1F4",
          textAlign: "center",
          padding: "0",
        },
        cell_style: {
          fontSize: "14px",
          color: "#000",
          padding: "0",
          height: "40px",
        },
        header_row_style: {
          "line-height": "36px",
        },
        search_value: {
          orgIds: "",
          keyword: "",
        },
        page_size: 10,
        transfer_page_size: 10,
        total_data: [],
        total_count: 0,
        table_data: [],
        transfer_total_data: [],
        current_page: 1,
        multiple_selection: [],
        transfer_selection: [],
        transfer_current_page: 1,
        searchCode: '',
        transfer_value: {
          limit: 50,
          offset: 0,
          code: "",
        },
      }
    },
    computed: {
      transferFilterData () {
        return this.transfer_total_data
          .filter((t) => {
            return (
              t.real_name.includes(this.transfer_value.code) ||
              t.user_name.includes(this.transfer_value.code)
            );
          })
          .map((t, index) => {
            var obj = { ...t };
            obj.index = index;
            return obj;
          });
      },
      transferData () {
        return (
          this.transferFilterData.filter((t) => {
            return (
              t.index >= this.transfer_page_size * (this.transfer_current_page - 1) &&
              t.index < this.transfer_current_page * this.transfer_page_size
            );
          }) ?? []
        );
      },
      addToRightDisabled () {
        if (this.multiple_selection.length == 0) {
          return true;
        }
        return this.table_data.every((t) => this.handleSelectable(t) === false);
      },
      removeFromRightDisabled () {
        return this.transfer_selection.length == 0;
      },
    },
    watch: {
      role_id: {
        handler (value) {
          if (this.org_id != '') {
            this.search_value.orgIds = this.org_id
          }
          this.init()
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      async init () {
        await this.getRoleUser()
        await this.search();
      },
      async getRoleUser () {
        this.transfer_total_data = []
        var data = (await getRoleUsers(this.role_id)).map((t) => {
          var obj = { ...t }
          obj.id = t.uid
          return obj
        })
        this.transfer_total_data = ArrSet([...this.transfer_total_data, ...data], 'id')
      },

      async submit () {
        //var data = null;
        //设置系统角色用户
        // if (this.type == "role") {
        //   var obj = {
        //     roleId: this.role_id,
        //     userIds: this.transfer_total_data.map(t => t.id).join(',')
        //   }
        //   data = await roleUserAssign(obj)
        // }
        //设置组织角色用户
        // else if (this.type == "orgRole") {
        //   var obj = {
        //     roleId: this.role_id,
        //     userIds: this.transfer_total_data.map(t => t.id),
        //     orgId: this.org_id
        //   }
        //   data = await setOrgRoleUser(obj)
        // }
        this.$emit('submit', this.transfer_total_data, this.role_id, this.org_id)

        // if (data > -1) {
        //   this.$message.success("设置角色用户成功")
        //   this.$emit('close')
        // }
        // else {
        //   this.$message.error("设置角色用户失败")
        // }
      },
      cancel () {
        this.$emit('close')
      },
      handleNodeClick (data) {
        var { value } = this.defaultProps;
        this.search_value.orgIds = flatten([data], this.defaultProps)
          .map((t) => t[value])
          .join(",");
        this.$emit('selectOrgChange', this.search_value.orgIds)
        this.init()

      },
      async search () {
        try {
          this.loading = true;
          this.total_data = (await multiUser(this.search_value)).filter(t => {
            return this.transfer_total_data.map(o => o.id).includes(t?.uid || t?.id) == false
          }).map((t) => {
            var obj = { ...t }
            obj.id = t.uid
            return obj
          })
          this.total_count = this.total_data.length
          this.table_data = [...this.total_data].slice((this.current_page - 1) * this.pageSize, this.pageSize).map((t) => {
            var obj = { ...t }
            obj.id = t.uid
            return obj
          })

        } catch (error) {
          this.loading = false;
        }
        this.loading = false;
      },
      handleSelectionChange (val) {
        this.multiple_selection = [...new Set([...this.multiple_selection, ...val])];
        this.multiple_selection = this.multiple_selection.reduce((list, item) => {
          if (list.every((t) => t.id !== item.id)) {
            list.push(item);
          }
          return list;
        }, []);
        //如果等于空 需要把本页所有的项目移除
        var selecedIds = val.map((t) => t.id);
        var ids = this.table_data
          .map((t) => t.id)
          .filter((t) => {
            return !selecedIds.includes(t);
          });
        //当前表格没有选中的
        this.multiple_selection = this.multiple_selection.reduce((list, item) => {
          if (!ids.includes(item.id)) {
            list.push(item);
          }
          return list;
        }, []);

      },
      handleSizeChange (size) {
        this.page_size = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange (current) {
        this.current_page = current;
        this.buildTableData()
      },
      addToRight () {        
        this.transfer_total_data =
          ArrSet([...this.transfer_total_data, ...this.multiple_selection], 'uid')
        this.total_data = this.total_data.filter(t => {
          return !this.transfer_total_data.map((t) => t.uid).includes(t.uid)
        })
        this.buildTableData()
        this.$emit("selectedResultChange", this.transfer_total_data);
      },
      removeFromRight () {
        var ids = this.transfer_selection.map((t) => t.uid);
        this.total_data = [...this.total_data, ... this.transfer_total_data.filter((t) => ids.includes(t.id))];
        this.buildTableData()
        this.transfer_total_data = this.transfer_total_data.filter((t) => !ids.includes(t.id));
        this.transfer_selection = []
        this.$emit("selectedResultChange", this.transfer_total_data);
      },
      setSearchCode () {
        this.transferCurrentPage = 1;
        this.transfer_value.code = this.searchCode;
      },
      handleSelectable (row) {
        return !this.transfer_total_data.some((t) => t.id === row.id);
      },
      handleTransferSelectionChange (val) {
        this.transfer_selection = val;
      },
      handleTransferCurrentChange (current) {
        this.transfer_currentPage = current;
        this.transfer_value.offset = (this.transfer_currentPage - 1) * this.transfer_page_size;
      },
      handleTransferSizeChange (size) {
        this.transfer_page_size = size;
        this.transfer_total_data.limit = this.transfer_page_size;
        this.handleTransferCurrentChange(1);
      },
      buildTableData () {
        this.total_count = this.total_data.length
        this.table_data = [...this.total_data].slice((this.current_page - 1) * this.pageSize, this.pageSize).map((t) => {
          var obj = { ...t }
          obj.id = t.uid
          return obj
        })
      }

    },
    mounted () {
    }
  }
</script>

<style lang="scss" >
.role-user {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .role-user-center {
    flex: 1;
    height: 95%;
    display: flex;
    .role-user-left {
      width: 260px;
      height: 100%;
      border: 0.5px solid #c1cfdf;
    }
    .role-user-table {
      flex: 1;
      display: flex;
      margin-left: 10px;
      padding: 10px;
      border: 0.5px solid #c1cfdf;
      .role-user-table-left {
        display: flex;
        flex: 1;
        overflow: hidden;
        flex-direction: column;
        padding: auto;
        .role-user-table-left-toolbar {
          flex-direction: row-reverse;
          display: flex;
          .file-table-header {
            flex: 1;
            padding-left: 5px;
          }
          .role-user-table-searchBar {
            display: flex;
            flex-direction: row-reverse;
            padding-right: 2px;
          }
        }
        .file-table-footer {
          flex: 0 0 7% !important;
          display: flex;
          //justify-content: center;
          align-items: center;
        }
      }

      .role-user-table-center {
        padding: 2px;

        flex: 0 0 5% !important;
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        flex-direction: column;
      }
      .role-user-table-right {
        display: flex;
        flex: 1;
        overflow: hidden;
        flex-direction: column;
        .role-user-table-searchBar {
          display: flex;
          flex-direction: row-reverse;
          padding-right: 2px;
        }
        .file-table-footer {
          flex: 0 0 7% !important;
          display: flex;
          //justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .role-user-foot {
    flex: 0 0 5% !important;
    min-height: 30px;
    display: flex;
    justify-content: right;
    margin-top: 10px;
  }
}
</style>