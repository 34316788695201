<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="用户编号：">
          <el-input
            v-model.trim="form.user_number"
            placeholder="请输入"
            clearable
            maxlength="10"
            oninput="value=value.replace(/[^0-9]/g,'')"
          />
        </el-form-item>
        <el-form-item label="用户手机号：">
          <el-input
            v-model.trim="form.cellphone"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="操作时间">
          <el-date-picker
            v-model="createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 220px"
            align="center"
          >
          </el-date-picker>
        </el-form-item>

        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <!-- <el-row style="margin-bottom: 15px">
        <el-button style="margin-left: 15px" @click="exportData(FILE_VIEW_URL)"
          >导出</el-button
        >
      </el-row> -->
      <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
        <el-table-column
          prop="operate_type"
          label="操作类型"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            {{ operateTypeFilter(scope.row.operate_type) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="operate_number"
          label="操作"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            {{ operateNumberFilter(scope.row.operate_number) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="real_name"
          label="用户名"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="用户手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="操作时间"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { operateNumber } from "@/utils/utils";
export default {
  data() {
    return {
      form: {
        user_number: "",
        cellphone: "",
        create_time_start: "",
        create_time_end: "",
      },
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      dataTable: [],
    };
  },
  computed: {
    createTime: {
      set(val) {
        this.form.create_time_start = val
          ? moment(val[0]).startOf("days").format("YYYY-MM-DD HH:mm:ss")
          : "";
        this.form.create_time_end = val
          ? moment(val[1]).endOf("days").format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
      get() {
        return [this.form.create_time_start, this.form.create_time_end];
      },
    },
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.search();
  },
  methods: {
    operateTypeFilter(type) {
      let operateType = {
        1: "导出",
      };
      return operateType[type];
    },
    operateNumberFilter(num) {
      return operateNumber[num];
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {
        user_number: "",
        cellphone: "",
        create_time_start: "",
        create_time_end: "",
      };
      this.search();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    async loadDataTable() {
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      const res = await this.axiosHelper.post(
        `/api/user/common/operate/query/${offset}/${limit}`,
        {
          ...this.form,
        }
      );
      if (res.data.code == 0) {
        this.dataTable = res.data.data.items;
        this.page_total = res.data.data.total;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}
</style>