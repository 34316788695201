<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <div slot="header" class="clearfix">
        <span>数据筛选</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="filter"
          >高级查询</el-button
        >
      </div>
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          label-width="110px"
        >
          <el-row>
            <el-col :span="7">
              <el-form-item label="微信号/微信ID：" label-width="120px">
                <el-input
                  v-model.trim="searchValue.user_no"
                  placeholder="请输入微信号或微信ID"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="3" style="float: right; text-align: right">
              <el-button type="primary" @click="clickSearch">查询</el-button>
              <el-button type="default" @click="clear">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>用户卡包</span>
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
        @selection-change="rowSelected"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="wechat_no"
          label="微信号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="open_id"
          label="微信ID"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="联系方式"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="used_total"
          label="已用卡券"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="available_total"
          label="可用卡券"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="unavailable_total"
          label="不可用卡券"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="overdue_total"
          label="过期卡券"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="last_recharge_time"
          label="最后充值时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="last_pay_time"
          label="最后支付时间"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
  </div>
</template>
<script>
import { operateAdd } from "@/api/system/user-api";
import FilterModel from "./model/wallet-search-model.vue";
export default {
  name: "user-coupon",
  components: {
    FilterModel,
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      vloading: false,
      searchValue: {
        $limit: 50,
        $offset: 0,
        user_no: "",
      },
      dataTable: [],
      selection: [],
      pageSize: 50,
      offset: 0,
      limit: 0,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      filterVisible: false,
    };
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  mounted() {
    this.search();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (
          column.property === "used_total" ||
          column.property === "available_total" ||
          column.property === "overdue_total"
        ) {
          let total = data.reduce((prev, cur) => {
            return prev + Number(cur[column.property]);
          }, 0);
          sums[index] = Number(total.toFixed(2));
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    filter() {
      this.filterVisible = true;
    },
    search() {
      let that = this;
      this.vloading = true;
      this.axiosHelper
        .get("/v0.1/api/operate/coupon", { params: that.searchValue })
        .then((response) => {
          this.dataTable = response.data.items;
          this.total = response.data.total_count;
          this.vloading = false;
        })
        .catch((error) => {
          this.vloading = false;
          this.$message.error(
            {
              message: "获取用户卡券列表失败",
            },
            error
          );
        });
    },
    clear() {
      this.searchValue = {
        $limit: 50,
        $offset: 0,
        user_no: "",
      };
      this.clear();
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 23,
        operate_parameter: JSON.stringify(this.searchValue),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.selection.length == 0) {
        this.$confirm("是否要导出全部数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (this.total > 20000) {
            await this.$confirm(
              `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );
          }
          this.showLoading("数据加载中...");
          this.axiosHelper
            .get("/v0.1/api/operate/coupon/export", {
              params: this.searchValue,
            })
            .then((response) => {
              this.hideLoading();
              this.exportData(response.data);
            })
            .catch(() => {
              this.hideLoading();
            });
        });
      } else {
        this.exportData(this.selection);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "微信号",
          "微信ID",
          "联系方式",
          "已用卡券",
          "可用卡券",
          "不可用卡券",
          "过期卡券",
          "最后充值时间",
          "最后支付时间",
        ];
        const filterVal = [
          "wechat_no",
          "open_id",
          "cellphone",
          "used_total",
          "available_total",
          "unavailable_total",
          "overdue_total",
          "last_recharge_time",
          "last_pay_time",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "用户卡券导出",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.limit = this.pageSize;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.offset = (this.currentPage - 1) * this.pageSize;
      this.limit = this.pageSize;
      this.searchValue.$limit = this.limit;
      this.searchValue.$offset = this.offset;
      this.search();
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    clickSearch() {
      this.handleCurrentChange(1);
    },
    filterSearch(form) {
      this.searchValue.wechat_no = form.wechat_no;
      this.searchValue.open_id = form.open_id;
      this.searchValue.cellphone = form.cellphone;
      this.handleCurrentChange(1);
    },
  },
};
</script>
<style scoped>
.box-card {
  margin-top: 15px;
}
</style>