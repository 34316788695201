
<div class="menu-container">
  <div class="menu-container-left">
    <div class="menu-container-left-bar">
      <el-input
        v-model="filterText_"
        clearable
        placeholder="搜索"
        size="small"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search"
          size="small"
        ></el-button>
      </el-input>
    </div>
    <div class="menu-container-left-container">
      <el-scrollbar
        style="height: 100%"
        wrapStyle="overflow-x:hidden;"
        viewStyle=""
      >
        <!-- 选择树 -->
        <el-tree
          class="filter-tree"
          default-expand-all
          ref="tree"
          :props="defaultProps"
          :data="treeData"
          :show-checkbox="multiple"
          node-key="id"
          @node-click="handleNodeClick"
          @node-contextmenu="showContextmenu"
        >
        </el-tree>
      </el-scrollbar>
    </div>
  </div>

  <div class="menu-container-right">
    <el-scrollbar
      style="height: 100%"
      wrapStyle="overflow-x:hidden;"
      viewStyle=""
    >
      <el-form
        class="menuForm"
        ref="menuForm"
        :model="menu"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item :label="p_title" size="normal">
          {{ p_name }}
        </el-form-item>
        <el-form-item label="菜单名称" size="normal" prop="menu_name">
          <el-input
            v-model="menu.menu_name"
            placeholder="请输入菜单名称"
            size="normal"
            clearable
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="页面地址" size="normal">
          <el-input
            v-model="menu.url"
            placeholder="请输入页面地址"
            size="normal"
            clearable
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="图标icon" size="normal">
          <el-input
            v-model="menu.icon"
            placeholder="请输入图标icon"
            size="normal"
            clearable
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="页面组件" size="normal">
          <el-input
            v-model="menu.component"
            placeholder="请输入页面组件"
            size="normal"
            clearable
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="是否展开" size="normal" v-show="false">
          <el-switch
            v-model="menu.opened"
            active-text="是"
            inactive-text="否"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="是否显示" size="normal">
          <el-switch
            v-model="menu.status"
            active-text="是"
            inactive-text="否"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="排        序" size="normal">
          <el-input
            v-model.number="menu.sort"
            placeholder="请输入排序号"
            size="normal"
            type="number"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="描        述" size="normal">
          <el-input
            v-model="menu.remark"
            type="textarea"
            placeholder="请输入排序号"
            size="normal"
            clearable
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-scrollbar>
  </div>
  <div
    :style="{
      'z-index': 9999,
      position: 'fixed',
      left: optionCardX + 'px',
      top: optionCardY + 'px',
      width: '100px',
      background: 'white',
      'box-shadow':
        '0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)',
    }"
    v-show="optionCardShow"
    id="option-button-group"
  >
    <!-- <el-dropdown-item @click.stop="addMenu">新增菜单</el-dropdown-item>
    <el-dropdown-item @click.stop="removeMenu">删除菜单</el-dropdown-item> -->
    <li
      v-for="item in setting"
      :key="item.command"
      @click="handleCommand(item.command)"
    >
      <i :class="item.icon"></i>
      <span style="margin-left: 5px">{{ item.context }}</span>
    </li>
  </div>
</div>
