<template>
  <el-dialog
    class="showAll_dialog"
    :title="getDialogTitle()"
    width="1000px"
    :height="'80%'"
    :max-height="'450px'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
  >
    <el-form
      :model="form"
      :rules="dataRule"
      ref="form"
      label-width="110px"
      v-loading="formLoading"
    >
      <div class="flex">
        <el-form-item label="资讯标题" prop="news_title">
          <el-input
            v-model.trim="form.news_title"
            placeholder="请输入资讯标题："
            clearable
            maxlength="35"
          />
        </el-form-item>
        <el-form-item label="副标题" prop="subtitle">
          <el-input
            v-model.trim="form.subtitle"
            placeholder="请输入副标题"
            clearable
            maxlength="35"
          />
        </el-form-item>
        <el-form-item label="类型" prop="newsType">
          <el-select v-model="form.news_type">
            <el-option value="1" label="电池保养常识"></el-option>
            <el-option value="2" label="查看教程"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flex" style="margin-top: 10px">
        <el-form-item label="封面" prop="cover_pic_url">
          <el-upload
            v-if="!form.cover_pic_url"
            v-model="form.cover_pic_url"
            :action="uploadUrl"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :multiple="false"
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileList"
            :http-request="upload"
            :before-upload="beforeUp"
            list-type="picture-card"
            :disabled="editState != 'details' ? false : true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div v-else class="image-preview">
            <div class="image-preview-wrapper">
              <img :src="file_view_rul + form.cover_pic_url" />
              <div class="image-preview-action">
                <i @click="handleRemove()" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="发布日期" prop="release_time" label-width="166px">
          <el-date-picker
            v-model="form.release_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="请选择发布日期"
            :picker-options="pickerOptions"
            @change="handleDateChange"
          ></el-date-picker>
        </el-form-item>
      </div>
      <el-form-item label="详情内容" prop="details">
        <VueUeditorWrap v-model="form.details" :config="myConfig" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="formSubmit()">确定</el-button>
      <el-button v-if="editState != 'details'" @click="visible = false"
        >取消</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import axios from "../../../utils/axiosHelper";
import axiosConfig from "axios";
// import UEditor from '@/components/common/ueditor/index.vue';
import VueUeditorWrap from "vue-ueditor-wrap";

export default {
  // name: "AddOrUpdate",
  components: {
    VueUeditorWrap,
  },
  props: {
    editState: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      form: {
        news_title: "",
        subtitle: "",
        cover_pic_url: "",
        release_time: "",
        details: "",
        news_type: "1",
      },
      visible: false,
      formLoading: false,
      uploadUrl: "",
      fileList: [],
      defaultTime: "",
      file_view_rul: this.FILE_VIEW_ROOT_RUL,
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // 设置时间为今天的凌晨
          return time.getTime() < today.getTime();
        },
      },
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 400,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        // serverUrl: 'https://test.lvxiaoma.cn' + '/api/file/upload/path',
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: "/UEditor/",
        zIndex: 2500,
      },
    };
  },
  computed: {
    dataRule() {
      if (this.form.news_type == "1") {
        return {
          news_title: [
            {
              required: true,
              message: "请输入资讯标题",
              trigger: ["blur", "change"],
            },
          ],
          subtitle: [
            {
              required: true,
              message: "请输入副标题",
              trigger: ["blur", "change"],
            },
          ],
          cover_pic_url: [
            {
              required: true,
              message: "请上传封面图片",
              trigger: ["blur", "change"],
            },
          ],
          release_time: [
            {
              required: true,
              message: "请选择发布日期",
              trigger: ["blur", "change"],
            },
          ],
          details: [
            {
              required: true,
              message: "请输入详细内容",
              trigger: ["blur", "change"],
            },
          ],
        };
      } else {
        return {
          release_time: [
            {
              required: true,
              message: "请选择发布日期",
              trigger: ["blur", "change"],
            },
          ],
          details: [
            {
              required: true,
              message: "请输入详细内容",
              trigger: ["blur", "change"],
            },
          ],
        };
      }
    },
  },
  created() {},
  async mounted() {},
  methods: {
    init(row) {
      this.fileList = [];
      this.$nextTick(() => {
        // this.$refs["form"].resetFields();
        if (row) {
          this.$nextTick(() => {
            this.formItem = row;
            this.form.id = row.id;
            this.form.news_title = row.news_title;
            this.form.subtitle = row.subtitle;
            this.form.cover_pic_url = row.cover_pic_url;
            this.form.release_time = row.release_time;
            this.form.details = row.details;
            this.form.news_type = row.news_type;
            console.log(this.form);
            if (this.form.cover_pic_url) {
              this.fileList = [
                {
                  url: this.file_view_rul + this.form.cover_pic_url,
                  name: this.form.cover_pic_url,
                },
              ];
            }
          });
        } else {
          this.form.news_title = "";
          this.form.subtitle = "";
          this.form.cover_pic_url = "";
          this.form.release_time = "";
          this.form.details = "";
          this.form.news_type = "1";
          console.log(this.form, "aaa");
        }
      });
    },
    handleDateChange(value) {
      const selectedDate = new Date(value);
      const today = new Date();

      // 判断用户是否选择了今天
      if (
        selectedDate.getFullYear() === today.getFullYear() &&
        selectedDate.getMonth() === today.getMonth() &&
        selectedDate.getDate() === today.getDate()
      ) {
        // 更新时间为当前时间
        this.form.release_time = this.getCurrentDateTime();
      }
    },
    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const date = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");
      return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
    },
    getDialogTitle() {
      switch (this.editState) {
        case "edit":
          return this.form.news_title || "编辑";
        case "add":
          return "新增";
      }
    },
    //限制上传文件的个数提示
    handleExceed(files, fileList) {
      this.$message.warning("只允许上传1张图片");
    },
    //删除之前执行的方法
    beforeRemove(file) {
      //return this.$confirm(`确定移除 ${file.name}？`);
    },
    //文件删除的方法
    handleRemove() {
      // 点击删除图片，获取图片文件file,
      // 筛选出图片文件file.url和图片数组的url一致的数据
      // 删除掉这个数据
      this.fileList = [];
      this.form.cover_pic_url = "";
    },

    /* 上传前判断 */
    beforeUp(file) {
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        this.$message.error("仅支持上传jpg,jpeg,png格式图片");
        return false;
      } else if (file.size / 1024 / 1024 > 5) {
        this.$message.error("图片大小不能超过5M");
        return false;
      }
      return true;
    },
    /* 上传 */
    upload(file, index, type) {
      console.log(file, "file");
      const formData = new FormData();
      formData.append("path", "activityPage");
      formData.append("file", file.file);
      this.axiosHelper
        .post(`/api/file/upload/path`, formData)
        .then((res) => {
          // 获取上传成功返回的图片地址
          const imageUrl = res.data.url;

          let imgObj = {
            url: imageUrl,
            name: res.data.name,
          };
          console.log(index, type);

          this.fileList.push(imgObj);
          this.form.cover_pic_url = res.data.name;
        })
        .catch(() => {
          this.$message.error("上传失败");
        });
    },
    // 表单提交
    formSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (!valid) return;
        this.showLoading("加载中");
        //   新增
        if (this.editState == "add") {
          axios
            .post("/msg/battery/maintain/add", this.form)
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg);
                return;
              }
              this.fileList = [];
              this.visible = false;
              this.$emit("refreshDataList");
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            });
        } else if (this.editState == "edit") {
          // 编辑
          axios
            .post(`/msg/battery/maintain/update`, this.form)
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg);
                return;
              }
              this.fileList = [];
              this.visible = false;
              this.$emit("refreshDataList");
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form_list {
  &-title {
    &::after {
      content: " ";
      display: inline-block;
      width: 100%;
      height: 1px;
      background-color: rgb(222, 222, 222);
      margin: 10px 0;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 2px;
      height: 15px;
      background-color: #f6a83a !important;
      margin-right: 6px;
    }
  }
}

.showAll_dialog {
  overflow: hidden;
  ::v-deep .el-dialog {
    height: 70%;
    overflow: hidden;

    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
      line-height: 30px;
      padding: 0 15px 60px;
    }

    .el-dialog__footer {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: right;
      background-color: #ffffff;
      padding: 0 20px 0;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}

.marleft10 {
  margin-left: 10px;
}

.icon {
  width: 24px;
  height: 24px;

  &:first-child {
    margin-right: 4px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

::v-deep .el-upload-list__item {
  transition: none !important;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  &-btn {
    cursor: pointer;
    color: #315efb;
  }
}

.block {
  margin-bottom: 10px;
  /* 控制行与行之间的间距 */
}

.image-preview {
  position: relative;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .image-preview-wrapper {
    position: relative;
    width: 148px;
    height: 148px;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 1px dashed #d9d9d9;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    .image-preview-action {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s;
      cursor: pointer;
      text-align: center;
      line-height: 148px;
      border-radius: 10px;

      .el-icon-delete {
        font-size: 32px;
      }
    }
  }
}

.image-preview-wrapper:hover .image-preview-action {
  opacity: 1;
}
</style>