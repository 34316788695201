<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <div class="text item">
        <el-form ref="form" :model="form" label-width="110px">
          <el-row class="search">
            <el-form-item label="城市" style="width: 100%">
              <el-select
                v-model="form.firstCityCode"
                placeholder="全国"
                collapse-tags
                clearable
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.cityCode"
                  :label="item.cityName"
                  :value="item.cityCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-col class="elCol">
              <el-form-item label="年份：">
                <el-select v-model="form.year" placeholder="请选择">
                  <el-option :value="2024" label="2024" />
                  <el-option :value="2023" label="2023" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="elCol">
              <el-form-item label="月份：">
                <el-select v-model="form.month" placeholder="请选择">
                  <el-option :value="1" label="01" />
                  <el-option :value="2" label="02" />
                  <el-option :value="3" label="03" />
                  <el-option :value="4" label="04" />
                  <el-option :value="5" label="05" />
                  <el-option :value="6" label="06" />
                  <el-option :value="7" label="07" />
                  <el-option :value="8" label="08" />
                  <el-option :value="9" label="09" />
                  <el-option :value="10" label="10" />
                  <el-option :value="11" label="11" />
                  <el-option :value="12" label="12" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="elColBtn">
              <el-button
                style="margin-right: 15px"
                type="primary"
                @click="search"
                >查询</el-button
              >
              <el-button
                style="margin-right: 15px"
                type="default"
                @click="clear"
                >重置</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <el-button style="float: right" @click="exportData">导出</el-button>
      </el-row>
      <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
        <el-table-column
          prop="week_name"
          label="时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="new_add_user_num"
          label="新增注册用户数（个）"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.week_name != '合计'">
              <el-button
                @click="viewAddUserList(scope.row)"
                type="text"
                size="small"
                >{{ scope.row.new_add_user_num }}</el-button
              >
            </div>
            <div v-else>{{ scope.row.new_add_user_num }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_user_num"
          label="新用户下单客户数（个）"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.week_name != '合计'">
              <el-button
                @click="orderUserListList(scope.row, 0)"
                type="text"
                size="small"
                >{{ scope.row.order_user_num }}</el-button
              >
            </div>
            <div v-else>{{ scope.row.order_user_num }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_user_ratio"
          label="下单客户占比"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.order_user_ratio">
              {{ scope.row.order_user_ratio }}%
            </div>
            <!--<div v-else>0%</div>-->
          </template>
        </el-table-column>
        <el-table-column
          prop="un_order_user_num"
          label="未下单客户（个）"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="over_one_order_user_num"
          label="复购（2次及以上）客户数（个）"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.week_name != '合计'">
              <el-button
                @click="orderUserListList(scope.row, 1)"
                type="text"
                size="small"
                >{{ scope.row.over_one_order_user_num }}</el-button
              >
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="over_two_order_user_num"
          label="复购（3次及以上）客户数（个）"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.week_name != '合计'">
              <el-button
                @click="orderUserListList(scope.row, 2)"
                type="text"
                size="small"
                >{{ scope.row.over_two_order_user_num }}</el-button
              >
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="loss_user_num"
          label="流失客户"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="loss_user_ratio"
          :formatter="
            (row) => {
              if (row.loss_user_ratio) return row.loss_user_ratio + '%';
            }
          "
          label="客户流失率"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="loss_user_subsidy_money"
          label="流失客户消耗补贴金额"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table>
    </el-card>
    <NewAddUserList
      v-if="addUserListVisible"
      ref="newAddUserList"
      :dataItem="dataItem"
    ></NewAddUserList>
    <OrderUserList
      v-if="orderUserListVisible"
      ref="orderUserList"
      :dataItem="dataItem"
    ></OrderUserList>
  </div>
</template>
<script>
import DIM_API from "../../../api/dim_api";
import OPERATE_API from "../../../api/operate_api";
import NewAddUserList from "./new-add-user-list.vue";
import OrderUserList from "./order-user-list.vue";
import AREACORP_API from "../../../api/areacorp_api";
import * as XLSX from "xlsx";
import { operateAdd } from "@/api/system/user-api";
import moment from "moment";

export default {
  components: {
    NewAddUserList,
    OrderUserList,
  },
  data() {
    return {
      cityList: [],
      form: {
        year: new Date().toISOString().slice(0, 4),
        month: new Date().toISOString().slice(5, 7),
        firstCityCode: "",
      },
      createTime: [],

      dataTable: [],
      selection: [],
      tableHeight: "70vh",
      dataItem: [],
      addUserListVisible: false,
      orderUserListVisible: false,
    };
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  mounted() {
    this.search();
    this.getCityList();
  },
  methods: {
    filter() {
      this.filterVisible = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    // 获取城市选择列表
    async getCityList() {
      let res = await OPERATE_API.getCityList();
      if (res.data.code != 0) {
        this.$message.error({
          message: "加载城市列表错误",
        });
        return;
      }
      this.cityList = res.data.cityList;
    },
    async search() {
      this.loadDataTable();
    },
    clear() {
      this.form = {
        year: Number(moment().format("YYYY")),
        month: Number(moment().format("M")),
        firstCityCode: "",
      };
      this.loadDataTable();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    loadDataTable() {
      let params = {
        ...this.form,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/order/analysis/city/user/statistics/list", {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.list;
          this.$nextTick(() => {
            this.$refs["dataTable"].doLayout();
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },

    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 6,
        operate_parameter: JSON.stringify({
          ...this.form,
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      let params = {
        ...this.form,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/order/analysis/city/user/statistics/export", {
          params: params,
        })
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.items);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "城市客户开发情况.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["时间"] = item.week_name;
        newObj["新增注册用户数（个）"] = item.new_add_user_num;
        newObj["新用户下单客户数（个）"] = item.order_user_num;
        newObj["下单客户占比"] = item.order_user_ratio;
        newObj["未下单客户（个）"] = item.un_order_user_num;
        newObj["复购（2次及以上）客户数（个）"] = item.over_one_order_user_num;
        newObj["复购（3次及以上）客户数（个）"] = item.over_two_order_user_num;
        newObj["流失客户"] = item.loss_user_num;
        newObj["客户流失率"] = item.loss_user_ratio;
        newObj["流失客户消耗补贴金额"] = item.loss_user_subsidy_money;
        newArray.push(newObj);
      });
      return newArray;
    },
    xlsxToBlob(workbook) {
      const wopts = { bookType: "xlsx", bookSST: false, type: "blob" };
      const blob = XLSX.write(workbook, wopts);
      return blob;
    },
    viewAddUserList(row) {
      this.addUserListVisible = true;
      this.dataItem = row;
      this.$nextTick(() => {
        this.$refs.newAddUserList.init();
      });
    },
    orderUserListList(row, num) {
      this.orderUserListVisible = true;
      this.dataItem = row;
      this.$nextTick(() => {
        this.$refs.orderUserList.init(num);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
</style>
