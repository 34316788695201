
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="城市">
        <el-select
          v-model="form.cityCode"
          placeholder="全部"
          collapse-tags
          filterable
          clearable
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理商：">
        <el-input
          v-model.trim="form.agentName"
          placeholder="请输入代理商名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="营业状态">
        <el-select
          v-model="form.status"
          placeholder="全部"
          collapse-tags
          clearable
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <div class="flex" style="margin-bottom: 20px">
      <p>全国客服热线：{{ oldNationalCustomer.customer_service_phone }}</p>
      <el-button style="margin-left: 30px" @click="nationalDialogShow"
        >配置</el-button
      >
    </div>
    <el-table ref="tableData" :height="tableHeight" :data="dataTable">
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="agent_name"
        label="代理商"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="status"
        label="营业状态"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{ !scope.row.status ? "休息" : "营业" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="conventional_charging"
        label="常规充电营业时间"
        min-width="100"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.status">
            <div v-if="!scope.row.conventional_charging.business_type">
              全天
            </div>
            <div v-else>
              {{ scope.row.conventional_charging.start_time }}~{{
                scope.row.conventional_charging.end_time
              }}
            </div>
          </div>
          <div v-else>休息</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="discount"
        label="应急补电营业时间"
        min-width="100"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.status">
            <div v-if="!scope.row.emergency_charging.business_type">全天</div>
            <div v-else>
              {{ scope.row.emergency_charging.start_time }}~{{
                scope.row.emergency_charging.end_time
              }}
            </div>
          </div>
          <div v-else>休息</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handelConfiguration(scope.row)"
            type="text"
            size="small"
            >配置</el-button
          >
          <el-button
            v-if="user.isMaintenanceOrder == 1"
            @click="handelSpecialConfiguration(scope.row)"
            type="text"
            size="small"
            >特殊配置</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <operateConfiguration ref="Update" @refreshDataList="search">
  </operateConfiguration>
  <specialConfiguration ref="UpSpecialdate" @refreshDataList="search">
  </specialConfiguration>
  <el-dialog
    title="全国服务设置"
    :visible.sync="nationalDialogVisible"
    width="500px"
    :close-on-click-modal="false"
  >
    <el-form :model="nationalCustomer" label-width="120px" ref="form">
      <el-form-item
        prop="customer_service_phone"
        label="客服热线："
        :rules="[
          {
            required: true,
            message: '请输入客服热线',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <el-input
          style="width: 200px"
          v-model="nationalCustomer.customer_service_phone"
          maxlength="20"
        />
      </el-form-item>
      <el-form-item
        prop="wx_work_url"
        label="企微客服地址："
        :rules="[
          {
            required: true,
            message: '请输入企微客服地址',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <el-input
          style="width: 200px"
          v-model="nationalCustomer.wx_work_url"
          maxlength="150"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="nationalDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="nationalSubmit">确 定</el-button>
    </span>
  </el-dialog>
</div>
