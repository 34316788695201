<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.user_name" placeholder="账户姓名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="dataForm.mobile" placeholder="手机号" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button  type="primary" @click="getDataList()">查询</el-button>
        <el-button  type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <!-- <el-button  type="danger" @click="deleteHandle()"
          :disabled="dataListSelections.length <= 0">批量删除</el-button> -->
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <!-- <el-table-column type="selection" header-align="center" align="center" width="50">
      </el-table-column> -->
      <!-- <el-table-column prop="id" header-align="center" align="center" label="">
      </el-table-column> -->
      <el-table-column prop="user_name" header-align="center" align="center" min-width="120" label="账户名">
      </el-table-column>
      <el-table-column prop="login_name" header-align="center" align="center" min-width="100" label="用户名">
      </el-table-column>
      <!-- <el-table-column prop="login_password" header-align="center" align="center" label="MD5加密后的密文">
      </el-table-column> -->
      <el-table-column prop="mobile" header-align="center"  min-width="100" align="center" label="手机号">
      </el-table-column>
      <el-table-column prop="category" header-align="center" align="center"  label="账号类别">
      </el-table-column>
      <!-- 状态（0无效；1有效） -->
      <!-- <el-table-column prop="status" header-align="center" align="center" label="状态">
      </el-table-column> -->
      <el-table-column prop="remark" header-align="center" align="center" min-width="150" label="备注">
      </el-table-column>
      <!-- <el-table-column prop="createId" header-align="center" align="center" label="">
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" label="">
      </el-table-column> -->
      <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row)">修改</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <sysaccount_add_or_update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></sysaccount_add_or_update>
  </div>
</template>

<script>
import sysaccount_add_or_update from './sysaccount_add_or_update.vue'
import sysAccountApi from "../../api/sys_account_api";
export default {
  name: "sys_account",
  data() {
    return {
      dataForm: {
        user_name: "",
        mobile:""
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    sysaccount_add_or_update
  },
  created() {
    this.getDataList()
  },
  // activated:{
  //   this.getDataList()
  // },
  methods: {
    // 获取数据列表
   async getDataList() {
      this.dataListLoading = true

      let reqData = {
        'offset': this.pageIndex-1,
        'limit': this.pageSize,
        'reqdata': JSON.stringify(this.dataForm)
      }
      let res = await sysAccountApi.getByCondition(reqData)
      this.dataListLoading = false
      if (res.data.code != 0) {
        this.$message.error({
          message: res.data.msg
        });
        return
      }
      
      this.dataList = res.data.page.items
      this.totalPage = res.data.page.total_count
      // this.$http({
      //   url: this.$http.adornUrl('/partner/sysaccount/list'),
      //   method: 'get',
      //   params: this.$http.adornParams({
      //     'page': this.pageIndex,
      //     'limit': this.pageSize,
      //     'key': this.dataForm.key
      //   })
      // }).then(({ data }) => {
      //   if (data && data.code === 0) {
      //     this.dataList = data.page.list
      //     this.totalPage = data.page.totalCount
      //   } else {
      //     this.dataList = []
      //     this.totalPage = 0
      //   }
      //   this.dataListLoading = false
      // })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 删除
   async deleteHandle(id) {
      const confirmResult = await this.$confirm("确定删除该记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      let res = await sysAccountApi.deleteByIds(id)
      if(res.data.code!=0){
        this.$message.error(res.data.msg)
        return
      }
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 500,
        onClose: () => {
          this.getDataList()
        }
      })


      // var ids = id ? [id] : this.dataListSelections.map(item => {
      //   return item.id
      // })
      // this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   this.$http({
      //     url: this.$http.adornUrl('/partner/sysaccount/delete'),
      //     method: 'post',
      //     data: this.$http.adornData(ids, false)
      //   }).then(({ data }) => {
      //     if (data && data.code === 0) {
      //       this.$message({
      //         message: '操作成功',
      //         type: 'success',
      //         duration: 1500,
      //         onClose: () => {
      //           this.getDataList()
      //         }
      //       })
      //     } else {
      //       this.$message.error(data.msg)
      //     }
      //   })
      // })
    }
  }
}
</script>
<style scoped>
.el-form{
  margin-top:10px;
  margin-bottom:5px;
  height: 40px;
 align-content: center;
 line-height:60px;
  text-align:center;
}
.el-pagination{
  margin-top:10px;
}
</style>