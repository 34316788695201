/*
 * @Author: ljf
 * @Date: 2022-06-28 14:23:09
 * @LastEditors: ljf
 * @LastEditTime: 2022-06-28 14:36:50
 * @FilePath: \lxm-admin\src\api\system\dim-api.js
 * @Description: 
 * 
 */
import axios from "utils/axiosHelper";
import { Message } from 'element-ui';


export const getDimByType = (dataType) => {
  return axios.get(`api/dim/type/list?dataType=${dataType}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch((error) => {
      Message.error("获取枚举数据失败")
    })

}