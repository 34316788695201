<template>
    <el-dialog title="用户退款" v-dialogDrag :visible.sync="dialogVisible" :close-on-click-modal="false" width="600px"
        append-to-body>
        <el-form :model="dataForm" ref="dataForm" :rules="rules" label-width="110px">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="退款金额：" prop="money">
                        <el-input v-model="dataForm.money" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: {
        dialog: {
            type: Boolean,
            default() {
                return false;
            }
        },
        id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dialogVisible: false,
            dataForm: {},
            rules: {
                money: [{
                    required: true,
                    message: '请填写退款金额',
                    trigger: 'blur',
                },
                {
                    validator: this.validateMoney,
                    trigger: "blur"
                }]
            }
        }
    },
    watch: {
        dialog: {
            immediate: true,
            handler(value) {
                this.dialogVisible = value;
                if (value) {
                    this.clear();
                }
            }
        },
        dialogVisible: {
            handler(value) {
                this.$emit("update:dialog", value);
            }
        }
    },
    methods: {
        clear() {
            this.dataForm = {}
        },
        cancel() {
            this.dialogVisible = false;
        },
        confirm() {
            let param = {
                id: this.id,
                money: this.dataForm.money
            }
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.showLoading("提交中");
                    this.axiosHelper.put('/api/userAccount/refund', param)
                        .then((response) => {
                            this.hideLoading();
                            this.dialogVisible = false;
                            this.$emit("search", this.dataForm);
                        })
                        .catch((err) => {
                            this.hideLoading();
                            if (err.response.data.message) {
                                this.$message.error(err.response.data.message);
                            } else {
                                this.$message.error("提交失败");
                            }
                        })
                } else {
                    this.$message({
                        message: '请将表单填写完整',
                        type: 'error',
                    })
                    //错误位置定位
                    setTimeout(() => {
                        var isError =
                            document.getElementsByClassName('is-error')
                        isError[0].querySelector('input').focus()
                    }, 100)
                    return false
                }
            })
        },
        validateMoney(rule, value, callback) {
            var reg = /^(([1-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/
            if (!reg.test(value)) {
                callback(new Error('金额限制整数最大8位，小数2位'))
            } else {
                callback()
            }
        }
    }
}
</script>