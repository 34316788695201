/*
 * @Author: ljf
 * @Date: 2022-05-27 10:29:34
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-14 11:01:15
 * @FilePath: \lxm-admin\src\api\system\user-api.js
 * @Description:
 *
 */
import axios from "utils/axiosHelper";
import { Message } from "element-ui";
import { userHead } from "utils/utils";

/**
 * @description: 获取当前登录用户信息
 * @return {*}
 */
export const getUserPage = (param) => {
  let params = JSON.parse(JSON.stringify(param));
  let offset = params.offset || 0;
  let limit = params.limit || 10;
  delete params.offset;
  delete params.limit;
  console.log(params);
  return axios
    .get(`/api/user/manage/delivery/user/query/${offset}/${limit}`, {
      params: params,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data.data;
      }
    })
    .catch(() => {
      Message.error("当前登录用户信息获取失败");
    });
};

/**
 * @description: 删除用户
 * @param {*} ids
 * @return {*}
 */
export const deleteUser = (ids) => {
  return axios
    .post(`/api/user/manage/delivery/user/delete`, ids)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    });
};
/**
 * @description: 配送员认证审核
 * @param {Object} data 参数对象
 * @param {String} data.deliveryerCheckState 审核状态
 * @param {String} data.deliveryerRejectReason 驳回原因
 * @param {Object} data.id 用户
 * @return {*}
 */
export const examineAuthentication = (data) => {
  return axios
    .post(`/api/user/authentication/examineAuthentication`, data)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    });
};
