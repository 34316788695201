<!--
 * @Author: ljf
 * @Date: 2022-05-26 16:17:06
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-15 10:52:01
 * @FilePath: \lxm-admin\src\views\system\org\Org.vue
 * @Description: 
 * 
-->
<template>
  <div class="org-container">
    <div class="org-container-left is-always-shadow">
      <org-tree
        ref="orgTree"
        @nodeClick="handleNodeClick"
        showContext
        @command="handlerCommand"
      ></org-tree>
    </div>
    <div class="org-container-right">
      <div class="org-container-right-form">
        <el-card>
          <el-form
            :model="org"
            ref="orgForm"
            label-width="80px"
            style="width:100%"
          >

            <el-row style="width: 100%">
              <el-col :span="8">
                <el-form-item label="组织名称">
                  <el-input
                    v-model="org.org_name"
                    :style="InputWidth"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="组织编号">
                  <el-input
                    v-model="org.org_code"
                    readonly
                    :style="InputWidth"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="组织类型">
                  <!-- <el-input
                    v-model="org.category"
                    readonly
                  ></el-input> -->
                  <el-select
                    v-model="org.category"
                    disabled
                    :style="InputWidth"
                  >
                    <el-option
                      v-for="item in categoryOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="width: 100%">
              <el-col :span="8">
                <el-form-item label="组织别名">
                  <el-input
                    v-model="org.alias"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="组织领导">
                  <el-input
                    v-model="org.leader_name"
                    readonly
                    :style="InputWidth"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="排序值">
                  <el-input
                    v-model="org.sort_index"
                    readonly
                    :style="InputWidth"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="width: 100%">
              <el-col :span="8">
                <el-form-item label="联系地址">
                  <el-input
                    v-model="org.address"
                    readonly
                    :style="InputWidth"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系电话">
                  <el-input
                    v-model="org.telephone"
                    readonly
                    :style="InputWidth"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="是否显示">
                  <el-switch
                    v-model="org.show"
                    active-text="是"
                    inactive-text="否"
                    disabled
                    :style="InputWidth"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </div>
      <div class="org-container-right-table">
        <el-card>
          <el-row :gutter="20">
            <el-col
              :span="4"
              :offset="0"
            ></el-col>
            <el-button
              class="rewrite-button-style"
              size="small"
              @click="addOrgUser"
            >添加组织用户</el-button>
            <el-button
              class="rewrite-button-style"
              size="small"
              @click="removeOrgUser"
            >移除组织用户</el-button>
          </el-row>

          <el-row
            :gutter="20"
            style="margin-top:12px;width:100%"
          >
            <base-table
              ref="choose-table"
              :data="tableData"
              showIndex
              showCheck
              :height="height"
              :columns="tableColumns"
              :totalCount="totalCount"
              @page-change="pageChange"
              @on-select-change="onSelectChange"
            ></base-table>
          </el-row>
        </el-card>
      </div>
    </div>
    <!-- 组织信息编辑弹窗 -->
    <ex-dialog
      :title="editOrgTitle"
      :visible.sync="orgDialogVisible"
      v-if="orgDialogVisible"
      :width="1100"
      :height="500"
      :show-fullscreen-icon="true"
      :append-to-body="true"
    >
      <org-edit
        :action="action"
        :org_="org_"
        :categoryOptions="categoryOptions"
        @close="closeEditOrgTitle"
        @refresh="refresh"
      ></org-edit>

    </ex-dialog>
    <!-- 组织角色弹窗 -->
    <ex-dialog
      title="设置组织角色"
      :visible.sync="orgRoleUserDialogVisible"
      v-if="orgRoleUserDialogVisible"
      :width="1300"
      :height="700"
      :show-fullscreen-icon="true"
      :append-to-body="true"
    >
      <org-role-user
        v-if="orgRoleUserDialogVisible"
        :selectOrgId="org.id"
        @close="orgRoleUserDialog"
      ></org-role-user>
    </ex-dialog>
    <!-- 添加组织角色弹窗 -->
    <ex-dialog
      title="添加组织用户"
      :visible.sync="OrgUserDialogVisible"
      v-if="OrgUserDialogVisible"
      :width="1100"
      :height="700"
      :show-fullscreen-icon="true"
      :append-to-body="true"
    >
      <role-user
        role_id=""
        :org_id="org.id"
        type="role"
        @close="closeOrgUser"
        @submit="submitOrgUser"
      ></role-user>
    </ex-dialog>
  </div>
</template>

<script>
  import { getUserById } from 'api/system/user-api'
  import { getOrgUserData, deleteOrg, orgUserRemove, orgUserAssign } from 'api/system/org-api'
  import { getDimByType } from 'api/system/dim-api'
  import OrgEdit from './OrgEdit.vue'
  export default {
    components: { OrgEdit },
    name: 'org',
    data () {
      return {
        org: {
          org_name: '',
          org_code: '',
          category: '',
          alias: '',
          leader_name: '',
          sort_index: 0,
          telephone: '',
          show: false,
        },
        tableData: [],
        height: '53vh',
        tableColumns: [
          {
            label: '账号',
            prop: 'user_name',
            style: 'center',
            minWidth: '80'
          },
          {
            label: '姓名',
            prop: 'real_name',
            style: 'center',
            minWidth: '80'
          },
          {
            label: '性别',
            prop: 'sex',
            style: 'center',
            minWidth: '80'
          },
          {
            label: '手机号',
            prop: 'cellphone',
            style: 'center',
            minWidth: '80'
          },
          {
            label: '所属组织',
            prop: 'org_name',
            style: 'center',
            minWidth: '80'
          },
        ],
        totalCount: 0,
        page: 1,
        offset: 0,
        limit: 50,
        action: 'add',
        orgDialogVisible: false,
        orgRoleUserDialogVisible: false,
        OrgUserDialogVisible: false,
        org_: null,
        categoryOptions: [],
        selectUsers: [],
        InputWidth: 'auto',
      }
    },
    computed: {
      editOrgTitle () {
        return this.action === 'add' ? '新增组织' : '编辑组织'
      }
    },
    methods: {
      async handleNodeClick (data) {
        this.org = { ...data }
        if (data.leader_id != '') {
          var user = await getUserById(data.leader_id)
          this.org.leader_name = user.real_name
        }
        this.org.org_id = data.id
        this.search()
      },
      onSelectChange (data) {
        this.selectUsers = data;
        console.log(this.selectUsers);
      },
      async search () {
        var data = await getOrgUserData(this.page, this.offset, this.limit, this.org.org_id)
        this.tableData = data.items;
        this.totalCount = data.total_count
      },
      addRootOrg () {
        this.action = 'add'
        this.org_ = {
          org_name: '',
          org_code: '',
          category: '',
          alias: '',
          leader_name: '',
          sort_index: 0,
          telephone: '',
          show: false,
          parent_id: 0,
          parent_name: '顶级组织'
        }
        this.orgDialogVisible = true
      },
      addOrg () {
        this.action = 'add'
        this.org_ = {
          org_name: '',
          org_code: '',
          category: '',
          alias: '',
          leader_name: '',
          sort_index: 0,
          telephone: '',
          show: true,
          parent_id: this.org.id,
          parent_name: this.org.org_name
        }
        this.orgDialogVisible = true
      },
      editOrg () {
        this.action = 'edit'
        this.org_ = { ...this.org }
        this.orgDialogVisible = true
      },
      async removeOrg () {
        this.$confirm('此操作将永久删除该组织, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          var data = await deleteOrg(this.org.id)
          if (data) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
          this.refresh()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      pageChange (page) {
        this.limit = page.limit;
        this.offset = page.offset;
        this.page = page.currentPage;
        this.search()
      },
      setOrgUser () {
        this.orgRoleUserDialogVisible = true;
      },
      orgRoleUserDialog () {
        this.orgRoleUserDialogVisible = false;
      },
      handlerCommand (command, selectOrg) {
        this.handleNodeClick(selectOrg)
        this[command]()
      },
      closeEditOrgTitle () {
        this.orgDialogVisible = false
      },
      refresh () {
        this.$refs["orgTree"].init();
        this.org = {
          org_name: '',
          org_code: '',
          category: '',
          alias: '',
          leader_name: '',
          sort_index: 0,
          telephone: '',
          show: false,
        }
      },
      async getCategoryOptions () {
        this.categoryOptions = (await getDimByType('OrgType')).map((t) => {
          return {
            value: t.data_value ?? 0,
            label: t.data_name
          }
        })
      },
      addOrgUser () {
        console.log(this.org.org_id);
        if (this.org.org_id) {
          this.OrgUserDialogVisible = true
        }
        else {
          this.$message.warning("请选择添加用户的组织")
        }

      },
      removeOrgUser () {
        if (this.selectUsers.length === 0) {
          this.$message.warning("请选择要删除的组织用户")
        }
        this.$confirm('此操作将永久删除该组织用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          var obj = {
            orgId: this.org.id,
            userIds: this.selectUsers.map(t => t.uid).join(',')
          }
          var data = await orgUserRemove(obj)
          if (data) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.search()
          }
          this.refresh()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      },
      closeOrgUser () {
        this.OrgUserDialogVisible = false
      },
      async submitOrgUser (data) {
        var obj = {
          orgId: this.org.id,
          userIds: data.map(t => t.uid).join(',')
        }
        var data = await orgUserAssign(obj)
        if (data > -1) {
          this.$message.success("设置角色用户成功")
          this.$emit('close')
        }
        else {
          this.$message.error("设置角色用户失败")
        }
        this.OrgUserDialogVisible = false
        this.search()
      },
    },
    mounted () {
      this.getCategoryOptions()
    }
  }
</script>

<style lang="scss" scoped>
.org-container {
  margin-top: 10px;
  height: calc(100% - 20px);
  width: 100%;
  display: flex;
  .org-container-left {
    height: calc(100% - 10px);
    width: 300px;
    padding: auto;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    background-color: #fff;
    color: #303133;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .org-container-right {
    margin-left: 10px;
    flex: 1;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    .org-container-right-form {
      width: 100%;
      flex: 0 0 24% !important;
      margin-bottom: 5px;
    }
    .org-container-right-table {
      flex: 1;
      margin-top: 5px;
    }
  }
}
</style>