<!--
 * @Author: ljf
 * @Date: 2022-05-26 16:17:06
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-15 15:56:45
 * @FilePath: \lxm-admin\src\views\system\user\User.vue
 * @Description:
 *
-->
<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="手机号码：">
          <el-input
            v-model.trim="search_value.cellphone"
            placeholder=""
            clearable
          />
        </el-form-item>
        <el-form-item label="用户编号：">
          <el-input
            v-model.trim="search_value.userNumber"
            placeholder=""
            clearable
            maxlength="10"
            oninput="value=value.replace(/[^0-9]/g,'')"
          />
        </el-form-item>
        <el-form-item label="微信昵称：">
          <el-input
            v-model.trim="search_value.realName"
            placeholder=""
            clearable
          />
        </el-form-item>
        <el-form-item label="城市：">
          <el-select
            v-model="search_value.firstCityCode"
            placeholder="全国"
            multiple
            collapse-tags
            clearable
            filterable
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否黑名单：">
          <el-select
            v-model="search_value.blacklist"
            collapse-tags
            clearable
            @clear="search_value.blacklist = null"
          >
            <el-option
              v-for="item in if_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否脱敏：" v-if="isShowBtn">
          <el-select
            v-model="search_value.desensi"
            collapse-tags
            clearable
            @clear="search_value.desensi = null"
          >
            <el-option
              v-for="item in if_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户类型：">
          <el-select
            v-model="search_value.userCategory"
            collapse-tags
            clearable
            @clear="search_value.userCategory = null"
            @change="userCategoryChange"
          >
            <el-option
              v-for="item in user_categorys"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="B端类型：">
          <el-select
            v-model="search_value.unPayNumLimit"
            collapse-tags
            clearable
            @clear="search_value.unPayNumLimit = null"
          >
            <el-option
              v-for="item in un_pay_num_limits"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册时间：">
          <el-date-picker
            v-model="createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            align="center"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="注册推荐人类型：">
          <el-select
            v-model="search_value.recommendType"
            collapse-tags
            clearable
            @clear="search_value.recommendType = null"
          >
            <el-option
              v-for="item in recommend_type_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推荐人：">
          <el-input
            v-model.trim="search_value.referenceName"
            placeholder=""
            clearable
          />
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="init"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <!-- <el-button
          class="rewrite-button-style"
          size="small"
          @click="addUser"
          v-if="isShowBtn"
          >新增</el-button
        > -->
        <!-- <el-button class="rewrite-button-style" size="small" @click="deleteUser(multiple_selection)" v-if="isShowBtn">删除</el-button> -->
        <el-button
          class="rewrite-button-style"
          size="small"
          @click="resetPassword"
          v-if="isShowBtn"
          >重置密码</el-button
        >
        <el-button class="rewrite-button-style" size="small" @click="exportData"
          >导出</el-button
        >
      </el-row>

      <el-table
        :data="table_data"
        max-height="80vh"
        height="70vh"
        border
        header-align="center"
        element-loading-customClass="personal-loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(204, 204, 204, 0.6)"
        class="refTable"
        ref="refTable"
        default-expand-all
        :header-row-style="headerRowStyle"
        :header-cell-style="headerCellStyle"
        :cell-style="cell_style"
        :style="table_style"
        @selection-change="handleSelectionChange"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
          fixed="left"
        ></el-table-column>
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column
          prop="first_city_name"
          label="城市"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="手机号码"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="real_name"
          label="微信昵称"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="un_pay_num_limit"
          label="用户类型"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.user_category == 1">C端用户</div>
            <div v-else>B端用户</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="un_pay_num_limit"
          label="是否允许连续下单"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.user_category == 1 ||
                (scope.row.un_pay_num_limit == 1 &&
                  scope.row.user_category != 1)
              "
            >
              否
            </div>
            <div v-else>是</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="注册时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" min-width="150" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.sex == '1'">男</div>
            <div v-else-if="scope.row.sex == '2'">女</div>
            <div v-else>保密</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="recommend_type_name"
          label="注册推荐人类型"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="reference_name"
          label="推荐人"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="blacklist"
          label="是否黑名单"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.blacklist == 1">是</div>
            <div v-else>否</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="desensi"
          label="是否脱敏"
          min-width="150"
          align="center"
          v-if="isShowBtn"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.desensi == 1">是</div>
            <div v-else>否</div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="250"
          align="center"
          v-if="isShowBtn"
        >
          <template slot-scope="scope">
            <el-button
              v-if="isShowCityBtn"
              type="primary"
              size="small"
              @click="openCityConfigDialog(scope.row)"
              >城市配置</el-button
            >
            <el-button type="primary" size="small" @click="editUser(scope.row)"
              >编辑</el-button
            >
            <!-- <el-button type="danger" size="small" @click="deleteUser([scope.row])">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current_page"
          small
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="page_size"
          layout="total, sizes, prev, pager, next"
          :total="total_count"
        ></el-pagination>
      </el-footer>
    </el-card>

    <!-- <ex-dialog
      :title="title"
      :visible.sync="dialog_visible"
      v-if="dialog_visible"
      :width="900"
      :height="500"
      :show-fullscreen-icon="true"
      :append-to-body="true"
    >
      <el-scrollbar
        style="height: 100%; margin-right: -20px"
        wrapStyle="overflow-x:hidden;padding-right:20px;"
        viewStyle=""
      >
        <user-edit
          :action="action"
          :user_="select_user"
          @close="closeDialog"
          @refresh="refresh"
        ></user-edit>
      </el-scrollbar>
      <div slot="footer">
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </ex-dialog> -->
    <user-edit
      :title="title"
      v-if="dialog_visible"
      :action="action"
      :user_="select_user"
      @close="closeDialog"
      @refresh="refresh"
    ></user-edit>
    <el-dialog
      title="城市信息"
      :visible.sync="cityConfigDialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="城市：">
          <el-checkbox-group
            v-model="form.city_codes"
            :disabled="form.restricted_city == 0"
          >
            <el-checkbox
              v-for="city in cityList"
              :key="city.cityCode"
              :label="city.cityCode"
              >{{ city.cityName }}</el-checkbox
            >
          </el-checkbox-group></el-form-item
        >
        <el-form-item label="是否受限城市：">
          <el-radio-group v-model="form.restricted_city">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cityConfigDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveCityConfig">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserPage, resetUser, deleteUser } from "api/system/user-api";
import { flatten } from "utils/utils";
import UserEdit from "./UserEdit.vue";
import * as XLSX from "xlsx";
import OPERATE_API from "../../../api/operate_api";
import agentApi from "../../../api/agent_api";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";
import { recommend_type_list } from "@/enum/userEnum";
export default {
  components: { UserEdit },
  name: "user",
  props: {
    defaultProps: {
      type: Object,
      default: () => {
        return {
          value: "id",
          label: "org_name",
          children: "child_orgs",
          parentId: "parent_id",
          treeDeep: "tree_deep",
        };
      },
    },
  },
  data() {
    return {
      table_style: {
        "margin-top": "10px",
        width: this.tableType == "table" ? "100%" : "99%",
      },
      headerCellStyle: {
        background: "#EDF1F4",
        textAlign: "center",
        padding: "0",
      },
      cell_style: {
        fontSize: "14px",
        color: "#000",
        padding: "0",
        height: "40px",
      },
      headerRowStyle: {
        "line-height": "36px",
      },
      current_page: 1,
      loading: false,
      searchText: "",
      table_data: [],
      total_count: 0,
      page_size: 50,
      multiple_selection: [],
      dialog_visible: false,
      action: "add",
      selectedUserId: "",
      total_table_data: [],
      cityList: [],
      search_value: {
        limit: 50,
        offset: 0,
        page: "1",
        orgIds: "",
        code: "",
        keyword: "",
        cellphone: "",
        realName: "",
        userNumber: "",
        firstCityCode: [],
        blacklist: null,
        userCategory: null,
        unPayNumLimit: null,
        desensi: null,
        recommendType: null,
        referenceName: "",
      },
      createTime: [],
      select_user: null,
      screen_width: document.body.clientWidth,
      style: null,
      if_options: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      user_categorys: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "C端用户",
        },
        {
          value: 2,
          label: "B端用户",
        },
      ],
      cityConfigDialogVisible: false,
      form: {
        city_codes: [],
        restricted_city: 1,
      },
      isShowBtn: false,
      isShowCityBtn: false,
      tableLoading: false,
    };
  },

  created() {
    if (this.$store.state.user.isMaintenanceOrder == 1) {
      this.isShowBtn = true;
    }
    if (this.$store.state.user.isMaintenanceOrder == 1) {
      this.isShowCityBtn = true;
    }
    this.getCityList();
  },
  computed: {
    ...mapState(["user", "restrictedCityList"]),
    title() {
      return this.action === "add" ? "新增用户信息" : "编辑用户信息";
    },
    collapse() {
      return this.$store.state.collapse;
    },
    un_pay_num_limits() {
      if (this.search_value.userCategory == 1) {
        return [
          {
            value: null,
            label: "全部",
          },
        ];
      } else {
        return [
          {
            value: null,
            label: "全部",
          },
          {
            value: 0,
            label: "连续下单",
          },
          {
            value: 1,
            label: "B端优享",
          },
          {
            value: 2,
            label: "B端优享+连续下单",
          },
        ];
      }
    },
    recommend_type_list() {
      return recommend_type_list;
    },
  },
  watch: {
    screen_width(val) {
      if (!this.timer) {
        this.screen_width = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // that.screen_width = that.$store.state.canvasWidth
          that.getStyle();
          that.timer = false;
        }, 300);
      }
    },
    collapse() {
      this.getStyle();
    },
  },
  methods: {
    userCategoryChange(e) {
      if (e == 1) {
        this.search_value.unPayNumLimit = null;
      }
    },
    // 获取城市选择列表
    async getCityList() {
      let res = await OPERATE_API.getCityList();
      if (res.data.code != 0) {
        this.$message.error({
          message: "加载城市列表错误",
        });
        return;
      }
      this.cityList = res.data.cityList;
    },
    handleNodeClick(data) {
      var { value } = this.defaultProps;
      this.search_value.orgIds = flatten([data], this.defaultProps)
        .map((t) => t[value])
        .join(",");
      this.search();
    },
    clear() {
      this.search_value = {
        limit: 50,
        offset: 0,
        page: "1",
        orgIds: "",
        code: "",
        keyword: "",
        cellphone: "",
        realName: "",
        userNumber: "",
        firstCityCode: [],
        blacklist: null,
        userCategory: null,
        unPayNumLimit: null,
        desensi: null,
        recommendType: null,
      };
      this.createTime = [];
      this.search();
    },
    formatMerchantName(row, column) {
      // 检查数据是否为空，如果为空则显示 "-"
      return row[column.property] || "-";
    },
    async search() {
      try {
        this.loading = true;
        let search_value = { ...this.search_value };
        search_value.firstCityCode = search_value.firstCityCode.join(",");
        if (this.createTime) {
          search_value.createTimeStart = this.createTime[0];
          search_value.createTimeEnd = this.createTime[1];
        } else {
          search_value.createTimeStart = null;
          search_value.createTimeEnd = null;
        }
        if (search_value.unPayNumLimit && !search_value.userCategory) {
          search_value.userCategory = 2;
        }
        var data = await getUserPage(search_value);
        let arr = data.items;
        arr.forEach((item) => {
          item.recommend_type_name =
            recommend_type_list.find((it) => it.value === item.recommend_type)
              ?.label || "";
        });
        this.table_data = arr;
        this.total_count = data.total_count;
      } catch (error) {
        this.loading = false;
      }
      this.loading = false;
    },
    async init() {
      this.search_value.offset = 0;
      this.search_value.page = "1";
      this.current_page = 1;
      this.search();
    },
    addUser() {
      this.select_user = {
        user_name: "",
        real_name: "",
        wechat_no: "",
        open_id: "",
        sex: "",
        user_no: "",
        cellphone: "",
        email: "",
        org_ids: this.search_value.orgIds,
        role_ids: "",
        user_id: "",
        blacklist: 0,
        user_number: "",
        desensi: 1,
        orgs: {
          id: "",
          name: "",
        },
      };
      this.action = "add";
      this.dialog_visible = true;
    },
    deleteUser(list) {
      if (list.length === 0) {
        this.$message.warning("请选择记录");
        return;
      }
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteUser(list.map((item) => item.uid));
          this.$message.success("删除成功!");
          this.search();
        })
        .catch((e) => {
          this.$message.info("已取消删除");
        });
    },
    editUser(row) {
      this.select_user = { ...row };
      this.select_user.orgs = { id: "", name: "" };
      this.action = "edit";
      this.dialog_visible = true;
      console.log(this.dialog_visible);
    },
    async resetPassword() {
      if (this.multiple_selection.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择要重置密码的用户",
        });
        return;
      }
      this.$confirm("确定重置用户密码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await resetUser(this.multiple_selection.map((t) => t.uid));
          this.$message({
            type: "success",
            message: "重置用户密码成功!",
          });
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
    handleSelectionChange(val) {
      this.multiple_selection = val;
      // this.multiple_selection = this.multiple_selection.reduce((list, item) => {
      //   if (list.every((t) => t.id !== item.id)) {
      //     list.push(item);
      //   }
      //   return list;
      // }, []);
      // //如果等于空 需要把本页所有的项目移除
      // var selectedIds = val.map((t) => t.id);
      // var ids = this.table_data
      //   .map((t) => t.id)
      //   .filter((t) => {
      //     return !selectedIds.includes(t);
      //   });
      // //当前表格没有选中的
      // this.multiple_selection = this.multiple_selection.reduce((list, item) => {
      //   if (!ids.includes(item.id)) {
      //     list.push(item);
      //   }
      //   return list;
      // }, []);
    },
    handleCurrentChange(current) {
      this.current_page = current;
      this.search_value.offset = (this.current_page - 1) * this.page_size;
      this.search();
    },
    handleSelectable(row) {
      return !this.total_table_data.some((t) => t.id === row.id);
    },
    handleSizeChange(size) {
      this.page_size = size;
      this.search_value.limit = this.page_size;
      this.handleCurrentChange(1);
    },
    closeDialog() {
      this.dialog_visible = false;
    },
    refresh() {
      this.search();
    },
    getStyle() {
      var flag = this.$store.state.collapse;
      var obj = {};
      if (!flag) {
        var a = document.body.clientWidth;

        obj["max-width"] = a - 560 + "px";
      } else {
        var a = document.body.clientWidth;

        obj["max-width"] = a - 360 + "px";
      }
      this.style = obj;
    },
    async exportData() {
      let search_value = { ...this.search_value };
      search_value.firstCityCode = search_value.firstCityCode.join(",");
      if (this.createTime) {
        search_value.createTimeStart = this.createTime[0];
        search_value.createTimeEnd = this.createTime[1];
      } else {
        search_value.createTimeStart = null;
        search_value.createTimeEnd = null;
      }
      if (search_value.unPayNumLimit && !search_value.userCategory) {
        search_value.userCategory = 2;
      }
      await operateAdd({
        operate_type: "1",
        operate_number: 34,
        operate_parameter: JSON.stringify({
          ...search_value,
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.multiple_selection.length > 0) {
        let newData = this.getHeadersAndbody(this.multiple_selection);
        const ws = XLSX.utils.json_to_sheet(newData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const fileName = "用户.xlsx";
        XLSX.writeFile(wb, fileName);
        return;
      }
      if (this.total_count > 20000) {
        await this.$confirm(
          `您本次需要导出${this.total_count}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        ...search_value,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/manage/export", {
          params: params,
        })
        .then((res) => {
          this.hideLoading();
          let arr = res.data.items;
          arr.forEach((item) => {
            item.recommend_type_name =
              recommend_type_list.find((it) => it.value === item.recommend_type)
                ?.label || "";
          });
          let newData = this.getHeadersAndbody(arr);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "用户.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["用户编号"] = item.user_number;
        newObj["手机号码"] = item.cellphone;
        newObj["微信昵称"] = item.real_name;
        newObj["注册时间"] = item.create_time;
        let sex = "保密";
        if (item.sex == "1") {
          sex = "男";
        } else if (item.sex == "2") {
          sex = "女";
        }
        newObj["性别"] = sex;
        newObj["注册推荐人类型"] = item.recommend_type_name || "-";
        newObj["推荐人"] = item.reference_name || "-";
        newObj["是否黑名单"] = !item.blacklist ? "否" : "是";
        newObj["城市"] = item.first_city_name;
        newArray.push(newObj);
      });
      return newArray;
    },
    // 打开城市配置对话框
    openCityConfigDialog(row) {
      console.log("原用户信息:", row);
      this.select_user = row;
      this.form.city_codes = [];
      this.form.restricted_city = JSON.parse(
        JSON.stringify(row)
      ).restricted_city;
      let params = {
        user_id: this.select_user.uid,
      };
      this.axiosHelper
        .post("/api/user/common/manage/city/restricted/get", params)
        .then((res) => {
          this.form.city_codes = res.data.data.cityList;
          this.form.restricted_city = res.data.data.restrictedCity;
          this.cityConfigDialogVisible = true;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("获取用户管理城市信息失败");
        });
    },

    saveCityConfig() {
      let params = {
        user_id: this.select_user.uid,
        ...this.form,
      };
      this.axiosHelper
        .post("/api/user/common/manage/city/update", params)
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.search();
          }
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("获取用户管理城市信息失败" + "");
        });
      this.cityConfigDialogVisible = false;
    },
  },
  async mounted() {
    this.init();
    this.getStyle();
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screen_width = window.screenWidth;
      })();
    };
  },
};
</script>

<style lang="scss" scoped>
.user-container {
  height: 97%;
  width: 100%;
  display: flex;
  padding: 5px;
  margin-top: 10px;
  .user-left {
    width: 300px;
    display: flex;
    flex-direction: column;
    border-top: 0px;

    border: 0.5px solid #c1cfdf;
    border-top: 0;
    height: 99.9%;
    .user-left-tree {
      height: calc(100% - 30px);
    }
  }
  .user-table {
    flex: 1;
    border-left: 0px;
    border: 0.5px solid #c1cfdf;
    border-top: 0;
    height: 99.9%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: 20px;
    .user-table-bar {
      margin-top: 10px;
      height: 30px;
      padding-left: 20px;
      display: flex;
      .user-table-bar-left {
        flex: 1;
      }
      .user-table-bar-right {
        width: 400px;
        display: flex;
        padding-right: 20px;
      }
    }
    .user-table-container {
      flex: 1;
      padding-left: 20px;
      height: 70%;
    }
    .user-table-floor {
      min-height: 35px;
      flex: 0 0 5% !important;
      display: flex;
      justify-content: left;
      align-content: center;
    }
  }
  .is-horizontal {
    display: none;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
</style>
