<template>
  <el-dialog
    title="开票详情"
    v-dialogDrag
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-scrollbar
      style="height: 500px; margin-right: -20px"
      wrapStyle="padding-right:20px;overflow-x:hidden;"
      viewStyle
    >
      <el-form :model="dataForm" ref="dataForm" label-width="110px">
        <el-row class="block-header">
          <span class="block-title">基本信息</span>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="开票流水">
              <el-input v-model="dataForm.bill_no" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="城市">
              <el-input v-model="dataForm.city_name" readonly />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="申请手机号">
              <el-input v-model="dataForm.user_mobile" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请时间">
              <el-input v-model="dataForm.create_time" readonly />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="抬头类型">
              <el-input v-model="dataForm.bill_type_name" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发票抬头">
              <el-input v-model="dataForm.company_name" readonly />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="公司税号">
              <el-input v-model="dataForm.company_tax_no" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开票金额(元)">
              <el-input v-model="dataForm.real_pay_money" readonly />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="开票状态">
              <el-input v-model="dataForm.state_name" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱地址">
              <el-input v-model="dataForm.email_address" readonly />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="block-header">
          <span class="block-title">开票订单</span>
          <el-button style="float: right" @click="exportData">导出</el-button>
        </el-row>
        <el-row class="block-body">
          <el-table
            ref="dataTable"
            :data="dataTable"
            style="margin-top: 10px; margin-left: 2px"
          >
            <el-table-column
              type="index"
              width="50"
              label="序号"
            ></el-table-column>
            <el-table-column
              prop="order_no"
              label="订单编号"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="pay_time"
              label="支付时间"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="real_pay_money"
              label="实付金额（元）"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="car_no"
              label="车牌号码"
              min-width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="address"
              label="服务地址"
              min-width="250"
              align="center"
            ></el-table-column>
          </el-table>
          <el-footer height="32px" style="margin-top: 10px">
            <el-pagination
              background
              layout="total,sizes, prev, pager, next,jumper"
              :total="page_total"
              :page-size="page_size"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 50, 100]"
              :current-page.sync="page_index"
            ></el-pagination>
          </el-footer>
        </el-row>
      </el-form>
    </el-scrollbar>

    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="dialogVisible = false">确认</el-button>
    </div>
  </el-dialog>
</template>
  <script>
import * as XLSX from "xlsx";
import { operateAdd } from "@/api/system/user-api";

export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dataForm: {
        bill_id: "",
        bill_no: "",
        city_name: "",
        user_mobile: "",
        create_time: "",
        bill_type: "",
        company_name: "",
        company_tax_no: "",
        amount: "",
        state: "",
        bill_type_name: "",
        state_name: "",
        email_address: "",
      },
      dataTable: [],
      page_total: 0,
      page_index: 1,
      page_size: 10,
    };
  },
  mounted() {},
  methods: {
    init(row) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (row) {
          console.log(row);
          this.dataForm.bill_id = row.id;
          this.dataForm.bill_no = row.bill_no;
          this.dataForm.city_name = row.city_name;
          this.dataForm.user_mobile = row.user_mobile;
          this.dataForm.create_time = row.create_time;
          this.dataForm.bill_type = row.bill_type;
          this.dataForm.company_name = row.company_name;
          this.dataForm.company_tax_no = row.company_tax_no;
          this.dataForm.amount = row.amount;
          this.dataForm.state = row.state;
          this.dataForm.bill_type_name = this.typeFormat(row.bill_type);
          this.dataForm.state_name = this.stateFormat(row.state);
          this.dataForm.email_address = row.email_address;
          this.dataForm.real_pay_money = row.amount;
          this.loadDataTable();
        } else {
          this.dataForm = {};
        }
      });
    },
    stateFormat: function (state) {
      if (state == 0) {
        return "未开票";
      } else if (state == 1) {
        return "已开票";
      } else {
        return "";
      }
    },
    typeFormat: function (bill_type) {
      if (bill_type == 0) {
        return "企业单位";
      } else if (bill_type == 1) {
        return "个人";
      } else {
        return "";
      }
    },
    async loadDataTable() {
      let params = {
        billId: this.dataForm.bill_id,
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/order/select/by/billId/" + offset + "/" + limit, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.items;
          this.page_total = response.data.total;
          this.$nextTick(() => {
            this.$refs["dataTable"].doLayout();
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 28,
        operate_parameter: JSON.stringify({
          billId: this.dataForm.bill_id,
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        billId: this.dataForm.bill_id,
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/order/list/by/billId", {
          params: params,
        })
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.items);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "开票订单导出.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["订单编号"] = item.order_no;
        newObj["支付时间"] = item.pay_time;
        newObj["实付金额（元）"] = item.real_pay_money;
        newObj["车牌号码"] = item.car_no;
        newObj["服务地址"] = item.address;
        newArray.push(newObj);
      });
      return newArray;
    },
  },
};
</script>

  <style scoped>
.form-title {
  font-size: 16px;
  color: red;
  margin: 15px 20px 25px 20px;
}

.block-body {
  margin-bottom: 15px;
}

::v-deep .el-rate__icon {
  font-size: 24px;
}
</style>
