
<el-dialog :title="'新增储值客户-'+this.dataItem.week_name" :close-on-click-modal="false" :visible.sync="visible">
  <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
    <el-table-column prop="fullname" label="城市" min-width="150" align="center"></el-table-column>
    <el-table-column prop="cellphone" label="客户手机号" min-width="150" align="center"></el-table-column>
    <el-table-column prop="create_time" label="充值时间" min-width="150" align="center"></el-table-column>
  </el-table>
  <el-footer height="32px" style="margin-top:10px;">
    <el-pagination background layout="total,sizes, prev, pager, next,jumper" :total="page_total"
                   :page-size="page_size" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                   :page-sizes="[10, 20, 50, 100]" :current-page.sync="page_index"></el-pagination>
  </el-footer>
</el-dialog>
