
<el-dialog
  title="开票详情"
  v-dialogDrag
  v-if="dialogVisible"
  :visible.sync="dialogVisible"
  :close-on-click-modal="false"
  append-to-body
>
  <el-scrollbar
    style="height: 500px; margin-right: -20px"
    wrapStyle="padding-right:20px;overflow-x:hidden;"
    viewStyle
  >
    <el-form :model="dataForm" ref="dataForm" label-width="110px">
      <el-row class="block-header">
        <span class="block-title">基本信息</span>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="开票流水">
            <el-input v-model="dataForm.bill_no" readonly />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="城市">
            <el-input v-model="dataForm.city_name" readonly />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="申请手机号">
            <el-input v-model="dataForm.user_mobile" readonly />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="申请时间">
            <el-input v-model="dataForm.create_time" readonly />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="抬头类型">
            <el-input v-model="dataForm.bill_type_name" readonly />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="发票抬头">
            <el-input v-model="dataForm.company_name" readonly />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="公司税号">
            <el-input v-model="dataForm.company_tax_no" readonly />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="开票金额(元)">
            <el-input v-model="dataForm.real_pay_money" readonly />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="开票状态">
            <el-input v-model="dataForm.state_name" readonly />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="邮箱地址">
            <el-input v-model="dataForm.email_address" readonly />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row class="block-header">
        <span class="block-title">开票订单</span>
        <el-button style="float: right" @click="exportData">导出</el-button>
      </el-row>
      <el-row class="block-body">
        <el-table
          ref="dataTable"
          :data="dataTable"
          style="margin-top: 10px; margin-left: 2px"
        >
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="order_no"
            label="订单编号"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="pay_time"
            label="支付时间"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="real_pay_money"
            label="实付金额（元）"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="car_no"
            label="车牌号码"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="address"
            label="服务地址"
            min-width="250"
            align="center"
          ></el-table-column>
        </el-table>
        <el-footer height="32px" style="margin-top: 10px">
          <el-pagination
            background
            layout="total,sizes, prev, pager, next,jumper"
            :total="page_total"
            :page-size="page_size"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :current-page.sync="page_index"
          ></el-pagination>
        </el-footer>
      </el-row>
    </el-form>
  </el-scrollbar>

  <div slot="footer" class="dialog-footer" style="text-align: center">
    <el-button type="primary" @click="dialogVisible = false">确认</el-button>
  </div>
</el-dialog>
