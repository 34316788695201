
<el-dialog
  class="showAll_dialog"
  :title="getDialogTitle()"
  width="800px"
  :height="'60%'"
  :max-height="'450px'"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <el-form
    :model="dataForm"
    :rules="dataRule"
    ref="dataForm"
    label-width="165px"
    v-loading="formLoading"
  >
    <!-- 基础信息 -->
    <el-form-item label="城市：" prop="city_code">
      <el-select
        v-model="dataForm.city_code"
        placeholder="请选择城市"
        @change="cityChange"
        collapse-tags
        clearable
        style="width: 400px"
        :disabled="isDisabled"
      >
        <el-option
          v-for="item in cityList"
          :key="item.city_code"
          :label="item.city_name"
          :value="item.city_code"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="editState != 'add'" label="创建时间">
      <el-date-picker
        v-model="dataForm.create_time"
        type="datetime"
        :disabled="true"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="展示时间" prop="start_time" :disabled="true">
      <el-date-picker
        v-model="showTime"
        type="datetimerange"
        style="width: 400px"
        value-format="yyyy-MM-dd HH:mm:ss"
        :clearable="true"
        :default-time="['00:00:00', '23:59:59']"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        @change="timeChange"
        :disabled="isDisabled"
      ></el-date-picker>
    </el-form-item>
    <div v-for="(item, index) in dataForm.page_config.item_list" :key="index">
      <el-form-item
        :label="`首页活动图${index + 1}`"
        :prop="`page_config.item_list.${index}.pic_url`"
        :rules="[
          {
            required: true,
            message: '请上传首页活动图',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <div
          v-if="editState != 'details'"
          class="flex"
          style="justify-content: space-between"
        >
          <el-upload
            v-if="!item.fileList1.length"
            v-model="item.pic_url"
            :action="uploadUrl"
            :on-remove="createOnRemove(index, 1)"
            :before-remove="beforeRemove"
            :multiple="false"
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="item.fileList1"
            :http-request="createUploadRequest(index, 1)"
            :before-upload="beforeUp"
            list-type="picture-card"
            :disabled="editState != 'details' ? false : true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="image-preview" v-else>
            <div class="image-preview-wrapper">
              <img :src="file_view_rul + item.pic_url" />
              <div class="image-preview-action">
                <i
                  @click="handleRemove('file', index, 1)"
                  class="el-icon-delete"
                ></i>
              </div>
            </div>
          </div>
          <!-- 加减按钮 -->
          <div
            class="flex"
            style="margin-left: 0.625rem; margin-bottom: 1.125rem"
          >
            <img
              class="icon"
              @click="formArrHandleChange(0, index)"
              src="@/assets/img/icon-add.png"
              alt
            />
            <img
              v-if="dataForm.page_config.item_list.length > 1"
              @click="formArrHandleChange(1, index)"
              class="icon"
              src="@/assets/img/icon-delete.png"
              alt
            />
          </div>
        </div>
        <div v-else class="image-container">
          <el-image
            style="
              width: 150px;
              height: 150px;
              border-radius: 6px;
              margin-right: 10px;
            "
            :src="file_view_rul + item.pic_url"
            :preview-src-list="[file_view_rul + item.pic_url]"
            :disabled="true"
          >
            <img
              slot="error"
              :src="file_view_rul + item.pic_url"
              style="
                width: 150px;
                height: 150px;
                border-radius: 6px;
                margin-right: 10px;
              "
            />
          </el-image>
          <div
            class="image-container-btn"
            @click="
              downloadByBlob(
                `${file_view_rul + item.pic_url}`,
                `${'首页活动图' + index + 1}`
              )
            "
          >
            下载
          </div>
        </div>
        <span style="display: block"
          >仅支持上传jpg,jpeg,png格式图片，推荐分辨率为560*720</span
        >
      </el-form-item>
      <el-form-item
        label="是否跳转详情页"
        :prop="`page_config.item_list.${index}.to_detail`"
        :rules="[
          {
            required: true,
            message: '请选择是否跳转详情页',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <el-select
          v-model="item.to_detail"
          placeholder="请选择是否跳转详情页"
          @change="detailsChange($event, index)"
          collapse-tags
          clearable
          :disabled="editState != 'details' ? false : true"
        >
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="item.to_detail"
        :label="`活动详情${index + 1}`"
        :prop="`page_config.item_list.${index}.detail_url`"
        :rules="[
          {
            required: true,
            message: '请上传活动详情图',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <div v-if="editState != 'details'">
          <el-upload
            v-if="!item.fileList2.length"
            v-model="item.detail_url"
            :action="uploadUrl"
            :on-remove="createOnRemove(index, 2)"
            :before-remove="beforeRemove"
            :multiple="false"
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="item.fileList2"
            :http-request="createUploadRequest(index, 2)"
            :before-upload="beforeUp"
            list-type="picture-card"
            :disabled="editState != 'details' ? false : true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div v-else class="image-preview">
            <div class="image-preview-wrapper">
              <img :src="file_view_rul + item.detail_url" />
              <div class="image-preview-action">
                <i
                  @click="handleRemove('file', index, 2)"
                  class="el-icon-delete"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="image-container">
          <el-image
            style="
              width: 150px;
              height: 150px;
              border-radius: 6px;
              margin-right: 10px;
            "
            :src="file_view_rul + item.detail_url"
            :preview-src-list="[file_view_rul + item.detail_url]"
            :disabled="true"
            @error="handleError"
          />
          <div
            class="image-container-btn"
            @click="
              downloadByBlob(
                `${file_view_rul + item.detail_url}`,
                `${'活动详情' + index + 1}`
              )
            "
          >
            下载
          </div>
        </div>
      </el-form-item>
      <el-form-item
        v-if="item.to_detail"
        label="详情页是否有跳转按钮"
        :prop="`page_config.item_list.${index}.has_btn`"
        :rules="[
          {
            required: true,
            message: '请选择详情页是否有跳转按钮',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <el-select
          v-model="item.has_btn"
          placeholder="请选择详情页是否有跳转按钮"
          @change="hasBtnChange($event, index)"
          collapse-tags
          clearable
          :disabled="editState != 'details' ? false : true"
        >
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="item.has_btn"
        label="跳转位置"
        :prop="`page_config.item_list.${index}.path`"
        :rules="[
          {
            required: true,
            message: '请选择详情页是否有跳转按钮',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <el-select
          v-model="item.path"
          placeholder="请选择跳转位置"
          collapse-tags
          clearable
          :disabled="editState != 'details' ? false : true"
        >
          <el-option label="余额充值页" :value="1"></el-option>
          <el-option label="度数充值页" :value="2"></el-option>
          <el-option label="度数页" :value="3"></el-option>
        </el-select>
      </el-form-item>
    </div>
  </el-form>
  <span slot="footer" class="dialog-foote r">
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    <el-button v-if="editState != 'details'" @click="visible = false"
      >取消</el-button
    >
  </span>
</el-dialog>
