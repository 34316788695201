<!--
 * @Author: ljf
 * @Date: 2022-05-27 09:44:42
 * @LastEditors: ljf
 * @LastEditTime: 2022-05-27 09:48:29
 * @FilePath: \lxm-admin\src\views\dashboard\index.vue
 * @Description: 
 * 
-->
<template>
  <div>

  </div>
</template>

<script>
  export default {
    name:'dashboard'
  }
</script>

<style lang="scss" scoped>

</style>