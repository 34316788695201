<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 10%">
      <el-form :inline="true">
        <el-form-item label="城市">
          <el-select
            v-model="form.cityCode"
            placeholder="全部"
            collapse-tags
            clearable
            filterable
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代理商">
          <el-input
            v-model="form.agentName"
            placeholder="请输入代理商"
            clearable
            maxlength="35"
          />
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-table ref="tableData" :height="tableHeight" :data="dataTable">
        <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="agent_name"
          label="代理商"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="修改时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column label="上门充电" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              @click="updateHandle(scope.row, '1')"
              type="text"
              size="small"
              >设置
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="应急补电" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              @click="updateHandle(scope.row, '2')"
              type="text"
              size="small"
              >设置
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <activityPageAddModal
      ref="addOrUpdate"
      :editState="editState"
      @refreshDataList="search"
    >
    </activityPageAddModal>
    <activityPageView
      v-if="activityPageViewVisible"
      ref="activityPageView"
    ></activityPageView>
    <delivertySettingModal
      ref="delivertySettingModal"
      @mounted="onChildMounted"
      @getList="getList"
    ></delivertySettingModal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import activityPageAddModal from "./model/activity-page-add-modal.vue";
import activityPageView from "./model/activity-page-view.vue";
import delivertySettingModal from "./model/deliverty-setting-modal.vue";

export default {
  components: {
    activityPageAddModal,
    activityPageView,
    delivertySettingModal,
  },
  data() {
    const that = this;
    return {
      createTime: [],
      multiQuery: {},
      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      addOrUpdateVisible: false,
      pickerShow: false,
      activityPageViewVisible: false,
      form: {
        status: "",
        cityCode: "",
      },
      statusList: [
        {
          label: "进行中",
          value: 1,
        },
        {
          label: "停用",
          value: 2,
        },
        {
          label: "已结束",
          value: 3,
        },
        {
          label: "待开始",
          value: 4,
        },
      ],
      rules: {
        end_time: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: ["blur", "change"],
          },
        ],
      },
      file_view_rul: this.FILE_VIEW_ROOT_RUL,
    };
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    await this.search();
  },
  mounted() {},
  methods: {
    filter() {
      this.filterVisible = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {
        name: "",
        orderType: "",
        status: "",
        cityCode: "",
      };
      this.createTime = [];
      this.page_index = 1;
      this.loadDataTable();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    //显示图片
    showActivityPageView(row, index) {
      this.activityPageViewVisible = true;
      this.$nextTick(() => {
        this.$refs.activityPageView.init(row, index, this.file_view_rul);
      });
    },
    getPicUrl(url) {
      return this.file_view_rul + url;
    },
    getList(e) {
      this.search();
    },
    // 设置
    updateHandle(row, type) {
      // this.showLoading('加载中');

      this.$refs.delivertySettingModal.init(row, type);
      // this.$refs.delivertySettingModal.visible = true
      // console.log(row, 'asdasd')
      // this.axiosHelper
      //     .get(`/api/config/area/get`, {
      //         params: {},
      //     })
      //     .then((response) => {
      //         this.hideLoading();
      //         this.$refs.delivertySettingModal.visible = true
      //         this.$refs.delivertySettingModal.init(row);
      //         //   this.$nextTick(() => {
      //         //     this.$refs["dataTable"].doLayout();
      //         //   });
      //     })
      //     .catch(() => {
      //         this.hideLoading();
      //         this.$message.error("加载失败");
      //     });
      // this.editState = editState;
      // this.$refs['addOrUpdate'].visible = true
      // this.$refs['addOrUpdate'].formLoading = true
      // switch (editState) {
      //     case 'add':
      //     case 'edit':
      //     case 'details':
      //         this.$nextTick(() => {
      //             this.$refs.addOrUpdate.init(row);
      //             this.$refs['addOrUpdate'].formLoading = false
      //         });
      //         break;
      // }
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        ...this.form,
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/api/config/area/query/${offset}/${limit}`, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.data.items;
          this.page_total = response.data.data.total;
          //   this.$nextTick(() => {
          //     this.$refs["dataTable"].doLayout();
          //   });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },

    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    // 删除
    async deleteHandle(row) {
      const confirmResult = await this.$confirm(`确定删除该活动页么?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .delete("/api/activity/page/del/" + row.id, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    async statusSwitch(row, status) {
      let str =
        row.status === 2
          ? "确认启用该活动页么？"
          : "目前该活动页正在展示中，确认停用么？";
      const confirmResult = await this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .put(`/api/activity/page/set/status/${row.id}/${status}`, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    onChildMounted() {
      // 设置地图的默认事件属性为非被动的
      AMap.event.preventDefault = "SIMULATE";
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}

.flex {
  display: flex;
  align-items: center;
}

.dialog-foote {
  display: flex;
  justify-content: center;
}
</style>