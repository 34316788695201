<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <div class="box-card-main">
        <p class="title">数据筛选</p>
        <div class="text item">
          <el-form
            ref="searchValue"
            style="padding-right: 10px"
            :model="searchValue"
          >
            <div class="flex">
              <el-form-item
                label="城市"
                label-width="60px"
                style="margin-bottom: 0"
              >
                <el-select
                  v-model="searchValue.cityCode"
                  placeholder="请选择城市"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in restrictedCityList"
                    :key="item.cityCode"
                    :label="item.cityName"
                    :value="item.cityCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="手机号"
                label-width="80px"
                style="margin-bottom: 0"
              >
                <el-input
                  v-model.trim="searchValue.cellphone"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  clearable
                />
              </el-form-item>
              <el-form-item
                label="用户编号"
                label-width="80px"
                style="margin-bottom: 0"
              >
                <el-input
                  v-model.trim="searchValue.userNumber"
                  maxlength="10"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  clearable
                />
              </el-form-item>
              <el-button
                type="primary"
                @click="search"
                style="margin-left: 10px"
                >查询</el-button
              >
              <el-button type="default" @click="clear">重置</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>车辆检测钱包</span>
        <el-button style="float: right" @click="exportData">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
      >
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="手机号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="available_car_assess_report_num"
          label="剩余检测次数"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" min-width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="detail(scope.row)">明细</el-button>
            <el-button
              type="text"
              v-if="user.isMaintenanceOrder == 1"
              @click="deduction(scope.row)"
              >扣减次数</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <el-dialog
      title="扣减次数"
      :visible.sync="deductionDialogVisible"
      width="500px"
    >
      <el-form :rules="dataRule" ref="dataForm" :model="dataForm">
        <el-form-item label="用户编号：">
          <span>{{ selectObj.user_number }}</span>
        </el-form-item>
        <el-form-item label="用户手机号：">
          <span>{{ selectObj.cellphone }}</span>
        </el-form-item>
        <el-form-item label="剩余检测次数：">
          <span>{{ selectObj.available_car_assess_report_num }}次</span>
        </el-form-item>
        <el-form-item label="扣减次数：" prop="reduce_number">
          <el-input
            style="width: 200px"
            v-model="dataForm.reduce_number"
            @input="deductionNum"
            placeholder="请输入扣减次数"
          >
            <span slot="suffix">次</span>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deductionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deductionDialogSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <detectionWalletDetailModal
      v-if="isDetectionWalletDetailModal"
      :selectObj="selectObj"
      @close="isDetectionWalletDetailModal = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import detectionWalletDetailModal from "./model/detection-wallet-detail-modal.vue";
import { validateNum } from "@/utils/utils";
import { operateAdd } from "@/api/system/user-api";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      searchValue: {
        cityCode: "",
        cellphone: "",
        userNumber: "",
      },
      vloading: false,
      tableHeight: "70vh",
      dataTable: [],
      pageSizeList: [10, 20, 50, 100, 500],
      total: 0,
      currentPage: 1,
      pageSize: 50,
      deductionDialogVisible: false,
      isDetectionWalletDetailModal: false,
      selectObj: {},
      dataForm: {
        reduce_number: "",
      },
      dataRule: {
        reduce_number: [
          {
            required: true,
            message: "请输入扣减次数",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (value <= 0) {
                callback(new Error("请输入大于0的数字"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["restrictedCityList", "user"]),
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.search();
  },
  methods: {
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 37,
        operate_parameter: JSON.stringify(this.searchValue),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/manage/query/carAssess/list", {
          params: {
            ...this.searchValue,
          },
        })
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.data);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "车辆检测钱包.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["用户编号"] = item.user_number;
        newObj["手机号"] = item.cellphone;
        newObj["城市"] = item.city_name;
        newObj["剩余检测次数"] = item.available_car_assess_report_num;
        newArray.push(newObj);
      });
      return newArray;
    },
    async deductionDialogSubmit() {
      this.$refs["dataForm"].validate(async (valid, fields) => {
        if (!valid) return;
        await this.$confirm(
          `确定要扣减${this.dataForm.reduce_number}次吗?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
        this.showLoading("加载中");
        const res = await this.axiosHelper
          .post(`/api/user/manage/reduce/carAssess/number`, {
            id: this.selectObj.id,
            reduce_number: this.dataForm.reduce_number,
          })
          .finally(() => {
            this.hideLoading();
          });
        if (res.data.code == 0) {
          this.$message({
            message: "扣减成功",
            type: "success",
          });
          this.deductionDialogVisible = false;
          this.loadData();
        }
      });
    },
    deductionNum() {
      this.dataForm.reduce_number = validateNum(
        this.dataForm.reduce_number,
        0,
        0,
        false,
        this.selectObj.available_car_assess_report_num
      );
    },
    detail(item) {
      this.selectObj = item;
      this.isDetectionWalletDetailModal = true;
    },
    deduction(item) {
      this.selectObj = item;
      this.reduce_number = "";
      this.deductionDialogVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
    },
    clear() {
      this.searchValue = {
        city_code: "",
        cellphone: "",
        userNumber: "",
      };
      this.search();
    },
    search() {
      this.currentPage = 1;
      this.loadData();
    },
    async loadData() {
      this.vloading = true;
      const res = await this.axiosHelper
        .get(
          `/api/user/manage/query/carAssess/${
            (this.currentPage - 1) * this.pageSize
          }/${this.pageSize}`,
          {
            params: {
              ...this.searchValue,
            },
          }
        )
        .finally(() => {
          this.vloading = false;
        });
      if (res.data.code == 0) {
        this.dataTable = res.data.data.items;
        this.total = res.data.data.total;
      }
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      this.loadData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.loadData();
    },
  },
  components: {
    detectionWalletDetailModal,
  },
};
</script>

<style lang="scss" scoped>
.box-card-main {
  display: flex;
  align-items: center;
  .flex {
    display: flex;
  }
}
</style>