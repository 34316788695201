<template>
  <div
    class="role-body"
    v-loading="loading"
    element-loading-text="正在加载请稍后..."
  >
    <div class="role-header">
      <div class="role-header-left">
        <el-button
          class="rewrite-button-style"
          size="small"
          @click="addRole"
        >新增</el-button>
        <el-button
          class="rewrite-button-style"
          size="small"
          @click="deleteRole(multiple_selection)"
        >删除</el-button>
      </div>
      <div class="role-header-right">
        <el-input
          style="width: 100%"
          v-model="search_value.keyword"
          placeholder="请输入编号或名称进行搜索"
          clearable
        >
          <el-button
            slot="append"
            @click="search"
          >搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="role-center">
       
        <el-table
          :data="tableData"
          max-height="80vh"
          height="70vh"
          border
          header-align="center"
          element-loading-customClass="personal-loading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(204, 204, 204, 0.6)"
          class="refTable"
          ref="refTable"
          default-expand-all
          :header-row-style="header_row_style"
          :header-cell-style="header_cell_style"
          :cell-style="cell_style"
          :style="table_style"
          @selection-change="handleSelectionChange"
          @select-all="handleSelectionChange"
          row-key="id"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            :selectable="handleSelectable"
            :reserve-selection="true"
            fixed="left"
          >
          </el-table-column>
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column
            width="200"
            label="编号"
            prop="code"
          ></el-table-column>
          <el-table-column
            width="200"
            label="名称"
            prop="role_name"
          ></el-table-column>
          <el-table-column
            width="200"
            label="类型"
            prop="type"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.type ==3">
                系统角色
              </div>
              <div v-if="scope.row.type ==4">
                组织角色
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="200"
            label="描述"
            prop="description"
          ></el-table-column>
          <el-table-column
            width="100"
            label="状态"
            prop="status"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.status ==1">
                启用
              </div>
              <div v-else>
                禁用
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            label="是否可以删除"
            prop="allow_deleted"
          >
          <template slot-scope="scope">
              <div v-if="scope.row.allow_deleted ==true">
                是
              </div>
              <div v-else>
                否
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="备注"
            prop="remark"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="320"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="editRole(scope.row)"
              >编辑</el-button>
              <el-button
                type="primary"
                size="mini"
                @click="setRoleUsers(scope.row)"
              >设置角色用户</el-button>
              <el-button
                type="primary"
                size="mini"
                @click="checkRoleUsers(scope.row)"
              >查看角色用户
              </el-button>
            </template>
          </el-table-column>
        </el-table>
       
    </div>
    <div class="role-footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current_page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="page_size"
        layout="total, sizes, prev, pager, next"
        :total="total_count"
      >
      </el-pagination>
    </div>
    <ex-dialog
      :title="title"
      :visible.sync="dialog_visible"
      v-if="dialog_visible"
      :width="900"
      :height="450"
      :show-fullscreen-icon="true"
      :append-to-body="true"
    >
      <el-scrollbar
        style="height: 100%; margin-right: -20px"
        wrapStyle="overflow-x:hidden;padding-right:20px;"
        viewStyle=""
      >
        <role-edit
          :action="action"
          :role_="select_role"
          @close="closeDialog"
          @refresh="refresh"
        ></role-edit>
      </el-scrollbar>
    </ex-dialog>
    <ex-dialog
      title="设置角色用户"
      :visible.sync="role_user_dialog_visible"
      v-if="role_user_dialog_visible"
      :width="1100"
      :height="670"
      :show-fullscreen-icon="true"
      :append-to-body="true"
    >
      <role-user
        :role_id="select_role_id"
        @close="closeRoleUserDialog"
        @submit="submit"
      ></role-user>
    </ex-dialog>
    <ex-dialog
      title="查看角色用户"
      :visible.sync="role_users_dialog_visible"
      v-if="role_users_dialog_visible"
      :width="1100"
      :height="670"
      :show-fullscreen-icon="true"
      :append-to-body="true"
    >
      <role-table
        :role_id="select_role_id"
        @close="closeRoleUsersDialog"
      ></role-table>
    </ex-dialog>

  </div>
</template>

<script>
  import { getRolePage, deleteRole, roleUserAssign } from "api/system/role-api";
  import Cookies from "js-cookie";
  export default {
    name: "Role",
    data () {
      return {
        table_style: {
          "margin-top": "10px",
          width: this.tableType == "table" ? "100%" : "99%",
        },
        header_cell_style: {
          background: "#EDF1F4",
          textAlign: "center",
          padding: "0",
        },
        cell_style: {
          fontSize: "14px",
          color: "#000",
          padding: "0",
          height: "40px",
        },
        header_row_style: {
          "line-height": "36px",
        },
        current_page: 1,
        loading: false,
        search_value: {
          $limit: 50,
          $offset: 0,
          field: "",
          type: "",
          keyword: "",
        },
        page_size: 50,
        total_count: 0,
        tableData: [],
        multiple_selection: [],
        total_table_data: [],
        select_role: null,
        dialog_visible: false,
        role_user_dialog_visible: false,
        role_users_dialog_visible: false,
        select_role_id: ''
      };
    },
    watch: {},
    computed: {
      title () {
        return this.action === "add" ? "新增角色信息" : "编辑角色信息";
      },
      collapse () {
        return this.$store.state.collapse;
      },
    },
    methods: {
      async search () {
        this.loading = true;
        try {
          var data = await getRolePage(this.search_value);
          this.tableData = data.items;
          this.total_count = data.total_count;
        } catch (error) {
          this.loading = false;
        }
        this.loading = false;
      },
      addRole () {
        this.select_role = {
          allow_deleted: true,
          app_id: Cookies.get("appId") ?? 2,
          code: "",
          description: "",
          id: "",
          remark: "",
          required: false,
          role_name: "",
          status: 1,
          type: 3,
        };
        this.action = "add";
        this.dialog_visible = true;
      },
      editRole (row) {
        this.select_role = { ...row };
        this.action = "edit";
        this.dialog_visible = true;
      },
      deleteRole (list) {
        if (list.length === 0) {
          this.$message.warning("请选择记录");
          return;
        }
        this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await deleteRole(list.map((item) => item.id));
            this.$message.success("删除成功!");
            this.search();
          })
          .catch((e) => {
            this.$message.info("已取消删除");
          });
      },
      checkRoleUsers (row) {
        this.select_role_id = row.id
        this.role_users_dialog_visible = true;
      },
      handleSelectable (row) {
        return !this.total_table_data.some((t) => t.id === row.id);
      },
      handleSizeChange (size) {
        this.page_size = size;
        this.search_value.$limit = this.page_size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange (current) {
        this.current_page = current;
        this.search_value.$offset = (this.current_page - 1) * this.page_size;
        this.search();
      },
      handleSelectionChange (val) {
        this.multiple_selection = [
          ...new Set([...this.multiple_selection, ...val]),
        ];
        this.multiple_selection = this.multiple_selection.reduce((list, item) => {
          if (list.every((t) => t.id !== item.id)) {
            list.push(item);
          }
          return list;
        }, []);
        //如果等于空 需要把本页所有的项目移除
        var selectedIds = val.map((t) => t.id);
        var ids = this.tableData
          .map((t) => t.id)
          .filter((t) => {
            return !selectedIds.includes(t);
          });
        //当前表格没有选中的
        this.multiple_selection = this.multiple_selection.reduce((list, item) => {
          if (!ids.includes(item.id)) {
            list.push(item);
          }
          return list;
        }, []);
      },
      setRoleUsers (row) {
        if (row.type == 4) {
          this.$message.warning("不可设置组织角色用户！")
          return
        }
        this.select_role_id = row.id
        this.role_user_dialog_visible = true;
      },
      closeDialog () {
        this.dialog_visible = false;
      },
      closeRoleUserDialog () {
        this.role_user_dialog_visible = false;
      },
      closeRoleUsersDialog () {
        this.role_users_dialog_visible = false;
      },
      refresh () {
        this.search();
      },
      async submit (data, role_id) {
        var obj = {
          roleId: role_id,
          userIds: data.map(t => t.id).join(',')
        }
        data = await roleUserAssign(obj)
        if (data > -1) {
          this.$message.success("设置角色用户成功")
          this.role_user_dialog_visible = false;
        }
        else {
          this.$message.error("设置角色用户失败")
        }
      }
    },
    mounted () {
      this.search(this.search_value);
    },
  };
</script>

<style lang="scss">
.role-body {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 10px);
  width: 100%;
  overflow: hidden;

  .role-header {
    flex: 0 0 5% !important;
    display: flex;
    width: 100%;
    .role-header-left {
      flex: 1;
    }
    .role-header-right {
      width: 400px;
    }
  }

  .role-footer {
    flex: 0 0 5% !important;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .role-center {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;
  }

  .is-horizontal {
    display: none;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
