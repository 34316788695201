
<el-dialog title="关闭订单" v-dialogDrag :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
    <el-form :model="dataForm" ref="dataForm" label-width="110px">
        <el-row>
            <el-col :span="24">
                <el-form-item label="备注信息：">
                    <el-input v-model.trim="dataForm.remark" type="textarea" placeholder="输入备注信息" :rows="5"
                        maxlength="300" show-word-limit clearable resize="none" />
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
    </div>
</el-dialog>
