<template>
  <div class="header">
    <!-- 折叠按钮 -->
    <div
      class="collapse-btn"
      @click="collapseChange"
    >
      <i class="el-icon-menu"></i>
    </div>
    <div class="logo">绿小马运营平台</div>
    <span style="font-size: 16px;line-height:50px;color:#FFDC9A;font-weight:bold">本数据平台所涉及的信息均为高度敏感且受法律严格保护的重要资产，严守保密，严禁外传。</span>
    <div class="header-right">
      
      <div
        class="header-date"
        v-html="current_time"
      ></div>
      <div class="header-user-con">
        <!-- 全屏显示 -->
        <div
          class="btn-fullscreen"
          @click="handleFullScreen"
        >
          <el-tooltip
            effect="dark"
            :content="fullscreen?`取消全屏`:`全屏`"
            placement="bottom"
          >
            <i class="el-icon-rank"></i>
          </el-tooltip>
        </div>
        <!-- 消息中心 -->
        <!-- <div class="btn-bell">
          <el-tooltip
            effect="dark"
            :content="message?`有${message}条未读消息`:`消息中心`"
            placement="bottom"
          >
         
            <i class="el-icon-bell"></i>
           
          </el-tooltip>
          <span
            class="btn-bell-badge"
            v-if="message"
          ></span>
        </div> -->
        <!-- 用户头像 -->
        <!--todo 用户头像需保存-->
        <el-popover
          placement="bottom"
          trigger="click"
        >
          <div>
            <div class="my-info-large">{{ myInfo.realName }}</div>
            <div class="my-info-gray">{{ myInfo.orgName }}</div>
            <el-divider class="my-divider"></el-divider>
            <div class="my-info-small">手机：{{ myInfo.cellphone }}</div>
            <div class="my-info-small-bottom">电子邮箱：{{ myInfo.email }}</div>
          </div>
          <div
            slot="reference"
            class="user-avator"
          ><img
              :src="imgSrc"
              class="user-avatar-img"
            ></img></div>
        </el-popover>
        <!-- 用户名下拉菜单 -->
        <el-dropdown
          class="user-name"
          trigger="click"
          @command="handleCommand"
        >
          <span class="el-dropdown-link">
            {{username}}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item   
              command="changePassword"
            >修改密码</el-dropdown-item>
            <el-dropdown-item
              divided
              command="loginout"
            >退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <changePassword ref="changePassword"></changePassword>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import changePassword from "./changePasswod.vue";
import moment from "utils/moment";
import { getUserPhoto, loginOut } from "api/system/user-api";
export default {
  components: {
    changePassword,
  },
  data() {
    return {
      collapse: false,
      fullscreen: false,
      name: "",
      message: 0,
      current_time: "",
      imgSrc: "",
    };
  },
  computed: {
    username() {
      let username = this.$store?.state?.user?.realName;
      return username || this.name;
    },
    myInfo() {
      return (
        this.$store.state.user || {
          realName: "",
          orgName: "",
          cellphone: "",
          email: "",
        }
      );
    },
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command === "loginout") {
        this.$confirm("是否注销当前用户?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          let user = Cookies.get("userInfo");
          this.$store.commit("setTagsByList", []);
          if (user === null || user === "" || user === undefined) {
            Cookies.remove("userToken");
            localStorage.clear();
            this.$store.state.user = null;
            this.$store.state.addRoutes = [];
            this.$store.state.tagsList = [];
            this.$store.state.firstPath = "";
            this.$router.push("/");
            return;
          }
          let userInfo = JSON.parse(user);

          var data = await loginOut(userInfo.uid);
          if (data) {
            Cookies.remove("userToken");
            Cookies.remove("userInfo");
            localStorage.clear();
            this.$store.state.user = null;
            this.$store.state.addRoutes = [];
            this.$store.state.tagsList = [];
            this.$store.state.firstPath = "";
            this.$router.push("/");

            // this.socket.close()
          } else {
            let token = Cookies.get("userToken");
            if (!token) {
              this.$router.push("/");
            }
            this.$message.error("注销失败，请重试");
          }
        });
      }
      if (command === "changePassword") {
        this.$refs["changePassword"].init();
      }
    },
    // 侧边栏折叠
    collapseChange() {
      this.collapse = !this.collapse;
      this.$store.commit("setCollapse", this.collapse);
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    getCurrentTime() {
      this.current_time = moment(new Date()).format("YYYY年MM月DD dddd");
    },
    async init() {
      this.getCurrentTime();
      if (document.body.clientWidth < 1500) {
        this.collapseChange();
      }
      this.imgSrc = await getUserPhoto(
        this.$store.state.user.uid,
        this.$store.state.user?.realName?.substring(0, 1) ?? "无"
      );
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-size: 22px;
  color: #fff;
  background-color: #5a8bff !important;
}

.header-date {
  float: left;
  line-height: 50px;
  margin-right: 32px;
  padding-right: 32px;
  /* padding-top: 8px; */
  font-size: 18px;
  /* border-right: 1px solid #46494e; */
}

.collapse-btn {
  float: left;
  padding: 0 24px;
  cursor: pointer;
  line-height: 50px;
}
.collapse-btn:hover {
  background: #5a8bff;
}
.header .logo {
  float: left;
  width: 170px;
  line-height: 50px;
}

.header-right {
  float: right;
  padding-right: 16px;
}

.header-user-con {
  display: flex;
  height: 50px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}

.btn-bell .el-icon-bell {
  color: #fff;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-left: 20px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}

.el-dropdown-menu__item {
  text-align: center;
}
.my-divider {
  margin: 6px 0px;
}

.my-info-large {
  font-size: 18px;
  color: #333333;
}

.my-info-gray {
  font-size: 12px;
  color: #8590a6;
}

.my-info-small {
  font-size: 12px;
  color: #333333;
  margin: 6px 0px;
}

.my-info-small-bottom {
  font-size: 12px;
  color: #333333;
  margin: 6px 0px 0px;
}
</style>