<template>
    <el-dialog class="showAll_dialog" :title="'编辑'" width="800px" :height="'60%'" :max-height="'450px'"
        :close-on-click-modal="false" :visible.sync="visible" @close="reset">
        <el-form :model="form" ref="form" label-width="110px" v-loading="formLoading">
            <div class="flex">
                <el-form-item label="姓名">
                    <el-input v-model.trim="form.partner_name" readonly placeholder="请输入姓名：" clearable maxlength="35" />
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model.trim="form.partner_tel" readonly placeholder="请输入手机号" clearable maxlength="35" />
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="意向城市">
                    <el-input v-model.trim="form.cityName" readonly placeholder="请输入意向城市：" clearable maxlength="35" />
                </el-form-item>
                <el-form-item label="投资预算">
                    <el-input v-model.trim="form.investment_budget_name" readonly placeholder="请输入投资预算" clearable
                        maxlength="35" />
                </el-form-item>
            </div>
            <el-form-item label="状态">
                <el-select v-model="form.state" placeholder="请选择状态">
                    <el-option label="未联系" value="0"></el-option>
                    <el-option label="已联系" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="details">
                <el-input type="textarea" :rows="5" v-model="form.remarks" placeholder="备注"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="formSubmit()">确定</el-button>
            <el-button v-if="editState != 'details'" @click="visible = false">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import axios from "../../../utils/axiosHelper";
import axiosConfig from 'axios';

export default {
    props: {
        editState: {
            type: String,
            default: "add"
        }
    },
    data() {
        return {
            form: {
                id: '',
                state: '',
                remarks: '',

            },
            visible: false,
            formLoading: false,
        };
    },
    created() { },
    async mounted() {

    },
    methods: {
        init(row) {
            this.fileList = [];
            this.showTime = [];
            this.$nextTick(() => {
                // this.$refs["form"].resetFields();
                if (row) {
                    this.$nextTick(() => {
                        console.log(row, 'row')
                        this.formItem = row;
                        this.form.id = row.id
                        this.form.partner_name = row.partner_name
                        this.form.partner_tel = row.partner_tel
                        this.form.cityName = `${row.province_name} ${row.city_name} ${row.area_name}`
                        this.form.investment_budget_name = row.investment_budget_name
                        this.form.state = row.state
                        this.form.remarks = row.remarks
                    });
                } else {
                    this.form.id = ''
                    this.form.partner_name = ''
                    this.form.partner_tel = ''
                    this.form.cityName = ''
                    this.form.investment_budget_name = ''
                    this.form.state = ''
                    this.form.remarks = ''
                }
            });
        },
        // 表单提交
        formSubmit() {
            this.$refs["form"].validate(async valid => {
                if (!valid) return;
                this.showLoading("加载中");
                // 编辑
                axios
                    .post(
                        `/api/courtship/partner/update`,
                        this.form
                    )
                    .then(res => {
                        this.hideLoading();
                        if (res.data.code != 0) {
                            this.$message.error(res.data.msg);
                            return;
                        }
                        this.visible = false;
                        this.$emit("refreshDataList");
                        this.$message({
                            message: "操作成功",
                            type: "success",
                            duration: 1500
                        });
                    })
                    .catch(() => {
                        this.hideLoading();
                        this.$message.error("加载失败");
                    });
            });
        },
        reset() {
            this.form.id = ''
            this.form.partner_name = ''
            this.form.partner_tel = ''
            this.form.cityName = ''
            this.form.investment_budget_name = ''
            this.form.state = ''
            this.form.remarks = ''
        }
    }
};
</script>
<style lang="scss" scoped>
.showAll_dialog {
    overflow: hidden;

    ::v-deep .el-dialog {
        height: 70%;
        overflow: hidden;

        .el-dialog__body {
            position: absolute;
            left: 0;
            top: 80px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
            line-height: 30px;
            padding: 0 15px 60px;
        }

        .el-dialog__footer {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            padding: 0 20px 0;
        }
    }
}

.flex {
    display: flex;
    align-items: center;
}
</style>