
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="渠道商：">
        <el-input
          v-model.trim="form.channel_merchant_name"
          placeholder="请输入渠道商"
          clearable
        />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="createTime"
          type="datetimerange"
          style="width: 335px"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          :clearable="true"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="timeChange"
        ></el-date-picker>
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>渠道商管理</span>
      <el-button
        style="margin-left: 15px"
        v-if="isMaintenanceOrder == 1"
        @click="handelBtn('add')"
        >新增</el-button
      >
      <el-button style="float: right" @click="exportOrder">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      :height="tableHeight"
      :data="dataTable"
      @selection-change="rowSelected"
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="channel_merchant_name"
        label="渠道商"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="channel_code"
        label="渠道码"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="contacts"
        label="联系人"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="phone_number"
        label="手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="total_user_count"
        label="引流客户总数量"
        min-width="160"
        align="center"
      >
        <template slot-scope="scope">
          <el-button @click="seeUser('total', scope.row)" type="text">{{
            scope.row.total_user_count
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="today_user_count"
        label="今日注册用户数"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-button @click="seeUser('today', scope.row)" type="text">{{
            scope.row.today_user_count
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="qrcode_url"
        label="二维码"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.qrcode_url"
            style="width: 50px; height: 50px"
            :src="getQrcodeUrl(scope.row.qrcode_url)"
            @click="showWxappQrcodeView(scope.row)"
          >
          </el-image>
          <el-button
            v-if="!scope.row.qrcode_url"
            @click="generateWxAppQrcode(scope.row.id)"
            type="text"
            size="small"
            >生成</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handelBtn('edit', scope.row)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            @click="handelBtn('del', scope.row)"
            type="text"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <channelBusinessManageAddModal
    ref="channelBusinessManageAddModal"
    :editState="editState"
    @refreshDataList="search"
  ></channelBusinessManageAddModal>
  <channelUserModal
    v-if="isChannelUserModal"
    :type="channelUserType"
    :dataItem="dataItem"
    @close="isChannelUserModal = false"
  />
  <wxappQrcode v-if="wxappQrcodeViewVisible" ref="wxappQrcode" />
</div>
