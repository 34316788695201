<template>
  <div>
    <div class="block-view-content-2">
      <span class="span-title">{{ lineChartName }}</span>
      <div style="float: right">
        <el-button
          v-for="(item, index) in dateSpans"
          :key="index"
          @click="getChartData(item)"
          >{{ item.data_name }}</el-button
        >
        <el-date-picker
          style="margin-left: 10px"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="dateChange"
        />
      </div>
      <div :id="lineChartId" class="line-chart"></div>
    </div>
  </div>
</template>

<script>
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekOfYear from "dayjs/plugin/weekOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
const dayjs_SHOW_PATTERN = "YYYY.MM.DD";
const dayjs_DATA_PATTERN = "YYYY-MM-DD";
import * as echarts from "echarts";
export default {
  name: "lineBaseStatistic",
  props: {
    // 接口地址
    interFace: {
      type: String,
      default: "",
    },
    // 折线图名称
    lineChartName: {
      type: String,
      default: "",
    },
    // 折线图id
    lineChartId: {
      type: String,
      default: "",
    },
    // 横坐标枚举
    xAxisData: {
      type: Array,
      default: [],
    },
    // 纵坐标最小跨度
    yAxisDataMinInterval: {
      type: Number,
      default: 1,
    },
    // 每周展示的数据来源类型
    weekDataType: {
      type: String,
      default: "order",
    },
    // 需要展示的日期跨度按钮
    dateSpans: {
      type: Array,
      default: [
        { data_value: "day", data_name: "本日" },
        { data_value: "week", data_name: "本周" },
        { data_value: "month", data_name: "本月" },
      ],
    },
  },
  data() {
    return {
      // 时间范围
      dateRange: [],
      lineChart: null,
      option: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "bottom",
          icon: "rect",
          data: [],
          selected: {},
          selectedMode: true,
        },
        grid: {
          top: "20",
          left: "3.5%",
          right: "2.7%",
        },
        // 横坐标
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xAxisData,
        },
        // 纵坐标
        yAxis: {
          type: "value",
          minInterval: this.yAxisDataMinInterval,
        },
        series: [],
        // 折线图线条颜色
        color: ["#359EFF", "#47C777"],
        dataZoom: [
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 100,
          },
        ],
      },
    };
  },
  methods: {
    dateChange(vals) {
      if (null === vals) {
        this.getChartData(null);
      } else if (vals.length > 1) {
        this.getChartData({
          data_value: "dateRange",
          data_name: "当前日期范围内",
        });
      }
    },
    // 折线图按日、按周、按月、按季度、按年展示(后续有区分时用)
    switchTime(interval) {
      this.loading = true;
      if (interval) {
        const legendData = [];
        const xAxisData = [];
        const series = [];
        if (this.statisData) {
          Object.keys(this.orgs).forEach((key) => {
            legendData.push(this.orgs[key]);
          });
          switch (interval) {
            case "day":
              this.statisByDay(legendData, xAxisData, series);
              break;
            case "week":
              this.statisByWeek(legendData, xAxisData, series);
              break;
            case "month":
              this.statisByMonth(legendData, xAxisData, series);
              break;
            // case 'quarter':
            //   this.statisByQuarter(legendData, xAxisData, series)
            //   break
            // case 'year':
            //   this.statisByYear(legendData, xAxisData, series)
            //   break
            default:
              this.$message.error("非法间距值");
          }
        } else {
          this.$message.warning("无数据!");
        }
        this.option.legend.data = legendData;
        this.option.xAxis.data = xAxisData;
        this.option.series = series;
        this.lineChart.clear();
        this.lineChart.setOption(this.option);
      }
      this.loading = false;
    },
    statisByDay: function (legendData, xAxisData, series) {
      let start = dayjs(this.startDate);
      let end = dayjs(this.endDate);
      const xData = {};
      while (start.isSameOrBefore(end)) {
        xAxisData.push(start.format(dayjs_SHOW_PATTERN));
        const dateStr = start.format(dayjs_DATA_PATTERN);
        Object.keys(this.orgs).forEach((id) => {
          let count = 0;
          if (this.statisData[dateStr] && this.statisData[dateStr][id]) {
            count = this.statisData[dateStr][id];
          }
          if (xData[id]) {
            if (this.method !== "incr") {
              const length = xData[id].length;
              count = xData[id][length - 1] + count;
            }
            xData[id].push(count);
          } else {
            xData[id] = [];
            xData[id].push(count);
          }
        });
        start = start.add(1, "d");
      }
      Object.keys(xData).forEach((id) => {
        series.push({
          name: this.orgs[id],
          type: "line",
          data: xData[id],
        });
      });
    },
    statisByWeek: function (legendData, xAxisData, series) {
      let start = dayjs(this.startDate);
      let end = dayjs(this.endDate);
      const xData = {};
      while (start.isSameOrBefore(end)) {
        const weekXAxis = start.format("(YYYY)w");
        let nextWeek = false;
        if (!xAxisData.find((i) => i === weekXAxis)) {
          xAxisData.push(weekXAxis);
          nextWeek = true;
        }
        const dateStr = start.format(dayjs_DATA_PATTERN);
        Object.keys(this.orgs).forEach((id) => {
          let count = 0;
          if (this.statisData[dateStr] && this.statisData[dateStr][id]) {
            count = this.statisData[dateStr][id];
          }
          if (xData[id]) {
            const length = xData[id].length;
            if (nextWeek) {
              if (this.method !== "incr") {
                count = xData[id][length - 1] + count;
              }
              xData[id].push(count);
            } else {
              xData[id][length - 1] += count;
            }
          } else {
            xData[id] = [];
            xData[id].push(count);
          }
        });
        start = start.add(1, "d");
      }
      Object.keys(xData).forEach((id) => {
        series.push({
          name: this.orgs[id],
          type: "line",
          data: xData[id],
        });
      });
    },
    statisByMonth: function (legendData, xAxisData, series) {
      let start = dayjs(this.startDate);
      let end = dayjs(this.endDate);
      const xData = {};
      while (start.isSameOrBefore(end)) {
        const monthXAxis = start.format("YYYY-MM");
        let nextMonth = false;
        if (!xAxisData.find((i) => i === monthXAxis)) {
          xAxisData.push(monthXAxis);
          nextMonth = true;
        }
        const dateStr = start.format(dayjs_DATA_PATTERN);
        Object.keys(this.orgs).forEach((id) => {
          let count = 0;
          if (this.statisData[dateStr] && this.statisData[dateStr][id]) {
            count = this.statisData[dateStr][id];
          }
          if (xData[id]) {
            const length = xData[id].length;
            if (nextMonth) {
              if (this.method !== "incr") {
                count = xData[id][length - 1] + count;
              }
              xData[id].push(count);
            } else {
              xData[id][length - 1] += count;
            }
          } else {
            xData[id] = [];
            xData[id].push(count);
          }
        });
        start = start.add(1, "d");
      }
      Object.keys(xData).forEach((id) => {
        series.push({
          name: this.orgs[id],
          type: "line",
          data: xData[id],
        });
      });
    },
    // 获取折线图所需数据
    async getChartData(dateDim) {
      let dateFilterType = dateDim.data_value;
      try {
        if (dateFilterType === null || dateFilterType !== "dateRange")
          this.dateRange = [];
        let condition = {
          filterType: dateFilterType,
          rangeStartTime: this.dateRange?.length > 1 ? this.dateRange[0] : null,
          rangeEndTime: this.dateRange?.length > 1 ? this.dateRange[1] : null,
          weekDataType: this.weekDataType,
        };
        let res = await this.axiosHelper.get(`${this.interFace}`, {
          params: condition,
        });
        if (res.status === 200 && !!res.data) {
          this.option.xAxis.data = res.data.xAxisData;
          this.renderStatisticChart(
            res.data.seriesNames,
            res.data.data,
            dateDim.data_name
          );
        }
      } catch (e) {
        this.$message.error("获取" + this.lineChartName + "失败");
      }
    },
    // 渲染折线图
    renderStatisticChart(seriesNames, statisData, dateTypeName) {
      let xAxisData = this.option.xAxis.data;
      this.loading = true;
      let legendData = [];
      let series = [];
      let xData = {};
      let selectedItem = {};
      Object.keys(seriesNames).forEach((key) => {
        legendData.push(seriesNames[key]);
        // 设置图例是否置灰
        selectedItem[seriesNames[key]] = JSON.stringify(statisData) !== "{}";
        xAxisData.forEach((dateStr) => {
          let count = 0;
          if (statisData[dateStr] && statisData[dateStr][key]) {
            count = statisData[dateStr][key];
          }
          if (xData[key]) {
            xData[key].push(count);
          } else {
            xData[key] = [];
            xData[key].push(count);
          }
        });
      });
      Object.keys(xData).forEach((id) => {
        series.push({
          name: seriesNames[id],
          type: "line",
          data: xData[id],
          areaStyle: { color: id === "complete" ? "#C0EDCD" : "#B9DDFF" },
        });
      });
      this.option.legend.selectedMode = JSON.stringify(statisData) !== "{}";
      this.option.legend.selected = selectedItem;
      this.option.legend.data = legendData;
      this.option.series = series;
      this.lineChart.clear();
      this.lineChart.setOption(this.option);
      this.loading = false;
      if (JSON.stringify(statisData) === "{}") {
        this.$message.warning(dateTypeName + this.lineChartName + "暂无数据");
      }
    },
  },
  created() {
    this.getChartData(this.dateSpans[0]);
  },
  mounted() {
    this.lineChart = echarts.init(document.getElementById(this.lineChartId));
    this.lineChart.setOption(this.option);
    //echart自适应窗口改变
    window.addEventListener("resize", () => {
      if (this.lineChart) {
        this.lineChart.resize();
      }
    });
  },
};
</script>

<style scoped>
.line-chart {
  /*margin-top: 25px;*/
  float: left;
  width: 95%;
  height: 85%;
  margin-left: 20px;
}
.span-title {
  display: inline-block;
  position: relative;
  line-height: 18px;
  margin: 7px 0;
  padding-left: 20px;
  font-weight: bold;
  font-size: 18px;
}
.count {
  text-align: center;
  font-size: 40px;
}
.title {
  text-align: center;
  font-size: 30px;
}

.view-block {
  position: relative;
  padding: 18px 0 18px 24px;
  font-size: 14px;
  overflow: hidden;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  /*color: #fff;*/
}
.view-block .view-title {
  padding-bottom: 10px;
}
.view-block .view-count {
  height: 34px;
  line-height: 34px;
  font-size: 32px;
}
.view-block .view-add {
  height: 34px;
  line-height: 34px;
  font-size: 15px;
}
.view-block .view-icon {
  position: absolute;
  top: 32px;
  right: 30px;
}
.block-view-content-2 {
  top: 5px;
  border: none;
}
</style>