<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="手机号：">
          <el-input
            v-model.trim="form.cellphone"
            placeholder="请输入手机号"
            clearable
          />
        </el-form-item>
        <el-form-item label="来源活动：">
          <el-input
            v-model.trim="form.activityName"
            placeholder="请输入来源活动"
            clearable
          />
        </el-form-item>
        <!--                <el-form-item label="城市：">-->
        <!--                    <el-select v-model="form.cityCode" placeholder="全部" collapse-tags clearable>-->
        <!--                        <el-option v-for="item in cityOptions" :key="item.cityCode" :label="item.cityName"-->
        <!--                            :value="item.cityCode">-->
        <!--                        </el-option>-->
        <!--                    </el-select>-->
        <!--                </el-form-item>-->
        <el-form-item label="状态：">
          <el-select
            v-model="form.state"
            placeholder="全部"
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in stateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="购买时间：">
          <el-date-picker
            v-model="createTime"
            type="datetimerange"
            style="width: 335px"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :clearable="true"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="timeChange"
          ></el-date-picker>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card" style="height: 20%">
      <div class="total">
        <div class="total_money">
          <div>购买总额（元）：{{ statistics.total_amount }}</div>
        </div>
        <div class="total_degrees">
          <div>购买总度数（度）：{{ statistics.total_degree }}</div>
        </div>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>度数管理</span>
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        :height="tableHeight"
        :data="dataTable"
        @selection-change="rowSelected"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cell_phone"
          label="手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="activity_name"
          label="来源活动"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="time"
          label="领取时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="类型"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.type === 7">充值</div>
            <div v-else-if="scope.row.type === 9">赠送</div>
            <div v-else-if="scope.row.type === 11">转赠</div>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          label="购买金额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="degree"
          label="获得度数"
          min-width="160"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="state	"
          label="状态"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.state === 1
                  ? "正常"
                  : scope.row.state === 0
                  ? "失败"
                  : "作废"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              @click="handelDetails(scope.row)"
              type="text"
              size="small"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <agentDegreesManageDetailsModal ref="detailsModal">
    </agentDegreesManageDetailsModal>
  </div>
</template>
<script>
import { operateAdd } from "@/api/system/user-api";
import agentDegreesManageDetailsModal from "./model/agent-degrees-manage-details-modal.vue";
import * as XLSX from "xlsx";
import moment from "moment";

export default {
  components: {
    agentDegreesManageDetailsModal,
  },
  data() {
    const currentDate = new Date();
    const threeMonthsBefore = new Date();
    threeMonthsBefore.setMonth(currentDate.getMonth() - 3);

    // 设置开始时间为三个月前的0点0分0秒
    const startOfDay = new Date(threeMonthsBefore.setHours(0, 0, 0, 0));

    // 设置结束时间为当天的23点59分59秒
    const endOfDay = new Date(currentDate.setHours(23, 59, 59, 999));

    // 格式化日期时间为 yyyy-MM-dd HH:mm:ss
    function formatDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const formattedStartOfDay = formatDateTime(startOfDay);
    const formattedEndOfDay = formatDateTime(endOfDay);
    return {
      cityOptions: [],
      form: {
        cellphone: "",
        cityCode: this.$store.state.user.cityCode,
        activityName: "",
        startTime: formattedStartOfDay,
        endTime: formattedEndOfDay,
        state: "",
      },
      stateOptions: [
        { label: "失败", value: 0 },
        { label: "正常", value: 1 },
        { label: "作废", value: -1 },
      ],
      createTime: [formattedStartOfDay, formattedEndOfDay],
      dataTable: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      addOrUpdateVisible: false,
      selection: [],
      statistics: {},
    };
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.getCityList();
    this.search();
  },
  mounted() {
    this.getStatistics();
  },
  methods: {
    getCityList() {
      this.axiosHelper
        .post(`/api/partner/areacorp/allList`, {})
        .then((res) => {
          this.cityOptions = res.data.cityList;
        })
        .catch(() => {
          this.$message.error("加载失败");
        });
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    async search() {
      this.page_index = 1;
      this.loadDataTable();
      this.getStatistics();
    },
    clear() {
      this.form = {
        cellphone: "",
        cityCode: this.$store.state.user.cityCode,
        activityName: "",
        startTime: moment().subtract(3, "months").format("YYYY-MM-DD 00:00:00"),
        endTime: moment().format("YYYY-MM-DD 23:59:59"),
        state: "",
      };
      this.createTime = [];
      this.page_index = 1;
      this.loadDataTable();
      this.getStatistics();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    getStatistics() {
      this.showLoading("加载中");
      let params = {
        ...this.form,
      };
      this.axiosHelper
        .get(`/v0.1/api/operate/degree/manage/statistics`, {
          params: params,
        })
        .then((res) => {
          console.log(res.data, "res.data");
          this.statistics = res.data.data;
          this.hideLoading();
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    loadDataTable() {
      let params = {
        ...this.form,
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/v0.1/api/operate/degree/manage/${offset}/${limit}`, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.data.items;
          this.page_total = response.data.data.total;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    handelDetails(row) {
      this.$refs.detailsModal.visible = true;
      this.$refs.detailsModal.init(row);
      // /v0.1/api/operate/degree/manage/statistics
    },

    timeChange(val) {
      if (val && val.length) {
        this.form.startTime = val[0];
        this.form.endTime = val[1];
      } else {
        this.form.startTime = null;
        this.form.endTime = null;
      }
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 1,
        operate_parameter: JSON.stringify(this.form),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.selection.length == 0) {
        this.$confirm("是否要导出全部数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (this.page_total > 20000) {
            await this.$confirm(
              `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );
          }
          this.showLoading("数据加载中...");
          this.axiosHelper
            .get("/v0.1/api/operate/degree/manage/export", {
              params: this.form,
            })
            .then((response) => {
              this.hideLoading();
              this.exportData(response.data.data.items);
            })
            .catch(() => {
              this.hideLoading();
            });
        });
      } else {
        this.exportData(this.selection);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "手机号码",
          "城市",
          "来源活动",
          "领取时间",
          "类型",
          "购买金额",
          "获得度数",
          "备注",
          "状态",
        ];
        const filterVal = [
          "cell_phone",
          "city_name",
          "billing_name",
          "time",
          "type",
          "amount",
          "degree",
          "remark",
          "state",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "度数管理导出",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j == "state") {
            if (v[j] == -1) {
              return "作废";
            } else {
              return "正常";
            }
          } else if (j == "type") {
            if (v[j] == 7) {
              return "充值";
            } else if (v[j] == 9) {
              return "赠送";
            } else if (v[j] == 11) {
              return "转赠";
            } else {
              return "其他";
            }
          } else {
            return v[j];
          }
        })
      );
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_money,
  &_degrees {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #7899ee;
    font-size: 24px;
    height: 130px;
    width: 49.5%;
  }

  &_degrees {
  }
}
</style>