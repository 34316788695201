
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <!--多条件查询表单-->
    <el-form :inline="true">
      <el-form-item label="城市：">
        <el-select v-model="form.firstCityCode" placeholder="全国" clearable>
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号码：">
        <el-input
          v-model.trim="form.cellphone"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
      <el-form-item label="用户编号：">
        <el-input
          v-model.trim="form.userNumber"
          placeholder="请输入"
          clearable
          maxlength="10"
          oninput="value=value.replace(/[^0-9]/g,'')"
        />
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker
          v-model="createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          align="center"
          style="width: 250px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="录入状态：">
        <el-select v-model="form.inputStatus" placeholder="请选择" clearable>
          <el-option :value="1" label="已录入" />
          <el-option :value="0" label="待录入" />
        </el-select>
      </el-form-item>
      <el-form-item label="车价：">
        <el-select v-model="form.carPrice" placeholder="请选择" clearable>
          <el-option
            v-for="item in carPriceDim"
            :key="item.data_value"
            :value="item.data_value"
            :label="item.data_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年龄：">
        <el-select v-model="form.ageGroup" placeholder="请选择" clearable>
          <el-option
            v-for="item in ageGroupDim"
            :key="item.data_value"
            :value="item.data_value"
            :label="item.data_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="性别：">
        <el-select v-model="form.sex" placeholder="请选择" clearable>
          <el-option :value="1" label="男" />
          <el-option :value="2" label="女" />
        </el-select>
      </el-form-item>
      <el-form-item label="车辆属性：">
        <el-select
          v-model="form.car_attribute"
          placeholder="请选择"
          clearable
        >
          <el-option :value="1" label="客运车" />
          <el-option :value="2" label="私家车" />
          <el-option :value="2" label="货运车" />
        </el-select>
      </el-form-item>
      <el-form-item label="原因：">
        <el-select
          v-model="form.unOrderReason"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in unOrderReasonDim"
            :key="item.data_value"
            :value="item.data_value"
            :label="item.data_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结论：">
        <el-select v-model="form.followResult" placeholder="请选择" clearable>
          <el-option
            v-for="item in followResultDim"
            :key="item.data_value"
            :value="item.data_value"
            :label="item.data_name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
    <!--
    <div style="text-align: center" >
      <p v-if="showFlag" style="display: block;">这是要显示的内容</p>
      <el-button @click="toggle">更多</el-button>
    </div>-->
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <el-button style="float: right" @click="exportData">导出</el-button>
    </el-row>
    <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
      <el-table-column
        prop="fullname"
        label="城市"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="客户手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="register_time"
        label="注册日期"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="car_price_name"
        label="车价"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="delivery_user_name"
        label="性别"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.sex === 1">男</span>
          <span v-if="scope.row.sex === 2">女</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="age_group_name"
        label="年龄段"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="car_attribute_name"
        label="车辆属性"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="un_order_reason_name"
        label="未下单原因"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="follow_result_name"
        label="回访结论"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="remarks"
        label="备注"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button
            @click="addOrUpdateHandle(scope.row)"
            type="text"
            size="small"
            >{{
              scope.row.input_status === true ? "修改" : "待录入"
            }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <UnorderUserSave
    v-if="addOrUpdateVisible"
    ref="addOrUpdate"
    @refreshDataList="search"
    :carPriceDim="carPriceDim"
    :ageGroupDim="ageGroupDim"
    :unOrderReasonDim="unOrderReasonDim"
    :followResultDim="followResultDim"
  ></UnorderUserSave>
</div>
