/*
 * @Author: ljf
 * @Date: 2022-05-26 16:17:05
 * @LastEditors: ljf
 * @LastEditTime: 2022-06-23 10:18:46
 * @FilePath: \lxm-admin\src\router\routers.js
 * @Description:
 *
 */
export const constantRoutes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: "/404",
    component: () => import("@/components/common/page/404.vue"),
    meta: {
      title: "找不到页面",
    },
  },
  {
    path: "/403",
    component: () => import("@/components/common/page/403.vue"),
    meta: {
      title: "无权访问",
    },
  },
  {
    path: "/redirect/:path*",
    name: "redirect",
    component: () => import("@/views/redirect/Redirect.vue"),
  },

  //   {
  //     path: "/home",
  //     name: "home",
  //     component: () => import("@/views/home/Home.vue"),
  //     meta: {
  //       title: "首页",
  //     },
  //     children: [
  //       {
  //         name: "dashboard",
  //         path: "/dashboard",
  //         component: () => import("@/views/dashboard/index.vue"),
  //         meta: {
  //           title: "系统首页",
  //         },
  //       },

  //       {
  //         path: "/user",
  //         name: "user",
  //         meta: {
  //           title: "用户管理",
  //         },
  //         component: () => import("@/views/system/user/User.vue"),
  //       },
  //       {
  //         path: "/account",
  //         name: "account",
  //         meta: {
  //           title: "账户管理",
  //         },
  //         component: () => import("@/views/sysAccount/sysaccount.vue"),
  //       },
  //       {
  //         path: "/role",
  //         name: "role",
  //         meta: {
  //           title: "角色管理",
  //         },
  //         component: () => import("@/views/system/role/Role.vue"),
  //       },
  //       {
  //         path: "/org",
  //         name: "org",
  //         meta: {
  //           title: "组织管理",
  //         },
  //         component: () => import("@/views/system/org/Org.vue"),
  //       },
  //       {
  //         path: "/menu",
  //         name: "menu",
  //         meta: {
  //           title: "菜单管理",
  //         },
  //         component: () => import("@/views/system/menu/menu.vue"),
  //       },
  //       {
  //         path: "/role-authority",
  //         name: "roleAuthority",
  //         meta: {
  //           title: "角色权限管理",
  //         },
  //         component: () =>
  //           import("@/views/system/role-authority/role-authority.vue"),
  //       },
  //       {
  //         path: "/orderStatistics",
  //         name: "orderStatistics",
  //         meta: {
  //           title: "订单统计",
  //         },
  //         component: () => import("@/views/reportForm/page/order-statistics.vue"),
  //       },
  //       {
  //         path: "/batteryStatistics",
  //         name: "batteryStatistics",
  //         meta: {
  //           title: "电池统计",
  //         },
  //         component: () =>
  //           import("@/views/reportForm/page/battery-statistics.vue"),
  //       },
  //       {
  //         path: "/equipment_manager",
  //         name: "equipment_manager",
  //         meta: {
  //           title: "设备管理",
  //         },
  //         component: () => import("@/views/equipment/equipment_manager.vue"),
  //       },
  //       {
  //         path: "/agent_manager",
  //         name: "agent_manager",
  //         meta: {
  //           title: "代理商管理",
  //         },
  //         component: () => import("@/views/agentManager/agentlist.vue"),
  //       },
  //       {
  //         path: "/orderCount_config",
  //         name: "orderCount_config",
  //         meta: {
  //           title: "代理商配置",
  //         },
  //         component: () => import("@/views/agentManager/config/config.vue"),
  //       },

  //       {
  //         path: "/subsidy_data",
  //         name: "subsidy_data",
  //         meta: {
  //           title: "补贴池",
  //         },
  //         component: () => import("@/views/subsidy/subsidy.vue"),
  //       },

  //       {
  //         path: "/subsidy_detail",
  //         name: "subsidy_detail",
  //         meta: {
  //           title: "补贴池详情",
  //         },
  //         component: () => import("@/views/subsidy/subsidyDetail.vue"),
  //       },
  //       {
  //         path: "/unorder-user-data",
  //         name: "unorder-user-data",
  //         meta: {
  //           title: "未下单客户分析",
  //         },
  //         component: () =>
  //           import("@/views/dataCenter/unOrderUser/unorder-user-data.vue"),
  //       },
  //       {
  //         path: "/city-user-develop-data",
  //         name: "city-user-develop-data",
  //         meta: {
  //           title: "城市客户开发情况",
  //         },
  //         component: () =>
  //           import(
  //             "@/views/dataCenter/cityUserDevelop/city-user-develop-data.vue"
  //           ),
  //       },
  //       {
  //         path: "/user-recharge-data",
  //         name: "user-recharge-data",
  //         meta: {
  //           title: "客户储值情况",
  //         },
  //         component: () =>
  //           import("@/views/dataCenter/userRecharge/user-recharge-data.vue"),
  //       },

  //       {
  //         path: "/order/management",
  //         name: "orderManagement",
  //         meta: {
  //           title: "订单列表",
  //         },
  //         component: () => import("@/views/order/order-management.vue"),
  //       },
  //       {
  //         path: "/payment-account",
  //         name: "payment-account",
  //         meta: {
  //           title: "支付对账",
  //         },
  //         component: () => import("@/views/operate/payment-account.vue"),
  //       },
  //       {
  //         path: "/recharge-account",
  //         name: "recharge-account",
  //         meta: {
  //           title: "充值对账",
  //         },
  //         component: () => import("@/views/operate/recharge-account.vue"),
  //       },
  //       {
  //         path: "/user-refund-account",
  //         name: "user-refund-account",
  //         meta: {
  //           title: "退款对账",
  //         },
  //         component: () => import("@/views/operate/user-refund-account.vue"),
  //       },
  //       {
  //         path: "/user-wallet",
  //         name: "user-wallet",
  //         meta: {
  //           title: "用户钱包",
  //         },
  //         component: () => import("@/views/operate/user-wallet.vue"),
  //       },
  //       {
  //         path: "/user-coupon",
  //         name: "user-coupon",
  //         meta: {
  //           title: "用户卡券",
  //         },
  //         component: () => import("@/views/operate/user-coupon.vue"),
  //       },
  //       {
  //         path: "/bill-manage",
  //         name: "bill-manage",
  //         meta: {
  //           title: "开票管理",
  //         },
  //         component: () => import("@/views/operate/bill-manage.vue"),
  //       },
  //       {
  //         path: "/activity-manage",
  //         name: "activity-manage",
  //         meta: {
  //           title: "活动管理",
  //         },
  //         component: () => import("@/views/operate/activity-manage.vue"),
  //       },
  //       {
  //         path: "/coupon-manage",
  //         name: "coupon-manage",
  //         meta: {
  //           title: "优惠券管理",
  //         },
  //         component: () => import("@/views/operate/coupon-manage.vue"),
  //       },
  //       {
  //         path: "/coupon-setting",
  //         name: "coupon-setting",
  //         meta: {
  //           title: "优惠券设置",
  //         },
  //         component: () => import("@/views/operate/coupon-setting.vue"),
  //       },
  //       {
  //         path: "/recom-merchant",
  //         name: "recom-merchant",
  //         meta: {
  //           title: "B端二维码",
  //         },
  //         component: () => import("@/views/operate/recom-merchant.vue"),
  //       },
  //       {
  //         path: "/operate-setting",
  //         name: "operate-setting",
  //         meta: {
  //           title: "经营设置",
  //         },
  //         component: () => import("@/views/operate/operate-setting.vue"),
  //       },
  //       {
  //         path: "/business-setting",
  //         name: "business-setting",
  //         meta: {
  //           title: "营业设置",
  //         },
  //         component: () => import("@/views/agentManager/business-setting.vue"),
  //       },
  //       {
  //         path: "/agent-city-degrees-setting",
  //         name: "agent-city-degrees-setting",
  //         meta: {
  //           title: "代理商城市度数设置",
  //         },
  //         component: () =>
  //           import("@/views/agentManager/agent-city-degrees-setting.vue"),
  //       },
  //       {
  //         path: "/activity-page-manage",
  //         name: "activity-page-manage",
  //         meta: {
  //           title: "活动页管理",
  //         },
  //         component: () => import("@/views/operate/activity-page-manage.vue"),
  //       },
  //       {
  //         path: "/scene-manage",
  //         name: "scene-manage",
  //         meta: {
  //           title: "场景管理",
  //         },
  //         component: () => import("@/views/operate/scene-manage.vue"),
  //       },
  //       {
  //         path: "/delivery-area-setting",
  //         name: "delivery-area-setting",
  //         meta: {
  //           title: "配送范围设置",
  //         },
  //         component: () => import("@/views/operate/delivery-area-setting.vue"),
  //       },
  //       {
  //         path: "/agent-delivery-area-setting",
  //         name: "agent-delivery-area-setting",
  //         meta: {
  //           title: "代理商配送范围设置",
  //         },
  //         component: () =>
  //           import("@/views/agentManager/agent-delivery-area-setting.vue"),
  //       },
  //       {
  //         path: "/city-degrees-price-setting",
  //         name: "city-degrees-price-setting",
  //         meta: {
  //           title: "城市度数价格设置",
  //         },
  //         component: () =>
  //           import("@/views/operate/city-degrees-price-setting.vue"),
  //       },
  //       {
  //         path: "/user-degrees-wallet",
  //         name: "user-degrees-wallet",
  //         meta: {
  //           title: "度数钱包",
  //         },
  //         component: () =>
  //           import(
  //             "@/views/dataCenter/userDegreesWallet/user-degrees-wallet.vue"
  //           ),
  //       },
  //       {
  //         path: "/agent-user-degrees-wallet",
  //         name: "agent-user-degrees-wallet",
  //         meta: {
  //           title: "用户度数钱包",
  //         },
  //         component: () =>
  //           import("@/views/agentManager/agent-user-degrees-wallet.vue"),
  //       },
  //       {
  //         path: "/degrees-manage",
  //         name: "degrees-manage",
  //         meta: {
  //           title: "度数管理",
  //         },
  //         component: () => import("@/views/operate/degrees-manage.vue"),
  //       },
  //       {
  //         path: "/agent-degrees-manage",
  //         name: "agent-degrees-manage",
  //         meta: {
  //           title: "用户度数管理",
  //         },
  //         component: () =>
  //           import("@/views/agentManager/agent-degrees-manage.vue"),
  //       },
  //       {
  //         path: "/channel-business-manage",
  //         name: "channel-business-manage",
  //         meta: {
  //           title: "渠道商管理",
  //         },
  //         component: () =>
  //           import(
  //             "@/views/channelBusiness/channelBusinessManage/channel-business-manage.vue"
  //           ),
  //       },
  //       {
  //         path: "/b-end-enjoy-manage",
  //         name: "b-end-enjoy-manage",
  //         meta: {
  //           title: "B端优享",
  //         },
  //         component: () => import("@/views/operate/b-end-enjoy-manage.vue"),
  //       },
  //       {
  //         path: "/attract-message",
  //         name: "attract-message",
  //         meta: {
  //           title: "B端优享",
  //         },
  //         component: () => import("@/views/operate/attract-message.vue"),
  //       },
  //       {
  //         path: "/battery-maintenance",
  //         name: "battery-maintenance",
  //         meta: {
  //           title: "电池保养常识",
  //         },
  //         component: () => import("@/views/operate/battery-maintenance.vue"),
  //       },
  //       {
  //         path: "/partner-message",
  //         name: "partner-message",
  //         meta: {
  //           title: "招商信息",
  //         },
  //         component: () => import("@/views/operate/partner-message.vue"),
  //       },
  //       {
  //         path: "/system-delivery",
  //         name: "system-delivery",
  //         meta: {
  //           title: "配送员管理",
  //         },
  //         component: () => import("@/views/system/delivery/index.vue"),
  //       },
  //     ],
  //   },
];
