<template>
  <el-dialog
    :title="'度数配置'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <div style="max-height: 50vh; overflow: auto">
      <el-form :model="form" :rules="relus" ref="form" label-width="90px">
        <el-form-item label="当前城市">
          <div>{{ form.city_name }}</div>
        </el-form-item>
        <el-form-item label="常规充电"></el-form-item>
        <div class="form_label">度数价格配置</div>
        <div class="flex">
          <el-form-item label="最低度数" prop="min_degree">
            <el-input-number
              v-model.number="form.min_degree"
              @change="minChange"
              :step="5"
              :min="1"
              :max="200"
              controls-position="right"
              @keyup.native="UpNumber"
              @keydown.native="UpNumber"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="最高度数" prop="max_degree">
            <el-input-number
              v-model.number="form.max_degree"
              @change="maxChange"
              :step="5"
              :step-strictly="true"
              :min="15"
              :max="200"
              controls-position="right"
              @keyup.native="UpNumber"
              @keydown.native="UpNumber"
            ></el-input-number>
          </el-form-item>
        </div>
        <el-form-item label="应急补电"></el-form-item>
        <div class="form_label">服务时间段配置</div>
        <div class="flex">
          <el-form-item label="常规时间" prop="regularTimeStart">
            <el-time-picker
              v-model="form.regularTimeStart"
              placeholder="请选择常规开始时间"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
          </el-form-item>
          <div style="padding: 0 10px; margin-bottom: 20px">至</div>
          <el-form-item label-width="0" prop="regularTimeEnd">
            <el-time-picker
              v-model="form.regularTimeEnd"
              placeholder="请选择常规结束时间"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="夜间时间" prop="nightTimeStart">
            <el-time-picker
              v-model="form.nightTimeStart"
              placeholder="请选择夜间开始时间"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
          </el-form-item>
          <div style="padding: 0 10px; margin-bottom: 20px">至</div>
          <el-form-item label-width="0" prop="nightTimeEnd">
            <el-time-picker
              v-model="form.nightTimeEnd"
              placeholder="请选择夜间结束时间"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
          </el-form-item>
        </div>
        <div class="form_label">服务价位配置</div>
        <div class="flex price_form">
          <el-form-item label="常规时间" prop="regularFiveDegreePrice">
            <div class="flex time_label">
              <span style="margin-left: 0">5度</span><span>/</span
              ><el-input
                v-model.trim="form.regularFiveDegreePrice"
                placeholder="请输入价格"
                maxlength="9"
                clearable
              /><i>元</i>
            </div>
          </el-form-item>
          <el-form-item prop="regularTenDegreePrice" label-width="0">
            <div class="flex time_label">
              <span>10度</span><span>/</span
              ><el-input
                v-model.trim="form.regularTenDegreePrice"
                placeholder="请输入价格"
                maxlength="9"
                clearable
              /><i>元</i>
            </div>
          </el-form-item>
        </div>
        <div class="flex price_form">
          <el-form-item label="夜间时间" prop="nightFiveDegreePrice">
            <div class="flex time_label">
              <span style="margin-left: 0">5度</span><span>/</span>
              <el-input
                v-model.trim="form.nightFiveDegreePrice"
                placeholder="请输入价格"
                maxlength="9"
                clearable
              /><i>元</i>
            </div>
          </el-form-item>
          <el-form-item prop="nightTenDegreePrice" label-width="0">
            <div class="flex time_label">
              <span>10度</span><span>/</span>
              <!-- <el-form-item prop="nightTenDegreePrice" label-width="0"> -->
              <el-input
                v-model.trim="form.nightTenDegreePrice"
                placeholder="请输入价格"
                maxlength="9"
                clearable
              />
              <!-- </el-form-item> -->
              <i>元</i>
            </div>
          </el-form-item>
        </div>
        <!-- <el-form-item label="自助充电"></el-form-item>
        <div class="price_form-zzcd box-card">
          <div class="flex">
            <el-input placeholder="请输入电费单价" style="width: 200px" />
            <p style="margin-left: 10px">元</p>
          </div>
          <div class="flex box-card">
            <el-radio-group v-model="radio">
              <el-radio :label="3">全天</el-radio>
              <el-radio :label="6">自定义</el-radio>
            </el-radio-group>
            <el-time-picker
              style="margin-left: 30px"
              is-range
              v-model="value1"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
            >
            </el-time-picker>
          </div>
          <div class="flex box-card">
            <el-button type="primary" icon="el-icon-plus">增加一组</el-button>
            <el-button type="danger">删除</el-button>
          </div>
        </div> -->
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import axios from "../../../utils/axiosHelper";
export default {
  data() {
    return {
      visible: false,
      pickerOptionsRegularTime: {
        selectableRange: "00:00:00-23:59:59",
      },
      pickerOptionsNightTime: {
        selectableRange: "00:00:00-23:59:59",
      },
      form: {
        regularTimeStart: "",
        regularTimeEnd: "",
        nightTimeStart: "",
        nightTimeEnd: "",
        regularFiveDegreePrice: "",
        regularTenDegreePrice: "",
        nightFiveDegreePrice: "",
        nightTenDegreePrice: "",
      },
      relus: {
        max_degree: [
          {
            required: true,
            message: "请输入最高度数",
            trigger: ["blur", "change"],
          },
        ],
        min_degree: [
          {
            required: true,
            message: "请输入最低度数",
            trigger: ["blur", "change"],
          },
        ],
        regularTimeStart: [
          {
            required: true,
            message: "请选择常规开始时间",
            trigger: ["blur", "change"],
          },
        ],
        regularTimeEnd: [
          {
            required: true,
            message: "请选择常规结束时间",
            trigger: ["blur", "change"],
          },
        ],
        nightTimeStart: [
          {
            required: true,
            message: "请选择夜间开始时间",
            trigger: ["blur", "change"],
          },
        ],
        nightTimeEnd: [
          {
            required: true,
            message: "请选择夜间结束时间",
            trigger: ["blur", "change"],
          },
        ],
        regularFiveDegreePrice: [
          {
            required: true,
            message: "请输入价格,数字最多9位，小数点精确至2位",
            pattern: /^\d{1,6}(\.\d{1,2})?$/,
            trigger: ["blur", "change"],
          },
        ],
        regularTenDegreePrice: [
          {
            required: true,
            message: "请输入价格,数字最多9位，小数点精确至2位",
            pattern: /^\d{1,6}(\.\d{1,2})?$/,
            trigger: ["blur", "change"],
          },
        ],
        nightFiveDegreePrice: [
          {
            required: true,
            message: "请输入价格,数字最多9位，小数点精确至2位",
            pattern: /^\d{1,6}(\.\d{1,2})?$/,
            trigger: ["blur", "change"],
          },
        ],
        nightTenDegreePrice: [
          {
            required: true,
            message: "请输入价格,数字最多9位，小数点精确至2位",
            pattern: /^\d{1,6}(\.\d{1,2})?$/,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    init(row) {
      if (row) {
        this.form = {
          id: row.id,
          city_name: row.city_name,
          max_degree: row.max_degree,
          min_degree: row.min_degree,
          regularTimeStart: row.emergency_charging_price[0].start_time,
          regularTimeEnd: row.emergency_charging_price[0].end_time,
          regularFiveDegreePrice:
            row.emergency_charging_price[0].charging[0].price,
          regularTenDegreePrice:
            row.emergency_charging_price[0].charging[1].price,
          nightTimeStart: row.emergency_charging_price[1].start_time,
          nightTimeEnd: row.emergency_charging_price[1].end_time,
          nightFiveDegreePrice:
            row.emergency_charging_price[1].charging[0].price,
          nightTenDegreePrice:
            row.emergency_charging_price[1].charging[1].price,
        };
        return;
      }
      this.form = {
        max_degree: "",
        min_degree: "",
      };
    },
    toMinutes(timeStr) {
      let [hours, minutes] = timeStr.split(":").map(Number);
      return hours * 60 + minutes;
    },
    // 判断时间范围是否不交叉
    isNonOverlapping(a_start, a_end, b_start, b_end) {
      let a_start_minutes = this.toMinutes(a_start);
      let a_end_minutes = this.toMinutes(a_end);
      let b_start_minutes = this.toMinutes(b_start);
      let b_end_minutes = this.toMinutes(b_end);

      // 检查 a 时间段和 b 时间段是否重叠
      if (a_end_minutes < a_start_minutes) {
        // 常规时间跨午夜的情况
        if (
          b_start_minutes >= a_start_minutes ||
          b_start_minutes <= a_end_minutes ||
          b_end_minutes >= a_start_minutes ||
          b_end_minutes <= a_end_minutes
        ) {
          return false;
        }
      } else {
        // 常规时间不跨午夜的情况
        if (
          (b_start_minutes >= a_start_minutes &&
            b_start_minutes <= a_end_minutes) ||
          (b_end_minutes >= a_start_minutes &&
            b_end_minutes <= a_end_minutes) ||
          (b_start_minutes <= a_start_minutes && b_end_minutes >= a_end_minutes)
        ) {
          return false;
        }
      }

      if (b_end_minutes < b_start_minutes) {
        // 夜间时间跨午夜的情况
        if (
          a_start_minutes >= b_start_minutes ||
          a_start_minutes <= b_end_minutes ||
          a_end_minutes >= b_start_minutes ||
          a_end_minutes <= b_end_minutes
        ) {
          return false;
        }
      } else {
        // 夜间时间不跨午夜的情况
        if (
          (a_start_minutes >= b_start_minutes &&
            a_start_minutes <= b_end_minutes) ||
          (a_end_minutes >= b_start_minutes &&
            a_end_minutes <= b_end_minutes) ||
          (a_start_minutes <= b_start_minutes && a_end_minutes >= b_end_minutes)
        ) {
          return false;
        }
      }

      return true;
      // // 检查 a 是否跨越午夜
      // if (a_end_minutes < a_start_minutes) {
      //     a_end_minutes += 24 * 60;
      // }

      // // 检查 b 是否跨越午夜
      // if (b_end_minutes < b_start_minutes) {
      //     b_end_minutes += 24 * 60;
      // }

      // // 如果 a 和 b 都不跨越午夜，直接判断
      // if (a_end_minutes <= 24 * 60 && b_end_minutes <= 24 * 60) {
      //     return a_end_minutes < b_start_minutes || b_end_minutes < a_start_minutes;
      // }

      // // 如果 a 跨越午夜，调整其结束时间
      // if (a_end_minutes >= 24 * 60) {
      //     return a_end_minutes < b_start_minutes || b_end_minutes < a_start_minutes ||
      //         (b_start_minutes >= a_start_minutes && b_end_minutes <= a_end_minutes);
      // }

      // // 如果 b 跨越午夜，调整其结束时间
      // if (b_end_minutes >= 24 * 60) {
      //     return b_end_minutes < a_start_minutes || a_end_minutes < b_start_minutes ||
      //         (a_start_minutes >= b_start_minutes && a_end_minutes <= b_end_minutes);
      // }

      // return false;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        const {
          regularTimeStart,
          regularTimeEnd,
          nightTimeStart,
          nightTimeEnd,
          max_degree,
          min_degree,
          regularFiveDegreePrice,
          regularTenDegreePrice,
          nightFiveDegreePrice,
          nightTenDegreePrice,
        } = this.form;
        // 测试判断函数
        if (
          !this.isNonOverlapping(
            regularTimeStart,
            regularTimeEnd,
            nightTimeStart,
            nightTimeEnd
          )
        )
          return this.$message.error("常规时间与夜间时间交叉，请重新选择");
        const putData = {
          max_degree,
          min_degree,
          emergency_charging_price: [
            {
              charging: [
                {
                  degree: 5,
                  price: regularFiveDegreePrice,
                },
                {
                  degree: 10,
                  price: regularTenDegreePrice,
                },
              ],
              start_time: regularTimeStart,
              end_time: regularTimeEnd,
              type: 1,
            },
            {
              charging: [
                {
                  degree: 5,
                  price: nightFiveDegreePrice,
                },
                {
                  degree: 10,
                  price: nightTenDegreePrice,
                },
              ],
              start_time: nightTimeStart,
              end_time: nightTimeEnd,
              type: 2,
            },
          ],
        };
        axios
          .put(
            `/api/config/area/charging/range/update/${this.form.id}`,
            putData
          )
          .then((res) => {
            this.hideLoading();
            if (res.data.code != 0) return this.$message.error(res.data.msg);
            this.visible = false;
            this.$emit("refreshDataList");
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
            });
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error("加载失败");
          });
      });
    },
    maxChange(val) {
      if (val <= this.form.min_degree) {
        this.$message.warning("最高度数不能低于最低度数");
        this.$nextTick(() => {
          this.form.max_degree = this.form.min_degree + 5;
        });
      }
    },
    minChange(val) {
      if (val >= this.form.max_degree) {
        this.$message.warning("最低度数不能高于最高度数");
        this.$nextTick(() => {
          this.form.min_degree = this.form.max_degree - 5;
        });
      }
    },
    UpNumber(e) {
      e.target.value = e.target.value.replace(/[^\d]/g, "").slice(0, 3);
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.form_label {
  margin: 0 0 10px 22px;
}

.time_label {
  span {
    &:first-child {
      margin-left: 10px;
    }

    &:nth-child(2) {
      padding: 0 10px;
    }
  }

  i {
    margin-left: 10px;
    font-style: normal;
  }
}

::v-deep .price_form .el-form-item__error {
  padding-left: 65px !important;
}
.price_form-zzcd {
  padding-left: 30px;
}
</style>