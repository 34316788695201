
<el-dialog
  :title="this.editState == 'edit' ? '编辑' : '新增'"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <el-form
    :model="dataForm"
    :rules="dataRule"
    ref="dataForm"
    label-width="140px"
  >
    <el-form-item label="商户名称：" prop="merchant_name">
      <el-input
        v-model.trim="dataForm.merchant_name"
        placeholder="必填"
        clearable
        maxlength="35"
      />
    </el-form-item>
    <el-form-item label="城市：" prop="city_code">
      <el-select
        v-model="dataForm.city_code"
        placeholder="请选择"
        collapse-tags
        clearable
      >
        <el-option
          v-for="item in restrictedCityList"
          :key="item.cityCode"
          :label="item.cityName"
          :value="item.cityCode"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="商户负责人：" prop="merchant_manager">
      <el-input
        v-model.trim="dataForm.merchant_manager"
        clearable
        maxlength="20"
      />
    </el-form-item>
    <el-form-item label="商户电话：" prop="merchant_tel">
      <el-input
        v-model.trim="dataForm.merchant_tel"
        clearable
        clearable
        maxlength="11"
        oninput="value=value.replace(/[^0-9]/g,'')"
      />
    </el-form-item>
    <el-form-item label="有效期：" required prop="validTime">
      <el-date-picker
        v-model="dataForm.validTime"
        type="daterange"
        range-separator="至"
        start-placeholder="起始时间"
        end-placeholder="截止时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
      >
      </el-date-picker>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    <el-button @click="visible = false">取消</el-button>
  </span>
</el-dialog>
