<template>
    <div style="display:inline-block;width:100%">
        <el-upload
            :disabled="disabled"
            action="string"
            :class="{disabled: add_disabled}"
            :limit="1"
            class="avatar-uploader"
            v-loading.fullscreen.lock="loading"
            element-loading-text="拼命上传中"
            element-loading-customClass="personal-loading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(204, 204, 204, 0.6)"
            :http-request="upload"
            :file-list="image_list"
            accept=""
            :before-upload="beforeUpload"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove">
            <i slot="default" class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :title="`放大`" :visible.sync="dialog">
            <img width="100%" :src="url" alt="">
        </el-dialog>
    </div>
</template>

<script>
import FILE_API from "../../../api/file_api";
export default {
    name: "upload_pic",
    props: {
        file: {
            type: Object,
            default() {
                return {};
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            url: '',
            dialog: false,
            image_list: [],
            loading: false
        };
    },
    methods: {
        init() {
            Object.assign(this.$data, this.$options.data())
        },
        handleRemove(file) {
            this.url = '';
            this.image_list = [];
        },
        handlePictureCardPreview(file) {
            this.dialog = true;
        },
        beforeUpload(file) {
            this.loading = true;
        },
        async upload(file) {
            let formData = new FormData();
            formData.append("file", file.file);
            let res = await FILE_API.upload(formData).finally(_ => {this.loading = false;});
            this.url = res.data.url;
            this.image_list = [{url: this.url}];
            this.$emit("update:file", res.data)
        },
    },
    watch: {
        file: {
            deep: true,
            immediate: true,
            handler(value) {
                if (value && value.url) {
                    this.url = value.url;
                    this.image_list = [{url: this.url}];
                }
            }
        }
    },
    computed: {
        add_disabled() {
            return !!this.url && this.image_list.length >= 1;
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>
::v-deep .disabled .el-upload--picture-card {
    display: none !important;
}
::v-deep .el-upload-list__item {
    transition: none !important;
}
</style>