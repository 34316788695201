
<el-dialog
  class="showAll_dialog"
  :title="'详情'"
  width="800px"
  :height="'80%'"
  :max-height="'600px'"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <el-form
    :model="form"
    ref="form"
    v-loading="formLoading"
    label-width="130px"
  >
    <el-row>
      <el-col :span="12">
        <el-form-item label="用户手机号：">
          <el-input
            v-model.trim="form.cell_phone"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="用户编号：">
          <el-input
            v-model.trim="form.user_number"
            :disabled="true"
            clearable
            maxlength="10"
            oninput="value=value.replace(/[^0-9]/g,'')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="城市：">
          <el-input
            v-model.trim="form.city_name"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="获取度数方式：">
          <div class="flex">
            <el-radio-group v-model="form.distribute_src">
              <el-radio :disabled="true" :label="1">系统</el-radio>
              <el-radio :disabled="true" :label="2">手动</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="类型：">
          <el-input
            v-model.trim="form.typeName"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="this.form.type !== 11">
      <el-col :span="12">
        <el-form-item label="来源活动：">
          <el-input
            v-model.trim="form.activity_name"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="来源场景：">
          <el-input
            v-model.trim="form.billing_name"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-col :span="12">
        <el-form-item label="赠与人：">
          <el-input
            v-model.trim="form.source_user"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="购买金额（元）：">
          <el-input
            v-model.trim="form.amount"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="获取度数：">
          <el-input
            v-model.trim="form.degree"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="时间：">
          <el-input
            v-model.trim="form.time"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
      <el-col v-if="this.form.type === 7" :span="12">
        <el-form-item label="付款方式：">
          <el-input
            v-model.trim="form.pay_method_name"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="状态：">
          <el-input
            v-model.trim="form.stateName"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</el-dialog>
