<template>
  <el-row class="block-body">
    <el-form
      ref="roleForm"
      :model="role"
      label-width="80px"
      :rules="rules"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="角色名称"
            prop="role_name"
          >
            <el-input
              v-model="role.role_name"
              placeholder="请输入角色名称"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="角色编号"
            prop="code"
          >
            <el-input
              v-model="role.code"
              placeholder="请输入角色名称"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="角色类型"
            prop="role_name"
          >
            <el-select
              v-model="role.type"
              value-key="value"
              placeholder="请选择角色类型"
              clearable
              filterable
              :disabled="read_only"
            >
              <el-option
                v-for="item in role_type_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="角色状态"
            prop="role_name"
          >
            <el-select
              v-model="role.status"
              value-key="value"
              placeholder="请选择角色类型"
              clearable
              filterable
            >
              <el-option
                v-for="item in role_status_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col
          :span="24"
          :offset="0"
        >
          <el-form-item label="描述">
            <el-input
              type="textarea"
              v-model="role.description"
              :rows="3"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col
          :span="24"
          :offset="0"
        >
          <el-form-item label="备注">
            <el-input
              type="textarea"
              v-model="role.remark"
              :rows="3"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button
          type="primary"
          @click="submit"
        >确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
  import Cookies from "js-cookie";
  import { checkRole, createRole, updateRole } from "api/system/role-api";
  export default {
    name: "RoleEdit",
    props: {
      action: {
        type: String,
        validator: (s) => ["add", "edit"].includes(s),
      },
      role_: {
        type: Object,
        default: () => {
          return {
            allow_deleted: true,
            app_id: Cookies.get("appId") ?? 2,
            code: "",
            description: "",
            id: "",
            remark: "",
            required: false,
            role_name: "",
            status: 1,
            type: 3,
          };
        },
      },
    },
    data () {
      return {
        role: {
          allow_deleted: true,
          app_id: Cookies.get("appId") ?? 2,
          code: "",
          description: "",
          id: "",
          remark: "",
          required: false,
          role_name: "",
          status: 1,
          type: 3,
        },
        role_type_options: [
          {
            value: "3",
            label: "系统角色",
          },
          {
            value: "4",
            label: "组织角色",
          },
        ],
        role_status_options: [
          {
            value: 1,
            label: "启用",
          },
          {
            value: 0,
            label: "禁用",
          },
        ],
        rules: {
          role_name: [
            { required: true, message: "请输入角色名称", trigger: "change" },
            { validator: this.unique, trigger: "blur", text: "角色名称" },
          ],
          code: [
            { required: true, message: "请输入角色编号", trigger: "change" },
            { validator: this.unique, trigger: "blur", text: "角色编号" },
          ],
          type: [
            { required: true, message: "请输入角色类型", trigger: "change" },
          ],
        },
      };
    },
    computed: {
      read_only () {
        if (this.action !== "add") {
          return true;
        }
        return false;
      },
    },
    methods: {
      async init () {
        this.loading = true;
        this.role = { ...this.role_ };
        this.loading = false;
      },
      async submit () {
        try {
          this.loading = true;
          await this.$refs["roleForm"].validate(async (valid) => {
            if (valid) {
              var send_data = this.buildSendData();
              if (this.action == "add") {
                var result = await createRole(send_data);
                if (result == 1) {
                  this.$message.success("添加成功!");
                  this.$emit("close");
                  this.$emit("refresh");
                  this.loading = false;
                }
              } else if (this.action == "edit") {
                var result = await updateRole(send_data);
                if (result == 1) {
                  this.$message.success("修改成功!");
                  this.$emit("close");
                  this.$emit("refresh");
                  this.loading = false;
                }
              }
            }
          });
        } catch (error) {
          this.loading = false;
        }
      },
      buildSendData () {
        var role = { ...this.role };
        return role;
      },

      cancel () {
        this.$emit("close");
      },
      async unique (rule, value, callback) {
        var obj = {
          id: this.role.id,
          appId: Cookies.get("appId") ?? 2,
        };
        obj[rule.field] = value;
        var user = await checkRole(obj);
        if (user == false) {
          callback(new Error(`${rule.text}被占用`));
        }
        callback();
      },
    },
    mounted () {
      this.init();
    },
  };
</script>

<style lang="scss" scoped></style>
