
<div>
  <ex-dialog
    :title="title"
    :visible.sync="dialog_visible"
    :width="750"
    :height="400"
    :show-fullscreen-icon="true"
    :append-to-body="true"
    @close="close"
  >
    <el-form ref="FormData" label-width="110px" inline>
      <el-form-item label="微信昵称">
        <el-input
          v-model="select_user.real_name"
          disabled
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input
          v-model="select_user.cellphone"
          disabled
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item label="性别">
        <el-select
          v-model="select_user.sex"
          disabled
          placeholder="请选择"
          style="width: 200px"
        >
          <el-option label="保密" :value="0"></el-option>
          <el-option label="男" :value="1"></el-option>
          <el-option label="女" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="城市">
        <el-select
          style="width: 200px"
          v-model="select_user.city_code"
          disabled
          placeholder="请选择城市"
        >
          <el-option
            v-for="item in cityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册时间">
        <el-date-picker
          disabled
          type="date"
          v-model="select_user.create_time"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="image-box">
      <div class="item">
        <p class="item-title">身份证正反面：</p>
        <div class="item-main">
          <el-image
            style="width: 110px; height: 55px"
            :src="select_user.id_card_front_url"
            :preview-src-list="[
              select_user.id_card_front_url,
              select_user.id_card_back_url,
            ]"
          />
          <el-image
            style="width: 110px; height: 55px; margin-left: 20px"
            :src="select_user.id_card_back_url"
            :preview-src-list="[
              select_user.id_card_front_url,
              select_user.id_card_back_url,
            ]"
          />
        </div>
      </div>
      <div class="item">
        <p class="item-title">头像：</p>
        <div class="item-main">
          <el-image
            class="image"
            :src="select_user.deliveryer_head_url"
            :preview-src-list="[select_user.deliveryer_head_url]"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </div>
    </div>
    <div class="image-box">
      <div class="item">
        <p class="item-title">证书：</p>
        <div class="item-main">
          <el-image
            style="width: 110px; height: 55px"
            :preview-src-list="[select_user.deliveryer_certif_url]"
            :src="select_user.deliveryer_certif_url"
          />
        </div>
      </div>
    </div>
    <div slot="footer" v-if="select_user.deliveryer_check_state == 1">
      <el-button type="primary" @click="examineBtn">审批通过</el-button>
      <el-button @click="reject_visible = true">驳回</el-button>
    </div>
    <ex-dialog
      title="驳回理由"
      :visible.sync="reject_visible"
      :width="550"
      :height="300"
      append-to-body
      @close="reject_visible = false"
    >
      <p class="reject-title">请选择原因</p>
      <el-checkbox-group
        v-model="deliveryer_reject_reason"
        class="radio-group"
      >
        <el-checkbox label="1" v-if="select_user.deliveryer_head_url"
          >头像不符合规定</el-checkbox
        >
        <el-checkbox label="2">培训证书认证资料不符合规定</el-checkbox>
        <el-checkbox label="3">身份证信息不符合规定</el-checkbox>
      </el-checkbox-group>
      <div slot="footer">
        <el-button type="primary" @click="rejectBtn">确定</el-button>
        <el-button @click="reject_visible = false">取消</el-button>
      </div>
    </ex-dialog>
  </ex-dialog>
</div>
