
<div class="header">
  <!-- 折叠按钮 -->
  <div
    class="collapse-btn"
    @click="collapseChange"
  >
    <i class="el-icon-menu"></i>
  </div>
  <div class="logo">绿小马运营平台</div>
  <span style="font-size: 16px;line-height:50px;color:#FFDC9A;font-weight:bold">本数据平台所涉及的信息均为高度敏感且受法律严格保护的重要资产，严守保密，严禁外传。</span>
  <div class="header-right">
    
    <div
      class="header-date"
      v-html="current_time"
    ></div>
    <div class="header-user-con">
      <!-- 全屏显示 -->
      <div
        class="btn-fullscreen"
        @click="handleFullScreen"
      >
        <el-tooltip
          effect="dark"
          :content="fullscreen?`取消全屏`:`全屏`"
          placement="bottom"
        >
          <i class="el-icon-rank"></i>
        </el-tooltip>
      </div>
      <!-- 消息中心 -->
      <!-- <div class="btn-bell">
        <el-tooltip
          effect="dark"
          :content="message?`有${message}条未读消息`:`消息中心`"
          placement="bottom"
        >
       
          <i class="el-icon-bell"></i>
         
        </el-tooltip>
        <span
          class="btn-bell-badge"
          v-if="message"
        ></span>
      </div> -->
      <!-- 用户头像 -->
      <!--todo 用户头像需保存-->
      <el-popover
        placement="bottom"
        trigger="click"
      >
        <div>
          <div class="my-info-large">{{ myInfo.realName }}</div>
          <div class="my-info-gray">{{ myInfo.orgName }}</div>
          <el-divider class="my-divider"></el-divider>
          <div class="my-info-small">手机：{{ myInfo.cellphone }}</div>
          <div class="my-info-small-bottom">电子邮箱：{{ myInfo.email }}</div>
        </div>
        <div
          slot="reference"
          class="user-avator"
        ><img
            :src="imgSrc"
            class="user-avatar-img"
          ></img></div>
      </el-popover>
      <!-- 用户名下拉菜单 -->
      <el-dropdown
        class="user-name"
        trigger="click"
        @command="handleCommand"
      >
        <span class="el-dropdown-link">
          {{username}}
          <i class="el-icon-caret-bottom"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item   
            command="changePassword"
          >修改密码</el-dropdown-item>
          <el-dropdown-item
            divided
            command="loginout"
          >退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <changePassword ref="changePassword"></changePassword>
  </div>
</div>
