
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <div slot="header" class="clearfix">
      <span>数据筛选</span>
      <el-button
        style="float: right; padding: 3px 0"
        type="text"
        @click="filter"
        >高级查询</el-button
      >
    </div>
    <div class="text item">
      <el-form
        ref="searchValue"
        style="padding-right: 10px"
        :model="searchValue"
        label-width="110px"
      >
        <el-row class="search">
          <el-col>
            <el-form-item label="开票状态：">
              <el-select
                v-model="searchValue.state"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="item in stateDim"
                  :key="item.value"
                  :value="item.value"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申请人编号：">
              <el-input
                v-model.trim="searchValue.userNumber"
                placeholder="请输入"
                oninput="value=value.replace(/[^0-9]/g,'')"
                clearable
              />
            </el-form-item>
            <el-form-item label="申请手机号：">
              <el-input
                v-model.trim="searchValue.user_mobile"
                placeholder="请输入"
                oninput="value=value.replace(/[^0-9]/g,'')"
                maxlength="11"
                clearable
              />
            </el-form-item>
            <el-form-item label="城市">
              <el-select
                v-model="searchValue.city_code"
                placeholder="全国"
                collapse-tags
                filterable
                clearable
              >
                <el-option
                  v-for="item in restrictedCityList"
                  :key="item.cityCode"
                  :label="item.cityName"
                  :value="item.cityCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col class="elColBtn">
            <el-button type="primary" @click="clickSearch">查询</el-button>
            <el-button type="default" @click="clear">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>开票管理</span>
      <el-button style="margin-left: 15px" @click="finishBill" v-if="isAgent"
        >完成开票</el-button
      >
      <el-button style="float: right" @click="exportOrder">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
      @selection-change="rowSelected"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_number"
        label="申请人编号"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="bill_no"
        label="开票流水号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_mobile"
        label="申请手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="申请时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="bill_type"
        label="抬头类型"
        min-width="150"
        align="center"
        :formatter="typeFormat"
      ></el-table-column>
      <el-table-column
        prop="company_name"
        label="发票抬头"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="company_tax_no"
        label="公司税号"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="email_address"
        label="邮箱地址"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="more_content"
        label="更多内容"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="amount"
        label="开票金额（元）"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="state"
        label="开票情况"
        min-width="150"
        align="center"
        :formatter="stateFormat"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            @click="showBillDeatil(scope.row)"
            type="text"
            size="small"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
  <BillDeatil v-if="billDeatilVisible" ref="billDeatil"></BillDeatil>
</div>
