<template>
    <el-dialog :title="this.editState == 'edit' ? '编辑' : '新增'" :close-on-click-modal="false" :visible.sync="visible">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="170px">
            <el-form-item label="券名：" prop="name">
                <el-input v-model.trim="dataForm.name" placeholder="请输入券名" clearable maxlength="35" />
            </el-form-item>
            <el-form-item label="可用范围：" prop="order_type">
                <el-select v-model="dataForm.order_type" placeholder="请选择可用范围" @change="orderTypeChange" collapse-tags
                    clearable>
                    <el-option v-for="item in scopeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="类型：" prop="discount_type">
                <el-select v-model="dataForm.discount_type" placeholder="请选择类型" collapse-tags @change="typeChange"
                    clearable>
                    <el-option v-for="item in ticketList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- 现金券 -->
            <el-form-item v-if="dataForm.discount_type === 0" label="抵扣金额（元）：" prop="discount">
                <el-input v-model.trim="dataForm.discount" placeholder="抵扣金额（元）：" clearable maxlength="9" />
            </el-form-item>
            <!-- 度数券 -->
            <div v-if="dataForm.discount_type === 2">
                <el-form-item label="抵扣度数：" prop="discount_electric">
                    <el-input v-model.trim="dataForm.discount_electric" placeholder="请输入抵扣度数（元）" clearable
                        maxlength="11" @input="" oninput="value=value.replace(/[^0-9]/g,'')" />
                </el-form-item>
                <el-form-item label="电费单价（度数）" prop="electric_unit_price">
                    <el-input v-model.trim="dataForm.electric_unit_price" placeholder="请输入电费单价（度数）" clearable
                        maxlength="9" />
                </el-form-item>
            </div>
            <el-form-item v-if="dataForm.discount_type === 3" label="时间限制" prop="time_interval_limit">
                <el-select v-model="dataForm.time_interval_limit" placeholder="请选择时间限制" collapse-tags clearable>
                    <el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-else label="预估折算金额（元）：" prop="expect_discount_amount">
                <el-input v-model.trim="dataForm.expect_discount_amount" placeholder="预估折算金额（元）自动计算" clearable
                    maxlength="20" :disabled="true" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
            <el-button @click="visible = false">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import agentApi from "../../../api/agent_api";
    import axios from "../../../utils/axiosHelper";
    import OPERATE_API from "../../../api/operate_api";
    export default {
        // name: "AddOrUpdate",
        props: {
            editState: {
                type: String,
                default: "add",
            },
        },
        data() {
            return {
                visible: false,
                cityList: [],
                formItem: {},
                dataForm: {
                    id: "",
                    name: "",
                    order_type: "",
                    discount_type: "",
                    discount: "",
                    discount_electric: "",
                    electric_unit_price: "",
                    expect_discount_amount: "",
                    time_interval_limit: ""
                },
                scopeList: [
                    { label: "通用", value: 0 },
                    { label: "上门充电", value: 1 },
                    { label: "应急补电", value: 2 }
                ],
                ticketList: [
                    { label: "现金券", value: 0 },
                    { label: "度数券", value: 2 }
                ],
                timeList: [
                    { label: '否', value: 0 },
                    { label: '常规时间', value: 1 },
                    { label: '夜间时间', value: 2 }
                ],
                dataRule: {
                    name: [
                        {
                            required: true,
                            message: "请输入券名",
                            trigger: ["blur", "change"],
                        },
                    ],
                    order_type: [
                        {
                            required: true,
                            message: "请选择可用范围",
                            trigger: ["blur", "change"],
                        },
                    ],
                    discount_type: [
                        {
                            required: true,
                            message: "请选择类型",
                            trigger: ["blur", "change"],
                        },
                    ],
                    discount: [
                        {
                            required: true,
                            message: "请输入抵扣金额,只能数字且最多6位，小数点最多2位",
                            pattern: /^\d{1,6}(\.\d{1,2})?$/,
                            trigger: ["blur", "change"],
                        },
                    ],
                    discount_electric: [
                        {
                            required: true,
                            message: "请输入抵扣度数",
                            trigger: ["blur", "change"],
                        },
                    ],
                    electric_unit_price: [
                        {
                            required: true,
                            message: "请输入电费单价,只能数字且最多6位，小数点最多2位",
                            pattern: /^\d{1,6}(\.\d{1,2})?$/,
                            trigger: ["blur", "change"],
                        },
                    ],
                    expect_discount_amount: [
                        {
                            required: true,
                            message: "未填写抵扣金额/抵扣度数/电费单价",
                            trigger: ["blur", "change"],
                        },
                    ],
                    time_interval_limit: [
                        {
                            required: true,
                            message: "请选择时间限制",
                            trigger: ["blur", "change"],
                        },
                    ]
                },
            };
        },
        watch: {
            dataForm:{
                handler(newVal, oldVal) {
                    if(newVal.discount_type == 0){
                        if (newVal.discount) {
                            newVal.expect_discount_amount = newVal.discount;
                        }else{
                            newVal.expect_discount_amount = ""
                        }
                    }
                    if(newVal.discount_type == 2){
                        if (newVal.discount_electric && newVal.electric_unit_price) {
                            newVal.expect_discount_amount = newVal.discount_electric * newVal.electric_unit_price;
                        }else{
                            newVal.expect_discount_amount = ""
                        }
                    }
                    if(newVal.discount_type == 3){
                        newVal.expect_discount_amount = ""
                    }
                   
                },
                deep: true // 开启深度监听
            }
        },
        created() { },
        mounted() { },
        methods: {
            init(row) {
                this.visible = true;
                this.$nextTick(() => {
                    this.$refs["dataForm"].resetFields();
                    if (row) {
                        console.log(row);
                        this.formItem = row;
                        this.dataForm.id = row.id;
                        this.dataForm.name = row.name;
                        this.dataForm.order_type = row.order_type;
                        this.dataForm.discount_type = row.discount_type;
                        this.dataForm.discount = row.discount;
                        this.dataForm.discount_electric = row.discount_electric;
                        this.dataForm.electric_unit_price = row.electric_unit_price;
                        this.dataForm.time_interval_limit = row.time_interval_limit
                        this.dataForm.expect_discount_amount = !this.dataForm.discount_type ? row.discount : row.discount_electric * row.electric_unit_price
                        this.ticketListChange(row.order_type)
                        console.log()
                    } else {
                        this.dataForm.id = "";
                        this.dataForm.name = "";
                        this.dataForm.order_type = "";
                        this.dataForm.discount_type = "";
                        this.dataForm.discount = "";
                        this.dataForm.discount_electric = "";
                        this.dataForm.electric_unit_price = "";
                        this.dataForm.expect_discount_amount = "";
                        this.dataForm.time_interval_limit = ""
                    }
                });
            },
            typeChange(e) {
                if (!e) return (this.dataForm.discount = "");
                this.dataForm.discount_electric = "";
                this.dataForm.electric_unit_price = "";
            },
            orderTypeChange(val) {
                this.dataForm.discount_type = ''
                this.ticketListChange(val)
            },
            ticketListChange(val) {
                console.log(val, 'asdasd')
                switch (val) {
                    case 0:
                        this.ticketList = [
                            { label: "现金券", value: 0 }
                        ]
                        break;
                    case 1:
                        this.ticketList = [
                            { label: "现金券", value: 0 },
                            { label: "度数券", value: 2 }
                        ]
                        break;
                    case 2:
                        this.ticketList = [
                            { label: "现金券", value: 0 },
                            { label: "次数券", value: 3 }
                        ]
                        this.dataForm.time_interval_limit = 0
                        break;
                }
            },
            // 表单提交
            dataFormSubmit() {
                // 预估折扣金额
                if (this.dataForm.discount)
                    this.dataForm.expect_discount_amount = this.dataForm.discount;
                if (
                    this.dataForm.discount_electric &&
                    this.dataForm.electric_unit_price
                )
                    this.dataForm.expect_discount_amount =
                        this.dataForm.discount_electric *
                        this.dataForm.electric_unit_price;
                this.$refs["dataForm"].validate(async (valid) => {
                    if (!valid) return;
                    let dataFormStr = JSON.stringify(this.dataForm);

                    this.showLoading("加载中");
                    //   新增
                    if (this.editState == "add") {
                        axios
                            .post("/api/coupon/add", this.dataForm)
                            .then((res) => {
                                this.hideLoading();
                                if (res.data.code != 0) {
                                    this.$message.error(res.data.msg);
                                    return;
                                }
                                this.visible = false;
                                this.$emit("refreshDataList");
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                    duration: 1500,
                                });
                            })
                            .catch(() => {
                                this.hideLoading();
                                this.$message.error("加载失败");
                            });
                    } else if (this.editState == "edit") {
                        // 编辑
                        axios
                            .put(`/api/coupon/update/${this.dataForm.id}`, this.dataForm)
                            .then((res) => {
                                this.hideLoading();
                                if (res.data.code != 0) {
                                    this.$message.error(res.data.msg);
                                    return;
                                }
                                this.visible = false;
                                this.$emit("refreshDataList");
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                    duration: 1500,
                                });
                            })
                            .catch(() => {
                                this.hideLoading();
                                this.$message.error("加载失败");
                            });
                    }
                });
            },
        },
    };
</script>