<template>
  <el-dialog
    title="二维码"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="20%"
    style="height: 100vh"
    center
  >
    <div style="text-align: center">
      <el-image
        style="align-content: center; width: 300px"
        :src="imgUrl"
      ></el-image>
      <p style="font-size: 32px; color: #000">
        {{ dataItem.equipment_name }}{{ dataItem.equipment_no.slice(-4) }}
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="downloadQrcode()">下载</el-button>
      <el-button @click="visible = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      visible: false,
      imgUrl: "",
      dataItem: {},
    };
  },
  created() {},
  mounted() {},
  methods: {
    init(row, qrcode_url) {
      console.log(123123123);
      console.log(qrcode_url);
      this.visible = true;
      this.dataItem = row;
      if (this.dataItem) {
        this.imgUrl = qrcode_url;
      }
    },
    downloadQrcode() {
      if (this.imgUrl) {
        this.downloadIamge(
          this.imgUrl,
          this.dataItem.equipment_name + "-二维码.png"
        );
      } else {
        this.$message.warning("下载失败");
      }
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height + 50;
        let context = canvas.getContext("2d");
        context.fillStyle = "white"; // 设置背景颜色为白色
        context.fillRect(0, 0, canvas.width, canvas.height); // 填充整个画布
        context.drawImage(image, 0, 0, image.width, image.height);
        context.font = "32px Arial";
        context.fillStyle = "black"; // 设置文本颜色
        context.textAlign = "center"; // 设置文本对齐方式
        let text = `${
          this.dataItem.equipment_name
        }${this.dataItem.equipment_no.slice(-4)}`; // 要绘制的文本
        let x = canvas.width / 2; // 文本的 x 坐标
        let y = canvas.height - 20; // 文本的 y 坐标
        context.fillText(text, x, y);
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
  },
};
</script>