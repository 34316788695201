<template>
  <el-dialog
    :title="title + '-配送范围设置'"
    :visible.sync="visible"
    width="80%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    @opened="handleDialogOpened"
  >
    <div v-if="titleShow" class="map_title">双击即可结束绘制！</div>
    <div id="map-container">
      <div ref="amap" id="amap" style="height: 100%; width: 100%"></div>
    </div>
    <div class="footer">
      <button class="footer_btn" @click="createPolygon" :disabled="titleShow">
        新建多边形
      </button>
      <el-button v-if="hasPolygon" class="footer_btn" @click="startEdit"
        >开始编辑</el-button
      >
      <el-button v-if="hasPolygon" class="footer_btn" @click="endEdit"
        >结束编辑</el-button
      >
      <el-button v-if="hasPolygon" class="footer_btn" @click="clearPolygon"
        >清空</el-button
      >
      <el-button
        v-if="selectedPolygon"
        class="footer_btn"
        @click="deletePolygon"
        >删除多边形</el-button
      >
      <el-button class="footer_btn" @click="confirm">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      titleShow: false,
      map: null,
      polygonEditor: null,
      polyEditor: null,
      areaRangeConfig: [],
      coordList: [],
      isDrawing: false,
      hasPolygon: false,
      position: [],
      id: "",
      title: "",
      polygons: [], // 存储多个多边形对象
      selectedPolygon: null, // 选中的多边形对象
      type: 1,
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.initializeMap();
        });
      }
    },
  },
  methods: {
    init(row, type) {
      this.type = type;
      if (row) {
        if (type === "1") {
          this.areaRangeConfig = row.area_range_config || [];
        } else {
          this.areaRangeConfig = row.emergency_area_range_config || [];
        }

        this.position = [row.agent_longitude, row.agent_latitude];
        this.id = row.id;

        this.hasPolygon = this.areaRangeConfig.some(
          (data) => data.paths.length > 0
        );
        this.visible = true;
        this.title = row.city_name;
      }
    },
    handleClose() {
      this.visible = false;
      this.areaRangeConfig = [];
      this.position = [];
      this.id = "";
      this.hasPolygon = false;
      this.polygons = [];
      this.selectedPolygon = null;
      // this.clearPolygon()
      this.map = null;
    },
    handleDialogOpened() {
      if (this.map) {
        setTimeout(() => {
          this.map.setFitView();
        }, 100); // 确保对话框完全渲染后调用
      }
    },
    initializeMap() {
      if (!this.map) {
        this.map = new AMap.Map(this.$refs.amap, {
          center: this.position, // 北京天安门坐标
          zoom: 15,
        });
        // 添加标识点
        const marker = new AMap.Marker({
          position: this.position,
          map: this.map,
        });
        this.map.plugin(["AMap.ToolBar", "AMap.PolygonEditor"], () => {
          this.map.addControl(new AMap.ToolBar());
        });

        // 根据初始化的多边形数据添加多边形
        if (this.areaRangeConfig.length) {
          this.areaRangeConfig.forEach((data) => {
            this.addPolygon(data);
          });
        } else {
          this.addPolygon();
        }
      } else {
        this.map.setFitView(); // 如果地图已经存在，重新调整视图
      }
    },
    addPolygon(data) {
      // 多边形类型为1，即指定路径的多边形
      // 已设置配送范围区域时
      if (data) {
        const polygon1 = new AMap.Polygon({
          path: data ? data.paths : [],
        });
        this.map.add([polygon1]);
        polygon1.on("dblclick", () => {
          this.polygonEditor.setTarget(polygon1);
          this.polygonEditor.open();
          this.titleShow = false;
        });
        polygon1.on("click", () => {
          this.selectPolygon(polygon1);
        });
        this.polygons.push(polygon1);
        this.hasPolygon = this.polygons.some(
          (polygon) => polygon.getPath().length > 0
        );
        this.polygonEditor = new AMap.PolygonEditor(this.map);
        return;
      }
      // 未配置范围区域时
      this.polygonEditor = new AMap.PolygonEditor(this.map); // 确保每次绘制新多边形时正确初始化 polygonEditor
      console.log(this.polygonEditor);
      // this.polygonEditor.on("add", (item) => {
      //   this.titleShow = false;
      //   this.coordList = item.lnglat;
      //   const polygon = item.target;
      //   polygon.on("dblclick", () => {
      //     this.polygonEditor.setTarget(polygon);
      //     this.polygonEditor.open();
      //   });
      //   polygon.on("click", () => {
      //     this.selectPolygon(polygon);
      //   });
      //   this.polygons.push(polygon); // 确保新绘制的多边形添加到数组中
      //   this.hasPolygon = this.polygons.some(
      //     (polygon) => polygon.getPath().length > 0
      //   );
      // });
      // this.polygonEditor.open(); // 确保 polygonEditor 打开
    },
    createPolygon() {
      this.titleShow = true;
      this.polygonEditor = new AMap.PolygonEditor(this.map); // 确保每次绘制新多边形时正确初始化 polygonEditor
      this.polygonEditor.on("add", (item) => {
        this.titleShow = false;
        this.coordList = item.lnglat;
        const polygon = item.target;
        polygon.on("dblclick", () => {
          this.polygonEditor.setTarget(polygon);
          this.polygonEditor.open();
        });
        polygon.on("click", () => {
          this.selectPolygon(polygon);
        });
        this.polygons.push(polygon); // 确保新绘制的多边形添加到数组中
        this.hasPolygon = this.polygons.some(
          (polygon) => polygon.getPath().length > 0
        );
      });
      this.polygonEditor.open(); // 确保 polygonEditor 打开
    },
    startEdit() {
      if (this.polygonEditor) {
        this.polygonEditor.open();
      }
    },
    endEdit() {
      let mapList = [];
      if (this.polygonEditor) {
        this.polygonEditor.on("end", (event) => {
          this.coordList = event.target.getPath();
          mapList = this.coordList
            ? this.coordList.map((v) => [v.lng, v.lat])
            : [];
        });
        this.polygonEditor.close();
        this.selectedPolygon = null;
      }
      return mapList;
    },
    async confirm() {
      let data = this.polygons.map((polygon) => ({
        paths: polygon.getPath().map((v) => [v.lng, v.lat]),
        type: 1,
      }));
      data = data.filter((item) => item.paths.length);
      // 在这里添加确认逻辑
      let api = "";
      if (this.type === "1") {
        api = `/api/config/area/range/config/set/${this.id}`;
      } else {
        api = `/api/config/emergency/area/range/set/${this.id}`;
      }
      this.axiosHelper
        .put(api, data)
        .then((res) => {
          if (data.length) {
            this.$message.success("修改成功");
          }
          this.$emit("getList");
        })
        .catch((error) => {
          this.$message.error(error.msg);
        });
      this.handleClose();
    },
    clearPolygon() {
      if (this.polygonEditor) {
        this.titleShow = false;
        this.polygonEditor.close();
        this.polygonEditor = null;
        this.coordList = [];
        // 清空地图上的所有覆盖物
        this.map.clearMap();
        // 添加标识点
        const marker = new AMap.Marker({
          position: this.position,
          map: this.map,
        });
        // 未配置范围区域时
        this.polygonEditor = new AMap.PolygonEditor(this.map);
        this.polygonEditor.on("add", (item) => {
          this.titleShow = false;
          this.coordList = item.lnglat;
          const polygon = item.target;
          polygon.on("dblclick", () => {
            this.polygonEditor.setTarget(polygon);
            this.polygonEditor.open();
          });
          this.polygons.push(polygon);
          this.hasPolygon = this.polygons.some(
            (polygon) => polygon.getPath().length > 0
          );
        });
        this.hasPolygon = false;
        this.polygons = [];
        this.selectedPolygon = null;
        this.polygonEditor.close();
      }
    },
    selectPolygon(polygon) {
      if (this.selectedPolygon) {
        this.selectedPolygon.setOptions({
          strokeColor: "#FF33FF",
          fillColor: "#1791fc",
        });
      }
      polygon.setOptions({
        strokeColor: "#FF0000",
        fillColor: "#FF99FF",
      });
      this.selectedPolygon = polygon;
      console.log(this.polygons);
    },
    deletePolygon() {
      if (this.selectedPolygon) {
        const index = this.polygons.indexOf(this.selectedPolygon);
        if (index > -1) {
          this.polygons.splice(index, 1);
          this.map.remove(this.selectedPolygon);
          this.selectedPolygon = null;
          this.hasPolygon = this.polygons.some(
            (polygon) => polygon.getPath().length > 0
          );
        }
        this.polygonEditor.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#map-container {
  position: relative;
  height: 800px;
  /* 根据需要调整 */
  width: 100%;
}

.map_title {
  padding-bottom: 10px;
  font-size: 16px;
  color: #fe5e00;
  font-weight: bold;
}

.footer {
  padding: 30px 0 0 0;
  display: flex;
  justify-content: space-around;

  &_btn {
    width: 120px;
    height: 30px;
    border: 1px solid #5a8bff;
    color: #5a8bff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>