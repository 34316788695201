
<ex-dialog
  :title="title"
  :visible.sync="dialog_visible"
  :width="900"
  :height="500"
  :show-fullscreen-icon="true"
  :append-to-body="true"
  :before-close="cancel"
>
  <el-scrollbar
    style="height: 100%; margin-right: -20px"
    wrapStyle="overflow-x:hidden;padding-right:20px;"
    viewStyle=""
  >
    <div class="user-edit">
      <el-form
        ref="userForm"
        v-loading="loading"
        :model="user"
        label-width="130px"
        :rules="rules"
        inline
      >
        <el-form-item label="手机号码" prop="cellphone">
          <el-input
            v-model="user.cellphone"
            placeholder="请输入手机号码"
            maxlength="11"
            show-word-limit
            disabled
            style="width: 100%"
            :readonly="this.action == 'edit'"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信昵称" prop="real_name">
          <el-input
            v-model="user.real_name"
            placeholder="请输入微信昵称"
            style="width: 100%"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="注册推荐人类型" prop="recommend_type">
          <el-select
            v-model="user.recommend_type"
            placeholder=""
            collapse-tags
            clearable
            :disabled="this.action == 'edit'"
          >
            <el-option
              v-for="item in recommendTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推荐方：" prop="reference_name">
          <el-input
            v-model="user.reference_name"
            placeholder="请输入微信昵称"
            maxlength="20"
            style="width: 100%"
            show-word-limit
            :disabled="this.action == 'edit'"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户编号" prop="user_number">
          <el-input
            v-model="user.user_number"
            placeholder="请输入用户编号"
            maxlength="10"
            oninput="value=value.replace(/[^0-9]/g,'')"
            disabled
            :readonly="this.action == 'edit'"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input
            v-model="user.email"
            placeholder="请输入邮箱"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select
            v-model="user.sex"
            value-key="value"
            placeholder="请选择性别"
            clearable
            filterable
          >
            <el-option
              v-for="item in sex_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="黑名单">
          <el-select
            v-model="user.blacklist"
            value-key="value"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in if_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市">
          <el-select
            v-model="user.first_city_code"
            value-key="value"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in cityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否脱敏" prop="desensi" v-if="isShowBtn">
          <el-select
            v-model="user.desensi"
            value-key="value"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in if_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="每日可下单数">
          <el-input
            v-model="user.daily_person_max_order_num"
            oninput="value=value.replace(/[^\d]/g,'')"
            placeholder="请输入每日可下单数"
            maxlength="6"
          >
            <template slot="suffix">单</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="是否地推人员"
          prop="ground_push_person"
          v-if="isShowBtn"
        >
          <el-select
            v-model="user.ground_push_person"
            value-key="value"
            placeholder="请选择"
          >
            <el-option
              v-for="item in if_options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户类型">
          <el-select
            v-model="user.user_category"
            value-key="value"
            placeholder="请选择"
            filterable
            :disabled="!showTypeBtn"
          >
            <el-option
              v-for="item in user_categorys"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="B端类型"
          label-width="100px"
          v-if="user.user_category === 2"
        >
          <el-select
            v-model="user.un_pay_num_limit"
            value-key="value"
            placeholder="请选择"
            :disabled="!showTypeBtn"
          >
            <el-option
              v-for="item in b_end_type_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="系统角色">
          <!-- <el-input v-model="user.role_ids"></el-input> -->
          <el-select
            v-model="user.role_ids_"
            clearable
            filterable
            multiple
            :disabled="!isShowBtn"
          >
            <el-option
              v-for="item in role_options"
              :key="item.id"
              :label="item.role_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 100%" label="备注">
          <el-input
            type="textarea"
            v-model="user.remark"
            :rows="3"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </el-scrollbar>
  <div slot="footer">
    <el-button type="primary" @click="submit">确定</el-button>
    <el-button @click="cancel">取消</el-button>
  </div>
</ex-dialog>
