<template>
  <el-dialog
    title="订单详情"
    v-dialogDrag
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="1400px"
  >
    <el-scrollbar
      style="height: 500px; margin-right: -20px"
      wrapStyle="padding-right:20px;overflow-x:hidden;"
      viewStyle
    >
      <el-form
        :model="dataForm"
        ref="dataForm"
        :rules="rules"
        label-width="110px"
      >
        <el-row>
          <p class="form-title">
            当前订单状态：{{ dataForm.order_status_name }}
            <el-button
              type="default"
              v-if="dataForm.order_status === 4"
              @click="showDeliveryMap(dataForm.id)"
              >实时配送位置</el-button
            >
          </p>
          <el-divider></el-divider>
        </el-row>
        <el-row class="block-header">
          <span class="block-title">基本信息</span>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="订单编号">
              <el-input v-model="dataForm.order_no" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单类型">
              <el-select
                v-model="dataForm.order_type"
                style="width: 100%"
                :disabled="true"
              >
                <el-option
                  v-for="item in orderTypeDim"
                  :key="item.data_value"
                  :value="item.data_value"
                  :label="item.data_name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预约时间" prop="reserveTime">
              <el-date-picker
                style="width: 100%"
                v-model="reserveTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="disabled"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="用户账号">
              <el-input v-model="dataForm.user_name" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户编号">
              <el-input
                v-model="dataForm.user_number"
                :disabled="true"
                maxlength="10"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式">
              <el-input v-model="dataForm.user_mobile" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="车牌号码">
              <el-input v-model="dataForm.car_no" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="服务地址">
              <el-input v-model="dataForm.full_address" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="车型名称">
              <el-input v-model="dataForm.car_name" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="下单时间">
              <el-date-picker
                v-model="dataForm.create_time"
                type="datetime"
                :disabled="true"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单完成时间">
              <el-date-picker
                v-model="dataForm.order_finish_time"
                type="datetime"
                :disabled="true"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="运维工单">
              <el-input
                v-model="dataForm.has_maintenance_name"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="备注">
              <el-input v-model.trim="dataForm.remark" :disabled="disabled" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <template>
              <div>
                <el-form-item label="摄像图片">
                  <div
                    class="demo-image__preview"
                    v-if="
                      dataForm.workplace_picture &&
                      dataForm.workplace_picture.length
                    "
                  >
                    <el-image
                      v-for="item in dataForm.workplace_picture"
                      :key="item"
                      style="width: 100px; height: 100px; margin-left: 10px"
                      :src="item"
                      :preview-src-list="dataForm.workplace_picture"
                    >
                    </el-image>
                  </div>
                </el-form-item>
              </div>
            </template>
          </el-col>
        </el-row>
        <el-row v-if="dataForm.has_maintenance_order" class="block-header">
          <span class="block-title">运维信息</span>
        </el-row>
        <el-row v-if="dataForm.has_maintenance_order">
          <el-col :span="8">
            <el-form-item label="审批人姓名">
              <el-input
                v-model="dataForm.maintenance_order.user_name"
                style="width: 180px"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="充电量(度)">
              <div class="flex">
                <el-input
                  v-model="dataForm.actual_charge"
                  style="width: 180px"
                  :disabled="true"
                />&nbsp;=&nbsp;
                <div
                  class="flex"
                  v-for="(item, index) in dataForm.maintenance_order
                    .actual_charge_list"
                  :key="index"
                >
                  <el-input v-model="item.value" :disabled="true" />&nbsp;
                  <div
                    v-if="
                      (!index &&
                        dataForm.maintenance_order.actual_charge_list.length >
                          1) ||
                      (index === 1 &&
                        dataForm.maintenance_order.actual_charge_list.length >
                          2)
                    "
                  >
                    +
                  </div>
                  &nbsp;
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="异常类型" prop="expected_charge">
              <el-input
                v-model="dataForm.maintenance_order.type"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dataForm.has_maintenance_order">
          <el-col :span="8">
            <el-form-item label="审批人手机号">
              <el-input
                v-model="dataForm.maintenance_order.cellphone"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="备注">
              <el-input
                v-model="dataForm.maintenance_order.remark"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="充电量图片">
              <div class="demo-image__preview">
                <el-image
                  v-for="(item, index) in dataForm.maintenance_order
                    .charge_picture_list"
                  :key="item"
                  style="width: 100px; height: 100px; margin-left: 10px"
                  :src="item"
                  :preview-src-list="
                    dataForm.maintenance_order.charge_picture_list
                  "
                >
                </el-image>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="block-header">
          <span class="block-title">服务信息</span>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="开始时间">
              <el-date-picker
                v-model="dataForm.service_start_time"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="true"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束时间">
              <el-date-picker
                v-model="dataForm.service_end_time"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="true"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预计充电" prop="expected_charge">
              <el-input
                v-model="dataForm.expected_charge"
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="配送员">
              <user-selector
                :disabled="disabled"
                v-model="dataForm.delivery_user_id"
                :userName.sync="dataForm.delivery_user_name"
              >
              </user-selector>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="配送开始时间">
              <el-date-picker
                v-model="dataForm.delivery_time"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="true"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="充电度数">
              <el-input v-model="dataForm.actual_charge" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="block-header">
          <span class="block-title">设备信息</span>
        </el-row>
        <el-row class="block-tool">
          <el-button @click="addEquipment" :disabled="disabled">添加</el-button>
          <el-button @click="removeEquipment" :disabled="disabled"
            >移除</el-button
          >
        </el-row>
        <el-row class="block-body">
          <el-table
            :data="equipmentTable"
            border
            @selection-change="rowSelected"
          >
            <el-table-column
              type="selection"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="equipment_no"
              label="设备编号"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="equipment_name"
              label="设备名称"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="equipment_type_name"
              label="设备类型"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="equipment_power"
              label="设备功率"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="equipment_capacity"
              label="容量（kWh）"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="battery_percentage"
              label="电量百分比"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="equipment_state_name"
              label="设备状态"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="equipment_org"
              label="归属网点"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  @click="editRow(scope.row)"
                  type="text"
                  size="small"
                  :disabled="disabled"
                  >更换
                </el-button>
                <el-button
                  @click="removeRow(scope.row)"
                  type="text"
                  size="small"
                  :disabled="disabled"
                >
                  移除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row
          class="block-header"
          v-if="
            user.isMaintenanceOrder == 1 ||
            user.isMaintenanceOrder == 3 ||
            user.isMaintenanceOrder == 4 ||
            user.isMaintenanceOrder == 5 ||
            user.isMaintenanceOrder == 6
          "
        >
          <span class="block-title">充电交易流水</span>
        </el-row>
        <el-row
          class="block-body"
          v-if="
            user.isMaintenanceOrder == 1 ||
            user.isMaintenanceOrder == 3 ||
            user.isMaintenanceOrder == 4 ||
            user.isMaintenanceOrder == 5 ||
            user.isMaintenanceOrder == 6
          "
        >
          <el-table :data="chargingTransactionFlowTable" border>
            <el-table-column
              prop="station_id"
              label="设备编号"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="equipment_name"
              label="设备名称"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="创建时间"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="start_time"
              label="充电开始时间"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="end_time"
              label="充电结束时间"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="total"
              label="充电电量"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="equipment_order_distance"
              label="设备距离订单距离（m）"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="equipment_state_name"
              label="状态"
              min-width="150"
              align="center"
            >
              <template slot-scope="scope">
                <p v-if="scope.row.stop_reason == 110">充电完成</p>
                <p
                  v-else-if="scope.row.status == '0' && !scope.row.stop_reason"
                >
                  充电中
                </p>
                <p v-else-if="scope.row.status == '0' && scope.row.stop_reason">
                  充电完成
                </p>
                <p v-else-if="scope.row.status == '1'">充电失败</p>
                <p v-else-if="scope.row.status == '2'">充电异常中止</p>
                <p v-else-if="scope.row.status == '3'">未知原因停止</p>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="block-header">
          <span class="block-title">费用明细</span>
        </el-row>
        <el-row class="block-body">
          <el-table :data="priceTable" border>
            <el-table-column
              label="电费单价（元）"
              min-width="150"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.electric_unit_price"
                  disabled
                  @change="computePrice"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="充电度数" min-width="150" align="center">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.actual_charge"
                  :disabled="true"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="电费总价（元）"
              min-width="150"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.electric_total_price"
                  :disabled="true"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="配送服务费（元）"
              min-width="150"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.distribution_price"
                  disabled
                  @change="computePrice"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="夜间服务费（元）"
              min-width="150"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.night_price"
                  disabled
                  @change="computePrice"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="订单金额（元）"
              min-width="150"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.order_price"
                  :disabled="true"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="用户开票金额（元）"
              min-width="150"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.real_pay_money"
                  :disabled="true"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="block-header">
          <span class="block-title">支付信息</span>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="支付状态" label-width="150px">
              <el-input v-model="dataForm.pay_flag_name" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="支付时间" :span="8">
              <el-date-picker
                v-model="dataForm.pay_time"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="true"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结算状态" :span="8" label-width="170px">
              <el-input v-model="dataForm.agent_settle_name" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="使用优惠券" label-width="150px">
              <el-input v-model="dataForm.coupon_name" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="优惠券补贴金额(元)" label-width="170px">
              <el-input v-model="dataForm.coupon_deduction_money" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <div style="display: flex">
              <el-form-item label="实时度数金额(元)" label-width="150px">
                <el-input v-model="dataForm.use_degree_real_money" disabled />
              </el-form-item>
              <!-- <div style="margin:10px 6px 0px 8px;">=</div>
                            <el-form-item label="实时度数单价(元/度)" label-width="138px">
                                <el-input v-model="dataForm.use_degree_unit_price" disabled />
                            </el-form-item>
                            <div style="margin: 9px 4px 0px 8px;">X</div>
                            <el-form-item label="数量(度)" label-width="64px">
                                <el-input v-model="dataForm.use_degree" disabled />
                            </el-form-item> -->
            </div>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账户余额支付(元)" label-width="170px">
              <el-input v-model="dataForm.actual_price" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="度数补贴合计(元)" label-width="150px">
              <el-input
                v-model="dataForm.use_degree_deduction_money"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="block-header">
          <span class="block-title">客户评价</span>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="配送服务">
              <el-rate
                v-model="dataForm.delivery_evaluation"
                readonly
                :disabled="true"
              ></el-rate>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="充电速度">
              <el-rate
                v-model="dataForm.charge_evaluation"
                readonly
                :disabled="true"
              ></el-rate>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="用户体验">
              <el-rate
                v-model="dataForm.experience_evaluation"
                readonly
                :disabled="true"
              ></el-rate>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="评价内容">
              <el-input
                type="textarea"
                :rows="3"
                v-model="dataForm.evaluation_content"
                resize="none"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-scrollbar>

    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">返回</el-button>
      <el-button type="primary" :disabled="disabled" @click="save"
        >保存</el-button
      >
    </div>
    <SelectEquipmentModel
      :dialog.sync="selectEquipmentVisible"
      :mode="selectEquipmentMode"
      @confirm="selectEquipment"
    />
    <DeliveryAmap v-if="deliveryAmapVisible" ref="deliveryAmap"></DeliveryAmap>
  </el-dialog>
</template>
<script>
import DIM_API from "@/api/dim_api";
import SelectEquipmentModel from "./select-equipment.vue";
import DeliveryAmap from "./delivery-amap.vue";
import { mapState } from "vuex";
export default {
  components: {
    SelectEquipmentModel,
    DeliveryAmap,
  },
  props: {
    dialog: {
      type: Boolean,
      default() {
        return false;
      },
    },
    id: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      dialogVisible: false,
      dataForm: {},
      orderStatusDim: [],
      orderTypeDim: [],
      equipmentTable: [],
      priceTable: [],
      selection: [],
      disabled: false,
      selectEquipmentVisible: false,
      deliveryAmapVisible: false,
      selectEquipmentRow: null,
      selectEquipmentMode: 1,
      reserveTime: [],
      rules: {
        reserveTime: [
          {
            validator: this.validateReserveTime,
            trigger: ["blur", "change"],
          },
        ],
        expected_charge: [
          {
            required: true,
            message: "请填写预计充电",
            trigger: "blur",
          },
          {
            validator: this.validateNumber,
            trigger: "blur",
          },
        ],
      },
      picDialogVisible: false,
      chargingTransactionFlowTable: [],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {},
  watch: {
    dialog: {
      immediate: true,
      handler(value) {
        this.dialogVisible = value;
        if (value) {
          this.init();
        }
      },
    },
    dialogVisible: {
      handler(value) {
        this.$emit("update:dialog", value);
      },
    },
  },
  methods: {
    async init() {
      this.clearForm();
      this.setFormDisabled();
      await this.initDim();
      this.loadDataForm();
    },
    setFormDisabled() {
      if (this.status == "view") {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    async initDim() {
      let res = await DIM_API.getByType("OrderStatus");
      this.orderStatusDim = res.data;
      res = await DIM_API.getByType("OrderType");
      this.orderTypeDim = res.data;
    },
    clearForm() {
      this.dataForm = {};
      this.equipmentTable = [];
      this.priceTable = [];
      this.reserveTime = [];
    },
    filterDataForm() {
      let item = this.dataForm;
      // item.reserve_time = item.reserve_start_time + ' - ' + item.reserve_end_time
      item.pay_flag_name = item.pay_flag
        ? "已支付"
        : item.order_status == 7
        ? "未支付"
        : "";
      item.recover_flag_name = item.has_equipment
        ? item.recover_flag
          ? "已回收"
          : "未回收"
        : "";
      switch (item.agent_settle_status) {
        case "1":
          item.agent_settle_name = "已结算";
          break;
        case "2":
          item.agent_settle_name = "测试单";
          break;
        case "0":
          if (item.order_status == "7") {
            item.agent_settle_name = "未结算";
          }
          break;
        default:
      }
      this.dataForm = item;
    },
    loadDataForm() {
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/order/" + this.id, { params: { is_pc: true } })
        .then((response) => {
          this.hideLoading();
          this.dataForm = response.data;
          this.filterDataForm();
          this.dataForm.full_address =
            response.data.address_title + " " + response.data.address;
          this.reserveTime = [
            this.dataForm.reserve_start_time,
            this.dataForm.reserve_end_time,
          ];
          this.equipmentTable = response.data.equipment_info_list;
          this.chargingTransactionFlowTable = response.data.transactions;
          if (this.dataForm.workplace_picture) {
            this.dataForm.workplace_picture =
              this.dataForm.workplace_picture.split(",");
          }
          // this.priceTable.push({
          //     electric_unit_price: response.data.electric_unit_price,
          //     actual_charge: response.data.actual_charge,
          //     electric_total_price: response.data.electric_total_price,
          //     distribution_price: response.data.distribution_price,
          //     night_price: response.data.night_price,
          //     order_price: response.data.order_price,
          // })
          this.priceTable.push(this.dataForm);
          //实际的充电度数通过电费总价/电费单价
          let unitPrice = isNaN(Number(this.dataForm.electric_unit_price))
            ? 0
            : Number(this.dataForm.electric_unit_price);
          if (unitPrice === 0) {
            this.dataForm.computed_charge = 0;
          } else {
            this.dataForm.computed_charge =
              (isNaN(Number(this.dataForm.electric_total_price))
                ? 0
                : Number(this.dataForm.electric_total_price)) / unitPrice;
          }
          this.dataForm.has_maintenance_name = this.dataForm
            .has_maintenance_order
            ? "是"
            : "否";
          if (this.dataForm.has_maintenance_order) {
            this.dataForm.maintenance_order.actual_charge_list =
              this.dataForm.maintenance_order.actual_charge_list.map(
                (item, index) => {
                  return { value: item };
                }
              );
          }
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("获取表单失败");
        });
    },
    cancel() {
      this.dialogVisible = false;
    },
    save() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (!this.validateForm()) {
            return;
          }
          if (this.dataForm.maintenance_order)
            delete this.dataForm.maintenance_order;
          let data = {
            ...this.dataForm,
            reserve_start_time: this.reserveTime[0],
            reserve_end_time: this.reserveTime[1],
          };
          data.equipment_info_list = this.equipmentTable;
          this.showLoading("提交中");
          this.axiosHelper
            .post("/api/order/update", data)
            .then((response) => {
              this.hideLoading();
              this.dialogVisible = false;
              this.$emit("search");
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("保存失败");
            });
        } else {
          this.$message({
            message: "请将表单填写完整",
            type: "error",
          });
          //错误位置定位
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].querySelector("input").focus();
          }, 100);
          return false;
        }
      });
    },
    addEquipment() {
      this.selectEquipmentRow = null;
      this.selectEquipmentMode = 2;
      this.selectEquipmentVisible = true;
    },
    removeEquipment() {
      if (!this.selection || this.selection.length === 0) {
        this.$message.warning("请先选择设备");
        return;
      }
      this.$confirm("确认移除所选设备？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.selection.map((a) => a.id);
        this.equipmentTable = this.equipmentTable.filter(
          (val) => !ids.includes(val.id)
        );
      });
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    editRow(row) {
      this.selectEquipmentRow = row;
      this.selectEquipmentMode = 1;
      this.selectEquipmentVisible = true;
    },
    removeRow(row) {
      this.$confirm("确认移除所选设备？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let index = this.equipmentTable.findIndex((val) => val.id === row.id);
        this.equipmentTable.splice(index, 1);
      });
    },
    selectEquipment(data) {
      if (!this.selectEquipmentRow) {
        for (let item of data) {
          let tmp = this.equipmentTable.find((val) => val.id === item.id);
          if (!tmp) {
            this.equipmentTable.push(item);
          }
        }
      } else {
        Object.assign(this.selectEquipmentRow, data);
      }
    },
    validateNumber(rule, value, callback) {
      var reg = /^(([1-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/;
      if (!reg.test(value)) {
        callback(new Error("数值限制整数最大8位，小数2位"));
      } else {
        callback();
      }
    },
    computePrice() {
      let unitPrice = isNaN(Number(this.dataForm.electric_unit_price))
        ? 0
        : Number(Number(this.dataForm.electric_unit_price).toFixed(2));
      this.dataForm.electric_total_price = Number(
        (this.dataForm.computed_charge * unitPrice).toFixed(2)
      );
      let distributionPrice = isNaN(Number(this.dataForm.distribution_price))
        ? 0
        : Number(Number(this.dataForm.distribution_price).toFixed(2));
      let nightPrice = isNaN(Number(this.dataForm.night_price))
        ? 0
        : Number(Number(this.dataForm.night_price).toFixed(2));
      this.dataForm.order_price = Number(
        (
          this.dataForm.electric_total_price +
          distributionPrice +
          nightPrice
        ).toFixed(2)
      );
      this.dataForm.electric_unit_price = unitPrice;
      this.dataForm.distribution_price = distributionPrice;
      this.dataForm.night_price = nightPrice;
    },
    validateForm() {
      var reg = /^(([1-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/;
      if (!reg.test(this.dataForm.electric_unit_price)) {
        this.$message.warning("电费单价数值限制整数最大8位，小数2位");
        return false;
      }

      if (!reg.test(this.dataForm.electric_total_price)) {
        this.$message.warning("电费总价数值限制整数最大8位，小数2位");
        return false;
      }

      if (!reg.test(this.dataForm.distribution_price)) {
        this.$message.warning("配送服务费数值限制整数最大8位，小数2位");
        return false;
      }

      if (!reg.test(this.dataForm.night_price)) {
        this.$message.warning("夜间服务费数值限制整数最大8位，小数2位");
        return false;
      }

      if (!reg.test(this.dataForm.order_price)) {
        this.$message.warning("总金额数值限制整数最大8位，小数2位");
        return false;
      }

      return true;
    },
    validateReserveTime(rule, value, callback) {
      if (!this.reserveTime || this.reserveTime.length == 0) {
        callback(new Error("请填写预约时间"));
      } else {
        callback();
      }
    },
    //显示配送地图
    showDeliveryMap(id) {
      this.deliveryAmapVisible = true;
      this.$nextTick(() => {
        this.$refs.deliveryAmap.init(id);
      });
    },
    handleImageClick() {
      this.picDialogVisible = true;
    },
  },
};
</script>

<style scoped>
.form-title {
  font-size: 16px;
  color: red;
  margin: 15px 20px 25px 20px;
}

.block-body {
  margin-bottom: 15px;
}

.flex {
  display: flex;
}

::v-deep .el-rate__icon {
  font-size: 24px;
}
</style>