
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="手机号码：">
        <el-input
          v-model.trim="search_value.cellphone"
          placeholder=""
          clearable
        />
      </el-form-item>
      <el-form-item label="用户编号：">
        <el-input
          v-model.trim="search_value.userNumber"
          placeholder=""
          clearable
          maxlength="10"
          oninput="value=value.replace(/[^0-9]/g,'')"
        />
      </el-form-item>
      <el-form-item label="微信昵称：">
        <el-input
          v-model.trim="search_value.realName"
          placeholder=""
          clearable
        />
      </el-form-item>
      <el-form-item label="城市：">
        <el-select
          v-model="search_value.firstCityCode"
          placeholder="全国"
          multiple
          collapse-tags
          clearable
          filterable
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否黑名单：">
        <el-select
          v-model="search_value.blacklist"
          collapse-tags
          clearable
          @clear="search_value.blacklist = null"
        >
          <el-option
            v-for="item in if_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否脱敏：" v-if="isShowBtn">
        <el-select
          v-model="search_value.desensi"
          collapse-tags
          clearable
          @clear="search_value.desensi = null"
        >
          <el-option
            v-for="item in if_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户类型：">
        <el-select
          v-model="search_value.userCategory"
          collapse-tags
          clearable
          @clear="search_value.userCategory = null"
          @change="userCategoryChange"
        >
          <el-option
            v-for="item in user_categorys"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="B端类型：">
        <el-select
          v-model="search_value.unPayNumLimit"
          collapse-tags
          clearable
          @clear="search_value.unPayNumLimit = null"
        >
          <el-option
            v-for="item in un_pay_num_limits"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册时间：">
        <el-date-picker
          v-model="createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          align="center"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="注册推荐人类型：">
        <el-select
          v-model="search_value.recommendType"
          collapse-tags
          clearable
          @clear="search_value.recommendType = null"
        >
          <el-option
            v-for="item in recommend_type_list"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推荐人：">
        <el-input
          v-model.trim="search_value.referenceName"
          placeholder=""
          clearable
        />
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="init"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <!-- <el-button
        class="rewrite-button-style"
        size="small"
        @click="addUser"
        v-if="isShowBtn"
        >新增</el-button
      > -->
      <!-- <el-button class="rewrite-button-style" size="small" @click="deleteUser(multiple_selection)" v-if="isShowBtn">删除</el-button> -->
      <el-button
        class="rewrite-button-style"
        size="small"
        @click="resetPassword"
        v-if="isShowBtn"
        >重置密码</el-button
      >
      <el-button class="rewrite-button-style" size="small" @click="exportData"
        >导出</el-button
      >
    </el-row>

    <el-table
      :data="table_data"
      max-height="80vh"
      height="70vh"
      border
      header-align="center"
      element-loading-customClass="personal-loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(204, 204, 204, 0.6)"
      class="refTable"
      ref="refTable"
      default-expand-all
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
      :cell-style="cell_style"
      :style="table_style"
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
        fixed="left"
      ></el-table-column>
      <el-table-column type="index" width="50" label="序号"></el-table-column>
      <el-table-column
        prop="first_city_name"
        label="城市"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="手机号码"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="real_name"
        label="微信昵称"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="un_pay_num_limit"
        label="用户类型"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.user_category == 1">C端用户</div>
          <div v-else>B端用户</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="un_pay_num_limit"
        label="是否允许连续下单"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.user_category == 1 ||
              (scope.row.un_pay_num_limit == 1 &&
                scope.row.user_category != 1)
            "
          >
            否
          </div>
          <div v-else>是</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="注册时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column prop="sex" label="性别" min-width="150" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.sex == '1'">男</div>
          <div v-else-if="scope.row.sex == '2'">女</div>
          <div v-else>保密</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="recommend_type_name"
        label="注册推荐人类型"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reference_name"
        label="推荐人"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="blacklist"
        label="是否黑名单"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.blacklist == 1">是</div>
          <div v-else>否</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="desensi"
        label="是否脱敏"
        min-width="150"
        align="center"
        v-if="isShowBtn"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.desensi == 1">是</div>
          <div v-else>否</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="250"
        align="center"
        v-if="isShowBtn"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isShowCityBtn"
            type="primary"
            size="small"
            @click="openCityConfigDialog(scope.row)"
            >城市配置</el-button
          >
          <el-button type="primary" size="small" @click="editUser(scope.row)"
            >编辑</el-button
          >
          <!-- <el-button type="danger" size="small" @click="deleteUser([scope.row])">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current_page"
        small
        :page-sizes="[10, 20, 50, 100, 500]"
        :page-size="page_size"
        layout="total, sizes, prev, pager, next"
        :total="total_count"
      ></el-pagination>
    </el-footer>
  </el-card>

  <!-- <ex-dialog
    :title="title"
    :visible.sync="dialog_visible"
    v-if="dialog_visible"
    :width="900"
    :height="500"
    :show-fullscreen-icon="true"
    :append-to-body="true"
  >
    <el-scrollbar
      style="height: 100%; margin-right: -20px"
      wrapStyle="overflow-x:hidden;padding-right:20px;"
      viewStyle=""
    >
      <user-edit
        :action="action"
        :user_="select_user"
        @close="closeDialog"
        @refresh="refresh"
      ></user-edit>
    </el-scrollbar>
    <div slot="footer">
      <el-button type="primary" @click="submit">确定</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
  </ex-dialog> -->
  <user-edit
    :title="title"
    v-if="dialog_visible"
    :action="action"
    :user_="select_user"
    @close="closeDialog"
    @refresh="refresh"
  ></user-edit>
  <el-dialog
    title="城市信息"
    :visible.sync="cityConfigDialogVisible"
    width="30%"
  >
    <el-form>
      <el-form-item label="城市：">
        <el-checkbox-group
          v-model="form.city_codes"
          :disabled="form.restricted_city == 0"
        >
          <el-checkbox
            v-for="city in cityList"
            :key="city.cityCode"
            :label="city.cityCode"
            >{{ city.cityName }}</el-checkbox
          >
        </el-checkbox-group></el-form-item
      >
      <el-form-item label="是否受限城市：">
        <el-radio-group v-model="form.restricted_city">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cityConfigDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveCityConfig">确 定</el-button>
    </span>
  </el-dialog>
</div>
