
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <!--            <div slot="header" class="clearfix">
              <span>数据筛选</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="filter">高级查询</el-button>
          </div>-->
    <div class="text item">
      <el-form
        ref="form"
        style="padding-right: 10px"
        :model="form"
        label-width="110px"
      >
        <el-row>
          <el-col :span="4">
            <el-form-item label="投资预算：">
              <el-select
                v-model="form.investmentBudgetId"
                placeholder="全部"
                clearable
              >
                <el-option
                  v-for="item in budgetList"
                  :key="item.id"
                  :label="item.data_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="手机号：">
              <el-input
                v-model.trim="form.partnerTel"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="状态：">
              <el-select v-model="form.state" placeholder="全部" clearable>
                <el-option label="全部" :value="-1"></el-option>
                <el-option label="未联系" :value="0"></el-option>
                <el-option label="已联系" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col class="elCol" :span="7">
            <el-form-item label="创建日期：" label-width="100px">
              <el-date-picker
                v-model="createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                style="width: auto"
                align="center"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="3" style="float: right; text-align: right">
            <el-button type="primary" @click="clickSearch">查询</el-button>
            <el-button type="default" @click="clear">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>招商信息</span>
      <el-button style="float: right" @click="exportOrder">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
      @selection-change="rowSelected"
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column label="序号" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="partner_name"
        label="姓名"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="partner_tel"
        label="手机号"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="province_name"
        label="意向城市"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.province_name +
              scope.row.city_name +
              scope.row.area_name
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="investment_budget_name"
        label="投资预算"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="state"
        label="状态"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.state == "0" ? "未联系" : " 已联系" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handleOperation(scope.row, 'edit')"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            @click="handleOperation(scope.row, 'del')"
            type="text"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="page_total"
        :current-page.sync="page_index"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <partnerMessageEditModal ref="editModal" @refreshDataList="loadDataTable" />
</div>
