<template>
  <div class="page">
    <div class="city">
      <div style="display: flex">
        <div class="city_item">
          <div class="city_item-label">城市：</div>
          <el-select
            style="width: 150px"
            v-model="cityCode"
            placeholder="全国"
            filterable
            @change="getCityArea"
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </div>
        <div class="city_item">
          <div class="city_item-label">代理商：</div>
          <div class="city_item-text">{{ cityAgent }}</div>
        </div>
      </div>
      <div class="city-tab">
        <div
          class="city-tab-item"
          :class="{ active: type === '1' }"
          @click="switchType('1')"
        >
          上门充电
        </div>
        <div
          class="city-tab-item"
          :class="{ active: type === '2' }"
          @click="switchType('2')"
        >
          应急补电
        </div>
      </div>
      <div
        class="city_title"
        :style="{ color: titleShow ? '#fe5e00' : '#fff' }"
      >
        双击即可结束绘制！
      </div>
    </div>
    <div class="map_box" id="map-container">
      <div class="map_box-main">
        <div class="map_box-map" ref="amap" id="amap"></div>
        <div class="map_box-btnList">
          <el-button
            class="map_box-btnList--btn"
            @click="createPolygon"
            :disabled="titleShow"
            >新建多边形</el-button
          >
          <el-button
            v-if="hasPolygon"
            class="map_box-btnList--btn"
            @click="startEdit"
            >开始编辑</el-button
          >
          <el-button
            v-if="hasPolygon"
            class="map_box-btnList--btn"
            @click="endEdit"
            >结束编辑</el-button
          >
          <el-button
            v-if="selectedPolygon"
            class="map_box-btnList--btn"
            @click="deletePolygon"
            >删除多边形</el-button
          >
          <el-button
            v-if="hasPolygon"
            class="map_box-btnList--btn"
            @click="clearPolygon"
            >清空</el-button
          >
          <el-button class="map_box-btnList--btn" @click="confirm"
            >确认</el-button
          >
        </div>
      </div>

      <!-- <div class="map_box-type">
        <div>
          <el-button
            :type="type === '1' ? 'primary' : ''"
            @click="switchType('1')"
            style="width: 150px"
            >上门充电</el-button
          >
        </div>
        <div style="margin-top: 20px">
          <el-button
            :type="type === '2' ? 'primary' : ''"
            @click="switchType('2')"
            style="width: 150px"
            >应急补电</el-button
          >
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "@/utils/axiosHelper";
import { mapState } from "vuex";
export default {
  data() {
    return {
      cityName: "",
      cityAgent: "",
      map: null,
      polygonEditor: null,
      areaRangeConfig: [],
      coordList: [],
      isDrawing: false,
      hasPolygon: false,
      titleShow: false,
      position: [],
      id: "",
      cityCode: "",
      polygons: [], // 新增：存储多个多边形对象
      selectedPolygon: null, // 新增：记录当前选中的多边形
      type: "1",
    };
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  async mounted() {
    if (this.restrictedCityList.length > 0) {
      this.cityCode = this.restrictedCityList[0].cityCode;
      this.getCityArea();
    }
  },
  methods: {
    async switchType(type) {
      this.type = type;
      await this.init();
      await this.initializeMap();
    },
    async getCityArea() {
      this.type = "1";
      await this.init();
      await this.initializeMap();
    },
    async init() {
      this.map = null;
      this.polygons = [];
      this.selectedPolygon = null;
      this.showLoading("加载中");
      await this.axiosHelper
        .get(`/api/config/area/get?cityCode=${this.cityCode}`, {})
        .then((res) => {
          if (this.type === "1") {
            this.areaRangeConfig = res.data.data.area_range_config || [];
          } else {
            this.areaRangeConfig =
              res.data.data.emergency_area_range_config || [];
          }
          this.position = [
            res.data.data.agent_longitude,
            res.data.data.agent_latitude,
          ];
          console.log(this.position);
          this.id = res.data.data.id;
          this.hasPolygon = this.areaRangeConfig.some(
            (data) => data.paths.length > 0
          );
          console.log(this.hasPolygon);
          this.cityName = res.data.data.city_name;
          this.cityAgent = res.data.data.agent_name;
        })
        .catch((res) => {
          this.hideLoading();
        });
    },
    handleDialogOpened() {
      if (this.map) {
        setTimeout(() => {
          this.map.setFitView();
        }, 100); // 确保对话框完全渲染后调用
      }
    },
    initializeMap() {
      if (!this.map) {
        this.map = new AMap.Map(this.$refs.amap, {
          center: this.position, // 北京天安门坐标
          zoom: 15,
        });
        // 添加标识点
        const marker = new AMap.Marker({
          position: this.position,
          map: this.map,
        });
        this.map.plugin(["AMap.ToolBar", "AMap.PolygonEditor"], () => {
          this.map.addControl(new AMap.ToolBar());
        });

        // 根据初始化的多边形数据添加多边形
        if (this.areaRangeConfig.length) {
          this.areaRangeConfig.forEach((data) => {
            this.addPolygon(data);
          });
        } else {
          this.addPolygon();
        }
      } else {
        this.map.setFitView(); // 如果地图已经存在，重新调整视图
      }
      this.hideLoading();
    },
    addPolygon(data) {
      // 已设置配送范围区域时
      if (data) {
        const polygon1 = new AMap.Polygon({
          path: data ? data.paths : [],
        });
        this.polygonEditor = new AMap.PolygonEditor(this.map, polygon1);
        this.map.add([polygon1]);
        polygon1.on("dblclick", () => {
          this.selectedPolygon = polygon1; // 新增：设置选中的多边形
          this.polygonEditor.setTarget(polygon1);
          this.polygonEditor.open();
          this.titleShow = false;
        });
        this.hasPolygon = data.paths && data.paths.length ? true : false;
        this.polygons.push(polygon1); // 确保多边形添加到数组中
        return;
      }
      // 未配置范围区域时
      this.polygonEditor = new AMap.PolygonEditor(this.map);
      this.polygonEditor.on("add", (item) => {
        this.titleShow = false;
        this.coordList = item.lnglat;
        const polygon = item.target;
        polygon.on("dblclick", () => {
          this.selectedPolygon = polygon; // 新增：设置选中的多边形
          this.polygonEditor.setTarget(polygon);
          this.polygonEditor.open();
        });
        this.polygons.push(polygon); // 确保多边形添加到数组中
      });
    },
    createPolygon() {
      this.titleShow = true;
      this.polygonEditor = new AMap.PolygonEditor(this.map); // 确保每次绘制新多边形时正确初始化 polygonEditor
      this.polygonEditor.on("add", (item) => {
        this.titleShow = false;
        this.coordList = item.lnglat;
        const polygon = item.target;
        polygon.on("dblclick", () => {
          this.polygonEditor.setTarget(polygon);
          this.polygonEditor.open();
        });
        polygon.on("click", () => {
          this.selectPolygon(polygon);
        });
        this.polygons.push(polygon); // 确保新绘制的多边形添加到数组中
        this.hasPolygon = this.polygons.some(
          (polygon) => polygon.getPath().length > 0
        );
      });
      this.polygonEditor.open(); // 确保 polygonEditor 打开
    },
    selectPolygon(polygon) {
      if (this.selectedPolygon) {
        this.selectedPolygon.setOptions({
          strokeColor: "#FF33FF",
          fillColor: "#1791fc",
        });
      }
      polygon.setOptions({
        strokeColor: "#FF0000",
        fillColor: "#FF99FF",
      });
      this.selectedPolygon = polygon;
      console.log(this.polygons);
    },
    startEdit() {
      if (this.polygonEditor) {
        this.polygonEditor.open();
      }
    },
    endEdit() {
      let mapList;
      if (this.polygonEditor) {
        this.polygonEditor.on("end", (event) => {
          this.coordList = event.target.getPath();
          mapList = this.coordList
            ? this.coordList.map((v) => [v.lng, v.lat])
            : [];
        });
        this.polygonEditor.close();
        this.selectedPolygon = null;
      }
      return mapList ? mapList : [];
    },
    deletePolygon() {
      // 新增：删除选中的多边形
      if (this.selectedPolygon) {
        this.map.remove(this.selectedPolygon);
        this.polygons = this.polygons.filter(
          (polygon) => polygon !== this.selectedPolygon
        );
        this.selectedPolygon = null;
        this.hasPolygon = this.polygons.length > 0;
        this.polygonEditor.close();
      }
    },
    async confirm() {
      let data = this.polygons.map((polygon) => ({
        paths: polygon.getPath().map((v) => [v.lng, v.lat]),
        type: 1,
      }));
      data = data.filter((item) => item.paths.length);
      console.log(data);
      if (!data.length) data = [];
      let api = "";
      if (this.type === "1") {
        api = `/api/config/area/range/config/set/${this.id}`;
      } else {
        api = `/api/config/emergency/area/range/set/${this.id}`;
      }
      // 在这里添加确认逻辑
      this.axiosHelper
        .put(api, data)
        .then((res) => {
          this.$message.success("修改成功");
          // if (data.length) {
          //   this.$message.success("修改成功");
          // } else {
          //   this.$message({
          //     message: "绘制失败",
          //     type: "error",
          //   });
          // }
          this.$emit("getList");
        })
        .catch((error) => {
          this.$message.success(error.msg);
        });
    },
    clearPolygon() {
      if (this.polygonEditor) {
        this.titleShow = false;
        this.polygonEditor.close();
        this.polygonEditor = null;
        this.coordList = [];
        this.polygons = [];
        // 清空地图上的所有覆盖物
        this.map.clearMap();
        // 添加标识点
        const marker = new AMap.Marker({
          position: this.position,
          map: this.map,
        });
        // 未配置范围区域时
        this.polygonEditor = new AMap.PolygonEditor(this.map);
        this.polygonEditor.on("add", (item) => {
          this.titleShow = false;
          this.coordList = item.lnglat;
          const polygon = item.target;
          polygon.on("dblclick", () => {
            this.polygonEditor.setTarget(polygon);
            this.polygonEditor.open();
          });
          this.polygons.push(polygon); // 新增：将多边形添加到数组中
        });
        this.hasPolygon = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#map-container {
  position: relative;
  display: flex;
}

.map {
  &_box {
    width: 100%;
    height: calc(100% - 64px);
    .map_box-main {
      position: relative;
      width: 100%;
      height: 100%;
    }
    &-map {
      width: 100%;
      height: 100%;
    }

    &-btnList {
      display: flex;
      justify-content: space-around;
      margin-left: 10px;
      position: absolute;
      bottom: 50px;
      z-index: 10;
      left: 0;
      right: 0;

      &--btn {
        width: 120px;
        height: 40px;
        border: 1px solid #5a8bff;
        color: #fff;
        background-color: #5a8bff;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        box-sizing: border-box;
        margin-top: 20px;
      }
    }
    &-type {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }
  }
}

.page {
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  .city {
    width: 100%;
    padding: 20px 120px 20px 0;
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;

    &_item {
      display: flex;
      margin-right: 20px;

      &-label {
      }

      &-text {
      }
    }

    &_title {
      box-sizing: border-box;
      font-size: 16px;
      color: #fe5e00;
      font-weight: bold;
    }
  }
}
.city-tab {
  display: flex;
  width: 250px;
  height: 40px;
  background: #f1f1f1;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  margin-left: -125px;
  .city-tab-item {
    border-radius: 20px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333333;
    height: 40px;
    cursor: pointer;
    &.active {
      background: #5a8bff;
      color: #fff;
    }
  }
}
.footer {
}
</style>