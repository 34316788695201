
<div style="height: 100%">
  <base-table
    ref="choose-table"
    :data="tableData"
    showIndex
    :height="height"
    :columns="tableColumns"
    :totalCount="totalCount"
    @page-change="pageChange"
  ></base-table>
</div>
