<template>
  <el-dialog
    title="修改密码"
    v-dialogDrag
    :visible.sync="changePassword"
    width="20%"
    :close-on-click-modal="false"
    v-if="changePassword"
  >
    <el-form
      ref="form"
      :model="password"
      label-width="100px"
      :rules="rules"
      label-position="left"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item
            label="旧密码:"
            prop="oldPassword"
          >
            <el-input
              v-model.trim="password.oldPassword"
              autocomplete="off"
              show-password
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item
            label="新密码:"
            prop="password1"
          >
            <el-input
              v-model.trim="password.password1"
              autocomplete="off"
              show-password
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item
            label="确认新密码:"
            prop="password2"
          >
            <el-input
              v-model.trim="password.password2"
              autocomplete="off"
              show-password
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-button
            type="primary"
            @click="onSubmit"
            style="float: right"
            :disabled="updateDisabled"
          >确定修改</el-button>
        </el-col>
        <el-col
          :span="12"
          style="text-align:center"
        >
          <el-button
            type="primary"
            @click="changePassword = false"
          >取消</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
  import Cookies from 'js-cookie'
  import { checkPassword, loginOut } from 'api/system/user-api.js'
  export default {
    name: 'changePassword',
    methods: {
      init () {
        this.changePassword = true
        this.currentUser = this.$store.state.user;
        if (this.currentUser == null) {
          this.currentUser = JSON.parse(Cookies.get('userInfo'))
        }      
      },
      async onSubmit () {
        if (this.password.password1 !== this.password.password2) {
          this.$message.warning({
            message: '两次密码输入不一致，请确认'
          })
          return;
        }
        let params = {}
        params.oldPwd = this.password.oldPassword
        params.pwd = this.password.password1
        //禁用修改按钮
        this.updateDisabled = true;
        //判断旧密码是否为正确
        var data = await checkPassword(params);
        if (data == 0) {
          this.updateDisabled = false;
          this.$message.warning({
            message: '旧密码错误'
          })
          return
        }
        this.$message.success({
          message: '修改成功，请重新登录'
        })
        this.changePassword = false;
        this.updateDisabled = false;
        await loginOut(this.currentUser.uid);
        Cookies.remove("userToken")
        Cookies.remove("userInfo")
        this.$store.commit("setUser", null)
        this.$router.push('/');
      },
    },
    data () {
      var oldPasswordCheck = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('旧密码不能为空'));
        }
      };
      var passwordCheck = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('新密码不能为空'));
        }
      };
      var passwordCheck2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('确认密码不能为空'));
        }
      };
      return {
        updateDisabled: false,
        currentUser: null,
        password: {
          oldPassword: "",
          password1: "",
          password2: ""
        },
        changePassword: false,
        // 表单校验规则
        rules: {
          oldPassword: [
            { required: true, validator: oldPasswordCheck, trigger: 'blur' },
            { max: 20, message: '长度不能超过20个字符', trigger: 'blur' }
          ],
          password1: [
            { required: true, validator: passwordCheck, trigger: 'blur' },
            { max: 20, message: '长度不能超过20个字符', trigger: 'blur' }
          ],
          password2: [
            { required: true, validator: passwordCheck2, trigger: 'blur' },
            { max: 20, message: '长度不能超过20个字符', trigger: 'blur' }
          ],
        },
      }
    },
    mounted () {
      //this.getCurrentUser();

    }
  }
</script>
<style scoped>
</style>