<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <!--多条件查询表单-->
      <el-form :inline="true">
        <el-form-item label="城市：">
          <el-select v-model="form.firstCityCode" placeholder="全国" clearable>
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input
            v-model.trim="form.cellphone"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="用户编号：">
          <el-input
            v-model.trim="form.userNumber"
            placeholder="请输入"
            clearable
            maxlength="10"
            oninput="value=value.replace(/[^0-9]/g,'')"
          />
        </el-form-item>
        <el-form-item label="注册日期：">
          <el-date-picker
            v-model="createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            align="center"
            style="width: 250px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="录入状态：">
          <el-select v-model="form.inputStatus" placeholder="请选择" clearable>
            <el-option :value="1" label="已录入" />
            <el-option :value="0" label="待录入" />
          </el-select>
        </el-form-item>
        <el-form-item label="车价：">
          <el-select v-model="form.carPrice" placeholder="请选择" clearable>
            <el-option
              v-for="item in carPriceDim"
              :key="item.data_value"
              :value="item.data_value"
              :label="item.data_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄：">
          <el-select v-model="form.ageGroup" placeholder="请选择" clearable>
            <el-option
              v-for="item in ageGroupDim"
              :key="item.data_value"
              :value="item.data_value"
              :label="item.data_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别：">
          <el-select v-model="form.sex" placeholder="请选择" clearable>
            <el-option :value="1" label="男" />
            <el-option :value="2" label="女" />
          </el-select>
        </el-form-item>
        <el-form-item label="车辆属性：">
          <el-select
            v-model="form.car_attribute"
            placeholder="请选择"
            clearable
          >
            <el-option :value="1" label="客运车" />
            <el-option :value="2" label="私家车" />
            <el-option :value="2" label="货运车" />
          </el-select>
        </el-form-item>
        <el-form-item label="原因：">
          <el-select
            v-model="form.unOrderReason"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in unOrderReasonDim"
              :key="item.data_value"
              :value="item.data_value"
              :label="item.data_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结论：">
          <el-select v-model="form.followResult" placeholder="请选择" clearable>
            <el-option
              v-for="item in followResultDim"
              :key="item.data_value"
              :value="item.data_value"
              :label="item.data_name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
      <!--
      <div style="text-align: center" >
        <p v-if="showFlag" style="display: block;">这是要显示的内容</p>
        <el-button @click="toggle">更多</el-button>
      </div>-->
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <el-button style="float: right" @click="exportData">导出</el-button>
      </el-row>
      <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
        <el-table-column
          prop="fullname"
          label="城市"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="客户手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="register_time"
          label="注册日期"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="car_price_name"
          label="车价"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="delivery_user_name"
          label="性别"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.sex === 1">男</span>
            <span v-if="scope.row.sex === 2">女</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="age_group_name"
          label="年龄段"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="car_attribute_name"
          label="车辆属性"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="un_order_reason_name"
          label="未下单原因"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="follow_result_name"
          label="回访结论"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remarks"
          label="备注"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="250" align="center">
          <template slot-scope="scope">
            <el-button
              @click="addOrUpdateHandle(scope.row)"
              type="text"
              size="small"
              >{{
                scope.row.input_status === true ? "修改" : "待录入"
              }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <UnorderUserSave
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="search"
      :carPriceDim="carPriceDim"
      :ageGroupDim="ageGroupDim"
      :unOrderReasonDim="unOrderReasonDim"
      :followResultDim="followResultDim"
    ></UnorderUserSave>
  </div>
</template>
<script>
import DIM_API from "@/api/dim_api";
import OPERATE_API from "@/api/operate_api";
import { operateAdd } from "@/api/system/user-api";
import UnorderUserSave from "./unorder-user-save.vue";
import * as XLSX from "xlsx";
import { mapState } from "vuex";

export default {
  components: {
    UnorderUserSave,
  },
  data() {
    return {
      form: {},
      createTime: [],
      multiQuery: {},
      carPriceDim: [], //车价
      ageGroupDim: [], //年龄段
      unOrderReasonDim: [], //未下单原因
      followResultDim: [], //回访结论

      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      addOrUpdateVisible: false,
      showFlag: false, // 初始化为不显示
    };
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  mounted() {
    // 初始化默认时间
    this.defaultDate();
    this.initDim();
    this.search();
  },
  methods: {
    filter() {
      this.filterVisible = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    defaultDate() {
      const end = new Date();
      const start = new Date();
      const dayOfThisMon = end.getDate(); // 获取当前日（1-31）
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      this.createTime = [start, end]; //将值设置给插件绑定的数据
    },
    //加载数据字典
    async initDim() {
      let res = await DIM_API.getByTypes(
        "CarPrice,AgeGroup,UnOrderReason,FollowResult"
      );
      if (res.data) {
        console.log(res.data);
        res.data.forEach((item, index) => {
          if ("CarPrice" == item.data_type) {
            this.carPriceDim.push(item);
          } else if ("AgeGroup" == item.data_type) {
            this.ageGroupDim.push(item);
          } else if ("UnOrderReason" == item.data_type) {
            this.unOrderReasonDim.push(item);
          } else if ("FollowResult" == item.data_type) {
            this.followResultDim.push(item);
          }
        });
      }
    },

    async search() {
      if (this.createTime) {
        this.form.registerTimeStar = this.createTime[0];
        this.form.registerTimeEnd = this.createTime[1];
      } else {
        this.form.registerTimeStar = null;
        this.form.registerTimeEnd = null;
      }
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {};
      this.defaultDate();
      this.page_index = 1;
      this.loadDataTable();
      // this.defaultDate();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    // 新增 / 修改
    addOrUpdateHandle(row) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(row);
      });
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        ...this.form,
        ...this.multiQuery,
        source: "pc",
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/un/order/extend/select/" + offset + "/" + limit, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.items;
          this.page_total = response.data.total;
          this.$nextTick(() => {
            this.$refs["dataTable"].doLayout();
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 7,
        operate_parameter: JSON.stringify({
          ...this.form,
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        ...this.form,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/un/order/extend/export", {
          params: params,
        })
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.items);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "未下单客户分析.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["区域名称"] = item.fullname;
        newObj["用户编号"] = item.user_number;
        newObj["客户手机号"] = item.cellphone;
        newObj["注册日期"] = item.register_time;
        newObj["车价"] = item.car_price_name;
        newObj["性别"] = item.sex;
        newObj["年龄段"] = item.age_group_name;
        newObj["车辆属性"] = item.car_attribute_name;
        newObj["未下单原因"] = item.un_order_reason_name;
        newObj["回访结论"] = item.follow_result_name;
        newObj["备注"] = item.remarks;
        newArray.push(newObj);
      });
      return newArray;
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    filterSearch(data) {
      this.multiQuery = data;
      this.page_index = 1;
      this.loadDataTable();
    },
    toggle() {
      this.showFlag = !this.showFlag; // 每次点击按钮都反转showFlag的值
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
</style>