<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <!--            <div slot="header" class="clearfix">
                <span>数据筛选</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="filter">高级查询</el-button>
            </div>-->
      <div class="text item">
        <el-form
          ref="form"
          style="padding-right: 10px"
          :model="form"
          label-width="110px"
        >
          <el-row>
            <el-col :span="4">
              <el-form-item label="投资预算：">
                <el-select
                  v-model="form.investmentBudgetId"
                  placeholder="全部"
                  clearable
                >
                  <el-option
                    v-for="item in budgetList"
                    :key="item.id"
                    :label="item.data_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="手机号：">
                <el-input
                  v-model.trim="form.partnerTel"
                  placeholder="请输入"
                  clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="状态：">
                <el-select v-model="form.state" placeholder="全部" clearable>
                  <el-option label="全部" :value="-1"></el-option>
                  <el-option label="未联系" :value="0"></el-option>
                  <el-option label="已联系" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="elCol" :span="7">
              <el-form-item label="创建日期：" label-width="100px">
                <el-date-picker
                  v-model="createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  style="width: auto"
                  align="center"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="3" style="float: right; text-align: right">
              <el-button type="primary" @click="clickSearch">查询</el-button>
              <el-button type="default" @click="clear">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>招商信息</span>
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
        @selection-change="rowSelected"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column label="序号" min-width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="partner_name"
          label="姓名"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="partner_tel"
          label="手机号"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="province_name"
          label="意向城市"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.province_name +
                scope.row.city_name +
                scope.row.area_name
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="investment_budget_name"
          label="投资预算"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="state"
          label="状态"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.state == "0" ? "未联系" : " 已联系" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              @click="handleOperation(scope.row, 'edit')"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              @click="handleOperation(scope.row, 'del')"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="page_total"
          :current-page.sync="page_index"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <partnerMessageEditModal ref="editModal" @refreshDataList="loadDataTable" />
  </div>
</template>
<script>
import OPERATE_API from "../../api/operate_api";
import agentApi from "../../api/agent_api";
import partnerMessageEditModal from "./model/partner-message-edit-modal.vue";
import { operateAdd } from "@/api/system/user-api";
export default {
  name: "user-wallet",
  components: {
    partnerMessageEditModal,
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      vloading: false,
      form: {
        investmentBudgetId: "",
        partnerTel: "",
        state: "",
      },
      dataTable: [],
      selection: [],
      budgetList: [],
      pageSize: 50,
      page_total: 0,
      page_index: 1,
      offset: 0,
      limit: 0,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      filterVisible: false,
      selectId: "",
      refundDialogVisible: false,
      userAccountRecordVisible: false,
      prompDialogVisible: false,
      createTime: [],
    };
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  async mounted() {
    this.search();
    this.getBudget();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  methods: {
    // 获取投资预算列表
    async getBudget() {
      this.axiosHelper
        .get(`/api/dim/types/InvestmentBudget`)
        .then((res) => {
          console.log(res.data, "aaa");
          this.budgetList = res.data;
        })
        .catch(() => {
          this.$message.error("加载失败");
        });
    },
    clear() {
      this.form = {
        investmentBudgetId: "",
        partnerTel: "",
        state: "",
      };
      this.createTime = [];
      this.page_index = 1;
      this.search();
    },
    search() {
      this.vloading = true;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        ...this.form,
      };
      let limit = this.pageSize;
      let offset = this.pageSize * (this.page_index - 1);
      if (this.createTime) {
        params.createTimeStart = this.createTime[0];
        params.createTimeEnd = this.createTime[1];
      } else {
        params.createTimeStart = null;
        params.createTimeEnd = null;
      }
      this.axiosHelper
        .get(`/api/courtship/partner/query/${offset}/${limit}`, {
          params: params,
        })
        .then((response) => {
          this.vloading = false;
          this.dataTable = response.data.data.items.map((item) => {
            item.cityName = `${item.province_name} ${item.city_name} ${item.area_name}`;
            return item;
          });
          this.page_total = response.data.data.total;
          //   this.$nextTick(() => {
          //     this.$refs["dataTable"].doLayout();
          //   });
        })
        .catch(() => {
          this.vloading = false;
          this.$message.error("加载失败");
        });
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 19,
        operate_parameter: JSON.stringify(this.form),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.selection.length == 0) {
        this.$confirm("是否要导出全部数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (this.page_total > 20000) {
            await this.$confirm(
              `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );
          }
          this.showLoading("数据加载中...");
          this.axiosHelper
            .get("/api/courtship/partner/list", {
              params: this.form,
            })
            .then((response) => {
              this.hideLoading();
              let export_data = response.data.data.map((item) => {
                item.cityName = `${item.province_name} ${item.city_name} ${item.area_name}`;
                return item;
              });
              this.exportData(export_data);
            })
            .catch(() => {
              this.hideLoading();
            });
        });
      } else {
        this.exportData(this.selection);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = ["姓名", "手机号", "意向城市", "投资预算", "状态"];
        const filterVal = [
          "partner_name",
          "partner_tel",
          "cityName",
          "investment_budget_name",
          "state",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "招商信息",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j == "state") {
            if (v[j] == 0) {
              return "未联系";
            } else {
              return "已联系";
            }
          } else {
            return v[j];
          }
        })
      );
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.limit = this.pageSize;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.search();
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    clickSearch() {
      this.handleCurrentChange(1);
    },
    async handleOperation(row, type) {
      const _this = this;
      switch (type) {
        case "edit":
          _this.$refs.editModal.visible = true;
          _this.$refs.editModal.init(row);
          break;
        case "del":
          const confirmResult = await _this
            .$confirm(`是否删除此招商信息？请注意删除后无法找回`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .catch((err) => {
              return err;
            });
          if (confirmResult != "confirm") {
            return;
          }
          _this.loadDataTable();
          _this.axiosHelper
            .post(`/api/courtship/partner/delete?id=${row.id}`)
            .then((res) => {
              _this.hideLoading();
              _this.$message.success("删除成功");
              _this.search();
            })
            .catch(() => {
              _this.hideLoading();
              _this.$message.error("加载失败");
            });
          break;
      }
    },
  },
};
</script>
<style scoped>
.box-card {
  margin-top: 15px;
}
</style>
