
<div class="height:100%;">
  <el-card class="box-card">
      <el-row>
          <el-col :span="4">
              <div class="block-view-head">
                  <span class="block-view-title" >电池统计</span>
              </div>
          </el-col>
      </el-row>
      <el-row :gutter="48" style="margin-top:11px;">
          <el-col :span="6">
              <div class="view-block left-line-blue" style="border:1px solid rgba(200,200,200,0.5);border-radius: 0;">
                <span class="outImag1">
                  <i class="el-icon-document" style="margin: 10px;"/>
                </span>
                  <span class="view-title" style="margin-left: 40px">设备数量</span>
                  <p class="view-count" style="text-align: center;">{{growthData.equipmentCount}}</p>
                  <p class="view-add" style="text-align: center;">台</p>
              </div>
          </el-col>
          <el-col :span="6">
              <div class="view-block left-line-orange" style="border:1px solid rgba(200,200,200,0.5);border-radius: 0;">
                <span class="outImag2">
                  <i class="el-icon-money" style="margin: 10px;"/>
                </span>
                  <span class="view-title" style="margin-left: 40px">电量储备</span>
                  <p class="view-count" style="text-align: center;">{{growthData.powerReserveCount}}</p>
                  <p class="view-add" style="text-align: center;">kWh</p>
              </div>
          </el-col>
          <el-col :span="6">
            <div class="view-block left-line-orange" style="border:1px solid rgba(200,200,200,0.5);border-radius: 0;">
                  <span class="outImag2">
                    <i class="el-icon-s-data" style="margin: 10px;"/>
                  </span>
              <span class="view-title" style="margin-left: 40px">耗电统计</span>
              <span style="float:right;margin-right: 20px">本日</span>
              <p class="view-count" style="text-align: center;">{{growthData.currentDayActualChargeCount}}</p>
              <p class="view-add" style="text-align: center;">kWh</p>
            </div>
          </el-col>
      </el-row>
  </el-card>
  <el-card class="box-card">
    <el-row :style="{height:(pageHeight - 110) * 0.45+'px','box-sizing':'border-box','padding-top':'16px'}">
      <commonLineStatistic :dateSpans="dateSpans" weekDataType="battery" yAxisDataMinInterval="20"  interFace="/api/battery/analysis/getWeekLineChart" lineChartName="耗电统计" lineChartId="week-day-line-chart" :xAxisData="weekDayXAxisData"/>
    </el-row>
  </el-card>
</div>
