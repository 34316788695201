<template>
  <div>
    <el-card class="iconShow">
      <el-row :gutter="24">
        <el-col :span="8">
          <div
            class="view-block left-line-blue"
            style="
              border: 1px solid rgba(200, 200, 200, 0.5);
              border-radius: 0;
              padding-right: 10px;
              position: relative;
            "
          >
            <div class="outImag1">
              <img class="imgCss" src="../../assets/img/subsidy-total.png" />
            </div>
            <div class="textCss">
              <p class="view-count" style="text-align: center">
                {{ statistics.totalAmount }}
              </p>
              <p class="view-add" style="text-align: center">补贴池总额</p>
            </div>
            <div class="btn">
              <el-button
                plain
                type="primary"
                size="mini"
                style="padding: 5px; font-size: 10px"
                @click="toSubsidyDetail"
                >总部补贴明细</el-button
              >
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div
            class="view-block left-line-blue"
            style="border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 0"
          >
            <div class="outImag1">
              <img
                class="imgCss"
                src="../../assets/img/subsidy-remaining.png"
              />
            </div>
            <div class="textCss">
              <p class="view-count" style="text-align: center">
                {{ statistics.remainingAmount }}
              </p>
              <p class="view-add" style="text-align: center">补贴池余额</p>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div
            class="view-block left-line-blue"
            style="border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 0"
          >
            <div class="outImag1">
              <img class="imgCss" src="../../assets/img/subsidy-used.png" />
            </div>
            <div class="textCss">
              <!-- <span class="view-title" style="margin-left: 40px">80033</span> -->
              <p class="view-count" style="text-align: center">
                {{ statistics.usedAmount }}
              </p>
              <p class="view-add" style="text-align: center">本月已用金额</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="search">
      <el-form :model="dataForm">
        <el-row class="row" :gutter="24">
          <el-col :span="5">
            <el-form-item label="补贴类型" label-width="75px">
              <el-select
                v-model="dataForm.flowType"
                placeholder="请选择补贴类型"
                clearable
              >
                <el-option
                  v-for="item in flowTypeList"
                  :key="item.flowType"
                  :label="item.flowName"
                  :value="item.flowType"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="用户手机号" label-width="100px">
              <el-input v-model="dataForm.cellphone" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="用户编号" label-width="100px">
              <el-input
                v-model="dataForm.userNumber"
                clearable
                maxlength="10"
                oninput="value=value.replace(/[^0-9]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="名称" label-width="60px">
              <el-input v-model="dataForm.couponName" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="更新时间" label-width="90px">
              <el-date-picker
                v-model="dataForm.createRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
                @change="choseDate"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row" style="margin-top: 12px" :gutter="24">
          <el-col :span="5">
            <el-form-item label="状态" label-width="75px">
              <el-select
                v-model="dataForm.expandState"
                placeholder="请选择状态"
                clearable
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.state"
                  :label="item.stateName"
                  :value="item.state"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="活动名称" label-width="100px">
              <el-input v-model="dataForm.promotionName" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="城市" label-width="75px">
              <el-select
                v-model="dataForm.cityCode"
                placeholder="请选择状态"
                filterable
              >
                <el-option
                  v-for="item in restrictedCityList"
                  :key="item.cityCode"
                  :label="item.cityName"
                  :value="item.cityCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label-width="25px">
              <el-button type="primary" @click="search">查询</el-button>
              <el-button @click="clear()">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card>
      <div class="tableHeaderCss">
        <div>补贴用户明细</div>
        <div>
          <el-button type="primary" @click="exportExcel">导出</el-button>
        </div>
      </div>
      <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%"
        max-height="400"
      >
        <el-table-column
          prop="flow_name"
          header-align="center"
          align="center"
          min-width="100"
          label="补贴类型"
        >
        </el-table-column>
        <el-table-column
          prop="user_number"
          header-align="center"
          align="center"
          min-width="150"
          label="用户编号"
        >
        </el-table-column>
        <el-table-column
          prop="cellphone"
          header-align="center"
          align="center"
          min-width="150"
          label="用户手机号"
        >
        </el-table-column>
        <el-table-column
          prop="promotion_name"
          header-align="center"
          align="center"
          min-width="150"
          label="活动名称"
        >
        </el-table-column>
        <el-table-column
          prop="coupon_name"
          header-align="center"
          align="center"
          min-width="120"
          label="名称"
        >
          <template slot-scope="scope">
            {{ scope.row.coupon_name || "—— ——" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          header-align="center"
          align="center"
          min-width="100"
          label="补贴折算金额(元)"
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          header-align="center"
          align="center"
          min-width="140"
          label="更新时间"
        >
        </el-table-column>
        <!--
      <el-table-column prop="end_time" header-align="center" align="center" min-width="140" label="失效日期">
        <template slot-scope="scope">
       {{ scope.row.end_time || '—— ——' }}
      </template>
      </el-table-column>
        -->
        <el-table-column
          prop="state_name"
          header-align="center"
          align="center"
          label="状态"
        >
          <template slot-scope="scope">
            {{ scope.row.state_name || "—— ——" }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </el-card>
    <headquarters_subsidy_detail
      v-if="subsidyDetailVisible"
      ref="subsidyDetail"
      :initialData="dataForm"
      @refreshDataList="getDataList"
    ></headquarters_subsidy_detail>
  </div>
</template>

<script>
import headquarters_subsidy_detail from "./model/headquarters_subsidy_detail.vue";
import subsidyPoolApi from "../../api/subsidy_pool_api";
import AREACORP_API from "../../api/areacorp_api";
import agentApi from "../../api/agent_api";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";
export default {
  // name: "subsidyDetail",
  data() {
    return {
      statistics: {
        cityName: "",
        totalAmount: "",
        remainingAmount: "",
        usedAmount: "",
      },
      dataForm: {
        flowType: "",
        // agent_name: "",
        cellphone: "",
        createRange: [],
        expandState: 1,
        couponName: "",
        cityCode: "",
        promotionName: "",
      },
      // 0.优惠券 1.积分 2.现金返现 3.卡券 4.总部补贴 9.其它
      flowTypeList: [
        { flowType: 0, flowName: "优惠券" },
        //{flowType:1,flowName:'积分'},
        { flowType: 2, flowName: "现金返现" },
        //{flowType:3,flowName:'卡券'},
        //{flowType:9,flowName:'其它'},
        { flowType: 10, flowName: "度数" },
      ],
      /*//（0-可用；1-已使用；2-失效）
      stateList:[
        {state:0,stateName:'待使用'},
        {state:1,stateName:'已使用'},
        {state:3,stateName:'已作废'},
        {state:4,stateName:'已过期'},
      ],*/
      //1:已发放、2:过期返回、3:作废返回、4:激活代扣、5:异常退款返还
      stateList: [
        { state: 1, stateName: "已发放" },
        { state: 2, stateName: "过期返回" },
        { state: 3, stateName: "作废返回" },
        { state: 4, stateName: "激活代扣" },
        { state: 5, stateName: "异常退款返还" },
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      subsidyDetailVisible: false,
    };
  },
  components: {
    headquarters_subsidy_detail,
  },

  async created() {
    if (this.subsidy_detail && this.subsidy_detail.area_code) {
      let cityCode = this.subsidy_detail.area_code;
      this.dataForm.cityCode = cityCode;
    } else {
      if (this.restrictedCityList.length > 0) {
        this.dataForm.cityCode = this.restrictedCityList[0].cityCode;
      }
    }
    if (this.dataForm.cityCode) {
      this.subsidyStatistics(this.dataForm.cityCode);
      this.getDataList(this.dataForm.cityCode);
    }

    //  this.getDataList()
    // console.log("===============>"+this.$store.state.test)
    // // this.subsidyStatistics()
    // if(this.$route.params.isRoute){
    //   this.dataForm.cityCode=this.$route.params.city.area_code
    //   this.subsidyStatistics(this.$route.params.city.area_code)
    //   this.getDataList(this.$route.params.city.area_code)
    // }else{
    //   this.subsidyStatistics()
    //   this.getDataList()
    // }
  },

  methods: {
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["补贴类型"] = item.flow_name;
        newObj["用户编号"] = item.user_number;
        newObj["用户手机号"] = item.cellphone;
        newObj["活动名称"] = item.promotion_name;
        newObj["名称"] = item.coupon_name;
        newObj["补贴折算金额(元)"] = item.amount;
        newObj["更新时间"] = item.create_time;
        //newObj['失效日期']=item.end_time
        newObj["状态"] = item.state_name;
        newArray.push(newObj);
      });
      return newArray;
    },

    xlsxToBlob(workbook) {
      const wopts = { bookType: "xlsx", bookSST: false, type: "blob" };
      const blob = XLSX.write(workbook, wopts);
      return blob;
    },
    //导出
    async exportExcel() {
      await operateAdd({
        operate_type: "1",
        operate_number: 32,
        operate_parameter: JSON.stringify({
          reqdata: this.dataForm,
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.totalPage > 20000) {
        await this.$confirm(
          `您本次需要导出${this.totalPage}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let reqData = {
        reqdata: this.dataForm,
      };
      let res = await subsidyPoolApi.subsidyDetail(reqData);

      if (res.data.code != 0) {
        this.$message.error({
          message: res.data.msg,
        });
        return;
      }
      let newData = this.getHeadersAndbody(res.data.page.items);
      const ws = XLSX.utils.json_to_sheet(newData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const fileName = "补贴用户明细.xlsx";
      XLSX.writeFile(wb, fileName);
    },
    choseDate() {
      let createRange = this.dataForm.createRange;
      if (createRange && createRange.length > 0) {
        this.dataForm.createStartTime = createRange[0];
        this.dataForm.createEndTime = createRange[1];
      } else {
        this.dataForm.createStartTime = "";
        this.dataForm.createEndTime = "";
      }
    },
    async subsidyStatistics(city_code) {
      if (!city_code) {
        city_code = "";
      }
      let res = await AREACORP_API.subsidyStatistics({ cityCode: city_code });
      if (res.data.code != 0) {
        this.$message.error({
          message: res.data.msg,
        });
        return;
      }
      this.statistics = { ...res.data.statistics };
    },

    formatOpeningDate(row, column, cellValue) {
      if (cellValue) {
        return dayjs(cellValue).format("YYYY-MM-DD");
      }
    },
    //重置
    clear() {
      this.dataForm.flowType = "";
      this.dataForm.cellphone = "";
      this.dataForm.createRange = [];
      this.dataForm.expandState = "";
      this.dataForm.couponName = "";
      // this.dataForm.cityCode=''
      this.dataForm.createStartTime = "";
      this.dataForm.createEndTime = "";
      this.dataForm.userNumber = "";
      this.search();
    },

    //弹窗总部补贴明细
    toSubsidyDetail() {
      this.subsidyDetailVisible = true;
      // this.
      // this.$refs.subsidyDetail.init(this.dataForm)
      this.$nextTick(() => {
        this.$refs.subsidyDetail.init(this.dataForm);
      });
    },
    search() {
      this.pageIndex = 1;
      this.subsidyStatistics(this.dataForm.cityCode);
      this.getDataList();
    },
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      // let dataFormCopy=JSON.parse(JSON.stringify(this.dataForm))
      let offset = (this.pageIndex - 1) * this.pageSize;
      let reqData = {
        offset: offset,
        limit: this.pageSize,
        reqdata: this.dataForm,
      };

      let res = await subsidyPoolApi.subsidyDetail(reqData).finally((_) => {
        this.dataListLoading = false;
      });

      if (res.data.code != 0) {
        this.$message.error({
          message: res.data.msg,
        });
        return;
      }
      this.dataList = res.data.page.items;
      this.totalPage = res.data.page.total_count;
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
  computed: {
    ...mapState(["restrictedCityList"]),
    subsidy_detail() {
      return this.$store.state.routerCache.subsidy_detail;
    },
  },
};
</script>
<style scoped lang="scss">
.imgCss {
  height: 55px;
  line-height: 60px;
  font-size: 60px;
  // width:3.0em;
  // height: 3.0em;
}
.textCss {
  margin-left: 10px;
}
// .iconShow{
.el-form {
  .row {
    height: 32px !important;
    width: 100%;
    // padding-top:10px;
    // padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    .el-col {
      padding: 0px !important;
    }
    .el-form-item {
      width: 100% !important;
      // margin-right: 60px;
      // align-items: flex-start;
      // justify-content: flex-start;
    }
  }
}
// }
.el-pagination {
  margin-top: 8px;
}
::v-deep .el-card {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  padding: 12px 10px !important;
  border-radius: 8px;
  .el-card__body,
  .el-main {
    padding: 5px;
  }
}
.iconShow {
  margin-top: 10px !important;
  .view-block {
    // position: relative;
    padding: 15px 20px;
    font-size: 14px;
    overflow: hidden;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .btn {
      // align-self:flex-end;
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
    /*color: #fff;*/
  }
  .view-block .view-title {
    // padding-bottom: 10px;
  }
  .view-block .view-count {
    height: 34px;
    line-height: 32px;
    font-size: 34px;
  }
  .view-block .view-add {
    height: 34px;
    line-height: 32px;
    font-size: 16px;
    color: #999999;
  }
  .view-block .view-icon {
    position: absolute;
    top: 32px;
    right: 30px;
  }
  .left-line-blue ::v-deep .outImag1 {
    // position: absolute;
    // left: 50px;
    // top: 20px;
    // width: 40px;
    // height: 40px;
    background: inherit;
    // background-color: rgba(82, 193, 245, 1);
    border: 0 none;
    border-radius: 40px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    // font-family: 'FontAwesome', sans-serif;
    // font-weight: 400;
    font-style: normal;
    // font-size: 20px;
    color: #ffffff;
    // line-height: 20px;
  }
  .left-line-orange ::v-deep .outImag2 {
    position: absolute;
    left: 20px;
    top: 10px;
    width: 40px;
    height: 40px;
    background: inherit;
    background-color: rgba(75, 206, 208, 1);
    border: 0px none;
    border-radius: 40px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: "FontAwesome", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: #ffffff;
    line-height: 20px;
  }
}
.tableHeaderCss {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center; /* 垂直居中 */
}
</style>
