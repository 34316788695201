<template>
    <el-dialog title="高级搜索" v-dialogDrag :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
        <el-form :model="dataForm" ref="dataForm" label-width="125px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="开票流水号：">
                        <el-input v-model.trim="dataForm.bill_no" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="更多内容：">
                        <el-input v-model.trim="dataForm.more_content" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="发票抬头：">
                        <el-input v-model.trim="dataForm.company_name" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="公司税号：">
                        <el-input v-model.trim="dataForm.company_tax_no" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="抬头类型：">
                        <el-select v-model="dataForm.bill_type" clearable style="width: 100%">
                            <el-option v-for="item in typeDim" :key="item.value" :value="item.value"
                                :label="item.name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="开票状态：">
                        <el-select v-model="dataForm.state" clearable style="width: 100%">
                            <el-option v-for="item in stateDim" :key="item.value" :value="item.value"
                                :label="item.name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="clear">清除条件</el-button>
            <el-button type="default" @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    export default {
        name: "bill-search-model",
        props: {
            dialog: {
                type: Boolean,
                default () {
                    return false;
                }
            },
        },
        data() {
            return {
                dialogVisible: false,
                dataForm: {},
                typeDim:[
                    {
                       value: 0,
                       name: '企业单位' 

                    },{
                       value: 1,
                       name: '个人' 
                    }
                ],
                stateDim:[
                    {
                       value: 0,
                       name: '未开票' 

                    },{
                       value: 1,
                       name: '已开票' 
                    }
                ],
            }
        },
        mounted() {
            
        },
        watch: {
            dialog: {
                immediate: true,
                handler(value) {
                    this.dialogVisible = value;
                }
            },
            dialogVisible: {
                handler(value) {
                    this.$emit("update:dialog", value);
                }
            }
        },
        methods: {
            clear() {
                this.dataForm = {};
            },
            cancel() {
                this.dialogVisible = false;
            },
            confirm() {
                this.dialogVisible = false;
                this.$emit("search", this.dataForm);
            },
        }
    }
</script>