<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="投资预算：" label-width="54px" v-if="!isAgent">
          <el-select v-model="form.cityCode" placeholder="全国" clearable>
            <el-option
              v-for="item in investList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input
            v-model.trim="form.cellphone"
            placeholder="请输入手机号码"
            clearable
          />
        </el-form-item>
        <el-form-item label="状态：" label-width="54px" v-if="!isAgent">
          <el-select v-model="form.cityCode" placeholder="全国" clearable>
            <el-option label="全部" :value="0"></el-option>
            <el-option label="已联系" :value="1"></el-option>
            <el-option label="未联系" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>B端优享管理</span>
        <el-button style="float: right" @click="exportData">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        :height="tableHeight"
        :data="dataTable"
        @selection-change="rowSelected"
      >
        <!-- <el-table-column
                    type="selection"
                    width="50"
                align="center"></el-table-column>-->
        <el-table-column
          type="selection"
          width="50"
          align="center"
          :selectable="selectable"
        ></el-table-column>
        <el-table-column label="序号" min-width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="first_city_name"
          label="姓名"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="用户手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="意向城市"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="投资预算"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="状态"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope"> </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              @click="handelDetails(scope.row)"
              type="text"
              size="small"
              >明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <bEndEnjoyDetailsModal
      ref="detailsModal"
      @getList="getUserDetails"
      :userId="user_id"
    />
  </div>
</template>
<script>
import OPERATE_API from "../../api/operate_api";
import * as XLSX from "xlsx";
import bEndEnjoyDetailsModal from "./model/b-end-enjoy-details-modal.vue";
import agentApi from "@/api/agent_api";
import { operateAdd } from "@/api/system/user-api";
export default {
  components: {
    bEndEnjoyDetailsModal,
  },
  data() {
    return {
      investList: [],
      form: {
        cityCode: "",
        cellphone: "",
        degreeType: 1,
      },
      selection: [],
      dataTable: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      field: "",
      type: "",
      user_id: "",
      addOrUpdateVisible: false,
    };
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    if (this.isAgent) {
      await this.initAgentCity();
    }
    this.getCityList();
    this.search();
  },
  computed: {
    isAgent() {
      let user = this.$store.state.user;
      if (user && user.isAgent) {
        return true;
      }
      return false;
    },
  },
  mounted() {},
  methods: {
    async initAgentCity() {
      this.form.cityCode = "xxxx";
      let res = await agentApi.getConfig().finally((_) => {});

      if (res.data.code == 0) {
        this.form.cityCode = res.data.config.area_code;
      }
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    async clear() {
      this.form = {
        cityCode: "",
        cellphone: "",
        degreeType: 1,
      };
      if (this.isAgent) {
        await this.initAgentCity();
      }
      this.createTime = [];
      this.page_index = 1;
      this.loadDataTable();
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        ...this.form,
      };
      params.field = this.field;
      params.type = this.type;
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/v0.1/api/operate/degree/${offset}/${limit}`, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.data.items;
          this.page_total = response.data.data.total;
          //   this.$nextTick(() => {
          //     this.$refs["dataTable"].doLayout();
          //   });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    // 获取城市选择列表
    async getCityList() {
      let res = await OPERATE_API.getCityList();
      if (res.data.code != 0) {
        this.$message.error({
          message: "加载城市列表错误",
        });
        return;
      }
      this.cityList = res.data.cityList;
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 13,
        operate_parameter: JSON.stringify({
          ...this.form,
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        ...this.form,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/v0.1/api/operate/degree/export", {
          params: params,
        })
        .then((res) => {
          console.log(res, "asdas");
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.data);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "B端优享管理.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["城市"] = item.first_city_name;
        newObj["用户手机号"] = item.cellphone;
        newObj["度数余额(度)"] = item.busin_available_degree;
        newObj["已转赠总度数(度)"] = item.transfer_total_degree;
        newObj["购买度数总金额(元)"] = item.busin_total_degree_buy_money;
        newObj["购买度数合计(度)"] = item.busin_total_degree;
        // newObj["状态"] = item.cur_status === 1 ? '进行中' : item.cur_status === 2 ? '停用' : item.cur_status === 3 ? '已结束' : '待开始';
        newArray.push(newObj);
      });
      return newArray;
    },
    handelDetails(row) {
      this.user_id = row.user_id;
      this.getUserDetails();
      this.$refs.detailsModal.visible = true;
    },
    getUserDetails() {
      let params = {
        ...this.$refs.detailsModal.form,
        ...{ degreeType: 1 },
      };
      let limit = this.$refs.detailsModal.page_size;
      let offset =
        this.$refs.detailsModal.page_size *
        (this.$refs.detailsModal.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(
          `/v0.1/api/operate/degree/detail/${this.user_id}/${offset}/${limit}`,
          {
            params: params,
          }
        )
        .then((response) => {
          this.hideLoading();
          this.$refs.detailsModal.dataTable = response.data.data.items;
          this.$refs.detailsModal.page_total = response.data.data.total;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}
</style>
