<template>
  <el-dialog :title="'新增储值客户-'+this.dataItem.week_name" :close-on-click-modal="false" :visible.sync="visible">
    <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
      <el-table-column prop="fullname" label="城市" min-width="150" align="center"></el-table-column>
      <el-table-column prop="cellphone" label="客户手机号" min-width="150" align="center"></el-table-column>
      <el-table-column prop="create_time" label="充值时间" min-width="150" align="center"></el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top:10px;">
      <el-pagination background layout="total,sizes, prev, pager, next,jumper" :total="page_total"
                     :page-size="page_size" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :page-sizes="[10, 20, 50, 100]" :current-page.sync="page_index"></el-pagination>
    </el-footer>
  </el-dialog>
</template>

<script>

export default {
  props: {
    dataItem: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  data() {
    return {
      visible: false,
      form: {},
      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: '70vh',
    }
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  mounted() {

  },
  methods: {
    init() {
      this.visible = true
      this.search();
    },
    getTableHeight() {
      this.tableHeight = String(document.documentElement.clientHeight - 95 - 150 - 200);
    },
    search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {}
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
          filterVal.map(j => {
            return v[j];
          })
      );
    },
    handleSizeChange(val) {
      this.page_size = val
      this.loadDataTable()
    },
    loadDataTable() {
      this.form.firstCityCode = this.$parent.form.firstCityCode
      this.form.starTime = this.dataItem.star_time
      this.form.endTime = this.dataItem.end_time

      let params = {
        ...this.form,
        source: 'pc'
      }
      let limit = this.page_size
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
          .get('/api/userAccountRecord/recharge/user/'+offset+'/'+limit, {
            params: params
          })
          .then((response) => {
            this.hideLoading();
            this.dataTable = response.data.items
            this.page_total = response.data.total
            this.$nextTick(() => {
              this.$refs['dataTable'].doLayout();
            })
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error("加载失败");
          })
    },
    handleCurrentChange(val) {
      this.page_index = val
      this.loadDataTable()
    }
  }
}
</script>
