
<el-dialog
  :title="'新增注册用户数-' + this.dataItem.week_name"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <div class="text item">
        <el-form
          ref="form"
          :model="form"
          label-width="110px"
          class="demo-form-inline"
        >
          <el-row class="search">
            <el-form-item label="城市" style="width: 100%">
              <el-select
                v-model="form.firstCityCode"
                placeholder="全国"
                collapse-tags
                clearable
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.cityCode"
                  :label="item.cityName"
                  :value="item.cityCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-col class="elCol">
              <el-form-item label="手机号码：">
                <el-input
                  v-model.trim="form.cellphone"
                  placeholder="请输入"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col class="elColBtn">
              <el-button
                style="margin-right: 15px"
                type="primary"
                @click="search"
                >查询</el-button
              >
              <el-button type="default" @click="clear">重置</el-button>
              <!-- </el-form-item> -->
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
        <el-table-column
          prop="fullname"
          label="城市"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="客户手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="注册时间"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
  </div>
</el-dialog>
