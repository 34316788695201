
<el-dialog class="showAll_dialog" :title="editState === 'add' ? '新增' : '编辑'" width="800px" :height="'80%'"
    :max-height="'600px'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="form" ref="form" v-loading="formLoading" :rules="dataRule" label-width="130px">
        <el-row>
            <el-col v-if="editState === 'edit'" :span="12">
                <el-form-item label="ID：">
                    <el-input v-model.trim="form.id" readonly placeholder="请填写id" clearable maxlength="35" />
                </el-form-item>
            </el-col>
            <el-col :span="editState === 'edit' ? 12 : 24">
                <el-form-item v-if="editState === 'add'" label="渠道码：" prop="channel_code">
                    <el-input v-model.trim="form.channel_code" placeholder="请填写渠道码" clearable maxlength="35" />
                </el-form-item>
                <el-form-item v-else label="渠道码：">
                    <el-input v-model.trim="form.channel_code" readonly placeholder="请填写渠道码" clearable maxlength="35" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="渠道商：" prop="channel_merchant_name">
                    <el-input v-model.trim="form.channel_merchant_name" placeholder="请填写渠道商" clearable
                        maxlength="35" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="手机号码：" prop="phone_number">
                    <el-input v-model.trim="form.phone_number" placeholder="请填写手机号码" clearable maxlength="35" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item label="联系人：">
                    <el-input v-model.trim="form.contacts" placeholder="请填写联系人" clearable maxlength="35" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item label="备注：">
                    <el-input v-model="form.remarks" type="textarea" :rows="5" placeholder="备注信息填写位置">
                    </el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <span slot="footer" class="dialog-foote r">
        <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
        <el-button @click="visible = false">取消</el-button>
    </span>
</el-dialog>
