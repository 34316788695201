
<el-dialog
  title="车辆检测明细"
  :close-on-click-modal="false"
  :visible.sync="visible"
  center
  @close="close"
>
  <div class="height:100%;">
    <div style="display: flex; justify-content: space-between">
      <el-form :inline="true">
        <el-form-item label="收入/支出" style="margin-bottom: 0">
          <el-select
            v-model="form.type"
            placeholder="全部"
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button type="default" @click="clear">重置</el-button>
      </el-form>
      <el-button @click="exportData">导出</el-button>
    </div>

    <el-card class="box-card">
      <el-table ref="dataTable" height="50vh" :data="dataTable">
        <el-table-column
          prop="typeName"
          label="类型"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="time"
          label="时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="购买金额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="num"
          label="收入/支出（次）"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="activity_name"
          label="活动名称"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="early_num"
          label="前"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="after_num"
          label="后"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
  </div>
</el-dialog>
