
<el-dialog
  :title="`地推详情-${selectObj.user_number}`"
  :close-on-click-modal="false"
  :visible.sync="visible"
  center
  width="1000px"
  @close="close"
>
  <div class="height:100%;">
    <div style="display: flex; justify-content: space-between">
      <el-form :inline="true">
        <el-form-item label="购买时间" style="margin-bottom: 0">
          <el-date-picker
            v-model="createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="primary" @click="clear"
          >重置</el-button
        >
      </el-form>
      <el-button @click="exportData">导出</el-button>
    </div>
    <div class="total">
      <div class="total-item">交易面额（元）：{{ total.total_amount }}</div>
      <div class="total-item">单数：{{ total.total_number }}</div>
    </div>
    <el-card class="box-card">
      <el-table ref="dataTable" height="50vh" :data="dataTable">
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_platform_serial_number"
          label="支付平台流水单号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="time"
          label="购买时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="交易面额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="discount_content"
          label="套餐内容"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
  </div>
</el-dialog>
