
<el-table-column :label="coloumnHeader.name" align="center" show-overflow-tooltip :render-header="labelHead">
    <template v-for="item in coloumnHeader.children">
        <el-table-column :label="item.name" :prop="item.value" show-overflow-tooltip align="center"
            :render-header="labelHead">
            <template v-for="(child,index) in item.children">
                <CommonTableColumn v-if="child.children && child.children.length" :key="index"
                    :coloumn-header="child"></CommonTableColumn>
                <el-table-column v-else :label="child.name" :prop="child.value" show-overflow-tooltip
                    :tooltip="tooltip" align="center" :render-header="labelHead">
                    <template slot-scope="scope">
                        <el-button type="text" @click="clickBtn(scope.$index,dataList,child.value,child.name)"
                            v-if="child.type==='bLine'" class="base-color tdu">{{scope.row[child.value]}}
                        </el-button>
                        <span v-else>{{scope.row[child.value]}}</span>
                    </template>
                </el-table-column>
            </template>
            <template slot-scope="scope">
                <span @click="clickBtn(scope.$index, dataList, item.value)" class="base-color cp tdu"
                    v-if="item.type === 'bLine'">
                    {{ scope.row[item.value] }}
                </span>
                <div v-else>{{scope.row[item.value]}}</div>
            </template>
        </el-table-column>
    </template>
</el-table-column>
