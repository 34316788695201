
<div style="height: 97%">
  <el-card class="box-card" style="height: 100%" v-if="pageShow">
    <el-form :model="form" :rules="relus" ref="form" label-width="80px">
      <el-form-item label="当前城市">
        <el-select
          style="width: 200px"
          v-model="cityCode"
          placeholder="全国"
          filterable
          @change="getCityDegrees"
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最低度数" prop="min_degree">
        <el-input-number
          v-model.number="form.min_degree"
          @change="minChange"
          :step="5"
          :step-strictly="true"
          :min="15"
          :max="150"
          controls-position="right"
          @keyup.native="UpNumber"
          @keydown.native="UpNumber"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="最高度数" prop="max_degree">
        <el-input-number
          v-model.number="form.max_degree"
          @change="maxChange"
          :step="5"
          :step-strictly="true"
          :min="15"
          :max="150"
          controls-position="right"
          @keyup.native="UpNumber"
          @keydown.native="UpNumber"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div class="footer-btn">
      <el-button type="primary" style="width: 100px" @click="dataFormSubmit()"
        >编辑</el-button
      >
    </div>
  </el-card>
</div>
