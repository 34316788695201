/*
 * @Author: ljf
 * @Date: 2022-06-23 09:07:15
 * @LastEditors: ljf
 * @LastEditTime: 2022-06-23 14:31:32
 * @FilePath: \lxm-admin\src\api\system\role-authority-api.js
 * @Description: 
 * 
 */
import axios from "utils/axiosHelper";
import { Message } from "element-ui";

export const getRoleAuthority = (type, roleId, menuType) => {
  return axios.get(`api/authority/${type}/${roleId}?menuType=${menuType}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
}

export const saveRoleAuthority = (roleId, params) => {
  return axios.post(`/api/authority/assign/${roleId}`,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    },
    {
      params
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
}