
<div class="org-container">
  <div class="org-container-left is-always-shadow">
    <org-tree
      ref="orgTree"
      @nodeClick="handleNodeClick"
      showContext
      @command="handlerCommand"
    ></org-tree>
  </div>
  <div class="org-container-right">
    <div class="org-container-right-form">
      <el-card>
        <el-form
          :model="org"
          ref="orgForm"
          label-width="80px"
          style="width:100%"
        >

          <el-row style="width: 100%">
            <el-col :span="8">
              <el-form-item label="组织名称">
                <el-input
                  v-model="org.org_name"
                  :style="InputWidth"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织编号">
                <el-input
                  v-model="org.org_code"
                  readonly
                  :style="InputWidth"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织类型">
                <!-- <el-input
                  v-model="org.category"
                  readonly
                ></el-input> -->
                <el-select
                  v-model="org.category"
                  disabled
                  :style="InputWidth"
                >
                  <el-option
                    v-for="item in categoryOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="width: 100%">
            <el-col :span="8">
              <el-form-item label="组织别名">
                <el-input
                  v-model="org.alias"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织领导">
                <el-input
                  v-model="org.leader_name"
                  readonly
                  :style="InputWidth"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="排序值">
                <el-input
                  v-model="org.sort_index"
                  readonly
                  :style="InputWidth"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="width: 100%">
            <el-col :span="8">
              <el-form-item label="联系地址">
                <el-input
                  v-model="org.address"
                  readonly
                  :style="InputWidth"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话">
                <el-input
                  v-model="org.telephone"
                  readonly
                  :style="InputWidth"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否显示">
                <el-switch
                  v-model="org.show"
                  active-text="是"
                  inactive-text="否"
                  disabled
                  :style="InputWidth"
                >
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>
    <div class="org-container-right-table">
      <el-card>
        <el-row :gutter="20">
          <el-col
            :span="4"
            :offset="0"
          ></el-col>
          <el-button
            class="rewrite-button-style"
            size="small"
            @click="addOrgUser"
          >添加组织用户</el-button>
          <el-button
            class="rewrite-button-style"
            size="small"
            @click="removeOrgUser"
          >移除组织用户</el-button>
        </el-row>

        <el-row
          :gutter="20"
          style="margin-top:12px;width:100%"
        >
          <base-table
            ref="choose-table"
            :data="tableData"
            showIndex
            showCheck
            :height="height"
            :columns="tableColumns"
            :totalCount="totalCount"
            @page-change="pageChange"
            @on-select-change="onSelectChange"
          ></base-table>
        </el-row>
      </el-card>
    </div>
  </div>
  <!-- 组织信息编辑弹窗 -->
  <ex-dialog
    :title="editOrgTitle"
    :visible.sync="orgDialogVisible"
    v-if="orgDialogVisible"
    :width="1100"
    :height="500"
    :show-fullscreen-icon="true"
    :append-to-body="true"
  >
    <org-edit
      :action="action"
      :org_="org_"
      :categoryOptions="categoryOptions"
      @close="closeEditOrgTitle"
      @refresh="refresh"
    ></org-edit>

  </ex-dialog>
  <!-- 组织角色弹窗 -->
  <ex-dialog
    title="设置组织角色"
    :visible.sync="orgRoleUserDialogVisible"
    v-if="orgRoleUserDialogVisible"
    :width="1300"
    :height="700"
    :show-fullscreen-icon="true"
    :append-to-body="true"
  >
    <org-role-user
      v-if="orgRoleUserDialogVisible"
      :selectOrgId="org.id"
      @close="orgRoleUserDialog"
    ></org-role-user>
  </ex-dialog>
  <!-- 添加组织角色弹窗 -->
  <ex-dialog
    title="添加组织用户"
    :visible.sync="OrgUserDialogVisible"
    v-if="OrgUserDialogVisible"
    :width="1100"
    :height="700"
    :show-fullscreen-icon="true"
    :append-to-body="true"
  >
    <role-user
      role_id=""
      :org_id="org.id"
      type="role"
      @close="closeOrgUser"
      @submit="submitOrgUser"
    ></role-user>
  </ex-dialog>
</div>
