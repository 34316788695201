<!--
 * @Author: mxb
 * @Date: 2021-09-01 16:54:59
 * @LastEditors: LianQiaoXu xulianqiao@longshine.com
 * @LastEditTime: 2022-10-12 16:15:32
-->
<style>
</style>

<template>
    <div>
        <el-row>
            <div :span="24">
                <div style="position: absolute;z-index: -100;width: 100%;top: 0px;left: 0px;" v-if="exportData.length">
                    <a id="aTags"></a>
                    <el-table border :row-style="rowClass" ref="exportTable" id="exportTable" :data="dataList">
                        <template v-for="(item,index) in exportHead">
                            <CommonTableColumn v-if="item.children && item.children.length" :key="index"
                                :coloumn-header="item"></CommonTableColumn>
                            <el-table-column v-else :key="index" :label="item.name" :prop="item.value"
                                :width="item.width" show-overflow-tooltip :tooltip="tooltip" align="center"
                                :render-header="labelHead"></el-table-column>
                        </template>
                    </el-table>
                </div>
                <el-table :stripe="true" border ref="singleTable" :data="dataList" :max-height="defaultHeight"
                    :highlight-current-row="isHighlight" style="width: 100%;" :row-key="rowKey"
                    @current-change="handleHighlight" @selection-change="handleSelectionChange" @select="handleSelect"
                    @select-all="handleSelectAll" :show-summary="showSummary" :summary-method='summaryMethod'
                    @row-click="clickRow" @sort-change="sortChange" empty-text="暂无数据"
                    :row-class-name="tableRowClassName">
                    <el-table-column v-if="isSelection" type="selection" align="center"
                        :reserve-selection="reserveSelection" width="55">
                    </el-table-column>
                    <el-table-column v-if="isIndex" label="序号" align="center" header-align="center" width="100">
                        <template slot-scope="scope">
                            <span>{{ (currentPage-1)*pageSize + scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <template v-for="(item,index) in headList">
                        <el-table-column v-if="!item.children" :label="item.name" :prop="item.value" :width="item.width"
                            show-overflow-tooltip :tooltip="tooltip" :key="index" align="center"
                            :render-header="labelHead">
                            <template slot-scope="scope">
                                <!-- 某列单元格可点击 -->
                                <span @click="clickBtn(scope.$index, dataList, item.value)" class="base-color cp tdu"
                                    v-if="item.type === 'bLine'">
                                    {{ scope.row[item.value] }}
                                </span>
                                <span v-else-if="item.type === 'list'">
                                    <span v-for="(valItem,index) in scope.row[item.value]" :key="index">
                                        {{ valItem }} <br />
                                    </span>
                                </span>
                                <!-- 其他 -->
                                <span v-else>{{ scope.row[item.value] }}</span>
                            </template>
                        </el-table-column>
                        <CommonTableColumn v-else :coloumn-header="item" @clickDetails="clickDetails" :key="index"
                            :dataList="dataList"></CommonTableColumn>
                    </template>
                    <el-table-column v-if="buttonList.length" :label="label || '操作'" :fixed="fixed" align="center"
                        header-align="center" :width="buttonWidth">
                        <template slot-scope="scope">
                            <div v-for="(button, index) in buttonList" class="dib mr6" :key="index">
                                <el-button :type="'text'" size="small" :disabled="
                  !(!button.isDisabled ||
                  button.isDisabled.indexOf(
                    dataList[scope.$index][button.disabledConditionField]
                  ) !== -1)
                " v-if="!button.isShow||button.isShow.indexOf(dataList[scope.$index][button.conditionField])!==-1"
                                    @click="clickBtn(scope.$index,dataList,button.methods,button.desc)">{{button.name}}
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-if="isPagination" class="tar" height="32px" style="margin-top: 10px">
                <el-pagination background :select-class="secClass" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page.sync="currentPage"
                    :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :layout=layout :total="totalSize">
                </el-pagination>
            </div>
        </el-row>
    </div>
</template>

<script>
    import CommonTableColumn from './common-table-column.vue'
    // import { pagination as LsPagination } from 'vmd-ui'
    export default {
        name: 'common-table-multi',
        data() {
            return {
                multipleSelection: [],
                totalSize: 0,
                currentPage: 1,
                pageSize: 10,
                currentRow: null,
                border: true,
                tooltip: {
                    placement: 'top',
                    effect: 'dark'
                }
            }
        },
        components: {
            CommonTableColumn,
            // LsPagination
        },
        computed: {
            lastPageNum() {
                return Math.ceil(this.totalSize / this.pageSize)
            }
        },
        props: {
            secClass: '',
            label: {
                type: String,
                default: '操作'
            },
            rowKey: {
                type: String,
                default: ''
            },
            pageSizes: {
                type: Array,
                default: () => [10, 20, 30, 40]
            },
            headList: {
                type: Array,
                default: () => []
            },
            exportHead: {
                type: Array,
                default: () => []
            },
            dataList: {
                type: Array,
                default: () => []
            },
            defaultHeight: {
                type: String,
                default: '500'
            },
            buttonWidth: {
                default: 120
            },
            buttonList: {
                type: Array,
                default: () => []
            },
            isSelection: {
                type: Boolean,
                default: false
            },
            isHighlight: {
                type: Boolean,
                default: false
            },
            fixed: { default: false },
            isPagination: {
                type: Boolean,
                default: true
            },
            isIndex: {
                type: Boolean,
                default: false
            },
            exportData: {
                type: Array,
                default: () => []
            },
            layout: {
                type: String,
                default: 'total, sizes, prev, pager, next, jumper'
            },
            reserveSelection: {
                type: Boolean,
                default: false
            },
            showSummary: {
                type: Boolean,
                default: false
            },
            summaryMethod: {
                type: Function,
                default: () => { }
            },
            originPagesSize:{
                type:Number,
                default:10
            }
        },
        created(){
            this.pageSize = this.originPagesSize
        },
        methods: {
            labelHead: function (h, { column, $index }) {
                let l = column.label.length
                let f = 16
                const sortLenth = column.sortable ? 30 : 0
                column.minWidth = f * (l + 2) + sortLenth //加上一个文字长度 ,如果有排序还需加上排序的宽度
                return h('div', { style: { display: 'inline-block' } }, [
                    column.label
                ])
            },
            sortChange(obj) {
                if (!obj.order) {
                    this.orderByRule = ''
                } else {
                    this.orderByField = obj.prop
                    this.orderByRule = obj.order === 'ascending' ? 'asc' : 'desc'
                }
                this.$emit(
                    'pageChange',
                    this.currentPage,
                    this.pageSize,
                    this.orderByField,
                    this.orderByRule
                )
            },
            // 给指定行添加颜色
            tableRowClassName({ row, rowIndex }) {
                if (row.statusCode) {
                    if (row.statusCode === '0') return ''
                    else return 'red-row'
                } else {
                    return ''
                }
            },
            rowClass() {
                // return 'color: #000;border: 1px solid #000; text-align: center;'
            },
            handleHighlight(currentRow, oldCurrentRow) {
                this.$emit('getCurrentRow', currentRow, oldCurrentRow)
            },
            handleSelectionChange(val) {
                // this.multipleSelection = val;
                this.$emit('getSelections', val)
            },
            handleSelect(selection, row, index) {
                this.$emit('rowSelect', selection, row, index)
            },
            handleSelectAll(selection) {
                this.$emit('rowSelectAll', selection)
            },
            clickButton(index, list, methods) {
                //操作按钮
                this.$emit(methods, list[index])
            },
            clickBtn(index, list, methods, name) {
                this.$emit(methods, list[index])
            },
            setCurrent(row) {
                if (typeof row === 'undefined') return
                this.$nextTick(() => {
                    if (this.dataList.length) {
                        this.$refs.singleTable.setCurrentRow(row)
                    }
                })
            },
            handleSizeChange(val) {
                this.pageSize = val
                if (this.currentPage !== 1) {
                    this.currentPage = 1
                }
                this.$emit(
                    'pageChange',
                    this.currentPage,
                    this.pageSize,
                    this.orderByField,
                    this.orderByRule
                )
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.$emit('pageChange', this.currentPage, this.pageSize)
            },
            clickRow(row, column, event) {
                if (
                    event.target.nodeName != 'INPUT' &&
                    // event.target.nodeName != 'SPAN' &&
                    event.target.nodeName != 'BUTTON'
                    // event.target.nodeName != 'TD'
                ) {
                    this.$emit('rowClick', row)
                    if (this.isHighlight) {
                        const $table = this.$refs.singleTable
                        // 因为不知道表格中包裹了多少层元素,所以需要通过查询点击元素的所有父级元素找到符合条件的类名进行判断
                        const haveHeightLight = [...event.path].some((el) => {
                            if (el.classList)
                                return (
                                    Array.from(el.classList).indexOf(
                                        'ms-table__row'
                                    ) !== -1 &&
                                    Array.from(el.classList).indexOf(
                                        'current-row'
                                    ) !== -1
                                )
                        })
                        try {
                            $table[0].setCurrentRow(haveHeightLight ? '' : row)
                        } catch (e) {
                            $table.setCurrentRow(haveHeightLight ? '' : row)
                        }
                    }
                }
            },
            toggleRowSelection(row, selected) {
                this.$refs.singleTable.toggleRowSelection(row, selected)
            },
            exportExcel(name) {
                var that = this
                var html =
                    "<html><head><meta charset='utf-8' /></head><body>" +
                    document.getElementById('exportTable').outerHTML +
                    '</body></html>'
                var blob = new Blob([html], {
                    type: 'application/vnd.ms-excel'
                })
                var a = document.getElementById('aTags')
                a.href = URL.createObjectURL(blob)
                // 设置文件名
                a.download = name
                a.click()
            },
            printExcel(name) {
                if (this.getExplorer() == 'IE') {
                    this.pagesetup_null()
                }
                var myWindow = window.open('', name)
                var bodyHtml =
                    window.document.getElementById('exportTable').outerHTML
                var headHtml = document.head.outerHTML
                headHtml = headHtml.replace('screen', 'screen, print')
                //重新写入文档流
                myWindow.document.write('<html>')
                myWindow.document.write(headHtml)
                myWindow.document.write('<body >')
                myWindow.document.write(bodyHtml)
                myWindow.document.write(
                    '<script>setTimeout(function() {window.print(); window.close();}, 1)</'
                )
                myWindow.document.write('script>')
                myWindow.document.write('</body></html>')
            },
            pagesetup_null() {
                var hkey_root, hkey_path, hkey_key
                hkey_root = 'HKEY_CURRENT_USER'
                hkey_path = '\\Software\\Microsoft\\Internet Explorer\\PageSetup\\'
                try {
                    var RegWsh = new ActiveXObject('WScript.Shell')
                    hkey_key = 'header'
                    RegWsh.RegWrite(hkey_root + hkey_path + hkey_key, '')
                    hkey_key = 'footer'
                    RegWsh.RegWrite(hkey_root + hkey_path + hkey_key, '')
                } catch (e) { }
            },
            getExplorer() {
                var explorer = window.navigator.userAgent
                //ie
                if (explorer.indexOf('MSIE') >= 0) {
                    return 'IE'
                }
                //firefox
                else if (explorer.indexOf('Firefox') >= 0) {
                    return 'Firefox'
                }
                //Chrome
                else if (explorer.indexOf('Chrome') >= 0) {
                    return 'Chrome'
                }
                //Opera
                else if (explorer.indexOf('Opera') >= 0) {
                    return 'Opera'
                }
                //Safari
                else if (explorer.indexOf('Safari') >= 0) {
                    return 'Safari'
                }
            },
            record(params, methods, name) {
                this.$emit('record', params, methods, name)
            },
            clickDetails(methods, listIndex) {
                this.$emit(methods, listIndex)
            }
        },
        mounted() {
            window.xx = this
        }
    }
</script>