<template>
  <div>
    <el-card class="box-card">
      <el-form :inline="true">
        <el-form-item label="城市">
          <el-select
            v-model="form.cityCode"
            placeholder="全部"
            collapse-tags
            clearable
            filterable
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代理商：">
          <el-input
            v-model.trim="form.agentName"
            placeholder="请输入代理商名称"
            clearable
          />
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <commonTableMulti
        fixed="right"
        :headList="headList"
        defaultHeight="520"
        :dataList="dataTable"
        :buttonList="buttonList"
        ref="commonTable"
        @pageChange="handleCurrentChange"
        @edit="handleEdit"
        :originPagesSize="page_size"
      ></commonTableMulti>
    </el-card>
    <cityDegreesModal ref="Update" @refreshDataList="search">
    </cityDegreesModal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import cityDegreesModal from "./model/city-degrees-modal.vue";
import commonTableMulti from "./model/common-table-multi.vue";
export default {
  component: {
    commonTableMulti,
    cityDegreesModal,
  },
  data() {
    return {
      headList: [
        { name: "城市", value: "city_name", width: "120" },
        { name: "代理商", value: "agent_name" },
        {
          name: "常规充电",
          value: "test",
          width: "150",
          children: [
            {
              name: "最低度数",
              value: "min_degree",
            },
            {
              name: "最高度数",
              value: "max_degree",
            },
          ],
        },
        {
          name: "应急补电",
          value: "emergency_charging_price",
          width: "150",
          children: [
            {
              name: "常规时间",
              value: "convention",
            },
            {
              name: "夜间时间",
              value: "night",
            },
          ],
        },
      ],
      buttonList: [
        {
          type: "info",
          methods: "edit",
          name: "配置",
        },
      ],
      cityOptions: [],
      form: {
        name: "",
        orderType: "",
        status: "",
        cityCode: "",
      },
      statusOptions: [
        { label: "停业", value: 0 },
        { label: "营业", value: 1 },
      ],
      dataTable: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      addOrUpdateVisible: false,
    };
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  mounted() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.search();
  },
  methods: {
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {
        name: "",
        orderType: "",
        status: "",
        cityCode: "",
      };
      this.createTime = [];
      this.page_index = 1;
      this.loadDataTable();
    },
    handleSizeChange(val, valsize) {
      this.page_size = valsize;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        ...this.form,
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/api/config/area/charging/range/query/${offset}/${limit}`, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.data.items;
          this.dataTable.forEach((item, index) => {
            item.convention = `${item.emergency_charging_price[0].charging[0].degree}度 / ${item.emergency_charging_price[0].charging[0].price}元\n${item.emergency_charging_price[0].charging[1].degree}度 / ${item.emergency_charging_price[0].charging[1].price}元`;
            item.night = `${item.emergency_charging_price[1].charging[0].degree}度 / ${item.emergency_charging_price[1].charging[0].price}元\n${item.emergency_charging_price[1].charging[1].degree}度 / ${item.emergency_charging_price[1].charging[1].price}元`;
          });
          this.page_total = response.data.data.total;
          this.$refs.commonTable.totalSize = response.data.data.total;
          //   this.$nextTick(() => {
          //     this.$refs["dataTable"].doLayout();
          //   });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    handleEdit(row) {
      console.log(row, "asdasd");
      this.$refs.Update.visible = true;
      this.$refs.Update.init(row);
    },
    handleCurrentChange(val, size) {
      console.log(val);
      console.log(size);
      this.page_size = size;
      this.page_index = val;
      // this.page_index = val;
      this.loadDataTable();
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}
.box-card {
  margin-top: 15px;
}
::v-deep .el-tooltip {
  white-space: pre-wrap !important;
}
</style>