<template>
  <el-dialog
    class="level-one-dialog"
    v-dialogDrag
    title="导出数据"
    :visible.sync="model_dialog"
    :close-on-click-modal="false"
    append-to-body
    width="400px"
  >
    <el-scrollbar
      style="height: 220px"
      wrapStyle="padding-right:20px;overflow-x:hidden;"
      viewStyle=""
    >
      <div>
        <el-checkbox-group v-model="checked_headers">
          <el-checkbox
            v-for="item in export_headers"
            :label="item"
            :key="item.value"
            class="filterContent"
          >
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </el-scrollbar>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="exportData">导出</el-button>
      <el-button @click="model_dialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "export_data_model",
  props: {
    dialog: {
      type: Boolean,
      default() {
        return false;
      },
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      export_headers: [],
      checked_headers: [],
      model_dialog: false,
    };
  },
  methods: {
    init() {
      this.export_headers = [];
      this.checked_headers = [];
    },
    dealData(data) {
      this.export_headers = data.filter((item) => item.label !== "操作");
      this.checked_headers = this.export_headers;
    },
    async exportData() {
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      this.model_dialog = false;
      this.$emit("exportExcel", this.checked_headers);
    },
  },
  watch: {
    dialog: {
      deep: true,
      immediate: true,
      handler(value) {
        this.model_dialog = value;
        if (value && this.columns) {
          this.dealData(JSON.parse(JSON.stringify(this.columns)));
        }
      },
    },
    model_dialog: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit("update:close", value);
      },
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
::v-deep .el-dialog__body {
  padding: 20px !important;
}
.filterContent {
  display: block;
  margin-bottom: 7px;
}
.filterContent:hover {
  background-color: #edfffd;
  text-shadow: 1px 1px 2px #7dbdff;
  box-shadow: 0 1px 0 #cddcec;
}
</style>