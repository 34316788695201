
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <!--            <div slot="header" class="clearfix">
              <span>数据筛选</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="filter">高级查询</el-button>
          </div>-->
    <div class="text item">
      <el-form
        ref="searchValue"
        style="padding-right: 10px"
        :model="searchValue"
        label-width="110px"
      >
        <el-row>
          <el-col :span="5">
            <el-form-item label="用户手机号：" style="margin-bottom: 0">
              <el-input
                v-model.trim="searchValue.cellphone"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="用户编号：" style="margin-bottom: 0">
              <el-input
                v-model.trim="searchValue.userNumber"
                placeholder="请输入"
                clearable
                maxlength="10"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="所属城市：" style="margin-bottom: 0">
              <el-select
                v-model="searchValue.first_city_code"
                placeholder="全部"
                multiple
                collapse-tags
                clearable
                filterable
              >
                <el-option
                  v-for="item in restrictedCityList"
                  :key="item.cityCode"
                  :label="item.cityName"
                  :value="item.cityCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="float: right; text-align: right">
            <el-button type="primary" @click="clickSearch">查询</el-button>
            <el-button type="default" @click="clear">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>用户钱包</span>
      <el-button style="float: right" @click="exportOrder">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
      @selection-change="rowSelected"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="手机号码"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="first_city_name"
        label="城市"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="total_amount"
        label="账户余额（元）"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="recharge_total_amount"
        label="充值合计（元）"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="pay_total_amount"
        label="支付合计（元）"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="order_total_number"
        label="累计下单数"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="last_recharge_time"
        label="最后充值时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="last_pay_time"
        label="最后支付时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button
            @click="showUserAccountRecord(scope.row)"
            type="text"
            size="small"
            >明细</el-button
          >
          <!-- <el-button @click="refund(scope.row)" type="text" size="small" v-if="isShowBtn" >退款</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
  <RefundModel
    :dialog.sync="refundDialogVisible"
    :id="selectId"
    @search="clickSearch"
  />
  <UserAccountRecord
    :dialog.sync="userAccountRecordVisible"
    ref="userAccountRecord"
    @search="clickSearch"
  />
  <el-dialog title="温馨提示" :visible.sync="prompDialogVisible" width="30%">
    <span>用户是否已申请退款？可在退款对账中确认哦~</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="refundCancle">取 消</el-button>
      <el-button type="primary" @click="showRefundConfirm">确 定</el-button>
    </span>
  </el-dialog>
</div>
