
<el-dialog :title="this.editState == 'edit' ? '编辑' : '新增'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="170px">
        <el-form-item label="券名：" prop="name">
            <el-input v-model.trim="dataForm.name" placeholder="请输入券名" clearable maxlength="35" />
        </el-form-item>
        <el-form-item label="可用范围：" prop="order_type">
            <el-select v-model="dataForm.order_type" placeholder="请选择可用范围" @change="orderTypeChange" collapse-tags
                clearable>
                <el-option v-for="item in scopeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="类型：" prop="discount_type">
            <el-select v-model="dataForm.discount_type" placeholder="请选择类型" collapse-tags @change="typeChange"
                clearable>
                <el-option v-for="item in ticketList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <!-- 现金券 -->
        <el-form-item v-if="dataForm.discount_type === 0" label="抵扣金额（元）：" prop="discount">
            <el-input v-model.trim="dataForm.discount" placeholder="抵扣金额（元）：" clearable maxlength="9" />
        </el-form-item>
        <!-- 度数券 -->
        <div v-if="dataForm.discount_type === 2">
            <el-form-item label="抵扣度数：" prop="discount_electric">
                <el-input v-model.trim="dataForm.discount_electric" placeholder="请输入抵扣度数（元）" clearable
                    maxlength="11" @input="" oninput="value=value.replace(/[^0-9]/g,'')" />
            </el-form-item>
            <el-form-item label="电费单价（度数）" prop="electric_unit_price">
                <el-input v-model.trim="dataForm.electric_unit_price" placeholder="请输入电费单价（度数）" clearable
                    maxlength="9" />
            </el-form-item>
        </div>
        <el-form-item v-if="dataForm.discount_type === 3" label="时间限制" prop="time_interval_limit">
            <el-select v-model="dataForm.time_interval_limit" placeholder="请选择时间限制" collapse-tags clearable>
                <el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-else label="预估折算金额（元）：" prop="expect_discount_amount">
            <el-input v-model.trim="dataForm.expect_discount_amount" placeholder="预估折算金额（元）自动计算" clearable
                maxlength="20" :disabled="true" />
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
        <el-button @click="visible = false">取消</el-button>
    </span>
</el-dialog>
