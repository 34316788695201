<!--
 * @Author: ljf
 * @Date: 2022-06-20 19:04:07
 * @LastEditors: ljf
 * @LastEditTime: 2022-06-21 10:48:04
 * @FilePath: \lxm-admin\src\views\system\role\RoleTable.vue
 * @Description: 
 * 
-->
<template>
  <div style="height: 100%">
    <base-table
      ref="choose-table"
      :data="tableData"
      showIndex
      :height="height"
      :columns="tableColumns"
      :totalCount="totalCount"
      @page-change="pageChange"
    ></base-table>
  </div>
</template>

<script>
import { getRoleUserPage } from "api/system/role-api";
export default {
  name: "RoleTable",
  props: {
    role_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      height: "60vh",
      tableData: [],
      totalCount: 0,
      tableColumns: [
        {
          label: "账号",
          prop: "user_name",
          style: "center",
          minWidth: "80",
        },
        {
          label: "姓名",
          prop: "real_name",
          style: "center",
          minWidth: "80",
        },
        {
          label: "性别",
          prop: "sex_name",
          style: "center",
          minWidth: "80",
        },
        {
          label: "手机号",
          prop: "cellphone",
          style: "center",
          minWidth: "80",
        },
        {
          label: "微信号",
          prop: "wechat_no",
          style: "center",
          minWidth: "80",
        },
        {
          label: "所属城市",
          prop: "first_city_name",
          style: "center",
          minWidth: "80",
        },
      ],
      page: 1,
      limit: 10,
      offset: 0,
    };
  },
  methods: {
    init() {
      this.search();
    },
    pageChange(page) {
      this.limit = page.limit;
      this.offset = page.offset;
      this.search();
    },
    async search() {
      var data = await getRoleUserPage(
        this.page,
        this.offset,
        this.limit,
        this.role_id
      );
      let arr = data.items;
      arr.forEach((item) => {
        item.sex_name = item.sex == 0 ? "保密" : item.sex == 1 ? "男" : "女";
      });
      this.tableData = data.items;
      this.totalCount = data.total_count;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
</style>