
<el-dialog
  :title="'账户明细-' + this.dataItem.user_number"
  :close-on-click-modal="false"
  :visible.sync="visible"
  center
>
  <div class="height:100%;">
    <el-card class="box-card">
      <el-form :inline="true">
        <el-form-item label="收入/支出">
          <el-select v-model="form.record_type" placeholder="全部" clearable>
            <el-option :value="1" label="收入" />
            <el-option :value="-1" label="支出" />
          </el-select>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="float: right" @click="exportData">导出</el-button>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
        <el-table-column
          prop="type_name"
          label="名称"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_no"
          label="订单编号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="收入/支出"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span
              style="color: red"
              v-if="
                scope.row.type == '0' ||
                scope.row.type == '3' ||
                scope.row.type == '5'
              "
              >{{ "+" + scope.row.amount }}</span
            >
            <span
              style="color: green"
              v-if="
                scope.row.type == '1' ||
                scope.row.type == '2' ||
                scope.row.type == '4'
              "
              >{{
                scope.row.amount < 0 ? scope.row.amount : -scope.row.amount
              }}</span
            >
            <span v-if="scope.row.type == -1">{{ scope.row.amount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="early_amount"
          label="前"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="current_amount"
          label="后"
          min-width="100"
          align="center"
        ></el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
  </div>
</el-dialog>
