<template>
  <el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="账户姓名" prop="userName">
        <el-input v-model="dataForm.userName" placeholder="账户名"></el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="loginName">
        <el-input v-model="dataForm.loginName" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="账户密码" prop="loginPassword">
        <el-input v-model="dataForm.loginPassword" placeholder="账户密码"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item label="账号类别" prop="category">
        <el-radio v-model="dataForm.category" label=1>总部账号</el-radio>
        <el-radio v-model="dataForm.category" label=2>代理商账号</el-radio>
        <!-- <el-input v-model="dataForm.category" placeholder="账号类别"></el-input> -->
      </el-form-item>
      <!-- <el-form-item label="状态" prop="status">
        <el-input v-model="dataForm.status" placeholder="状态"></el-input>
      </el-form-item> -->
      <el-form-item label="系统角色">
        <!-- <el-input v-model="user.role_ids"></el-input> -->
        <el-select v-model="dataForm.role_ids" clearable filterable multiple collapse-tags>
          <el-option v-for="item in role_options" :key="item.id" :label="item.role_name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" v-model="dataForm.remark" placeholder="备注"></el-input>
      </el-form-item>
      <!-- <el-form-item label="" prop="createId">
        <el-input v-model="dataForm.createId" placeholder=""></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="" prop="createTime">
        <el-input v-model="dataForm.createTime" placeholder=""></el-input>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import sysAccountApi from "../../api/sys_account_api";
import sysAccountRoleApi from "../../api/sys_account_role_api";
import { getAllRole } from "api/system/role-api";
import Cookies from "js-cookie";
export default {
  // name: 'AccountAddOrUpdate',
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        userName: '',
        loginName: '',
        loginPassword: '',
        mobile: '',
        category: 0,
        status: '',
        remark: '',
        createId: '',
        createTime: '',
        role_ids:""
      },
      dataRule: {
        userName: [
          { required: true, message: '账户姓名不能为空', trigger: 'blur' }
        ],
        loginName: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        loginPassword: [
          { required: true, message: 'MD5加密后的密文不能为空', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态（0无效；1有效）不能为空', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '备注不能为空', trigger: 'blur' }
        ],
        createId: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        createTime: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      },

      role_options: [],
    }
  },
  methods: {
    async init(row) {
      this.role_options =await  getAllRole(Cookies.get("appId"));
      this.visible = true
      //todo
      if (row) {
        //获取角色id
       let res= await sysAccountRoleApi.queryByAccoutId(row.id);
       if(res.data.code==0){
        this.dataForm.role_ids=res.data.ids.split(",")
       }
        this.dataForm.id = row.id
        this.dataForm.userName = row.user_name
        this.dataForm.loginName = row.login_name
        // this.dataForm.loginPassword = row.login_password
        this.dataForm.mobile = row.mobile
        // this.dataForm.status = row.status
        this.dataForm.remark = row.remark
        this.dataForm.category=row.category
      } else{
        this.dataForm.id = undefined
        this.dataForm.userName = ""
        this.dataForm.loginName = ""
        // this.dataForm.loginPassword = ""
        this.dataForm.mobile = ""
        // this.dataForm.status = ""
        this.dataForm.remark = ""
        this.dataForm.role_ids=""
        this.dataForm.category=0
      }

      // this.dataForm.id = id || 0
      // this.visible = true
      // this.$nextTick(() => {
      //   this.$refs['dataForm'].resetFields()
      //   if (this.dataForm.id) {
      //     this.$http({
      //       url: this.$http.adornUrl(`/partner/sysaccount/info/${this.dataForm.id}`),
      //       method: 'get',
      //       params: this.$http.adornParams()
      //     }).then(({ data }) => {
      //       if (data && data.code === 0) {
      //         this.dataForm.userName = data.sysAccount.userName
      //         this.dataForm.loginName = data.sysAccount.loginName
      //         this.dataForm.loginPassword = data.sysAccount.loginPassword
      //         this.dataForm.mobile = data.sysAccount.mobile
      //         this.dataForm.status = data.sysAccount.status
      //         this.dataForm.remark = data.sysAccount.remark
      //         this.dataForm.createId = data.sysAccount.createId
      //         this.dataForm.createTime = data.sysAccount.createTime
      //       }
      //     })
      //   }
      // })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(async valid => {
        if (!valid) return
        let res = await sysAccountApi.saveOrupdate(this.dataForm.id ? 'update' : 'save', this.dataForm)
        if (res.data.code != 0) {
          this.$message.error(res.data.msg)
          return
        }
        this.visible = false
        this.$emit('refreshDataList')
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 1500,
          // onClose: () => {
          //   this.visible = false
          //   this.$emit('refreshDataList')
          // }
        })
        // this.$refs['dataForm'].validate((valid) => {
        //   if (valid) {
        //     this.$http({
        //       url: this.$http.adornUrl(`/partner/sysaccount/${!this.dataForm.id ? 'save' : 'update'}`),
        //       method: 'post',
        //       data: this.$http.adornData({
        //         'id': this.dataForm.id || undefined,
        //         'userName': this.dataForm.userName,
        //         'loginName': this.dataForm.loginName,
        //         'loginPassword': this.dataForm.loginPassword,
        //         'mobile': this.dataForm.mobile,
        //         'status': this.dataForm.status,
        //         'remark': this.dataForm.remark,
        //         'createId': this.dataForm.createId,
        //         'createTime': this.dataForm.createTime
        //       })
        //     }).then(({ data }) => {
        //       if (data && data.code === 0) {
        //         this.$message({
        //           message: '操作成功',
        //           type: 'success',
        //           duration: 1500,
        //           onClose: () => {
        //             this.visible = false
        //             this.$emit('refreshDataList')
        //           }
        //         })
        //       } else {
        //         this.$message.error(data.msg)
        //       }
        //     })
        //   }
      })
    }
  }
}
</script>
