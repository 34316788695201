<template>
  <div class="height:100%;">
    <el-card class="box-card">
        <el-row>
            <el-col :span="4">
                <div class="block-view-head">
                    <span class="block-view-title" >订单统计</span>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="48" style="margin-top:11px;">
            <el-col :span="6">
                <div class="view-block left-line-blue" style="border:1px solid rgba(200,200,200,0.5);border-radius: 0;">
                  <span class="outImag1">
                    <i class="el-icon-document" style="margin: 10px;"/>
                  </span>
                    <span class="view-title" style="margin-left: 40px">订单</span>
                    <span style="float:right;margin-right: 20px">本月</span>
                    <p class="view-count" style="text-align: center;">{{growthData.currentMonthOrderCount}}</p>
                    <p class="view-add" style="text-align: center;">{{growthData.orderCountYoYGrowthRate}} 同比增长</p>
<!--                    <img class="view-icon" src="../../assets/img/project_icon_a.png" alt="">-->
                </div>
            </el-col>
            <el-col :span="6">
                <div class="view-block left-line-orange" style="border:1px solid rgba(200,200,200,0.5);border-radius: 0;">
                  <span class="outImag2">
                    <i class="el-icon-money" style="margin: 10px;"/>
                  </span>
                    <span class="view-title" style="margin-left: 40px">订单金额</span>
                    <span style="float:right;margin-right: 20px">本月</span>
                    <p class="view-count" style="text-align: center;">{{growthData.currentMonthOrderPrice}}</p>
                  <p class="view-add" style="text-align: center;">{{growthData.orderPriceYoYGrowthRate}} 同比增长</p>
                  <!--<img class="view-icon" src="../../assets/img/project_icon_b.png" alt="">-->
                </div>
            </el-col>
        </el-row>
    </el-card>
    <el-card class="box-card">
      <el-row :gutter="24" :style="{height:(pageHeight - 110) * 0.34+'px','box-sizing':'border-box','padding-top':'16px','margin-left': '0px','margin-right': '0px'}">
        <commonLineStatistic yAxisDataMinInterval="1000" interFace="/api/order/analysis/getLineChart" lineChartName="订单统计" lineChartId="order-line-chart" :xAxisData="orderXAxisData"/>
      </el-row>
    </el-card>
    <el-card class="box-card">
      <el-row :style="{height:(pageHeight - 110) * 0.34+'px','box-sizing':'border-box','padding-top':'16px'}">
        <commonLineStatistic :dateSpans="dateSpans" yAxisDataMinInterval="20"  interFace="/api/order/analysis/getWeekLineChart" lineChartName="平均客单价统计" lineChartId="week-day-line-chart" :xAxisData="weekDayXAxisData"/>
      </el-row>
    </el-card>
  </div>
</template>

<script>
// import $ from '@/assets/js/jquery-vendor.js'
import commonLineStatistic from "@/views/reportForm/component/common-line-statistic.vue";
export default {
    name: 'orderStatistics',
    components:{
      commonLineStatistic
    },
    data () {
        return {
          // 同比区域展示的数据
          growthData:{},
          orderXAxisData:["0:00-2:00","2:00-4:00","4:00-6:00","6:00-8:00","8:00-10:00","10:00-12:00","12:00-14:00","14:00-16:00","16:00-18:00","18:00-20:00","20:00-22:00","22:00-0:00"],
          weekDayXAxisData:["周一","周二","周三","周四","周五","周六","周日"],
          pageHeight: 0,
          // 日期按钮
          dateSpans:[
            {data_value:'week',data_name:'本周'},
            {data_value:'month',data_name:'本月'},
            {data_value:'quarter',data_name:'本季度'},
          ],
        }
    },
    methods: {
      setPageHeight() {
        this.pageHeight = document.documentElement.clientHeight - 118 - 24
      },
      async getGrowthData(){
        try {
          let condition={
          }
          let res = await this.axiosHelper.get("/api/order/analysis/getGrowthData",{params: condition});
          if(res.status === 200 && !!res.data){
            this.growthData = res.data;
          }
        }catch (e){
          this.$message.error("获取同比增长数据失败");
        }
      },
    },
    created(){
      this.getGrowthData();
    },
    beforeMount () {
        let _this = this;
        _this.setPageHeight();
        window.onresize = ()=>{
          _this.setPageHeight();
        };
    }
}
</script>

<style scoped>
.line-chart {
  margin-top: 25px;
  float: left;
  width: 95%;
  height: 180px;
  margin-left: 20px;
}
.span-title{
  display: inline-block;
  position: relative;
  line-height: 18px;
  margin: 7px 0;
  padding-left: 20px;
  font-weight: bold;
  font-size: 18px;
}
.count{
    text-align: center;
    font-size: 40px;
}
.title{
    text-align: center;
    font-size: 30px;
}

.view-block{
    position: relative;
    padding: 18px 0 18px 24px;
    font-size: 14px;
    overflow: hidden;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 6px;
    /*color: #fff;*/
}
.view-block .view-title{
    padding-bottom: 10px;
}
.view-block .view-count{
    height: 34px;
    line-height: 34px;
    font-size: 32px;
}
.view-block .view-add{
  height: 34px;
  line-height: 34px;
  font-size: 15px;
  color: #999999;
}
.view-block  .view-icon{
    position: absolute;
    top: 32px;
    right: 30px;
}
.left-line-blue ::v-deep .outImag1{
  position: absolute;
  left: 20px;
  top: 10px;
  width: 40px;
  height: 40px;
  background: inherit;
  background-color: rgba(82, 193, 245, 1);
  border: 0 none;
  border-radius: 40px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: 'FontAwesome', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 20px;
}
.left-line-orange ::v-deep .outImag2 {
  position: absolute;
  left: 20px;
  top: 10px;
  width: 40px;
  height: 40px;
  background: inherit;
  background-color: rgba(75, 206, 208, 1);
  border: 0px none;
  border-radius: 40px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: 'FontAwesome', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 20px;
}

::v-deep .block-view-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #0079FE;
}
.box-card {
  margin-top: 15px;
}
</style>