<template>
    <el-dialog class="showAll_dialog" :title="editState === 'add' ? '新增' : '编辑'" width="800px" :height="'80%'"
        :max-height="'600px'" :close-on-click-modal="false" :visible.sync="visible">
        <el-form :model="form" ref="form" v-loading="formLoading" :rules="dataRule" label-width="130px">
            <el-row>
                <el-col v-if="editState === 'edit'" :span="12">
                    <el-form-item label="ID：">
                        <el-input v-model.trim="form.id" readonly placeholder="请填写id" clearable maxlength="35" />
                    </el-form-item>
                </el-col>
                <el-col :span="editState === 'edit' ? 12 : 24">
                    <el-form-item v-if="editState === 'add'" label="渠道码：" prop="channel_code">
                        <el-input v-model.trim="form.channel_code" placeholder="请填写渠道码" clearable maxlength="35" />
                    </el-form-item>
                    <el-form-item v-else label="渠道码：">
                        <el-input v-model.trim="form.channel_code" readonly placeholder="请填写渠道码" clearable maxlength="35" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="渠道商：" prop="channel_merchant_name">
                        <el-input v-model.trim="form.channel_merchant_name" placeholder="请填写渠道商" clearable
                            maxlength="35" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="手机号码：" prop="phone_number">
                        <el-input v-model.trim="form.phone_number" placeholder="请填写手机号码" clearable maxlength="35" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="联系人：">
                        <el-input v-model.trim="form.contacts" placeholder="请填写联系人" clearable maxlength="35" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注：">
                        <el-input v-model="form.remarks" type="textarea" :rows="5" placeholder="备注信息填写位置">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-foote r">
            <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
            <el-button @click="visible = false">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
    import axios from "@/utils/axiosHelper";
    export default {
        props: {
            editState: {
                type: String,
                default: "add",
            },
        },
        data() {
            return {
                visible: false,
                formLoading: false,
                form: {
                    id: '',
                    channel_code: '',
                    channel_merchant_name: '',
                    phone_number: '',
                    contacts: '',
                    remarks: ''
                },
                dataRule: {
                    phone_number: [
                        {
                            required: true,
                            message: '手机号必须是11位,且必须是数字',
                            trigger: ["blur", "change"],
                            pattern: /^\d{11}$/
                        },
                    ],
                    channel_code: [
                        {
                            required: true,
                            message: "请填写渠道码",
                            trigger: ["blur", "change"],
                        },
                    ],
                    channel_merchant_name: [
                        {
                            required: true,
                            message: "请填写渠道商",
                            trigger: ["blur", "change"],
                        },
                    ]
                }
            }
        },
        methods: {
            init(row) {
                this.$nextTick(() => {
                    console.log(row)
                    if (row) {
                        this.form = JSON.parse(JSON.stringify(row))
                    } else {
                        this.form = {
                            channel_code: '',
                            channel_merchant_name: '',
                            channel_code: '',
                            phone_number: '',
                            contacts: '',
                            remarks: ''
                        }
                    }
                })
            },
            // 表单提交
            dataFormSubmit() {
                this.$refs["form"].validate(async (valid) => {
                    if (!valid) return;
                    this.showLoading("加载中");
                    let api = this.editState === 'add' ? '/api/channel/merchant/create' : '/api/channel/merchant/update'
                    axios
                        .post(api, this.form)
                        .then((res) => {
                            this.hideLoading();
                            if (res.data.code != 0) {
                                this.$message.error(res.data.msg);
                                return;
                            }
                            this.visible = false;
                            this.$emit("refreshDataList");
                            this.$message({
                                message: "操作成功",
                                type: "success",
                                duration: 1500,
                            });
                        })
                        .catch((error) => {
                            console.log(error, 'error')
                            this.hideLoading();
                            this.$message.error("加载失败");
                        });
                });
            },
        },
    }
</script>
<style lang="scss" scoped>

</style>