
<div>
  <el-card class="iconShow">
    <el-row :gutter="24">
      <el-col :span="8">
        <div
          class="view-block left-line-blue"
          style="
            border: 1px solid rgba(200, 200, 200, 0.5);
            border-radius: 0;
            padding-right: 10px;
            position: relative;
          "
        >
          <div class="outImag1">
            <img class="imgCss" src="../../assets/img/subsidy-total.png" />
          </div>
          <div class="textCss">
            <p class="view-count" style="text-align: center">
              {{ statistics.totalAmount }}
            </p>
            <p class="view-add" style="text-align: center">补贴池总额</p>
          </div>
          <div class="btn">
            <el-button
              plain
              type="primary"
              size="mini"
              style="padding: 5px; font-size: 10px"
              @click="toSubsidyDetail"
              >总部补贴明细</el-button
            >
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div
          class="view-block left-line-blue"
          style="border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 0"
        >
          <div class="outImag1">
            <img
              class="imgCss"
              src="../../assets/img/subsidy-remaining.png"
            />
          </div>
          <div class="textCss">
            <p class="view-count" style="text-align: center">
              {{ statistics.remainingAmount }}
            </p>
            <p class="view-add" style="text-align: center">补贴池余额</p>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div
          class="view-block left-line-blue"
          style="border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 0"
        >
          <div class="outImag1">
            <img class="imgCss" src="../../assets/img/subsidy-used.png" />
          </div>
          <div class="textCss">
            <!-- <span class="view-title" style="margin-left: 40px">80033</span> -->
            <p class="view-count" style="text-align: center">
              {{ statistics.usedAmount }}
            </p>
            <p class="view-add" style="text-align: center">本月已用金额</p>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-card>
  <el-card class="search">
    <el-form :model="dataForm">
      <el-row class="row" :gutter="24">
        <el-col :span="5">
          <el-form-item label="补贴类型" label-width="75px">
            <el-select
              v-model="dataForm.flowType"
              placeholder="请选择补贴类型"
              clearable
            >
              <el-option
                v-for="item in flowTypeList"
                :key="item.flowType"
                :label="item.flowName"
                :value="item.flowType"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="用户手机号" label-width="100px">
            <el-input v-model="dataForm.cellphone" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="用户编号" label-width="100px">
            <el-input
              v-model="dataForm.userNumber"
              clearable
              maxlength="10"
              oninput="value=value.replace(/[^0-9]/g,'')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="名称" label-width="60px">
            <el-input v-model="dataForm.couponName" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="更新时间" label-width="90px">
            <el-date-picker
              v-model="dataForm.createRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
              @change="choseDate"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row" style="margin-top: 12px" :gutter="24">
        <el-col :span="5">
          <el-form-item label="状态" label-width="75px">
            <el-select
              v-model="dataForm.expandState"
              placeholder="请选择状态"
              clearable
            >
              <el-option
                v-for="item in stateList"
                :key="item.state"
                :label="item.stateName"
                :value="item.state"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="活动名称" label-width="100px">
            <el-input v-model="dataForm.promotionName" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="城市" label-width="75px">
            <el-select
              v-model="dataForm.cityCode"
              placeholder="请选择状态"
              filterable
            >
              <el-option
                v-for="item in restrictedCityList"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label-width="25px">
            <el-button type="primary" @click="search">查询</el-button>
            <el-button @click="clear()">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
  <el-card>
    <div class="tableHeaderCss">
      <div>补贴用户明细</div>
      <div>
        <el-button type="primary" @click="exportExcel">导出</el-button>
      </div>
    </div>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
      max-height="400"
    >
      <el-table-column
        prop="flow_name"
        header-align="center"
        align="center"
        min-width="100"
        label="补贴类型"
      >
      </el-table-column>
      <el-table-column
        prop="user_number"
        header-align="center"
        align="center"
        min-width="150"
        label="用户编号"
      >
      </el-table-column>
      <el-table-column
        prop="cellphone"
        header-align="center"
        align="center"
        min-width="150"
        label="用户手机号"
      >
      </el-table-column>
      <el-table-column
        prop="promotion_name"
        header-align="center"
        align="center"
        min-width="150"
        label="活动名称"
      >
      </el-table-column>
      <el-table-column
        prop="coupon_name"
        header-align="center"
        align="center"
        min-width="120"
        label="名称"
      >
        <template slot-scope="scope">
          {{ scope.row.coupon_name || "—— ——" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="amount"
        header-align="center"
        align="center"
        min-width="100"
        label="补贴折算金额(元)"
      >
      </el-table-column>
      <el-table-column
        prop="create_time"
        header-align="center"
        align="center"
        min-width="140"
        label="更新时间"
      >
      </el-table-column>
      <!--
    <el-table-column prop="end_time" header-align="center" align="center" min-width="140" label="失效日期">
      <template slot-scope="scope">
     {{ scope.row.end_time || '—— ——' }}
    </template>
    </el-table-column>
      -->
      <el-table-column
        prop="state_name"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          {{ scope.row.state_name || "—— ——" }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </el-card>
  <headquarters_subsidy_detail
    v-if="subsidyDetailVisible"
    ref="subsidyDetail"
    :initialData="dataForm"
    @refreshDataList="getDataList"
  ></headquarters_subsidy_detail>
</div>
