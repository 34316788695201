<template>
    <el-dialog title="设置结算订单"  :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
           <div>
            您所选择的订单将被结算，且无法修改哦
           </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import agentApi from "../../../api/agent_api";
    export default {
        props: {
            dialog: {
                type: Boolean,
                default () {
                    return false;
                }
            },
            ids: {
                type: Array,
                default: []
            }

        },
        data() {
            return {
                dialogVisible: false,
                dataForm: {},
            }
        },
        watch: {
            dialog: {
                immediate: true,
                handler(value) {
                    this.dialogVisible = value;
                    if(value){
                        this.clear();
                    }
                }
            },
            dialogVisible: {
                handler(value) {
                    this.$emit("update:dialog", value);
                }
            }
        },
        methods: {
            clear(){
                this.dataForm = {}
            },
            cancel() {
                this.dialogVisible = false;
            },
            async confirm() {
                let ids=this.ids
                if(ids.length==0){
                    this.$message.error({
                    message: '请选择要结算的订单'
                    });
                    return;
                }
                this.showLoading("提交中");
                let res = await agentApi.settleOrder('1',ids);
                this.hideLoading();
                if (res.data.code != 0) {
                    this.$message.error({
                    message: res.data.msg
                    });
                    return
                }
               
                this.dialogVisible = false;
              this.$emit("search", this.dataForm);

            },
        }
    }
</script>