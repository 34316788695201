
<div>
  <el-card class="box-card">
    <el-form :inline="true">
      <el-form-item label="城市">
        <el-select
          v-model="form.cityCode"
          placeholder="全部"
          collapse-tags
          clearable
          filterable
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理商：">
        <el-input
          v-model.trim="form.agentName"
          placeholder="请输入代理商名称"
          clearable
        />
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <commonTableMulti
      fixed="right"
      :headList="headList"
      defaultHeight="520"
      :dataList="dataTable"
      :buttonList="buttonList"
      ref="commonTable"
      @pageChange="handleCurrentChange"
      @edit="handleEdit"
      :originPagesSize="page_size"
    ></commonTableMulti>
  </el-card>
  <cityDegreesModal ref="Update" @refreshDataList="search">
  </cityDegreesModal>
</div>
