<template>
  <el-dialog
    :visible.sync="model_dialog"
    v-if="model_dialog"
    :title="`设备详情(${type_name})`"
    append-to-body
    :close-on-click-modal="false"
    @close="model_dialog = false"
  >
    <el-row>
      <el-row class="block-header">
        <el-col :span="8">
          <span class="block-title">基本信息</span>
        </el-col>
      </el-row>
      <el-form
        ref="form"
        style="padding-right: 10px"
        :model="form"
        :rules="rules"
        label-width="110px"
        v-loading="loading"
        element-loading-background="rgba(204, 204, 204, 0.6)"
        element-loading-text="基础数据正在加载中..."
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="代理商：">
              <el-autocomplete
                :disabled="is_see"
                v-model="state"
                :fetch-suggestions="querySearchAsync"
                @select="handleSelect"
                placeholder="输入代理商名称首字母"
              >
                <template slot-scope="{ item }">
                  <div class="name">{{ item.agent_name }}</div>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="城市：">
              <el-select
                disabled
                v-model="form.city_code"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.cityCode"
                  :value="item.cityCode"
                  :label="item.cityName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
                        <el-form-item label="设备物理状态：">
                            <el-select :disabled="is_see" v-model="form.physical_state" clearable style="width: 100%">
                                <el-option  v-for="item in dim_physical_state"
                                            :key="item.code"
                                            :value="item.code"
                                            :label="item.desc"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="设备编号：" prop="equipment_no">
              <el-input
                :disabled="is_see"
                v-model.trim="form.equipment_no"
                clearable
                placeholder="请输入设备编号"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备名称：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.equipment_name"
                clearable
                placeholder="请输入设备名称"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备类型：" prop="equipment_type">
              <el-select
                :disabled="is_see"
                v-model="form.equipment_type"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="item in dim_equipment_type"
                  :key="item.data_value"
                  :value="item.data_value"
                  :label="item.data_name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="版本号：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.version"
                clearable
                placeholder="请输入版本号"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务器地址：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.service_ip"
                clearable
                placeholder="请输入服务器地址"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="GPS设备标识：" label-width="130px">
              <el-input
                :disabled="is_see"
                v-model.trim="form.gps_imei"
                clearable
                placeholder="请输入GPS设备标识"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-container>
          <el-aside width="607px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="设备功率：">
                  <el-input
                    :disabled="is_see"
                    v-model.trim="form.equipment_power"
                    clearable
                    placeholder="请输入设备功率"
                    maxlenth="7"
                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                  >
                    <template slot="append" style="width: 10px">kW</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备容量：">
                  <el-input
                    :disabled="is_see"
                    v-model.trim="form.equipment_capacity"
                    clearable
                    placeholder="请输入设备容量"
                    maxlenth="7"
                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                  >
                    <template slot="append">kWh</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="长宽高：">
                  <el-input
                    :disabled="is_see"
                    v-model.trim="form.equipment_describe"
                    clearable
                    placeholder="长宽高"
                    maxlenth="30"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="重量：">
                  <el-input
                    :disabled="is_see"
                    v-model.trim="form.equipment_weight"
                    clearable
                    placeholder="请输入重量"
                    maxlenth="7"
                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                  >
                    <template slot="append">KG</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="归属网点：">
                  <el-input
                    :disabled="is_see"
                    v-model.trim="form.equipment_org"
                    clearable
                    placeholder="归属网点"
                    autocomplete="off"
                    maxlenth="36"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="可用状态：" prop="availability">
                  <el-select
                    :disabled="is_see"
                    v-model="form.availability"
                    clearable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in dim_availability"
                      :key="item.data_value"
                      :value="item.data_value"
                      :label="item.data_name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!--                        <el-row>-->
            <!--                            <el-col :span="12">-->
            <!--                                <el-form-item label="设备枪号：" prop="gun_no">-->
            <!--                                    <el-input :disabled="is_see" v-model.trim="form.gun_no" clearable placeholder="请输入设备枪号" maxlenth="100"/>-->
            <!--                                </el-form-item>-->
            <!--                            </el-col>-->
            <!--                        </el-row>-->
          </el-aside>
          <el-main
            style="margin-top: -24px; margin-left: -19px; margin-bottom: -17px"
          >
            <el-form-item label="设备图片：">
              <upload_pic
                :file.sync="form.image_file"
                :disabled="is_see"
              ></upload_pic>
            </el-form-item>
          </el-main>
        </el-container>
        <el-row>
          <el-form-item label="备注：">
            <el-input
              :disabled="is_see"
              :rows="4"
              type="textarea"
              v-model="form.remark"
              show-word-limit
              maxlength="500"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-form>
    </el-row>
    <el-row v-if="is_see">
      <el-row class="block-header">
        <span class="block-title">工况参数</span>
        <el-button style="margin-left: 29px; margin-top: -9px" @click="test"
          >实时更新</el-button
        >
      </el-row>
      <el-form
        ref="form"
        style="padding-right: 10px"
        :model="form"
        label-width="110px"
        v-loading="loading"
        element-loading-background="rgba(204, 204, 204, 0.6)"
        element-loading-text="基础数据正在加载中..."
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="设备状态：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.equipment_state_name"
                clearable
                placeholder="请输入工况评估"
                maxlenth="100"
              />
              <!--                            <el-select :disabled="is_see" v-model="form.equipment_state" clearable style="width: 100%">-->
              <!--                                <el-option  v-for="item in dim_equipment_state"-->
              <!--                                            :key="item.data_value"-->
              <!--                                            :value="item.data_value"-->
              <!--                                            :label="item.data_name"-->
              <!--                                ></el-option>-->
              <!--                            </el-select>-->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="工况评估：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.working_condition_assessment"
                clearable
                placeholder="请输入工况评估"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电池温度：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.battery_temperature"
                clearable
                placeholder="请输入电池温度"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="充电电压：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.charging_voltage"
                clearable
                placeholder="请输入充电电压"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="充电电流：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.recharging_current"
                clearable
                placeholder="请输入充电电流"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="充电功率：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.charging_power"
                clearable
                placeholder="请输入充电功率"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="电量百分比：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.battery_percentage"
                clearable
                placeholder="请输入电量百分比"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="剩余电量：">
              <el-input
                :disabled="is_see"
                v-model.trim="form.remaining_battery"
                clearable
                placeholder="请输入剩余电量"
                maxlenth="100"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="model_dialog = false">返回</el-button>
      <el-button type="primary" @click="click" v-if="!is_see">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import upload_pic from "../../../components/common/upload/upload_pic.vue";
import DIM_API from "../../../api/dim_api";
import EQUIPMENT_API from "../../../api/equipment_api";
import { Message } from "element-ui";
export default {
  name: "add_equipment",
  components: {
    upload_pic,
  },
  props: {
    dialog: {
      type: Boolean,
      default() {
        return false;
      },
    },
    model_type: {
      type: String,
      default() {
        return "";
      },
    },
    id: {
      type: String,
      default() {
        return "";
      },
    },
    cityList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    //用于绑定代理商编号，获取代理商列表
    async loadAgentList() {
      let res = await EQUIPMENT_API.queryAgentList();
      if (res.data.code != 0) {
        return;
      }
      this.restaurants = res.data.agentList;
    },

    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 100 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.agent_name_char
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      // console.log(item);
      this.state = item.agent_name;
      this.seletcAgentItem = item;
      this.form.city_code = item.city_code;
    },
    init() {
      this.loadAgentList();
      // this.getPhysicalStates();
      this.state = "";
      this.seletcAgentItem = {};
      Object.assign(this.$data.form, this.$options.data().form);
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].clearValidate();
        }
      });
    },
    click() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (
            this.seletcAgentItem &&
            this.state == this.seletcAgentItem.agent_name
          ) {
            this.form.agent_no = this.seletcAgentItem.agent_no;
          } else {
            this.form.agent_no = "";
          }
          switch (this.model_type) {
            case "add":
              this.add();
              break;
            case "edit":
              this.update();
              break;
          }
        } else {
          return valid;
        }
      });
    },
    // async getPhysicalStates() {
    //     let res = await EQUIPMENT_API.getPhysicalStates();
    //     if(res.data.code==0){
    //         this.dim_physical_state=res.data.physicalStates
    //     }
    // },
    async initDim() {
      let res = await DIM_API.getByTypes(
        ["equipment_type", "equipment_state", "availability"].join(",")
      );
      let arr = res.data;
      this.dim_equipment_state = arr.filter(
        (item) => item.data_type === "equipment_state"
      );
      this.dim_equipment_type = arr.filter(
        (item) => item.data_type === "equipment_type"
      );
      this.dim_availability = arr.filter(
        (item) => item.data_type === "availability"
      );
    },
    async add() {
      let res = await EQUIPMENT_API.add(this.form).then((_) => {
        Message.success("设备信息添加成功！");
        this.$emit("search");
        this.model_dialog = false;
      });
      // if (res.status === 200) {
      //     Message.success("设备信息添加成功！")
      // }
    },
    async update() {
      let res = await EQUIPMENT_API.update(this.form).then((_) => {
        Message.success("设备信息更新成功！");
        this.$emit("search");
        this.model_dialog = false;
      });
      // if(res.status === 200) {
      //     Message.success("设备信息更新成功！")
      // }
    },
    async getBaseInfo(id) {
      this.loading = true;
      let res = await EQUIPMENT_API.getById(id).finally((_) => {
        this.loading = false;
      });
      if (res.status === 200) {
        Object.assign(this.form, res.data);
      }
      //设置代理商编号input
      let arr = this.restaurants;
      if (this.form.agent_no && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].agent_no === this.form.agent_no) {
            this.seletcAgentItem = arr[i];
            this.state = arr[i].agent_name;
            break;
          }
        }
      }
    },
    test() {
      this.getBaseInfo(this.id);
    },
  },
  data() {
    let tipMessage = (params, callback, message) => {
      EQUIPMENT_API.checkNo(params)
        .then(() => {
          callback();
        })
        .catch((error) => {
          let data = error.response.data;
          if (data.code === "EQUIPMENT_INFO/REPEAT_PROVIDE") {
            return callback(new Error(message));
          } else {
            callback();
          }
        });
    };
    let ruleNo = (rule, value, callback) => {
      let params = {};
      if (!value) {
        return callback(new Error("请输入设备编号"));
      }
      if (this.is_add) {
        params.no = value;
      } else if (this.is_edit) {
        params.no = value;
        params.id = this.id;
      }
      tipMessage(params, callback, "该设备编号已存在");
    };
    return {
      //绑定代理商--开始--
      restaurants: [],
      state: "",
      timeout: null,
      seletcAgentItem: {},
      // --结束--

      model_dialog: false,
      loading: false,
      form: {
        id: "",
        equipment_no: "",
        gun_no: "",
        equipment_name: "",
        equipment_type: 0,
        equipment_power: "",
        equipment_capacity: "",
        equipment_describe: "",
        equipment_weight: "",
        equipment_org: "",
        image_file: {},
        remark: "",
        equipment_state: "",
        equipment_state_name: "",
        working_condition_assessment: "",
        battery_temperature: "",
        charging_voltage: "",
        recharging_current: "",
        charging_power: "",
        battery_percentage: "",
        remaining_battery: "",
        availability: 1,
        agent_no: "",
        version: "",
        service_ip: "",
        gps_imei: "",
        city_code: "",
      },
      socket_form: {
        // 交易流水号
        transaction_id: "",
        // 充电桩编号
        station_id: "",
        // 枪编号
        charger_id: "",
        // 状态
        status: 0,
        // 是否归位
        homing: 0,
        // 插枪
        plugged: 0,
        // 输出电压
        output_voltage: 0,
        // 输出电流
        output_current: 0,
        // 枪线温度
        charger_temperature: 0,
        // 枪线编码
        charger_line_id: "",
        // soc
        soc: 0,
        // 电池组最高温度
        battery_max_temperature: 0,
        // 累计充电时间
        charging_time: 0,
        // 剩余时间
        remaining_time: 0,
        // 充电度数
        charging_degree: 0,
        // 计损充电度数
        loss_charging_degree: 0,
        // 已充金额
        charged_amount: 0,
        // 硬件故障
        hardware_malfunction: 0,
      },
      dim_equipment_state: [],
      dim_equipment_type: [],
      dim_availability: [],
      dim_physical_state: [],
      rules: {
        equipment_no: [{ required: true, validator: ruleNo, trigger: "blur" }],
        // gun_no: [
        //     {required: true, message: '请输入设备枪号', trigger: 'blur'}
        // ],
        equipment_type: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        availability: [
          { required: true, message: "请选择可用状态类型", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    dialog: {
      deep: true,
      immediate: true,
      handler(value) {
        this.model_dialog = value;
        if (value) {
          this.init();
          if (this.id) {
            this.getBaseInfo(this.id);
          }
        }
      },
    },
    model_dialog: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit("update:close", value);
      },
    },
    id: {
      deep: true,
      immediate: true,
      handler(value) {
        this.form.id = value;
      },
    },
  },
  computed: {
    is_see() {
      return this.model_type === "browse";
    },
    is_add() {
      return this.model_type === "add";
    },
    is_edit() {
      return this.model_type === "edit";
    },
    type_name() {
      switch (this.model_type) {
        case "add":
          return "新增";
        case "edit":
          return "编辑";
        case "browse":
          return "查看";
      }
      return "";
    },
  },
  mounted() {
    this.initDim();
    this.loadAgentList();
  },
};
</script>

<style scoped>
</style>