import axios from '../utils/axiosHelper.js'
import OBJECT_UTIL from "../assets/js/objectUtil.js";
const sysAccountApi = {};

sysAccountApi.getByCondition = (params) => {
    return axios.post('/sysAccount/list', params)
}
sysAccountApi.saveOrupdate = (saveOrUpdate,params) => {
    let objParams=JSON.parse(JSON.stringify(params))
    objParams.role_ids=objParams.role_ids.toString()
    return axios.post(`/sysAccount/`+saveOrUpdate,{"reqdata": JSON.stringify(objParams)})
    // return axios.post(`/api/partner/agentlist/`+saveOrUpdate,{params:OBJECT_UTIL.jsonToUnderline(params)})
    // return axios.post(`/api/partner/agentlist/`+saveOrUpdate,params)
}
sysAccountApi.deleteByIds = (ids) => {
    // let agentListStr=JSON.stringify(ids);
    // return axios.delete(`/api/partner/agentlist/delete`,{"reqdata": ids+""})
    return axios.post(`/sysAccount/delete`,{"reqdata": ids+""})
}
// agentApi.update = (params) => {
//     return axios.put(`/api/equipment`, params)
// }
// agentApi.add = (params) => {
//     return axios.post(`/api/equipment`, params)
// }
// agentApi.getById = (id) => {
//     return axios.get(`/api/equipment/${id}`)
// }
// agentApi.deleteByIds = (ids) => {
//     return axios.delete(`/api/equipment/${ids}`)
// }
// agentApi.checkNo = (params) => {
//     return axios.get(`/api/equipment/check`, {params: params})
// }
// agentApi.exportData = (params) => {
//     return axios.get('/api/equipment/export_data', {params: OBJECT_UTIL.ObjectKeyConvert(params)})
// }
export default sysAccountApi;