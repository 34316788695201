<!--
 * @Author: your name
 * @Date: 2022-02-22 18:22:56
 * @LastEditTime: 2022-07-15 15:55:41
 * @LastEditors: ljf
 * @Description: 
 * @FilePath: \lxm-admin\src\views\home\Home.vue
-->
<template>
  <div class="home-div">
    <div class="home-header">
      <v-head></v-head>
    </div>
    <div class="home-body">
      <div v-bind:style="{ width: myWidth }">
        <transition name="el-zoom-in-center">
          <v-sidebar></v-sidebar>
        </transition>
      </div>
      <div class="home-center">
        <v-tags></v-tags>
        <div class="home-center-div">
          <keep-alive :include="tagsList">
            <router-view :key="$route.fullPath"></router-view>
          </keep-alive>
        </div>
        <div class="home-footer">
          闽ICP备2022009927号，备案查询网站：<a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >https://beian.miit.gov.cn/</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vHead from "@/components/common/layout/Header.vue";
import vSidebar from "@/components/common/layout/Sidebar.vue";
import vTags from "@/components/common/layout/Tags.vue";
// import Cookies from 'js-cookie'
// import Vue from "vue";
// import io from 'socket.io-client'
export default {
  name: "Home",
  data() {
    return {};
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    },
    tagsList() {
      return this.$store.state.tagsList.map((item) => {
        return item.componentName;
      });
    },
    list() {
      return this.$store.state.tagsList;
    },
    myWidth() {
      if (!this.$store.state.collapse) {
        return "210px";
      } else {
        return "65px";
      }
    },
  },
  components: {
    vHead,
    vSidebar,
    vTags,
  },
  mounted() {
    // if (this.socket) {
    //     return;
    // }
    // console.log('Vue.prototype.WEB_SOCKET_URL:', Vue.prototype.WEB_SOCKET_URL)
    // const socket = io(Vue.prototype.WEB_SOCKET_URL,{
    //     query:{
    //         token: Cookies.get('userToken')
    //     }
    // })
    // Vue.prototype.socket = socket;
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home-div {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;

  .home-header {
    flex: 0 0 5% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 45px;
    max-height: 50px;
  }
  .home-body {
    display: flex;
    flex: 1;
    height: 90%;
  }
  .home-body-left {
    width: 210px;
  }
  .home-body-left-collapse {
    width: 55px;
  }
  .home-footer {
    flex: 0 0 3% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-center {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;
    height: 100%;
    /* fade-slide */
    .home-center-div {
      height: 100%;
      overflow-x: hidden;
      padding-left: 10px;
      padding-right: 10px;
    }
    .fade-slide-leave-active,
    .fade-slide-enter-active {
      transition: all 0.8s;
    }

    .fade-slide-enter-to {
      opacity: 0;
      transform: translateX(-30px);
    }

    .fade-slide-leave-from {
      opacity: 0;
      transform: translateX(30px);
    }
  }

  .is-horizontal {
    display: none;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease-out;
}

.slide-enter-to {
  position: absolute;
  left: 0;
}

.slide-enter-from {
  position: absolute;
  left: -100%;
}

.slide-leave-to {
  position: absolute;
  right: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}
</style>
