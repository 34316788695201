export function getDecimalPlaces(num) {
  // 计算小数位数
  const parts = num.toString().split(".");
  return parts.length === 1 ? 0 : parts[1].length;
}

export function getMinBase(...numbers) {
  // 获取所有数值的小数位数
  const decimalPlaces = numbers.map((num) => getDecimalPlaces(num));
  // 返回最大的小数位数作为最小基数
  return Math.max(...decimalPlaces);
}

export function preciseAddition() {
  // // 获取最小基数
  // const minBase = getMinBase(...numbers);

  // // 将所有数值转换为整数进行计算
  // const scaledNumbers = numbers.map((num) =>
  //   Math.round(num * Math.pow(10, minBase))
  // );

  // // 进行整数加法
  // const sum = scaledNumbers.reduce((acc, curr) => acc + curr, 0);

  // // 转换回原始数值
  // return sum / Math.pow(10, minBase);

  const sum = Array.from(arguments).reduce(
    (acc, curr) => Number(acc) + Number(curr),
    0
  );
  // 对结果进行四舍五入并保留两位小数
  const result = Math.round(sum * 100) / 100;
  return result.toFixed(2);
}
export function preciseMultiplication() {
  // // 获取总的小数位数
  // const minBase = getMinBase(...numbers);
  // console.log(Math.pow(10, minBase));
  // // 将所有数值转换为整数进行计算
  // const scaledNumbers = numbers.map((num) =>
  //   Math.round(num * Math.pow(10, minBase))
  // );

  // // 进行整数乘法
  // const product = scaledNumbers.reduce((acc, curr) => acc * curr, 1);

  // // 转换回原始数值
  // return product / Math.pow(10, minBase * numbers.length);

  const product = Array.from(arguments).reduce((acc, curr) => acc * curr, 1);
  // 对结果进行四舍五入并保留两位小数
  const result = Math.round(product * 100) / 100;
  return result.toFixed(2);
}
export function preciseSubtraction(...numbers) {
  // // 获取所有数值的小数位数最大值
  // const minBase = getMinBase(...numbers);

  // // 将所有数值转换为整数进行计算
  // const scaledNumbers = numbers.map((num) =>
  //   Math.round(num * Math.pow(10, minBase))
  // );
  // console.log(scaledNumbers);
  // // 进行整数减法
  // const difference = scaledNumbers.reduce((acc, curr) => acc - curr);
  // // 转换回原始数值
  // return difference / Math.pow(10, minBase);

  // 使用 reduce 函数连续减去所有参数
  const difference = numbers.reduce((acc, curr, index) => {
    if (index === 0) return curr; // 第一个元素作为初始值
    return acc - curr;
  });

  // 对结果进行四舍五入并保留两位小数
  const result = Math.round(difference * 100) / 100;
  return result.toFixed(2);
}
