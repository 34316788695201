<template>
  <el-dialog
    :title="'特殊配置-' + form.city_name"
    width="800px"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form :model="form" :rules="relus" ref="form" label-width="200px">
      <el-form-item label="电费单价" prop="electric_unit_price">
        <el-input v-model="form.electric_unit_price" style="width: 150px">
          <template slot="suffix">元/度</template>
        </el-input>
      </el-form-item>
      <el-form-item label="运维工单" prop="maintenance_display_status">
        <el-radio-group v-model="form.maintenance_display_status">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="订单进入调度时间设置"
        prop="dispatch_order_delay_time"
      >
        <el-input v-model="form.dispatch_order_delay_time" style="width: 150px">
          <template slot="suffix">分钟</template>
        </el-input>
      </el-form-item>
      <el-form-item label="每天每人最大下单数" prop="dispatch_order_delay_time">
        <el-input
          v-model="form.max_place_order"
          style="width: 150px"
          maxlength="2"
        ></el-input>
      </el-form-item>
      <el-form-item label="接单模式" prop="name">
        <el-select v-model="form.operate_mode" style="width: 150px">
          <el-option :value="1" label="抢单模式"></el-option>
          <el-option :value="2" label="抢单+调度模式"></el-option>
          <el-option :value="3" label="调度模式"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否开启认证配送员" prop="name">
        <el-radio-group v-model="form.delivery_open_authentication">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="确认订单是否开启人脸认证"
        prop="name"
        v-if="form.delivery_open_authentication == 1"
      >
        <el-radio-group v-model="form.confirm_order_open_authentication">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="自助充电单价" prop="self_charge_unit_price">
        <el-input
          v-model="form.self_charge_unit_price"
          style="width: 150px"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备预留比例" prop="surplus_electric_tip_ratio">
        <el-input
          v-model="form.surplus_electric_tip_ratio"
          style="width: 150px"
        >
          <span slot="suffix">%</span>
        </el-input>
      </el-form-item>
      <el-form-item label="自助充电最低充电度数" prop="self_charge_min_degree">
        <el-input v-model="form.self_charge_min_degree" style="width: 150px">
          <span slot="suffix">度</span>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      form: {
        electric_unit_price: 2.5,
        maintenance_display_status: 0,
        dispatch_order_delay_time: 10,
        operate_mode: 1,
        max_place_order: 2,
        surplus_electric_tip_ratio: 15,
        self_charge_unit_price: 2.5,
        self_charge_min_degree: 1,
      },
      relus: {
        electric_unit_price: [
          {
            required: true,
            message: "请输入电费单价",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^\d{1,6}(\.\d{1,2})?$/,
            message: "只能输入数字且最多6位，小数点最多2位",
            trigger: ["blur", "change"],
          },
        ],
        maintenance_display_status: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        dispatch_order_delay_time: [
          {
            required: true,
            message: "请输入订单进入调度时间设置",
            trigger: ["blur", "change"],
          },
        ],
        operate_mode: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        max_place_order: [
          {
            required: true,
            message: "请输入每天每人最大下单数",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^\d{1,6}$/,
            message: "只能输入数字且最多6位",
            trigger: ["blur", "change"],
          },
        ],
        self_charge_unit_price: [
          {
            required: true,
            message: "请输入自助充电单价",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^\d{1,6}(\.\d{1,2})?$/,
            message: "只能输入数字且最多6位，小数点最多2位",
            trigger: ["blur", "change"],
          },
        ],
        surplus_electric_tip_ratio: [
          {
            required: true,
            message: "请输入剩余电量提示比例",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[0-9]{1,2}$/,
            message: "只能输入数字且最多2位",
          },
        ],
        self_charge_min_degree: [
          {
            required: true,
            message: "请输入自充最低度数",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[0-9]{1,2}$/,
            message: "只能输入数字且最多2位",
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {
    init(data) {
      this.$nextTick(() => {
        let row = JSON.parse(JSON.stringify(data));
        if (row) {
          this.form = row;
          this.form.surplus_electric_tip_ratio =
            this.form.surplus_electric_tip_ratio * 100;
          return;
        }
        this.form = {
          electric_unit_price: 2.5,
          maintenance_display_status: 0,
          dispatch_order_delay_time: 10,
          operate_mode: 1,
          max_place_order: 2,
          surplus_electric_tip_ratio: 15,
          self_charge_unit_price: 2.5,
          self_charge_min_degree: 1,
        };
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (!valid) return;
        let form = JSON.parse(JSON.stringify(this.form));
        form.surplus_electric_tip_ratio = form.surplus_electric_tip_ratio / 100;
        this.axiosHelper
          .put(`/api/config/area/update/special/${this.form.id}`, form)
          .then((res) => {
            this.hideLoading();
            if (res.data.code != 0) return this.$message.error(res.data.msg);
            this.visible = false;
            this.$emit("refreshDataList");
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
            });
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error("加载失败");
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>