<!--
 * @Author: ljf
 * @Date: 2022-06-28 08:56:59
 * @LastEditors: ljf
 * @LastEditTime: 2022-06-28 16:11:43
 * @FilePath: \lxm-admin\src\views\system\org\OrgEdit.vue
 * @Description: 
 * 
-->
<template>
  <el-row class="block-body">
    <el-form
      ref="orgForm"
      :model="org"
      label-width="80px"
      :rules="rules"
      v-loading="loading"
    >
      <el-form-item
        label="父组织"
        v-show="action=='add'"
      >
        <el-input
          v-model="org.parent_name"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="组织名称">
        <el-input
          v-model="org.org_name"
          placeholder=""
          clearable
          maxlength="50"
          show-word-limit
        ></el-input>

      </el-form-item>
      <el-form-item label="组织别名">
        <el-input
          v-model="org.alias"
          placeholder=""
          clearable
          maxlength="100"
          show-word-limit
        ></el-input>

      </el-form-item>

      <el-row :gutter="20">
        <el-col
          :span="12"
          :offset="0"
        >
          <el-form-item label="组织编号">
            <el-input
              v-model="org.org_code"
              placeholder=""
              clearable
              maxlength="32"
              show-word-limit
            ></el-input>

          </el-form-item>
        </el-col>
        <el-col
          :span="12"
          :offset="0"
        >
          <el-form-item label="组织领导">

            <user-selector
              v-model="org.leader_id"
              defaultPanel="byDepartmentFilter"
              :panels="panels"
            ></user-selector>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :span="12"
          :offset="0"
        >
          <el-form-item label="组织类型">
            <el-select v-model="org.category">
              <el-option
                v-for="item in categoryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

          </el-form-item>
        </el-col>
        <el-col
          :span="12"
          :offset="0"
        >
          <el-form-item label="组织排序">

            <el-input-number
              v-model="org.sort_index"
              :min="0"
              :max="20"
              label="组织排序"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :span="12"
          :offset="0"
        >
          <el-form-item label="联系电话">
            <el-input
              v-model="org.telephone"
              placeholder=""
              clearable
              maxlength="20"
              show-word-limit
            ></el-input>

          </el-form-item>
        </el-col>
        <el-col
          :span="12"
          :offset="0"
        >
          <el-form-item label="是否显示">
            <el-switch
              v-model="org.show"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>

          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="联系地址">
        <el-input
          v-model="org.address"
          placeholder=""
          clearable
          type="textarea"
          maxlength="100"
          show-word-limit
        ></el-input>

      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="submit"
        >确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
  import Cookies from "js-cookie";
  import { checkOrgName, createOrg, updateOrg } from 'api/system/org-api'
  export default {
    name: 'OrgEdit',
    props: {
      action: {
        type: String,
        validator: (s) => ["add", "edit"].includes(s),
      },
      org_: {
        type: Object,
        default: () => {
          return {
            alias: '',
            parent_name: '',
            parent_id: '',
            org_name: '',
            address: '',
            show: false,
            telephone: '',
            sort_index: 0,
            category: 1,
            org_code: '',
            leader_id: '',
            leader_name: '',
            leader_code: '',
          }
        }
      },
      categoryOptions: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data () {
      return {
        org: {
          alias: '',
          parent_name: '',
          parent_id: '',
          org_name: '',
          address: '',
          show: true,
          telephone: '',
          sort_index: 0,
          category: 1,
          org_code: '',
          leader_id: '',
          leader_name: '',
          leader_code: '',
        },
        loading: false,
        rules: {
          org_name: [
            { required: true, message: "请输入组织名称", trigger: "change" },
            { validator: this.unique, trigger: "blur", text: "组织名称" },
          ],

        },
        panels: ['byDepartmentFilter'],
      }
    },
    computed: {
      read_only () {
        if (this.action !== "add") {
          return true;
        }
        return false;
      },
    },
    methods: {
      init () {
        this.loading = true;
        this.org = { ...this.org_ };
        this.loading = false;
      },
      async unique (rule, value, callback) {
        var obj = {
          id: this.org.id,
          appId: Cookies.get("appId") ?? 2,
          parentId: this.org.parent_id,
          orgName: value
        };
        var user = await checkOrgName(obj);
        if (user == false) {
          callback(new Error(`${rule.text}被占用`));
        }
        callback();
      },
      async submit () {
        try {
          this.loading = true;
          await this.$refs["orgForm"].validate(async (valid) => {
            if (valid) {
              var send_data = this.buildSendData();
              if (this.action == "add") {
                var result = await createOrg(send_data);
                if (result == 1) {
                  this.$message.success("添加成功!");
                  this.$emit("close");
                  this.$emit("refresh");
                  this.loading = false;
                }
              } else if (this.action == "edit") {
                var result = await updateOrg(send_data);
                if (result == 1) {
                  this.$message.success("修改成功!");
                  this.$emit("close");
                  this.$emit("refresh");
                  this.loading = false;
                }
              }
            }
          });
        } catch (error) {
          this.loading = false;
        }
      },
      buildSendData () {
        var org = { ...this.org };
        return org;
      },
      cancel () {
        this.$emit("close");
      },
    },
    mounted () {
      this.init();
    },
  }
</script>

<style lang="scss" scoped>
</style>