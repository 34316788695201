
<div class="home-div">
  <div class="home-header">
    <v-head></v-head>
  </div>
  <div class="home-body">
    <div v-bind:style="{ width: myWidth }">
      <transition name="el-zoom-in-center">
        <v-sidebar></v-sidebar>
      </transition>
    </div>
    <div class="home-center">
      <v-tags></v-tags>
      <div class="home-center-div">
        <keep-alive :include="tagsList">
          <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
      </div>
      <div class="home-footer">
        闽ICP备2022009927号，备案查询网站：<a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          >https://beian.miit.gov.cn/</a
        >
      </div>
    </div>
  </div>
</div>
