
<el-dialog title="设置结算订单"  :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
       <div>
        您所选择的订单将被结算，且无法修改哦
       </div>
    <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
    </div>
</el-dialog>
