<template>
  <el-dialog
    class="showAll_dialog"
    :title="'详情'"
    width="800px"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="140px"
      v-loading="formLoading"
    >
      <div class="form_label">基础信息</div>
      <div class="flex">
        <el-form-item label="活动名称：" prop="name">
          <el-input
            v-model.trim="form.name"
            placeholder="请输入活动名称"
            :disabled="true"
            clearable
            maxlength="35"
          />
        </el-form-item>
        <el-form-item label="城市：" prop="city_code">
          <el-select
            v-model="form.city_code"
            placeholder="请选择城市"
            :disabled="true"
          >
            <el-option :label="form.city_name" :value="form.city_code">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="活动展示名称：" prop="name">
        <el-input
          v-model.trim="form.promotion_show_name"
          placeholder="请输入活动展示名称"
          :disabled="true"
          clearable
          maxlength="35"
        />
      </el-form-item>
      <el-form-item label="活动时间：" prop="create_time">
        <el-date-picker
          v-model="form.create_time"
          :disabled="true"
          type="datetimerange"
          style="width: 400px"
          value-format="yyyy-MM-dd HH:mm:ss"
          :clearable="true"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        ></el-date-picker>
      </el-form-item>
      <div class="form_label">场景</div>
      <div class="flex">
        <el-form-item label="场景" prop="billing_type">
          <el-input
            v-model.trim="form.billing_name"
            placeholder="补贴金额上限"
            :disabled="true"
            clearable
          />
        </el-form-item>
        <el-form-item label="补贴金额上限：" prop="total_amount">
          <div class="flex">
            <el-input
              v-model.trim="form.total_amount"
              placeholder="补贴金额上限"
              :disabled="true"
              clearable
              maxlength="35"
            />
            <span style="margin: 0 10px">元</span>
          </div>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item
          :label="
            form.billing_type == '13' ? '参与活动人数限制：' : '人数限制：'
          "
          prop="personCode"
        >
          <el-select
            v-model="form.personCode"
            placeholder="请选择人数限制"
            :disabled="true"
          >
            <el-option label="否" :value="-1"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.personCode !== -1"
          label="前："
          prop="person_limit"
        >
          <div class="flex">
            <el-input
              v-model.trim="form.person_limit"
              placeholder="人数"
              :disabled="true"
              clearable
              maxlength="35"
            />
            <span style="margin: 0 10px">人</span>
          </div>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="场景互斥限制：" prop="billing_mutex">
          <el-select
            v-model="form.billing_mutex"
            placeholder="请选择场景互斥限制"
            :disabled="true"
          >
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.billing_mutex"
          label="互斥场景："
          prop="mutex_billing_type"
        >
          <el-select
            v-model="form.mutex_billing_type"
            placeholder="请选择场景互斥限制"
            :disabled="true"
          >
            <el-option
              v-for="item in sceneList"
              :key="item.id"
              :label="item.billing_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flex" v-if="form.billing_type === 0">
        <el-form-item label="落点设置：" prop="jump_page">
          <el-select v-model="form.jump_page" placeholder="请选择" disabled>
            <el-option label="无" value="0"></el-option>
            <el-option label="首页" value="1"></el-option>
            <el-option label="常规充电下单页" value="2"></el-option>
            <el-option label="应急补电下单页" value="3"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flex" v-if="form.billing_type === 13">
        <el-form-item label="参与活动次数限制" prop="frequencyCode">
          <el-select
            v-model="form.frequencyCode"
            placeholder="请选择人数限制"
            :disabled="true"
          >
            <el-option label="否" :value="-1"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.frequencyCode !== -1"
          label=""
          prop="person_limit"
        >
          <div class="flex">
            <el-input
              v-model.trim="form.every_one_times_limit"
              placeholder="次数"
              :disabled="true"
              clearable
              maxlength="35"
            />
            <span style="margin: 0 10px">次</span>
          </div>
        </el-form-item>
      </div>
      <div class="flex" v-if="form.billing_type === 13">
        <el-form-item
          label="连续邀请人数："
          prop="reach_standard_meet_person_num"
        >
          <div class="flex">
            <el-input
              v-model.trim="form.reach_standard_meet_person_num"
              placeholder="人数"
              :disabled="true"
              clearable
              maxlength="35"
            />
            <span style="margin: 0 10px">人</span>
          </div>
        </el-form-item>
      </div>
      <div
        class="flex"
        v-if="billing_rule_type === 2 || billing_rule_type === 3"
      >
        <el-form-item
          label="单人充值上限："
          prop="every_one_recharge_limit_code"
        >
          <el-select
            v-model="form.every_one_recharge_limit_code"
            placeholder="请选择场景互斥限制"
            :disabled="true"
          >
            <el-option label="否" :value="-1"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.every_one_recharge_limit_code !== -1"
          label="金额："
          prop="every_one_recharge_limit"
        >
          <div class="flex">
            <el-input
              v-model.trim="form.every_one_recharge_limit"
              placeholder="金额"
              :disabled="true"
              clearable
              maxlength="35"
            />
            <span style="margin: 0 10px">元</span>
          </div>
        </el-form-item>
      </div>
      <!-- 非充值、度数套餐活动时 -->
      <div v-if="billing_rule_type === 1">
        <div
          v-for="(item, index) in form.activity_subsidy_type_list"
          :key="index"
        >
          <el-form-item label="优惠类型：">
            <el-select
              v-model="item.subsidy_type"
              placeholder="请选择优惠类型"
              :disabled="true"
            >
              <el-option label="优惠券" :value="1"></el-option>
              <el-option label="充电度数" :value="2"></el-option>
              <el-option label="车辆性能报告" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="item.subsidy_type === 1"
            label="支付互斥限制："
            prop="pay_mutex_limit"
          >
            <el-select
              v-model="item.pay_mutex_limit"
              placeholder="请选择"
              :disabled="true"
            >
              <el-option label="否" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="item.subsidy_type === 2" label="度数：">
            <el-input
              v-model.trim="item.degree"
              placeholder="度数"
              :disabled="true"
              clearable
              maxlength="35"
            />
          </el-form-item>
          <el-form-item v-if="item.subsidy_type === 3" label="次数：">
            <div class="flex">
              <el-input
                v-model.trim="item.num"
                placeholder="请输入次数"
                :disabled="true"
                clearable
                maxlength="35"
              />
              <el-button
                plain
                style="
                  margin-left: 10px;
                  color: #409eff;
                  border: 1px #409eff solid;
                "
                @click="viewCondition(4, item.promotion_id)"
                >发放情况</el-button
              >
            </div>
          </el-form-item>
          <div
            class="flex"
            v-for="(val, valIndex) in item.coupon_quote_list"
            :key="valIndex"
          >
            <el-form-item label="券：">
              <el-input
                v-model.trim="val.coupon_name"
                style="width: 180px"
                placeholder="券"
                :disabled="true"
                clearable
                maxlength="35"
              />
            </el-form-item>
            <el-form-item label="有效期：" label-width="80px">
              <div class="flex">
                <el-input
                  v-model.trim="val.valid_day"
                  style="width: 94px"
                  placeholder="有效期"
                  :disabled="true"
                  clearable
                  maxlength="35"
                />
                <span style="margin: 0 10px">天</span>
              </div>
            </el-form-item>
            <el-form-item label="张数：" label-width="70px">
              <div class="flex">
                <el-input
                  v-model.number="val.num"
                  :disabled="true"
                  label="描述文字"
                ></el-input>
                <el-button
                  plain
                  style="
                    margin-left: 10px;
                    color: #409eff;
                    border: 1px #409eff solid;
                  "
                  @click="viewCondition(2, val.id)"
                  >发放情况</el-button
                >
              </div>
            </el-form-item>
          </div>
        </div>
      </div>
      <!-- 充值活动 -->
      <div v-if="billing_rule_type === 2">
        <div v-for="(item, index) in form.recharge_rule_list" :key="index">
          <el-form-item
            v-if="form.recharge_rule_list.length"
            :label="`第${index + 1}档：`"
            prop="recharge"
          >
            <div class="flex">
              <span
                style="
                  margin: 0 10px 0 20px;
                  white-space: nowrap;
                  color: #8590a6;
                "
                >充值</span
              >
              <el-input
                v-model.trim="item.recharge"
                placeholder="有效期"
                :disabled="true"
                clearable
                maxlength="35"
              />
            </div>
          </el-form-item>
          <div
            v-for="(val, valIndex) in item.activity_subsidy_type_list"
            :key="valIndex"
          >
            <el-form-item label="优惠类型：">
              <el-select
                v-model="val.subsidy_type"
                placeholder="请选择优惠类型"
                :disabled="true"
              >
                <el-option label="优惠券" :value="1"> </el-option>
              </el-select>
            </el-form-item>
            <div
              class="flex"
              v-for="(coupon, couponIndex) in val.coupon_quote_list"
              :key="couponIndex"
            >
              <el-form-item label="券：">
                <el-input
                  v-model.trim="coupon.coupon_name"
                  style="width: 180px"
                  placeholder="券"
                  :disabled="true"
                  clearable
                  maxlength="35"
                />
              </el-form-item>
              <el-form-item label="有效期：" label-width="80px">
                <div class="flex">
                  <el-input
                    v-model.trim="coupon.valid_day"
                    style="width: 94px"
                    placeholder="有效期"
                    :disabled="true"
                    clearable
                    maxlength="35"
                  />
                  <span style="margin: 0 10px">天</span>
                </div>
              </el-form-item>

              <el-form-item label="张数：" label-width="70px">
                <div class="flex">
                  <el-input
                    v-model.number="coupon.num"
                    :disabled="true"
                    label="描述文字"
                  ></el-input>
                  <el-button
                    plain
                    style="
                      margin-left: 10px;
                      color: #409eff;
                      border: 1px #409eff solid;
                    "
                    @click="viewCondition(2, coupon.id)"
                    >发放情况</el-button
                  >
                </div>
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
      <!-- 度数套餐活动 -->
      <div v-if="billing_rule_type === 3">
        <div v-for="(item, index) in form.recharge_rule_list" :key="item.id">
          <el-form-item
            v-if="form.recharge_rule_list.length"
            :label="`第${index + 1}档：`"
            prop="recharge"
          >
            <div class="flex">
              <span
                style="
                  margin: 0 10px 0 20px;
                  white-space: nowrap;
                  color: #8590a6;
                "
                >充值</span
              >
              <el-input
                v-model.trim="item.recharge"
                placeholder="有效期"
                :disabled="true"
                clearable
                maxlength="35"
              />
            </div>
          </el-form-item>
          <div
            v-for="(val, valIndex) in item.activity_subsidy_type_list"
            :key="val.id"
          >
            <template v-if="valIndex === 0 && form.billing_type != 15">
              <el-form-item label="优惠类型：">
                <el-select
                  v-model="val.subsidy_type"
                  placeholder="请选择优惠类型"
                  :disabled="true"
                >
                  <el-option label="充电度数" :value="2"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="度数：">
                <div class="flex">
                  <el-input
                    v-model.trim="val.degree"
                    placeholder="请输入充电度数"
                    :disabled="true"
                    clearable
                    maxlength="35"
                  />
                  <el-button
                    plain
                    style="
                      margin-left: 10px;
                      color: #409eff;
                      border: 1px #409eff solid;
                    "
                    @click="viewCondition(3, val.id)"
                    >发放情况</el-button
                  >
                </div>
              </el-form-item>
            </template>
            <div v-else-if="form.billing_type == 11 || form.billing_type == 15">
              <el-form-item
                :label="form.billing_type == 15 ? '优惠类型：' : '搭赠类型：'"
                style="width: 100%"
              >
                <el-select
                  v-model="val.subsidy_type"
                  placeholder="请选择是否搭赠"
                  disabled
                >
                  <el-option label="无" :value="0"> </el-option>
                  <el-option label="车辆性能检测" :value="3"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="form.billing_type == 15 ? '数量' : '搭赠数量：'"
                v-if="val.subsidy_type == 3"
                style="width: 100%"
              >
                <div class="flex">
                  <el-input
                    v-model.trim="val.num"
                    placeholder="请输入次数"
                    clearable
                    disabled
                    maxlength="9"
                    ><span slot="suffix">次</span></el-input
                  >
                  <el-button
                    plain
                    style="
                      margin-left: 10px;
                      color: #409eff;
                      border: 1px #409eff solid;
                    "
                    @click="viewCondition(4, val.promotion_id, item.id)"
                    >发放情况</el-button
                  >
                </div>
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <!-- 发放情况表格 -->
    <el-dialog
      width="70%"
      title="发放情况"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div class="flex">
        <el-button style="margin-left: auto" type="primary" @click="exportExcel"
          >导出</el-button
        >
      </div>
      <el-table
        style="margin-top: 20px"
        ref="tableData"
        :height="tableHeight"
        :data="dataTable"
      >
        <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="用户手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          v-if="type === 4"
          prop="num"
          label="次数"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          :label="type === 2 ? '领取时间' : '获取时间'"
          min-width="150"
          align="center"
        ></el-table-column>
        <div v-if="type === 2">
          <el-table-column
            prop="start_time"
            label="有效期"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="state_name"
            label="状态"
            min-width="100"
            align="center"
          ></el-table-column>
        </div>
      </el-table>

      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-dialog>
    <span slot="footer" class="dialog-foote r">
      <el-button type="primary" @click="confirmForm">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { operateAdd } from "@/api/system/user-api";
import axios from "../../../utils/axiosHelper";
export default {
  data() {
    return {
      form: {},
      visible: false,
      formLoading: false,
      cityList: [],
      conditionList: [],
      dataTable: [],
      innerVisible: false,
      tableHeight: "50vh",
      page_total: 0,
      page_index: 1,
      page_size: 50,
      sceneList: [],
      billing_rule_type: "",
      type: "",
      rules: {
        name: [
          {
            required: true,
            message: "请输入券名",
            trigger: ["blur", "change"],
          },
        ],
      },
      rechargeRuleId: "",
      conditionId: "",
    };
  },
  methods: {
    init(row) {
      this.$nextTick(() => {
        if (row) {
          this.form = row;
          this.form.create_time = [row.start_time, row.end_time];
          this.form.personCode = row.person_limit === -1 ? -1 : 1;
          this.form.frequencyCode = row.every_one_times_limit === -1 ? -1 : 1;
          this.form.billing_mutex = row.billing_mutex ? 1 : 0;
          const activity_subsidy_type_list = row.activity_subsidy_type_list.map(
            (item) => {
              item.pay_mutex_limit = item.pay_mutex_limit ? 1 : 0;
              return item;
            }
          );
          this.form.activity_subsidy_type_list = activity_subsidy_type_list;
          this.billing_rule_type = row.billing_rule_type;
          this.form.every_one_recharge_limit_code =
            row.every_one_recharge_limit === -1 ? -1 : 1;
          this.billing_rule_type = row.billing_rule_type;
        }
      });
    },
    confirmForm() {
      this.visible = false;
    },
    viewCondition(type, id, rechargeRuleId) {
      this.type = type;
      this.rechargeRuleId = rechargeRuleId;
      this.conditionId = id;
      this.loadDataTable();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        $limit: 50,
        $offset: 0,
      };
      params.$limit = this.page_size;
      params.$offset = this.page_size * (this.page_index - 1);
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      let api = "";
      if (this.type === 4) {
        api = `api/userAccountRecord/query/by/carAssess/${offset}/${limit}?promotionId=${this.conditionId}&rechargeRuleId=${this.rechargeRuleId}`;
      } else if (this.type === 2) {
        api = "/v0.1/api/operate/coupon_manage";
        params.coupon_quote_id = this.conditionId;
      } else {
        api = `/api/userAccountRecord/query/by/activitySubsidyTypeId/${offset}/${limit}?activitySubsidyTypeId=${this.conditionId}`;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .get(api, { params })
        .then((res) => {
          console.log(res.data, "asdasdaass");
          this.hideLoading();
          this.innerVisible = true;
          this.dataTable =
            this.type === 2 ? res.data.items : res.data.data.items;
          this.page_total =
            this.type === 2 ? res.data.total_count : res.data.data.total;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("失败");
        });
    },
    async exportExcel() {
      await operateAdd({
        operate_type: "1",
        operate_number: 26,
        operate_parameter: JSON.stringify({
          promotionId: this.conditionId,
          rechargeRuleId: this.rechargeRuleId,
          $limit: 20000,
          $offset: 0,
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      this.showLoading("数据加载中...");
      let params = {
        $limit: 20000,
        $offset: 0,
      };
      params.$limit = 20000;
      params.$offset = 0;
      let limit = 20000;
      let offset = 0;
      let api = "";
      if (this.type === 4) {
        api = `api/userAccountRecord/query/by/carAssess/${offset}/${limit}?promotionId=${this.conditionId}&rechargeRuleId=${this.rechargeRuleId}`;
      } else if (this.type === 2) {
        api = "/v0.1/api/operate/coupon_manage";
        params.coupon_quote_id = this.conditionId;
      } else {
        api = `/api/userAccountRecord/query/by/activitySubsidyTypeId/${offset}/${limit}?activitySubsidyTypeId=${this.conditionId}`;
      }
      this.axiosHelper
        .get(api, { params })
        .then((res) => {
          let arr = this.type === 2 ? res.data.items : res.data.data.items;
          this.hideLoading();
          arr.forEach((item) => {
            item.validity_time = `${item.start_time} ~ ${item.end_time}`;
          });
          this.exportData(arr);
        })
        .catch(() => {
          this.hideLoading();
        });
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        let tHeader = [];
        let filterVal = [];
        if (this.type == 2) {
          tHeader = ["用户编号", "用户手机号", "领取时间", "有效期", "状态"];
          filterVal = [
            "user_number",
            "cellphone",
            "create_time",
            "validity_time",
            "state_name",
          ];
        }
        if (this.type == 4) {
          tHeader = ["用户编号", "用户手机号", "次数", "获取时间"];
          filterVal = ["user_number", "cellphone", "num", "create_time"];
        } else {
          tHeader = ["用户编号", "用户手机号", "获取时间"];
          filterVal = ["user_number", "cellphone", "create_time"];
        }
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "发放情况",
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.form_label {
  &::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 15px;
    background-color: #f6a83a !important;
    margin-right: 6px;
  }

  &::after {
    content: " ";
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: rgb(222, 222, 222);
    margin: 10px 0;
  }
}

.showAll_dialog {
  overflow: hidden;

  ::v-deep .el-dialog {
    height: 70%;
    overflow: hidden;

    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
      line-height: 30px;
      padding: 0 15px 60px;
    }

    .el-dialog__footer {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      padding: 0 20px 0;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}
</style>