/*
 * @Author: ljf
 * @Date: 2022-05-27 10:29:34
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-14 11:01:15
 * @FilePath: \lxm-admin\src\api\system\user-api.js
 * @Description: 
 * 
 */
import axios from "utils/axiosHelper";
import { Message } from 'element-ui';
import { userHead } from "utils/utils"

/**
 * @description: 获取当前登录用户信息
 * @return {*}
 */
export const getCurrentUser = () => {
  return axios.get(
    '/v0.1/api/user/currentUser',
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  }).catch(() => {
    Message.error('当前登录用户信息获取失败');
  })
}

/**
 * @description: 修改密码
 * @param {*} params
 * @return {*}
 */
export const checkPassword = (params) => {
  return axios.put('/api/user/manage/password/1',
    params
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  }).catch(() => {
    Message.error('修改密码失败');
  })
}

/**
 * @description: 用户注销
 * @param {*} uid
 * @return {*}
 */
export const loginOut = (uid) => {
  return axios.put(`/api/user/common/logout?userId=${uid}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    }).catch(() => {
      Message.error('用户注销失败');
    })
}
/**
 * @description: 获取用户头像
 * @param {*} id
 * @param {*} name
 * @return {*}
 */
export const getUserPhoto = (id, name) => {
  var host = window.location.host
  var url = `/api/user/common/download/photo/${id}`;
  return axios.get(url).then((res) => {
    if (res.data.length > 0) {
      
      return res.data;
    }
    else {
      return userHead(name)
    }
  }).catch((err) => {
    return userHead(name)
  })
}
/**
 * @description: 分页获取用户
 * @param {*} params
 * @return {*}
 */
export const getUserPage = (params) => {
  return axios.post(
    `api/user/manage/page`,
    params
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  })
}
/**
 * @description: 按条件查询用户
 * @param {*} id
 * @param {*} field
 * @param {*} value
 * @return {*}
 */
export const getUserByCondition = (id, field, value) => {
  return axios.get(
    `/api/user/common/condition?field=${field}&value=${value}&id=${id}`
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  })
}

/**
 * @description: 重置用戶密碼
 * @param {*} users
 * @return {*}
 */
export const resetUser = (users) => {
  return axios.put(
    `api/user/manage/reset`,
    users
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  }).catch((error) => {
    Message.error('重置用户密码失败')
  })
}
/**
 * @description: 删除用户
 * @param {*} ids
 * @return {*}
 */
export const deleteUser = (ids) => {
  return axios.delete(
    `api/user/manage/${ids}`
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  })
}

export const getUserById = (id) => {
  return axios.get(`/api/user/common/userId/${id}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
}
/**
 * @description: 创建用户
 * @return {*}
 */
export const createUser = (appId, userDto) => {
  return axios.post(
    `api/user/manage?appid=${appId}`,
    userDto
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  }).catch((error) => {
    if (error.response){
      return '请求失败:'+error.response.data.message
    }
    return '请求失败'
  })
}
/**
 * @description: 更新用户
 * @return {*}
 */
export const updateUser = (appId, userDto) => {
  return axios.put(
    `api/user/manage?appid=${appId}`,
    userDto
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  }).catch((error) => {
    if (error.response){
      return '请求失败:'+error.response.data.message
    }
    return '请求失败'
  })
}

export const multiUser = (params) => {
  return axios.post(`api/user/common/multi`, params)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
}

/**
 * 获取推荐商户列表
 * @param params
 * @returns {*}
 */
export const getRecomMerchantList = (params) => {
  return axios.get(`/api/recom/merchant/entity/list`, {
        params: params
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data
        }
      })
}
/**
 * @description: 添加用户操作日志
 * @param {Object} data 参数对象
 * @param {String} data.operate_type 操作类型: 1、导出
 * @param {String} data.operate_number 操作编号：1、导出用户度数管理 2、导出用户度数钱包 3、导出用户度数钱包明细 4、导出渠道商管理
 * @param {Object} data.operate_parameter 操作入参
 * @return {*}
 */
export const operateAdd = (data) => {
  return axios
    .post(`/api/user/common/operate/add`, data)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    });
};