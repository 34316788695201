<!--
 * @Author: ljf
 * @Date: 2022-06-28 16:49:39
 * @LastEditors: ljf
 * @LastEditTime: 2022-06-30 14:44:24
 * @FilePath: \lxm-admin\src\views\system\org\OrgRoleUser.vue
 * @Description: 
 * 
-->
<template>
  <div class="org-role-user-container">
    <div class="org-role-user-left">
      <base-table
        ref="choose-table"
        :data="roleData"
        showIndex
        :height="height"
        :columns="tableColumns"
        noPage
        @on-row-click="rowClick"
        rowKey="id"
        :selectRows.sync="selectRole"
      ></base-table>
    </div>
    <div class="org-role-user-right">
      <role-user
        v-if="ready"
        :role_id="selectRoleId"
        :org_id="selectOrgId"
        type="orgRole"
        @close="close"
        @submit="submit"
      ></role-user>
    </div>
  </div>
</template>

<script>
  import { getRoleUserByType } from 'api/system/role-api'
  import { setOrgRoleUser } from 'api/system/org-api'
  export default {
    name: 'OrgRoleUser',
    props: {
      selectOrgId: String,
      default: ''
    },
    data () {
      return {
        selectRoleId: '',
        selectRole: null,
        roleData: [],
        height: '60.5vh',
        tableColumns: [{
          label: '角色名称',
          prop: 'role_name'
        }],
        orgRoleUsers: [],
        ready: false,

      }
    },
    methods: {
      async init () {
        this.ready = false
        this.roleData = await getRoleUserByType(4)
        if (this.roleData.length > 0) {
          this.selectRoleId = this.roleData[0].id
          this.selectRole = [this.roleData[0]]
        }
        this.ready = true
      },
      rowClick (row) {
        this.selectRoleId = row.row.id
        //this.searchOrgRoleUser()
      },
      close () {
        this.$emit('close')
      },
      async submit (data, role_id, org_id) {
        var obj = {
          roleId: role_id,
          userIds: data.map(t => t.id),
          orgId: org_id
        }
        data = await setOrgRoleUser(obj)
        if (data > -1) {
          this.$message.success("设置角色用户成功")
          this.$emit('close')
        }
        else {
          this.$message.error("设置角色用户失败")
        }
      }

      // selectOrgChange (orgsIds) {
      //   this.selectOrgIds = orgsIds
      //   this.searchOrgRoleUser()
      // },
      // async searchOrgRoleUser () {
      //   this.orgRoleUsers = await getRoleOrgUser(this.selectRoleId, this.selectOrgIds, false, false)
      // }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style lang="scss" scoped>
.org-role-user-container {
  display: flex;
  width: 100%;
  height: calc(100% - 20px);
  .org-role-user-left {
    width: 300px;
  }
  .org-role-user-right {
    flex: 1;
    margin-left: 10px;
  }
}
</style>