
<div>
    <el-row>
        <div :span="24">
            <div style="position: absolute;z-index: -100;width: 100%;top: 0px;left: 0px;" v-if="exportData.length">
                <a id="aTags"></a>
                <el-table border :row-style="rowClass" ref="exportTable" id="exportTable" :data="dataList">
                    <template v-for="(item,index) in exportHead">
                        <CommonTableColumn v-if="item.children && item.children.length" :key="index"
                            :coloumn-header="item"></CommonTableColumn>
                        <el-table-column v-else :key="index" :label="item.name" :prop="item.value"
                            :width="item.width" show-overflow-tooltip :tooltip="tooltip" align="center"
                            :render-header="labelHead"></el-table-column>
                    </template>
                </el-table>
            </div>
            <el-table :stripe="true" border ref="singleTable" :data="dataList" :max-height="defaultHeight"
                :highlight-current-row="isHighlight" style="width: 100%;" :row-key="rowKey"
                @current-change="handleHighlight" @selection-change="handleSelectionChange" @select="handleSelect"
                @select-all="handleSelectAll" :show-summary="showSummary" :summary-method='summaryMethod'
                @row-click="clickRow" @sort-change="sortChange" empty-text="暂无数据"
                :row-class-name="tableRowClassName">
                <el-table-column v-if="isSelection" type="selection" align="center"
                    :reserve-selection="reserveSelection" width="55">
                </el-table-column>
                <el-table-column v-if="isIndex" label="序号" align="center" header-align="center" width="100">
                    <template slot-scope="scope">
                        <span>{{ (currentPage-1)*pageSize + scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <template v-for="(item,index) in headList">
                    <el-table-column v-if="!item.children" :label="item.name" :prop="item.value" :width="item.width"
                        show-overflow-tooltip :tooltip="tooltip" :key="index" align="center"
                        :render-header="labelHead">
                        <template slot-scope="scope">
                            <!-- 某列单元格可点击 -->
                            <span @click="clickBtn(scope.$index, dataList, item.value)" class="base-color cp tdu"
                                v-if="item.type === 'bLine'">
                                {{ scope.row[item.value] }}
                            </span>
                            <span v-else-if="item.type === 'list'">
                                <span v-for="(valItem,index) in scope.row[item.value]" :key="index">
                                    {{ valItem }} <br />
                                </span>
                            </span>
                            <!-- 其他 -->
                            <span v-else>{{ scope.row[item.value] }}</span>
                        </template>
                    </el-table-column>
                    <CommonTableColumn v-else :coloumn-header="item" @clickDetails="clickDetails" :key="index"
                        :dataList="dataList"></CommonTableColumn>
                </template>
                <el-table-column v-if="buttonList.length" :label="label || '操作'" :fixed="fixed" align="center"
                    header-align="center" :width="buttonWidth">
                    <template slot-scope="scope">
                        <div v-for="(button, index) in buttonList" class="dib mr6" :key="index">
                            <el-button :type="'text'" size="small" :disabled="
              !(!button.isDisabled ||
              button.isDisabled.indexOf(
                dataList[scope.$index][button.disabledConditionField]
              ) !== -1)
            " v-if="!button.isShow||button.isShow.indexOf(dataList[scope.$index][button.conditionField])!==-1"
                                @click="clickBtn(scope.$index,dataList,button.methods,button.desc)">{{button.name}}
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="isPagination" class="tar" height="32px" style="margin-top: 10px">
            <el-pagination background :select-class="secClass" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :current-page.sync="currentPage"
                :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :layout=layout :total="totalSize">
            </el-pagination>
        </div>
    </el-row>
</div>
