<template>
    <div class="height:100%;">
        <el-card class="box-card" style="height: 20%">
            <el-form :inline="true">
                <el-form-item label="场景名称：">
                    <el-input v-model.trim="form.billingName" placeholder="请输入" clearable />
                </el-form-item>
                <el-form-item label="创建时间：">
                    <el-date-picker v-model="createTime" type="datetimerange" style="width: 400px"
                        value-format="yyyy-MM-dd HH:mm:ss" :clearable="true" :default-time="['00:00:00', '23:59:59']"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="form.status" placeholder="全部" collapse-tags clearable>
                        <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-button style="margin-right: 15px" type="primary" @click="search">查询</el-button>
                <el-button style="margin-right: 15px" type="default" @click="clear">重置</el-button>
            </el-form>
        </el-card>
        <el-card class="box-card">
            <el-table ref="tableData" :height="tableHeight" :data="dataTable">
                <!-- <el-table-column
                    type="selection"
                    width="50"
                    align="center"></el-table-column> -->
                <el-table-column prop="billing_name" label="场景名称" min-width="150" align="center"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" min-width="150" align="center"></el-table-column>
                <el-table-column prop="billing_desc" label="活动描述" min-width="150" align="center"></el-table-column>
                <el-table-column prop="status" label="状态" min-width="100" align="center">
                    <template slot-scope="scope">
                        <div>
                            {{
                            !scope.row.status
                            ? "停用": "启用"
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <el-button @click="statusSwitch(scope.row)" type="text" size="small">{{
                            !scope.row.status ? "启用" : "停用"
                            }}</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-footer height="32px" style="margin-top: 10px">
                <el-pagination background layout="total,sizes, prev, pager, next,jumper" :total="page_total"
                    :page-size="page_size" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50, 100]" :current-page.sync="page_index"></el-pagination>
            </el-footer>
        </el-card>
    </div>
</template>
<script>

    export default {
        data() {
            return {
                cityList: [],
                form: {
                    billingName: "",
                    createTimeStart: "",
                    createTimeEnd: "",
                    status: ''
                },
                statusList: [
                    {
                        label: "停用",
                        value: 0,
                    },
                    {
                        label: "启用",
                        value: 1,
                    },
                ],
                createTime: [],
                dataTable: [],
                page_total: 0,
                page_index: 1,
                page_size: 50,
                tableHeight: "70vh",
                editState: "add",
                addOrUpdateVisible: false,
            };
        },
        async created() {
            this.getTableHeight();
            window.onresize = () => {
                this.getTableHeight();
            };
            this.search();
        },
        mounted() { },
        methods: {
            filter() {
                this.filterVisible = true;
            },
            getTableHeight() {
                this.tableHeight = String(
                    document.documentElement.clientHeight - 95 - 150 - 200
                );
            },
            async search() {
                if (this.createTime) {
                    this.form.createTimeStart = this.createTime[0];
                    this.form.createTimeEnd = this.createTime[1];
                } else {
                    this.form.createTimeStart = null;
                    this.form.createTimeEnd = null;
                }
                this.page_index = 1;
                this.loadDataTable();
            },
            clear() {
                this.form = {
                    name: "",
                    orderType: "",
                    status: "",
                };
                this.createTime = [];
                this.page_index = 1;
                this.loadDataTable();
            },
            handleSizeChange(val) {
                this.page_size = val;
                this.loadDataTable();
            },
            loadDataTable() {
                let params = {
                    ...this.form,
                };
                let limit = this.page_size;
                let offset = this.page_size * (this.page_index - 1);
                this.showLoading("加载中");
                this.axiosHelper
                    .get(`/api/activity/query/billing/type/${offset}/${limit}`, {
                        params: params,
                    })
                    .then((response) => {
                        this.hideLoading();
                        this.dataTable = response.data.data.items;
                        this.page_total = response.data.data.total;
                        //   this.$nextTick(() => {
                        //     this.$refs["dataTable"].doLayout();
                        //   });
                    })
                    .catch(() => {
                        this.hideLoading();
                        this.$message.error("加载失败");
                    });
            },
            handleCurrentChange(val) {
                this.page_index = val;
                this.loadDataTable();
            },
            async statusSwitch(row) {
                const confirmResult = await this.$confirm(
                    `确定${!row.discountType ? "停用" : "启用"}${row.billing_name}么？`,
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).catch((err) => {
                    return err;
                });
                if (confirmResult != "confirm") {
                    return;
                }
                this.showLoading("加载中");
                this.axiosHelper
                    .put(
                        `/api/activity/set/billing/type/status/${row.id}/${!row.status ? 1 : 0}`,
                        {}
                    )
                    .then((res) => {
                        this.hideLoading();
                        if (res.data.code != 0) {
                            this.$message.error(res.data.msg);
                            return;
                        }
                        this.search();
                        this.$message({
                            message: "操作成功",
                            type: "success",
                            duration: 1500,
                        });
                    })
                    .catch(() => {
                        this.hideLoading();
                        this.$message.error("加载失败");
                    });
            },
        },
    };
</script>
<style scoped lang="scss">
    .search {
        display: flex;
        flex-direction: row;
    }

    .el-col {
        display: flex;
        align-items: center;
    }

    .elColBtn {
        display: flex;
        align-items: flex-start;
        justify-content: left;
        padding-left: 40px;
    }

    .box-card {
        margin-top: 15px;
    }
</style>
