<template>

  <div class="base-table-container">    
    <div class="base-table-main">
      <el-table
        :class="{fixCellHeight:fixCellHeight?true:false}"
        ref="refTable"
        :height=setTableHeight
        border
        default-expand-all
        :data="newData"
        :tree-props="{children: 'children'}"
        :stripe="stripe"
        :size="size"
        :show-header="showHeader"
        :row-key="rowKey"
        :highlight-current-row="rowLight"
        :header-row-style="{'line-height':'36px'}"
        :row-style="tableRowClassName"
        :header-cell-style="headerCellSyleFun"
        :cell-style="{fontSize:'14px', color:'#000', padding:fixCellHeight?'0':'8px 0'}"
        @header-contextmenu="rightClick"
        @sort-change="sortChange"
        @select="OnSelect"
        @select-all="selectAll"
        @selection-change="selectChange"
        @cell-mouse-enter="OnCellMouseEnter"
        @row-click="rowClick"
        @header-dragend="handleHeaderDragend"
        v-loading="loading"
        :element-loading-text="loadingText"
        element-loading-customClass="my-table-loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(204, 204, 204, 0.6)"
      >
        <el-table-column
          v-if="showCheck"
          type="selection"
          :fixed="selectFixed"
          width="40px"
          align="center"
          :reserve-selection="reserveSelection"
          :selectable="selectable"
        ></el-table-column>
        <el-table-column
          v-if="showIndex"
          type="index"
          label="序号"
          width="60px"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(col, index) in columns"
          :key="index"
          :prop="col.prop"
          :label="col.label"
          :header-align="col.headerAlign"
          :align="col.style"
          :fixed="col.fixed"
          :width="col.width"
          :min-width="col.minWidth"
          :type="col.type"
          :sortable="col.sortable"
          :sort-method="col.sortMethod"
          :class-name="col.className"
          :show-overflow-tooltip="overflow || fixCellHeight"
        >
          <template slot-scope="scope">
            <div
              v-if="col.useFormItem"
              :style="fixCellHeight?{display:'flex',alignItems:'center',height:'36px',lineHeight:'36px',}:{}"
            >
              <el-form-item
                style="flex:1;margin-bottom:3px;"
                :prop="`data.${scope.$index}.${col.prop}`"
                :rules="[{required: col.validate?true:false, message: '不能为空', trigger: 'change'}]"
              >
                <form-slot
                  :render="col.render"
                  :row="scope.row"
                  :index="scope.$index"
                ></form-slot>
              </el-form-item>
            </div>
            <ex-slot
              v-else
              :render="col.render"
              :row="scope.row"
              :index="scope.$index"
              :column="col"
              :propId="col.propId"
              :showCard="col.showCard"
              :fixHeight="fixCellHeight"
            ></ex-slot>
          </template>
        </el-table-column>
        <span
          slot="empty"
          id="empty_box"
          class="empty-box"
        >
          <empty-slot
            :click="empty.click"
            :text="empty.text"
            :btnText="empty.btnText"
            :html="emptyHtml"
          ></empty-slot>
        </span>
      </el-table>
    </div>
    <div
      class="base-table-footer"
      v-if="noPage === false"
    >
      <el-pagination
        background
        :small="smallPagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="page_sizes"
        :layout="layout"
        :page-size="pageSize"
        :total="total"
        :disabled="pageForbidden"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
  let that = null;
  let exSlot = {
    functional: true,
    props: {
      row: Object,
      render: Function,
      index: Number,
      column: {
        type: Object,
        default: null
      },
      showCard: Boolean,
      propId: [String, Number],
      fixHeight: Boolean
    },
    render: (h, data) => {
      let eles = null;
      const params = {
        row: data.props.row,
        index: data.props.index
      }
      if (data.props.column) params.column = data.props.column
      if (data.props.render) {
        eles = [data.props.render(h, params)]
      } else if (data.props.showCard && data.props.propId) {
        let persons = !!(data.props.row[data.props.column.prop]) == true ? data.props.row[data.props.column.prop].split(',') : [];
        let personsId = !!(data.props.row[data.props.column.propId]) == true ? data.props.row[data.props.column.propId].split(',') : [];
        let users = [];
        let popovers = [];
        for (let i = 0; i < persons.length; i++) {
          popovers.push(h('el-popover', {
            style: {
              minWidth: '100px'
            },
            props: {
              popperClass: 'user-card-popover',
              placement: 'right-start',
              trigger: 'click'
            },
            on: {
              show: () => {
                that.show_popover_loading = true;
                that.axiosHelper.get(
                  '/rest/template/userDto/userId',
                  {
                    params: {
                      id: personsId[i]
                    }
                  }
                ).then(response => {
                  if (response.data !== '') {
                    that.user_popover_info = response.data;
                  } else {
                    that.user_popover_info = {
                      real_name: 'XXX',
                      org_name: 'xxx',
                      user_no: 'xxx',
                      cellphone: 'xxx',
                      email: 'xxx'
                    };
                  }
                  that.$nextTick(() => {
                    that.show_popover_loading = false;
                  });
                });
              }
            }
          }, [
            that.createRenderElement(h, that.user_popover_info),
            h('span',
              {
                slot: 'reference',
                style: {
                  display: 'inline-block',
                  color: '#409EFF',
                  cursor: 'pointer'
                }
              }, persons[i])
          ]))
          if (persons.length > 1 && (i !== persons.length - 1)) popovers.push(h('span', { style: { margin: '0 4px' } }, ','));
        }
        eles = popovers;
      } else {
        eles = data.props.row[data.props.column.prop];
      }
      return h('div', {
        style: data.props.fixHeight ? {
          position: 'relative',
          display: 'inline-block',
          maxWidth: '100%',
          height: '36px',
          lineHeight: '36px',
          padding: '0',
          marginRight: '1px',
          overflow: 'hidden',
          verticalAlign: 'middle',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        } : that.overflow ? {
          marginRight: '-1px',
          overflow: 'hidden',
          verticalAlign: 'middle',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        } : {}
      }, eles)
    }
  }
  let formSlot = {
    functional: true,
    props: {
      row: Object,
      render: Function,
      index: Number
    },
    render: (h, data) => {
      let params = {
        row: data.props.row,
        index: data.props.index
      }
      if (data.props.render) {
        return h('div', {}, [data.props.render(h, params)])
      }
    }
  }
  let emptySlot = {
    functional: true,
    props: {
      text: String,
      btnText: String,
      click: Function,
      html: String
    },
    render: (h, data) => {
      if (data.props.html) {
        return h('div', {
          style: {
            position: 'relative',
            top: '-20px',
            left: '50%',
            marginLeft: '-100px',
            width: '200px',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }, [
          h('div', {
            style: {
              position: 'relative'
            },
            domProps: {
              innerHTML: data.props.html
            }
          })
        ])
      } else if (data.props.click && data.props.btnText) {
        return h('div', {
          style: {
            'font-size': '14px'
          },
          domProps: {
            innerHTML: data.props.html
          }
        }, [
          h('span', data.props.text || '暂无数据'),
          h('span', {
            style: {
              cursor: 'pointer',
              color: '#5A8BFF',
              'text-decoration': 'underline'
            },
            on: {
              click () {
                data.props.click();
              }
            }
          }, data.props.btnText)
        ]);
      } else {
        return h('span', data.props.text || '暂无数据');
      }
    }
  };
  export default {
    name: 'BaseTable',
    components: {
      exSlot,
      formSlot,
      emptySlot
    },
    props: {
      selectFixed: String,
      tablData: Object,
      headerCellSyleFun: {
        type: Function,
        default: () => {
          return { background: '#EDF1F4', textAlign: 'center', padding: '0' }
        }
      },
      fixCellHeight: {
        type: Boolean,
        default: true
      },

      height: {
        type: [String, Number]
      },
      stripe: Boolean,
      size: String,
      data: {
        type: Array,
        default: () => {
          return []
        }
      },
      columns: {
        type: Array,
        default: []
      },
      showCheck: Boolean,
      showIndex: Boolean,
      // 表格选中框是否可勾选
      selectable: {
        type: Function,
        default: () => {
          return true
        }
      },
      showHeader: {
        type: Boolean,
        default: true
      },
      rowKey: {
        type: String,
        default: ''
      },
      rowLight: Boolean,
      rowClassName: [Function, String],

      reserveSelection: Boolean,
      overflow: {
        type: Boolean,
        default: true
      },

      empty: {
        type: Object,
        default () {
          return {
            text: '暂无数据',
            btnText: '',
            click: Function
          }
        }
      },
      emptyHtml: String,

      showLoading: Boolean,
      loadingText: {
        type: String,
        default: '努力加载中'
      },

      noPage: Boolean,
      smallPagination: Boolean,
      // 一页显示多少条
      page_sizes: {
        type: Array,
        default: () => {
          return [10, 20, 50, 100, 500]
        }
      },
      layout: {
        type: String,
        default: 'total, sizes, prev, pager, next, jumper'
      },
      totalCount: {
        type: Number,
        default: 0
      },
      //分页禁用，默认不禁用
      pageForbidden: false,
      selectRows: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data () {
      return {
        loading: this.showLoading,
        user_popover_info: {},
        show_popover_loading: false,

        currentPage: 1,
        pageSize: 50,
        offset: 0,
        limit: 0,
        currentPage: 1,
        pageSize: 50,
        offset: 0,
        limit: 0,
        ids: [],
        selectArr: [],
        selectPropArr: [],
        selectionArr: [],
        isSelectAll: false,
        newData: !!this.data === true ? this.data : [],
        style:{}
      }
    },
    computed: {
      setTableHeight () {
        if (this.height === undefined) return 'auto';
        let height = JSON.parse(JSON.stringify(this.height));
        if (height * 1 === Number(height)) {
          return height + 'px';
        } else if (height.endsWith('%') || height.endsWith('px') || height.endsWith('rem') || height.endsWith('vh')) {
          return height;
        } else {
          return 'auto';
        }
      },
      total () {
        return this.totalCount
      }
    },
    methods: {
      sortChange (column, prop, order) {
        this.$emit('sort-change', column, prop, order)
      },
      rightClick (column, event) {
        // 对外暴露方法与返回值<回调方法>
        this.$emit('header-contextmenu', {
          'column': column,
          'event': event
        })
      },
      OnSelect (selection, row) { this.$emit('select', selection, row) },
      selectAll (selection) {
        let _this = this;
        _this.isSelectAll = !_this.isSelectAll;
        for (let i = 0; i < _this.data.length; i++) {
          if (_this.data[i].children && _this.data[i].children.length > 0) {
            for (let j = 0; j < _this.data[i].children.length; j++) {
              _this.$refs.refTable.toggleRowSelection(_this.data[i].children[j], _this.isSelectAll);
            }
          }
        }
      },
      selectChange (selection) {
        this.selectionArr = selection;
        this.$emit('on-select-change', selection)
      },
      OnCellMouseEnter (row, column, cell, event) { this.$emit('cell-mouse-enter', row, column, cell, event) },
      rowClick (row, column, cell, event) {
        this.$emit('update:selectRows', [row])
        // 对外暴露方法与返回值<回调方法>
        this.$emit('on-row-click', {
          'row': row,
          'column': column,
          'cell': cell,
          'event': event
        })
      },
      currentPageToOne () {
        this.currentPage = 1;
      },
      handleSizeChange (size) {
        this.pageSize = size;
        this.limit = this.pageSize;
        this.handleCurrentChange(1);
      },
      handleCurrentChange (current) {
        this.currentPage = current;
        this.offset = (this.currentPage - 1) * this.pageSize;
        this.$emit('original-page-change', {
          'currentPage': this.currentPage,
          'pageSize': this.pageSize
        })
        this.$emit('page-change', {
          'offset': this.offset,
          'limit': this.limit,
          'currentPage': this.currentPage
        })
      },
      getSelectedData () {
        if (this.selectionArr.length === 0) {
          this.$message.warning({
            message: '没有选择任何数据，请先选择！！'
          });
        }
        return this.selectionArr
      },
      getSelectedOfProp (prop) {
        this.selectPropArr = [];
        for (let i = 0; i < this.selectionArr.length; i++) {
          this.selectPropArr.push(this.selectionArr[i][prop])
        }
        if (this.selectPropArr.length === 0) {
          this.$message.warning({
            message: '没有选择任何数据，请先选择！！'
          });
        }
        return this.selectPropArr
      },
      getSelectedDataNoTips () {
        return this.selectionArr
      },
      getIds () {
        return this.getSelectedOfProp('id');
      },
      toggleSelection (rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.refTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.refTable.clearSelection();
        }
      },
      resize (h) {
        this.$set(this.$refs.refTable, "height", h);
      },
      // 默认选中的表格数据
      getSelection (row, selected) {
        this.$refs['refTable'].toggleRowSelection(row, selected)
      },
      
      setEmptyTextCenter () {
        if (this.newData.length <= 0) {
          this.$nextTick(() => {
            setTimeout(() => {
              let tableDom = this.$refs['refTable'].bodyWrapper;
              if (tableDom.scrollWidth > tableDom.clientWidth) {
                let scrollLeft = tableDom.scrollLeft;
                document.getElementById('empty_box').style.left = scrollLeft - (tableDom.scrollWidth - tableDom.clientWidth) / 2 + 'px';
              }
            }, 0);
          });
        }
      },
      createRenderElement (h, info) {
        return h('div', {
          style: {
            fontSize: '12px',
            color: '#333333'
          },
          directives: [{
            name: 'loading',
            value: this.show_popover_loading
          }]
        }, [
          h('div', {
            style: {
              padding: '0 4px 8px',
              borderBottom: '1px solid #E0E6ED'
            }
          }, [
            h('div', {
              style: {
                fontSize: '18px'
              }
            }, info.real_name ? info.real_name : 'xxx'),
            h('div', {
              style: {
                color: '#8590A6'
              }
            }, info.org_name ? info.org_name : '')
          ]),
          h('div', {
            style: {
              lineHeight: '24px',
              padding: '8px 4px 0'
            }
          }, [
            h('div', {}, '工号：' + (info.user_no ? info.user_no : '')),
            h('div', {}, '电话：' + (info.cellphone ? info.cellphone : '')),
            h('div', {}, '电子邮箱：' + (info.email ? info.email : ''))
          ])
        ]);
      },
      handleHeaderDragend () {
        setTimeout(() => {
          this.doLayout();
        }, 0);
      },
      doLayout () {
        if (this.$refs['refTable']) {                 
          this.$refs['refTable'].doLayout();
        }
        this.setEmptyTextCenter()
      },
      tableRowClassName ({ row, rowIndex }) {
        //拿到每行的数据row              
        if (this.rowKey != '' && this.selectRows && this.selectRows.map(t => t[this.rowKey]).includes(row[this.rowKey])) {
          return {
            background: '#f0f9eb'
          }
        }
      },
    },

    created () {
      that = this;
    },
    mounted () {
      this.limit = this.pageSize;
      this.offset = (this.currentPage - 1) * this.pageSize;

      this.doLayout();
      window.addEventListener('resize', this.doLayout);
      let tableDom = this.$refs['refTable'].bodyWrapper;
      tableDom.addEventListener('scroll', (e) => {
        if (this.newData.length <= 0) {
          let scrollLeft = tableDom.scrollLeft;
          document.getElementById('empty_box').style.left = scrollLeft - (tableDom.scrollWidth - tableDom.clientWidth) / 2 + 'px';
        }
      });
    },
    destroyed () {
      window.removeEventListener('resize', this.doLayout);
    },
    watch: {
      data: {
        deep: true,
        handler () {
          this.newData = this.data;
          this.$nextTick(() => {
            setTimeout(() => {
              this.doLayout();
              if (this.newData.length > 0) return;
              let tableDom = this.$refs['refTable'].bodyWrapper;
              if (tableDom.scrollWidth > tableDom.clientWidth) {
                let scrollLeft = tableDom.scrollLeft;
                document.getElementById('empty_box').style.left = scrollLeft - (tableDom.scrollWidth - tableDom.clientWidth) / 2 + 'px';
              }
            }, 0)
          })
        }
      },
      showLoading () {
        this.loading = this.showLoading;
      },

    }
  }
</script>

<style lang="scss" scoped>
.base-table-container {
  height: calc(100% - 20px);
  width: 100%;
  display: flex;
  flex-direction: column;
  .base-table-main {
    flex: 1;
    width:100%;
  }
  .base-table-footer {
    height: 15px;
    width: calc(100%);
  }
}
/* 设置人员名片最小宽度 */
.user-card-popover {
  min-width: 100px;
  margin-top: 0px;
}
.el-tooltip__popper {
  max-width: 800px;
}
</style>
<style scoped>
::v-deep .el-table__fixed-right {
  /*此处的important表示优先于element.style*/
  height: auto !important;
  /*改为自动高度后，设置与父容器的底部距离，则高度会动态改变*/
  bottom: 17px;
}

::v-deep .el-table__fixed {
  /*此处的important表示优先于element.style*/
  height: auto !important;
  /*改为自动高度后，设置与父容器的底部距离，则高度会动态改变*/
  bottom: 17px;
}
.fixCellHeight ::v-deep .cell.el-tooltip {
  text-overflow: clip;
}
::v-deep .el-table--border,
::v-deep .el-table th.is-leaf,
::v-deep .el-table td,
::v-deep .el-table--border td {
  border-color: #c1cfdf;
}
::v-deep .el-table--border::after,
::v-deep .el-table--group::after,
::v-deep .el-table::before {
  background-color: #c1cfdf;
}
[element-loading-customClass="my-table-loading"] ::v-deep .el-icon-loading {
  font-size: 56px;
}
[element-loading-customClass="my-table-loading"] ::v-deep .el-loading-text {
  font-size: 16px;
}

::v-deep .el-table__empty-text {
  position: relative;
  height: 60px;
  line-height: 20px !important;
}
::v-deep .el-table__empty-text .empty-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
::v-deep .el-pagination--small .el-input__inner {
  height: 22px;
  line-height: 22px;
}
::v-deep .el-pagination--small .el-input .el-select__caret {
  line-height: 20px;
}
::v-deep .el-input--mini .el-input__inner {
  height: 22px !important;
  line-height: 22px !important;
}
::v-deep .el-form-item__error {
  margin-top: -5px;
  margin-left: -8px;
  padding-top: 0;
  transform: scale(0.7);
}
::v-deep .el-tag--mini {
  height: 18px;
}
::v-deep .el-select__tags > span span:first-child span {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
</style>
