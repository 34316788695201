
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="用户手机号：" style="margin-bottom: 0">
        <el-input
          v-model.trim="form.cellphone"
          placeholder="请输入"
          clearable
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item label="用户编号：" style="margin-bottom: 0">
        <el-input
          v-model.trim="form.userNumber"
          placeholder="请输入"
          clearable
          style="width: 100px"
          maxlength="10"
          oninput="value=value.replace(/[^0-9]/g,'')"
        />
      </el-form-item>
      <el-form-item label="所属城市：" style="margin-bottom: 0">
        <el-select
          v-model="form.firstCityCode"
          filterable
          placeholder="全部"
          clearable
          style="width: 100px"
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态：" style="margin-bottom: 0">
        <el-select
          v-model="form.refundState"
          placeholder="全部"
          clearable
          style="width: 100px"
        >
          <el-option :value="0" label="待退款" />
          <el-option :value="1" label="已退款" />
          <el-option :value="2" label="不同意" />
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间：" style="margin-bottom: 0">
        <el-date-picker
          style="width: 250px"
          v-model="applyTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          align="center"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="退款时间：" style="margin-bottom: 0">
        <el-date-picker
          style="width: 250px"
          v-model="refundTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          align="center"
        >
        </el-date-picker>
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span style="float: left">退款对账</span>
      <el-button style="float: right" @click="exportData">导出</el-button>
    </el-row>
    <el-table
      ref="dataTable"
      :height="tableHeight"
      :data="dataTable"
      show-summary
      :summary-method="getSummaries"
      border
    >
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="用户手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="first_city_name"
        label="所属城市"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="apply_number"
        label="退款申请单号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="申请时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="apply_content"
        label="申请信息"
        min-width="150"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="refund_amount"
        label="退款金额"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="refund_time"
        label="退款时间"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="refund_early_amount"
        label="退款前"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="refund_current_amount"
        label="退款后"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="refund_state"
        label="状态"
        min-width="100"
        align="center"
      >
        <template slot-scope="scope">{{
          getRefundStateName(scope.row.refund_state)
        }}</template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="250"
        align="center"
        v-if="isShowBtn2"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.refund_state == 0">
            <el-button
              @click="agreeRefundShow(scope.row)"
              type="text"
              size="small"
              >同意</el-button
            >
            <el-button
              @click="setRefundState(scope.row, '2')"
              type="text"
              size="small"
              >不同意</el-button
            >
          </span>
          <span v-if="scope.row.refund_state == 1">已完成</span>
          <span v-if="scope.row.refund_state == 2">
            <el-button
              @click="setRefundState(scope.row, '0')"
              type="text"
              size="small"
              >重置</el-button
            >
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>

  <AgreeRefund
    v-if="agreeRefundVisible"
    ref="agreeRefund"
    @refreshDataList="search"
  ></AgreeRefund>
</div>
