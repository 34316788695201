
<el-dialog title="用户退款" v-dialogDrag :visible.sync="dialogVisible" :close-on-click-modal="false" width="600px"
    append-to-body>
    <el-form :model="dataForm" ref="dataForm" :rules="rules" label-width="110px">
        <el-row>
            <el-col :span="24">
                <el-form-item label="退款金额：" prop="money">
                    <el-input v-model="dataForm.money" clearable />
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
    </div>
</el-dialog>
