
<el-dialog
  :title="'营业配置-' + form.city_name"
  width="800px"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <el-form
    :model="form"
    :rules="relus"
    ref="form"
    :label-width="form.status === 1 ? '120px' : '120px'"
  >
    <h3 style="margin-bottom: 10px">接单服务</h3>
    <el-form-item label="营业状态" prop="name">
      <el-radio-group v-model="form.status">
        <el-radio :label="1">营业</el-radio>
        <el-radio :label="0">休息</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="!form.status" label="自定义提示语">
      <div
        style="
          border: 1px solid #409eff;
          padding: 10px 20px;
          width: 300px;
          border-radius: 10px;
        "
      >
        <div>亲爱的用户，您好！</div>
        <div style="display: flex; white-space: nowrap">
          {{ form.city_name }}&nbsp;<el-input
            v-model.trim="form.tips"
            clearable
            maxlength="20"
            style="width: 300px"
          />
        </div>
        <div>因此给您带来不便，请见谅!</div>
        <div>如有问题，可联系:4009936668</div>
      </div>
      <div>设置休息后，用户将无法在小程序下单，请谨慎操作！</div>
    </el-form-item>
    <div v-if="form.status">
      <!-- <div style="margin-bottom: 10px">营业时间</div> -->
      <!-- 常规充电 -->
      <el-form-item
        label="常规充电"
        prop="conventional_charging.business_type"
      >
        <el-radio-group
          v-model="form.conventional_charging.business_type"
          @change="radioChange($event, 0)"
        >
          <el-radio :label="0">全天</el-radio>
          <el-radio :label="1">自定义</el-radio>
        </el-radio-group>
        <div v-if="form.conventional_charging.business_type">
          <div>每天重复</div>
          <el-form-item prop="convention_time">
            <el-time-picker
              v-model="form.convention_time"
              format="HH:mm"
              value-format="HH:mm"
              is-range
              clearable
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              @change="timeChange($event, 0)"
              @input="$forceUpdate()"
            >
            </el-time-picker>
          </el-form-item>
          <div>每周重复</div>
          <el-form-item>
            <el-checkbox-group v-model="form.conventional_charging.weeks">
              <el-checkbox
                v-for="(item, index) in weekList"
                :key="index"
                :label="item"
                >{{ filtersWeek(item) }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div>
          <div>尽快抵达时间设置</div>
          <el-form-item prop="conventional_charging.arrive_time">
            <span>用户下单时当下时间延后</span>
            <el-input
              style="width: 300px; margin-left: 10px"
              v-model="form.conventional_charging.arrive_time"
              oninput="value=value.replace(/[^0-9]/g,'')"
              maxlength="3"
            >
              <template slot="suffix">分钟</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 应急补电 -->
      <el-form-item label="应急补电" prop="name">
        <el-radio-group
          v-model="form.emergency_charging.business_type"
          @change="radioChange($event, 1)"
        >
          <el-radio :label="0">全天</el-radio>
          <el-radio :label="1">自定义</el-radio>
        </el-radio-group>
        <div v-if="form.emergency_charging.business_type">
          <div>每天重复</div>
          <el-form-item prop="emergency_time">
            <el-time-picker
              v-model="form.emergency_time"
              format="HH:mm"
              value-format="HH:mm"
              is-range
              clearable
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              @change="timeChange($event, 1)"
              @input="$forceUpdate()"
            >
            </el-time-picker>
          </el-form-item>
          <div>每周重复</div>
          <el-form-item>
            <el-checkbox-group v-model="form.emergency_charging.weeks">
              <el-checkbox
                v-for="(item, index) in weekList"
                :key="index"
                :label="item"
                >{{ filtersWeek(item) }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div>
          <div>尽快抵达时间设置</div>
          <el-form-item prop="emergency_charging.arrive_time">
            <span>用户下单时当下时间延后</span>
            <el-input
              style="width: 300px; margin-left: 10px"
              v-model="form.emergency_charging.arrive_time"
              oninput="value=value.replace(/[^0-9]/g,'')"
              maxlength="3"
            >
              <template slot="suffix">分钟</template>
            </el-input>
          </el-form-item>
        </div>
        <div>在您设置的营业时间内，用户可在小程序内下单</div>
      </el-form-item>
    </div>

    <div v-else>
      <el-form-item label="设置自动开业" prop="auto_open">
        <el-radio-group v-model="form.auto_open">
          <el-radio :label="0">不设置自动开业时间</el-radio>
          <el-radio :label="1">设置自动开业时间</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="form.auto_open"
        label="自动开业时间"
        prop="auto_open_time"
      >
        <el-date-picker
          type="datetime"
          placeholder="请选择开始时间"
          style="width: 100%"
          clearable
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
          v-model="form.auto_open_time"
        ></el-date-picker>
      </el-form-item>
    </div>
    <el-form-item
      label="服务区域"
      prop="customer_service_config.service_area"
      :rules="[
        {
          required: true,
          message: '请输入服务区域',
          trigger: ['change', 'blur'],
        },
      ]"
    >
      <el-input
        v-model="form.customer_service_config.service_area"
        maxlength="100"
        style="width: 300px"
      ></el-input>
    </el-form-item>
    <div>
      <h3 style="margin-bottom: 10px">客服服务</h3>
      <el-form-item
        label="服务电话"
        prop="customer_service_config.service_tel"
        :rules="[
          {
            required: true,
            message: '请输入服务电话',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <el-input
          v-model="form.customer_service_config.service_tel"
          maxlength="20"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="服务时间" prop="service_time">
        <el-time-picker
          v-model="form.service_time"
          format="HH:mm"
          value-format="HH:mm"
          is-range
          clearable
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          @input="$forceUpdate()"
          @change="timeChange($event, 2)"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item
        label="企微客服地址"
        prop="customer_service_config.wx_work_url"
      >
        <el-input
          v-model="form.customer_service_config.wx_work_url"
          style="width: 300px"
          maxlength="150"
        ></el-input>
      </el-form-item>
    </div>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    <el-button @click="visible = false">取消</el-button>
  </span>
</el-dialog>
