<!--
 * @Author: ljf
 * @Date: 2022-06-01 14:42:20
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-04 14:09:37
 * @FilePath: \lxm-admin\src\views\system\org\orgTree.vue
 * @Description: 
 * 
-->
<template>
  <div class="orgTree">
    <!-- 搜索框 -->
    <div class="orgTree-bar">
      <el-input
        v-model="filterText_"
        clearable
        placeholder="搜索"
        size="small"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search"
          size="small"
        ></el-button>
      </el-input>
    </div>
    <div
      class="orgTree-container"
      @contextmenu.prevent="show"
    >
      <el-scrollbar
        style="height: 100%"
        wrapStyle="overflow-x:hidden;"
        viewStyle=""
      >
        <!-- 选择树 -->
        <el-tree
          class="filter-tree"
          default-expand-all
          ref="tree"
          :props="defaultProps"
          :data="treeData"
          :show-checkbox="multiple"
          :node-key="props.value"
          @node-click="handleNodeClick"
          @node-contextmenu="showContextmenu"
          :filter-node-method="filterNode"
        >
        </el-tree>
      </el-scrollbar>
    </div>
    <div
      :style="{'z-index': 9999, position: 'fixed',left: optionCardX + 'px', 
				top: optionCardY + 'px', width: '140px', background: 'white',
				 'box-shadow': '0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)'}"
      v-show="optionCardShow"
      id="option-button-group"
    >
      <!-- <el-dropdown-item @click.stop="addMenu">新增菜单</el-dropdown-item>
      <el-dropdown-item @click.stop="removeMenu">删除菜单</el-dropdown-item> -->
      <li
        v-for="item in settings"
        :key="item.command"
        @click="handleCommand(item.command)"
      >
        <i :class="item.icon"></i>
        <span style="margin-left:5px">{{ item.context }}</span>
      </li>
    </div>
  </div>
</template>

<script>
  import { getOrgTree } from "api/system/org-api";
  import { nest } from "utils/utils";
  export default {
    name: "orgTree",
    props: {
      //是否多选
      multiple: {
        type: Boolean,
        default: false,
      },
      //是否可搜索
      filterable: {
        type: Boolean,
        default: true,
      },
      //是否禁用
      disabled: {
        type: Boolean,
        default: false,
      },
      //节点结构
      props: {
        type: Object,
        default: () => {
          return {
            value: "id",
            label: "org_name",
            children: "child_orgs",
            parentId: "parent_id",
            treeDeep: "treeDeep",
          };
        },
      },
      showContext: {
        type: Boolean,
        default: false,
      },
      filter: {
        type: Boolean,
        default: false,
      }
    },
    data () {
      return {
        filterText: "",
        filterText_: "",
        treeData: [],
        defaultProps: {
          children: "child_orgs",
          value: "id",
          label: "org_name",
        },
        optionCardShow: false,
        optionCardX: 0,
        optionCardY: 0,
        selectOrg: null,
        node: null,
        setting: [{
          command: 'addOrg',
          icon: 'el-icon-plus',
          context: "新增组织",
          root: false
        },
        {
          command: 'addRootOrg',
          icon: 'el-icon-plus',
          context: "新增组织",
          root: true
        },
        {
          command: 'editOrg',
          icon: 'el-icon-edit',
          context: "编辑组织",
          root: false
        },
        {
          command: 'removeOrg',
          icon: 'el-icon-minus',
          context: "删除组织",
          root: false
        }, {
          command: 'setOrgUser',
          icon: 'el-icon-setting',
          context: "设置组织角色",
          root: false
        }],
        settings: []
      };
    },
    watch: {
      optionCardShow: {
        handler (newValue, oldValue) {
          if (newValue) {
            document.body.addEventListener('click', this.closeMenu)
          } else {
            document.body.removeEventListener('click', this.closeMenu)
          }
        },
      }
    },
    methods: {
      async init () {
        this.treeData = this.buildTreeData(await getOrgTree(this.filter));
      },
      handleNodeClick (data) {
        this.$emit("nodeClick", data);
      },
      buildTreeData (treeData) {
        return nest(treeData, "0", this.props);
      },
      search () {
        this.filterText = this.filterText_;
        this.$nextTick(() => {
          this.$refs.tree.filter(this.filterText);
        });
      },
      filterNode (value, data) {
        const { label } = this.props;
        if (!value) return true;
        return data[label].indexOf(value) !== -1;
      },
      handleCommand (command) {
        this.$emit('command', command, this.selectOrg);
      },
      showContextmenu (e, data, n, t) {
        if (this.showContext) {
          this.optionCardShow = false
          this.optionCardX = e.x + 5  // 让右键菜单出现在鼠标右键的位置
          var height = document.body.clientHeight - 150
          this.optionCardY = (e.y + 10) > height ? height : e.y + 10
          this.selectOrg = data
          this.node = n // 将当前节点保存        
          this.optionCardShow = true  // 展示右键菜单
          this.settings = this.setting.filter((t) => t.root === false)
          this.$emit("nodeClick", data);
        }
      },
      closeMenu () {
        this.optionCardShow = false
      },
      show (e) {
        if (this.showContext) {
          this.optionCardX = e.x + 5  // 让右键菜单出现在鼠标右键的位置
          var height = document.body.clientHeight - 10
          this.optionCardY = (e.y + 10) > height ? height : e.y + 10
          this.optionCardShow = true
          this.settings = this.setting.filter((t) => t.root)
        }
      }
    },
    mounted () {
      this.init();
    },
  };
</script>

<style lang="scss" scoped>
.orgTree {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .orgTree-bar {
    height: 30px;
    width: 100%;
    display: flex;
  }
  .orgTree-container {
    margin-top: 10px;
    flex: 1;
    height: calc(100% - 50px);
  }

  li {
    margin: 0;
    padding: 7px 10px;
    cursor: pointer;
    &:hover {
      background: #ecf5ff;
      color: #66b1ff;
    }
  }

  li::marker {
    content: "";
  }
}
</style>
