
<div class="app-main">
  <el-card class="box-card">
    <el-form :inline="true">
      <el-form-item label="手机号码：">
        <el-input
          v-model.trim="search_value.cellphone"
          placeholder=""
          clearable
        />
      </el-form-item>
      <el-form-item label="城市：">
        <el-select
          v-model="search_value.cityCode"
          placeholder="全国"
          collapse-tags
          filterable
          clearable
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册时间：">
        <el-date-picker
          v-model="createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="center"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="性别：" v-if="!isAgent">
        <el-select
          v-model="search_value.sex"
          placeholder="全部"
          collapse-tags
          clearable
        >
          <el-option label="保密" :value="0"> </el-option>
          <el-option label="男" :value="1"> </el-option>
          <el-option label="女" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审核状态：" v-if="!isAgent">
        <el-select
          v-model="search_value.deliveryerCheckState"
          placeholder="全部"
          collapse-tags
          @clear="search_value.deliveryerCheckState = null"
          clearable
        >
          <el-option
            v-for="item in check_state_list"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card-main">
    <el-row style="margin-bottom: 15px">
      <el-button
        class="rewrite-button-style"
        size="small"
        @click="addUser"
        v-if="isShowBtn"
        >新增</el-button
      >
      <el-button
        class="rewrite-button-style"
        size="small"
        @click="deleteUser(multiple_selection)"
        v-if="isShowBtn"
        >删除</el-button
      >
      <el-button
        class="rewrite-button-style"
        size="small"
        @click="resetPassword"
        v-if="isShowBtn"
        >重置密码</el-button
      >
      <el-button class="rewrite-button-style" size="small" @click="exportData"
        >导出</el-button
      >
    </el-row>

    <el-table
      :data="table_data"
      height="60vh"
      border
      header-align="center"
      element-loading-customClass="personal-loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(204, 204, 204, 0.6)"
      class="refTable"
      ref="refTable"
      default-expand-all
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
      :cell-style="cell_style"
      :style="table_style"
      @selection-change="handleSelectionChange"
      @select-all="handleSelectionChange"
      row-key="uid"
      v-loading="loading"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :reserve-selection="true"
        fixed="left"
      ></el-table-column>
      <el-table-column type="index" width="50" label="序号"></el-table-column>
      <el-table-column
        prop="cellphone"
        label="手机号码"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="real_name"
        label="微信昵称"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="注册时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column prop="sex" label="性别" min-width="150" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.sex == '1'">男</div>
          <div v-else-if="scope.row.sex == '2'">女</div>
          <div v-else>保密</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="check_state_name"
        label="审核状态"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="id_card_no"
        label="身份证号码"
        min-width="100"
        align="center"
        v-if="isShowBtn"
      ></el-table-column>
      <el-table-column
        prop="native_place"
        label="户籍地址"
        min-width="100"
        align="center"
        v-if="isShowBtn"
      ></el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="250"
        align="center"
        v-if="isShowBtn"
      >
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="editUser(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="small"
            v-if="scope.row.deliveryer_check_state == 1"
            @click="editState(scope.row)"
            >审核</el-button
          >
          <el-button
            type="primary"
            size="small"
            v-else-if="scope.row.deliveryer_check_state != '0'"
            @click="editState(scope.row)"
            >查看</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="deleteUser([scope.row])"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current_page"
        small
        :page-sizes="[10, 20, 50, 100, 500]"
        :page-size="page_size"
        layout="total, sizes, prev, pager, next"
        :total="total_count"
      ></el-pagination>
    </el-footer>
  </el-card>
  <addDialog
    v-if="isAddDialog"
    :select_user="select_user"
    :agentCityCode="agentCityCode"
    :cityList="restrictedCityList"
    @sure="clear"
    @close="isAddDialog = false"
  />
  <examineDialog
    v-if="isExamineDialog"
    :select_user="select_user"
    :cityList="restrictedCityList"
    @sure="clear"
    @close="isExamineDialog = false"
  />
</div>
