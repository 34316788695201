import axios from '../utils/axiosHelper.js'
import OBJECT_UTIL from "../assets/js/objectUtil.js";
const AREACORP_API = {};

AREACORP_API.getByCondition = (params) => {
    return axios.post('/api/partner/areacorp/citySubsidyList', params)
}


AREACORP_API.subsidyStatistics = (cityCode) => {
    return axios.get(`/api/partner/areacorp/subsidyStatistics`,{params:cityCode})
}

//获取下单数量配置
// agentApi.getConfig = () => {
//     return axios.get('/api/partner/areacorp/getConfig')
// }
// agentApi.saveOrupdate = (saveOrUpdate,params) => {
//     let agentListStr=JSON.stringify(params);
//      return axios.post(`/api/partner/agentlist/`+saveOrUpdate,{"reqdata": agentListStr})
//      // return axios.post(`/api/partner/agentlist/`+saveOrUpdate,{params:OBJECT_UTIL.jsonToUnderline(params)})
//      // return axios.post(`/api/partner/agentlist/`+saveOrUpdate,params)
//  }
// agentApi.areacorp_saveOrUpdate = (saveOrUpdate,params) => {
// //    let str=JSON.stringify(params);
//     return axios.post(`/api/partner/areacorp/`+saveOrUpdate,params)
//     // return axios.post(`/api/partner/agentlist/`+saveOrUpdate,{params:OBJECT_UTIL.jsonToUnderline(params)})
//     // return axios.post(`/api/partner/agentlist/`+saveOrUpdate,params)
// }
// agentApi.deleteByIds = (ids) => {
//     // let agentListStr=JSON.stringify(ids);
//     // return axios.delete(`/api/partner/agentlist/delete`,{"reqdata": ids+""})
//     return axios.post(`/api/partner/agentlist/delete`,{"reqdata": ids+""})
// }
// agentApi.update = (params) => {
//     return axios.put(`/api/equipment`, params)
// }
// agentApi.add = (params) => {
//     return axios.post(`/api/equipment`, params)
// }
// agentApi.getById = (id) => {
//     return axios.get(`/api/equipment/${id}`)
// }
// agentApi.deleteByIds = (ids) => {
//     return axios.delete(`/api/equipment/${ids}`)
// }
// agentApi.checkNo = (params) => {
//     return axios.get(`/api/equipment/check`, {params: params})
// }
// agentApi.exportData = (params) => {
//     return axios.get('/api/equipment/export_data', {params: OBJECT_UTIL.ObjectKeyConvert(params)})
// }
export default AREACORP_API;