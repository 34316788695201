
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <div slot="header" class="clearfix">
      <span>数据筛选</span>
      <el-button
        style="float: right; padding: 3px 0"
        type="text"
        @click="filter"
        >高级查询</el-button
      >
    </div>
    <div class="text item">
      <el-form
        ref="searchValue"
        style="padding-right: 10px"
        :model="searchValue"
        label-width="110px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="手机号：" label-width="110px">
              <el-input
                v-model.trim="searchValue.user_no"
                placeholder="请输入手机号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="优惠券名称：" label-width="120px">
              <el-input
                v-model.trim="searchValue.name"
                placeholder="请输入优惠券名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="城市：" label-width="110px">
              <el-select
                v-model="searchValue.city_code"
                placeholder="全国"
                clearable
                filterable
              >
                <el-option
                  v-for="item in restrictedCityList"
                  :key="item.cityCode"
                  :label="item.cityName"
                  :value="item.cityCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态：">
              <el-select
                clearable
                v-model="searchValue.cur_state"
                placeholder="请选择状态"
              >
                <el-option
                  v-for="item in cur_state_emu"
                  :key="item.data_code"
                  :label="item.data_name"
                  :value="item.data_value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="用户编号：" label-width="110px">
              <el-input
                v-model.trim="searchValue.userNumber"
                placeholder="请输入用户编号："
                clearable
                maxlength="10"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="领取时间：">
              <el-date-picker
                v-model="create_Time"
                type="datetimerange"
                style="width: 335px"
                value-format="yyyy-MM-dd HH:mm:ss"
                :clearable="true"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="来源活动：" label-width="90px">
              <el-input
                v-model.trim="searchValue.promotion_name"
                placeholder="请输入来源活动名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="3" style="float: right; text-align: right">
            <el-button type="primary" @click="clickSearch">查询</el-button>
            <el-button type="default" @click="clear">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>优惠券管理</span>
      <el-button
        style="margin-left: 18px"
        v-if="isShowBtn"
        @click="updateMethod"
        >修改有效期</el-button
      >
      <el-button
        style="margin-left: 20px"
        v-if="isShowBtn"
        @click="cancelMethod()"
        >批量作废</el-button
      >
      <el-upload
        action="string"
        ref="upload"
        class="upload-test"
        :http-request="fileUpload"
        :on-error="fileError"
        :on-success="fileSuccess"
        :on-exceed="fileExceed"
        :show-file-list="false"
        :limit="1"
        :accept="this.suffix.join(',')"
      >
        <el-button v-if="isShowBtn">手动发放优惠（EXCEL导入）</el-button>
      </el-upload>
      <el-button v-if="isShowBtn" @click="addOrUpdateHandle('add')"
        >手动发放优惠</el-button
      >
      <el-button @click="downloadTemplate" v-if="isShowBtn"
        >EXCEL模板下载</el-button
      >
      <el-button style="float: right" @click="exportOrder">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
      @selection-change="rowSelected"
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
        :selectable="selectable"
      ></el-table-column>
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="手机号"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="优惠券名称"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="id"
        label="优惠券编号"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="discount_type_name"
        label="优惠券类型"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="billing_name"
        label="来源场景"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="promotion_name"
        label="来源活动"
        min-width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="领取时间"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="validity_time"
        label="有效期"
        min-width="200"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pay_mutex_limit"
        label="支付互斥"
        min-width="80"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{ !scope.row.pay_mutex_limit ? "否" : "是" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="state_name"
        label="状态"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="isShowBtn && !scope.row.cur_state"
            @click="cancelMethod(1, scope.row)"
            type="text"
            size="small"
            >作废</el-button
          >
          <el-button
            @click="addOrUpdateHandle('details', scope.row)"
            type="text"
            size="small"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
  <UpdateModel
    :selection="selection"
    :dialog.sync="updateVisible"
    @search="updateSearch"
  />
  <!-- 弹窗, 新增 / 修改 -->
  <conponAddModal ref="addOrUpdate" @refreshDataList="search">
  </conponAddModal>
  <conponDetailsModal ref="conponDetailsModal" />
</div>
