<template>
  <div>
    <el-card class="box-card" style="margin-top: 15px; padding-top: 20px">
      <el-form
        :label-position="'right'"
        label-width="200px"
        :model="configForm"
      >
        <el-form-item label="城市">
          <el-select
            style="width: 200px"
            v-model="cityCode"
            placeholder="全国"
            filterable
            @change="getConfig"
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-checkbox-group v-model="checkList">
          <div class="timeList">
            <div
              class="timeList-item"
              v-for="item in orderCountScope"
              :key="item.time"
            >
              <el-checkbox :label="item.time"> </el-checkbox>
              <el-input
                class="timeList-item-input"
                v-model="item.num"
                oninput="value=value.replace(/[^0-9]/g,'')"
                :disabled="edit_disable"
                maxlength="3"
              ></el-input>
            </div>
          </div>
        </el-checkbox-group>
        <el-form-item label="批量设置运力" label-width="auto">
          <el-input
            style="width: 300px; margin-right: 20px"
            v-model="order_count"
            oninput="value=value.replace(/[^0-9]/g,'')"
            :disabled="edit_disable"
            maxlength="3"
          ></el-input>
          <el-button type="primary" plain @click="allSettled">全选</el-button>
          <el-button type="primary" plain @click="setting">设置</el-button>
        </el-form-item>
      </el-form>

      <div class="btn_css">
        <el-button
          type="primary"
          style="margin-right: 30px"
          @click="edit_disable = false"
          >编辑</el-button
        >
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import agentApi from "@/api/agent_api";
export default {
  name: "orderCount_config",
  data() {
    return {
      edit_disable: true,
      configForm: {
        id: "",
        fullname: "",
        order_count: "",
      },
      order_count: "",
      cityCode: "",
      checkList: [],
      orderCountScope: [],
    };
  },

  computed: {
    ...mapState(["restrictedCityList"]),
  },
  async created() {
    if (this.restrictedCityList.length > 0) {
      this.cityCode = this.restrictedCityList[0].cityCode;
      this.getConfig();
    }
  },
  methods: {
    allSettled() {
      if (this.checkList.length != this.orderCountScope.length) {
        this.checkList = this.orderCountScope.map((it) => it.time);
      } else {
        this.checkList = [];
      }
    },
    setting() {
      if (this.checkList.length == 0) {
        this.$message.error({
          message: "请选择时间段",
        });
        return;
      }
      if (this.order_count === "" || this.order_count === null) {
        this.$message.error({
          message: "请输入批量设置的运力",
        });
        return;
      }
      if (
        this.order_count ||
        this.order_count === 0 ||
        this.order_count === "0"
      ) {
        this.orderCountScope.forEach((item) => {
          if (this.checkList.includes(item.time)) {
            item.num = this.order_count;
          }
        });
      }
      this.$message.success({
        message: "批量设置成功，请点击保存方能生效",
      });
    },
    async getConfig() {
      this.showLoading("加载中");
      let res = await this.axiosHelper
        .put(
          `/api/partner/areacorp/getConfig?cityCode=${this.cityCode}`,
          this.form
        )
        .finally(() => {
          this.hideLoading();
        });

      if (res.data.code != 0) {
        this.$message.error({
          message: res.data.msg,
        });
        return;
      }
      this.order_count = "";
      this.configForm = res.data.config;
      this.checkList = [];
      let num = res.data.config.order_count || 0;
      let order_count_scope = {
        0: num,
        1: num,
        2: num,
        3: num,
        4: num,
        5: num,
        6: num,
        7: num,
        8: num,
        9: num,
        10: num,
        11: num,
        12: num,
        13: num,
        14: num,
        15: num,
        16: num,
        17: num,
        18: num,
        19: num,
        20: num,
        21: num,
        22: num,
        23: num,
      };
      if (res.data.config.order_count_scope) {
        order_count_scope = JSON.parse(res.data.config.order_count_scope);
      }
      this.orderCountScope = this.getOrderCountScope(order_count_scope);
      this.edit_disable = true;
    },
    getOrderCountScope(obj) {
      const arr = [];
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          arr.push({
            time: `${String(key).padStart(2, "0")}:00-${String(
              Number(key) + 1
            ).padStart(2, "0")}:00`,
            num: obj[key],
          });
        }
      }
      return arr;
    },
    convertArrayToObject(arr) {
      const obj = arr.reduce((acc, item, index) => {
        acc[index] = Number(item.num || 0);
        return acc;
      }, {});
      return obj;
    },
    async save() {
      let arr = this.orderCountScope.filter(
        (it) => it.num === "" || it.num === undefined || it.num === null
      );
      if (arr.length > 0) {
        this.$message.error({
          message: "存在为空的运力，请填写运力",
        });
        return;
      }
      let res = await this.axiosHelper.post("/api/partner/areacorp/update", {
        id: this.configForm.id,
        order_count_scope: JSON.stringify(
          this.convertArrayToObject(this.orderCountScope)
        ),
      });
      if (res.data.code != 0) {
        this.$message.error(res.data.msg);
        return;
      }
      this.$message.success({
        message: res.data.msg,
      });
      this.edit_disable = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn_css {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.timeList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  .timeList-item {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 40%;
    .timeList-item-input {
      margin-left: 20px;
    }
  }
}
</style>
