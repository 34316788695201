﻿<template>
    <div :style="{height:heightAuto?'100%':'auto'}">
        <el-row :style="{height:heightAuto?'100%':'auto'}">
            <el-col :span="24" :style="{height:heightAuto?'100%':'auto'}">
                <template v-if="setTableHigh">
                    <el-table v-loading="loading" :element-loading-text="loadingText" element-loading-customClass="personal-loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(204, 204, 204, 0.6)" class="refTable" :class="{removeDefaultHidden:true,fixCellHeight: fixCellHeight}" :style="{'margin-top':heightAuto?'0':'10px'}" ref="refTable" :row-key="rowKey" :data="newData" :empty-text="emptyText" border default-expand-all :tree-props="{children: 'children'}" :header-row-style="{'line-height':'36px'}" :header-cell-style="{background:'#EDF1F4',textAlign:'center', padding:'0'}"
                              :cell-style="{fontSize:'14px', color:'#000', padding:'0'}" :highlight-current-row="rowLight" @sort-change="sortChange" :default-sort="defaultSortObj"
                              @selection-change="selectChange" @header-click="headerClick" @row-click="rowClick" @header-contextmenu="rightClick" style="width:100%;" :height="tableHigh" :row-class-name="tableRowClassName" @header-dragend="handleHeaderDragend">
                        <el-table-column v-if="showCheck===true" type="selection" width="40px" align="center" :reserve-selection="reserveSelection"
                            :selectable="selectable">
                        </el-table-column>
                        <el-table-column v-if="showIndex===true" type="index" label="序号" width="60px" align="center">
                        </el-table-column>
                        <el-table-column v-for="(col, index) in columns" :key="index" :min-width="col.minWidth"
                            :show-overflow-tooltip="overflow" :prop="col.prop" :header-align="col.headerAlign" :align="col.style" :type="col.type"
                            :fixed="col.fixed" :sortable="col.sortable"  :sort-method="col.sortMethod" :class-name="col.className">
                            <template slot="header" slot-scope="">
                                <label-slot :label="col.label" />
                            </template>
                            <template slot-scope="scope">
                                <div :style="{position:'relative',display:'inline-block','max-width':'100%',height:fixCellHeight?'36px':'auto','line-height':fixCellHeight?'36px':'',padding:fixCellHeight?'':'5px 0','margin-right':'1px',overflow:'hidden','vertical-align':'middle','text-overflow':'ellipsis','white-space':'nowrap'}">
                                    <ex-slot v-if="col.render" :render="col.render" :row="scope.row" :index="scope.$index"
                                        :column="col" :showCard="col.showCard">
                                    </ex-slot>
                                    <ex-slot v-else-if="col.showCard&&col.propId" :propId="col.propId" :showCard="col.showCard" :row="scope.row" :index="scope.$index" :column="col"></ex-slot>
                                    <span v-else>
                                        {{scope.row[col.prop]}}
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                        <span slot="empty"></span>
                    </el-table>
                    <div v-if="showEmpty" :style="{position:'absolute',top:'50%',left:'50%',marginTop:'-6px',marginLeft:'-24px',color:'#909399',fontSize:'12px'}">{{ showLoading ? '' : '暂无数据'}}</div>
                </template>
                <template v-else>
                    <el-table v-loading="loading" :element-loading-text="loadingText" element-loading-customClass="personal-loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(204, 204, 204, 0.6)" :class="{removeDefaultHidden:true,fixCellHeight:fixCellHeight}" ref="refTable" :data="newData" :empty-text="emptyText" border :row-key="rowKey" default-expand-all :tree-props="{children: 'children'}"
                              @select-all="selectAll" @sort-change="sortChange" @selection-change="selectChange" @header-click="headerClick" :default-sort="defaultSortObj"
                              :cell-style="{fontSize:'14px', padding:'0'}" :highlight-current-row="rowLight" @row-click="rowClick" @header-contextmenu="rightClick"
                              style="width:100%;" :header-row-style="{'line-height':'36px'}" :header-cell-style="{background:'#EDF1F4', padding:'0'}" class="no-setHigh-table table-base-table" :row-class-name="tableRowClassName" @header-dragend="handleHeaderDragend">
                        <el-table-column v-if="showCheck===true" type="selection" width="40px" fixed="left" align="center" :reserve-selection="reserveSelection"
                            :selectable="selectable">
                        </el-table-column>
                        <el-table-column v-if="showIndex===true" type="index" label="序号" width="60" align="center">
                        </el-table-column>
                        <el-table-column v-for="(col, index) in columns" :key="index" :min-width="col.minWidth"
                            :show-overflow-tooltip="overflow" :prop="col.prop" :header-align="col.headerAlign"  :align="col.style" :type="col.type"
                            :fixed="col.fixed" :sortable="col.sortable" :sort-method="col.sortMethod" :label="col.label">
                            <template slot-scope="scope">
                                <div :style="{position:'relative',display:'inline-block','max-width':'100%',height:fixCellHeight?'36px':'auto','line-height':fixCellHeight?'36px':'',padding:fixCellHeight?'':'5px 0','margin-right':'1px',overflow:'hidden','vertical-align':'middle','text-overflow':'ellipsis','white-space':'nowrap'}">
                                    <ex-slot v-if="col.render" :render="col.render" :row="scope.row" :index="scope.$index"
                                        :column="col">
                                    </ex-slot>
                                    <ex-slot v-else-if="col.showCard&&col.propId" :propId="col.propId" :showCard="col.showCard" :row="scope.row" :index="scope.$index" :column="col"></ex-slot>
                                    <span v-else>
                                        {{scope.row[col.prop]}}
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                        <span slot="empty"></span>
                    </el-table>
                    <div v-if="showEmpty" :style="{position:'absolute',top:'50%',left:'50%',marginTop:noPage?'16px':'-6px',marginLeft:'-24px',color:'#909399',fontSize:'12px'}">{{ showLoading ? '' : '暂无数据'}}</div>
                </template>
                <el-row v-show="noPage===false" type="flex" justify="space-between" class="footer">
                    <el-col>
                        <div class="page">
                            <el-pagination background :small="small" @size-change="handleSizeChange"
                                @current-change="handleCurrentChange" :highlight-current-row="rowLight"
                                :current-page="currentPage" :page-sizes="page_sizes" :layout="layout"
                                :page-size="pageSize" :total="total" :disabled="pageForbidden">
                            </el-pagination>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
let that = null;
let labelSlot = {
    functional: true,
    props: {
        label: String | Object,
    },
    render: (h, data) => {
        return h('span', [data.props.label]);
    }
};
var exSlot = {
    functional: true,
    props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
            type: Object,
            default: null
        },
        showCard:  Boolean,
        propId: String
    },
    render: (h, data) => {
        const params = {
            row: data.props.row,
            index: data.props.index
        };
        if (data.props.column) params.column = data.props.column
        if(data.props.showCard && data.props.propId && !data.props.render) {
            if(data.props.row[data.props.column.prop] === '系统通知' || data.props.row[data.props.column.prop] === '系统') {
                return h('span', data.props.row[data.props.column.prop]);
            } else {
                let persons = !!(data.props.row[data.props.column.prop])==true?data.props.row[data.props.column.prop].split(','):[];
                let personsId = !!(data.props.row[data.props.column.propId])==true?data.props.row[data.props.column.propId].split(','):[];
                let popovers = [];
                for(let i = 0; i < persons.length; i++) {
                    popovers.push(h('el-popover', {
                        props: {
                            popperClass: 'user-card-popover',
                            placement: 'right-start',
                            trigger: 'click'
                        },
                        on: {
                            show: () => {
                                that.show_popover_loading = true;
                                that.axiosHelper.get(
                                     '/rest/template/userDto/userId',
                                    {params: {
                                        id: personsId[i]
                                    }}
                                ).then(response => {
                                    if(response.data !== '') {
                                        that.user_popover_info = response.data;
                                    }else {
                                        that.user_popover_info = {
                                            real_name: '查无此人',
                                            org_name: '查无此人',
                                            user_no: '查无此人',
                                            cellphone: '查无此人',
                                            email: '查无此人'
                                        }
                                    }
                                    that.$nextTick(() => {
                                        that.show_popover_loading = false;
                                    });
                                });
                            }
                        }
                    }, [
                        that.createRenderElement(h, that.user_popover_info),
                        h('span',
                            {
                                slot: 'reference',
                                style: {
                                    color: '#409EFF',
                                    cursor: 'pointer'
                                }
                            }, persons[i])
                    ]))
                    if(persons.length > 1 && (i !== persons.length - 1)) {
                        popovers.push(h('span', ','))
                    }
                }

                return h('div', {}, [
                    popovers
                ]);
            }
        } else {
            return data.props.render(h, params);
        }
    }
};
export default {
    name: "base_table",
    components: {
        'ex-slot': exSlot,
        'label-slot': labelSlot
    },
    props: {
        // 表格选中框是否可勾选
        selectable: {
            type: Function,
            default: () => {
                return true
            }
        },
        tableRowClassName: {
            type: Function,
            default: ()=>{
                return ''
            }
        },
        // 表格数据
        data: {
            type: Array,
            default: () => {
                return null
            }
        },
        emptyText: {
            type: String,
            default: () => {
                return '暂无数据'
            }
        },
        rowKey:{
            type: String,
            default: () => {
                return ''
            }
        },
        reserveSelection:{
            type: Boolean,
            default: false
        },
        children: {
            type: Array,
            default: () => {
                return []
            }
        },
        // 表头数据
        columns: {
            type: Array,
            default: () => {
                return []
            }
        },
        // 超出表格隐藏，默认不隐藏
        overflow: {
            type: Boolean,
            default: true
        },
        // 是否分页，默认分页
        noPage: {
            type: Boolean,
            default: false
        },
        //分页禁用，默认不禁用
        pageForbidden: {
            type: Boolean,
            default: false
        },
        // 是否显示checkbox
        showCheck: {
            type: Boolean,
            default: false
        },
        showIndex: {
            type: Boolean,
            default: false
        },
        // 一页显示多少条
        page_sizes: {
            type: Array,
            default: () => {
                return [10, 20, 50, 100, 500]
            }
        },
        setTableHigh: {
            type: Boolean,
            default: false
        },
        tableHigh: {
            type: String,
            default: '70vh'
        },
        tableHeightClass: {
            type: String,
            default: 'default-height'
        },
        layout: {
            type: String,
            default: 'total, sizes, prev, pager, next, jumper'
        },
        small: {
            type: Boolean,
            default: false
        },
        rowLight: {
            type: Boolean,
            default () {
                return false;
            }
        },
        heightAuto: {
            type: Boolean,
            default () {
                return false;
            }
        },
        totalCount:{
            type:Number,
            default:0
        },
        fixCellHeight: {
            type: Boolean,
            default: true
        },
        showLoading: {
            type: Boolean,
            default: false
        },
        loadingText: {
            type: String,
            default: '拼命加载中'
        },
        defaultSortObj: {
            type:Object
        }
    },
    computed: {
        // 计算属性
    },
    methods: {
        createRenderElement (h, info) {
            return h('div', {
                style: {
                    fontSize: '12px',
                    color: '#333333'
                },
                directives: [{
                    name: 'loading',
                    value: this.show_popover_loading
                }]
            }, [
                h('div', {
                    style: {
                        padding: '0 4px 8px',
                        borderBottom:'1px solid #E0E6ED'
                    }
                }, [
                    h('div', {
                        style: {
                            fontSize: '18px'
                        }
                    }, info.real_name?info.real_name:'xxx'),
                    h('div', {
                        style: {
                            color: '#8590A6'
                        }
                    }, info.org_name?info.org_name:'')
                ]),
                h('div', {
                    style: {
                        lineHeight: '24px',
                        padding: '8px 4px 0'
                    }
                }, [
                    h('div', {}, '工号：'+(info.user_no?info.user_no:'')),
                    h('div', {}, '电话：'+(info.cellphone?info.cellphone:'')),
                    h('div', {}, '电子邮箱：'+(info.email?info.email:''))
                ])
            ]);
        },
        handleSizeChange(size) {
            this.pageSize = size;
            this.limit = this.pageSize;
            this.currentPage=1;
            this.handleCurrentChange(1);
        },
        handleCurrentChange(current) {
            this.currentPage = current;
            this.offset = (this.currentPage - 1) * this.pageSize;
            this.limit=this.pageSize
            this.$emit('original-page-change', {
                'currentPage': this.currentPage,
                'pageSize': this.pageSize
            });
            this.$emit('page-change', {
                'offset': this.offset,
                'limit': this.limit
            })
        },
        rowClick(row, column, cell, event) {
            // 对外暴露方法与返回值<回调方法>
            this.$emit('on-row-click', {
                'row': row,
                'column': column,
                'cell': cell,
                'event': event
            })
        },
        rightClick(column, event) {
            // 对外暴露方法与返回值<回调方法>
            this.$emit('header-contextmenu', {
                'column': column,
                'event': event
            })
        },
        getSelectedOfProp(prop) {
            this.selectPropArr = [];
            for (let i = 0; i < this.selectionArr.length; i++) {
                this.selectPropArr.push(this.selectionArr[i][prop])
            }
            if (this.selectPropArr.length === 0) {
                this.$message.warning({
                    message: '没有选择任何数据，请先选择！！'
                });
            }
            return this.selectPropArr
        },
        getSelectedData() {
            if (this.selectionArr.length === 0) {
                this.$message.warning({
                    message: '没有选择任何数据，请先选择！！'
                });
            }
            return this.selectionArr
        },
        getSelectedDataNoTips () {
            return this.selectionArr
        },
        getIds() {
            return this.getSelectedOfProp('id');
        },
        selectChange(selection) {
            this.selectionArr = selection;
            this.$emit('on-select-change', selection)
        },
        headerClick(column, event) {
            this.$emit('on-header-click', column, event);
        },
        sortChange(column, prop, order) {            
            this.$emit('sort-change', column, prop, order)
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.refTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.refTable.clearSelection();
            }
        },
        resize(h) {
            this.$set(this.$refs.refTable, "height", h);
        },
        // 默认选中的表格数据
        getSelection(row, selected) {
            this.$refs['refTable'].toggleRowSelection(row, selected)
        },
        currentPageToOne() {
            this.currentPage = 1;
        },
        //树形表格全选/反选
        selectAll () {
            let _this = this;
            _this.isSelectAll = !_this.isSelectAll;
            for (let i = 0; i < _this.data.length; i++) {
                if (_this.data[i].children && _this.data[i].children.length > 0) {
                    for (let j = 0; j < _this.data[i].children.length; j++) {
                        _this.$refs.refTable.toggleRowSelection(_this.data[i].children[j], _this.isSelectAll);
                    }
                }
            }
        },
        handleHeaderDragend () {
            setTimeout(() => {
                this.doLayout();
            }, 0);
        },
        doLayout () {
            if(this.$refs['refTable']) {
                this.$refs['refTable'].doLayout();
            }
        }
    },
    data () {
        return {
            showEmpty: false,
            user_popover_info: {},
            show_popover_loading: false,
            total: this.totalCount,
            currentPage: 1,
            pageSize: 50,
            offset: 0,
            limit: 0,
            ids: [],
            selectArr: [],
            selectPropArr: [],
            selectionArr: [],
            isSelectAll: false,
            newData: !!this.data === true ? this.data : [],
            loading: this.showLoading
        }
    },
    mounted () {
        this.limit = this.pageSize;
        this.offset = (this.currentPage - 1) * this.pageSize;
        this.$nextTick(() => {
            if(this.$refs['refTable']) {
                this.$refs['refTable'].doLayout();
            }
        });
        // this.showEmpty = true;
    },
    created () {
        that = this;
    },
    watch: {
        data () {
            this.newData = this.data;
            this.showEmpty = this.data.length === 0 ? true : false;
        },
        showLoading () {
            this.loading = this.showLoading;
        }
    }
}
</script>

<style scoped>
    .default-height {
        height: 70vh;
    }
    .page {
        margin-top: 10px;
        display: flex;
        float: left;
        align-items: center;
    }

    .el-table {
        /* border-top: 1px solid #E7E7E7;
        border-right: 1px solid #E7E7E7; */
        border-radius: 2px;
        width: 100%;
        margin-top: 10px;
    }

    /* 穿透渲染 */
    .el-table ::v-deep  td {
        /* border-bottom: 1px solid #E7E7E7;
        border-left: 1px solid #E7E7E7; */
    }

    .el-table ::v-deep  th {
        /* border-bottom: 1px solid #E7E7E7;
        border-left: 1px solid #E7E7E7; */
    }

    body ::v-deep  .el-table ::v-deep  colgroup.gutter {
        display: table-cell !important;
    }

    .el-table ::v-deep  tr:hover {
        background: #d2d2d2;
    }

    ::v-deep .el-table__fixed-right {
        /*此处的important表示优先于element.style*/
        height: auto !important;
        /*改为自动高度后，设置与父容器的底部距离，则高度会动态改变*/
        bottom: 17px;
    }

    ::v-deep .el-table__fixed {
        /*此处的important表示优先于element.style*/
        height: auto !important;
        /*改为自动高度后，设置与父容器的底部距离，则高度会动态改变*/
        bottom: 17px;
    }

    ::v-deep  .el-table th,
    .el-table tr {
        font-family: Serif !important;
    }
    .el-table ::v-deep  .danger-row {
        background: #f9dcdc;
    }
    .el-table ::v-deep  .pending-row {
        background: #EDEDED;
    }
    .el-table ::v-deep  .danger-row .el-table-column--selection{
        background: #ffffff;
    }

    .el-table ::v-deep  .danger-row .col-operate {
        background: #ffffff;
    }
    /* [element-loading-customClass="personal-loading"] ::v-deep .el-icon-loading{
        font-size: 56px;
    }
    [element-loading-customClass="personal-loading"] ::v-deep .el-loading-text{
        font-size: 16px;
    } */
</style>