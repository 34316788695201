<template>
    <el-dialog title="实时配送位置" :close-on-click-modal="false" :visible.sync="visible" width="60%" append-to-body>

        <div id="amap-container" style="width: 100%;height: 600px;"></div>

        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">关 闭</el-button>
            <el-button type="primary" @click="refresh()">刷 新</el-button>
        </span>
    </el-dialog>
</template>

<style>
    .marker-label {
        font-size: 16px;
        /* 设置字体大小为20像素 */
        font-weight: bold;
        /* 设置字体加粗 */
        margin: 5px;
    }
</style>

<script>

    export default {
        components: {

        },
        data() {
            return {
                visible: false,
                orderId: '',
                //地图数据
                mapData: {},
                defaultCity: '',
                // 地图对象
                map: null,
                // 地址对应的经纬度信息
                position: {},
                // 地图标记
                marker: '',
            }
        },
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
            // 销毁地图
            this.map.destroy();
        },
        methods: {
            init(id) {
                this.orderId = id
                this.showLoading("加载中");
                this.axiosHelper.get('/api/order/delivery/position/' + id, {})
                    .then((res) => {
                        this.hideLoading();
                        let result = res.data.result
                        if (result) {
                            if (!(result.order_status === 4)) {
                                this.$message.warning("当前订单非配送中，不可查看")
                                this.visible = false
                            } else {
                                this.mapData.order_longitude = result.order_longitude
                                this.mapData.order_latitude = result.order_latitude
                                this.mapData.delivery_longitude = result.delivery_longitude
                                this.mapData.delivery_latitude = result.delivery_latitude
                                this.mapData.distance = result.distance

                                this.visible = true
                                this.$nextTick(() => {
                                    this.initMap()
                                    this.markerMap()
                                })
                            }
                        }
                    })
                    .catch(() => {
                        this.hideLoading();
                        this.$message.error("加载失败");
                    })
            },
            initMap() {
                this.map = new AMap.Map('amap-container', {
                    zoom: 13,
                    //center: [116.4,39.92],
                    center: [this.mapData.order_longitude, this.mapData.order_latitude],
                    resizeEnable: true
                });
            },

            markerMap() {
                //配送员位置
                if (this.mapData.delivery_longitude && this.mapData.delivery_latitude) {
                    // 创建一个 Icon
                    var startIcon = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(40, 40),
                        // 图标的取图地址
                        //image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                        image: 'https://lvxiaoma.cn/file/amap/amap-start.svg',
                        // 图标所用图片大小
                        imageSize: new AMap.Size(40, 40),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(-1, -1)
                    })

                    // 将 icon 传入 marker
                    var startMarker = new AMap.Marker({
                        position: new AMap.LngLat(this.mapData.delivery_longitude, this.mapData.delivery_latitude),
                        icon: startIcon,
                        offset: new AMap.Pixel(-13, -30)
                    })
                    //将 markers 添加到地图
                    this.map.add([startMarker])

                    //设置直线距离标注内容
                    startMarker.setLabel({
                        offset: new AMap.Pixel(0, -1),  //设置文本标注偏移量
                        content: "<div class='marker-label'>距您" + this.mapData.distance + "米</div>", //设置文本标注内容
                        direction: 'top' //设置文本标注方位
                    })
                }

                //用户位置
                // 创建一个 icon
                /*var endIcon = new AMap.Icon({
                  size: new AMap.Size(25, 34),
                  image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                  imageSize: new AMap.Size(135, 40),
                  imageOffset: new AMap.Pixel(-95, -3)
                })*/
                var endIcon = new AMap.Icon({
                    // 图标尺寸
                    size: new AMap.Size(30, 30),
                    // 图标的取图地址
                    image: 'https://lvxiaoma.cn/file/amap/amap-end.svg',
                    // 图标所用图片大小
                    imageSize: new AMap.Size(30, 30),
                    // 图标取图偏移量
                    imageOffset: new AMap.Pixel(-1, -1)
                })
                // 将 icon 传入 marker
                var endMarker = new AMap.Marker({
                    position: new AMap.LngLat(this.mapData.order_longitude, this.mapData.order_latitude),
                    icon: endIcon,
                    offset: new AMap.Pixel(-13, -30)
                })
                //增加用户标注
                endMarker.setLabel({
                    offset: new AMap.Pixel(0, -1),  //设置文本标注偏移量
                    content: "<div class='marker-label'>我</div>", //设置文本标注内容
                    direction: 'top' //设置文本标注方位
                })
                //将 markers 添加到地图
                this.map.add([endMarker])

                //自适应显示多个点标记
                this.map.setFitView()
            },
            //刷新
            refresh() {
                this.init(this.orderId)
            }


        }
    }
</script>