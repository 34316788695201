<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="渠道商：">
          <el-input
            v-model.trim="form.channel_merchant_name"
            placeholder="请输入渠道商"
            clearable
          />
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="createTime"
            type="datetimerange"
            style="width: 335px"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :clearable="true"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="timeChange"
          ></el-date-picker>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>渠道商管理</span>
        <el-button
          style="margin-left: 15px"
          v-if="isMaintenanceOrder == 1"
          @click="handelBtn('add')"
          >新增</el-button
        >
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        :height="tableHeight"
        :data="dataTable"
        @selection-change="rowSelected"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="channel_merchant_name"
          label="渠道商"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="channel_code"
          label="渠道码"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contacts"
          label="联系人"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone_number"
          label="手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="total_user_count"
          label="引流客户总数量"
          min-width="160"
          align="center"
        >
          <template slot-scope="scope">
            <el-button @click="seeUser('total', scope.row)" type="text">{{
              scope.row.total_user_count
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="today_user_count"
          label="今日注册用户数"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-button @click="seeUser('today', scope.row)" type="text">{{
              scope.row.today_user_count
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="qrcode_url"
          label="二维码"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.qrcode_url"
              style="width: 50px; height: 50px"
              :src="getQrcodeUrl(scope.row.qrcode_url)"
              @click="showWxappQrcodeView(scope.row)"
            >
            </el-image>
            <el-button
              v-if="!scope.row.qrcode_url"
              @click="generateWxAppQrcode(scope.row.id)"
              type="text"
              size="small"
              >生成</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              @click="handelBtn('edit', scope.row)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              @click="handelBtn('del', scope.row)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <channelBusinessManageAddModal
      ref="channelBusinessManageAddModal"
      :editState="editState"
      @refreshDataList="search"
    ></channelBusinessManageAddModal>
    <channelUserModal
      v-if="isChannelUserModal"
      :type="channelUserType"
      :dataItem="dataItem"
      @close="isChannelUserModal = false"
    />
    <wxappQrcode v-if="wxappQrcodeViewVisible" ref="wxappQrcode" />
  </div>
</template>
<script>
import { operateAdd } from "@/api/system/user-api";
import channelBusinessManageAddModal from "./model/channel-business-manage-add-modal.vue";
import channelUserModal from "./model/channel-user-modal.vue";
import wxappQrcode from "./model/wxapp-qrcode.vue";
import * as XLSX from "xlsx";

export default {
  components: {
    channelBusinessManageAddModal,
    channelUserModal,
    wxappQrcode,
  },
  data() {
    return {
      form: {
        channel_merchant_name: "",
        start_time: "",
        end_time: "",
      },
      stateOptions: [
        { label: "失败", value: 0 },
        { label: "正常", value: 1 },
        { label: "作废", value: -1 },
      ],
      createTime: [],
      dataTable: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      addOrUpdateVisible: false,
      statistics: {},
      selection: [],
      isChannelUserModal: false,
      channelUserType: "",
      dataItem: {},
      wxappQrcodeViewVisible: false,
      file_view_rul: this.FILE_VIEW_ROOT_RUL,
    };
  },
  computed: {
    isMaintenanceOrder() {
      return this.$store.state.user.isMaintenanceOrder;
    },
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.search();
  },
  methods: {
    seeUser(type, item) {
      this.channelUserType = type;
      this.dataItem = item;
      this.isChannelUserModal = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    async search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {
        channelMerchantName: "",
        startTime: null,
        endTime: null,
      };
      this.createTime = [];
      this.page_index = 1;
      this.loadDataTable();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    loadDataTable() {
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      let postData = {
        ...this.form,
        limit,
        offset,
      };
      this.showLoading("加载中");
      this.axiosHelper
        .post(`/api/channel/merchant/query`, postData)
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.data.items;
          this.page_total = response.data.data.total;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    handelDetails(row) {
      this.$refs.detailsModal.visible = true;
      this.$refs.detailsModal.init(row);
    },
    timeChange(val) {
      if (val && val.length) {
        this.form.start_time = val[0];
        this.form.end_time = val[1];
      } else {
        this.form.start_time = null;
        this.form.end_time = null;
      }
    },
    handelBtn(type, row = {}) {
      switch (type) {
        case "add":
        case "edit":
          this.$refs.channelBusinessManageAddModal.visible = true;
          this.editState = type;
          this.$refs.channelBusinessManageAddModal.init(row);
          break;
        case "del":
          this.allCancelDegree(row);
          break;
      }
    },
    allCancelDegree(row) {
      this.$confirm("确认删除该渠道商么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.showLoading("加载中");
        this.axiosHelper
          .post("/api/channel/merchant/delete", { id: row.id })
          .then((response) => {
            if (response.data.code != 0) {
              this.hideLoading();
              this.$message.error(response.data.msg);
              return;
            }
            this.hideLoading();
            this.$message.success({
              message: "删除成功",
            });
            this.search();
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error({
              message: "删除失败",
            });
          });
      });
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 4,
        operate_parameter: JSON.stringify(this.form),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.selection.length == 0) {
        this.$confirm("是否要导出全部数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (this.page_total > 20000) {
            await this.$confirm(
              `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );
          }
          this.showLoading("数据加载中...");
          this.axiosHelper
            .post("/api/channel/merchant/export", this.form)
            .then((response) => {
              this.hideLoading();
              this.exportData(response.data.data);
            })
            .catch(() => {
              this.hideLoading();
            });
        });
      } else {
        this.exportData(this.selection);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "ID",
          "渠道商",
          "渠道码",
          "联系人",
          "手机号",
          "引流客户总数量",
          "今日注册用户数",
          "创建时间",
        ];
        const filterVal = [
          "id",
          "channel_merchant_name",
          "channel_code",
          "contacts",
          "phone_number",
          "total_user_count",
          "today_user_count",
          "create_time",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "渠道商管理导出",
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    //显示小程序二维码
    showWxappQrcodeView(row) {
      this.wxappQrcodeViewVisible = true;
      this.$nextTick(() => {
        this.$refs.wxappQrcode.init(row, this.file_view_rul);
      });
    },
    getQrcodeUrl(qrcode_url) {
      return this.file_view_rul + qrcode_url;
    },
    //生成商户推荐微信小程序二维码
    generateWxAppQrcode(id) {
      this.showLoading("加载中");
      this.axiosHelper
        .post(`/api/channel/merchant/generate/mp/qrcode/${id}`, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("生成失败");
        });
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_money,
  &_degrees {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #7899ee;
    font-size: 24px;
    height: 130px;
    width: 49.5%;
  }

  &_degrees {
  }
}
</style>