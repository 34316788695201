<template>
  <div class="tags">
    <ul>
      <li
        class="tags-li"
        v-for="(item, index) in tagsList"
        :class="{ active: isActive(item) }"
        :key="index"
      >
        <router-link
          :to="{ name: item.name, params: item.params, query: item.query }"
          class="tags-li-title"
          >{{ item.title }}</router-link
        >

        <span
          v-if="!item.path.includes(firstPath)"
          class="tags-li-icon"
          @click="closeTags(item)"
        >
          <i class="el-icon-close"></i>
        </span>
      </li>
    </ul>
    <div class="tags-close-box">
      <el-dropdown @command="handleTags">
        <el-button
          size="mini"
          type="primary"
          style="
            margin-top: 10px;
            padding: 7px 8px;
            font-size: 14px;
            background: #5a8bff;
          "
        >
          标签选项
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu size="small" slot="dropdown">
          <el-dropdown-item command="refresh">刷新当前</el-dropdown-item>
          <el-dropdown-item command="other">关闭其他</el-dropdown-item>
          <el-dropdown-item command="all">关闭所有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
export default {
  data() {
    return {
      // tagsList: []
      current_route_path: "",
    };
  },
  methods: {
    isActive(route) {
      return (
        route.fullPath === this.$route.fullPath ||
        (!!route.id && route.id === this.$route?.query?.id)
      );
    },
    // 关闭单个标签
    closeTags(delItem) {
      this.tagsList.findIndex((t) => {
        return t.fullPath === delItem.fullPath;
      });
      this.$store.dispatch("delTags", delItem).then(() => {
        if (this.isActive(delItem)) {
          this.toLastView(this.tagsList, delItem);
        }
      });
    },
    //找到最新的标签
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0];

      if (_.isNil(latestView?.name) || latestView?.name === "Dashboard") {
        // to reload home page
        this.$router.push(this.firstPath);
      } else {
        const path = latestView.fullPath;
        this.$router.replace({
          path: path,
        });
      }
    },
    // 刷新当前
    refresh() {
      this.$router.push({
        name: "redirect",
        query: this.$route.query,
        params: {
          name: this.$route.name,
          params: this.$route.params,
        },
      });
    },
    goBack() {
      this.$router
        .replace({
          path: "/redirect" + this.$route.fullPath,
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 关闭全部标签
    closeAll() {
      var path = this.$store.state.tagsList.filter((item) => {
        return item.path === this.firstPath;
      });
      this.$store.commit("setTagsByList", [...path]);
      if (this.$route.path !== this.firstPath) {
        this.$router.push(this.firstPath);
      }
    },
    // 关闭其他标签
    closeOther() {
      const curItem = this.tagsList.filter((item) => {
        return (
          item.path === this.$route.fullPath || item.path === this.firstPath
        );
      });
      this.$store.commit("setTagsByList", curItem);
    },
    handleTags(command) {
      switch (command) {
        case "refresh":
          this.refresh(); // 刷新当前
          break;
        case "other":
          this.closeOther(); // 关闭其他标签
          break;
        case "all":
          this.closeAll(); // 关闭全部标签
          break;
      }
    },
  },
  computed: {
    ...mapState(["firstPath"]),
    showTags() {
      return this.tagsList.length > 0;
    },
    tagsList() {
      return this.$store.state.tagsList.filter((r) => {
        return !!r.name === true;
      });
    },
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.params.isReload) {
        newValue.params.isReload = false;
        this.refresh();
        return;
      }
      // eslint-disable-next-line no-console
      if (newValue.name !== undefined && newValue.name !== "redirect") {
        this.$store.commit("setTagsByRoute", newValue);
      }
      if (newValue.params?.refresh === 1) {
        this.goBack();
      }
    },
    tagsList: {
      deep: true,
      handler() {
        this.current_route_path = "";
      },
    },
  },
  created() {
    console.log(111111111111111);
    console.log(this.firstPath);
    console.log(111111111111111);
    this.$store.commit("setTagsByRoute", this.$route);
  },
  mounted() {},
};
</script>

 
<style>
.tags {
  position: relative;
  /*横栏高*/
  height: 50px;
  overflow: hidden;
  background: #fff;
  padding-right: 120px;
  /* border-bottom: 1px solid #e2e2e2; */
  border-bottom: 1px solid #c1cfdf;
  top: 2px;
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 8px;
}

.tags-li {
  float: left;
  margin: 19px 5px 0 3px;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -ms-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  /*按钮高*/
  height: 30px;
  line-height: 23px;
  border: 1px solid #e9eaec;
  background: #f6f8fd;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3px 5px 0 12px;
  vertical-align: middle;
  color: #666;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li.active {
  color: #fff;
  background-color: #5a8bff;
}

.tags-li-title {
  /* float: left; */
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
  display: inline-block;
  vertical-align: middle;
}

.tags-li.active .tags-li-title {
  color: #fff;
}

.tags-close-box {
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  padding-top: 1px;
  text-align: center;
  width: 120px;
  height: 48px;
  background: #fff;
  box-shadow: 0 0 0 1px #c1cfdf;
  z-index: 10;
}
</style>
