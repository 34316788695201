<!--
 * @Author: mxb
 * @Date: 2021-09-01 17:13:16
 * @LastEditors: mxb
 * @LastEditTime: 2022-01-26 18:02:09
-->
<style>
</style>
<template>
    <el-table-column :label="coloumnHeader.name" align="center" show-overflow-tooltip :render-header="labelHead">
        <template v-for="item in coloumnHeader.children">
            <el-table-column :label="item.name" :prop="item.value" show-overflow-tooltip align="center"
                :render-header="labelHead">
                <template v-for="(child,index) in item.children">
                    <CommonTableColumn v-if="child.children && child.children.length" :key="index"
                        :coloumn-header="child"></CommonTableColumn>
                    <el-table-column v-else :label="child.name" :prop="child.value" show-overflow-tooltip
                        :tooltip="tooltip" align="center" :render-header="labelHead">
                        <template slot-scope="scope">
                            <el-button type="text" @click="clickBtn(scope.$index,dataList,child.value,child.name)"
                                v-if="child.type==='bLine'" class="base-color tdu">{{scope.row[child.value]}}
                            </el-button>
                            <span v-else>{{scope.row[child.value]}}</span>
                        </template>
                    </el-table-column>
                </template>
                <template slot-scope="scope">
                    <span @click="clickBtn(scope.$index, dataList, item.value)" class="base-color cp tdu"
                        v-if="item.type === 'bLine'">
                        {{ scope.row[item.value] }}
                    </span>
                    <div v-else>{{scope.row[item.value]}}</div>
                </template>
            </el-table-column>
        </template>
    </el-table-column>
</template>
<script>
    export default {
        name: 'common-table-column',
        data() {
            return {
                tooltip: {
                    placement: 'top',
                    effect: 'dark'
                }
            }
        },
        components: {},
        props: {
            coloumnHeader: {
                type: Object,
                required: true
            },
            dataList: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            labelHead: function (h, { column, $index }) {
                let l = column.label.length
                let f = 16
                const sortLenth = column.sortable ? 30 : 0
                column.minWidth = f * (l + 2) + sortLenth //加上一个文字长度 ,如果有排序还需加上排序的宽度
                return h('div', { style: { display: 'inline-block' } }, [
                    column.label
                ])
            },
            clickBtn(index, list, methods, name) {
                var params = {
                    currentPageNum: 1,
                    pageSize: 10
                }
                params.opDailyId = list[index].opDailyId
                params[methods] = list[index][methods]
                this.$emit('record', params, methods, name)
                this.$emit('clickDetails', methods, list[index])
            }
        },
        mounted() { }
    }
</script>