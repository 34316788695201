
<div class="role-user">
  <div class="role-user-center">
    <div
      class="role-user-left"
      v-if="type=='role'"
    >
      <org-tree
        @nodeClick="handleNodeClick"
        filter
      ></org-tree>
    </div>
    <div class="role-user-table">
      <div class="role-user-table-left">
        <div class="role-user-table-left-toolbar">
          <div class="role-user-table-searchBar">
            <el-input
              v-model="search_value.keyword"
              placeholder="请输入用户姓名或账号"
              size="normal"
              clearable
              style="width: 300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="search()"
              ></el-button>
            </el-input>
          </div>

        </div>

        <!-- <el-scrollbar style="height: 100%; overflow-x: hidden"> -->
        <!-- 左边用户列表 -->
        <el-table
          :data="table_data"
          max-height="750"
          height="700"
          border
          header-align="center"
          element-loading-customClass="personal-loading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(204, 204, 204, 0.6)"
          class="refTable"
          ref="leftTable"
          default-expand-all
          :header-row-style="header_row_style"
          :header-cell-style="header_cell_style"
          :cell-style="cell_style"
          :style="table_style"
          @selection-change="handleSelectionChange"
          @select-all="handleSelectionChange"
          row-key="id"
        >
          >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="user_name"
            label="用户账号"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="real_name"
            label="用户姓名"
            show-overflow-tooltip
          >
          </el-table-column>

        </el-table>
        <!-- </el-scrollbar> -->
        <div class="file-table-footer">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="current_page"
            small
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="page_size"
            layout="total, sizes, prev, pager, next"
            :total="total_count"
          ></el-pagination>
        </div>
      </div>
      <!-- 中间穿梭框按钮 -->
      <div class="role-user-table-center">
        <el-button
          icon="el-icon-arrow-right"
          circle
          :disabled="addToRightDisabled"
          type="primary"
          @click="addToRight"
        ></el-button>
        <el-button
          icon="el-icon-arrow-left"
          circle
          @click="removeFromRight"
          type="primary"
          :disabled="removeFromRightDisabled"
        ></el-button>
      </div>
      <div class="role-user-table-right">
        <div class="role-user-table-searchBar">
          <el-input
            v-model="searchCode"
            placeholder="请输入用户姓名或编号"
            size="normal"
            clearable
            style="width: 300px"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="setSearchCode"
            ></el-button>
          </el-input>
        </div>
        <!-- <el-scrollbar style="height: 100%; overflow-x: hidden"> -->
        <!-- 右边选中的人员列表 -->
        <el-table
          :data="transferData"
          max-height="750"
          height="700"
          border
          header-align="center"
          element-loading-customClass="personal-loading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(204, 204, 204, 0.6)"
          class="refTable"
          ref="refTable"
          default-expand-all
          :header-row-style="header_row_style"
          :header-cell-style="header_cell_style"
          :cell-style="cell_style"
          :style="table_style"
          show-overflow-tooltip
          @selection-change="handleTransferSelectionChange"
          row-key="id"
        >
          >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="user_name"
            label="用户账号"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="real_name"
            label="用户姓名"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <!-- </el-scrollbar> -->
        <div class="file-table-footer">
          <el-pagination
            @size-change="handleTransferSizeChange"
            @current-change="handleTransferCurrentChange"
            :current-page.sync="transfer_current_page"
            small
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="transfer_page_size"
            layout="total, sizes, prev, pager, next"
            :total="transferFilterData.length"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>

  <div class="role-user-foot">

    <el-button
      type="primary"
      @click="submit"
    >确定</el-button>
    <el-button @click="cancel">取消</el-button>

  </div>
</div>
