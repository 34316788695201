<template>
  <el-dialog
    :title="'账户明细-' + this.dataItem.user_number"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
  >
    <div class="height:100%;">
      <el-card class="box-card">
        <el-form :inline="true">
          <el-form-item label="收入/支出">
            <el-select v-model="form.record_type" placeholder="全部" clearable>
              <el-option :value="1" label="收入" />
              <el-option :value="-1" label="支出" />
            </el-select>
          </el-form-item>
          <el-button style="margin-right: 15px" type="primary" @click="search"
            >查询</el-button
          >
          <el-button style="float: right" @click="exportData">导出</el-button>
        </el-form>
      </el-card>
      <el-card class="box-card">
        <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
          <el-table-column
            prop="type_name"
            label="名称"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="时间"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="order_no"
            label="订单编号"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="收入/支出"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope">
              <span
                style="color: red"
                v-if="
                  scope.row.type == '0' ||
                  scope.row.type == '3' ||
                  scope.row.type == '5'
                "
                >{{ "+" + scope.row.amount }}</span
              >
              <span
                style="color: green"
                v-if="
                  scope.row.type == '1' ||
                  scope.row.type == '2' ||
                  scope.row.type == '4'
                "
                >{{
                  scope.row.amount < 0 ? scope.row.amount : -scope.row.amount
                }}</span
              >
              <span v-if="scope.row.type == -1">{{ scope.row.amount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="early_amount"
            label="前"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="current_amount"
            label="后"
            min-width="100"
            align="center"
          ></el-table-column>
        </el-table>
        <el-footer height="32px" style="margin-top: 10px">
          <el-pagination
            background
            layout="total,sizes, prev, pager, next,jumper"
            :total="page_total"
            :page-size="page_size"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :current-page.sync="page_index"
          ></el-pagination>
        </el-footer>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import OPERATE_API from "../../../api/operate_api";
import * as XLSX from "xlsx";
import { operateAdd } from "@/api/system/user-api";

export default {
  props: {},
  data() {
    return {
      visible: false,
      dataItem: {},
      form: {
        record_type: "",
        user_id: "",
      },
      cityList: [],
      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 20,
      tableHeight: "70vh",
    };
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  mounted() {},
  methods: {
    init(dataItem) {
      this.visible = true;
      this.dataItem = dataItem;
      this.dataTable = [];
      this.clear();
      this.search();
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {};
    },
    loadDataTable() {
      this.form.user_id = this.dataItem.user_id;
      let params = {
        ...this.form,
        source: "pc",
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .post(
          "/api/userAccountRecord/get?$limit=" + limit + "&$offset=" + offset,
          params
        )
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.items;
          this.page_total = response.data.total;
          this.$nextTick(() => {
            this.$refs["dataTable"].doLayout();
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 29,
        operate_parameter: JSON.stringify({
          ...this.form,
          ...this.multiQuery,
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        ...this.form,
        ...this.multiQuery,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .post("/api/userAccountRecord/list", params)
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.items);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "账户明细.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["名称"] = item.type_name;
        newObj["时间"] = item.create_time;
        let amount = item.amount;
        if (item.type == 1 || item.type == 2 || item.type == 4) {
          amount = item.amount < 0 ? item.amount : -item.amount;
        }
        newObj["订单编号"] = item.order_no;
        newObj["收入/支出"] = amount;
        newObj["前"] = item.early_amount;
        newObj["后"] = item.current_amount;
        newArray.push(newObj);
      });
      return newArray;
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
</style>