<template>
  <div class="height:100%;">
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <el-form label-width="80px">
          <div class="el-col">
            <el-form-item label="类型">
              <el-select v-model="form.newsType" @change="searchBtn">
                <el-option value="1" label="电池保养常识"></el-option>
                <el-option value="2" label="查看教程"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="elColBtn">
              <el-button
                style="margin-right: 10px"
                type="primary"
                @click="searchBtn"
                >查询</el-button
              >
              <el-button type="default" @click="clear">重置</el-button>
            </el-form-item>
          </div>
        </el-form>
      </el-row>
      <el-row style="margin-bottom: 15px">
        <el-button style="margin-left: 15px" @click="addOrUpdateHandle('add')"
          >新增</el-button
        >
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
      >
        <el-table-column label="序号" min-width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="news_type"
          label="资讯类型"
          min-width="50"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.news_type == 1 ? "电池保养常识" : "查看教程"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="news_title"
          label="资讯标题"
          min-width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="subtitle"
          label="副题目"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cover_pic_url"
          label="封面"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="
                width: 150px;
                height: 150px;
                border-radius: 6px;
                margin-right: 10px;
              "
              :src="file_view_rul + scope.row.cover_pic_url"
              :preview-src-list="[file_view_rul + scope.row.cover_pic_url]"
              :disabled="true"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="release_time"
          label="发布日期"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              @click="handleOperation(scope.row, 'edit')"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              @click="handleOperation(scope.row, 'del')"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="page_total"
          :current-page.sync="page_index"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <batteryMaintenanceAddModal
      ref="addModal"
      @refreshDataList="loadDataTable"
      :editState="editState"
    />
  </div>
</template>
<script>
import OPERATE_API from "../../api/operate_api";
import agentApi from "../../api/agent_api";
import batteryMaintenanceAddModal from "./model/battery-maintenance-add.modal.vue";
export default {
  name: "bill-manage",
  components: {
    batteryMaintenanceAddModal,
  },
  data() {
    return {
      editState: "add",
      total: 0,
      currentPage: 1,
      vloading: false,
      dataTable: [],
      pageSize: 50,
      offset: 0,
      limit: 0,
      page_total: 0,
      page_index: 1,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      file_view_rul: this.FILE_VIEW_ROOT_RUL,
      form: {
        newsType: "1",
      },
    };
  },
  async created() {
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  async mounted() {
    this.loadDataTable();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  methods: {
    searchBtn() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form.newsType = "1";
      this.page_index = 1;
      this.loadDataTable();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.limit = this.pageSize;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(current) {
      this.page_index = current;
      this.loadDataTable();
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 300
      );
    },

    loadDataTable() {
      let params = {
        ...this.form,
      };
      let limit = this.pageSize;
      let offset = this.pageSize * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/msg/battery/maintain/query/${offset}/${limit}`, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.data.items;
          this.page_total = response.data.data.total;
          //   this.$nextTick(() => {
          //     this.$refs["dataTable"].doLayout();
          //   });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    addOrUpdateHandle(type) {
      this.editState = "add";
      this.$refs.addModal.visible = true;
      this.$refs.addModal.init();
    },
    async handleOperation(row, type) {
      switch (type) {
        case "edit":
          this.editState = "edit";
          this.axiosHelper
            .get(`/msg/battery/maintain/get?id=${row.id}`, {
              params: {},
            })
            .then((res) => {
              this.hideLoading();
              this.$refs.addModal.init(res.data.data);
              this.$refs.addModal.visible = true;
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            });
          break;
        case "del":
          this.$confirm(`确定删除该条内容么?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.axiosHelper
                .post(`/msg/battery/maintain/delete?id=${row.id}`)
                .then((res) => {
                  this.$message.success("删除成功");
                  this.hideLoading();
                  this.loadDataTable();
                })
                .catch(() => {
                  this.hideLoading();
                  this.$message.error("加载失败");
                });
            })
            .catch((err) => {
              return err;
            });

          break;
      }
      // this.billDeatilVisible = true
      // this.$nextTick(() => {
      //     this.$refs.billDeatil.init(row)
      // })
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}
</style>
