
<div :style="{height:heightAuto?'100%':'auto'}">
    <el-row :style="{height:heightAuto?'100%':'auto'}">
        <el-col :span="24" :style="{height:heightAuto?'100%':'auto'}">
            <template v-if="setTableHigh">
                <el-table v-loading="loading" :element-loading-text="loadingText" element-loading-customClass="personal-loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(204, 204, 204, 0.6)" class="refTable" :class="{removeDefaultHidden:true,fixCellHeight: fixCellHeight}" :style="{'margin-top':heightAuto?'0':'10px'}" ref="refTable" :row-key="rowKey" :data="newData" :empty-text="emptyText" border default-expand-all :tree-props="{children: 'children'}" :header-row-style="{'line-height':'36px'}" :header-cell-style="{background:'#EDF1F4',textAlign:'center', padding:'0'}"
                          :cell-style="{fontSize:'14px', color:'#000', padding:'0'}" :highlight-current-row="rowLight" @sort-change="sortChange" :default-sort="defaultSortObj"
                          @selection-change="selectChange" @header-click="headerClick" @row-click="rowClick" @header-contextmenu="rightClick" style="width:100%;" :height="tableHigh" :row-class-name="tableRowClassName" @header-dragend="handleHeaderDragend">
                    <el-table-column v-if="showCheck===true" type="selection" width="40px" align="center" :reserve-selection="reserveSelection"
                        :selectable="selectable">
                    </el-table-column>
                    <el-table-column v-if="showIndex===true" type="index" label="序号" width="60px" align="center">
                    </el-table-column>
                    <el-table-column v-for="(col, index) in columns" :key="index" :min-width="col.minWidth"
                        :show-overflow-tooltip="overflow" :prop="col.prop" :header-align="col.headerAlign" :align="col.style" :type="col.type"
                        :fixed="col.fixed" :sortable="col.sortable"  :sort-method="col.sortMethod" :class-name="col.className">
                        <template slot="header" slot-scope="">
                            <label-slot :label="col.label" />
                        </template>
                        <template slot-scope="scope">
                            <div :style="{position:'relative',display:'inline-block','max-width':'100%',height:fixCellHeight?'36px':'auto','line-height':fixCellHeight?'36px':'',padding:fixCellHeight?'':'5px 0','margin-right':'1px',overflow:'hidden','vertical-align':'middle','text-overflow':'ellipsis','white-space':'nowrap'}">
                                <ex-slot v-if="col.render" :render="col.render" :row="scope.row" :index="scope.$index"
                                    :column="col" :showCard="col.showCard">
                                </ex-slot>
                                <ex-slot v-else-if="col.showCard&&col.propId" :propId="col.propId" :showCard="col.showCard" :row="scope.row" :index="scope.$index" :column="col"></ex-slot>
                                <span v-else>
                                    {{scope.row[col.prop]}}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <span slot="empty"></span>
                </el-table>
                <div v-if="showEmpty" :style="{position:'absolute',top:'50%',left:'50%',marginTop:'-6px',marginLeft:'-24px',color:'#909399',fontSize:'12px'}">{{ showLoading ? '' : '暂无数据'}}</div>
            </template>
            <template v-else>
                <el-table v-loading="loading" :element-loading-text="loadingText" element-loading-customClass="personal-loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(204, 204, 204, 0.6)" :class="{removeDefaultHidden:true,fixCellHeight:fixCellHeight}" ref="refTable" :data="newData" :empty-text="emptyText" border :row-key="rowKey" default-expand-all :tree-props="{children: 'children'}"
                          @select-all="selectAll" @sort-change="sortChange" @selection-change="selectChange" @header-click="headerClick" :default-sort="defaultSortObj"
                          :cell-style="{fontSize:'14px', padding:'0'}" :highlight-current-row="rowLight" @row-click="rowClick" @header-contextmenu="rightClick"
                          style="width:100%;" :header-row-style="{'line-height':'36px'}" :header-cell-style="{background:'#EDF1F4', padding:'0'}" class="no-setHigh-table table-base-table" :row-class-name="tableRowClassName" @header-dragend="handleHeaderDragend">
                    <el-table-column v-if="showCheck===true" type="selection" width="40px" fixed="left" align="center" :reserve-selection="reserveSelection"
                        :selectable="selectable">
                    </el-table-column>
                    <el-table-column v-if="showIndex===true" type="index" label="序号" width="60" align="center">
                    </el-table-column>
                    <el-table-column v-for="(col, index) in columns" :key="index" :min-width="col.minWidth"
                        :show-overflow-tooltip="overflow" :prop="col.prop" :header-align="col.headerAlign"  :align="col.style" :type="col.type"
                        :fixed="col.fixed" :sortable="col.sortable" :sort-method="col.sortMethod" :label="col.label">
                        <template slot-scope="scope">
                            <div :style="{position:'relative',display:'inline-block','max-width':'100%',height:fixCellHeight?'36px':'auto','line-height':fixCellHeight?'36px':'',padding:fixCellHeight?'':'5px 0','margin-right':'1px',overflow:'hidden','vertical-align':'middle','text-overflow':'ellipsis','white-space':'nowrap'}">
                                <ex-slot v-if="col.render" :render="col.render" :row="scope.row" :index="scope.$index"
                                    :column="col">
                                </ex-slot>
                                <ex-slot v-else-if="col.showCard&&col.propId" :propId="col.propId" :showCard="col.showCard" :row="scope.row" :index="scope.$index" :column="col"></ex-slot>
                                <span v-else>
                                    {{scope.row[col.prop]}}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <span slot="empty"></span>
                </el-table>
                <div v-if="showEmpty" :style="{position:'absolute',top:'50%',left:'50%',marginTop:noPage?'16px':'-6px',marginLeft:'-24px',color:'#909399',fontSize:'12px'}">{{ showLoading ? '' : '暂无数据'}}</div>
            </template>
            <el-row v-show="noPage===false" type="flex" justify="space-between" class="footer">
                <el-col>
                    <div class="page">
                        <el-pagination background :small="small" @size-change="handleSizeChange"
                            @current-change="handleCurrentChange" :highlight-current-row="rowLight"
                            :current-page="currentPage" :page-sizes="page_sizes" :layout="layout"
                            :page-size="pageSize" :total="total" :disabled="pageForbidden">
                        </el-pagination>
                    </div>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</div>
