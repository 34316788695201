
<div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
        <el-form :inline="true">
            <el-form-item label="场景名称：">
                <el-input v-model.trim="form.billingName" placeholder="请输入" clearable />
            </el-form-item>
            <el-form-item label="创建时间：">
                <el-date-picker v-model="createTime" type="datetimerange" style="width: 400px"
                    value-format="yyyy-MM-dd HH:mm:ss" :clearable="true" :default-time="['00:00:00', '23:59:59']"
                    range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.status" placeholder="全部" collapse-tags clearable>
                    <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-button style="margin-right: 15px" type="primary" @click="search">查询</el-button>
            <el-button style="margin-right: 15px" type="default" @click="clear">重置</el-button>
        </el-form>
    </el-card>
    <el-card class="box-card">
        <el-table ref="tableData" :height="tableHeight" :data="dataTable">
            <!-- <el-table-column
                type="selection"
                width="50"
                align="center"></el-table-column> -->
            <el-table-column prop="billing_name" label="场景名称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="create_time" label="创建时间" min-width="150" align="center"></el-table-column>
            <el-table-column prop="billing_desc" label="活动描述" min-width="150" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" min-width="100" align="center">
                <template slot-scope="scope">
                    <div>
                        {{
                        !scope.row.status
                        ? "停用": "启用"
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100" align="center">
                <template slot-scope="scope">
                    <el-button @click="statusSwitch(scope.row)" type="text" size="small">{{
                        !scope.row.status ? "启用" : "停用"
                        }}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-footer height="32px" style="margin-top: 10px">
            <el-pagination background layout="total,sizes, prev, pager, next,jumper" :total="page_total"
                :page-size="page_size" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 50, 100]" :current-page.sync="page_index"></el-pagination>
        </el-footer>
    </el-card>
</div>
