<template>
  <el-dialog
    class="showAll_dialog"
    :title="'详情'"
    width="800px"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="form"
      style="margin-top: 20px"
      ref="form"
      label-width="120px"
      v-loading="formLoading"
    >
      <div class="flex">
        <el-form-item label="用户手机号：">
          <el-input
            v-model.trim="form.cellphone"
            placeholder="请输入券名"
            :disabled="true"
            clearable
          />
        </el-form-item>
        <el-form-item label="用户编号：">
          <el-input
            v-model.trim="form.user_number"
            placeholder="请输入用户编号"
            :disabled="true"
            clearable
            maxlength="10"
            oninput="value=value.replace(/[^0-9]/g,'')"
          />
        </el-form-item>
        <el-form-item label="城市：" prop="city_code">
          <el-select
            v-model="form.city_code"
            placeholder="请选择城市"
            :disabled="true"
          >
            <el-option :label="form.city_name" :value="form.city_code">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="发券方式：">
        <el-radio-group :disabled="true" v-model="form.distribute_src">
          <el-radio label="1">系统发券</el-radio>
          <el-radio label="2">手动发券</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="flex">
        <el-form-item label="来源活动：">
          <el-input
            v-model.trim="form.promotion_name"
            :disabled="true"
            clearable
          />
        </el-form-item>
        <el-form-item label="来源场景：">
          <el-input
            v-model.trim="form.billing_name"
            :disabled="true"
            clearable
          />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="优惠券编号：">
          <el-input v-model.trim="form.id" :disabled="true" clearable />
        </el-form-item>
        <el-form-item label="优惠券名称：">
          <el-input v-model.trim="form.name" :disabled="true" clearable />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="领取时间：">
          <el-input
            v-model.trim="form.create_time"
            :disabled="true"
            clearable
          />
        </el-form-item>
        <el-form-item label="优惠券类型">
          <el-input
            v-model.trim="form.discount_type_name"
            :disabled="true"
            clearable
          />
        </el-form-item>
      </div>
      <el-form-item label="时间限制：">
        <el-input
          v-model.trim="form.time_interval_limit"
          :disabled="true"
          clearable
        />
      </el-form-item>
      <el-form-item label="有效期：">
        <el-input
          v-model.trim="form.validity_time"
          :disabled="true"
          clearable
        />
      </el-form-item>
      <el-form-item label="状态">
        <el-input v-model.trim="form.state_name" :disabled="true" clearable />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="visible = false">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import axios from "../../../utils/axiosHelper";
export default {
  data() {
    return {
      form: {},
      visible: false,
      formLoading: false,
      cityList: [],
      conditionList: [],
      tableHeight: "50vh",
    };
  },
  mounted() {},
  methods: {
    init(row) {
      this.$nextTick(() => {
        if (row) {
          this.form = JSON.parse(JSON.stringify(row));
          this.form.time_interval_limit = !this.form.time_interval_limit
            ? "否"
            : this.form.time_interval_limit === 1
            ? "常规时间"
            : "夜间时间";
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form_label {
  &::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 15px;
    background-color: #f6a83a !important;
    margin-right: 6px;
  }

  &::after {
    content: " ";
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: rgb(222, 222, 222);
    margin: 10px 0;
  }
}

.showAll_dialog {
  overflow: hidden;

  ::v-deep .el-dialog {
    height: 70%;
    overflow: hidden;

    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
      line-height: 30px;
      padding: 0 15px 60px;
    }

    .el-dialog__footer {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      padding: 0 20px 0;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}
</style>