
    <el-dialog title="编辑有效期" v-dialogDrag :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
        <el-form :model="dataForm" ref="dataForm" label-width="125px" >
            <el-row>
                <el-col :span="24">
                    <el-form-item label="有效期：">
                        <el-date-picker v-model="validity_Time" type="datetimerange" :default-time="['00:00:00', '23:59:59']"
										value-format="yyyy-MM-dd HH:mm:ss" :clearable="true" range-separator="至"
										start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
