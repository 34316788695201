
<el-dialog title="用户退款" :close-on-click-modal="false" :visible.sync="visible">
  <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="140px" @submit.native.prevent>
    <el-form-item label="手机号码：" prop="cellphone">
      {{ this.formItem.cellphone}}
    </el-form-item>
    <el-form-item label="申请信息：" prop="apply_content">
      {{ this.formItem.apply_content}}
    </el-form-item>
    <el-form-item label="用户账户余额：" prop="apply_content">
      {{ this.totalAmount}}
    </el-form-item>
    <el-form-item label="退款金额：" prop="refund_amount">
      <el-input v-model="dataForm.refund_amount" placeholder="请输入" show-word-limit style="width: 30%;"
          oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 999999.99 ? 999999.99 : value" size="mini" ></el-input>

    </el-form-item>

  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="visible = false">取消</el-button>
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
  </span>
</el-dialog>
