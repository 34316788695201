
<el-dialog class="showAll_dialog" :title="'编辑'" width="800px" :height="'60%'" :max-height="'450px'"
    :close-on-click-modal="false" :visible.sync="visible" @close="reset">
    <el-form :model="form" ref="form" label-width="110px" v-loading="formLoading">
        <div class="flex">
            <el-form-item label="姓名">
                <el-input v-model.trim="form.partner_name" readonly placeholder="请输入姓名：" clearable maxlength="35" />
            </el-form-item>
            <el-form-item label="手机号">
                <el-input v-model.trim="form.partner_tel" readonly placeholder="请输入手机号" clearable maxlength="35" />
            </el-form-item>
        </div>
        <div class="flex">
            <el-form-item label="意向城市">
                <el-input v-model.trim="form.cityName" readonly placeholder="请输入意向城市：" clearable maxlength="35" />
            </el-form-item>
            <el-form-item label="投资预算">
                <el-input v-model.trim="form.investment_budget_name" readonly placeholder="请输入投资预算" clearable
                    maxlength="35" />
            </el-form-item>
        </div>
        <el-form-item label="状态">
            <el-select v-model="form.state" placeholder="请选择状态">
                <el-option label="未联系" value="0"></el-option>
                <el-option label="已联系" value="1"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="details">
            <el-input type="textarea" :rows="5" v-model="form.remarks" placeholder="备注"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="formSubmit()">确定</el-button>
        <el-button v-if="editState != 'details'" @click="visible = false">取消</el-button>
    </span>
</el-dialog>
