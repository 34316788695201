
<el-dialog title="总部补贴明细" :close-on-click-modal="true" :visible.sync="visible" width="70%" @close="close" @open="open">
    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%;" max-height="500">
    <el-table-column prop="real_name" header-align="center" align="center" min-width="100" label="申请人">
    </el-table-column>
    <el-table-column prop="cellphone" header-align="center" align="center" min-width="100" label="申请人手机号">
    </el-table-column>
    <el-table-column prop="create_time" header-align="center" align="center" label="补贴时间">
    </el-table-column>
    <el-table-column prop="amount" header-align="center" align="center" label="补贴金额">
    </el-table-column>
    <el-table-column prop="early_amount" header-align="center" align="center" label="补贴前金额">
    </el-table-column>
    <el-table-column prop="current_amount" header-align="center" align="center" label="补贴后金额">
    </el-table-column>
    <el-table-column prop="associate_id" header-align="center" align="center" label="审批编号">
    </el-table-column>
    <el-table-column prop="remark" header-align="center" align="center" min-width="200" label="备注">
    </el-table-column>
  </el-table>
  <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
    :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" 
    layout="total, sizes, prev, pager, next, jumper">
  </el-pagination>

</el-dialog>
