<template>
  <el-dialog
    class="showAll_dialog"
    :title="this.editState == 'edit' ? '编辑' : '新增'"
    width="800px"
    :height="'80%'"
    :max-height="'600px'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <div ref="dialogBody">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        label-width="160px"
        v-loading="formLoading"
      >
        <!-- 基础信息 -->
        <div class="form_list">
          <div class="form_list-title">基础信息</div>
          <el-form-item label="活动名称：" prop="name">
            <el-input
              v-model.trim="dataForm.name"
              placeholder="请输入活动名称"
              clearable
              maxlength="35"
            />
          </el-form-item>
          <el-form-item label="活动展示名称：" prop="promotion_show_name">
            <el-input
              v-model.trim="dataForm.promotion_show_name"
              placeholder="请输入活动展示名称"
              clearable
              maxlength="30"
            />
          </el-form-item>
          <el-form-item label="活动时间：" prop="create_time">
            <el-date-picker
              v-model="dataForm.create_time"
              type="datetimerange"
              style="width: 400px"
              value-format="yyyy-MM-dd HH:mm:ss"
              :clearable="true"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change="timeChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="城市：" prop="city_code">
            <el-select
              v-model="dataForm.city_code"
              placeholder="请选择城市"
              @change="cityChange"
              collapse-tags
              clearable
            >
              <el-option
                v-for="item in cityList"
                :key="item.id"
                :label="item.city_name"
                :value="item.city_code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- 活动配置 -->
        <div class="form_list">
          <div class="form_list-title">活动配置</div>
          <el-form-item label="场景：" prop="billing_type">
            <el-select
              v-model="dataForm.billing_type"
              placeholder="请选择场景"
              collapse-tags
              @change="billingChange"
              clearable
            >
              <el-option
                v-for="item in sceneList"
                :key="item.id"
                :label="item.billing_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="补贴金额上限：" prop="total_amount">
            <div class="flex">
              <el-input
                v-model.trim="dataForm.total_amount"
                placeholder="请输入补贴金额上限"
                clearable
                maxlength="9"
              />
              <span class="marleft10">元</span>
            </div>
          </el-form-item>
          <div class="flex">
            <el-form-item
              :label="
                dataForm.billing_type == '13'
                  ? '参与活动人数限制：'
                  : '人数限制：'
              "
              prop="personCode"
            >
              <el-select
                style="width: 150px"
                v-model="dataForm.personCode"
                placeholder="请选择"
                @change="
                  () => {
                    dataForm.person_limit = '';
                  }
                "
                collapse-tags
                clearable
              >
                <el-option label="否" :value="-1"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="dataForm.personCode === 1"
              label="前"
              prop="person_limit"
              label-width="50px"
            >
              <div class="flex">
                <el-input
                  v-model.trim="dataForm.person_limit"
                  placeholder="请输入人数"
                  clearable
                  maxlength="6"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                />
                <span class="marleft10">人</span>
              </div>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="场景互斥限制：" prop="billing_mutex">
              <el-select
                v-model="dataForm.billing_mutex"
                placeholder="请选择"
                @change="
                  () => {
                    dataForm.mutex_billing_type = '';
                  }
                "
                collapse-tags
                clearable
              >
                <el-option label="否" :value="0"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="dataForm.billing_mutex"
              label="互斥场景："
              prop="mutex_billing_type"
            >
              <el-select
                v-model="dataForm.mutex_billing_type"
                placeholder="请选择"
                collapse-tags
                clearable
              >
                <el-option
                  v-for="item in sceneList"
                  :key="item.id"
                  :label="item.billing_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="flex" v-if="billingId === 0">
            <el-form-item label="落点设置：" prop="jump_page">
              <el-select v-model="dataForm.jump_page" placeholder="请选择">
                <el-option label="首页" value="1"></el-option>
                <el-option label="常规充电下单页" value="2"></el-option>
                <el-option label="应急补电下单页" value="3"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="flex" v-if="dataForm.billing_type === 13">
            <el-form-item label="参与活动次数限制" prop="frequencyCode">
              <el-select
                style="width: 150px"
                v-model="dataForm.frequencyCode"
                placeholder="请选择"
                @change="
                  () => {
                    dataForm.every_one_times_limit = '';
                  }
                "
                collapse-tags
                clearable
              >
                <el-option label="否" :value="-1"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="dataForm.frequencyCode === 1"
              label=""
              prop="every_one_times_limit"
              label-width="50px"
            >
              <div class="flex">
                <el-input
                  v-model.trim="dataForm.every_one_times_limit"
                  placeholder="请输入次数"
                  clearable
                  maxlength="6"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                />
                <span class="marleft10">次</span>
              </div>
            </el-form-item>
          </div>
          <div class="flex" v-if="dataForm.billing_type === 13">
            <el-form-item
              label="连续邀请人数"
              prop="reach_standard_meet_person_num"
            >
              <div class="flex">
                <el-input
                  v-model.trim="dataForm.reach_standard_meet_person_num"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  placeholder="请输入人数"
                  clearable
                  maxlength="9"
                />
                <span class="marleft10">人</span>
              </div>
            </el-form-item>
          </div>
          <div
            v-if="billing_rule_type === 2 || billing_rule_type === 3"
            class="flex"
          >
            <el-form-item
              label="单人充值上限："
              prop="every_one_recharge_limit_code"
            >
              <el-select
                v-model="dataForm.every_one_recharge_limit_code"
                placeholder="请选择"
                @change="
                  () => {
                    dataForm.every_one_recharge_limit = '';
                  }
                "
                collapse-tags
                clearable
              >
                <el-option label="否" :value="-1"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="dataForm.every_one_recharge_limit_code === 1"
              label="金额："
              prop="every_one_recharge_limit"
            >
              <div class="flex">
                <el-input
                  v-model.trim="dataForm.every_one_recharge_limit"
                  placeholder="请输入金额"
                  clearable
                  maxlength="9"
                />
                <span class="marleft10">元</span>
              </div>
            </el-form-item>
          </div>
          <!-- 1 除充值及度数类之外的活动 -->
          <div v-if="billing_rule_type === 1">
            <div
              v-for="(item, index) in dataForm.activity_subsidy_type_list"
              :key="index"
            >
              <div class="flex">
                <el-form-item
                  label="优惠类型："
                  style="width: 100%"
                  :prop="`activity_subsidy_type_list.${index}.subsidy_type`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择优惠类型',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <el-select
                    v-model="item.subsidy_type"
                    placeholder="请选择优惠类型"
                    @change="subsidyChange($event, index)"
                    collapse-tags
                  >
                    <el-option label="优惠券" :value="1"></el-option>
                    <el-option
                      v-if="billingId === 0"
                      label="充电度数"
                      :value="2"
                    ></el-option>
                    <el-option
                      v-if="billingId === 0 || billingId === 2"
                      label="车辆性能报告"
                      :value="3"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div
                  v-if="billingId !== 0"
                  class="flex"
                  style="margin-left: 10px; margin-bottom: 18px"
                >
                  <img
                    class="icon"
                    @click="formArrHandleChange(0)"
                    src="@/assets/img/icon-add.png"
                    alt=""
                  />
                  <img
                    v-if="dataForm.activity_subsidy_type_list.length > 1"
                    @click="formArrHandleChange(1, index)"
                    class="icon"
                    src="@/assets/img/icon-delete.png"
                    alt=""
                  />
                </div>
              </div>
              <el-form-item
                v-if="item.subsidy_type === 1"
                label="支付互斥限制："
                :prop="`activity_subsidy_type_list.${index}.pay_mutex_limit`"
                :rules="[
                  {
                    required: true,
                    message: '请选择支付互斥限制',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <el-select
                  v-model="item.pay_mutex_limit"
                  placeholder="请选择"
                  collapse-tags
                  clearable
                >
                  <el-option label="否" :value="0"></el-option>
                  <el-option label="是" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="item.subsidy_type === 2"
                label="度数："
                :prop="`activity_subsidy_type_list.${index}.degree`"
                :rules="[
                  {
                    required: true,
                    message: '请输入度数',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <el-input
                  v-model.trim="item.degree"
                  placeholder="请输入度数"
                  clearable
                  maxlength="8"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                />
              </el-form-item>
              <el-form-item
                v-if="item.subsidy_type === 3"
                label="次数："
                :prop="`activity_subsidy_type_list.${index}.num`"
                :rules="numRules"
              >
                <el-input
                  v-model.trim="item.num"
                  disabled
                  placeholder="请输入次数"
                  clearable
                  maxlength="8"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                />
              </el-form-item>
              <div
                v-if="
                  item.coupon_quote_list &&
                  item.coupon_quote_list.length &&
                  item.subsidy_type === 1
                "
              >
                <div
                  class="flex"
                  v-for="(val, valIndex) in item.coupon_quote_list"
                  :key="valIndex"
                >
                  <el-form-item
                    label="券："
                    :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.coupon_id`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择券',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <el-select
                      v-model="val.coupon_id"
                      placeholder="请选择券"
                      collapse-tags
                      clearable
                    >
                      <el-option
                        v-for="couponVal in couponList"
                        :key="couponVal.id"
                        :label="couponVal.name"
                        :value="couponVal.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="有效期："
                    :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.valid_day`"
                    label-width="90px"
                    :rules="[
                      {
                        required: true,
                        message: '请输入有效期',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <div class="flex">
                      <el-input
                        v-model.trim="val.valid_day"
                        style="width: 100px"
                        placeholder="有效期"
                        clearable
                        maxlength="6"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                      />
                      <span class="marleft10">天</span>
                    </div>
                  </el-form-item>
                  <el-form-item
                    label="张数："
                    :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.num`"
                    label-width="70px"
                    :rules="[
                      {
                        required: true,
                        message: '请输入张数',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <el-input-number
                      v-model.number="val.num"
                      style="width: 90px"
                      :min="1"
                      :max="99"
                      controls-position="right"
                      @keyup.native="UpNumber"
                      @keydown.native="UpNumber"
                      label="描述文字"
                      :readonly="item"
                    ></el-input-number>
                  </el-form-item>
                  <div
                    v-if="billingId !== 0"
                    class="flex"
                    style="margin-left: 10px; margin-bottom: 18px"
                  ></div>
                  <img
                    class="icon"
                    @click="formArrHandleChange(2, index)"
                    src="@/assets/img/icon-add.png"
                    alt=""
                  />
                  <img
                    v-if="item.coupon_quote_list.length > 1"
                    @click="formArrHandleChange(3, index, valIndex)"
                    class="icon"
                    src="@/assets/img/icon-delete.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 2 充值类活动 -->
        <div v-if="billing_rule_type === 2">
          <div
            v-for="(item, index) in dataForm.recharge_rule_list"
            :key="index"
          >
            <div class="flex">
              <el-form-item
                :label="`第 ${index + 1} 档充值`"
                :prop="`recharge_rule_list.${index}.recharge`"
                :rules="[
                  {
                    required: true,
                    message: '只能数字且最多6位，小数点最多2位',
                    pattern: /^\d{1,6}(\.\d{1,2})?$/,
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <div class="flex">
                  <el-input
                    v-model.trim="item.recharge"
                    placeholder="请输入金额"
                    clearable
                    maxlength="9"
                  />
                  <img
                    class="icon marleft10"
                    @click="topUpArrHandleChange(0)"
                    src="@/assets/img/icon-add.png"
                    alt=""
                  />
                  <img
                    v-if="dataForm.recharge_rule_list.length > 1"
                    @click="topUpArrHandleChange(1, index)"
                    class="icon marleft10"
                    src="@/assets/img/icon-delete.png"
                    alt=""
                  />
                </div>
              </el-form-item>
            </div>
            <div>
              <div
                v-for="(val, valIndex) in item.activity_subsidy_type_list"
                :key="valIndex"
              >
                <!-- 优惠类型 -->
                <div class="flex">
                  <el-form-item
                    label="优惠类型："
                    style="width: 100%"
                    :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.subsidy_type`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择优惠类型',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <el-select
                      v-model="val.subsidy_type"
                      placeholder="请选择优惠类型"
                      @change="subsidyChange($event, index)"
                      collapse-tags
                    >
                      <el-option label="优惠券" :value="1"> </el-option>
                    </el-select>
                  </el-form-item>
                  <div
                    class="flex"
                    style="margin-left: 10px; margin-bottom: 18px"
                  >
                    <img
                      class="icon"
                      @click="topUpArrHandleChange(2, index)"
                      src="@/assets/img/icon-add.png"
                      alt=""
                    />
                    <img
                      v-if="item.activity_subsidy_type_list.length > 1"
                      @click="topUpArrHandleChange(3, index, valindex)"
                      class="icon"
                      src="@/assets/img/icon-delete.png"
                      alt=""
                    />
                  </div>
                </div>
                <!-- 券 有效期 -->
                <div
                  v-if="val.coupon_quote_list && val.coupon_quote_list.length"
                >
                  <div
                    class="flex"
                    v-for="(coupon, couponIndex) in val.coupon_quote_list"
                    :key="couponIndex"
                  >
                    <el-form-item
                      label="券："
                      :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.coupon_quote_list.${couponIndex}.coupon_id`"
                      :rules="[
                        {
                          required: true,
                          message: '请选择券',
                          trigger: ['change', 'blur'],
                        },
                      ]"
                    >
                      <el-select
                        v-model="coupon.coupon_id"
                        placeholder="请选择券"
                        collapse-tags
                        clearable
                      >
                        <el-option
                          v-for="couponVal in couponList"
                          :key="couponVal.id"
                          :label="couponVal.name"
                          :value="couponVal.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="有效期："
                      :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.coupon_quote_list.${couponIndex}.valid_day`"
                      label-width="90px"
                      :rules="[
                        {
                          required: true,
                          message: '只能数字且最多6位',
                          pattern: /^\d{1,6}?$/,
                          trigger: ['change', 'blur'],
                        },
                      ]"
                    >
                      <div class="flex">
                        <el-input
                          v-model.trim="coupon.valid_day"
                          placeholder="有效期"
                          maxlength="6"
                          clearable
                        />
                        <span class="marleft10">天</span>
                      </div>
                    </el-form-item>
                    <el-form-item
                      label="张数："
                      :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.coupon_quote_list.${couponIndex}.num`"
                      label-width="70px"
                      :rules="[
                        {
                          required: true,
                          message: '请输入张数',
                          trigger: ['change', 'blur'],
                        },
                      ]"
                    >
                      <el-input-number
                        v-model.number="coupon.num"
                        style="width: 90px"
                        :min="1"
                        :max="99"
                        controls-position="right"
                        @keyup.native="UpNumber($event, 2)"
                        @keydown.native="UpNumber($event, 2)"
                        label="描述文字"
                      ></el-input-number>
                    </el-form-item>
                    <div
                      class="flex"
                      style="margin-left: 10px; margin-bottom: 18px"
                    >
                      <img
                        class="icon"
                        @click="topUpArrHandleChange(4, index, valIndex)"
                        src="@/assets/img/icon-add.png"
                        alt=""
                      />
                      <img
                        v-if="val.coupon_quote_list.length > 1"
                        @click="
                          topUpArrHandleChange(5, index, valIndex, couponIndex)
                        "
                        class="icon"
                        src="@/assets/img/icon-delete.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 3 度数套餐活动 -->
        <div v-if="billing_rule_type === 3">
          <div
            v-for="(item, index) in dataForm.recharge_rule_list"
            :key="index"
          >
            <div class="flex">
              <el-form-item
                :label="`第 ${index + 1} 档充值`"
                :prop="`recharge_rule_list.${index}.recharge`"
                :rules="[
                  {
                    required: true,
                    message: '只能数字且最多6位，小数点最多2位',
                    pattern: /^\d{1,6}(\.\d{1,2})?$/,
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <div class="flex">
                  <el-input
                    v-model.trim="item.recharge"
                    placeholder="请输入金额"
                    clearable
                    maxlength="9"
                  />
                  <img
                    class="icon marleft10"
                    @click="topUpArrHandleChange(0)"
                    src="@/assets/img/icon-add.png"
                    alt=""
                  />
                  <img
                    v-if="dataForm.recharge_rule_list.length > 1"
                    @click="topUpArrHandleChange(1, index)"
                    class="icon marleft10"
                    src="@/assets/img/icon-delete.png"
                    alt=""
                  />
                </div>
              </el-form-item>
            </div>
            <div>
              <div
                v-for="(val, valIndex) in item.activity_subsidy_type_list"
                :key="valIndex"
              >
                <template v-if="valIndex === 0 && billingId != 15">
                  <div class="flex">
                    <el-form-item
                      label="优惠类型："
                      style="width: 100%"
                      :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.subsidy_type`"
                      :rules="[
                        {
                          required: true,
                          message: '请选择优惠类型',
                          trigger: ['change', 'blur'],
                        },
                      ]"
                    >
                      <el-select
                        v-model="val.subsidy_type"
                        placeholder="请选择优惠类型"
                        @change="subsidyChange($event, index)"
                        collapse-tags
                      >
                        <el-option label="充电度数" :value="2"> </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="flex">
                    <el-form-item
                      label="度数："
                      style="width: 100%"
                      :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.degree`"
                      :rules="[
                        {
                          required: true,
                          message: '请输入充值度数',
                          trigger: ['change', 'blur'],
                        },
                      ]"
                    >
                      <el-input
                        v-model.trim="val.degree"
                        placeholder="请输入充值度数"
                        clearable
                        maxlength="9"
                      />
                    </el-form-item>
                  </div>
                </template>
                <!-- 优惠类型 -->

                <div v-else-if="billingId == 11 || billingId == 15">
                  <el-form-item
                    :label="billingId == 15 ? '优惠类型：' : '搭赠类型：'"
                    style="width: 100%"
                    :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.subsidy_type`"
                  >
                    <el-select
                      style="width: 200px"
                      v-model="val.subsidy_type"
                      placeholder="请选择是否搭赠"
                    >
                      <el-option label="无" :value="0" v-if="billingId == 11">
                      </el-option>
                      <el-option label="车辆性能检测" :value="3"> </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    :label="billingId == 15 ? '数量' : '搭赠数量：'"
                    v-if="val.subsidy_type == 3"
                    style="width: 100%"
                    :rules="performanceNum"
                    :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.num`"
                  >
                    <el-input
                      style="width: 200px"
                      v-model.trim="val.num"
                      placeholder="请输入次数"
                      clearable
                      maxlength="9"
                      oninput="value=value.replace(/[^0-9]/g,'')"
                      ><span slot="suffix">次</span></el-input
                    >
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import agentApi from "../../../api/agent_api";
import axios from "../../../utils/axiosHelper";
import OPERATE_API from "../../../api/operate_api";
export default {
  // name: "AddOrUpdate",
  props: {
    editState: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      billing_rule_type: "",
      billingId: "",
      visible: false,
      formLoading: false,
      cityList: [],
      cityOptions: [],
      couponList: [],
      formItem: {},
      createTime: [],
      dataForm: {
        id: "",
        name: "",
        promotion_show_name: "",
        start_time: "",
        end_time: "",
        city_code: "",
        billing_type: "",
        total_amount: "",
        personCode: -1,
        frequencyCode: -1,
        person_limit: "",
        billing_mutex: 0,
        mutex_billing_type: "",
        every_one_recharge_limit_code: -1,
        every_one_recharge_limit: "",
        every_one_times_limit: "",
        reach_standard_meet_person_num: "",
        create_time: [],
        activity_subsidy_type_list: [],
        recharge_rule_list: [],
        jump_page: 0,
      },
      sceneList: [], // 场景
      numRules: [
        { required: true, message: "请输入次数", trigger: ["change", "blur"] },
        {
          validator: (rule, value, callback) => {
            if (value <= 0) {
              callback(new Error("请输入大于0的数字"));
            } else {
              callback();
            }
          },
          trigger: ["change", "blur"],
        },
      ],
      dataRule: {
        name: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: ["blur", "change"],
          },
        ],
        promotion_show_name: [
          {
            required: true,
            message: "请输入活动展示名称",
            trigger: ["blur", "change"],
          },
        ],
        create_time: [
          {
            required: true,
            message: "请选择活动时间",
            trigger: ["blur", "change"],
          },
        ],
        city_code: [
          {
            required: true,
            message: "请选择城市",
            trigger: ["blur", "change"],
          },
        ],
        discount_type: [
          {
            required: true,
            message: "请选择城市",
            trigger: ["blur", "change"],
          },
        ],
        billing_type: [
          {
            required: true,
            message: "请选择场景",
            trigger: ["blur", "change"],
          },
        ],
        total_amount: [
          {
            required: true,
            message: "只能数字且最多6位，小数点最多2位",
            pattern: /^\d{1,6}(\.\d{1,2})?$/,
            trigger: ["blur", "change"],
          },
        ],
        personCode: [
          {
            required: true,
            message: "请选择人数限制",
            trigger: ["blur", "change"],
          },
        ],
        frequencyCode: [
          {
            required: true,
            message: "请选择参与活动次数限制",
            trigger: ["blur", "change"],
          },
        ],
        person_limit: [
          {
            required: true,
            message: "请输入限制人数",
            trigger: ["blur", "change"],
          },
          {
            validator(rule, value, callback) {
              if (value <= 0) {
                return callback(new Error("请输入大于0的数字"));
              }
              callback();
            },
          },
        ],
        every_one_times_limit: [
          {
            required: true,
            message: "请输入参与活动次数限制",
            trigger: ["blur", "change"],
          },
          {
            validator(rule, value, callback) {
              if (value <= 0) {
                return callback(new Error("请输入大于0的数字"));
              }
              callback();
            },
          },
        ],
        reach_standard_meet_person_num: [
          {
            required: true,
            message: "请输入连续邀请人数",
            trigger: ["blur", "change"],
          },
          {
            validator(rule, value, callback) {
              if (value <= 0) {
                return callback(new Error("请输入大于0的数字"));
              }
              callback();
            },
          },
        ],
        billing_mutex: [
          {
            required: true,
            message: "请选择场景互斥限制",
            trigger: ["blur", "change"],
          },
        ],
        pay_mutex_limit: [
          {
            required: true,
            message: "请选择支付互斥限制",
            trigger: ["blur", "change"],
          },
        ],
        mutex_billing_type: [
          {
            required: true,
            message: "请选择互斥场景",
            trigger: ["blur", "change"],
          },
        ],
        every_one_recharge_limit_code: [
          {
            required: true,
            message: "轻选择充值类型",
            trigger: ["blur", "change"],
          },
        ],
        every_one_recharge_limit: [
          {
            required: true,
            message: "请输入金额",
            trigger: ["blur", "change"],
          },
        ],
      },
      performanceNum: [
        {
          required: true,
          message: `请输入${this.billingId == 15 ? "数量" : "搭赠数量"}`,
          trigger: ["change", "blur"],
        },
        {
          validator: (rule, value, callback) => {
            if (value == 0) {
              callback(
                new Error(
                  `${this.billingId == 15 ? "数量" : "搭赠数量"}不能为0`
                )
              );
            } else {
              callback();
            }
          },
          trigger: ["change", "blur"],
        },
      ],
    };
  },
  created() {},
  async mounted() {
    await this.getCityList();
    await this.getCouponList();
    this.hideLoading();
  },
  methods: {
    init(row) {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (row) {
          this.$nextTick(() => {
            this.formItem = row;
            this.dataForm.id = row.id;
            this.dataForm.name = row.name;
            this.dataForm.promotion_show_name = row.promotion_show_name;
            this.dataForm.jump_page = row.jump_page;
            if (this.editState == "edit") {
              this.dataForm.create_time = [row.start_time, row.end_time];
              this.dataForm.start_time = row.start_time;
              this.dataForm.end_time = row.end_time;
            }
            this.dataForm.city_name = row.city_name;
            this.dataForm.agent_id = row.agent_id;
            this.dataForm.city_code = row.city_code;
            this.dataForm.billing_type = row.billing_type;
            this.dataForm.reach_standard_meet_person_num =
              row.reach_standard_meet_person_num;
            this.billingId = row.billing_type;
            this.dataForm.total_amount = row.total_amount;
            this.dataForm.personCode = row.person_limit === -1 ? -1 : 1;
            this.dataForm.frequencyCode =
              row.every_one_times_limit === -1 ? -1 : 1;
            this.dataForm.person_limit =
              row.person_limit === -1 ? "" : row.person_limit;
            this.dataForm.every_one_times_limit =
              row.every_one_times_limit === -1 ? "" : row.every_one_times_limit;
            this.dataForm.billing_mutex = row.billing_mutex ? 1 : 0;
            this.dataForm.mutex_billing_type = row.mutex_billing_type;
            this.dataForm.every_one_recharge_limit_code =
              row.every_one_recharge_limit === -1 ? -1 : 1;
            this.dataForm.every_one_recharge_limit =
              row.every_one_recharge_limit === -1
                ? ""
                : row.every_one_recharge_limit;
            const activity_subsidy_type_list =
              row.activity_subsidy_type_list.map((item) => {
                item.pay_mutex_limit = item.pay_mutex_limit ? 1 : 0;

                return item;
              });
            this.dataForm.activity_subsidy_type_list =
              activity_subsidy_type_list;
            const recharge_rule_list = row.recharge_rule_list.map((item) => {
              if (this.billingId == 11) {
                console.log(item.activity_subsidy_type_list);
                if (item.activity_subsidy_type_list.length == 1) {
                  item.activity_subsidy_type_list.push({
                    subsidy_type: 0,
                    num: "",
                  });
                }
              }
              return item;
            });
            console.log(recharge_rule_list);
            this.dataForm.recharge_rule_list = recharge_rule_list;
            this.billing_rule_type = row.billing_rule_type;
          });
        } else {
          this.dataForm.id = "";
          this.dataForm.name = "";
          this.dataForm.jump_page = "0";
          this.dataForm.promotion_show_name = "";
          this.dataForm.create_time = [];
          this.dataForm.start_time = "";
          this.dataForm.end_time = "";
          this.dataForm.city_name = "";
          this.dataForm.city_code = "";
          this.dataForm.billing_type = "";
          this.dataForm.total_amount = "";
          this.dataForm.personCode = -1;
          this.dataForm.frequencyCode = -1;
          this.dataForm.reach_standard_meet_person_num = "";
          this.dataForm.every_one_times_limit = "";
          this.dataForm.person_limit = "";
          this.dataForm.billing_mutex = 0;
          this.dataForm.mutex_billing_type = "";
          this.dataForm.every_one_recharge_limit_code = -1;
          this.dataForm.every_one_recharge_limit = "";
          this.dataForm.activity_subsidy_type_list = [];
          this.dataForm.recharge_rule_list = [];
          this.billing_rule_type = "";
        }
      });
    },
    // 获取代理商城市
    getCityList() {
      this.axiosHelper
        .get("/api/partner/agentlist/get/list", { params: { status: 1 } })
        .then((res) => {
          this.hideLoading();
          this.cityList = res.data.data;
          res.data.data.map((item) => {
            this.cityOptions[item.city_code] = { ...item };
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    getCouponList() {
      this.axiosHelper
        .get("/api/coupon/all/list", { params: { status: 0 } })
        .then((res) => {
          this.couponList = res.data.data;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    // 城市选择Change
    cityChange(val) {
      this.dataForm.agent_id = this.cityOptions[val].id;
    },
    // 场景选择Change
    billingChange(e) {
      this.billingId = e;
      if (this.billingId === 0) {
        this.dataForm.jump_page = "1";
      } else {
        this.dataForm.jump_page = "0";
      }
      this.sceneList.map((item) => {
        if (e === item.id) {
          this.billing_rule_type = item.billing_rule_type;
          switch (item.billing_rule_type) {
            case 2: // 当场景选中值为2时  充值
              this.dataForm.activity_subsidy_type_list = [];
              this.dataForm.recharge_rule_list = [];
              // this.$nextTick(() => {
              this.dataForm.recharge_rule_list.push({
                recharge: "",
                activity_subsidy_type_list: [
                  {
                    subsidy_type: 1,
                    coupon_quote_list: [
                      {
                        coupon_id: "",
                        valid_day: "",
                        num: 1,
                      },
                    ],
                  },
                ],
              });
              // })
              break;
            case 1: // 当场景选中值为1
              this.dataForm.recharge_rule_list = [];
              this.dataForm.activity_subsidy_type_list = [];
              this.dataForm.activity_subsidy_type_list.push({
                subsidy_type: 1,
                pay_mutex_limit: 1,
                coupon_quote_list: [
                  {
                    coupon_id: "",
                    valid_day: "",
                    num: 1,
                  },
                ],
              });
              console.log(this.dataForm, "dataForm");
              break;
            case 3: // 当场景选中值为3时
              this.dataForm.recharge_rule_list = [];
              this.dataForm.activity_subsidy_type_list = [];
              if (this.billingId == 11) {
                this.dataForm.recharge_rule_list.push({
                  recharge: "",
                  activity_subsidy_type_list: [
                    {
                      subsidy_type: 2,
                      degree: "",
                    },
                    {
                      subsidy_type: 0,
                      num: "",
                    },
                  ],
                });
              } else if (this.billingId == 15) {
                this.dataForm.recharge_rule_list.push({
                  recharge: "",
                  activity_subsidy_type_list: [
                    {
                      subsidy_type: 3,
                      num: "",
                    },
                  ],
                });
              } else {
                this.dataForm.recharge_rule_list.push({
                  recharge: "",
                  activity_subsidy_type_list: [
                    {
                      subsidy_type: 2,
                      degree: "",
                    },
                  ],
                });
              }
              break;
          }
        }
      });
    },
    timeChange(val) {
      if (val.length) {
        this.dataForm.start_time = val[0];
        this.dataForm.end_time = val[1];
      }
    },
    // 补贴类型选择Change
    subsidyChange(e, index) {
      if (this.billingId === 0) {
        switch (e) {
          case 1:
            // this.dataForm.activity_subsidy_type_list = []
            this.dataForm.activity_subsidy_type_list = [
              {
                subsidy_type: 1,
                pay_mutex_limit: 1,
                coupon_quote_list: [
                  {
                    coupon_id: "",
                    valid_day: "",
                    num: 1,
                  },
                ],
              },
            ];
            break;
          case 2:
            // this.dataForm.activity_subsidy_type_list = []
            this.dataForm.activity_subsidy_type_list = [
              {
                subsidy_type: e,
                pay_mutex_limit: 1,
                degree: "",
                coupon_quote_list: [],
              },
            ];
            console.log(this.dataForm, "aaa");
            break;
          case 3:
            // this.dataForm.activity_subsidy_type_list = []
            this.dataForm.activity_subsidy_type_list = [
              {
                subsidy_type: e,
                pay_mutex_limit: 1,
                num: 1,
                coupon_quote_list: [],
              },
            ];
            console.log(this.dataForm, "aaa");
            break;
        }
      }
    },
    // 优惠券类型及券的增加与删除
    formArrHandleChange(type, index, valIndex) {
      // 0、1优惠券类型增加与删除
      // 2、3券增加与删除
      switch (type) {
        case 0:
          this.dataForm.activity_subsidy_type_list.push({
            subsidy_type: 1,
            pay_mutex_limit: 1,
            coupon_quote_list: [
              {
                coupon_id: "",
                valid_day: "",
                num: 1,
              },
            ],
          });
          break;
        case 1:
          this.dataForm.activity_subsidy_type_list.splice(index, 1);
          break;
        case 2:
          this.dataForm.activity_subsidy_type_list[
            index
          ].coupon_quote_list.push({
            coupon_id: "",
            valid_day: "",
            num: 1,
          });
          break;
        case 3:
          this.dataForm.activity_subsidy_type_list[
            index
          ].coupon_quote_list.splice(valIndex, 1);
          break;
      }
    },
    // 充值档数/优惠券类型/券的增加与删除
    topUpArrHandleChange(type, index, valIndex, couponIndex) {
      // 0、1充值档数增加与删除
      // 2、3优惠券类型增加与删除
      // 4、5券增加与删除
      switch (type) {
        case 0:
          // 场景类型不为3的情况下、2是充值活动
          if (this.billing_rule_type === 2) {
            this.dataForm.recharge_rule_list.push({
              recharge: "",
              activity_subsidy_type_list: [
                {
                  subsidy_type: 1,
                  coupon_quote_list: [
                    {
                      coupon_id: "",
                      valid_day: "",
                      num: 1,
                    },
                  ],
                },
              ],
            });
          } else {
            // 场景类型为3的情况下、3是度数套餐活动
            if (this.billingId == 11) {
              this.dataForm.recharge_rule_list.push({
                recharge: "",
                activity_subsidy_type_list: [
                  {
                    subsidy_type: 2,
                    degree: "",
                  },
                  {
                    subsidy_type: 0,
                    num: "",
                  },
                ],
              });
            } else if (this.billingId == 15) {
              this.dataForm.recharge_rule_list.push({
                recharge: "",
                activity_subsidy_type_list: [
                  {
                    subsidy_type: 3,
                    num: "",
                  },
                ],
              });
            } else {
              this.dataForm.recharge_rule_list.push({
                recharge: "",
                activity_subsidy_type_list: [
                  {
                    subsidy_type: 2,
                    degree: "",
                  },
                ],
              });
            }
          }
          break;
        case 1:
          this.dataForm.recharge_rule_list.splice(index, 1);
          break;
        case 2:
          this.dataForm.recharge_rule_list[
            index
          ].activity_subsidy_type_list.push({
            subsidy_type: 1,
            pay_mutex_limit: 1,
            coupon_quote_list: [
              {
                coupon_id: "",
                valid_day: "",
                num: 1,
              },
            ],
          });
          break;
        case 3:
          this.dataForm.recharge_rule_list[
            index
          ].activity_subsidy_type_list.splice(valIndex, 1);
          break;
        case 4:
          this.dataForm.recharge_rule_list[index].activity_subsidy_type_list[
            valIndex
          ].coupon_quote_list.push({
            coupon_id: "",
            valid_day: "",
            num: 1,
          });
          break;
        case 5:
          this.dataForm.recharge_rule_list[index].activity_subsidy_type_list[
            valIndex
          ].coupon_quote_list.splice(couponIndex, 1);
          break;
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid, fields) => {
        console.log(valid);
        if (!valid) {
          console.log(fields[Object.keys(fields)[0]][0].message);
          this.$message.error(fields[Object.keys(fields)[0]][0].message);
          this.$nextTick(() => {
            const el =
              this.$refs.dataForm.$el.querySelector(`.el-form-item__error`);
            console.log(el);
            if (el) {
              // 滚动到该字段的位置
              el.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          });
          return;
        }
        this.showLoading("加载中");
        this.getCityList();
        let dataForm = JSON.parse(JSON.stringify(this.dataForm));
        if (dataForm.personCode === -1) dataForm.person_limit = -1;
        if (dataForm.frequencyCode === -1) dataForm.every_one_times_limit = -1;
        if (dataForm.every_one_recharge_limit_code === -1)
          dataForm.every_one_recharge_limit = -1;
        if (dataForm.billing_type == 11) {
          dataForm.recharge_rule_list.forEach((item) => {
            item.activity_subsidy_type_list =
              item.activity_subsidy_type_list.filter(
                (it) => it.subsidy_type !== 0
              );
          });
        }
        //   新增
        if (this.editState == "add" || this.editState == "copy") {
          axios
            .post("/api/activity/add", dataForm)
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg);
                return;
              }
              this.visible = false;
              this.$emit("refreshDataList");
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            });
        } else if (this.editState == "edit") {
          // 编辑
          axios
            .put(`/api/activity/update/${dataForm.id}`, dataForm)
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg);
                return;
              }
              this.visible = false;
              this.$emit("refreshDataList");
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            });
        }
      });
    },
    // 只能输入汉字、英文、数字
    UpNumber(e, num) {
      e.target.value = e.target.value.replace(/[^\d]/g, "").slice(0, num);
    },
  },
};
</script>
<style lang="scss" scoped>
.form_list {
  &-title {
    &::after {
      content: " ";
      display: inline-block;
      width: 100%;
      height: 1px;
      background-color: rgb(222, 222, 222);
      margin: 10px 0;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 2px;
      height: 15px;
      background-color: #f6a83a !important;
      margin-right: 6px;
    }
  }
}

.showAll_dialog {
  overflow: hidden;

  ::v-deep .el-dialog {
    height: 70%;
    overflow: hidden;

    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
      line-height: 30px;
      padding: 0 15px 60px;
    }

    .el-dialog__footer {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: right;
      background-color: #ffffff;
      padding: 0 20px 0;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}

.marleft10 {
  margin-left: 10px;
}

.icon {
  width: 24px;
  height: 24px;
  &:first-child {
    margin-right: 4px;
  }
}
</style>