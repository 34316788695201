<template>
    <el-dialog title="活动页" :close-on-click-modal="false" :visible.sync="visible" width="20%" center>
        <div style="text-align: center;">
            <el-image style="width: 200px; height: 200px;align-content: center" :src="imgUrl"></el-image>
        </div>
        <span style="display: block; text-align: center;">{{ city_name }}</span>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="downloadPic()">下载</el-button>
            <el-button @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {},
    data() {
        return {
            visible: false,
            imgUrl: "",
            city_name: "",
            dataItem: {
                pic_url: [],
                show_time: "",
                city_name: ""
            }
        };
    },
    created() {},
    mounted() {},
    methods: {
        init(row, index, fileRoot) {
            this.visible = true;
            this.dataItem = row;
            this.fileList = [];
            this.showTime = [];
            if (this.dataItem) {
                this.imgUrl = fileRoot + this.dataItem.pic_url[index];
                this.city_name = this.dataItem.city_name;
            }
        },
        downloadPic() {
            if (this.imgUrl) {
                this.downloadIamge(
                    this.imgUrl,
                    this.dataItem.city_name + "-活动页"
                );
            } else {
                this.$message.warning("下载失败");
            }
        },
        downloadIamge(imgsrc, name) {
            //下载图片地址和图片名
            let image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
                let canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                let context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                let url = "";
                if (imgsrc.toLowerCase().endsWith(".png")) {
                    name = name + ".png";
                    url = canvas.toDataURL("image/png"); // 将图片转换为 PNG 格式的 base64 编码数据
                } else if (imgsrc.toLowerCase().endsWith(".jpg")) {
                    name = name + ".jpg";
                    url = canvas.toDataURL("image/jpeg"); // 将图片转换为 JPG 格式的 base64 编码数据
                } else if (imgsrc.toLowerCase().endsWith(".jpeg")) {
                    name = name + ".jpeg";
                    url = canvas.toDataURL("image/jpeg"); // 将图片转换为 JPG 格式的 base64 编码数据
                } else {
                    console.error("Unsupported image format");
                    return;
                }
                let a = document.createElement("a"); // 生成一个a元素
                let event = new MouseEvent("click"); // 创建一个单击事件
                a.download = name || "photo"; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = imgsrc;
        }
    }
};
</script>
