
  <div class="height:100%;">
    <el-card class="box-card">
        <el-row>
            <el-col :span="4">
                <div class="block-view-head">
                    <span class="block-view-title" >订单统计</span>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="48" style="margin-top:11px;">
            <el-col :span="6">
                <div class="view-block left-line-blue" style="border:1px solid rgba(200,200,200,0.5);border-radius: 0;">
                  <span class="outImag1">
                    <i class="el-icon-document" style="margin: 10px;"/>
                  </span>
                    <span class="view-title" style="margin-left: 40px">订单</span>
                    <span style="float:right;margin-right: 20px">本月</span>
                    <p class="view-count" style="text-align: center;">{{growthData.currentMonthOrderCount}}</p>
                    <p class="view-add" style="text-align: center;">{{growthData.orderCountYoYGrowthRate}} 同比增长</p>
<!--                    <img class="view-icon" src="../../assets/img/project_icon_a.png" alt="">-->
                </div>
            </el-col>
            <el-col :span="6">
                <div class="view-block left-line-orange" style="border:1px solid rgba(200,200,200,0.5);border-radius: 0;">
                  <span class="outImag2">
                    <i class="el-icon-money" style="margin: 10px;"/>
                  </span>
                    <span class="view-title" style="margin-left: 40px">订单金额</span>
                    <span style="float:right;margin-right: 20px">本月</span>
                    <p class="view-count" style="text-align: center;">{{growthData.currentMonthOrderPrice}}</p>
                  <p class="view-add" style="text-align: center;">{{growthData.orderPriceYoYGrowthRate}} 同比增长</p>
                  <!--<img class="view-icon" src="../../assets/img/project_icon_b.png" alt="">-->
                </div>
            </el-col>
        </el-row>
    </el-card>
    <el-card class="box-card">
      <el-row :gutter="24" :style="{height:(pageHeight - 110) * 0.34+'px','box-sizing':'border-box','padding-top':'16px','margin-left': '0px','margin-right': '0px'}">
        <commonLineStatistic yAxisDataMinInterval="1000" interFace="/api/order/analysis/getLineChart" lineChartName="订单统计" lineChartId="order-line-chart" :xAxisData="orderXAxisData"/>
      </el-row>
    </el-card>
    <el-card class="box-card">
      <el-row :style="{height:(pageHeight - 110) * 0.34+'px','box-sizing':'border-box','padding-top':'16px'}">
        <commonLineStatistic :dateSpans="dateSpans" yAxisDataMinInterval="20"  interFace="/api/order/analysis/getWeekLineChart" lineChartName="平均客单价统计" lineChartId="week-day-line-chart" :xAxisData="weekDayXAxisData"/>
      </el-row>
    </el-card>
  </div>
