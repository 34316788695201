<template>
  <el-dialog title="用户退款" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="140px" @submit.native.prevent>
      <el-form-item label="手机号码：" prop="cellphone">
        {{ this.formItem.cellphone}}
      </el-form-item>
      <el-form-item label="申请信息：" prop="apply_content">
        {{ this.formItem.apply_content}}
      </el-form-item>
      <el-form-item label="用户账户余额：" prop="apply_content">
        {{ this.totalAmount}}
      </el-form-item>
      <el-form-item label="退款金额：" prop="refund_amount">
        <el-input v-model="dataForm.refund_amount" placeholder="请输入" show-word-limit style="width: 30%;"
            oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 999999.99 ? 999999.99 : value" size="mini" ></el-input>

      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import agentApi from "../../../api/agent_api";
import axios from "../../../utils/axiosHelper";
export default {
  // name: "AddOrUpdate",
  props: {

  },
  data() {
    return {
      visible: false,
      totalAmount:0,
      formItem:{},
      dataForm: {
        id: '',
        refund_amount: '',
      },
      dataRule: {
        refund_amount: [
          { required: true, message: '请输入退款金额', trigger: 'blur' },
          //{ required: true, validator: checkRefundAmount, trigger: 'blur' },
        ],
      },

    };
    /*var checkRefundAmount = (rule, value, callback) => {
      alert(value)
      if (value === '') {
        callback(new Error('请输入退款金额'));
      } else if (this.totalAmount < value){
        callback(new Error('退款金额不可大于账户余额'));
      } else {
        callback();
      }
    };*/
  },
  methods: {
    init(row) {
      this.visible = true
      this.totalAmount=0
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (row) {
          console.log(row)
          this.formItem = row
          this.dataForm.id = row.id
          this.getUserTotalAmount(row.user_id);
        } else {
          this.dataForm.id = ""
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(async valid => {
        if (!valid) return

        if (Number(this.totalAmount) < Number(this.dataForm.refund_amount)){
          this.$message.error('退款金额不可大于账户余额');
          return
        }

        this.showLoading("加载中");
        axios.put(`/api/user/refund/apply/agree`,this.dataForm)
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg)
                return
              }
              this.visible = false
              this.$emit('refreshDataList')
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              })
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            })

      })
    },
    getUserTotalAmount(userId){
      this.axiosHelper
          .get(`/api/userAccount/getByUserId/${userId}`, null)
          .then((response) => {
            this.totalAmount = response.data.total_amount
          })
          .catch(() => {
            this.totalAmount = 0
          })
    },


  }
}
</script>
