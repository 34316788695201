import axios from '../utils/axiosHelper.js'
import OBJECT_UTIL from "../assets/js/objectUtil";
const EQUIPMENT_API = {};
EQUIPMENT_API.getByCondition = (params) => {
    return axios.get('/api/equipment/', {params: OBJECT_UTIL.ObjectKeyConvert(params)})
}
EQUIPMENT_API.queryAgentList = (params) => {
    return axios.get('/api/partner/agentlist/queryAgentList')
}
EQUIPMENT_API.update = (params) => {
    return axios.put(`/api/equipment`, params)
}
EQUIPMENT_API.add = (params) => {
    return axios.post(`/api/equipment`, params)
}
EQUIPMENT_API.getById = (id) => {
    return axios.get(`/api/equipment/${id}`)
}
EQUIPMENT_API.deleteByIds = (ids) => {
    return axios.delete(`/api/equipment/${ids}`)
}
EQUIPMENT_API.checkNo = (params) => {
    return axios.get(`/api/equipment/check`, {params: params})
}
EQUIPMENT_API.exportData = (params) => {
    return axios.get('/api/equipment/export_data', {params: OBJECT_UTIL.ObjectKeyConvert(params)})
}
EQUIPMENT_API.getPhysicalStates = (params) => {
    return axios.get(`/api/equipment/getPhysicalStates`)
}
export default EQUIPMENT_API;