
<div class="tags">
  <ul>
    <li
      class="tags-li"
      v-for="(item, index) in tagsList"
      :class="{ active: isActive(item) }"
      :key="index"
    >
      <router-link
        :to="{ name: item.name, params: item.params, query: item.query }"
        class="tags-li-title"
        >{{ item.title }}</router-link
      >

      <span
        v-if="!item.path.includes(firstPath)"
        class="tags-li-icon"
        @click="closeTags(item)"
      >
        <i class="el-icon-close"></i>
      </span>
    </li>
  </ul>
  <div class="tags-close-box">
    <el-dropdown @command="handleTags">
      <el-button
        size="mini"
        type="primary"
        style="
          margin-top: 10px;
          padding: 7px 8px;
          font-size: 14px;
          background: #5a8bff;
        "
      >
        标签选项
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu size="small" slot="dropdown">
        <el-dropdown-item command="refresh">刷新当前</el-dropdown-item>
        <el-dropdown-item command="other">关闭其他</el-dropdown-item>
        <el-dropdown-item command="all">关闭所有</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</div>
