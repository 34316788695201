<template>
<el-dialog
    :visible.sync="model_dialog"
    v-if="model_dialog"
    title="高级搜索"
    append-to-body
    :close-on-click-modal=false
    @close="model_dialog = false">
    <el-form ref="form" style="padding-right: 10px" :model="form" label-width="110px">
        <el-row>
            <el-col :span="12">
                <el-form-item label="设备编号：">
                    <el-input v-model.trim="form.equipment_no" clearable placeholder="请输入设备编号" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="设备名称：">
                    <el-input v-model.trim="form.equipment_name" clearable placeholder="请输入设备名称" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="设备类型：">
                    <el-select v-model="form.equipment_type" clearable style="width: 100%">
                        <el-option  v-for="item in dim_equipment_type"
                                    :key="item.data_value"
                                    :value="item.data_value"
                                    :label="item.data_name"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="设备状态：">
                    <el-select v-model="form.equipment_state" clearable style="width: 100%">
                        <el-option  v-for="item in dim_equipment_state"
                                    :key="item.data_value"
                                    :value="item.data_value"
                                    :label="item.data_name"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
       <el-row>
           <el-col :span="12">
               <el-form-item label="设备网点：">
                   <el-input v-model.trim="form.equipment_org" clearable placeholder="请输入设备网点" />
               </el-form-item>
           </el-col>
       </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="clear">清除条件</el-button>
        <el-button type="default" @click="model_dialog = false">取消</el-button>
        <el-button type="primary" @click="click">确定</el-button>
    </div>
</el-dialog>
</template>

<script>
import DIM_API from "../../../api/dim_api.js";
export default {
    name: "search_model",
    props: {
        dialog: {
            type: Boolean,
            default() {
                return false;
            }
        },
    },
    methods: {
        clear() {
            Object.assign(this.$data.form, this.$options.data().form)
        },
        click() {
            this.$emit('search', JSON.parse(JSON.stringify(this.form)));
            this.model_dialog = false;
        },
        async initDim() {
            let res = await DIM_API.getByTypes(['equipment_type', 'equipment_state'].join(","))
            let arr = res.data;
            this.dim_equipment_state = arr.filter(item => item.data_type === 'equipment_state')
            this.dim_equipment_type = arr.filter(item => item.data_type === 'equipment_type')
        }
    },
    data() {
        return {
            model_dialog: false,
            form: {
                equipment_no: '',
                equipment_name: '',
                equipment_type: '',
                equipment_state: '',
                equipment_org: ''
            },
            dim_equipment_state: [],
            dim_equipment_type: []
        }
    },
    watch: {
        dialog: {
            deep: true,
            immediate: true,
            handler(value) {
                this.model_dialog = value;
            }
        },
        model_dialog: {
            deep: true,
            immediate: true,
            handler(value) {
                this.$emit('update:close', value);
                if (value) {
                    // this.clear();
                    this.initDim();
                }
            }
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>