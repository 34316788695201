
<el-dialog
  title="高级搜索"
  v-dialogDrag
  :visible.sync="dialogVisible"
  :close-on-click-modal="false"
  append-to-body
>
  <el-form :model="dataForm" ref="dataForm" label-width="130px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="订单编号：">
          <el-input v-model.trim="dataForm.orderNo" clearable />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="订单类型：">
          <el-select
            v-model="dataForm.orderType"
            clearable
            multiple
            collapse-tags
            style="width: 100%"
          >
            <el-option
              v-for="item in orderTypeDim"
              :key="item.data_value"
              :value="item.data_value"
              :label="item.data_name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="订单状态：">
          <el-select
            v-model="dataForm.orderStatusList"
            multiple
            collapse-tags
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in orderStatusDim"
              :key="item.data_value"
              :value="item.data_value"
              :label="item.data_name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="配送人员：">
          <!-- <user-selector
            v-model="dataForm.deliveryUserId"
            :userName.sync="dataForm.deliveryUserName"
            :showBtn="false"
            :firstCityCode="firstCityCode"
          >
          </user-selector> -->
          <el-select
            v-model="dataForm.deliveryUserId"
            filterable
            remote
            reserve-keyword
            placeholder="输入姓名/拼音/账号搜索"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in deliveryList"
              :key="item.uid"
              :label="item.real_name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="预约时间：">
          <el-date-picker
            style="width: 100%"
            v-model="reserveTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="支付时间：">
          <el-date-picker
            style="width: 100%"
            v-model="payTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="支付状态：">
          <el-select v-model="dataForm.payFlag" clearable style="width: 100%">
            <el-option
              v-for="item in payStatus"
              :key="item.value"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="设备回收状态：">
          <el-select
            v-model="dataForm.recoverFlag"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in recoverStatus"
              :key="item.value"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="用户手机号：">
          <el-input v-model.trim="dataForm.userCellphone" clearable />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="结算状态：">
          <el-select
            v-model="dataForm.agentSettleStatus"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in agentSettleStatus"
              :key="item.value"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="运维工单：">
          <el-select
            v-model="dataForm.hasMaintenanceOrder"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in operationOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="车牌号：">
          <el-input
            placeholder="请输入车牌号"
            v-model.trim="dataForm.carNo"
            clearable
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="实际充电度数：">
          <div class="flex">
            <el-input
              placeholder="请输入起始度数"
              v-model.trim="dataForm.realChargeMin"
              style="width: 200px"
              oninput="value=value.replace(/[^0-9]/g,'')"
              maxlength="4"
              clearable
            />
            <span>-</span>
            <el-input
              placeholder="请输入结束度数"
              style="width: 200px"
              v-model.trim="dataForm.realChargeMax"
              oninput="value=value.replace(/[^0-9]/g,'')"
              maxlength="4"
              clearable
            />
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否已达起充：">
          <el-select
            v-model="dataForm.isAchieveStartCharging"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in operationOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="服务时间：">
          <el-date-picker
            style="width: 100%"
            v-model="serviceTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="推荐人类型：">
          <el-select
            v-model="dataForm.recommendType"
            clearable
            placeholder="请选择推荐人类型"
            style="width: 100%"
          >
            <el-option
              v-for="item in recommend_type_list"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="推荐人：">
          <el-input
            placeholder="请输入推荐人"
            v-model.trim="dataForm.referenceName"
            clearable
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="text" @click="clear">清除条件</el-button>
    <el-button type="default" @click="cancel">取消</el-button>
    <el-button type="primary" @click="confirm">确定</el-button>
  </div>
</el-dialog>
