<template>
  <div>
    <el-card class="iconShow">
      <el-row :gutter="24">
        <el-col :span="8">
          <div
            class="view-block left-line-blue"
            style="border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 0"
          >
            <div class="outImag1">
              <img class="imgCss" src="../../assets/img/subsidy-total.png" />
            </div>
            <div class="textCss">
              <p class="view-count" style="text-align: center">
                {{ statistics.totalAmount }}
              </p>
              <p class="view-add" style="text-align: center">全国补贴池总额</p>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div
            class="view-block left-line-blue"
            style="border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 0"
          >
            <div class="outImag1">
              <img
                class="imgCss"
                src="../../assets/img/subsidy-remaining.png"
              />
            </div>
            <div class="textCss">
              <!-- <span class="view-title" style="margin-left: 40px">80033</span> -->
              <p class="view-count" style="text-align: center">
                {{ statistics.remainingAmount }}
              </p>
              <p class="view-add" style="text-align: center">全国补贴池余额</p>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div
            class="view-block left-line-blue"
            style="border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 0"
          >
            <div class="outImag1">
              <img class="imgCss" src="../../assets/img/subsidy-used.png" />
            </div>
            <div class="textCss">
              <!-- <span class="view-title" style="margin-left: 40px">80033</span> -->
              <p class="view-count" style="text-align: center">
                {{ statistics.usedAmount }}
              </p>
              <p class="view-add" style="text-align: center">
                全国本月已用金额
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="search">
      <el-form :model="dataForm">
        <el-row :gutter="24">
          <el-col :span="4">
            <el-form-item label="城市" label-width="70px">
              <el-select
                v-model="dataForm.areaCode"
                placeholder="请选择城市"
                clearable
                filterable
              >
                <el-option
                  v-for="item in restrictedCityList"
                  :key="item.cityCode"
                  :label="item.cityName"
                  :value="item.cityCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="代理商名称" label-width="150px">
              <el-input v-model="dataForm.agentName" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="代理商联系方式" label-width="160px">
              <el-input v-model="dataForm.callPhone" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="开城日期" label-width="120px">
              <el-date-picker
                v-model="dataForm.openingDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-button type="primary" @click="searchBtn()">查询</el-button>
              <el-button @click="clear()">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card>
      <div class="tableHeaderCss">
        <div>城市补贴列表</div>
        <div>
          <el-button type="primary" @click="addOrUpdateHandle" v-if="isShowBtn"
            >新增</el-button
          >
          <el-button type="primary" @click="exportExcel">导出</el-button>
        </div>
      </div>
      <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%"
        max-height="370"
      >
        <el-table-column
          prop="fullname"
          header-align="center"
          align="center"
          min-width="100"
          label="城市"
        >
        </el-table-column>
        <el-table-column
          prop="agent_name"
          header-align="center"
          align="center"
          min-width="150"
          label="代理商名称"
        >
        </el-table-column>
        <el-table-column
          prop="call_phone"
          header-align="center"
          align="center"
          min-width="120"
          label="代理商联系方式"
        >
        </el-table-column>
        <el-table-column
          prop="opening_date"
          header-align="center"
          align="center"
          min-width="120"
          label="开城日期"
          :formatter="formatOpeningDate"
        >
        </el-table-column>
        <el-table-column
          prop="total_amount"
          header-align="center"
          align="center"
          min-width="150"
          label="合计补贴总额(元)"
        >
        </el-table-column>
        <el-table-column
          prop="used_amount"
          header-align="center"
          align="center"
          min-width="150"
          label="已使用额度(元)"
        >
        </el-table-column>
        <el-table-column
          prop="remaining_amount"
          header-align="center"
          align="center"
          min-width="150"
          label="剩余补贴额度(元)"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="toCitySubsidy(scope.row)"
              >补贴详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </el-card>
    <!-- 弹窗, 新增 / 修改 -->
    <subsidy_add_or_update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></subsidy_add_or_update>
  </div>
</template>

<script>
import subsidy_add_or_update from "./model/subsidy_add_or_update.vue";
import AREACORP_API from "../../api/areacorp_api";
import OPERATE_API from "../../api/operate_api.js";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api.js";
export default {
  // name: "subsidy",
  data() {
    return {
      isShowBtn: false,
      statistics: {
        totalAmount: "",
        remainingAmount: "",
        usedAmount: "",
      },
      dataForm: {
        areaCode: "",
        agentName: "",
        // agent_name: "",
        callPhone: "",
        openingDate: "",
      },
      cityList: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    subsidy_add_or_update,
  },

  async created() {
    if (this.$store.state.user.isMaintenanceOrder == 1) {
      this.isShowBtn = true;
    }
  },
  mounted() {
    this.getDataList();
    this.subsidyStatistics();
  },

  methods: {
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["城市"] = item.fullname;
        newObj["代理商名称"] = item.agent_name;
        newObj["代理商联系方式"] = item.call_phone;
        newObj["开城日期"] = dayjs(item.opening_date).format("YYYY-MM-DD");
        newObj["合计补贴总额"] = item.total_amount;
        newObj["已使用额度(元)"] = item.used_amount;
        newObj["剩余补贴额度(元)"] = item.remaining_amount;
        newArray.push(newObj);
      });
      return newArray;
    },

    xlsxToBlob(workbook) {
      const wopts = { bookType: "xlsx", bookSST: false, type: "blob" };
      const blob = XLSX.write(workbook, wopts);
      return blob;
    },
    //导出
    async exportExcel() {
      await operateAdd({
        operate_type: "1",
        operate_number: 31,
        operate_parameter: JSON.stringify({
          reqdata: this.dataForm,
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.totalPage > 20000) {
        await this.$confirm(
          `您本次需要导出${this.totalPage}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let reqData = {
        reqdata: this.dataForm,
      };

      let res = await AREACORP_API.getByCondition(reqData);
      if (res.data.code !== 0) {
        this.$message.error({
          message: res.data.msg,
        });
        return;
      }
      let newData = this.getHeadersAndbody(res.data.page.items);
      const ws = XLSX.utils.json_to_sheet(newData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const fileName = "城市补贴池数据.xlsx";
      XLSX.writeFile(wb, fileName);
    },

    //城市补贴池详情
    toCitySubsidy(row) {
      this.$store.state.routerCache.subsidy_detail = row;
      this.$router.push({
        name: "subsidy_detail",
        params: { inByRouter: true },
      });
    },
    async subsidyStatistics(city_code) {
      if (!city_code) {
        city_code = "";
      }
      let res = await AREACORP_API.subsidyStatistics({ cityCode: city_code });
      if (res.data.code != 0) {
        this.$message.error({
          message: res.data.msg,
        });
        return;
      }
      this.statistics = { ...res.data.statistics };
    },

    formatOpeningDate(row, column, cellValue) {
      if (cellValue) {
        return dayjs(cellValue).format("YYYY-MM-DD");
      }
      // debugger
      // 使用日期格式化库，如 day.js 或 moment.js，将 cellValue 格式化为需要的日期格式
      // 这里假设使用 day.js，需要在项目中安装 day.js
    },
    //重置
    clear() {
      this.dataForm = {
        areaCode: "",
        agentName: "",
        cellphone: "",
        openDate: "",
      };
      this.pageIndex = 1;
      this.getDataList();
      this.subsidyStatistics();
    },
    searchBtn() {
      this.pageIndex = 1;
      this.getDataList();
      this.subsidyStatistics();
    },
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      let reqData = {
        offset: this.pageIndex - 1,
        limit: this.pageSize,
        reqdata: this.dataForm,
      };

      let res = await AREACORP_API.getByCondition(reqData).finally((_) => {
        this.dataListLoading = false;
      });

      if (res.data.code != 0) {
        this.$message.error({
          message: res.data.msg,
        });
        return;
      }
      this.dataList = res.data.page.items;
      this.totalPage = res.data.page.total_count;
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },

    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    // async deleteHandle(id) {
    //   const confirmResult = await this.$confirm("确定删除该记录?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).catch((err) => {
    //     return err;
    //   });
    //   if (confirmResult != "confirm") {
    //     return;
    //   }
    //   let res = await agentApi.deleteByIds(id)
    //   if(res.data.code!=0){
    //     this.$message.error(res.data.msg)
    //     return
    //   }
    //   this.$message({
    //     message: '操作成功',
    //     type: 'success',
    //     duration: 500,
    //     onClose: () => {
    //       this.getDataList()
    //     }
    //   })
    // }
  },
  computed: {
    ...mapState(["restrictedCityList"]),
    tableHeight() {
      return this.$store.state.viewportHeight;
    },
  },
};
</script>
<style scoped lang="scss">
.imgCss {
  height: 55px;
  line-height: 60px;
  font-size: 60px;
  // width:3.0em;
  // height: 3.0em;
}
.textCss {
  margin-left: 10px;
}
// .iconShow{
.el-form {
  height: 32px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  .el-form-item {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    margin-right: 8px;
    ::v-deep .el-form-item__content {
      margin-left: 0px !important;
    }
  }
}
// }
.el-pagination {
  margin-top: 8px;
}
::v-deep .el-card {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  padding: 10px !important;
  border-radius: 8px;
  .el-card__body,
  .el-main {
    padding: 5px;
  }
}
.iconShow {
  margin-top: 10px !important;
  .view-block {
    // position: relative;
    padding: 15px 20px;
    font-size: 14px;
    overflow: hidden;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*color: #fff;*/
  }
  .view-block .view-title {
    // padding-bottom: 10px;
  }
  .view-block .view-count {
    height: 34px;
    line-height: 32px;
    font-size: 34px;
  }
  .view-block .view-add {
    height: 34px;
    line-height: 32px;
    font-size: 16px;
    color: #999999;
  }
  .view-block .view-icon {
    position: absolute;
    top: 32px;
    right: 30px;
  }
  .left-line-blue ::v-deep .outImag1 {
    // position: absolute;
    // left: 50px;
    // top: 20px;
    // width: 40px;
    // height: 40px;
    background: inherit;
    // background-color: rgba(82, 193, 245, 1);
    border: 0 none;
    border-radius: 40px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    // font-family: 'FontAwesome', sans-serif;
    // font-weight: 400;
    font-style: normal;
    // font-size: 20px;
    color: #ffffff;
    // line-height: 20px;
  }
  .left-line-orange ::v-deep .outImag2 {
    position: absolute;
    left: 20px;
    top: 10px;
    width: 40px;
    height: 40px;
    background: inherit;
    background-color: rgba(75, 206, 208, 1);
    border: 0px none;
    border-radius: 40px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: "FontAwesome", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: #ffffff;
    line-height: 20px;
  }
}
.tableHeaderCss {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center; /* 垂直居中 */
}
</style>
