<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <!--            <div slot="header" class="clearfix">
                <span>数据筛选</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="filter">高级查询</el-button>
            </div>-->
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          label-width="110px"
        >
          <el-row>
            <el-col :span="5">
              <el-form-item label="用户手机号：" style="margin-bottom: 0">
                <el-input
                  v-model.trim="searchValue.cellphone"
                  placeholder="请输入"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="用户编号：" style="margin-bottom: 0">
                <el-input
                  v-model.trim="searchValue.userNumber"
                  placeholder="请输入"
                  clearable
                  maxlength="10"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="所属城市：" style="margin-bottom: 0">
                <el-select
                  v-model="searchValue.first_city_code"
                  placeholder="全部"
                  multiple
                  collapse-tags
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in restrictedCityList"
                    :key="item.cityCode"
                    :label="item.cityName"
                    :value="item.cityCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3" style="float: right; text-align: right">
              <el-button type="primary" @click="clickSearch">查询</el-button>
              <el-button type="default" @click="clear">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>用户钱包</span>
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
        @selection-change="rowSelected"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="手机号码"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="first_city_name"
          label="城市"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="total_amount"
          label="账户余额（元）"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="recharge_total_amount"
          label="充值合计（元）"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pay_total_amount"
          label="支付合计（元）"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="order_total_number"
          label="累计下单数"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="last_recharge_time"
          label="最后充值时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="last_pay_time"
          label="最后支付时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              @click="showUserAccountRecord(scope.row)"
              type="text"
              size="small"
              >明细</el-button
            >
            <!-- <el-button @click="refund(scope.row)" type="text" size="small" v-if="isShowBtn" >退款</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
    <RefundModel
      :dialog.sync="refundDialogVisible"
      :id="selectId"
      @search="clickSearch"
    />
    <UserAccountRecord
      :dialog.sync="userAccountRecordVisible"
      ref="userAccountRecord"
      @search="clickSearch"
    />
    <el-dialog title="温馨提示" :visible.sync="prompDialogVisible" width="30%">
      <span>用户是否已申请退款？可在退款对账中确认哦~</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundCancle">取 消</el-button>
        <el-button type="primary" @click="showRefundConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import FilterModel from "./model/wallet-search-model.vue";
import RefundModel from "./model/refund-model.vue";
import UserAccountRecord from "./model/user_account_record.vue";
import OPERATE_API from "../../api/operate_api";
import agentApi from "../../api/agent_api";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";

export default {
  name: "user-wallet",
  components: {
    FilterModel,
    RefundModel,
    UserAccountRecord,
  },
  data() {
    return {
      isShowBtn: false,
      total: 0,
      currentPage: 1,
      vloading: false,
      searchValue: {
        $limit: 50,
        $offset: 0,
        cellphone: "",
        first_city_code: [],
        userNumber: "",
      },
      dataTable: [],
      selection: [],
      pageSize: 50,
      offset: 0,
      limit: 0,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      filterVisible: false,
      selectId: "",
      refundDialogVisible: false,
      userAccountRecordVisible: false,
      prompDialogVisible: false,
    };
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  async mounted() {
    if (this.$store.state.user.isMaintenanceOrder == 2) {
      this.isShowBtn = true;
    }
    this.search();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (
          column.property === "total_amount" ||
          column.property === "recharge_total_amount" ||
          column.property === "pay_total_amount" ||
          column.property === "order_total_number"
        ) {
          let total = data.reduce((prev, cur) => {
            if (column.property === "order_total_number") {
              return prev + Number(cur[column.property]);
            } else {
              return prev + parseFloat(cur[column.property]);
            }
          }, 0);
          if (column.property === "order_total_number") {
            sums[index] = Number(total.toFixed(2));
          } else {
            sums[index] = parseFloat(total).toFixed(2);
          }
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    filter() {
      this.filterVisible = true;
    },
    search() {
      let that = this;
      this.vloading = true;
      this.axiosHelper
        .get("/v0.1/api/operate/wallet", {
          params: {
            ...this.searchValue,
            first_city_code: this.searchValue.first_city_code.join(","),
          },
        })
        .then((response) => {
          this.dataTable = response.data.items;
          this.total = response.data.total_count;
          this.vloading = false;
        })
        .catch((error) => {
          this.vloading = false;
          this.$message.error(
            {
              message: "获取用户钱包列表失败",
            },
            error
          );
        });
    },
    clear() {
      this.searchValue.cellphone = "";
      this.searchValue.userNumber = "";
      this.searchValue.first_city_code = [];
      this.handleCurrentChange(1);
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 25,
        operate_parameter: JSON.stringify({
          ...this.searchValue,
          first_city_code: this.searchValue.first_city_code.join(","),
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.selection.length == 0) {
        this.$confirm("是否要导出全部数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (this.total > 20000) {
            await this.$confirm(
              `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );
          }
          this.showLoading("数据加载中...");
          this.axiosHelper
            .get("/v0.1/api/operate/wallet/export", {
              params: {
                ...this.searchValue,
                first_city_code: this.searchValue.first_city_code.join(","),
              },
            })
            .then((response) => {
              this.hideLoading();
              this.exportData(response.data);
            })
            .catch(() => {
              this.hideLoading();
            });
        });
      } else {
        this.exportData(this.selection);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "用户编号",
          "手机号码",
          "城市",
          "账户余额（元）",
          "充值合计（元）",
          "支付合计（元）",
          "累计下单数",
          "最后充值时间",
          "最后支付时间",
        ];
        const filterVal = [
          "user_number",
          "cellphone",
          "first_city_name",
          "total_amount",
          "recharge_total_amount",
          "pay_total_amount",
          "order_total_number",
          "last_recharge_time",
          "last_pay_time",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "用户钱包导出",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.limit = this.pageSize;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.offset = (this.currentPage - 1) * this.pageSize;
      this.limit = this.pageSize;
      this.searchValue.$limit = this.limit;
      this.searchValue.$offset = this.offset;
      this.search();
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    clickSearch() {
      this.handleCurrentChange(1);
    },
    filterSearch(form) {
      this.searchValue.wechat_no = form.wechat_no;
      this.searchValue.open_id = form.open_id;
      this.searchValue.cellphone = form.cellphone;
      this.handleCurrentChange(1);
    },
    showRefundConfirm() {
      this.refundDialogVisible = true;
      this.prompDialogVisible = false;
    },
    refundCancle() {
      this.selectId = "";
      this.prompDialogVisible = false;
    },
    refund(row) {
      this.selectId = row.id;
      this.prompDialogVisible = true;
      // this.refundDialogVisible = true;
    },
    showUserAccountRecord(row) {
      this.userAccountRecordVisible = true;
      this.$nextTick(() => {
        this.$refs.userAccountRecord.init(row);
      });
    },
  },
};
</script>
<style scoped>
.box-card {
  margin-top: 15px;
}
</style>