<template>
    <el-dialog
        class="showAll_dialog"
        :title="'详情'"
        width="800px"
        :height="'80%'"
        :max-height="'600px'"
        :close-on-click-modal="false"
        :visible.sync="visible"
    >
        <el-form :model="form" ref="form" v-loading="formLoading" label-width="130px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="用户手机号：">
                        <el-input
                            v-model.trim="form.cell_phone"
                            :disabled="true"
                            placeholder="请输入活动名称"
                            clearable
                            maxlength="35"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="城市：">
                        <el-input
                            v-model.trim="form.city_name"
                            :disabled="true"
                            placeholder="请输入活动名称"
                            clearable
                            maxlength="35"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="获取度数类型：">
                        <div class="flex">
                            <el-radio-group v-model="form.distribute_src">
                                <el-radio :disabled="true" :label="1">系统</el-radio>
                                <el-radio :disabled="true" :label="2">手动</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="来源活动：">
                        <el-input
                            v-model.trim="form.activity_name"
                            :disabled="true"
                            placeholder="请输入活动名称"
                            clearable
                            maxlength="35"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="来源场景：">
                        <el-input
                            v-model.trim="form.billing_name"
                            :disabled="true"
                            placeholder="请输入活动名称"
                            clearable
                            maxlength="35"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="购买金额（元）：">
                        <el-input
                            v-model.trim="form.amount"
                            :disabled="true"
                            placeholder="请输入活动名称"
                            clearable
                            maxlength="35"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="获取度数：">
                        <el-input
                            v-model.trim="form.degree"
                            :disabled="true"
                            placeholder="请输入活动名称"
                            clearable
                            maxlength="35"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="购买时间：">
                        <el-input
                            v-model.trim="form.time"
                            :disabled="true"
                            placeholder="请输入活动名称"
                            clearable
                            maxlength="35"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="付款方式：">
                        <el-input
                            v-model.trim="form.pay_method_name"
                            :disabled="true"
                            placeholder="请输入活动名称"
                            clearable
                            maxlength="35"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="状态：">
                        <el-input
                            v-model.trim="form.stateName"
                            :disabled="true"
                            placeholder="请输入活动名称"
                            clearable
                            maxlength="35"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            visible: false,
            formLoading: false,
            form: {
                activity_name: "",
                amount: "",
                billing_name: "",
                cell_phone: "",
                city_code: "",
                city_name: "",
                degree: "",
                distribute_src: "",
                pay_method: "",
                pay_method_name: "",
                remark: "",
                state: "",
                time: ""
            }
        };
    },
    methods: {
        init(row) {
            this.$nextTick(() => {
                if (row) {
                    this.form = row;
                    this.form.stateName = !this.form.state
                        ? "失败"
                        : this.form.state === 1
                        ? "正常"
                        : "作废";
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
</style>