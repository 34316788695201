<template>
  <div class="app-main">
    <el-card class="box-card">
      <div class="box-card-search">
        <el-form ref="form" :model="form" class="box-card-search-form">
          <p class="box-card-title">订单筛选</p>
          <el-form-item label="城市" style="flex: 1" label-width="50px">
            <el-select
              v-model="form.cityCode"
              placeholder="请选择城市"
              multiple
              collapse-tags
              clearable
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="item in restrictedCityList"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单编号：" style="flex: 1" label-width="90px">
            <el-input
              v-model.trim="form.searchOrderNo"
              placeholder="请输入订单编号"
              clearable
            />
          </el-form-item>
          <el-form-item label="用户编号：" style="flex: 1" label-width="100px">
            <el-input
              v-model.trim="form.userNumber"
              placeholder="请输入用户编号"
              clearable
              maxlength="10"
              oninput="value=value.replace(/[^0-9]/g,'')"
            />
          </el-form-item>
          <el-form-item label="订单状态：" style="flex: 1" label-width="90px">
            <el-select
              v-model="form.searchOrderStatus"
              multiple
              collapse-tags
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in orderStatusDim"
                :key="item.data_value"
                :value="item.data_value"
                :label="item.data_name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间：" label-width="90px">
            <el-date-picker
              v-model="payTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 220px"
              align="center"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item class="elColBtn">
            <el-button style="margin-right: 10px" type="primary" @click="search"
              >查询</el-button
            >
            <el-button type="default" @click="clear">重置</el-button>
          </el-form-item>
          <el-button type="text" class="filter_btn" @click="filter"
            >高级查询</el-button
          >
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card box-card-main">
      <div class="total">
        <div
          v-for="(item, index) in totalList"
          :style="{ background: item.background }"
          class="total-item"
          :key="index"
        >
          <img class="total-item-img" :src="item.img" />
          <div class="total-item-main">
            <p class="total-item-main-name">{{ item.name }}</p>
            <p class="total-item-main-price">{{ item.price }}</p>
          </div>
        </div>
      </div>
      <el-row style="margin-bottom: 15px">
        <span>订单列表</span>
        <el-button
          style="margin-left: 15px"
          v-if="isShowBtn"
          @click="closeOrder"
          type="primary"
          >关闭订单</el-button
        >
        <!-- <el-button style="margin-left: 15px" @click="settleOrder" type="primary"
          >设置结算单</el-button
        > -->
        <el-button
          style="margin-left: 15px"
          v-if="isShowBtn3 || isShowBtn2"
          @click="testOrder"
          type="primary"
          >设置测试单</el-button
        >
        <el-dropdown
          v-if="isShowBtn2 || isShowBtn"
          trigger="click"
          @command="settleOrder"
          placement="bottom"
        >
          <el-button type="primary" style="margin-left: 15px">
            设置结算单<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">已结算</el-dropdown-item>
            <el-dropdown-item command="0">未结算</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" style="float: right" @click="exportOrder"
          >导出</el-button
        >
      </el-row>
      <!-- <el-table ref="dataTable" :height="tableHeight" :data="dataTable" border @selection-change="rowSelected"
                :summary-method="getSummaries" show-summary> -->
      <el-table
        ref="dataTable"
        :height="tableHeight"
        class="dataTable"
        :data="dataTable"
        show-summary
        :summary-method="getSummaries"
        border
        @selection-change="rowSelected"
        @sort-change="handleSortChange"
        :header-cell-style="{
          background: '#eff4fd',
          color: '#323232',
        }"
      >
        <el-table-column
          fixed="left"
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column label="序号" min-width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_no"
          label="订单编号"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="viewRow(scope.row)">{{
              scope.row.order_no
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_type_name"
          label="订单类型"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="reserve_time"
          label="预约时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_name"
          label="用户名称"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="recommend_type_name"
          label="推荐人类型"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="reference_name"
          label="推荐人"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_cellphone"
          label="用户手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="car_no"
          label="车牌号码"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="delivery_user_name"
          label="配送人员"
          min-width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="order_status_name"
          label="订单状态"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_finish_time"
          label="订单完成时间"
          sortable="custom"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="equipment_name"
          label="设备名称"
          sortable="custom"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="recover_flag_name"
          label="设备回收状态"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="actual_charge"
          label="充电度数"
          sortable="custom"
          min-width="100"
          align="center"
          :formatter="formatCharge"
        ></el-table-column>
        <el-table-column
          prop="real_charge"
          label="实际充电度数"
          sortable="custom"
          min-width="120"
          align="center"
          :formatter="formatCharge"
        ></el-table-column>
        <el-table-column
          prop="start_charge_degree"
          label="起充度数"
          min-width="100"
          align="center"
          :formatter="formatCharge"
        >
        </el-table-column>
        <el-table-column
          prop="has_maintenance_order"
          label="是否运维工单"
          min-width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.has_maintenance_order" type="danger"
              >是</el-tag
            >
            <el-tag v-else type="success">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="electric_unit_price"
          label="电费单价（元/度）"
          sortable="custom"
          min-width="160"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_price"
          label="订单金额"
          sortable="custom"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_invoice_amount_money"
          label="用户开票金额"
          min-width="140"
          align="center"
        >
          <span style="" slot="header">
            <span
              >用户开票金额<el-tooltip
                class="item"
                effect="dark"
                content="账户余额支付+c端度数（度数金额）"
                placement="top"
                ><i class="el-icon-question"></i></el-tooltip
            ></span>
          </span>
        </el-table-column>
        <!-- <el-table-column prop="real_pay_money" label="实付金额总计" sortable="custom" min-width="120"
                    align="center"></el-table-column> -->
        <el-table-column
          prop="actual_price"
          label="账户余额支付"
          sortable="custom"
          min-width="120"
          align="center"
        ></el-table-column>
        <!-- <el-table-column label="实付金额" min-width="100" align="center">
                    <el-table-column prop="actual_price" label="账户余额支付" sortable="custom" min-width="120"
                        align="center">
                    </el-table-column><el-table-column prop="use_degree_real_money" label="实时度数金额" sortable="custom"
                        min-width="120" align="center"></el-table-column>
                </el-table-column> -->
        <el-table-column
          prop="coupon_name"
          label="优惠券"
          min-width="160"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="coupon_deduction_money"
          label="优惠券补贴金额"
          min-width="100"
          align="center"
        >
          <span style="" slot="header">
            <span
              >优惠券补贴金额<el-tooltip
                class="item"
                effect="dark"
                placement="top"
              >
                <div slot="content">
                  度数券：电费单价*实际抵扣度数<br />现金券：实际抵扣现金金额
                </div>
                <i class="el-icon-question"></i></el-tooltip
            ></span>
          </span>
        </el-table-column>

        <el-table-column label="C端度数" min-width="100" align="center">
          <el-table-column
            prop="use_degree_unit_price"
            label="实时单价"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="client_use_degree"
            label="数量"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="use_degree_real_money"
            label="度数金额"
            min-width="100"
            align="center"
          >
            <span style="" slot="header">
              <span
                >度数金额<el-tooltip
                  class="item"
                  effect="dark"
                  content="c端度数（数量） * c端度数（实时单价）"
                  placement="top"
                  ><i class="el-icon-question"></i></el-tooltip
              ></span>
            </span>
          </el-table-column>
        </el-table-column>
        <el-table-column label="B端度数" min-width="100" align="center">
          <el-table-column
            prop="busin_degree_unit_price"
            label="实时单价"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="busin_degree"
            label="数量"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="busin_degree_amount_money"
            label="度数金额"
            min-width="100"
            align="center"
          >
            <span slot="header">
              <span
                >度数金额<el-tooltip
                  class="item"
                  effect="dark"
                  content="B端度数（数量） * B端度数（实时单价）"
                  placement="top"
                  ><i class="el-icon-question"></i></el-tooltip
              ></span>
            </span>
          </el-table-column>
        </el-table-column>
        <el-table-column label="度数合计" min-width="100" align="center">
          <el-table-column
            prop="use_degree"
            label="数量"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="use_degree_subsidy_amount_money"
            label="度数补贴金额"
            min-width="120"
            align="center"
          >
            <span slot="header">
              <span
                >度数补贴金额<el-tooltip
                  class="item"
                  effect="dark"
                  content="订单金额 - 账户余额支付 - 优惠券补贴金额 - 度数金额合计"
                  placement="top"
                  ><i class="el-icon-question"></i></el-tooltip
              ></span>
            </span>
          </el-table-column>
          <el-table-column
            prop="use_degree_amount_money"
            label="度数金额合计"
            min-width="120"
            align="center"
          >
            <span slot="header">
              <span
                >度数金额合计<el-tooltip
                  class="item"
                  effect="dark"
                  content="c端度数（度数金额）+b端度数（ 度数金额）"
                  placement="top"
                  ><i class="el-icon-question"></i></el-tooltip
              ></span>
            </span>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="pay_flag_name"
          label="支付状态"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_time"
          label="支付时间"
          sortable="custom"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注信息"
          min-width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="agent_settle_name"
          label="结算状态"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button @click="viewRow(scope.row)" type="text" size="small"
              >查看</el-button
            >
            <el-button
              @click="editRow(scope.row)"
              v-if="isShowBtn"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              v-if="
                isShowBtn &&
                scope.row.order_status != 7 &&
                scope.row.order_status != 8 &&
                scope.row.order_status != 9
              "
              @click="closeRow(scope.row)"
              type="text"
              size="small"
              >关闭</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page-footer">
        <p class="page-footer-total">共{{ page_total }}条数据</p>
        <el-pagination
          background
          layout="sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100, 200, 300]"
          :current-page.sync="page_index"
        ></el-pagination>
      </div>
    </el-card>
    <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
    <OrderModel
      :dialog.sync="orderModelVisible"
      :id="selectId"
      :status="editState"
      @search="search"
    />
    <CloseModel
      :dialog.sync="closeModelVisible"
      :id="selectId"
      @search="search"
    />
    <SettleModel
      :dialog.sync="settleModelVisible"
      :ids="ids"
      @search="search"
    />
    <TestModel :dialog.sync="testModelVisible" :ids="ids" @search="search" />
  </div>
</template>
<script>
import {
  preciseAddition,
  preciseSubtraction,
  preciseMultiplication,
} from "@/utils/count";
import DIM_API from "../../api/dim_api";
import FilterModel from "./component/order-management-filter.vue";
import OrderModel from "./component/order-model.vue";
import CloseModel from "./component/close-order.vue";
import SettleModel from "./component/settle-order.vue";
import TestModel from "./component/test-order.vue";
import OPERATE_API from "../../api/operate_api.js";
import agentApi from "../../api/agent_api";
import moment from "moment";
import order_cdds_icon from "@/assets/img/order-icon/order_cdds_icon.png";
import order_ddje_icon from "@/assets/img/order-icon/order_ddje_icon.png";
import order_zhyezf_icon from "@/assets/img/order-icon/order_zhyezf_icon.png";
import order_dsjehj_icon from "@/assets/img/order-icon/order_dsjehj_icon.png";
import order_btjehj_icon from "@/assets/img/order-icon/order_btjehj_icon.png";
import order_yhqbtje_icon from "@/assets/img/order-icon/order_yhqbtje_icon.png";
import order_dsbtje_icon from "@/assets/img/order-icon/order_dsbtje_icon.png";
import order_yhysyds_icon from "@/assets/img/order-icon/order_yhysyds_icon.png";
import { recommend_type_list } from "@/enum/userEnum";
import * as XLSX from "xlsx";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api.js";
export default {
  name: "orderManagement",
  components: {
    FilterModel,
    OrderModel,
    CloseModel,
    SettleModel,
    TestModel,
  },
  data() {
    return {
      form: {
        createTimeBegin: "",
        createTimeEnd: "",
        cityCode: [],
      },
      multiQuery: {},
      orderStatusDim: [],
      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      filterVisible: false,
      editState: "add",
      selectId: "",
      orderModelVisible: false,
      closeModelVisible: false,
      settleModelVisible: false,
      testModelVisible: false,
      field: "",
      type: "",
      ids: [],
      statistics: {},
    };
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.initDim();
    this.form.createTimeBegin = moment()
      .subtract(2, "months")
      .format("YYYY-MM-DD 00:00:00");
    this.form.createTimeEnd = moment().format("YYYY-MM-DD 23:59:59");
    this.search();
  },
  computed: {
    ...mapState(["restrictedCityList"]),
    payTime: {
      set(val) {
        this.form.createTimeBegin = val
          ? moment(val[0]).startOf("days").format("YYYY-MM-DD HH:mm:ss")
          : "";
        this.form.createTimeEnd = val
          ? moment(val[1]).endOf("days").format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
      get() {
        return [this.form.createTimeBegin, this.form.createTimeEnd];
      },
    },
    isShowBtn() {
      let user = this.$store.state.user;
      if (user && user.isMaintenanceOrder == 1) {
        return true;
      }
      return false;
    },
    isShowBtn2() {
      let user = this.$store.state.user;
      if (user && user.isMaintenanceOrder == 2) {
        return true;
      }
      return false;
    },
    isShowBtn3() {
      let user = this.$store.state.user;
      if (
        user &&
        (user.isMaintenanceOrder == 1 || user.isMaintenanceOrder == 3)
      ) {
        return true;
      }
      return false;
    },
    totalList() {
      return [
        {
          name: "充电度数",
          img: order_cdds_icon,
          background: "#E9F0FF",
          price: parseFloat(this.statistics.actual_charge || 0).toFixed(3),
        },
        {
          name: "订单金额",
          img: order_ddje_icon,
          background: "#ECFFF4",
          price: `￥${Number(this.statistics.order_price || 0).toFixed(2)}`,
        },
        {
          name: "账户余额支付",
          img: order_zhyezf_icon,
          background: "#EAF8FF",
          price: `￥${Number(this.statistics.actual_price || 0).toFixed(2)}`,
        },
        {
          name: "度数金额合计",
          img: order_dsjehj_icon,
          background: "#FFF2E6",
          price: `￥${Number(this.statistics.degree_amount || 0).toFixed(2)}`,
        },
        {
          name: "补贴金额合计",
          img: order_btjehj_icon,
          background: "#E9F0FF",
          price: `￥${Number(this.statistics.subsidy_amount || 0).toFixed(2)}`,
        },
        {
          name: "优惠券补贴金额",
          img: order_yhqbtje_icon,
          background: "#ECFFF4",
          price: `￥${Number(
            this.statistics.coupon_deduction_money || 0
          ).toFixed(2)}`,
        },
        {
          name: "度数补贴金额",
          img: order_dsbtje_icon,
          background: "#EAF8FF",
          price: `￥${Number(
            this.statistics.use_degree_deduction_money || 0
          ).toFixed(2)}`,
        },
        {
          name: "用户已使用度数",
          img: order_yhysyds_icon,
          background: "#FFF2E6",
          price: `${Number(this.statistics.use_degree || 0).toFixed(3)}`,
        },
      ];
    },
  },
  methods: {
    filter() {
      this.filterVisible = true;
    },
    search() {
      this.page_index = 1;
      this.loadDataTable();
      this.getStatistics();
    },
    clear() {
      this.form = {
        createTimeBegin: moment()
          .subtract(2, "months")
          .format("YYYY-MM-DD 00:00:00"),
        createTimeEnd: moment().format("YYYY-MM-DD 23:59:59"),
        cityCode: [],
        searchOrderNo: "",
        userNumber: "",
        searchOrderStatus: "",
      };
      this.page_index = 1;
      this.loadDataTable();
      this.getStatistics();
    },
    async settleOrder(type) {
      console.log(type);
      if (!this.selection || this.selection.length === 0) {
        this.$message.warning("请先选择订单");
        return;
      }
      if (type == "0") {
        if (
          this.selection.some(
            (it) => !(it.pay_flag == 1 && it.agent_settle_status == "1")
          )
        ) {
          this.$message.warning(
            "目前仅支持将已结算状态的订单设置为未结算哦，请重新选择！"
          );
          return;
        }
      }
      if (type == "1") {
        if (
          this.selection.some(
            (it) => !(it.pay_flag == 1 && it.agent_settle_status == "0")
          )
        ) {
          this.$message.warning(
            "目前仅支持将未结算状态的订单设置为已结算哦，请重新选择！"
          );
          return;
        }
      }
      await this.$confirm(
        `您所选择的订单结算状态将变更为${
          type == 0 ? "未" : "已"
        }结算，是否确认设置？`,
        "设置结算订单",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      this.showLoading("提交中");
      let ids = this.selection.map((a) => a.id);
      let res = await agentApi.settleOrder(type, ids).finally(() => {
        this.hideLoading();
      });
      if (res.data.code != 0) {
        this.$message.error({
          message: res.data.msg,
        });
        return;
      }
      this.$message({
        message: "操作成功",
        type: "success",
        duration: 1500,
      });
      this.search();
      // let displayWarning = false;
      // this.selection.forEach((a) => {
      //   if (!(a.pay_flag == 1 && a.agent_settle_status == "0")) {
      //     displayWarning = true;
      //     return;
      //   }
      // });
      // if (displayWarning) {
      //   this.$message.warning("仅支持已支付且未结算的订单哦");
      //   return;
      // }

      // this.ids = this.selection.map((a) => a.id);

      // // this.selectId = ids;
      // this.settleModelVisible = true;
    },
    testOrder() {
      if (!this.selection || this.selection.length === 0) {
        this.$message.warning("请先选择订单");
        return;
      }
      let displayWarning = false;
      this.selection.forEach((a) => {
        if (a.agent_settle_status != "0") {
          displayWarning = true;
          return;
        }
      });
      if (displayWarning) {
        this.$message.warning("仅支持未结算的订单哦");
        return;
      }
      this.ids = this.selection.map((a) => a.id);
      this.testModelVisible = true;
    },
    closeOrder() {
      if (!this.selection || this.selection.length === 0) {
        this.$message.warning("请先选择订单");
        return;
      }

      let ids = this.selection.map((a) => a.id).join(",");
      this.selectId = ids;
      this.closeModelVisible = true;
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 30,
        operate_parameter: JSON.stringify({
          ...this.form,
          ...this.multiQuery,
          cityCode: this.form.cityCode.join(","),
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.selection.length == 0) {
        // this.$confirm("是否要导出全部数据?", "提示", {
        //     confirmButtonText: "确定",
        //     cancelButtonText: "取消",
        //     type: "warning"
        // }).then(() => {
        if (this.page_total > 20000) {
          await this.$confirm(
            `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          );
        }
        this.exportFunction();
        // })
      } else {
        this.exportData(this.selection);
      }
    },
    exportFunction() {
      this.showLoading("数据加载中...");
      let params = {
        ...this.form,
        ...this.multiQuery,
        cityCode: this.form.cityCode.join(","),
        source: "pc",
      };
      params.field = this.field;
      params.type = this.type;
      params.searchOrderStatus = params.searchOrderStatus
        ? params.searchOrderStatus.join(",")
        : "";
      params.orderStatusList = params.orderStatusList
        ? params.orderStatusList.join(",")
        : "";
      this.axiosHelper
        .get("/api/order/export", {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          let data = response.data;
          data.forEach((item) => {
            if (item.order_type != 3) {
              if (
                moment(item.reserve_start_time).valueOf() ==
                moment(item.reserve_end_time).valueOf()
              ) {
                item.reserve_time = item.reserve_start_time;
              } else {
                item.reserve_time =
                  item.reserve_start_time + " - " + item.reserve_end_time;
              }
            }

            item.pay_flag_name = item.pay_flag ? "已支付" : "未支付";
            item.pay_flag_name = item.pay_flag
              ? "已支付"
              : item.order_status == 7
              ? "未支付"
              : "";
            item.recover_flag_name = item.has_equipment
              ? item.recover_flag
                ? "已回收"
                : "未回收"
              : "未关联";
            switch (item.agent_settle_status) {
              case "1":
                item.agent_settle_name = "已结算";
                break;
              case "2":
                item.agent_settle_name = "测试单";
                break;
              case "0":
                if (item.order_status == "7") {
                  item.agent_settle_name = "未结算";
                }
                break;
              default:
            }
            item.electric_unit_price =
              item.order_type != 2
                ? Number(Number(item.electric_unit_price).toFixed(2))
                : "";
            item.user_invoice_amount_money = Number(
              preciseAddition(
                Number(item.actual_price),
                Number(item.use_degree_real_money)
              )
            );
            item.client_use_degree = Number(
              preciseSubtraction(
                Number(item.use_degree),
                Number(item.busin_degree)
              )
            );
            item.busin_degree_amount_money = Number(
              preciseMultiplication(
                Number(item.busin_degree),
                Number(item.busin_degree_unit_price)
              )
            );
            item.use_degree_amount_money = Number(
              preciseAddition(
                Number(item.busin_degree_amount_money),
                Number(item.use_degree_real_money)
              )
            );
            item.use_degree_subsidy_amount_money =
              item.pay_flag_name == "未支付"
                ? 0
                : Number(
                    preciseSubtraction(
                      Number(item.order_price),
                      Number(item.actual_price),
                      Number(item.coupon_deduction_money),
                      Number(item.use_degree_amount_money)
                    )
                  );
            item.maintenance_order = item.has_maintenance_order ? "是" : "否";
            item.recommend_type_name =
              recommend_type_list.find((it) => it.value === item.recommend_type)
                ?.label || "";
          });
          this.exportData(data);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "城市",
          "订单编号",
          "订单类型",
          "用户手机号",
          "车牌号码",
          "地址名称",
          "电费单价",
          "充电度数",
          "订单金额",
          "用户开票金额",
          "优惠券",
          "优惠券补贴金额",
          "B端度数（度数金额）",
          "度数合计（度数补贴金额）",
          "支付时间",
          "度数合计（度数金额合计）",
          "预约时间",
          "车型名称",
          "地址联系号码",
          "配送人员",
          "配送员号码",
          "设备回收状态",
          "订单状态",
          "账户余额支付",
          "C端度数（实时单价）",
          "C端度数（数量）",
          "C端度数（度数金额）",
          "B端度数（实时单价）",
          "B端度数（数量）",
          "度数合计（数量）",
          "支付状态",
          "备注信息",
          "创建时间",
          "结算状态",
          "用户编号",
          "实际充电度数",
          "起充度数",
          "是否运维工单",
          "推荐人类型",
          "推荐人",
          "服务开始时间",
          "服务结束时间",
          "设备名称",
        ];
        const filterVal = [
          "city_name",
          "order_no",
          "order_type_name",
          "user_cellphone",
          "car_no",
          "address",
          "electric_unit_price",
          "actual_charge",
          "order_price",
          "user_invoice_amount_money",
          "coupon_name",
          "coupon_deduction_money",
          "busin_degree_amount_money",
          "use_degree_subsidy_amount_money",
          "pay_time",
          "use_degree_amount_money",
          "reserve_time",
          "car_name",
          "user_mobile",
          "delivery_user_name",
          "delivery_user_cellphone",
          "recover_flag_name",
          "order_status_name",
          "actual_price",
          "use_degree_unit_price",
          "client_use_degree",
          "use_degree_real_money",
          "busin_degree_unit_price",
          "busin_degree",
          "use_degree",
          "pay_flag_name",
          "remark",
          "create_time",
          "agent_settle_name",
          "user_number",
          "real_charge",
          "start_charge_degree",
          "maintenance_order",
          "recommend_type_name",
          "reference_name",
          "service_start_time",
          "service_end_time",
          "equipment_name",
        ];
        // export_data.forEach((item) => {
        //     item.reserve_time = item.reserve_start_time + ' - ' + item.reserve_end_time
        //     item.pay_flag_name = item.pay_flag? '已支付' : '未支付'
        //     item.recover_flag_name = item.recover_flag ? '已回收' : '未回收'
        // })
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "订单导出",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    viewRow(row) {
      this.editState = "view";
      this.selectId = row.id;
      this.orderModelVisible = true;
    },
    editRow(row) {
      this.editState = "edit";
      this.selectId = row.id;
      this.orderModelVisible = true;
    },
    closeRow(row) {
      this.selectId = row.id;
      this.closeModelVisible = true;
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
      this.getStatistics();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
      this.getStatistics();
    },
    handleSortChange(column) {
      this.field = column.prop;
      const sortingType = column.order;
      sortingType == "ascending" ? (this.type = "ASC") : (this.type = "DESC");
      this.loadDataTable();
      this.getStatistics();
    },
    getStatistics() {
      this.showLoading("加载中");
      let params = {
        ...this.form,
        ...this.multiQuery,
        cityCode: this.form.cityCode.join(","),
      };
      params.field = this.field;
      params.type = this.type;
      params.searchOrderStatus = params.searchOrderStatus
        ? params.searchOrderStatus.join(",")
        : "";
      params.orderStatusList = params.orderStatusList
        ? params.orderStatusList.join(",")
        : "";
      this.axiosHelper
        .get(`/api/order/statistics`, {
          params: params,
        })
        .then((res) => {
          this.statistics = res.data.data;
          this.hideLoading();
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    loadDataTable() {
      let params = {
        ...this.form,
        ...this.multiQuery,
        cityCode: this.form.cityCode.join(","),
        source: "pc",
      };
      params.field = this.field;
      params.type = this.type;
      params.searchOrderStatus = params.searchOrderStatus
        ? params.searchOrderStatus.join(",")
        : "";
      params.orderStatusList = params.orderStatusList
        ? params.orderStatusList.join(",")
        : "";
      params.$limit = this.page_size;
      params.$offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/order", {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.items;
          this.dataTable.forEach((item) => {
            if (item.order_type != 3) {
              if (
                moment(item.reserve_start_time).valueOf() ==
                moment(item.reserve_end_time).valueOf()
              ) {
                item.reserve_time = item.reserve_start_time;
              } else {
                item.reserve_time =
                  item.reserve_start_time + " - " + item.reserve_end_time;
              }
            }

            item.pay_flag_name = item.pay_flag
              ? "已支付"
              : item.order_status == 7
              ? "未支付"
              : "";
            item.recover_flag_name = item.has_equipment
              ? item.recover_flag
                ? "已回收"
                : "未回收"
              : "未关联";
            switch (item.agent_settle_status) {
              case "1":
                item.agent_settle_name = "已结算";
                break;
              case "2":
                item.agent_settle_name = "测试单";
                break;
              case "0":
                if (item.order_status == "7") {
                  item.agent_settle_name = "未结算";
                }
                break;
              default:
            }
            item.electric_unit_price =
              item.order_type != 2
                ? Number(item.electric_unit_price).toFixed(2)
                : "";
            item.user_invoice_amount_money = preciseAddition(
              Number(item.actual_price),
              Number(item.use_degree_real_money)
            );
            item.client_use_degree = preciseSubtraction(
              Number(item.use_degree),
              Number(item.busin_degree)
            );
            item.busin_degree_amount_money = preciseMultiplication(
              Number(item.busin_degree),
              Number(item.busin_degree_unit_price)
            );
            item.use_degree_amount_money = preciseAddition(
              Number(item.busin_degree_amount_money),
              Number(item.use_degree_real_money)
            );
            item.use_degree_subsidy_amount_money =
              item.pay_flag_name == "未支付"
                ? "0.00"
                : preciseSubtraction(
                    Number(item.order_price),
                    Number(item.actual_price),
                    Number(item.coupon_deduction_money),
                    Number(item.use_degree_amount_money)
                  );
            item.maintenance_order = item.has_maintenance_order ? "是" : "否";
            item.recommend_type_name =
              recommend_type_list.find((it) => it.value === item.recommend_type)
                ?.label || "";
          });
          this.page_total = response.data.total_count;
          this.$nextTick(() => {
            this.$refs.dataTable.doLayout();
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    async initDim() {
      let res = await DIM_API.getByType("OrderStatus");
      this.orderStatusDim = res.data;
    },
    getTableHeight() {
      this.tableHeight = 70 * 11;
    },
    filterSearch(data) {
      this.multiQuery = data;
      console.log(this.multiQuery);
      this.page_index = 1;
      this.loadDataTable();
      this.getStatistics();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (
          column.property === "order_price" ||
          column.property === "actual_price" ||
          column.property === "actual_charge" ||
          column.property === "coupon_deduction_money" ||
          column.property === "use_degree_deduction_money" ||
          column.property === "use_degree" ||
          column.property === "use_degree_real_money" ||
          column.property === "busin_degree" ||
          column.property === "busin_degree_deduction_money" ||
          column.property === "client_use_degree" ||
          column.property === "degree_deduction_money" ||
          column.property === "user_invoice_amount_money" ||
          column.property === "busin_degree_amount_money" ||
          column.property === "use_degree_subsidy_amount_money" ||
          column.property === "use_degree_amount_money"
        ) {
          let total = data.reduce((prev, cur) => {
            return preciseAddition(prev, Number(cur[column.property]));
          }, 0);
          sums[index] = total;
          // if (column.property === "actual_charge") {
          //   sums[index] = Number(total.toFixed(3)).toFixed(3);
          // } else {
          //   sums[index] = Number(total.toFixed(2));
          // }
        } else {
          sums[index] = "";
          if (index === 0) {
            sums[index] = "合计";
          }
        }
      });
      return sums;
    },
    // 格式化日期时间为 yyyy-MM-dd HH:mm:ss
    formatDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    formatCharge(row, column, cellValue) {
      if (cellValue) {
        return parseFloat(cellValue).toFixed(3);
      }
      return cellValue;
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  //   display: flex;
  //   flex-direction: row;
}

.elCol {
  //   padding: 0px !important;
}

.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}

.elColBtn {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
  .total-item {
    display: flex;
    margin-left: 12px;
    min-height: 72px;
    padding: 12px;
    border-radius: 8px;
    box-sizing: border-box;
    align-items: center;
    flex: 1;
    overflow: hidden;
    .total-item-img {
      width: 40px;
      height: 40px;
    }
    .total-item-main {
      margin-left: 10px;
      flex: 1;
      overflow: hidden;
      .total-item-main-name {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #676d6d;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }
      .total-item-main-price {
        font-weight: 700;
        font-size: 20px;
        color: #323232;
        line-height: 23px;
        text-align: left;
        margin-top: 10px;
        font-family: Arial, Arial;
        word-break: break-all;
      }
    }
    &:first-child {
      margin-left: 0px;
    }
  }
}

.break-line {
  display: block;
  text-align: center;
  font-size: 16px;
}
.app-main {
  // height: 100%;
  .box-card {
    margin-top: 15px;
    .box-card-title {
      font-weight: bold;
      font-size: 16px;
      color: #323232;
      line-height: 32px;
    }
    .box-card-search {
      display: flex;
      align-items: center;
      .box-card-search-form {
        flex: 1;
        display: flex;
        align-items: center;
        .box-card-title {
          flex-shrink: 0;
        }
        .elColBtn {
          flex-shrink: 0;
        }
        .filter_btn {
          margin-left: 30px;
        }
        // .filter-btn {
        //   display: flex;
        //   .line {
        //     width: 1px;
        //     height: 32px;
        //     background: #ededed;
        //     margin-right: 30px;
        //   }
        // }
      }
    }
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .box-card-main {
    // flex: 1;
    // overflow: hidden;
    // ::v-deep .el-card__body {
    //   display: flex;
    //   flex-direction: column;
    //   overflow: hidden;
    //   // height: 100%;
    //   box-sizing: border-box;
    // }
    .dataTable {
      // overflow: hidden;
      // ::v-deep .el-table__fixed-body-wrapper {
      //   /*此处的important表示优先于element.style*/
      //   // height: 100% !important;
      //   /*改为自动高度后，设置与父容器的底部距离，则高度会动态改变*/
      //   bottom: 40px !important;
      // }
      ::v-deep .el-table__fixed-footer-wrapper tbody td {
        color: #fb864f;
        background: #faf4e6;
      }
      ::v-deep .el-table__footer-wrapper tbody td {
        color: #fb864f;
        background: #faf4e6;
      }
    }
    .page-footer {
      display: flex;
      margin-top: 10px;
      align-items: center;
      justify-content: space-between;
      .page-footer-total {
        font-size: 16px;
        color: #666666;
      }
    }
  }
}
</style>
