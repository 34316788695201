
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="商户名称：">
        <el-input
          v-model.trim="form.merchantName"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
      <el-form-item label="城市">
        <el-select
          v-model="form.cityCode"
          placeholder="全国"
          collapse-tags
          filterable
          clearable
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <el-button style="margin-left: 15px" @click="addOrUpdateHandle('add')"
        >新增</el-button
      >
      <el-button style="margin-left: 15px" @click="exportData(FILE_VIEW_URL)"
        >导出</el-button
      >
    </el-row>
    <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
      <el-table-column
        prop="merchant_name"
        label="商户名称"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="merchant_manager"
        label="商户负责人"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="merchant_tel"
        label="商户联系电话"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column label="有效期" min-width="150" align="center">
        <template slot-scope="scope">
          {{ scope.row.valid_time_start }}~{{ scope.row.valid_time_end }}
        </template>
      </el-table-column>
      <el-table-column
        prop="qrcode_url"
        label="二维码"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.qrcode_url"
            style="width: 50px; height: 50px"
            :src="getQrcodeUrl(scope.row.qrcode_url)"
            @click="showWxappQrcodeView(scope.row)"
          >
          </el-image>
          <!--            <span v-if="scope.row.qrcode_url">{{FILE_VIEW_URL+scope.row.qrcode_url}}</span>-->
          <el-button
            v-if="!scope.row.qrcode_url"
            @click="generateWxAppQrcode(scope.row.id)"
            type="text"
            size="small"
            >生成</el-button
          >
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button
            @click="addOrUpdateHandle('edit', scope.row)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            @click="deleteHandle(scope.row.id)"
            type="text"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <RecomMerchantSave
    v-if="addOrUpdateVisible"
    ref="addOrUpdate"
    :editState="editState"
    @refreshDataList="search"
  >
  </RecomMerchantSave>
  <WxappQrcodeView
    v-if="wxappQrcodeViewVisible"
    ref="wxappQrcodeView"
  ></WxappQrcodeView>
</div>
