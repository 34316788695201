
<el-dialog
  class="level-one-dialog"
  v-dialogDrag
  title="导出数据"
  :visible.sync="model_dialog"
  :close-on-click-modal="false"
  append-to-body
  width="400px"
>
  <el-scrollbar
    style="height: 220px"
    wrapStyle="padding-right:20px;overflow-x:hidden;"
    viewStyle=""
  >
    <div>
      <el-checkbox-group v-model="checked_headers">
        <el-checkbox
          v-for="item in export_headers"
          :label="item"
          :key="item.value"
          class="filterContent"
        >
          {{ item.label }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </el-scrollbar>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="exportData">导出</el-button>
    <el-button @click="model_dialog = false">取消</el-button>
  </div>
</el-dialog>
