
<el-dialog
  :title="'度数配置'"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <div style="max-height: 50vh; overflow: auto">
    <el-form :model="form" :rules="relus" ref="form" label-width="90px">
      <el-form-item label="当前城市">
        <div>{{ form.city_name }}</div>
      </el-form-item>
      <el-form-item label="常规充电"></el-form-item>
      <div class="form_label">度数价格配置</div>
      <div class="flex">
        <el-form-item label="最低度数" prop="min_degree">
          <el-input-number
            v-model.number="form.min_degree"
            @change="minChange"
            :step="5"
            :min="1"
            :max="200"
            controls-position="right"
            @keyup.native="UpNumber"
            @keydown.native="UpNumber"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="最高度数" prop="max_degree">
          <el-input-number
            v-model.number="form.max_degree"
            @change="maxChange"
            :step="5"
            :step-strictly="true"
            :min="15"
            :max="200"
            controls-position="right"
            @keyup.native="UpNumber"
            @keydown.native="UpNumber"
          ></el-input-number>
        </el-form-item>
      </div>
      <el-form-item label="应急补电"></el-form-item>
      <div class="form_label">服务时间段配置</div>
      <div class="flex">
        <el-form-item label="常规时间" prop="regularTimeStart">
          <el-time-picker
            v-model="form.regularTimeStart"
            placeholder="请选择常规开始时间"
            format="HH:mm"
            value-format="HH:mm"
          >
          </el-time-picker>
        </el-form-item>
        <div style="padding: 0 10px; margin-bottom: 20px">至</div>
        <el-form-item label-width="0" prop="regularTimeEnd">
          <el-time-picker
            v-model="form.regularTimeEnd"
            placeholder="请选择常规结束时间"
            format="HH:mm"
            value-format="HH:mm"
          >
          </el-time-picker>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="夜间时间" prop="nightTimeStart">
          <el-time-picker
            v-model="form.nightTimeStart"
            placeholder="请选择夜间开始时间"
            format="HH:mm"
            value-format="HH:mm"
          >
          </el-time-picker>
        </el-form-item>
        <div style="padding: 0 10px; margin-bottom: 20px">至</div>
        <el-form-item label-width="0" prop="nightTimeEnd">
          <el-time-picker
            v-model="form.nightTimeEnd"
            placeholder="请选择夜间结束时间"
            format="HH:mm"
            value-format="HH:mm"
          >
          </el-time-picker>
        </el-form-item>
      </div>
      <div class="form_label">服务价位配置</div>
      <div class="flex price_form">
        <el-form-item label="常规时间" prop="regularFiveDegreePrice">
          <div class="flex time_label">
            <span style="margin-left: 0">5度</span><span>/</span
            ><el-input
              v-model.trim="form.regularFiveDegreePrice"
              placeholder="请输入价格"
              maxlength="9"
              clearable
            /><i>元</i>
          </div>
        </el-form-item>
        <el-form-item prop="regularTenDegreePrice" label-width="0">
          <div class="flex time_label">
            <span>10度</span><span>/</span
            ><el-input
              v-model.trim="form.regularTenDegreePrice"
              placeholder="请输入价格"
              maxlength="9"
              clearable
            /><i>元</i>
          </div>
        </el-form-item>
      </div>
      <div class="flex price_form">
        <el-form-item label="夜间时间" prop="nightFiveDegreePrice">
          <div class="flex time_label">
            <span style="margin-left: 0">5度</span><span>/</span>
            <el-input
              v-model.trim="form.nightFiveDegreePrice"
              placeholder="请输入价格"
              maxlength="9"
              clearable
            /><i>元</i>
          </div>
        </el-form-item>
        <el-form-item prop="nightTenDegreePrice" label-width="0">
          <div class="flex time_label">
            <span>10度</span><span>/</span>
            <!-- <el-form-item prop="nightTenDegreePrice" label-width="0"> -->
            <el-input
              v-model.trim="form.nightTenDegreePrice"
              placeholder="请输入价格"
              maxlength="9"
              clearable
            />
            <!-- </el-form-item> -->
            <i>元</i>
          </div>
        </el-form-item>
      </div>
      <!-- <el-form-item label="自助充电"></el-form-item>
      <div class="price_form-zzcd box-card">
        <div class="flex">
          <el-input placeholder="请输入电费单价" style="width: 200px" />
          <p style="margin-left: 10px">元</p>
        </div>
        <div class="flex box-card">
          <el-radio-group v-model="radio">
            <el-radio :label="3">全天</el-radio>
            <el-radio :label="6">自定义</el-radio>
          </el-radio-group>
          <el-time-picker
            style="margin-left: 30px"
            is-range
            v-model="value1"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          >
          </el-time-picker>
        </div>
        <div class="flex box-card">
          <el-button type="primary" icon="el-icon-plus">增加一组</el-button>
          <el-button type="danger">删除</el-button>
        </div>
      </div> -->
    </el-form>
  </div>

  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    <el-button @click="visible = false">取消</el-button>
  </span>
</el-dialog>
