<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="用户手机号：" style="margin-bottom: 0">
          <el-input
            v-model.trim="form.cellphone"
            placeholder="请输入"
            clearable
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item label="用户编号：" style="margin-bottom: 0">
          <el-input
            v-model.trim="form.userNumber"
            placeholder="请输入"
            clearable
            style="width: 100px"
            maxlength="10"
            oninput="value=value.replace(/[^0-9]/g,'')"
          />
        </el-form-item>
        <el-form-item label="所属城市：" style="margin-bottom: 0">
          <el-select
            v-model="form.firstCityCode"
            filterable
            placeholder="全部"
            clearable
            style="width: 100px"
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：" style="margin-bottom: 0">
          <el-select
            v-model="form.refundState"
            placeholder="全部"
            clearable
            style="width: 100px"
          >
            <el-option :value="0" label="待退款" />
            <el-option :value="1" label="已退款" />
            <el-option :value="2" label="不同意" />
          </el-select>
        </el-form-item>
        <el-form-item label="申请时间：" style="margin-bottom: 0">
          <el-date-picker
            style="width: 250px"
            v-model="applyTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            align="center"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="退款时间：" style="margin-bottom: 0">
          <el-date-picker
            style="width: 250px"
            v-model="refundTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            align="center"
          >
          </el-date-picker>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span style="float: left">退款对账</span>
        <el-button style="float: right" @click="exportData">导出</el-button>
      </el-row>
      <el-table
        ref="dataTable"
        :height="tableHeight"
        :data="dataTable"
        show-summary
        :summary-method="getSummaries"
        border
      >
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="用户手机号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="first_city_name"
          label="所属城市"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="apply_number"
          label="退款申请单号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="申请时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="apply_content"
          label="申请信息"
          min-width="150"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="refund_amount"
          label="退款金额"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="refund_time"
          label="退款时间"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="refund_early_amount"
          label="退款前"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="refund_current_amount"
          label="退款后"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="refund_state"
          label="状态"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">{{
            getRefundStateName(scope.row.refund_state)
          }}</template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="250"
          align="center"
          v-if="isShowBtn2"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.refund_state == 0">
              <el-button
                @click="agreeRefundShow(scope.row)"
                type="text"
                size="small"
                >同意</el-button
              >
              <el-button
                @click="setRefundState(scope.row, '2')"
                type="text"
                size="small"
                >不同意</el-button
              >
            </span>
            <span v-if="scope.row.refund_state == 1">已完成</span>
            <span v-if="scope.row.refund_state == 2">
              <el-button
                @click="setRefundState(scope.row, '0')"
                type="text"
                size="small"
                >重置</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>

    <AgreeRefund
      v-if="agreeRefundVisible"
      ref="agreeRefund"
      @refreshDataList="search"
    ></AgreeRefund>
  </div>
</template>
<script>
import AgreeRefund from "./model/agree_refund.vue";
import OPERATE_API from "../../api/operate_api";
import * as XLSX from "xlsx";
import axios from "../../utils/axiosHelper";
import agentApi from "../../api/agent_api";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";

export default {
  components: {
    AgreeRefund,
  },
  data() {
    return {
      form: {
        cellphone: "",
        firstCityCode: "",
        refundState: "",
        user_number: "",
      },
      applyTime: [],
      refundTime: [],
      multiQuery: {},

      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      agreeRefundVisible: false,
    };
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  async mounted() {
    this.search();
  },
  computed: {
    ...mapState(["restrictedCityList"]),
    isShowBtn2() {
      let user = this.$store.state.user;
      if (user && user.isMaintenanceOrder == 2) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async search() {
      if (this.applyTime) {
        this.form.applyTimeStar = this.applyTime[0];
        this.form.applyTimeEnd = this.applyTime[1];
      } else {
        this.form.applyTimeStar = null;
        this.form.applyTimeEnd = null;
      }
      if (this.refundTime) {
        this.form.refundTimeStar = this.refundTime[0];
        this.form.refundTimeEnd = this.refundTime[1];
      } else {
        this.form.refundTimeStar = null;
        this.form.refundTimeEnd = null;
      }
      this.page_index = 1;
      this.loadDataTable();
    },
    async clear() {
      this.form = {
        cellphone: "",
        firstCityCode: "",
        refundState: "",
        user_number: "",
        applyTimeStar: "",
        applyTimeEnd: "",
        refundTimeStar: "",
        refundTimeEnd: "",
      };
      this.createTime = [];
      this.refundTime = [];
      this.search();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    loadDataTable() {
      let params = {
        ...this.form,
        ...this.multiQuery,
        source: "pc",
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/refund/apply/select/" + offset + "/" + limit, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.items;
          this.page_total = response.data.total;
          this.$nextTick(() => {
            this.$refs["dataTable"].doLayout();
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    setRefundState(row, refundState) {
      let msg = "";
      if (refundState == 2) {
        msg = "确认不同意给用户" + row.cellphone + "退款么？";
      } else if (refundState == 0) {
        msg = "确认重置该退款记录吗？";
      } else {
        this.$message({
          type: "warning",
          message: "参数有误",
        });
        return;
      }

      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.showLoading("加载中");
          axios
            .put(
              `/api/user/refund/apply/state/set/${row.id}/${refundState}`,
              null
            )
            .then((res) => {
              this.hideLoading();
              if (res.data.code != 0) {
                this.$message.error(res.data.msg);
                return;
              }
              this.search();
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
            })
            .catch(() => {
              this.hideLoading();
              this.$message.error("加载失败");
            });
        })
        .catch(() => {});
    },
    //同意退款页面
    agreeRefundShow(row) {
      this.agreeRefundVisible = true;
      this.$nextTick(() => {
        this.$refs.agreeRefund.init(row);
      });
    },
    getRefundStateName(refund_state) {
      if (refund_state == 0) {
        return "待退款";
      } else if (refund_state == 1) {
        return "已退款";
      } else if (refund_state == 2) {
        return "不同意";
      } else {
        return "";
      }
    },
    async exportData() {
      if (this.applyTime) {
        this.form.applyTimeStar = this.applyTime[0];
        this.form.applyTimeEnd = this.applyTime[1];
      } else {
        this.form.applyTimeStar = null;
        this.form.applyTimeEnd = null;
      }
      if (this.refundTime) {
        this.form.refundTimeStar = this.refundTime[0];
        this.form.refundTimeEnd = this.refundTime[1];
      } else {
        this.form.refundTimeStar = null;
        this.form.refundTimeEnd = null;
      }
      await operateAdd({
        operate_type: "1",
        operate_number: 24,
        operate_parameter: JSON.stringify({
          ...this.form,
          ...this.multiQuery,
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        ...this.form,
        ...this.multiQuery,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/refund/apply/list", {
          params: params,
        })
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.items);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "退款对账.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["用户编号"] = item.user_number;
        newObj["用户手机号"] = item.cellphone;
        newObj["所属城市"] = item.first_city_name;
        newObj["退款申请单号"] = item.apply_number;
        newObj["申请时间"] = item.create_time;
        newObj["申请信息"] = item.apply_content;
        newObj["退款金额"] = item.refund_amount;
        newObj["退款时间"] = item.refund_time;
        newObj["退款前"] = item.refund_early_amount;
        newObj["退款后"] = item.refund_current_amount;
        let refund_state = item.refund_state;
        newObj["状态"] = this.getRefundStateName(refund_state);

        newArray.push(newObj);
      });
      return newArray;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (
          column.property === "refund_amount" ||
          column.property === "refund_early_amount" ||
          column.property === "refund_current_amount"
        ) {
          let total = data.reduce((prev, cur) => {
            return prev + Number(cur[column.property] || 0);
          }, 0);

          sums[index] = Number(total).toFixed(2);
        } else {
          sums[index] = "";
          if (index === 0) {
            sums[index] = "合计";
          }
        }
      });

      return sums;
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
</style>