
<div class="org-role-user-container">
  <div class="org-role-user-left">
    <base-table
      ref="choose-table"
      :data="roleData"
      showIndex
      :height="height"
      :columns="tableColumns"
      noPage
      @on-row-click="rowClick"
      rowKey="id"
      :selectRows.sync="selectRole"
    ></base-table>
  </div>
  <div class="org-role-user-right">
    <role-user
      v-if="ready"
      :role_id="selectRoleId"
      :org_id="selectOrgId"
      type="orgRole"
      @close="close"
      @submit="submit"
    ></role-user>
  </div>
</div>
