
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="用户编号：">
        <el-input
          v-model.trim="form.userNumber"
          placeholder="请输入"
          clearable
          maxlength="10"
          oninput="value=value.replace(/[^0-9]/g,'')"
        />
      </el-form-item>
      <el-form-item label="用户手机号：">
        <el-input
          v-model.trim="form.cellphone"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
      <el-form-item label="城市">
        <el-select
          v-model="form.firstCityCode"
          placeholder="全国"
          collapse-tags
          filterable
          clearable
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <el-button style="margin-left: 15px" @click="exportData(FILE_VIEW_URL)"
        >导出</el-button
      >
    </el-row>
    <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="total_amount"
        label="交易总面额"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="viewRow(scope.row)">{{
            scope.row.total_amount
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="total_number"
        label="交易总单"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="viewRow(scope.row)">{{
            scope.row.total_number
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="qrcode_url"
        label="二维码"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.qrcode_url"
            style="width: 50px; height: 50px"
            :src="getQrcodeUrl(scope.row.qrcode_url)"
            @click="showWxappQrcodeView(scope.row)"
          >
          </el-image>
          <!--            <span v-if="scope.row.qrcode_url">{{FILE_VIEW_URL+scope.row.qrcode_url}}</span>-->
          <el-button
            v-if="!scope.row.qrcode_url"
            @click="generateWxAppQrcode(scope.row.user_id)"
            type="text"
            size="small"
            >生成</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <groundPushingModal
    :selectObj="selectObj"
    v-if="isGroundPushingModal"
    @close="isGroundPushingModal = false"
  />
  <el-dialog
    title="二维码"
    :close-on-click-modal="false"
    :visible.sync="wxappQrcodeViewVisible"
    width="500px"
    style="height: 100vh"
    center
  >
    <div style="text-align: center">
      <el-image style="align-content: center" :src="imgUrl"></el-image>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="downloadQrcode()">下载</el-button>
      <el-button @click="wxappQrcodeViewVisible = false">关闭</el-button>
    </span>
  </el-dialog>
</div>
