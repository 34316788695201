import axios from '../utils/axiosHelper.js'
import OBJECT_UTIL from "../assets/js/objectUtil";
const OPERATE_API = {};
OPERATE_API.getByCondition = (params) => {
    return axios.get('/api/equipment/', {params: OBJECT_UTIL.ObjectKeyConvert(params)})
}
OPERATE_API.getCityList = () => {
    return axios.post('/api/partner/areacorp/allList')
}

OPERATE_API.getPromotionSelectOpsList = (params) => {
    return axios.post('/api/partner/promotioninfo/getOpsList',params)
}

OPERATE_API.manualCoupon = (params) => {
    return axios.post('/api/partner/promotioninfo/manualCoupon',params)
}
export default OPERATE_API;