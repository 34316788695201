<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 10%">
      <el-form :inline="true">
        <el-form-item label="活动名称：">
          <el-input v-model.trim="form.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="城市">
          <el-select
            v-model="form.cityCode"
            placeholder="全部"
            collapse-tags
            clearable
            filterable
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="form.status"
            placeholder="全部"
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场景">
          <el-select
            v-model="form.billingType"
            placeholder="全部"
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in sceneList"
              :key="item.id"
              :label="item.billing_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <el-button
          style="margin-left: 15px"
          v-if="user.isMaintenanceOrder == 1"
          @click="addOrUpdateHandle('add')"
          >新增</el-button
        >
        <el-button style="margin-left: 15px" @click="exportData"
          >导出</el-button
        >
      </el-row>
      <el-table ref="tableData" :height="tableHeight" :data="dataTable">
        <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
        <el-table-column
          prop="name"
          label="活动名称"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="promotion_show_name"
          label="活动展示名称"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="billing_name"
          label="场景"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="start_time"
          label="活动时间"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="remaining_amount"
          label="剩余补贴金"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="person_limit_name"
          label="人数限制"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="grant_person_num"
          label="已发人数"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="cur_status"
          label="状态"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.cur_status === 1
                  ? "进行中"
                  : scope.row.cur_status === 2
                  ? "停用"
                  : scope.row.cur_status === 3
                  ? "已结束"
                  : "待开始"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              @click="addOrUpdateHandle('copy', scope.row)"
              type="text"
              size="small"
            >
              复制
            </el-button>
            <el-button
              v-if="scope.row.cur_status === 1 && user.isMaintenanceOrder == 1"
              @click="statusSwitch(scope.row)"
              type="text"
              size="small"
            >
              停用
            </el-button>
            <el-button
              v-if="scope.row.cur_status === 2 && user.isMaintenanceOrder == 1"
              @click="statusSwitch(scope.row)"
              type="text"
              size="small"
            >
              启用
            </el-button>
            <el-button
              v-if="scope.row.cur_status === 4 && user.isMaintenanceOrder == 1"
              @click="deleteHandle(scope.row)"
              type="text"
              size="small"
              >删除</el-button
            >
            <el-button
              v-if="scope.row.cur_status === 4 && user.isMaintenanceOrder == 1"
              @click="addOrUpdateHandle('edit', scope.row)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              @click="addOrUpdateHandle('details', scope.row)"
              type="text"
              size="small"
              >详情</el-button
            >
            <el-button
              v-if="scope.row.cur_status === 1 && user.isMaintenanceOrder == 1"
              @click="overTime(scope.row)"
              type="text"
              size="small"
              >延长时间</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <!-- 延长时间 -->
    <el-dialog
      :title="'延长有效期'"
      width="600px"
      :height="'30%'"
      :close-on-click-modal="false"
      :visible.sync="pickerShow"
    >
      <el-form
        :model="pickerForm"
        :rules="rules"
        ref="pickerForm"
        label-width="120px"
      >
        <el-form-item label="活动时间：" prop="end_time">
          <div class="flex">
            <el-date-picker
              v-model="pickerForm.start_time"
              style="width: 300px"
              type="datetime"
              placeholder="请选择开始时间"
              :disabled="true"
            >
            </el-date-picker>
            <span style="margin: 0 10px">至</span>
            <el-date-picker
              v-model="pickerForm.end_time"
              style="width: 300px"
              type="datetime"
              placeholder="请选择结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
          <!-- <el-date-picker v-model="dataForm.create_time" type="datetimerange" style="width: 400px"
                    value-format="yyyy-MM-dd HH:mm:ss" :clearable="true" :default-time="['00:00:00', '23:59:59']"
                    range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
                    @change="timeChange"></el-date-picker> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-foote r">
        <el-button type="primary" @click="pickerFormSubmit()">确定</el-button>
        <el-button @click="pickerShow = false">取消</el-button>
      </span>
    </el-dialog>
    <activityAddModal
      ref="addOrUpdate"
      :editState="editState"
      @refreshDataList="search"
    >
    </activityAddModal>
    <activityDetailsModal ref="detailsForm"> </activityDetailsModal>
  </div>
</template>
<script>
import OPERATE_API from "../../api/operate_api";
import activityAddModal from "./model/activity-add-modal.vue";
import activityDetailsModal from "./model/activity-details-modal.vue";
import * as XLSX from "xlsx";
import agentApi from "../../api/agent_api";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";

export default {
  components: {
    activityAddModal,
    activityDetailsModal,
  },
  data() {
    const that = this;
    return {
      sceneList: [],
      cityList: [],
      createTime: [],
      multiQuery: {},
      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      addOrUpdateVisible: false,
      pickerShow: false,
      form: {
        name: "",
        orderType: "",
        status: "",
        billingType: "",
        cityCode: "",
      },
      pickerForm: {
        id: "",
        start_time: "",
        end_time: "",
      },
      scopeList: [
        {
          label: "通用",
          value: 0,
        },
        {
          label: "上门充电",
          value: 1,
        },
        {
          label: "应急补电",
          value: 2,
        },
      ],
      statusList: [
        {
          label: "进行中",
          value: 1,
        },
        {
          label: "停用",
          value: 2,
        },
        {
          label: "已结束",
          value: 3,
        },
        {
          label: "待开始",
          value: 4,
        },
      ],
      rules: {
        end_time: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: ["blur", "change"],
          },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() <
            new Date(that.pickerForm.start_time || new Date()).getTime()
          );
        },
      },
    };
  },
  computed: {
    ...mapState(["user", "restrictedCityList"]),
  },
  async created() {},
  mounted() {
    this.getSceneList();
    this.getSceneListDetails();
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.search();
  },
  methods: {
    // 获取场景
    getSceneList() {
      this.axiosHelper
        .get("/api/activity/list/billing/type", { params: { status: 1 } })
        .then((res) => {
          this.hideLoading();
          this.$refs.addOrUpdate.sceneList = res.data.data;
          this.sceneList = res.data.data;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    getSceneListDetails() {
      this.axiosHelper
        .get("/api/activity/list/billing/type", { params: {} })
        .then((res) => {
          this.hideLoading();
          this.$refs.detailsForm.sceneList = res.data.data;
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    filter() {
      this.filterVisible = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async search() {
      if (this.createTime) {
        this.form.createTimeStart = this.createTime[0];
        this.form.createTimeEnd = this.createTime[1];
      } else {
        this.form.createTimeStart = null;
        this.form.createTimeEnd = null;
      }
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {
        name: "",
        orderType: "",
        status: "",
        billingType: "",
        cityCode: "",
      };
      this.createTime = [];
      this.page_index = 1;
      this.loadDataTable();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    // 新增 / 修改 / 详情 / 复制
    addOrUpdateHandle(editState, row) {
      this.editState = editState;
      this.$refs[
        editState === "details" ? "detailsForm" : "addOrUpdate"
      ].visible = true;
      this.$refs[
        editState === "details" ? "detailsForm" : "addOrUpdate"
      ].formLoading = true;
      switch (editState) {
        case "add":
          this.$nextTick(() => {
            this.$refs.addOrUpdate.init(row);
            this.$refs[
              editState === "details" ? "detailsForm" : "addOrUpdate"
            ].formLoading = false;
          });
          break;
        case "details":
        case "edit":
        case "copy":
          this.axiosHelper
            .get(`/api/activity/get/detail/${row.id}`)
            .then((res) => {
              this.$nextTick(() => {
                let form = JSON.parse(JSON.stringify(res.data.data));
                this.$refs[
                  editState === "details" ? "detailsForm" : "addOrUpdate"
                ].init(form);
                this.$refs[
                  editState === "details" ? "detailsForm" : "addOrUpdate"
                ].formLoading = false;
              });
            })
            .catch(() => {
              this.$refs[
                editState === "details" ? "detailsForm" : "addOrUpdate"
              ].formLoading = false;
              this.$message.error("加载失败");
            });
          break;
      }
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        ...this.form,
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/api/activity/query/${offset}/${limit}`, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          let array = response.data.data.items || [];
          array.forEach((item) => {
            item.person_limit_name =
              item.person_limit == -1 ? "否" : item.person_limit;
          });
          this.dataTable = array;
          this.page_total = response.data.data.total;
          //   this.$nextTick(() => {
          //     this.$refs["dataTable"].doLayout();
          //   });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 12,
        operate_parameter: JSON.stringify(this.form),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        ...this.form,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/activity/list", {
          params: params,
        })
        .then((res) => {
          this.hideLoading();
          let array = res.data.data || [];
          array.forEach((item) => {
            item.person_limit_name =
              item.person_limit == -1 ? "否" : item.person_limit;
          });
          let newData = this.getHeadersAndbody(array);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "活动管理.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["活动名称"] = item.name;
        newObj["活动展示名称"] = item.promotion_show_name;
        newObj["场景"] = !item.billing_type
          ? "新客见面有利"
          : item.billing_type === 1
          ? "老带新"
          : item.billing_type === 3
          ? "充电送券"
          : "充值活动";
        newObj["活动时间"] = `${item.start_time} ~ ${item.end_time}`;
        newObj["城市"] = item.city_name;
        newObj["剩余补贴金"] = item.remaining_amount;
        newObj["人数限制"] = item.person_limit_name;
        newObj["已发人数"] = item.grant_person_num;
        newObj["创建时间"] = item.create_time;
        newObj["状态"] =
          item.cur_status === 1
            ? "进行中"
            : item.cur_status === 2
            ? "停用"
            : item.cur_status === 3
            ? "已结束"
            : "待开始";
        newArray.push(newObj);
      });
      return newArray;
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    // 删除
    async deleteHandle(row) {
      const confirmResult = await this.$confirm(`确定删除该活动么?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .delete("/api/activity/del/" + row.id, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    async statusSwitch(row) {
      let str =
        row.cur_status === 2
          ? "确认启用该活动么？"
          : "目前该活动正在进行中哦，确认停用该活动么？";
      const confirmResult = await this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .put(`/api/activity/set/status/${row.id}/${!row.status ? 1 : 0}`, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    overTime(row) {
      this.pickerShow = true;
      this.pickerForm.start_time = row.start_time;
      this.pickerForm.id = row.id;
    },
    pickerFormSubmit() {
      this.$refs["pickerForm"].validate(async (valid) => {
        if (!valid) return;
        const confirmResult = await this.$confirm(
          `确定修改该活动场景的时间么?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => {
          return err;
        });
        if (confirmResult != "confirm") return;
        this.showLoading("加载中");
        this.axiosHelper
          .put(
            `/api/activity/set/endTime/${this.pickerForm.id}?endTime=${this.pickerForm.end_time}`
          )
          .then((res) => {
            this.hideLoading();
            if (res.data.code != 0) {
              this.$message.error(res.data.msg);
              return;
            }
            this.search();
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
            });
            this.pickerShow = false;
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error("加载失败");
          });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}

.flex {
  display: flex;
  align-items: center;
}

.dialog-foote {
  display: flex;
  justify-content: center;
}
</style>
