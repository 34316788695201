import axios from '../utils/axiosHelper.js'
import OBJECT_UTIL from "../assets/js/objectUtil.js";
const agentApi = {};

agentApi.getByCondition = (params) => {
    return axios.post('/api/partner/agentlist/list', params)
}

agentApi.queryByCondition = (params) => {
    return axios.post('/api/partner/agentlist/queryByCondition', params)
}

//获取代理商配置
agentApi.getConfig = () => {
    return axios.get('/api/partner/areacorp/getConfig')
}
agentApi.saveOrupdate = (saveOrUpdate,params) => {
    let agentListStr=JSON.stringify(params);
     return axios.post(`/api/partner/agentlist/`+saveOrUpdate,{"reqdata": agentListStr})
     // return axios.post(`/api/partner/agentlist/`+saveOrUpdate,{params:OBJECT_UTIL.jsonToUnderline(params)})
     // return axios.post(`/api/partner/agentlist/`+saveOrUpdate,params)
 }
agentApi.areacorp_saveOrUpdate = (saveOrUpdate,params) => {
//    let str=JSON.stringify(params);
    return axios.post(`/api/partner/areacorp/`+saveOrUpdate,params)
    // return axios.post(`/api/partner/agentlist/`+saveOrUpdate,{params:OBJECT_UTIL.jsonToUnderline(params)})
    // return axios.post(`/api/partner/agentlist/`+saveOrUpdate,params)
}
agentApi.deleteByIds = (ids) => {
    // let agentListStr=JSON.stringify(ids);
    // return axios.delete(`/api/partner/agentlist/delete`,{"reqdata": ids+""})
    return axios.post(`/api/partner/agentlist/delete`,{"reqdata": ids+""})
}
agentApi.queryByCityCode = (cityCode) => {
    // let agentListStr=JSON.stringify(ids);
    // return axios.delete(`/api/partner/agentlist/delete`,{"reqdata": ids+""})
    return axios.get(`/api/partner/agentlist/queryByCityCode`,{params:cityCode})
}

agentApi.settleOrder = (status,param) => {
    return axios.put(`/api/order/batch/set/agent/settle/${status}/`,param)
}
// agentApi.update = (params) => {
//     return axios.put(`/api/equipment`, params)
// }
// agentApi.add = (params) => {
//     return axios.post(`/api/equipment`, params)
// }
// agentApi.getById = (id) => {
//     return axios.get(`/api/partner/agentlist/${id}`)
// }
// agentApi.deleteByIds = (ids) => {
//     return axios.delete(`/api/equipment/${ids}`)
// }
// agentApi.checkNo = (params) => {
//     return axios.get(`/api/equipment/check`, {params: params})
// }
// agentApi.exportData = (params) => {
//     return axios.get('/api/equipment/export_data', {params: OBJECT_UTIL.ObjectKeyConvert(params)})
// }
export default agentApi;