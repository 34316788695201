export const recommend_type_list = [
  {
    value: null,
    label: "全部",
  },
  {
    value: "11",
    label: "个人",
  },
  {
    value: "12",
    label: "渠道商",
  },
  {
    value: "13",
    label: "小蜜蜂地推",
  },
  {
    value: "14",
    label: "B端",
  },
];
