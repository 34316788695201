/*
 * @Author: ljf
 * @Date: 2022-05-30 15:10:42
 * @LastEditors: ljf
 * @LastEditTime: 2022-06-22 17:04:36
 * @FilePath: \lxm-admin\src\api\system\menu-api.js
 * @Description: 
 * 
 */
import axios from "utils/axiosHelper";
import { Message } from 'element-ui';

export const getMenu = (menuType) => {
  return axios.get(`/api/menu/bar/${menuType}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      }
    })
}
export const getMenuTree = (menuType) => {
  return axios.get(`/api/menu/tree/${menuType}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      }
    })
}
export const addMenu = ((MenuDto) => {
  return axios.post(`/api/menu`,
    MenuDto
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data
      }
    })
})
export const updateMenu = ((MenuDto) => {
  return axios.put(`/api/menu`,
    MenuDto
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data
      }
    })
})


export const deleteMenu = (menu_id) => {
  return axios.delete(`/api/menu/${menu_id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      }
    })
}

export const checkMenu = (menu) => {
  return axios.post(`/api/menu/check`,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    },
    {
      menu
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data
      }
    })
}