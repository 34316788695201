
<el-dialog
  class="showAll_dialog"
  :title="editState === 'add' ? '新增' : '编辑'"
  width="50rem"
  :height="'80%'"
  :max-height="'37.5rem'"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <el-form
    :model="dataForm"
    style="margin-top: 1.25rem"
    :rules="dataRule"
    ref="dataForm"
    label-width="10.625rem"
    v-loading="formLoading"
  >
    <el-form-item label="用户手机号：" prop="cellphone">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入用户手机号"
        v-model="dataForm.cellphone"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="城市：" prop="city_code">
      <el-select
        v-model="dataForm.city_code"
        placeholder="请选择城市"
        @change="cityChange"
        collapse-tags
        clearable
      >
        <el-option
          v-for="item in cityList"
          :key="item.cityCode"
          :label="item.cityName"
          :value="item.cityCode"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="活动名称：" prop="promotion_id">
      <el-select
        v-model="dataForm.promotion_id"
        placeholder="请选择活动"
        @change="activityChange"
        collapse-tags
        clearable
      >
        <el-option
          v-for="item in promotionOpsList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="是否挂靠活动优惠：" prop="isCoupon">
      <el-select
        v-model="dataForm.isCoupon"
        placeholder="请选择是否挂靠活动优惠"
        :disabled="!dataForm.promotion_id"
        @change="couponChange"
        collapse-tags
        clearable
      >
        <el-option label="否" :value="0"></el-option>
        <el-option label="是" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <!-- 2 -->
    <div v-if="billing_rule_type === 3 && dataForm.isCoupon === 1">
      <el-form-item label="充值档位" prop="recharge">
        <el-select
          v-model="dataForm.recharge"
          placeholder="请选择充值档位"
          @change="rechargeChange"
          collapse-tags
          clearable
        >
          <el-option
            v-for="(item, index) in dataForm.recharge_rule_list"
            :key="index"
            :label="item.recharge"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div v-for="(item, index) in dataForm.activityList" :key="index">
        <div class="flex">
          <el-form-item label="补贴类型：" style="width: 100%">
            <el-select
              v-model="item.subsidy_type"
              :disabled="statusDisabled"
              placeholder="请选择补贴类型"
              @change="subsidyChange($event, index)"
              collapse-tags
            >
              <el-option label="充电度数" :value="2"> </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="度数：">
          <el-input
            v-model.trim="item.degree"
            :readonly="statusDisabled"
            placeholder="请输入度数"
            clearable
            maxlength="6"
            oninput="value=value.replace(/[^0-9]/g,'')"
          />
        </el-form-item>
        <!-- <div v-if="dataForm.activityList.length">
                      <div class="flex" v-for="(val, valIndex) in item.coupon_quote_list" :key="valIndex">
                          <el-form-item label="券：">
                              <el-select v-model="val.coupon_id" :disabled="statusDisabled" placeholder="请选择券"
                                  collapse-tags clearable>
                                  <el-option v-for="couponVal in couponList" :key="couponVal.id"
                                      :label="couponVal.name" :value="couponVal.id">
                                  </el-option>
                              </el-select>
                          </el-form-item>
                          <el-form-item label="张数：" label-width="70px">
                              <el-input-number v-model.number="val.num" :disabled="statusDisabled"
                                  style="width: 5.625rem;" :min="1" :max="99" controls-position="right"
                                  @keyup.native="UpNumber" @keydown.native="UpNumber" label="描述文字"></el-input-number>
                          </el-form-item>
                          <el-form-item label="有效期：" label-width="90px">
                              <div class="flex">
                                  <el-input v-model.trim="val.valid_day" :readonly="statusDisabled"
                                      style="width: 6.25rem;" placeholder="有效期" clearable maxlength="6"
                                      oninput="value=value.replace(/[^0-9]/g,'')" />
                                  <span class="marleft10">天</span>
                              </div>
                          </el-form-item>
                      </div>
                  </div> -->
      </div>
    </div>
    <!-- 1 -->
    <div v-else>
      <div
        v-for="(item, index) in dataForm.activity_subsidy_type_list"
        :key="index"
      >
        <div class="flex">
          <el-form-item
            label="补贴类型："
            style="width: 100%"
            :prop="`activity_subsidy_type_list.${index}.subsidy_type`"
            :rules="[
              {
                required: true,
                message: '请选择补贴类型',
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <el-select
              v-model="item.subsidy_type"
              :disabled="statusDisabled"
              placeholder="请选择补贴类型"
              @change="subsidyChange($event, index)"
              collapse-tags
            >
              <el-option label="充电度数" :value="2"> </el-option>
            </el-select>
          </el-form-item>
          <!-- 加减按钮 -->
          <!-- <div v-if="!statusDisabled" class="flex" style="margin-left: .625rem; margin-bottom: 1.125rem;">
                          <img class="icon" @click="formArrHandleChange(0)" src="@/assets/img/icon-add.png" alt="">
                          <img v-if="dataForm.activity_subsidy_type_list.length > 1"
                              @click="formArrHandleChange(1, index)" class="icon" src="@/assets/img/icon-delete.png"
                              alt="">
                      </div> -->
        </div>
        <el-form-item
          label="度数："
          :prop="`activity_subsidy_type_list.${index}.degree`"
          :rules="[
            {
              required: true,
              message: '请输入度数',
              trigger: ['change', 'blur'],
            },
            {
              pattern: /^\d{1,6}(\.\d{1,2})?$/,
              message: '只能输入数字且最多6位，小数点最多2位',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input
            v-model.trim="item.degree"
            :readonly="statusDisabled"
            placeholder="请输入度数"
            clearable
            maxlength="9"
          />
        </el-form-item>
        <!-- <div v-if="item.coupon_quote_list.length">
                      <div class="flex" v-for="(val, valIndex) in item.coupon_quote_list" :key="valIndex">
                          <el-form-item label="券："
                              :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.coupon_id`"
                              :rules="[{ required: true, message: '请选择券', trigger: ['change', 'blur']}]">
                              <el-select v-model="val.coupon_id" :disabled="statusDisabled" placeholder="请选择券"
                                  collapse-tags clearable>
                                  <el-option v-for="couponVal in couponList" :key="couponVal.id"
                                      :label="couponVal.name" :value="couponVal.id">
                                  </el-option>
                              </el-select>
                          </el-form-item>
                          <el-form-item label="张数："
                              :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.num`"
                              label-width="4.375rem"
                              :rules="[{ required: true, message: '请输入张数', trigger: ['change', 'blur']}]">
                              <el-input-number v-model.number="val.num" :disabled="statusDisabled"
                                  style="width: 5.625rem;" :min="1" :max="99" controls-position="right"
                                  @keyup.native="UpNumber" @keydown.native="UpNumber" label="描述文字"></el-input-number>
                          </el-form-item>
                          <el-form-item label="有效期："
                              :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.valid_day`"
                              label-width="5.625rem"
                              :rules="[{ required: true, message: '请输入有效期', trigger: ['change', 'blur']}]">
                              <div class="flex">
                                  <el-input v-model.trim="val.valid_day" :readonly="statusDisabled"
                                      style="width: 6.25rem;" placeholder="有效期" clearable maxlength="6"
                                      oninput="value=value.replace(/[^0-9]/g,'')" />
                                  <span class="marleft10">天</span>
                              </div>
                          </el-form-item>
                          加减按钮
                          <div v-if="!statusDisabled" class="flex"
                              style="margin-left: .625rem; margin-bottom: 1.125rem;">
                              <img class="icon" @click="formArrHandleChange(2, index)" src="@/assets/img/icon-add.png"
                                  alt="">
                              <img v-if="item.coupon_quote_list.length > 1 "
                                  @click="formArrHandleChange(3, index, valIndex)" class="icon"
                                  src="@/assets/img/icon-delete.png" alt="">
                          </div>
                      </div>
                  </div> -->
      </div>
    </div>
    <el-form-item label="备注：">
      <el-input
        type="textarea"
        :rows="5"
        placeholder="备注信息填写位置"
        v-model="dataForm.remark"
      >
      </el-input>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-foote r">
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    <el-button @click="visible = false">取消</el-button>
  </span>
</el-dialog>
