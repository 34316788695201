
<el-dialog title="高级搜索" v-dialogDrag :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
    <el-form :model="dataForm" ref="dataForm" label-width="125px">
        <el-row>
            <el-col :span="12">
                <el-form-item label="微信号：">
                    <el-input v-model.trim="dataForm.wechat_no" clearable />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="微信ID：">
                    <el-input v-model.trim="dataForm.open_id" clearable />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="联系方式：">
                    <el-input v-model.trim="dataForm.cellphone" clearable />
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="clear">清除条件</el-button>
        <el-button type="default" @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
    </div>
</el-dialog>
