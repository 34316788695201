
<el-dialog :title="!dataForm.id ? '新增补贴' : '修改'" :close-on-click-modal="false" :visible.sync="visible" width="40%">
  <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" >
    <el-form-item label="城市" prop="cityCode">
      <el-select v-model="dataForm.cityCode" placeholder="请选择城市" @change="cityOpsChange">
        <el-option
          v-for="item in cityList"
          :key="item.cityCode"
          :label="item.cityName"
          :value="item.cityCode">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="代理商名称" disabled>
      <el-input v-model="agent.agentName" disabled></el-input>
    </el-form-item>
    <el-form-item label="代理商手机号" >
      <el-input v-model="agent.agentPhone"  disabled></el-input>
    </el-form-item>
    <el-form-item label="申请人手机号" prop="applyPhone">
      <el-input v-model="dataForm.applyPhone" placeholder="申请人手机号"></el-input>
    </el-form-item>
    <el-form-item label="补贴金额" prop="amount">
      <el-input v-model="dataForm.amount" placeholder="补贴金额"></el-input>
    </el-form-item>
    <el-form-item label="审批编号" prop="approvalNum">
      <el-input v-model="dataForm.approvalNum" placeholder="审批编号"></el-input>
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input type="textarea" v-model="dataForm.remark" placeholder="备注"></el-input>
    </el-form-item>
  </el-form>
  <span slot="footer" >
    <el-button @click="visible = false">取消</el-button>
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
  </span>
</el-dialog>
