<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 10%">
      <el-form :inline="true">
        <el-form-item label="城市">
          <el-select
            v-model="form.cityCode"
            placeholder="全部"
            collapse-tags
            clearable
            filterable
          >
            <el-option
              v-for="item in cityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="form.status"
            placeholder="全部"
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <el-button style="margin-left: 15px" @click="addOrUpdateHandle('add')"
          >新增</el-button
        >
      </el-row>
      <el-table ref="tableData" :height="tableHeight" :data="dataTable">
        <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="start_time"
          label="展示时间"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.status === 1
                  ? "进行中"
                  : scope.row.status === 2
                  ? "停用"
                  : scope.row.status === 3
                  ? "已结束"
                  : "待开始"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status === 1"
              @click="statusSwitch(scope.row, 0)"
              type="text"
              size="small"
              >停用</el-button
            >
            <el-button
              v-if="scope.row.status === 2"
              @click="statusSwitch(scope.row, 1)"
              type="text"
              size="small"
              >启用</el-button
            >
            <el-button
              v-if="scope.row.status === 4"
              @click="deleteHandle(scope.row)"
              type="text"
              size="small"
              >删除</el-button
            >
            <el-button
              v-if="scope.row.status === 4 || scope.row.status === 1"
              @click="addOrUpdateHandle('edit', scope.row)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              @click="addOrUpdateHandle('details', scope.row)"
              type="text"
              size="small"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
    <activityPageAddModal
      ref="addOrUpdate"
      :editState="editState"
      @refreshDataList="search"
    ></activityPageAddModal>
    <activityPageView
      v-if="activityPageViewVisible"
      ref="activityPageView"
    ></activityPageView>
  </div>
</template>
<script>
import { mapState } from "vuex";
import activityPageAddModal from "./model/activity-page-add-modal.vue";
import activityPageView from "./model/activity-page-view.vue";

export default {
  components: {
    activityPageAddModal,
    activityPageView,
  },
  data() {
    const that = this;
    return {
      createTime: [],
      multiQuery: {},
      dataTable: [],
      selection: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      tableHeight: "70vh",
      editState: "add",
      addOrUpdateVisible: false,
      pickerShow: false,
      activityPageViewVisible: false,
      form: {
        status: "",
        cityCode: "",
      },
      statusList: [
        {
          label: "进行中",
          value: 1,
        },
        {
          label: "停用",
          value: 2,
        },
        {
          label: "已结束",
          value: 3,
        },
        {
          label: "待开始",
          value: 4,
        },
      ],
      rules: {
        end_time: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: ["blur", "change"],
          },
        ],
      },
      file_view_rul: this.FILE_VIEW_ROOT_RUL,
      cityList: [],
    };
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.getCityList();
    await this.search();
  },
  mounted() {},
  methods: {
    getCityList() {
      this.axiosHelper
        .post(`/api/partner/areacorp/allList`, {})
        .then((res) => {
          this.cityList = res.data.cityList;
        })
        .catch(() => {
          this.$message.error("加载失败");
        });
    },
    filter() {
      this.filterVisible = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    clear() {
      this.form = {
        name: "",
        orderType: "",
        status: "",
        cityCode: "",
      };
      this.createTime = [];
      this.page_index = 1;
      this.loadDataTable();
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    //显示图片
    showActivityPageView(row, index) {
      this.activityPageViewVisible = true;
      this.$nextTick(() => {
        this.$refs.activityPageView.init(row, index, this.file_view_rul);
      });
    },
    getPicUrl(url) {
      return this.file_view_rul + url;
    },
    // 新增 / 修改 / 详情
    addOrUpdateHandle(editState, row) {
      this.editState = editState;
      this.$refs["addOrUpdate"].visible = true;
      this.$refs["addOrUpdate"].formLoading = true;
      switch (editState) {
        case "add":
        case "edit":
        case "details":
          this.$nextTick(() => {
            this.$refs.addOrUpdate.init(row);
            this.$refs["addOrUpdate"].formLoading = false;
          });
          break;
      }
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    loadDataTable() {
      let params = {
        ...this.form,
      };
      let limit = this.page_size;
      let offset = this.page_size * (this.page_index - 1);
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/api/activity/page/query/${offset}/${limit}`, {
          params: params,
        })
        .then((response) => {
          this.hideLoading();
          this.dataTable = response.data.data.items;
          this.page_total = response.data.data.total;
          //   this.$nextTick(() => {
          //     this.$refs["dataTable"].doLayout();
          //   });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },

    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    // 删除
    async deleteHandle(row) {
      const confirmResult = await this.$confirm(`确定删除该活动页么?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .delete("/api/activity/page/del/" + row.id, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    async statusSwitch(row, status) {
      let str =
        row.status === 2
          ? "确认启用该活动页么？"
          : "目前该活动页正在展示中，确认停用么？";
      const confirmResult = await this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => {
        return err;
      });
      if (confirmResult != "confirm") {
        return;
      }
      this.showLoading("加载中");
      this.axiosHelper
        .put(`/api/activity/page/set/status/${row.id}/${status}`, {})
        .then((res) => {
          this.hideLoading();
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            return;
          }
          this.search();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}

.el-col {
  display: flex;
  align-items: center;
}

.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}

.box-card {
  margin-top: 15px;
}

.flex {
  display: flex;
  align-items: center;
}

.dialog-foote {
  display: flex;
  justify-content: center;
}
</style>