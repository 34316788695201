
<el-dialog title="活动页" :close-on-click-modal="false" :visible.sync="visible" width="20%" center>
    <div style="text-align: center;">
        <el-image style="width: 200px; height: 200px;align-content: center" :src="imgUrl"></el-image>
    </div>
    <span style="display: block; text-align: center;">{{ city_name }}</span>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadPic()">下载</el-button>
        <el-button @click="visible = false">关闭</el-button>
    </span>
</el-dialog>
