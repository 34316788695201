
<div>
  <div class="block-view-content-2">
    <span class="span-title">{{ lineChartName }}</span>
    <div style="float: right">
      <el-button
        v-for="(item, index) in dateSpans"
        :key="index"
        @click="getChartData(item)"
        >{{ item.data_name }}</el-button
      >
      <el-date-picker
        style="margin-left: 10px"
        :default-time="['00:00:00', '23:59:59']"
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="dateRange"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="dateChange"
      />
    </div>
    <div :id="lineChartId" class="line-chart"></div>
  </div>
</div>
