<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="140px"
    >
      <!-- <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px"> -->
      <!-- <el-form-item label="代理商编号" prop="sn">
        <el-input v-model="dataForm.sn" placeholder="代理商编号，格式：AG-202309-1001，10表示城市id，01表示代理商数量，依次递增"></el-input>
      </el-form-item> -->
      <el-form-item label="代理商名称" prop="agentName">
        <el-input
          v-model="dataForm.agentName"
          placeholder="代理商名称"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="代理商名称首字母" prop="agentNameChar" >
        <el-input v-model="dataForm.agentNameChar" placeholder="代理商名称首字母"></el-input>
      </el-form-item> -->
      <el-form-item label="信用代码" prop="creditCode">
        <el-input
          v-model="dataForm.creditCode"
          placeholder="信用代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="城市行政区划编码" prop="cityCode">
        <el-input
          v-model="dataForm.cityCode"
          placeholder="城市行政区划编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="代理商联系人" prop="callName">
        <el-input
          v-model="dataForm.callName"
          placeholder="代理商联系人"
        ></el-input>
      </el-form-item>
      <el-form-item label="代理商联系电话" prop="callPhone">
        <el-input
          v-model="dataForm.callPhone"
          placeholder="代理商联系电话"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="数据有效状态" prop="status" >
        <el-input v-model="dataForm.status" placeholder="数据有效状态（0无效；1有效）"></el-input>
      </el-form-item> -->
      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          v-model="dataForm.remark"
          placeholder="备注"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="创建人id" prop="createId">
        <el-input v-model="dataForm.createId" placeholder="创建人id"></el-input>
      </el-form-item>
      <el-form-item label="写入时间 YYYY-MM-DD HH24:MM:SS" prop="createTime">
        <el-input v-model="dataForm.createTime" placeholder="写入时间 YYYY-MM-DD HH24:MM:SS"></el-input>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import agentApi from "../../api/agent_api";
export default {
  // name: "AddOrUpdate",
  data() {
    return {
      visible: false,
      dataForm: {
        id: undefined,
        agentNo: "",
        agentName: "",
        agentNameChar: "",
        creditCode: "",
        cityCode: "",
        callName: "",
        callPhone: "",
        // status: '',
        remark: "",
        // createId: '',
        // createTime: ''
      },
      dataRule: {
        // sn: [
        //   { required: true, message: '代理商编号，格式：AG-202309-1001，10表示城市id，01表示代理商数量，依次递增不能为空', trigger: 'blur' }
        // ],
        agentName: [
          { required: true, message: "代理商名称不能为空", trigger: "blur" },
        ],
        // agentNameChar: [
        //   { required: true, message: '代理商名称首字母不能为空', trigger: 'blur' }
        // ],
        // creditCode: [
        //   { required: true, message: '信用代码不能为空', trigger: 'blur' }
        // ],
        cityCode: [
          { required: true, message: "城市行政区划编码", trigger: "blur" },
        ],
        // callName: [
        //   { required: true, message: '代理商联系人不能为空', trigger: 'blur' }
        // ],
        callPhone: [
          {
            required: true,
            message: "代理商联系电话不能为空,且和代理商登录的号码一致",
            trigger: "blur",
          },
        ],
        // status: [
        //   { required: true, message: '数据有效状态（0无效；1有效）不能为空', trigger: 'blur' }
        // ],
        // remark: [
        //   { required: true, message: '备注不能为空', trigger: 'blur' }
        // ],
        // createId: [
        //   { required: true, message: '创建人id不能为空', trigger: 'blur' }
        // ],
        // createTime: [
        //   { required: true, message: '写入时间 YYYY-MM-DD HH24:MM:SS不能为空', trigger: 'blur' }
        // ]
      },
    };
  },
  methods: {
    init(row) {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (row) {
          this.dataForm.id = row.id;
          this.dataForm.agentNo = row.agentNo;
          this.dataForm.agentName = row.agent_name;
          this.dataForm.agentNameChar = row.agent_name_char;
          this.dataForm.creditCode = row.credit_code;
          this.dataForm.cityCode = row.city_code;
          this.dataForm.callName = row.call_name;
          this.dataForm.callPhone = row.call_phone;
          // this.dataForm.status = row.status
          this.dataForm.remark = row.remark;
        } else {
          this.dataForm.id = undefined;
          this.dataForm.agentNo = "";
          this.dataForm.agentName = "";
          this.dataForm.agentNameChar = "";
          this.dataForm.creditCode = "";
          this.dataForm.cityCode = "";
          this.dataForm.callName = "";
          this.dataForm.callPhone = "";
          // this.dataForm.status = row.status
          this.dataForm.remark = "";
        }
      });
      // this.dataForm.id = row.id || 0
      //todo

      // this.dataForm.createId = row.createId
      // this.dataForm.createTime = row.createTime
      // this.dataForm=JSON.parse(JSON.stringify(row))
      // this.$nextTick(() => {
      //   this.$refs['dataForm'].resetFields()
      //   if (this.dataForm.id) {

      //     this.$http({
      //       url: this.$http.adornUrl(`/api/partner/agentlist/info/${this.dataForm.id}`),
      //       method: 'get',
      //       params: this.$http.adornParams()
      //     }).then(({ data }) => {
      //       if (data && data.code === 0) {
      //         this.dataForm.sn = data.agentList.sn
      //         this.dataForm.agentName = data.agentList.agentName
      //         this.dataForm.agentNameChar = data.agentList.agentNameChar
      //         this.dataForm.creditCode = data.agentList.creditCode
      //         this.dataForm.cityId = data.agentList.cityId
      //         this.dataForm.callName = data.agentList.callName
      //         this.dataForm.callPhone = data.agentList.callPhone
      //         this.dataForm.status = data.agentList.status
      //         this.dataForm.remark = data.agentList.remark
      //         this.dataForm.createId = data.agentList.createId
      //         this.dataForm.createTime = data.agentList.createTime
      //       }
      //     })
      //   }
      // })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (!valid) return;
        this.showLoading("提交中");
        let res = await agentApi
          .saveOrupdate(this.dataForm.id ? "update" : "save", this.dataForm)
          .finally(() => {
            this.hideLoading();
          });
        if (res.data.code != 0) {
          this.$message.error(res.data.msg);
          return;
        }
        this.visible = false;
        this.$emit("refreshDataList");
        this.$message({
          message: "操作成功",
          type: "success",
          duration: 1500,
          // onClose: () => {
          //   this.visible = false
          //   this.$emit('refreshDataList')
          // }
        });
        // this.$http({
        //   url: this.$http.adornUrl(`/api/partner/agentlist/${!this.dataForm.id ? 'save' : 'update'}`),
        //   method: 'post',
        //   data: this.$http.adornData({
        //     'id': this.dataForm.id || undefined,
        //     'sn': this.dataForm.sn,
        //     'agentName': this.dataForm.agentName,
        //     'agentNameChar': this.dataForm.agentNameChar,
        //     'creditCode': this.dataForm.creditCode,
        //     'cityId': this.dataForm.cityId,
        //     'callName': this.dataForm.callName,
        //     'callPhone': this.dataForm.callPhone,
        //     'status': this.dataForm.status,
        //     'remark': this.dataForm.remark,
        //     'createId': this.dataForm.createId,
        //     'createTime': this.dataForm.createTime
        //   })
        // }).then(({ data }) => {
        //   if (data && data.code === 0) {
        //     this.$message({
        //       message: '操作成功',
        //       type: 'success',
        //       duration: 1500,
        //       onClose: () => {
        //         this.visible = false
        //         this.$emit('refreshDataList')
        //       }
        //     })
        //   } else {
        //     this.$message.error(data.msg)
        //   }
        // })
      });
    },
  },
};
</script>
