<template>
    <div>
        <el-card class="box-card" style="height: 10%">
            <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
                <el-form-item label="代理商名称首字母">
                    <el-input v-model="dataForm.agent_name_char" placeholder="代理商名称首字母" clearable></el-input>
                </el-form-item>
                <el-button style="margin-right: 15px" type="primary" @click="getDataList()">查询</el-button>
                <el-button style="margin-right: 15px" type="default" @click="clear()">重置</el-button>
                <el-button style="margin-right: 15px" type="primary" @click="addOrUpdateHandle()">新增</el-button>
            </el-form>
        </el-card>
        <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
            style="width: 100%;">
            <!-- <el-table-column type="selection" header-align="center" align="center" width="50">
      </el-table-column> -->
            <!-- <el-table-column prop="id" header-align="center" align="center" label="">
      </el-table-column> -->
            <!-- <el-table-column prop="sn" header-align="center" align="center" label="代理商编号">
      </el-table-column>  -->
            <!-- label="代理商编号，格式：AG-202309-1001，10表示城市id，01表示代理商数量，依次递增"> -->
            <el-table-column prop="agent_name" header-align="center" align="center" min-width="150" label="代理商名称">
            </el-table-column>
            <el-table-column prop="agent_name_char" header-align="center" align="center" label="代理商名称首字母">
            </el-table-column>
            <el-table-column prop="credit_code" header-align="center" align="center" label="信用代码">
            </el-table-column>
            <el-table-column prop="city_code" header-align="center" align="center" label="城市行政区划编码">
            </el-table-column>
            <!-- <el-table-column prop="city_id" header-align="center" align="center" label="代理商所在城市">
      </el-table-column> -->
            <el-table-column prop="call_name" header-align="center" align="center" label="代理商联系人">
            </el-table-column>
            <el-table-column prop="call_phone" header-align="center" align="center" label="联系电话">
            </el-table-column>
            <!-- <el-table-column prop="total_amount" header-align="center" align="center" label="补贴总额"></el-table-column>
      <el-table-column prop="remaining_amount" header-align="center" align="center" label="补贴剩余总额"></el-table-column> -->
            <!-- <el-table-column prop="status" header-align="center" align="center" label="数据有效状态">
      </el-table-column> -->
            <!-- （0无效；1有效） -->
            <el-table-column prop="remark" header-align="center" align="center" min-width="150" label="备注">
            </el-table-column>
            <!-- <el-table-column prop="create_id" header-align="center" align="center" label="创建人id">
      </el-table-column>
      <el-table-column prop="create_time" header-align="center" align="center" label="写入时间">
      </el-table-column>       -->
            <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
                <template slot-scope="scope">
                    <!-- <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button> -->
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row)">修改</el-button>
                    <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <agentlist_add_or_update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList">
        </agentlist_add_or_update>
    </div>
</template>

<script>
    import agentlist_add_or_update from './agentlist_add_or_update.vue'
    import agentApi from "../../api/agent_api";

    export default {
        name: "agent_manager",
        data() {
            return {
                dataForm: {
                    // agent_name: "",
                    agent_name_char: ""
                },
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                dataListLoading: false,
                dataListSelections: [],
                addOrUpdateVisible: false
            }
        },
        components: {
            agentlist_add_or_update
        },

        async created() {
            await this.getDataList()
        },
        mounted() {

        },
        // activated() {
        //   // this.getDataList()
        // },
        methods: {
            // 获取数据列表
            async getDataList() {
                this.dataListLoading = true
                // JSON.stringify(this.dataForm.agent_name_char).toUpperCase()
                let obj = {
                    "agent_name_char": this.dataForm.agent_name_char.toUpperCase()
                }
                let reqData = {
                    'offset': this.pageIndex - 1,
                    'limit': this.pageSize,
                    "reqdata": JSON.stringify(obj)
                }
                // let reqData = {
                //   'page': this.pageIndex,
                //   'limit': this.pageSize,
                //   'key': this.dataForm.key
                // }
                let res = await agentApi.getByCondition(reqData).finally(_ => { this.dataListLoading = false });

                if (res.data.code != 0) {
                    this.$message.error({
                        message: res.data.msg
                    });
                    return
                }
                this.dataList = res.data.page.items
                this.totalPage = res.data.page.total_count
            },
            clear() {
                this.dataForm = {
                    agent_name_char: ""
                };
                this.page_index = 1;
                this.getDataList();
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                this.getDataList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 新增 / 修改
            addOrUpdateHandle(id) {
                this.addOrUpdateVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdate.init(id)
                })
            },
            // 删除
            async deleteHandle(id) {
                const confirmResult = await this.$confirm("确定删除该记录?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).catch((err) => {
                    return err;
                });
                if (confirmResult != "confirm") {
                    return;
                }
                let res = await agentApi.deleteByIds(id)
                if (res.data.code != 0) {
                    this.$message.error(res.data.msg)
                    return
                }
                this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 500,
                    onClose: () => {
                        this.getDataList()
                    }
                })
                // var ids = id ? [id] : this.dataListSelections.map(item => {
                //   return item.id
                // })
                // this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
                //   confirmButtonText: '确定',
                //   cancelButtonText: '取消',
                //   type: 'warning'
                // }).then(() => {
                //   this.$http({
                //     url: this.$http.adornUrl('/api/partner/agentlist/delete'),
                //     method: 'post',
                //     data: this.$http.adornData(ids, false)
                //   }).then(({ data }) => {
                //     if (data && data.code === 0) {
                //       this.$message({
                //         message: '操作成功',
                //         type: 'success',
                //         duration: 1500,
                //         onClose: () => {
                //           this.getDataList()
                //         }
                //       })
                //     } else {
                //       this.$message.error(data.msg)
                //     }
                //   })
                // })
            }
        }
    }
</script>
<style scoped>
    .box-card {
        margin-top: 15px;
    }

    .el-form {
        margin-top: 10px;
        margin-bottom: 5px;
        height: 40px;
        align-content: center;
        /* line-height: 60px; */
        text-align: center;
    }

    .el-pagination {
        margin-top: 10px;
    }
</style>