
<el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" :visible.sync="visible">
  <el-form :model="dataForm" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="账户姓名" prop="userName">
      <el-input v-model="dataForm.userName" placeholder="账户名"></el-input>
    </el-form-item>
    <el-form-item label="用户名" prop="loginName">
      <el-input v-model="dataForm.loginName" placeholder="用户名"></el-input>
    </el-form-item>
    <el-form-item label="账户密码" prop="loginPassword">
      <el-input v-model="dataForm.loginPassword" placeholder="账户密码"></el-input>
    </el-form-item>
    <el-form-item label="手机号" prop="mobile">
      <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
    </el-form-item>
    <el-form-item label="账号类别" prop="category">
      <el-radio v-model="dataForm.category" label=1>总部账号</el-radio>
      <el-radio v-model="dataForm.category" label=2>代理商账号</el-radio>
      <!-- <el-input v-model="dataForm.category" placeholder="账号类别"></el-input> -->
    </el-form-item>
    <!-- <el-form-item label="状态" prop="status">
      <el-input v-model="dataForm.status" placeholder="状态"></el-input>
    </el-form-item> -->
    <el-form-item label="系统角色">
      <!-- <el-input v-model="user.role_ids"></el-input> -->
      <el-select v-model="dataForm.role_ids" clearable filterable multiple collapse-tags>
        <el-option v-for="item in role_options" :key="item.id" :label="item.role_name" :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input type="textarea" v-model="dataForm.remark" placeholder="备注"></el-input>
    </el-form-item>
    <!-- <el-form-item label="" prop="createId">
      <el-input v-model="dataForm.createId" placeholder=""></el-input>
    </el-form-item> -->
    <!-- <el-form-item label="" prop="createTime">
      <el-input v-model="dataForm.createTime" placeholder=""></el-input>
    </el-form-item> -->
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="visible = false">取消</el-button>
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
  </span>
</el-dialog>
