
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <div slot="header" class="clearfix">
      <span>数据筛选</span>
      <el-button
        style="float: right; padding: 3px 0"
        type="text"
        @click="filter"
        >高级查询</el-button
      >
    </div>
    <div class="text item">
      <el-form
        ref="searchValue"
        style="padding-right: 10px"
        :model="searchValue"
        label-width="110px"
      >
        <el-row>
          <el-col :span="7">
            <el-form-item label="微信号/微信ID：" label-width="120px">
              <el-input
                v-model.trim="searchValue.user_no"
                placeholder="请输入微信号或微信ID"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="3" style="float: right; text-align: right">
            <el-button type="primary" @click="clickSearch">查询</el-button>
            <el-button type="default" @click="clear">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>用户卡包</span>
      <el-button style="float: right" @click="exportOrder">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
      @selection-change="rowSelected"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="wechat_no"
        label="微信号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="open_id"
        label="微信ID"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="联系方式"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="used_total"
        label="已用卡券"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="available_total"
        label="可用卡券"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="unavailable_total"
        label="不可用卡券"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="overdue_total"
        label="过期卡券"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="last_recharge_time"
        label="最后充值时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="last_pay_time"
        label="最后支付时间"
        min-width="150"
        align="center"
      ></el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
</div>
