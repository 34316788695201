import ElementUI, { Message } from "element-ui";

let util = function (Vue) {
  Vue.prototype.getOpBtn = (h, text, type, click, disabled) => {
    //return h(" 定义的元素 "，{ 元素的性质 }，" 元素的内容"/[元素的内容])
    return h(
      "el-button",
      {
        props: {
          type: type,
          size: "small",
        },
        attrs: {
          disabled: disabled ? true : false,
        },
        on: {
          click: () => {
            click();
          },
        },
      },
      text
    );
  };
  Vue.prototype.getLink = (h, text, type, click, disabled, slot) => {
    return h(
      "el-link",
      {
        props: {
          type: type,
        },
        attrs: {
          disabled: disabled ? true : false,
        },
        on: {
          click: () => {
            if (click) click();
          },
        },
        slot: slot,
      },
      text
    );
  };
  Vue.prototype.getLinkPopover = (h, elements) => {
    return h(
      "el-popover",
      {
        props: {},
        attrs: {},
      },
      null,
      elements
    );
  };
  Vue.prototype.getSelect = (h, value, input, arr, multiple, change) => {
    return h(
      "el-select",
      {
        props: {
          // clearable:true,
          value: value,
          multiple: multiple ? true : false,
        },
        on: {
          input: (value) => {
            input(value);
          },
          change: () => {
            if (change) {
              change();
            }
          },
        },
      },
      [
        arr.map((item) => {
          return h("el-option", {
            props: {
              value: item.value,
              label: item.label,
            },
          });
        }),
      ]
    );
  };
  Vue.prototype.getClick = (h, text, type, click) => {
    return h(
      "el-link",
      {
        props: {
          underline: false,
          type: type,
        },
        on: {
          click: () => {
            click();
          },
        },
        style: {
          whiteSpace: "pre-wrap",
        },
      },
      text
    );
  };
  Vue.prototype.clearNullArr = (arr) => {
    for (let i = 0, len = arr.length; i < len; i++) {
      if (!arr[i] || arr[i] === "" || arr[i] === undefined) {
        arr.splice(i, 1);
        len--;
        i--;
      }
    }
    return arr;
  };
  Vue.prototype.formatDateTime = (inputTime) => {
    let date = new Date(inputTime);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;
    return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
  };
  Vue.prototype.getImg = (name, isActive) => {
    try {
      // var name =`../../../assets/img/${name+(isActive?'_active':'')${name.includes('.png')?'':'.png'}`;
      var name = `../../../assets/img/${name}${isActive ? "_active" : ""}${
        name.includes(".png") ? "" : ".png"
      }`;
      //var p =import.meta.globEager("../../../assets/img/**/*.png");
      const picture = import.meta.globEager("../../../assets/img/**/*.png")[
        name
      ];
      return picture.default;
    } catch {
      return "";
    }
  };
  // 为了实现Class的私有属性
  const showMessage = Symbol("showMessage");
  /**
   *  重写ElementUI的Message
   *  single默认值true，因为项目需求，默认只弹出一个，可以根据实际需要设置
   */
  class DonMessage {
    success(options, single = true) {
      this[showMessage]("success", options, single);
    }
    warning(options, single = true) {
      this[showMessage]("warning", options, single);
    }
    info(options, single = true) {
      this[showMessage]("info", options, single);
    }
    error(options, single = true) {
      this[showMessage]("error", options, single);
    }

    [showMessage](type, options, single) {
      if (single) {
        // 判断是否已存在Message
        if (document.getElementsByClassName("el-message").length === 0) {
          Message[type](options);
        }
      } else {
        Message[type](options);
      }
    }
  }
};
export default util;
export const userHead = (name) => {
  const w = 96;
  const h = 96;
  const can = document.createElement("canvas");
  can.width = w;
  can.height = h;
  const _txt = can.getContext("2d");
  _txt.fillStyle = "#bdbdbd";
  _txt.fillRect(0, 0, w, h);
  _txt.font =
    "60px PingFang SC, Microsoft YaHei, 'Avenir', Helvetica, Arial, sans-serif";
  _txt.fillStyle = "#F0F3FA";
  _txt.textAlign = "center";
  _txt.textBaseline = "middle";
  _txt.fillText(name, w / 2, h / 2);
  return can.toDataURL();
};
export const filters = (nodes, condition, props) => {
  var { children = "children" } = props;

  if (!(nodes && nodes.length)) {
    return [];
  }
  let newChildren = [];
  for (let node of nodes) {
    if (condition(node)) {
      newChildren.push(node);
      node[children] = filters(node[children], condition, props);
    } else {
      newChildren.push(...filters(node[children], condition, props));
    }
  }
  return newChildren.length ? newChildren : [];
};
export const nest = (items, id, props) => {
  const { parentId = "parentId", children = "children", value = "id" } = props;

  var a = items.filter((t) => t[parentId] == id);
  return items
    .filter((t) => t[parentId] == id)
    .map((item) => {
      var obj = { ...item };
      obj[children] = nest(items, item[value], props);
      return obj;
    });
};

export const flatten = (data, props) => {
  const { children = "children" } = props;
  return data.reduce((arr, item) => {
    return [...arr, ...[item], ...flatten(item[children], props)];
  }, []);
};
export const getType = (obj) => {
  var toString = Object.prototype.toString;
  var map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  return map[toString.call(obj)];
};
export const deepClone = (data) => {
  var t = getType(data),
    o,
    i,
    ni;

  if (t === "array") {
    o = [];
  } else if (t === "object") {
    o = {};
  } else {
    return data;
  }

  if (t === "array") {
    for (i = 0, ni = data.length; i < ni; i++) {
      o.push(deepClone(data[i]));
    }
    return o;
  } else if (t === "object") {
    for (i in data) {
      o[i] = deepClone(data[i]);
    }
    return o;
  }
};
/**
 * @description: 数组对象去重
 * @param {*} array
 * @param {*} id
 * @return {*}
 */
export const ArrSet = (array, id) => {
  let obj = {};
  array = array.reduce((newArr, next) => {
    obj[next[id]] ? "" : (obj[next[id]] = true && newArr.push(next));
    return newArr;
  }, []);
  return array;
};
export const operateNumber = {
  1: "导出用户度数列表",
  2: "导出用户度数钱包列表",
  3: "导出用户度数钱包明细列表",
  4: "导出渠道商列表",
  5: "导出渠道商引流客户列表",
  6: "导出城市用户开发情况列表",
  7: "导出未下单客服分析列表",
  8: "导出度数钱包列表",
  9: "导出度数钱包明细列表",
  10: "导出客户储值情况列表",
  11: "导出设备管理列表",
  12: "导出活动管理列表",
  13: "导出B端优享列表",
  14: "导出B端优享管理列表",
  15: "导出开票管理列表",
  16: "导出优惠券管理列表",
  17: "导出优惠券设置列表",
  18: "导出度数管理列表",
  19: "导出招商信息列表",
  20: "导出支付对账列表",
  21: "导出充值对账列表",
  22: "导出B端二维码列表",
  23: "导出用户卡券列表",
  24: "导出退款对账列表",
  25: "导出用户钱包列表",
  26: "导出发放情况列表",
  27: "导出B端优享明细列表",
  28: "导出开票管理详情列表",
  29: "导出用户钱包明细列表",
  30: "导出订单管理列表",
  31: "导出城市补贴池列表",
  32: "导出城市补贴明细列表",
  33: "导出配送员列表",
  34: "导出用户列表",
  35: "导出地推概况列表",
  36: "导出地推概况详情列表",
  37: "导出车辆检测钱包列表",
  38: "导出车辆检测钱包明细列表",
};
export function validateNum(newVal, precision, scale, isOwe, max) {
  var oValue = newVal.toString();
  if (oValue.charAt(0) === "-") {
    oValue = oValue.substring(1, oValue.length);
  }
  if (scale == null || scale === 0) {
    //  要求输入为整数
    oValue = oValue.replace(/[^\d]/g, "");
    if (precision != null && precision > 0) {
      //  限制整数位数
      oValue = oValue.replace(
        new RegExp("^(\\d{" + precision + "})\\d+", "g"),
        "$1"
      );
    }
  } else {
    oValue = oValue.replace(/[^\d.]/g, "");
    oValue = oValue.replace(/^\./g, "");
    oValue = oValue.replace(/\.{2,}/g, ".");
    oValue = oValue.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    oValue = oValue.replace(
      new RegExp("^(\\d{" + (precision - scale) + "})\\d+", "g"),
      "$1"
    );
    oValue = oValue.replace(
      new RegExp("(\\.\\d{" + scale + "})\\d+", "g"),
      "$1"
    );
  }
  if (isOwe != null && isOwe === true && oValue.charAt(0) === "-") {
    oValue = "-" + oValue;
  }
  if (max != null) {
    if (Number(oValue) > Number(max)) {
      oValue = max;
    }
  }
  return oValue;
}
