<!--
 * @Author: ljf
 * @Date: 2022-05-26 16:17:06
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-15 15:56:45
 * @FilePath: \lxm-admin\src\views\system\user\User.vue
 * @Description:
 *
-->
<template>
  <div class="app-main">
    <el-card class="box-card">
      <el-form :inline="true">
        <el-form-item label="手机号码：">
          <el-input
            v-model.trim="search_value.cellphone"
            placeholder=""
            clearable
          />
        </el-form-item>
        <el-form-item label="城市：">
          <el-select
            v-model="search_value.cityCode"
            placeholder="全国"
            collapse-tags
            filterable
            clearable
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册时间：">
          <el-date-picker
            v-model="createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="center"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="性别：" v-if="!isAgent">
          <el-select
            v-model="search_value.sex"
            placeholder="全部"
            collapse-tags
            clearable
          >
            <el-option label="保密" :value="0"> </el-option>
            <el-option label="男" :value="1"> </el-option>
            <el-option label="女" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态：" v-if="!isAgent">
          <el-select
            v-model="search_value.deliveryerCheckState"
            placeholder="全部"
            collapse-tags
            @clear="search_value.deliveryerCheckState = null"
            clearable
          >
            <el-option
              v-for="item in check_state_list"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
      </el-form>
    </el-card>
    <el-card class="box-card-main">
      <el-row style="margin-bottom: 15px">
        <el-button
          class="rewrite-button-style"
          size="small"
          @click="addUser"
          v-if="isShowBtn"
          >新增</el-button
        >
        <el-button
          class="rewrite-button-style"
          size="small"
          @click="deleteUser(multiple_selection)"
          v-if="isShowBtn"
          >删除</el-button
        >
        <el-button
          class="rewrite-button-style"
          size="small"
          @click="resetPassword"
          v-if="isShowBtn"
          >重置密码</el-button
        >
        <el-button class="rewrite-button-style" size="small" @click="exportData"
          >导出</el-button
        >
      </el-row>

      <el-table
        :data="table_data"
        height="60vh"
        border
        header-align="center"
        element-loading-customClass="personal-loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(204, 204, 204, 0.6)"
        class="refTable"
        ref="refTable"
        default-expand-all
        :header-row-style="headerRowStyle"
        :header-cell-style="headerCellStyle"
        :cell-style="cell_style"
        :style="table_style"
        @selection-change="handleSelectionChange"
        @select-all="handleSelectionChange"
        row-key="uid"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
          :reserve-selection="true"
          fixed="left"
        ></el-table-column>
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column
          prop="cellphone"
          label="手机号码"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="real_name"
          label="微信昵称"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="注册时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" min-width="150" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.sex == '1'">男</div>
            <div v-else-if="scope.row.sex == '2'">女</div>
            <div v-else>保密</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="check_state_name"
          label="审核状态"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="id_card_no"
          label="身份证号码"
          min-width="100"
          align="center"
          v-if="isShowBtn"
        ></el-table-column>
        <el-table-column
          prop="native_place"
          label="户籍地址"
          min-width="100"
          align="center"
          v-if="isShowBtn"
        ></el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="250"
          align="center"
          v-if="isShowBtn"
        >
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="editUser(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="small"
              v-if="scope.row.deliveryer_check_state == 1"
              @click="editState(scope.row)"
              >审核</el-button
            >
            <el-button
              type="primary"
              size="small"
              v-else-if="scope.row.deliveryer_check_state != '0'"
              @click="editState(scope.row)"
              >查看</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="deleteUser([scope.row])"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current_page"
          small
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="page_size"
          layout="total, sizes, prev, pager, next"
          :total="total_count"
        ></el-pagination>
      </el-footer>
    </el-card>
    <addDialog
      v-if="isAddDialog"
      :select_user="select_user"
      :agentCityCode="agentCityCode"
      :cityList="restrictedCityList"
      @sure="clear"
      @close="isAddDialog = false"
    />
    <examineDialog
      v-if="isExamineDialog"
      :select_user="select_user"
      :cityList="restrictedCityList"
      @sure="clear"
      @close="isExamineDialog = false"
    />
  </div>
</template>

<script>
import { getUserPage, deleteUser } from "api/system/delivery-api";
import { resetUser } from "api/system/user-api";
import { mapGetters, mapState } from "vuex";
import { flatten } from "utils/utils";
import * as XLSX from "xlsx";
import OPERATE_API from "@/api/operate_api";
import agentApi from "@/api/agent_api";
import moment from "moment/moment";
import addDialog from "./addDialog.vue";
import examineDialog from "./examineDialog.vue";
import { operateAdd } from "@/api/system/user-api";
export default {
  name: "user",
  data() {
    return {
      table_style: {
        "margin-top": "10px",
      },
      headerCellStyle: {
        background: "#EDF1F4",
        textAlign: "center",
        padding: "0",
      },
      cell_style: {
        fontSize: "14px",
        color: "#000",
        padding: "0",
        height: "40px",
      },
      headerRowStyle: {
        "line-height": "36px",
      },
      current_page: 1,
      loading: false,
      table_data: [],
      total_count: 0,
      page_size: 50,
      multiple_selection: [],
      search_value: {
        limit: 50,
        offset: 0,
        cellphone: "",
        cityCode: "",
        createTimeStart: "",
        createTimeEnd: "",
        sex: "",
        deliveryerCheckState: null,
      },
      select_user: null,
      isAddDialog: false,
      isExamineDialog: false,
      agentCityCode: "",
      check_state_list: [
        { value: null, label: "全部" },
        { value: "0", label: "未认证" },
        { value: "1", label: "审核中" },
        { value: "2", label: "未通过" },
        { value: "3", label: "已通过" },
      ],
    };
  },
  computed: {
    ...mapState(["restrictedCityList"]),
    ...mapGetters(["user"]),
    isAgent() {
      if (this.user && this.user.isAgent) {
        return true;
      }
      return false;
    },
    isShowBtn() {
      if (
        this.user?.isMaintenanceOrder == 1 ||
        this.user?.isMaintenanceOrder == 5
      ) {
        return true;
      }
      return false;
    },
    createTime: {
      get() {
        return [
          this.search_value.createTimeStart,
          this.search_value.createTimeEnd,
        ];
      },
      set(val) {
        this.search_value.createTimeStart = val
          ? moment(val[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
        this.search_value.createTimeEnd = val
          ? moment(val[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
    },
  },
  async created() {
    this.init();
  },
  methods: {
    clear() {
      this.search_value.limit = 50;
      this.search_value.offset = 0;
      this.search_value.current_page = "1";
      this.search_value.cellphone = "";
      this.search_value.createTimeStart = "";
      this.search_value.createTimeEnd = "";
      this.search_value.sex = "";
      this.search_value.cityCode = "";
      this.search_value.deliveryerCheckState = null;
      this.isAddDialog = false;
      this.search();
    },
    async search() {
      try {
        this.loading = true;
        var data = await getUserPage(this.search_value);
        let arr = data.items;
        arr.forEach((item) => {
          item.check_state_name = this.check_state_list.find(
            (it) => it.value === item.deliveryer_check_state
          ).label;
        });
        this.table_data = arr;
        this.total_count = data.total;
      } catch (error) {
        this.loading = false;
      }
      this.loading = false;
    },
    async init() {
      this.search();
    },
    addUser() {
      this.select_user = {};
      this.isAddDialog = true;
    },
    deleteUser(list) {
      if (list.length === 0) {
        this.$message.warning("请选择记录");
        return;
      }
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteUser(list.map((item) => item.user_id));
          this.$message.success("删除成功!");
          this.search();
        })
        .catch((e) => {
          this.$message.info("已取消删除");
        });
    },
    editUser(row) {
      this.select_user = { ...row };
      this.isAddDialog = true;
    },
    editState(row) {
      this.select_user = { ...row };
      this.isExamineDialog = true;
    },
    async resetPassword() {
      if (this.multiple_selection.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择要重置密码的用户",
        });
        return;
      }
      this.$confirm("确定重置用户密码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await resetUser(this.multiple_selection.map((t) => t.user_id));
          this.$message({
            type: "success",
            message: "重置用户密码成功!",
          });
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
    handleSelectionChange(val) {
      this.multiple_selection = [
        ...new Set([...this.multiple_selection, ...val]),
      ];
      this.multiple_selection = this.multiple_selection.reduce((list, item) => {
        if (list.every((t) => t.id !== item.id)) {
          list.push(item);
        }
        return list;
      }, []);
      //如果等于空 需要把本页所有的项目移除
      var selectedIds = val.map((t) => t.id);
      var ids = this.table_data
        .map((t) => t.id)
        .filter((t) => {
          return !selectedIds.includes(t);
        });
      //当前表格没有选中的
      this.multiple_selection = this.multiple_selection.reduce((list, item) => {
        if (!ids.includes(item.id)) {
          list.push(item);
        }
        return list;
      }, []);
    },
    handleCurrentChange(current) {
      this.current_page = current;
      this.search_value.offset = (this.current_page - 1) * this.page_size;
      this.search();
    },
    handleSizeChange(size) {
      this.page_size = size;
      this.search_value.limit = this.page_size;
      this.handleCurrentChange(1);
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 33,
        operate_parameter: JSON.stringify({
          ...this.search_value,
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.total_count > 20000) {
        await this.$confirm(
          `您本次需要导出${this.total_count}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      let params = {
        ...this.search_value,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/manage/delivery/user/list", {
          params: params,
        })
        .then((res) => {
          this.hideLoading();
          console.log(12312313);
          let newData = this.getHeadersAndbody(res.data.data);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "配送员.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      console.log(list);
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["手机号码"] = item.cellphone;
        newObj["微信昵称"] = item.real_name;
        newObj["注册时间"] = item.create_time;
        let sex = "保密";
        if (item.sex == "1") {
          sex = "男";
        } else if (item.sex == "2") {
          sex = "女";
        }
        newObj["性别"] = sex;
        newObj["城市"] = item.city_name;

        newArray.push(newObj);
      });
      console.log(newArray);
      return newArray;
    },
  },
  components: {
    addDialog,
    examineDialog,
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  display: flex;
  flex-direction: column;
}
.user-container {
  height: 97%;
  width: 100%;
  display: flex;
  padding: 5px;
  margin-top: 10px;
  .user-left {
    width: 300px;
    display: flex;
    flex-direction: column;
    border-top: 0px;

    border: 0.5px solid #c1cfdf;
    border-top: 0;
    height: 99.9%;
    .user-left-tree {
      height: calc(100% - 30px);
    }
  }
  .user-table {
    flex: 1;
    border-left: 0px;
    border: 0.5px solid #c1cfdf;
    border-top: 0;
    height: 99.9%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: 20px;
    .user-table-bar {
      margin-top: 10px;
      height: 30px;
      padding-left: 20px;
      display: flex;
      .user-table-bar-left {
        flex: 1;
      }
      .user-table-bar-right {
        width: 400px;
        display: flex;
        padding-right: 20px;
      }
    }
    .user-table-container {
      flex: 1;
      padding-left: 20px;
      height: 70%;
    }
    .user-table-floor {
      min-height: 35px;
      flex: 0 0 5% !important;
      display: flex;
      justify-content: left;
      align-content: center;
    }
  }
  .is-horizontal {
    display: none;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
.box-card-main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 15px;
  .refTable {
    flex: 1;
    overflow: hidden;
  }
}
</style>
