
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="用户编号：">
        <el-input
          v-model.trim="form.userNumber"
          placeholder="请输入"
          clearable
          maxlength="10"
          oninput="value=value.replace(/[^0-9]/g,'')"
        />
      </el-form-item>
      <el-form-item label="用户手机号：">
        <el-input
          v-model.trim="form.cellphone"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
      <el-form-item label="登录时间">
        <el-date-picker
          v-model="createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 220px"
          align="center"
        >
        </el-date-picker>
      </el-form-item>

      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <!-- <el-row style="margin-bottom: 15px">
      <el-button style="margin-left: 15px" @click="exportData(FILE_VIEW_URL)"
        >导出</el-button
      >
    </el-row> -->
    <el-table ref="dataTable" :height="tableHeight" :data="dataTable">
      <el-table-column
        prop="real_name"
        label="用户名"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="用户手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="登录时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="login_ip"
        label="登录IP"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="city_name"
        label="用户城市"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="login_address"
        label="登录地址"
        min-width="150"
        align="center"
      ></el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
</div>
