/*
 * @Author: ljf
 * @Date: 2022-06-01 14:25:02
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-01 10:09:05
 * @FilePath: \lxm-admin\src\api\system\org-api.js
 * @Description: 
 * 
 */
import axios from "utils/axiosHelper";
import { Message } from 'element-ui';

export const getOrgTree = (show = false) => {
  return axios.get(`/api/organize/tree?show=${show}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch((error) => {
      Message.error("获取组织树失败")
    })
}

export const getTreeData = () => {
  return axios.get(`/api/organize/tree/recursive`)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch((error) => {
      Message.error("初始化数据失败")
    })
}
/**
 * @description: 
 * @param {*} page
 * @param {*} offset
 * @param {*} limit
 * @param {*} orgId
 * @return {*}
 */
export const getOrgUserData = (page, offset, limit, orgId) => {
  return axios.get(`/api/organize/user/page?$page=${page}&offset=${offset}&$limit=${limit}&orgId=${orgId}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch((error) => {
      Message.error("初始化数据失败")
    })
}


export const deleteOrg = (org_id) => {
  return axios.delete(`/api/organize/${org_id}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch((error) => {
      Message.error("删除组织失败")
    })

}

export const checkOrgName = (params) => {
  return axios.post(
    `/api/organize/check/`,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    },
    { params }
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  })
    .catch((error) => {
      Message.error("检查组织名失败")
    })
}

export const createOrg = (orgDto) => {
  return axios.post(
    `/api/organize`,
    orgDto
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  })
    .catch((error) => {
      Message.error("创建组织名失败")
    })
}

export const updateOrg = (orgDto) => {
  return axios.put(
    `/api/organize`,
    orgDto
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  })
    .catch((error) => {
      Message.error("创建组织名失败")
    })
}

export const getRoleOrgUser = (roleId, orgId, userInOrg, includeRelationOrg) => {
  return axios.get(`api/rest/relation/role/org/user/list?roleId=${roleId}&orgId=${orgId}&userInOrg=${userInOrg}&includeRelationOrg=${includeRelationOrg}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
}

export const setOrgRoleUser = (params) => {
  return axios.put(`api/organize/role/user`, params)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
}

export const orgUserRemove = (params) => {
  return axios.put(`api/organize/user/remove`, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  },
    { params })
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
}
export const orgUserAssign = (params) => {
  return axios.put(`api/organize/user/assign`,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    },
    { params })
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
}