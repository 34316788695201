<template>
  <el-dialog
    title="车辆检测明细"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    @close="close"
  >
    <div class="height:100%;">
      <div style="display: flex; justify-content: space-between">
        <el-form :inline="true">
          <el-form-item label="收入/支出" style="margin-bottom: 0">
            <el-select
              v-model="form.type"
              placeholder="全部"
              collapse-tags
              clearable
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button style="margin-right: 15px" type="primary" @click="search"
            >查询</el-button
          >
          <el-button type="default" @click="clear">重置</el-button>
        </el-form>
        <el-button @click="exportData">导出</el-button>
      </div>

      <el-card class="box-card">
        <el-table ref="dataTable" height="50vh" :data="dataTable">
          <el-table-column
            prop="typeName"
            label="类型"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="time"
            label="时间"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="购买金额"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="num"
            label="收入/支出（次）"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="activity_name"
            label="活动名称"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="early_num"
            label="前"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="after_num"
            label="后"
            min-width="150"
            align="center"
          ></el-table-column>
        </el-table>
        <el-footer height="32px" style="margin-top: 10px">
          <el-pagination
            background
            layout="total,sizes, prev, pager, next,jumper"
            :total="page_total"
            :page-size="page_size"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :current-page.sync="page_index"
          ></el-pagination>
        </el-footer>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import * as XLSX from "xlsx";
import moment from "moment/moment";
import OPERATE_API from "@/api/operate_api";
import { operateAdd } from "@/api/system/user-api";
export default {
  props: {
    selectObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      visible: true,
      form: {
        type: "",
      },
      page_total: 0,
      page_size: 20,
      page_index: 1,
      dataTable: [],
      typeList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "收入",
        },
        {
          value: "-1",
          label: "支出",
        },
      ],
    };
  },
  created() {
    this.loadDataTable();
  },
  mounted() {},
  methods: {
    clear() {
      this.form = {
        type: "",
      };
      this.search();
    },
    close() {
      this.$emit("close");
    },
    async loadDataTable() {
      const res = await this.axiosHelper.get(
        `/api/user/manage/query/carAssess/detail/${
          (this.page_index - 1) * this.page_size
        }/${this.page_size}`,
        {
          params: {
            userId: this.selectObj.id,
            ...this.form,
          },
        }
      );
      if (res.data.code == 0) {
        let array = res.data.data.items || [];
        array.forEach((item) => {
          if (item.type == 31) {
            item.typeName = "活动赠送";
          }
          if (item.type == 32) {
            item.typeName = "车辆检测";
          }
          if (item.type == 33) {
            item.typeName = "次数购买";
          }
          if (item.type == 34) {
            item.typeName = "手动扣减";
          }
          item.after_num =
            item.type == 31 || item.type == 33
              ? Number(item.early_num) + Number(item.num)
              : Number(item.early_num) - Number(item.num);
          item.activity_name = item.activity_name || "-";
          item.num =
            item.type == 31 || item.type == 33 ? item.num : `-${item.num}`;
        });
        this.dataTable = array;
        this.page_total = res.data.data.total;
      }
    },
    search() {
      this.page_index = 1;
      this.loadDataTable();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.loadDataTable();
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.loadDataTable();
    },
    async exportData() {
      await operateAdd({
        operate_type: "1",
        operate_number: 38,
        operate_parameter: JSON.stringify({
          userId: this.selectObj.id,
          ...this.form,
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.page_total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      this.showLoading("加载中");
      this.axiosHelper
        .get("/api/user/manage/query/carAssess/detail/list", {
          params: {
            userId: this.selectObj.id,
            ...this.form,
          },
        })
        .then((res) => {
          this.hideLoading();
          let array = res.data.data || [];
          array.forEach((item) => {
            if (item.type == 31) {
              item.typeName = "活动赠送";
            }
            if (item.type == 32) {
              item.typeName = "车辆检测";
            }
            if (item.type == 33) {
              item.typeName = "次数购买";
            }
            if (item.type == 34) {
              item.typeName = "手动扣减";
            }
            item.after_num =
              item.type == 31 || item.type == 33
                ? Number(item.early_num) + Number(item.num)
                : Number(item.early_num) - Number(item.num);
            item.activity_name = item.activity_name || "-";
            item.num =
              item.type == 31 || item.type == 33 ? item.num : `-${item.num}`;
          });
          let newData = this.getHeadersAndbody(array);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = `车辆检测明细.xlsx`;
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["类型"] = item.typeName;
        newObj["时间"] = item.time;
        newObj["购买金额"] = item.amount;
        newObj["收入/支出（次）"] = item.num;
        newObj["活动名称"] = item.activity_name;
        newObj["前"] = item.early_num;
        newObj["后"] = item.after_num;
        newArray.push(newObj);
      });
      return newArray;
    },
  },
};
</script>
<style scoped lang="scss">
.search {
  display: flex;
  flex-direction: row;
}
.el-col {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.box-card {
  margin-top: 15px;
}
</style>