
<el-dialog
  class="showAll_dialog"
  :title="this.editState == 'edit' ? '编辑' : '新增'"
  width="800px"
  :height="'80%'"
  :max-height="'600px'"
  :close-on-click-modal="false"
  :visible.sync="visible"
>
  <div ref="dialogBody">
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="160px"
      v-loading="formLoading"
    >
      <!-- 基础信息 -->
      <div class="form_list">
        <div class="form_list-title">基础信息</div>
        <el-form-item label="活动名称：" prop="name">
          <el-input
            v-model.trim="dataForm.name"
            placeholder="请输入活动名称"
            clearable
            maxlength="35"
          />
        </el-form-item>
        <el-form-item label="活动展示名称：" prop="promotion_show_name">
          <el-input
            v-model.trim="dataForm.promotion_show_name"
            placeholder="请输入活动展示名称"
            clearable
            maxlength="30"
          />
        </el-form-item>
        <el-form-item label="活动时间：" prop="create_time">
          <el-date-picker
            v-model="dataForm.create_time"
            type="datetimerange"
            style="width: 400px"
            value-format="yyyy-MM-dd HH:mm:ss"
            :clearable="true"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="timeChange"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="城市：" prop="city_code">
          <el-select
            v-model="dataForm.city_code"
            placeholder="请选择城市"
            @change="cityChange"
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.city_name"
              :value="item.city_code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- 活动配置 -->
      <div class="form_list">
        <div class="form_list-title">活动配置</div>
        <el-form-item label="场景：" prop="billing_type">
          <el-select
            v-model="dataForm.billing_type"
            placeholder="请选择场景"
            collapse-tags
            @change="billingChange"
            clearable
          >
            <el-option
              v-for="item in sceneList"
              :key="item.id"
              :label="item.billing_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="补贴金额上限：" prop="total_amount">
          <div class="flex">
            <el-input
              v-model.trim="dataForm.total_amount"
              placeholder="请输入补贴金额上限"
              clearable
              maxlength="9"
            />
            <span class="marleft10">元</span>
          </div>
        </el-form-item>
        <div class="flex">
          <el-form-item
            :label="
              dataForm.billing_type == '13'
                ? '参与活动人数限制：'
                : '人数限制：'
            "
            prop="personCode"
          >
            <el-select
              style="width: 150px"
              v-model="dataForm.personCode"
              placeholder="请选择"
              @change="
                () => {
                  dataForm.person_limit = '';
                }
              "
              collapse-tags
              clearable
            >
              <el-option label="否" :value="-1"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="dataForm.personCode === 1"
            label="前"
            prop="person_limit"
            label-width="50px"
          >
            <div class="flex">
              <el-input
                v-model.trim="dataForm.person_limit"
                placeholder="请输入人数"
                clearable
                maxlength="6"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
              <span class="marleft10">人</span>
            </div>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="场景互斥限制：" prop="billing_mutex">
            <el-select
              v-model="dataForm.billing_mutex"
              placeholder="请选择"
              @change="
                () => {
                  dataForm.mutex_billing_type = '';
                }
              "
              collapse-tags
              clearable
            >
              <el-option label="否" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="dataForm.billing_mutex"
            label="互斥场景："
            prop="mutex_billing_type"
          >
            <el-select
              v-model="dataForm.mutex_billing_type"
              placeholder="请选择"
              collapse-tags
              clearable
            >
              <el-option
                v-for="item in sceneList"
                :key="item.id"
                :label="item.billing_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flex" v-if="billingId === 0">
          <el-form-item label="落点设置：" prop="jump_page">
            <el-select v-model="dataForm.jump_page" placeholder="请选择">
              <el-option label="首页" value="1"></el-option>
              <el-option label="常规充电下单页" value="2"></el-option>
              <el-option label="应急补电下单页" value="3"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flex" v-if="dataForm.billing_type === 13">
          <el-form-item label="参与活动次数限制" prop="frequencyCode">
            <el-select
              style="width: 150px"
              v-model="dataForm.frequencyCode"
              placeholder="请选择"
              @change="
                () => {
                  dataForm.every_one_times_limit = '';
                }
              "
              collapse-tags
              clearable
            >
              <el-option label="否" :value="-1"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="dataForm.frequencyCode === 1"
            label=""
            prop="every_one_times_limit"
            label-width="50px"
          >
            <div class="flex">
              <el-input
                v-model.trim="dataForm.every_one_times_limit"
                placeholder="请输入次数"
                clearable
                maxlength="6"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
              <span class="marleft10">次</span>
            </div>
          </el-form-item>
        </div>
        <div class="flex" v-if="dataForm.billing_type === 13">
          <el-form-item
            label="连续邀请人数"
            prop="reach_standard_meet_person_num"
          >
            <div class="flex">
              <el-input
                v-model.trim="dataForm.reach_standard_meet_person_num"
                oninput="value=value.replace(/[^0-9]/g,'')"
                placeholder="请输入人数"
                clearable
                maxlength="9"
              />
              <span class="marleft10">人</span>
            </div>
          </el-form-item>
        </div>
        <div
          v-if="billing_rule_type === 2 || billing_rule_type === 3"
          class="flex"
        >
          <el-form-item
            label="单人充值上限："
            prop="every_one_recharge_limit_code"
          >
            <el-select
              v-model="dataForm.every_one_recharge_limit_code"
              placeholder="请选择"
              @change="
                () => {
                  dataForm.every_one_recharge_limit = '';
                }
              "
              collapse-tags
              clearable
            >
              <el-option label="否" :value="-1"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="dataForm.every_one_recharge_limit_code === 1"
            label="金额："
            prop="every_one_recharge_limit"
          >
            <div class="flex">
              <el-input
                v-model.trim="dataForm.every_one_recharge_limit"
                placeholder="请输入金额"
                clearable
                maxlength="9"
              />
              <span class="marleft10">元</span>
            </div>
          </el-form-item>
        </div>
        <!-- 1 除充值及度数类之外的活动 -->
        <div v-if="billing_rule_type === 1">
          <div
            v-for="(item, index) in dataForm.activity_subsidy_type_list"
            :key="index"
          >
            <div class="flex">
              <el-form-item
                label="优惠类型："
                style="width: 100%"
                :prop="`activity_subsidy_type_list.${index}.subsidy_type`"
                :rules="[
                  {
                    required: true,
                    message: '请选择优惠类型',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <el-select
                  v-model="item.subsidy_type"
                  placeholder="请选择优惠类型"
                  @change="subsidyChange($event, index)"
                  collapse-tags
                >
                  <el-option label="优惠券" :value="1"></el-option>
                  <el-option
                    v-if="billingId === 0"
                    label="充电度数"
                    :value="2"
                  ></el-option>
                  <el-option
                    v-if="billingId === 0 || billingId === 2"
                    label="车辆性能报告"
                    :value="3"
                  ></el-option>
                </el-select>
              </el-form-item>
              <div
                v-if="billingId !== 0"
                class="flex"
                style="margin-left: 10px; margin-bottom: 18px"
              >
                <img
                  class="icon"
                  @click="formArrHandleChange(0)"
                  src="@/assets/img/icon-add.png"
                  alt=""
                />
                <img
                  v-if="dataForm.activity_subsidy_type_list.length > 1"
                  @click="formArrHandleChange(1, index)"
                  class="icon"
                  src="@/assets/img/icon-delete.png"
                  alt=""
                />
              </div>
            </div>
            <el-form-item
              v-if="item.subsidy_type === 1"
              label="支付互斥限制："
              :prop="`activity_subsidy_type_list.${index}.pay_mutex_limit`"
              :rules="[
                {
                  required: true,
                  message: '请选择支付互斥限制',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <el-select
                v-model="item.pay_mutex_limit"
                placeholder="请选择"
                collapse-tags
                clearable
              >
                <el-option label="否" :value="0"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="item.subsidy_type === 2"
              label="度数："
              :prop="`activity_subsidy_type_list.${index}.degree`"
              :rules="[
                {
                  required: true,
                  message: '请输入度数',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <el-input
                v-model.trim="item.degree"
                placeholder="请输入度数"
                clearable
                maxlength="8"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
            </el-form-item>
            <el-form-item
              v-if="item.subsidy_type === 3"
              label="次数："
              :prop="`activity_subsidy_type_list.${index}.num`"
              :rules="numRules"
            >
              <el-input
                v-model.trim="item.num"
                disabled
                placeholder="请输入次数"
                clearable
                maxlength="8"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
            </el-form-item>
            <div
              v-if="
                item.coupon_quote_list &&
                item.coupon_quote_list.length &&
                item.subsidy_type === 1
              "
            >
              <div
                class="flex"
                v-for="(val, valIndex) in item.coupon_quote_list"
                :key="valIndex"
              >
                <el-form-item
                  label="券："
                  :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.coupon_id`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择券',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <el-select
                    v-model="val.coupon_id"
                    placeholder="请选择券"
                    collapse-tags
                    clearable
                  >
                    <el-option
                      v-for="couponVal in couponList"
                      :key="couponVal.id"
                      :label="couponVal.name"
                      :value="couponVal.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="有效期："
                  :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.valid_day`"
                  label-width="90px"
                  :rules="[
                    {
                      required: true,
                      message: '请输入有效期',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <div class="flex">
                    <el-input
                      v-model.trim="val.valid_day"
                      style="width: 100px"
                      placeholder="有效期"
                      clearable
                      maxlength="6"
                      oninput="value=value.replace(/[^0-9]/g,'')"
                    />
                    <span class="marleft10">天</span>
                  </div>
                </el-form-item>
                <el-form-item
                  label="张数："
                  :prop="`activity_subsidy_type_list.${index}.coupon_quote_list.${valIndex}.num`"
                  label-width="70px"
                  :rules="[
                    {
                      required: true,
                      message: '请输入张数',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <el-input-number
                    v-model.number="val.num"
                    style="width: 90px"
                    :min="1"
                    :max="99"
                    controls-position="right"
                    @keyup.native="UpNumber"
                    @keydown.native="UpNumber"
                    label="描述文字"
                    :readonly="item"
                  ></el-input-number>
                </el-form-item>
                <div
                  v-if="billingId !== 0"
                  class="flex"
                  style="margin-left: 10px; margin-bottom: 18px"
                ></div>
                <img
                  class="icon"
                  @click="formArrHandleChange(2, index)"
                  src="@/assets/img/icon-add.png"
                  alt=""
                />
                <img
                  v-if="item.coupon_quote_list.length > 1"
                  @click="formArrHandleChange(3, index, valIndex)"
                  class="icon"
                  src="@/assets/img/icon-delete.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 2 充值类活动 -->
      <div v-if="billing_rule_type === 2">
        <div
          v-for="(item, index) in dataForm.recharge_rule_list"
          :key="index"
        >
          <div class="flex">
            <el-form-item
              :label="`第 ${index + 1} 档充值`"
              :prop="`recharge_rule_list.${index}.recharge`"
              :rules="[
                {
                  required: true,
                  message: '只能数字且最多6位，小数点最多2位',
                  pattern: /^\d{1,6}(\.\d{1,2})?$/,
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <div class="flex">
                <el-input
                  v-model.trim="item.recharge"
                  placeholder="请输入金额"
                  clearable
                  maxlength="9"
                />
                <img
                  class="icon marleft10"
                  @click="topUpArrHandleChange(0)"
                  src="@/assets/img/icon-add.png"
                  alt=""
                />
                <img
                  v-if="dataForm.recharge_rule_list.length > 1"
                  @click="topUpArrHandleChange(1, index)"
                  class="icon marleft10"
                  src="@/assets/img/icon-delete.png"
                  alt=""
                />
              </div>
            </el-form-item>
          </div>
          <div>
            <div
              v-for="(val, valIndex) in item.activity_subsidy_type_list"
              :key="valIndex"
            >
              <!-- 优惠类型 -->
              <div class="flex">
                <el-form-item
                  label="优惠类型："
                  style="width: 100%"
                  :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.subsidy_type`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择优惠类型',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <el-select
                    v-model="val.subsidy_type"
                    placeholder="请选择优惠类型"
                    @change="subsidyChange($event, index)"
                    collapse-tags
                  >
                    <el-option label="优惠券" :value="1"> </el-option>
                  </el-select>
                </el-form-item>
                <div
                  class="flex"
                  style="margin-left: 10px; margin-bottom: 18px"
                >
                  <img
                    class="icon"
                    @click="topUpArrHandleChange(2, index)"
                    src="@/assets/img/icon-add.png"
                    alt=""
                  />
                  <img
                    v-if="item.activity_subsidy_type_list.length > 1"
                    @click="topUpArrHandleChange(3, index, valindex)"
                    class="icon"
                    src="@/assets/img/icon-delete.png"
                    alt=""
                  />
                </div>
              </div>
              <!-- 券 有效期 -->
              <div
                v-if="val.coupon_quote_list && val.coupon_quote_list.length"
              >
                <div
                  class="flex"
                  v-for="(coupon, couponIndex) in val.coupon_quote_list"
                  :key="couponIndex"
                >
                  <el-form-item
                    label="券："
                    :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.coupon_quote_list.${couponIndex}.coupon_id`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择券',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <el-select
                      v-model="coupon.coupon_id"
                      placeholder="请选择券"
                      collapse-tags
                      clearable
                    >
                      <el-option
                        v-for="couponVal in couponList"
                        :key="couponVal.id"
                        :label="couponVal.name"
                        :value="couponVal.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="有效期："
                    :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.coupon_quote_list.${couponIndex}.valid_day`"
                    label-width="90px"
                    :rules="[
                      {
                        required: true,
                        message: '只能数字且最多6位',
                        pattern: /^\d{1,6}?$/,
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <div class="flex">
                      <el-input
                        v-model.trim="coupon.valid_day"
                        placeholder="有效期"
                        maxlength="6"
                        clearable
                      />
                      <span class="marleft10">天</span>
                    </div>
                  </el-form-item>
                  <el-form-item
                    label="张数："
                    :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.coupon_quote_list.${couponIndex}.num`"
                    label-width="70px"
                    :rules="[
                      {
                        required: true,
                        message: '请输入张数',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <el-input-number
                      v-model.number="coupon.num"
                      style="width: 90px"
                      :min="1"
                      :max="99"
                      controls-position="right"
                      @keyup.native="UpNumber($event, 2)"
                      @keydown.native="UpNumber($event, 2)"
                      label="描述文字"
                    ></el-input-number>
                  </el-form-item>
                  <div
                    class="flex"
                    style="margin-left: 10px; margin-bottom: 18px"
                  >
                    <img
                      class="icon"
                      @click="topUpArrHandleChange(4, index, valIndex)"
                      src="@/assets/img/icon-add.png"
                      alt=""
                    />
                    <img
                      v-if="val.coupon_quote_list.length > 1"
                      @click="
                        topUpArrHandleChange(5, index, valIndex, couponIndex)
                      "
                      class="icon"
                      src="@/assets/img/icon-delete.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 3 度数套餐活动 -->
      <div v-if="billing_rule_type === 3">
        <div
          v-for="(item, index) in dataForm.recharge_rule_list"
          :key="index"
        >
          <div class="flex">
            <el-form-item
              :label="`第 ${index + 1} 档充值`"
              :prop="`recharge_rule_list.${index}.recharge`"
              :rules="[
                {
                  required: true,
                  message: '只能数字且最多6位，小数点最多2位',
                  pattern: /^\d{1,6}(\.\d{1,2})?$/,
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <div class="flex">
                <el-input
                  v-model.trim="item.recharge"
                  placeholder="请输入金额"
                  clearable
                  maxlength="9"
                />
                <img
                  class="icon marleft10"
                  @click="topUpArrHandleChange(0)"
                  src="@/assets/img/icon-add.png"
                  alt=""
                />
                <img
                  v-if="dataForm.recharge_rule_list.length > 1"
                  @click="topUpArrHandleChange(1, index)"
                  class="icon marleft10"
                  src="@/assets/img/icon-delete.png"
                  alt=""
                />
              </div>
            </el-form-item>
          </div>
          <div>
            <div
              v-for="(val, valIndex) in item.activity_subsidy_type_list"
              :key="valIndex"
            >
              <template v-if="valIndex === 0 && billingId != 15">
                <div class="flex">
                  <el-form-item
                    label="优惠类型："
                    style="width: 100%"
                    :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.subsidy_type`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择优惠类型',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <el-select
                      v-model="val.subsidy_type"
                      placeholder="请选择优惠类型"
                      @change="subsidyChange($event, index)"
                      collapse-tags
                    >
                      <el-option label="充电度数" :value="2"> </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="flex">
                  <el-form-item
                    label="度数："
                    style="width: 100%"
                    :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.degree`"
                    :rules="[
                      {
                        required: true,
                        message: '请输入充值度数',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <el-input
                      v-model.trim="val.degree"
                      placeholder="请输入充值度数"
                      clearable
                      maxlength="9"
                    />
                  </el-form-item>
                </div>
              </template>
              <!-- 优惠类型 -->

              <div v-else-if="billingId == 11 || billingId == 15">
                <el-form-item
                  :label="billingId == 15 ? '优惠类型：' : '搭赠类型：'"
                  style="width: 100%"
                  :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.subsidy_type`"
                >
                  <el-select
                    style="width: 200px"
                    v-model="val.subsidy_type"
                    placeholder="请选择是否搭赠"
                  >
                    <el-option label="无" :value="0" v-if="billingId == 11">
                    </el-option>
                    <el-option label="车辆性能检测" :value="3"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="billingId == 15 ? '数量' : '搭赠数量：'"
                  v-if="val.subsidy_type == 3"
                  style="width: 100%"
                  :rules="performanceNum"
                  :prop="`recharge_rule_list.${index}.activity_subsidy_type_list.${valIndex}.num`"
                >
                  <el-input
                    style="width: 200px"
                    v-model.trim="val.num"
                    placeholder="请输入次数"
                    clearable
                    maxlength="9"
                    oninput="value=value.replace(/[^0-9]/g,'')"
                    ><span slot="suffix">次</span></el-input
                  >
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>

  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    <el-button @click="visible = false">取消</el-button>
  </span>
</el-dialog>
