<template>
    <el-dialog title="总部补贴明细" :close-on-click-modal="true" :visible.sync="visible" width="70%" @close="close" @open="open">
        <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%;" max-height="500">
        <el-table-column prop="real_name" header-align="center" align="center" min-width="100" label="申请人">
        </el-table-column>
        <el-table-column prop="cellphone" header-align="center" align="center" min-width="100" label="申请人手机号">
        </el-table-column>
        <el-table-column prop="create_time" header-align="center" align="center" label="补贴时间">
        </el-table-column>
        <el-table-column prop="amount" header-align="center" align="center" label="补贴金额">
        </el-table-column>
        <el-table-column prop="early_amount" header-align="center" align="center" label="补贴前金额">
        </el-table-column>
        <el-table-column prop="current_amount" header-align="center" align="center" label="补贴后金额">
        </el-table-column>
        <el-table-column prop="associate_id" header-align="center" align="center" label="审批编号">
        </el-table-column>
        <el-table-column prop="remark" header-align="center" align="center" min-width="200" label="备注">
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" 
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>

    </el-dialog>
  </template>
  
  <script>
import subsidyPoolApi from "../../../api/subsidy_pool_api.js";
  export default {
    props: ['initialData'],
    data() {
      return {
        cityCode:'',
        visible: false,
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
      }
    },
    async created(){

     
    },
    methods: {
      init(data) {
        let _this=this
        // _this.cityCode=data.cityCode
        this.visible = true
        // this.$nextTick(() => {
        //   _this.cityCode=data.cityCode
        // })
      },
     // 获取数据列表
    async getDataList() {
      this.dataListLoading = true
      let reqData = {
        'offset': (this.pageIndex-1)*this.pageSize,
        'limit': this.pageSize,
        "reqdata":{cityCode:this.cityCode,flowType:4}
      }
      let res = await subsidyPoolApi.getSubsidyRecords(reqData).finally(_ => { this.dataListLoading = false });

      if (res.data.code != 0) {
        this.$message.error({
          message: res.data.msg
        });
        return
      }
      this.dataList = res.data.page.items
      this.totalPage = res.data.page.total_count
    },
    //
    async open(){
      this.cityCode=this.initialData.cityCode
      await this.getDataList()
    },
    close(){

    },

     // 每页数
     sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    }
  }
  </script>
<style scoped lang="scss">
.el-pagination{
  margin-top:10px;
}
</style>
  