<template>
  <div class="sidebar">
    <el-scrollbar
      style="height: 100%"
      wrapStyle="overflow-x:hidden;"
      viewStyle=""
    >
      <el-menu
        ref="menu"
        class="sidebar-el-menu"
        :default-active="onRoutes"
        :collapse="collapse"
        background-color="#ffffff"
        text-color="#333333"
        active-text-color="#ffffff"
        unique-opened
        router
      >
        <template v-for="item in items">
          <template v-if="item.subs">
            <el-submenu :fid="item.index" :index="item.index" :key="item.id">
              <template slot="title">
                <!-- <i :class="item.icon"></i><span slot="title">{{ item.title }}</span> -->
                <i
                  v-if="item.icon.indexOf('-icon-') > -1"
                  :class="item.icon"
                ></i>
                <img
                  v-else-if="item.icon !== ''"
                  style="display: inline-block; padding: 1px 10px 0 8px"
                  height="17px"
                  :src="
                    getImg(
                      item.icon,
                      item.index === current_route_path && !collapse
                    )
                  "
                  alt=""
                />
                <span
                  :style="{ marginLeft: item.icon == '' ? '8px' : '0' }"
                  slot="title"
                  >{{ item.title }}</span
                >
              </template>
              <template v-for="subItem in item.subs">
                <el-submenu
                  v-if="subItem.subs"
                  :fid="subItem.index"
                  :index="subItem.index"
                  :key="subItem.id"
                >
                  <template slot="title"
                    >&#x3000;&#x3000;{{ subItem.title }}</template
                  >
                  <el-menu-item
                    v-for="(threeItem, i) in subItem.subs"
                    :key="i"
                    :index="threeItem.index"
                    :fid="threeItem.index"
                    :class="{ active: threeItem.index === current_route_path }"
                  >
                    <span class="el-menu-item-box">
                      {{ threeItem.title }}
                    </span>
                  </el-menu-item>
                </el-submenu>
                <el-menu-item
                  v-else
                  :fid="subItem.index"
                  :index="subItem.index"
                  :key="subItem.index"
                  :class="{ active: subItem.index === current_route_path }"
                >
                  <span class="el-menu-item-box">
                    {{ subItem.title }}
                  </span>
                </el-menu-item>
              </template>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item
              :fid="item.index"
              :index="item.index"
              :key="item.index"
              :class="{ active: item.index === current_route_path }"
            >
              <!-- <i :class="item.icon"></i><span slot="title">{{ item.title }}</span> -->
              <i v-if="item.icon.indexOf('-icon-') > -1" :class="item.icon"></i>
              <img
                v-else-if="item.icon !== ''"
                style="display: inline-block; padding: 1px 10px 0 8px"
                height="17px"
                :src="
                  getImg(
                    item.icon,
                    item.index === current_route_path && !collapse
                  )
                "
                alt=""
              />
              <img
                v-else
                style="display: inline-block; padding: 1px 0 0 8px"
                height="17px"
                src=""
                alt=""
              />
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { getMenu } from "api/system/menu-api";
export default {
  data() {
    return {
      items: [],
      current_route_path: "",
      current_need_open_menu: "",
      store_collapse_status: false,
    };
  },
  computed: {
    onRoutes() {
      this.setCurrentRoutePath(this.$route.path);
      return this.$route.path.replace("/", "");
    },
    // 获取左侧菜单的折叠状态
    collapse() {
      return this.$store.state.collapse;
    },
  },
  methods: {
    getImg(name, isActive) {
      try {
        return new URL(
          `../../../assets/img/menu-icon/${name}` +
            (isActive ? "_active" : "") +
            ".png",
          import.meta.url
        ).href;
      } catch {
        return "";
      }
    },
    setCurrentRoutePath(val) {
      this.current_route_path = val;
    },
  },
  async created() {
    this.items = await getMenu("pms");
    if (this.items.length > 0) {
      if (this.items[0].subs.length > 0) {
        this.$store.commit("setFirstPath", this.items[0].subs[0].index);
      }
    }
  },
  mounted() {},
};
</script>

<style scoped>
.sidebar-el-menu {
  transition: none !important;
}

.el-submenu {
  transition: none !important;
}
.sidebar {
  position: absolute;
  left: 0;
  top: 50px;
  bottom: 0;
  /* width: 100%; */
  overflow-y: scroll;
}
.sidebar::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background-color: #c1cfdf;
  z-index: 10;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 209px;
  text-align: left;
}

/* .sidebar ul {
        height: 100%;
    } */
.sidebar .sidebar-el-menu .el-menu-item[role="menuitem"] {
  color: #000 !important;
}
.el-submenu__title:hover,
.el-menu-item:hover {
  background-color: #ffffff !important;
}

.sidebar .el-submenu__title i {
  color: #000;
}
.sidebar .sidebar-el-menu > .el-menu-item > i {
  margin-right: 0;
  padding-right: 5px;
  padding-left: 4px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  color: #000;
}
.sidebar .sidebar-el-menu > .el-menu-item > img {
  margin-right: 0;
  margin: 0 -1px 0 0\0;
  padding-right: 5px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  color: #000;
}
.sidebar .sidebar-el-menu > .el-menu-item > span {
  display: inline-block;
  line-height: 18px;
  padding-right: 8px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.sidebar .sidebar-el-menu > .el-menu-item:hover > i {
  background: #f6f8fd;
  box-shadow: -4px 0 0 4px #f6f8fd;
}
.sidebar .sidebar-el-menu > .el-menu-item:hover > img {
  background: #f6f8fd;
  box-shadow: -4px 0 0 4px #f6f8fd;
}
.sidebar .sidebar-el-menu > .el-menu-item:hover > span {
  background: #f6f8fd;
  box-shadow: 4px 0 0 4px #f6f8fd;
}
.sidebar .sidebar-el-menu > .el-menu-item.active > i {
  color: #ffffff !important;
  background: #5a8bff;
  box-shadow: -4px 0 0 4px #5a8bff;
}
.sidebar .sidebar-el-menu > .el-menu-item.active > img {
  color: #ffffff !important;
  background: #5a8bff;
  box-shadow: -4px 0 0 4px #5a8bff;
}
.sidebar .sidebar-el-menu > .el-menu-item.active > span {
  color: #ffffff;
  background: #5a8bff;
  box-shadow: 4px 0 0 4px #5a8bff;
}

.sidebar .el-menu--inline .el-menu-item .el-menu-item-box {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  padding: 6px 16px;
  border: none;
  box-shadow: none;
}
.sidebar .el-menu--inline .el-menu-item .el-menu-item-box:hover {
  background: #f6f8fd;
}
.sidebar .el-menu--inline .el-menu-item.active .el-menu-item-box {
  background: #5a8bff;
  color: #ffffff;
}
</style>
<style scoped>
::v-deep .el-submenu__title {
  color: #000 !important;
}
::v-deep .el-scrollbar__bar.is-vertical {
  display: none;
}
/* ::v-deep .el-menu-item, ::v-deep .el-submenu__title, ::v-deep .el-tooltip{
    padding: 0 16px;
} */
::v-deep .sidebar-el-menu > li:not([aria-haspopup="true"]) {
  padding: 0 16px !important;
}
::v-deep .sidebar-el-menu > li > div.el-submenu__title {
  padding: 0 16px !important;
}
::v-deep .sidebar-el-menu > li > .el-tooltip {
  padding: 0 16px !important;
}
::v-deep .el-menu--collapse > .el-menu-item [class^="el-icon-"] {
  padding-left: 4px;
}
::v-deep .el-submenu [class^="el-icon-"],
::v-deep
  .el-menu--collapse
  > .el-submenu
  > .el-submenu__title
  [class^="el-icon-"] {
  width: 32px;
}
</style>
