<template>
  <div>
    <ex-dialog
      :title="title"
      :visible.sync="dialog_visible"
      :width="700"
      :height="400"
      :show-fullscreen-icon="true"
      :append-to-body="true"
      @close="close"
    >
      <el-form
        :model="FormData"
        ref="FormData"
        :rules="rules"
        label-width="110px"
      >
        <el-form-item label="手机号码" prop="cellphone">
          <el-select
            v-model="FormData.cellphone"
            filterable
            remote
            reserve-keyword
            placeholder="请输入手机号码"
            style="width: 500px"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="selectUser"
            :disabled="!!select_user.user_id"
          >
            <el-option
              v-for="item in userList"
              :key="item.uid"
              :label="`${item.cellphone}`"
              :value="item.cellphone"
            >
              <p>{{ `${item.cellphone} ${item.first_city_name || ""}` }}</p>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市" prop="city_code">
          <el-select
            v-model="FormData.city_code"
            placeholder="请选择城市"
            style="width: 500px"
            :disabled="agentCityCode != ''"
          >
            <el-option
              v-for="item in cityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册时间">
          <el-date-picker
            v-model="FormData.create_time"
            :disabled="FormData.create_time != ''"
            style="width: 500px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="姓名" prop="real_name">
          <el-input
            v-model="FormData.real_name"
            style="width: 500px"
            placeholder="请输入姓名"
          />
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select
            v-model="FormData.sex"
            placeholder="请选择性别"
            style="width: 500px"
          >
            <el-option label="保密" :value="0"></el-option>
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="submitForm('FormData')"
          >确定</el-button
        >
      </div>
    </ex-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      FormData: {
        cellphone: "",
        city_code: "",
        create_time: "",
        real_name: "",
        sex: "",
        user_id: "",
      },
      rules: {
        cellphone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        city_code: [{ required: true, message: "请选择城市", trigger: "blur" }],
        real_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
      },
      userList: [],
      dialog_visible: true,
      loading: false,
    };
  },
  props: {
    select_user: {
      type: Object,
      default: () => {
        return {};
      },
    },
    agentCityCode: {
      type: [String, Number],
      default: "",
    },
    cityList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters(["user"]),
    title() {
      if (this.select_user.user_id) {
        return "编辑配送员信息";
      } else {
        return "新增配送员信息";
      }
    },
  },
  created() {
    if (this.select_user.user_id) {
      this.FormData.cellphone = this.select_user.cellphone;
      this.FormData.city_code = this.select_user.city_code;
      this.FormData.create_time = this.select_user.create_time;
      this.FormData.real_name = this.select_user.real_name;
      this.FormData.sex = this.select_user.sex;
      this.FormData.user_id = this.select_user.user_id;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitForm(FormData) {
      this.$refs[FormData].validate(async (valid) => {
        if (valid) {
          this.showLoading("加载中");
          const res = await this.axiosHelper
            .post(
              this.select_user.user_id
                ? "/api/user/manage/delivery/user/update"
                : "/api/user/manage/delivery/user/add",
              {
                ...this.FormData,
              }
            )
            .finally(() => {
              this.hideLoading();
            });
          if (res.data.code == 0) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.$emit("sure");
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        } else {
          return false;
        }
      });
    },
    selectUser(it) {
      let idx = this.userList.findIndex((item) => item.cellphone == it);
      if (idx > -1) {
        this.FormData.city_code = this.agentCityCode
          ? `${this.agentCityCode || ""}`
          : `${this.userList[idx].first_city_code || ""}`;
        this.FormData.create_time = this.userList[idx].create_time;
        this.FormData.sex = this.userList[idx].sex;
        this.FormData.user_id = this.userList[idx].uid;
        this.FormData.real_name = this.userList[idx].real_name;
      }
    },
    async remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const res = await this.axiosHelper
          .post("/api/user/manage/page", {
            cellphone: query,
            firstCityCode: this.agentCityCode ? this.agentCityCode : "",
            limit: 20,
            offset: 0,
          })
          .finally(() => {
            this.loading = false;
          });
        console.log(res);
        if (res.status == 200 && res.data.items) {
          this.userList = res.data.items;
        }
      } else {
        this.userList = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>