
<div class="height:100%;">
  <el-card class="box-card" style="height: 10%">
    <el-form :inline="true">
      <el-form-item label="城市">
        <el-select
          v-model="form.cityCode"
          placeholder="全部"
          collapse-tags
          clearable
          filterable
        >
          <el-option
            v-for="item in cityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="form.status"
          placeholder="全部"
          collapse-tags
          clearable
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <el-button style="margin-left: 15px" @click="addOrUpdateHandle('add')"
        >新增</el-button
      >
    </el-row>
    <el-table ref="tableData" :height="tableHeight" :data="dataTable">
      <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="start_time"
        label="展示时间"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        min-width="100"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.status === 1
                ? "进行中"
                : scope.row.status === 2
                ? "停用"
                : scope.row.status === 3
                ? "已结束"
                : "待开始"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status === 1"
            @click="statusSwitch(scope.row, 0)"
            type="text"
            size="small"
            >停用</el-button
          >
          <el-button
            v-if="scope.row.status === 2"
            @click="statusSwitch(scope.row, 1)"
            type="text"
            size="small"
            >启用</el-button
          >
          <el-button
            v-if="scope.row.status === 4"
            @click="deleteHandle(scope.row)"
            type="text"
            size="small"
            >删除</el-button
          >
          <el-button
            v-if="scope.row.status === 4 || scope.row.status === 1"
            @click="addOrUpdateHandle('edit', scope.row)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            @click="addOrUpdateHandle('details', scope.row)"
            type="text"
            size="small"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <activityPageAddModal
    ref="addOrUpdate"
    :editState="editState"
    @refreshDataList="search"
  ></activityPageAddModal>
  <activityPageView
    v-if="activityPageViewVisible"
    ref="activityPageView"
  ></activityPageView>
</div>
