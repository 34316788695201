<template>
  <!-- 发放情况表格 -->
  <el-dialog
    width="70%"
    :title="'账户明细'"
    :visible.sync="visible"
    append-to-body
  >
    <el-card class="box-card" style="height: 20%">
      <el-form :inline="true">
        <el-form-item label="收入/支出：">
          <el-select
            v-model="form.type"
            placeholder="全部"
            collapse-tags
            clearable
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="收入" :value="1"></el-option>
            <el-option label="支出" :value="-1"></el-option>
          </el-select>
        </el-form-item>
        <el-button style="margin-right: 15px" type="primary" @click="search"
          >查询</el-button
        >
        <el-button style="margin-right: 15px" type="default" @click="clear"
          >重置</el-button
        >
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-table ref="tableData" :height="tableHeight" :data="dataTable">
        <el-table-column
          prop="type"
          label="类型"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type === 7">账户充值</span>
            <span v-else-if="scope.row.type === 8">订单支出</span>
            <span v-else-if="scope.row.type === 9">作废</span>
            <span v-else-if="scope.row.type === 21">B端度数充值</span>
            <span v-else>B端度数转出</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="time"
          label="时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="购买金额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="degree"
          label="收入/支出(度)"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type === 7 || scope.row.type === 21"
              >+{{ scope.row.degree }}</span
            >
            <span v-else>-{{ scope.row.degree }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="activity_name"
          label="活动名称"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.activity_name ? scope.row.activity_name : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="target_user"
          label="转赠对象"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.target_user ? scope.row.target_user : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="target_user_number"
          label="转赠对象编号"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.target_user_number ? scope.row.target_user_number : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="early_available_degree"
          label="前(度)"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cur_available_degree"
          label="后(度)"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="page_total"
          :page-size="page_size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page_index"
        ></el-pagination>
      </el-footer>
    </el-card>
  </el-dialog>
</template>
<script>
import * as XLSX from "xlsx";
import { operateAdd } from "@/api/system/user-api";
export default {
  props: {
    userId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      form: {
        type: "",
        degreeType: 1,
      },
      visible: false,
      tableHeight: "50vh",
      dataTable: [],
      page_total: 0,
      page_index: 1,
      page_size: 50,
      user_id: "",
    };
  },
  methods: {
    async search() {
      this.page_index = 1;
      this.$emit("getList");
    },
    clear() {
      this.form = {
        type: "",
      };
      this.createTime = [];
      this.page_index = 1;
      this.$emit("getList");
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.$emit("getList");
    },
    handleCurrentChange(val) {
      this.page_index = val;
      this.$emit("getList");
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 27,
        operate_parameter: JSON.stringify({
          ...this.form,
          source: "pc",
        }),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      this.$confirm("是否要导出全部数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        if (this.page_total > 20000) {
          await this.$confirm(
            `您本次需要导出${this.page_total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          );
        }
        this.showLoading("数据加载中...");
        this.exportData();
      });
    },
    exportData() {
      let params = {
        ...this.form,
        source: "pc",
      };
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/v0.1/api/operate/degree/detail/${this.userId}/export`, {
          params: params,
        })
        .then((res) => {
          this.hideLoading();
          let newData = this.getHeadersAndbody(res.data.data);
          const ws = XLSX.utils.json_to_sheet(newData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          const fileName = "B端优享管理.xlsx";
          XLSX.writeFile(wb, fileName);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("导出失败");
        });
    },
    getHeadersAndbody(list) {
      let newArray = [];
      list.forEach((item) => {
        let newObj = {};
        newObj["类型"] =
          item.type === 7
            ? "账户充值"
            : item.type === 8
            ? "订单支出"
            : item.type === 9
            ? "作废"
            : item.type === 21
            ? "B端度数充值"
            : "B端度数转出";
        newObj["时间"] = item.time;
        newObj["购买金额"] = item.amount;
        newObj["收入/支出(度)"] =
          item.type === 7 || item.type === 21
            ? `+${item.degree}`
            : `-${item.degree}`;
        newObj["活动名称"] = item.activity_name;
        newObj["转赠对象"] = item.target_user;
        newObj["前(度)"] = item.early_available_degree;
        newObj["后(度)"] = item.cur_available_degree;
        // newObj["状态"] = item.cur_status === 1 ? '进行中' : item.cur_status === 2 ? '停用' : item.cur_status === 3 ? '已结束' : '待开始';
        newArray.push(newObj);
      });
      return newArray;
    },
  },
};
</script>
<style lang="scss">
.box-card {
  margin-top: 15px;
}
</style>